import React, { useState } from "react";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { apis } from "../../shared/apis";
import axios from "axios";
const DownloadPrescriptionButton = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadRecieptHandler = async (input) => {
    setIsDownloading(true);
    var data = JSON.stringify(input);

    var config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    await fetch(apis.postCreatePrescriptionPdf.url, config)
      .then((res) => res.blob())
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `JanElaaj-prescription-${props?.pdlaid}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsDownloading(false);
        message.success("Successfully downloaded Prescription");
      })
      .catch((err) => {
        setIsDownloading(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const getPresctionHandler = async () => {
    let input = {
      pdla_id: props?.pdlaid,
    };
    setIsDownloading(true);
    await axios({ ...apis.postOnlinePrescriptionEntries, data: input })
      .then((res, err) => {
        setIsDownloading(false);
        if (err) message.error("Something Went Wrong");
        else {
          downloadRecieptHandler(res.data.info[0]);
        }
      })
      .catch((err) => {
        setIsDownloading(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  return (
    <Button
      type="primary"
      className="my-1"
      onClick={getPresctionHandler}
      loading={isDownloading}
      // disabled={isDisabled || !isOTPValid}
      icon={<DownloadOutlined style={{ display: "inline-flex" }} />}
    >
      Get Prescription
    </Button>
  );
};

export default DownloadPrescriptionButton;
