import React, { useState } from "react";
import { Form, Button, Input, Select, DatePicker, Divider, Card } from "antd";
import sideImg from "../assets/images/gift.png";
const { Option } = Select;
const GiftFormHAC = () => {
  // const [loader, setLoader] = useState(false);
  // const [showOtpForm, setShowOtpForm] = useState(false);
  const [numStatus] = useState({
    message: "Sign Up",
    isLoading: false,
    error: "",
  });
  const handleSubmit = (e) => {
    // console.log("E: ", e);
  };

  return (
    <div
      className="row mx-0 pt-4 px-2"
      style={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <div
        className="col-sm-6 "
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={sideImg} alt={"gift"} style={{ width: "80%" }}></img>
      </div>
      <div className="col-sm-4">
        <Card hoverable bodyStyle={{ padding: "5px 24px" }}>
          <Form onFinish={handleSubmit}>
            <Divider>Enter your Gift Amount</Divider>
            <Form.Item
              name="amount"
              rules={[{ required: true, message: "Please Enter Amount!" }]}
            >
              <Input
                size="large"
                placeholder="Amount"
                addonBefore={<div>₹</div>}
                suffix={<div> to help a cause</div>}
              />
            </Form.Item>
            <Divider>Personal Information</Divider>
            <Form.Item
              name="fullname"
              rules={[{ required: true, message: "Please Enter Your Name!" }]}
            >
              <Input size="large" placeholder="Full Name" />
            </Form.Item>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              <Form.Item
                name="address"
                rules={[
                  { required: true, message: "Please Enter Your Address!" },
                ]}
                style={{ flexGrow: "2", paddingRight: "10px" }}
              >
                <Input size="large" placeholder="Address" />
              </Form.Item>
              <Form.Item
                name="city"
                rules={[{ required: true, message: "Please Enter Your City!" }]}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="country"
                style={{ flexGrow: "1", paddingRight: "10px" }}
              >
                <Select placeholder="Country" size="large">
                  <Option value="M">India</Option>
                  <Option value="F">United States</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="state"
                style={{ flexGrow: "1", paddingRight: "10px" }}
              >
                <Select placeholder="State" size="large">
                  <Option value="M">Rajasthan</Option>
                  <Option value="F">Delhi</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="postalCode"
                rules={[
                  { required: true, message: "Please Enter Your Postal Code!" },
                ]}
              >
                <Input size="large" placeholder="Postal Code" />
              </Form.Item>
            </div>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please Enter Your Email!" }]}
            >
              <Input type="email" size="large" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Phone Number!",
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length === 10) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Please enter valid Phone Number")
                    );
                  },
                }),
              ]}
              hasFeedback
              help={
                numStatus?.error ? (
                  <div className="text-danger">{numStatus?.error}</div>
                ) : null
              }
            >
              <Input size="large" placeholder="Phone Number" />
            </Form.Item>

            <Divider>Billing Information</Divider>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              <Form.Item
                name="cardName"
                rules={[
                  { required: true, message: "Please Enter Your Card Name!" },
                ]}
                style={{ flexGrow: "1", paddingRight: "10px" }}
              >
                <Input size="large" placeholder="Name on Card" />
              </Form.Item>
              <Form.Item
                name="cardNum"
                rules={[
                  { required: true, message: "Please Enter Your Card Number!" },
                ]}
                style={{ flexGrow: "1" }}
              >
                <Input size="large" placeholder="Card Number" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              <Form.Item
                name="cvv"
                rules={[{ required: true, message: "Please Enter Your CVV!" }]}
                style={{ flexGrow: "1", paddingRight: "10px" }}
              >
                <Input size="large" placeholder="CVV" />
              </Form.Item>
              <Form.Item
                name="dob"
                rules={[{ required: true, message: "Please enter your DOB!" }]}
                style={{ flexGrow: "2" }}
              >
                <DatePicker
                  size="large"
                  placeholder="Expiration"
                  style={{ width: "100%" }}
                  picker="month"
                  format="MM-YYYY"
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  //   justifyContent: "center",
                  alignItems: "center",
                  //   padding: "1rem",
                }}
              >
                <div>
                  <Button type="primary" htmlType="submit" size="large">
                    Gift Now
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="col-sm-2"></div>
    </div>
  );
};

export default GiftFormHAC;
