import { Card, Empty, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "../../shared/apis";
import ClinicCard from "./ClinicCard";

const ClinicDetailsCard = (props) => {
  const [loader, setLoader] = useState(false);
  const [clinics, setClinics] = useState([]);

  useEffect(() => {
    (async function () {
      setLoader(true);
      await axios({
        ...apis.getClinicsForDlm,
        data: { dlm_id: props?.doc?.ldlm_id },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Err in fetching Clinic Details");

          setClinics(res.data?.info);
        })
        .catch((err) => {
          setLoader(false);
          message.error("Err in fetching Clinic Details");
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid mt-2">
      <Card
        title={<div>Clinics</div>}
        headStyle={{ fontSize: "1.5rem" }}
        loading={loader}
        bodyStyle={{ padding: "0.5rem" }}
      >
        {clinics?.length ? (
          clinics.map((clinic, i) => (
            <div key={i + Math.random()}>
              <ClinicCard {...clinic}></ClinicCard>
            </div>
          ))
        ) : (
          <Empty description="No Clinics Found"></Empty>
        )}
      </Card>
    </div>
  );
};

export default ClinicDetailsCard;
