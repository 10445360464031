import * as ActionTypes from "../../ActionTypes";

// cities reducer
export const Cities = (
  state = {
    isLoading: true,
    errMess: null,
    cities: {},
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_CITIES:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        cities: action.payload,
      };

    case ActionTypes.CITIES_LOADING:
      return { ...state, isLoading: true, errMess: null, cities: {} };

    case ActionTypes.CITIES_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        cities: {},
      };

    default:
      return state;
  }
};
