import { Card } from "antd";
import React from "react";

const ManageLocCard = (props) => {
  return (
    <div
      onClick={() => {
        props.history.push(props.path);
      }}
    >
      <Card
        type="inner"
        hoverable
        className="bg-light"
        cover={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "30px 30px 10px", fontSize: "2rem" }}>
              {props.icon}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2px",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              {props.title}
            </div>
          </div>
        }
      ></Card>
    </div>
  );
};

export default ManageLocCard;
