import React, { useState } from "react";
import { Button, Card, Input, Form, Popconfirm, message } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";

const ServiceAndRateCard = (props) => {
  console.log(props)
  const [loader, setLoader] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [visible, setVisible] = useState(false);
  const isChemist =
    JSON.parse(localStorage.getItem("selectedRole"))?.role === "CHE";
  const deleteService = async () => {
    setLoader(true);
    await axios({ ...apis.deleteservice, data: { dcsmid: props.dcsmid } })
      .then((res, err) => {
        setLoader(false);
        setVisible(false);
        if (err) {
          message.error("Something went Wrong");
        } else props.updateList();
      })
      .catch((err) => {
        setLoader(false);
        setVisible(false);

        message.error("Something went Wrong");
      });
  };

  const updateServiceHandler = async (data) => {
    setUpdating(true);
    await axios({
      ...apis.updateservice,
      data: {
        dcsmid: props?.dcsmid,
        ...data,
        namount: data?.namount || "0",
        damount: data?.damount || "0",
        dflag: data?.damount > 0 && data.namount > data.damount ? "Y" : "N",
      },
    })
      .then((res, err) => {
        setUpdating(false);
        if (err) {
          message.error("Something Went Wrong");
        } else {
          message.success("Successfully Updated!");
          props.updateList();
        }
      })
      .catch((err) => {
        setUpdating(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div>
      <Card
        hoverable
        type="inner"
        headStyle={{ backgroundColor: "#004fcf", color: "white" }}
        title={
          <div
            className="text-center p-2"
            style={{
              display: "flex",
              fontSize: "1rem",
            }}
          >
            {props?.sname}
          </div>
        }
      >
        <div>
          <Form
            layout="vertical"
            initialValues={{ namount: props.namount, damount: props.flag == "Y"?props.damount:'' }}
            onFinish={updateServiceHandler}
          >
            <Form.Item
              name="namount"
              label={
                <div className="text-primary" style={{ fontSize: "1rem" }}>
                  {isChemist ? "Normal Discount(%)" : "Price (₹)"}
                </div>
              }
            >
              <Input
                placeholder={props.namount}
                // value={props.namount}
                // onChange={(e) => console.log("E: ", e)}
                size="large"
                bordered={false}
                style={{ borderBottom: "1px solid gray" }}
              ></Input>
            </Form.Item>
            <Form.Item
              name="damount"
              label={
                <div className="text-primary" style={{ fontSize: "1rem" }}>
                  {isChemist ? "Special Discount(%)" : "Discounted Price (₹)"}
                </div>
              }
              rules={[
                {
                  required: false,
                  message: "No problem",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      value &&
                      parseInt(value) > parseInt(getFieldValue("namount"))
                    ) {
                      return Promise.reject(
                        "Discount Amount Cannot be greater than Price !"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                // placeholder={1}
                value={1}
                size="large"
                bordered={false}
                style={{ borderBottom: "1px solid gray" }}
              ></Input>
            </Form.Item>
            <div className="font-weight-bold text-secondary m-1 mb-2">
              Leave blank for no discount!
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between ",
              }}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={updating}>
                  {updating ? "Please Wait ..." : "Save"}
                </Button>
              </Form.Item>
              <Form.Item className="mx-4">
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{ loading: loader }}
                  visible={visible}
                  onCancel={() => setVisible(false)}
                  onConfirm={deleteService}
                >
                  <Button
                    type="danger"
                    htmlType="reset"
                    onClick={() => setVisible(true)}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Card>
      {}
    </div>
  );
};

export default ServiceAndRateCard;
