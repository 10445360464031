import React, { useState, useEffect } from "react";
import { message, Select, Spin, Card, Divider, Image, Avatar } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";
import { UserOutlined } from "@ant-design/icons";
const { Option } = Select;
const UserProfile = () => {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))?.role[0];

  const [fetchingD, setFetchingD] = useState(false);
  const [dependents, setDependents] = useState([
    {
      dependentid: userProfile?.patientid,
      dob: userProfile?.patientdob,
      email: userProfile?.patientemail,
      gender: userProfile?.patientgender,
      mobile: userProfile?.patientmobile,
      name: userProfile?.patientname,
      patientid: userProfile?.patientid,
      pdmid: userProfile?.patientid,
      photo: userProfile?.patientphoto
        ? userProfile?.patientphoto?.includes("data:image/jpeg;base64,")
          ? userProfile?.patientphoto
          : "data:image/jpeg;base64," + userProfile?.patientphoto
        : userProfile?.patientphoto,
    },
  ]);
  const [dependent, setDependent] = useState(dependents[0]?.name);
  const [userDependent, setUserDependent] = useState(dependents[0]);

  const handleDependent = (e) => {
    setDependent(e);
    let selDep = dependents?.filter((d) => d.name === e);
    setUserDependent(selDep[0]);
  };

  const fetchDependents = async () => {
    setFetchingD(true);
    await axios({
      ...apis.getPatientDependents,
      data: {
        pid: JSON.parse(localStorage.getItem("userProfile"))?.role[0]
          ?.patientid,
      },
    })
      .then((res, err) => {
        if (err) message.error("Err while fetching dependents: ");
        setFetchingD(false);
        setDependents([...dependents, ...res.data.dependents]);
      })
      .catch((err) => {
        if (err) message.error("Err while fetching dependents: ");
        setFetchingD(false);
      });
  };
  useEffect(() => {
    fetchDependents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="m-1 p-1"
      >
        <div className="mx-3" style={{ fontSize: "1.25rem" }}>
          Showing Profile of
        </div>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder=" Choose Dependent"
          optionFilterProp="children"
          onChange={handleDependent}
          notFoundContent={fetchingD ? <Spin size="small" /> : null}
          size="large"
          value={dependent}
          allowClear
        >
          {dependents.map((d, i) => (
            <Option
              value={d.name}
              // key={d?.dependentid || d?.patientid}
              key={i + Math.random()}
            >
              <div className="option-dependent px-1">
                <span className="user-icon">
                  {d.photo ? (
                    <img
                      alt={" "}
                      className="img-fluid"
                      src={
                        d.photo?.includes("data:image/jpeg;base64")
                          ? d.photo
                          : "data:image/jpeg;base64," + d.photo
                      }
                    ></img>
                  ) : (
                    <UserOutlined></UserOutlined>
                  )}
                </span>
                <span className="user-name">{d.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </div>
      <div>
        {/* {userDependent?.name === dependents[0]?.name ? ( */}
        <div>
          <Card hoverable bordered={false}>
            <div className="profile-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {userDependent?.photo ? (
                  <Image
                    src={
                      userDependent?.photo?.includes("data:image/jpeg;base64,")
                        ? userDependent?.photo
                        : "data:image/jpeg;base64," + userDependent?.photo
                    }
                    alt="Image"
                    className="rounded"
                    style={{ width: "128px" }}
                    preview={true}
                  />
                ) : (
                  <Avatar
                    size={128}
                    icon={<UserOutlined style={{ display: "inline-flex" }} />}
                  />
                )}
              </div>
              <Divider></Divider>
              <div className="profile-details-container">
                <div>
                  <span className="label-profile">Name : </span>
                  {userDependent?.name}
                </div>
                <div>
                  <span className="label-profile">DOB : </span>
                  {userDependent?.dob}
                </div>
                <div>
                  <span className="label-profile">Gender : </span>
                  {userDependent?.gender
                    ? userDependent?.gender === "M"
                      ? "Male"
                      : "Female"
                    : "Not Applicable"}
                </div>
                {userDependent?.email ? (
                  <div>
                    <span className="label-profile">Email : </span>
                    {userDependent?.email}
                  </div>
                ) : null}
                {userDependent?.mobile ? (
                  <div>
                    <span className="label-profile">Phone : </span>
                    {userDependent?.mobile}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <Form initialValues={userDependent}>
                <Form.Item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {userDependent?.photo ? (
                    <Image
                      src={
                        userDependent?.photo?.includes(
                          "data:image/jpeg;base64,"
                        )
                          ? userDependent?.photo
                          : "data:image/jpeg;base64," + userDependent?.photo
                      }
                      alt="Image"
                      className="rounded"
                      style={{ width: "128px" }}
                      preview={true}
                    />
                  ) : (
                    <Avatar
                      size={128}
                      icon={<UserOutlined style={{ display: "inline-flex" }} />}
                    />
                  )}
                </Form.Item>
                <Form.Item name="name">
                  <Input
                    size="large"
                    placeholder="Full Name"
                    disabled
                    value={userDependent?.name}
                  />
                </Form.Item>
                <div className="row mx-0">
                  <Form.Item name="dob" className="col-sm-6 pl-0">
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Select DOB"
                      // format="YYYY-MM-DD"
                      disabled
                    ></Input>
                  </Form.Item>
                  <Form.Item name="gender" className="col-sm-6 pr-0">
                    <Select placeholder="Gender" size="large" disabled>
                      <Option value="M">Male</Option>
                      <Option value="F">Female</Option>
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item name="email">
                  <Input
                    type="email"
                    size="large"
                    placeholder="Email"
                    disabled
                  />
                </Form.Item>
                <Form.Item name="mobile">
                  <Input size="large" placeholder="Phone Number" disabled />
                </Form.Item>
              </Form> */}
          </Card>
        </div>
        {/* ) : (
          <div>
            <Card hoverable bodyStyle={{ padding: "5px 24px" }}>
              <Divider></Divider>
              <Form>
                <Form.Item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {userDependent?.photo ? (
                    <Image
                      src={
                        userDependent?.photo?.includes(
                          "data:image/jpeg;base64,"
                        )
                          ? userDependent?.photo
                          : "data:image/jpeg;base64," + userDependent?.photo
                      }
                      alt="Image"
                      className="rounded"
                      style={{ width: "128px" }}
                      preview={true}
                    />
                  ) : (
                    <Avatar
                      size={128}
                      icon={<UserOutlined style={{ display: "inline-flex" }} />}
                    />
                  )}
                </Form.Item>
                <Form.Item name="name">
                  <Input
                    size="large"
                    placeholder="Full Name"
                    disabled
                    value={userDependent.name}
                  />
                </Form.Item>
                <div className="row mx-0">
                  <Form.Item name="dob" className="col-sm-6 pl-0">
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Select DOB"
                      // format="YYYY-MM-DD"
                      disabled
                    ></Input>
                  </Form.Item>
                  <Form.Item name="gender" className="col-sm-6 pr-0">
                    <Select placeholder="Gender" size="large" disabled>
                      <Option value="M">Male</Option>
                      <Option value="F">Female</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </Card>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default UserProfile;
