import { Carousel } from "antd";
import React from "react";
import { Card, CardImg, CardImgOverlay, CardTitle } from "reactstrap";
import "../assets/styles/preventiveCare.scss";
// import Carousel from "nuka-carousel";

const PreventiveCare = () => {
  return (
    <div className="container-fluid">
      <div className="jumbotron">
        <h1 className="card-title-2 text-dark"> Preventive Care </h1>
        <hr className="my-4" />
        <p className="card-text-1 text-dark">
          Jan Elaaj envisages a requirement to change behavior, promote early
          detection at the primary care level at much lower costs(preventive
          mechanisms). These are fundamentals of good primary care. Jan Elaaj
          will introduce affordable health checkup booths to facilitate basic
          vitals measurements on regular interval
        </p>
      </div>
      <Carousel autoplay dots={{ className: "dark-dots" }}>
        <Card>
          <CardImg
            src="/images/preventive-care/prev-1.jpg"
            className="carousel-img"
          />
          <CardImgOverlay>
            <CardTitle className="carousel-text-1">
              Diabetes, Heart Attack, Stroke, Cancer, Asthma etc typically carry
              no symptoms or warnings
            </CardTitle>
          </CardImgOverlay>
        </Card>
        <Card>
          <CardImg
            src="/images/preventive-care/prev-2.jpg"
            className="carousel-img"
          />
          <CardImgOverlay>
            <CardTitle className="carousel-text-2">
              Mental Depression, TB are making strong footholds in urban areas
            </CardTitle>
          </CardImgOverlay>
        </Card>
        <Card>
          <CardImg
            src="/images/preventive-care/prev-3.jpg"
            className="carousel-img"
          />
          <CardImgOverlay>
            <CardTitle className="carousel-text-3">
              The individual continues to lead a healthy life unaware of the
              danger(s) indicated
            </CardTitle>
          </CardImgOverlay>
        </Card>
      </Carousel>
      <div className="alert alert-secondary mt-5" role="alert">
        <div className="m-4">
          <h4 className="alert-heading prev-head-x">
            More people die of the Non Communicable Diseases than normal
            diseases !!!
          </h4>
          <br />
          <p className="prev-text-x">
            Simple Measurements can detect most of these if done on time,
            Preventive checks leads to early detection of diseases with time to
            cure.
          </p>
          <hr />
          <p className="prev-text-x">
            Come, join the battle against non-communicable diseases with Jan
            Elaaj ! With a few simple measurements, Jan Elaaj preventively
            screens you for the above and more.
          </p>
          <a
            className="btn btn-primary prev-text-x"
            href="https://play.google.com/store/apps/details?id=clinic.janelaaj.janelaajuserapp&hl=en"
            role="button"
          >
            Visit us today !
          </a>
        </div>
      </div>
    </div>
  );
};

export default PreventiveCare;
