import React from "react";
import { Result, Button } from "antd";
import PaymentSuccessCard from "../PaymentSuccessCard";
const Razorpaypaymentfailed = (props) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Thank You for using Jan Elaaj Services."
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => props.history.push("/dashboard")}
            size="large"
          >
            Go To Dashboard
          </Button>,
          //   <Button key="buy">Buy Again</Button>,
        ]}
      />
      <PaymentSuccessCard
        data={JSON.parse(localStorage.getItem("rpError"))}
        statusCode={500}
      ></PaymentSuccessCard>
    </div>
  );
};

export default Razorpaypaymentfailed;
