import React, { useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioOutlined, AudioMutedOutlined } from "@ant-design/icons";
import { Modal } from "antd";
const Dictaphone = (props) => {
  const { transcript, resetTranscript } = useSpeechRecognition();

  const [status, setStatus] = useState({
    isListening: false,
    showModal: false,
  });

  return (
    <div className="mx-2" style={{ width: "fit-content" }}>
      {SpeechRecognition.browserSupportsSpeechRecognition() ? (
        <div>
          <AudioOutlined
            onClick={() => {
              setStatus({ isListening: true, showModal: true });
              resetTranscript();
              SpeechRecognition.startListening({ continuous: true });
            }}
            style={{
              fontSize: 16,
              color: "#1890ff",
              display: "inline-flex",
            }}
          />
        </div>
      ) : (
        <AudioMutedOutlined />
      )}
      <Modal
        centered
        visible={status.showModal}
        title={
          <div>
            {status.isListening ? "Listening..." : "Click Mic To Start"}
          </div>
        }
        onCancel={() => {
          resetTranscript();
          setStatus({ ...status, showModal: false, isListening: false });
          SpeechRecognition.stopListening();
        }}
        onOk={() => {
          props.setMessage(
            (props.message ? props.message : "") + " " + transcript
          );
          resetTranscript();
          SpeechRecognition.stopListening();
          setStatus({ ...status, showModal: false, isListening: false });
        }}
        okText="Save"
        cancelText="Close"
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AudioOutlined
              onClick={() => {
                setStatus({ ...status, isListening: !status.isListening });
                !status.isListening
                  ? SpeechRecognition.startListening({ continuous: true })
                  : SpeechRecognition.stopListening();
              }}
              style={{
                fontSize: 50,
                border: "1px solid gray",
                padding: "20px",
                margin: "auto",
                borderRadius: "50%",
                color: status.isListening ? "#1890ff" : "black",
              }}
            />
          </div>
          <div className="transcript">{transcript}</div>
        </div>
      </Modal>
    </div>
  );
};
export default Dictaphone;

// import React, { useState } from "react";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";

// const Dictaphone = () => {
//   const [message, setMessage] = useState("");
//   const commands = [
//     {
//       command: "I would like to order *",
//       callback: (food) => setMessage(`Your order is for: ${food}`),
//     },
//     {
//       command: "The weather is :condition today",
//       callback: (condition) => setMessage(`Today, the weather is ${condition}`),
//     },
//     {
//       command: "My top sports are * and *",
//       callback: (sport1, sport2) => setMessage(`#1: ${sport1}, #2: ${sport2}`),
//     },
//     {
//       command: "Pass the salt (please)",
//       callback: () => setMessage("My pleasure"),
//     },
//     {
//       command: ["Hello", "Hi"],
//       callback: ({ command }) => setMessage(`Hi there! You said: "${command}"`),
//       matchInterim: true,
//     },
//     {
//       command: "Beijing",
//       callback: (command, spokenPhrase, similarityRatio) =>
//         setMessage(
//           `${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`
//         ),
//       // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
//       isFuzzyMatch: true,
//       fuzzyMatchingThreshold: 0.2,
//     },
//     {
//       command: ["eat", "sleep", "leave"],
//       callback: (command) => setMessage(`Best matching command: ${command}`),
//       isFuzzyMatch: true,
//       fuzzyMatchingThreshold: 0.2,
//       bestMatchOnly: true,
//     },
//     {
//       command: "clear",
//       callback: ({ resetTranscript }) => resetTranscript(),
//     },
//   ];

//   const { transcript } = useSpeechRecognition({ commands });

//   if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
//     return null;
//   }

//   return (
//     <div>
//       <button onClick={SpeechRecognition.startListening}>Start</button>
//       <button onClick={SpeechRecognition.stopListening}>Stop</button>
//       <button onClick={SpeechRecognition.abortListening}>Reset</button>
//       <p>{message}</p>
//       <p>{transcript}</p>
//     </div>
//   );
// };
// export default Dictaphone;
