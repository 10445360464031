import React, { useState } from "react";
import { message, Card } from "antd";
import {
  UserOutlined,
  //   SolutionOutlined,
  //   LoadingOutlined,
  //   SmileOutlined,
} from "@ant-design/icons";

import axios from "axios";
import { connect } from "react-redux";
import { apis } from "../shared/apis";
import LogoCard from "./userDashBoard/LogoCard";
import * as dashboardAC from "../redux/ActionCreators/UserDashActionCreator";
import LoadingMask from "./LoaderMask/LoadingMask";
import ScreeningCard from "./userDashBoard/ScreeningCard";

const SpecialityList = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);

  const getDoctorsList = async (data) => {
    setLoader(true);
    let api =
      props?.data?.dmrole === "VIT"
        ? apis.getVitalsBySearchLimit
        : props?.data?.dmrole === "ONL" ||
          props?.data?.dmrole === "ODIE" ||
          props?.data?.dmrole === "OPSY"
        ? apis.getonlinedoctorsbysearchlimit
        : apis.getdoctorsbysearchlimit;
    // let api = apis.getVitalsBySearchLimit;
    // let api = apis.getdoctorsbysearchlimit;
    await axios({ ...api, data: data })
      .then((res, err) => {
        if (err) message.error("Err wile fetching specialities");
        else {
          props?.setDoctorsList(res.data);
          setLoader(false);
          //   props.next()
        }
      })
      .catch((err) => {
        if (err) message.error("Err wile fetching specialities");
        setLoader(false);
      });
  };

  const selectSpeciality = (service) => {
    props.setSpeciality(service);
    let data = {
      localityname: props?.data?.llocalityname,
      // specialityid: service?.id || "000",
      // service_id: service?.id || "000",
      //   hospital_id: props?.hospital_id,
      dmrole: props?.data?.dmrole,
      service: props?.data?.dmrole,
      cityname: props?.data?.cityname,
      localitylat: props?.data?.llocalitylat,
      localitylong: props?.data?.llocalitylong,

      // cityname: "new delhi", // city name
      // dmrole: "DOC", // role
      // localityname: "Current Location",
      // localitylat: 28.5415026, // latitude
      // localitylong: 77.2934899, // longitude
      // specialityid: service?.id, // speciality id comming from getspeciality id
      queryoffset: 0, // query off set limit
      querylimit: 50, // query upper limit
      sortid: "-1", // default sorting id
      searchfilter: "-1", // default search filter
      dayshortname: "", // day name
      // service_id: "10053A",
      ...props?.data,
    };
    if (props?.data?.dmrole === "ONL" || props?.data?.dmrole === "DOC") {
      data = { ...data, specialityid: service?.id || "000" };
    } else {
      data = { ...data, service_id: service?.id || "000" };
    }
    // getDoctorsList(data)
    props?.handleFinish(data);
  };

  const handleScreenSelect = (key) => {
    props.setSpeciality(key);
    setLoader(true);
    let data = {
      // localityname: props?.localityname,
      // specialityid: key.mom_id,
      // dmrole: props?.data?.Role,
      // cityname: props.userDash?.userState?.value,
      // localitylat: props?.locality_lat,
      // localitylong: props?.locality_long,

      localityname: props?.data?.llocalityname || "llocality",
      // specialityid: service?.id || "000",
      // service_id: service?.id || "000",
      dmrole: props?.data?.dmrole || "VIT",
      cityname: props?.data?.cityname || "cn",
      localitylat: props?.data?.llocalitylat || "000000",
      localitylong: props?.data?.llocalitylong || "000000",
      specialityid: key?.data?.mom_id,
      querylimit: "50",
      queryoffset: "0",
      sortid: "0",
      searchfilter: "0",
      mom_mental_health_flag: key?.mom_mental_health_flag,
      ...props?.data,
    };
    getDoctorsList(data);
  };

  // useEffect(() => {
  //     const getS
  // },[])

  return (
    <div>
      <Card
        loading={props?.loadingSpec}
        bodyStyle={{ padding: "0px" }}
        bordered={false}
      >
        {props?.loaders?.searchL ? (
          <LoadingMask message="Loading Doctor..."></LoadingMask>
        ) : null}
        {props?.data?.dmrole === "VIT" ? (
          Object.keys(props.outcomePackages)?.map((key, i) => (
            <div
              key={i}
              onClick={() => handleScreenSelect(props?.outcomePackages[key])}
            >
              <ScreeningCard
                screen={props?.outcomePackages[key]}
              ></ScreeningCard>
            </div>
          ))
        ) : (
          <div className="logo-card-container">
            {props?.specialities?.map((service, i) => (
              <span key={i} onClick={() => selectSpeciality(service)}>
                <LogoCard
                  title={service.name}
                  logo={<UserOutlined />}
                ></LogoCard>
              </span>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
    selectedService: state.userDash.selectedService,
    specialityList: state.userDash.specialityList,
    selectedSpeciality: state.userDash.selectedSpeciality,
    outcomePackages: state.userDash.outcomePackages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpeciality: (data) => dispatch(dashboardAC.setSpeciality(data)),
    setDoctorsList: (list) => dispatch(dashboardAC.setDoctorsList(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialityList);
