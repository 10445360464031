import { Empty } from "antd";
import React from "react";
import DocPlanCard from "./DocPlanCard";

const AllDocPlanList = (props) => {
  return (
    <div className="row mx-0">
      {props?.allPlan?.length ? (
        props?.allPlan?.map((plan, i) => (
          <div
            className="col-sm-5 m-1"
            style={{ minWidth: "350px" }}
            key={plan?.lfeatureid + plan?.lplanid + Math.random()}
          >
            <DocPlanCard {...props} {...plan}></DocPlanCard>
          </div>
        ))
      ) : (
        <Empty description="No Plans Found" />
      )}
    </div>
  );
};

export default AllDocPlanList;
