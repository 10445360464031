import * as ActionTypes from "../../ActionTypes";

// specialities reducer
export const Specialities = (
  state = {
    isLoading: true,
    errMess: null,
    specialities: {},
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_SPECIALITIES:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        specialities: action.payload,
      };

    case ActionTypes.SPECIALITIES_LOADING:
      return { ...state, isLoading: true, errMess: null, specialities: {} };

    case ActionTypes.SPECIALITIES_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        specialities: {},
      };

    default:
      return state;
  }
};
