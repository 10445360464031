import React from "react";

const PageNotFound = () => (
  <div className="text-center p-4">
    <h1 className="text-danger">Ooops... Page Not Found</h1>
    <h2 className="text-secondary">404 Error</h2>
    <h5 className="text-secondary lead">
      Go To <a href="/">Home</a>
    </h5>
  </div>
);
export default PageNotFound;
