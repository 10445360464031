import React, { Component } from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Card, Col, Row } from "antd";
// import CardTitle from "reactstrap/lib/CardTitle";
import "../assets/styles/DoctorVerification.css";
import "../assets/styles/forms.scss";
import ContactForm from "./ContactForm";

class Contact extends Component {
  render() {
    return (
      <div className="site-card-wrapper">
        <div className="row mx-0 contact-card">
          <div className="px-4 col-md-6">
            <Card
              title={<div className="bg-primary text-light">Contact Us</div>}
              bordered={false}
              headStyle={{
                textAlign: "center",
                fontSize: "1.5rem",
                // backgroundColor: "#007BFF",
                // color: "#ffffff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                backgroundColor: "#007BFF",
                color: "#ffffff",
              }}
              bodyStyle={{ padding: "30px 0px" }}
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                borderBottom: "1px solid #f1f1f1",
                borderLeft: "1px solid #f1f1f1",
                borderRight: "1px solid #f1f1f1",
                fontSize: "1.2em",
                margin: "auto",
                textAlign: "center",
                // height: "100%",
              }}
            >
              <Row className="m-2 p-1">
                <Col span={7} className="p-0">
                  <i className="fas fa-map-marker-alt fa-1x" /> &nbsp;
                  <strong>Address</strong>:
                </Col>
                <Col span={17} className="pl-4 text-left">
                  <strong className="head-md">
                    Indian Institute of Management Lucknow, Noida Campus, B1,
                    Institutional Area, Sector 62, Noida – 201307 Uttar Pradesh,
                    India
                  </strong>
                </Col>
              </Row>
              <Row className="m-2 p-1">
                <Col span={7} className="p-0">
                  <strong className="head-sm">
                    <i className="fas fa-phone fa-1x" /> &nbsp; Phone: &nbsp;
                  </strong>
                </Col>
                <Col span={17} className="pl-4 text-left">
                  +91 11 41025512
                </Col>
              </Row>
              <Row className="m-2 p-1">
                <Col span={7} className="p-0">
                  <strong className="head-sm">
                    <i className="fas fa-envelope-square fa-1x" /> &nbsp; Email:
                    &nbsp;
                  </strong>
                </Col>
                <Col span={17} className="pl-4 text-left">
                  <a href="mailto:enquiry@janelaaj.com">enquiry@janelaaj.com</a>
                </Col>
              </Row>
              {/* <Row>
                Map
              </Row> */}
            </Card>
          </div>
          <div className="px-4 col-md-6">
            <Card
              title={<div>Share Your Query With Us! </div>}
              bordered={true}
              headStyle={{
                textAlign: "center",
                fontSize: "1.5rem",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                backgroundColor: "#007BFF",
                color: "#ffffff",
              }}
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <ContactForm></ContactForm>
            </Card>
          </div>
        </div>
      </div>
      // (
      //   <div className="container-fluid row">
      //     <div className="col-sm-6">FORM</div>
      //     <Card className="col-sm-6">
      //       <CardTitle> Contact Us </CardTitle>
      //       <CardBody className="ml-5">
      //         <p>
      //           <strong className="head-sm">
      //             <i className="fas fa-map-marker-alt fa-2x" /> &nbsp; Address:
      //             &nbsp;
      //           </strong>
      //           Indian Institute of Management Lucknow, Noida Campus, B1,
      //           Institutional Area, Sector 62, Noida – 201307 Uttar Pradesh,
      //           India
      //         </p>
      //         <p>
      //           <strong className="head-sm">
      //             <i className="fas fa-phone fa-2x" /> &nbsp; Phone: &nbsp;
      //           </strong>
      //           +91 1141025512
      //         </p>
      //         <p>
      //           <strong className="head-sm">
      //             <i className="fas fa-envelope-square fa-2x" /> &nbsp; Email:
      //             &nbsp;
      //           </strong>
      //           <a href="mailto:enquiry@janelaaj.com"> enquiry@janelaaj.com </a>
      //         </p>
      //       </CardBody>
      //     </Card>
      //   </div>
    );
  }
}

export default Contact;
