import { Card, Empty } from "antd";
import React from "react";
import PartnerClinicCard from "./intermediate/PartnerClinicCard";

const Partneroneview = (props) => {
  return (
    <div>
      <Card
        title={
          <div className="p-1" style={{ fontSize: "1.5rem" }}>
            All Clinics
          </div>
        }
      >
        {props.partnerData.partnerClinics.status === "SUCCESS" ? (
          props.partnerData.partnerClinics.locations.map((clinic, i) => (
            <div
              key={i + Math.random()}
              //   onClick={() => props?.setSelectedClinic(clinic)}
            >
              <PartnerClinicCard
                shouldSkip={true}
                clinic={clinic}
                history={props.history}
              ></PartnerClinicCard>
            </div>
          ))
        ) : (
          <Empty></Empty>
        )}
      </Card>
    </div>
  );
};

export default Partneroneview;
