import * as firebase from "firebase";
import "firebase/storage";
// const config = {
//   apiKey: "AIzaSyBu6l2iCTgVt9oOgbY3d3ufhy1YIGfB9As",
//   authDomain: "",
//   databaseURL: "https://userrealtimetest-default-rtdb.firebaseio.com/",
//   projectId: "janelaajuserapp",
//   storageBucket: "janelaajuserapp.appspot.com",
//   messagingSenderId: "",
//   appId: "1:768395179845:android:1303f1a353fcbfcd",
//   measurementId: "",
// };

var janElaajConfig = {
  apiKey: "AIzaSyC3alWfHhE_CliaRCI7oiGKLkCKNbrj_GE",
  authDomain: "jan-elaaj.firebaseapp.com",
  projectId: "jan-elaaj",
  // storageBucket: "jan-elaaj.appspot.com",
  storageBucket: "janelaajpublicbucket",
  messagingSenderId: "599589989285",
  appId: "1:599589989285:web:1bc2f5bbcb087abccf964c",
  measurementId: "G-ZL2VM23XRR",
};
// Init

const usrConfig = {
  apiKey: "AIzaSyBu6l2iCTgVt9oOgbY3d3ufhy1YIGfB9As",
  authDomain: "",
  databaseURL: "https://janelaajuserapp.firebaseio.com",
  projectId: "janelaajuserapp",
  storageBucket: "janelaajuserapp.appspot.com",
  // storageBucket: "janelaajuserapp.appspot.com/AddSymptomsImages",
  messagingSenderId: "",
  appId: "1:768395179845:android:1303f1a353fcbfcd",
  measurementId: "",
};

const emlVrfConfig = {
  apiKey: "AIzaSyCes5qTs-43uN9XokROLqHHGx-KVlIuv48",
  authDomain: "",
  databaseURL: "https://jeemailverification.firebaseio.com",
  projectId: "jeemailverification",
  storageBucket: "jeemailverification.appspot.com",
  messagingSenderId: "",
  appId: "1:116427726523:android:cadef498b06b0ab5",
  measurementId: "",
};

const usrRTDbConfig = {
  apiKey: "AIzaSyC1xeuD1MLdlASkaZgQKpAgZSaPHzSni1I",
  authDomain: "",
  databaseURL: "https://userrealtimetest-default-rtdb.firebaseio.com/",
  projectId: "userrealtimetest",
  storageBucket: "userrealtimetest.appspot.com",
  messagingSenderId: "",
  appId: "1:383374520015:android:f7c5cc2a3a65e83cab91ba",
  measurementId: "",
};

const app = firebase.initializeApp(usrConfig);
const partnerFIRApp = firebase.initializeApp(emlVrfConfig, "secondary");
const janElaaj = firebase.initializeApp(janElaajConfig, "third");

app.auth().useDeviceLanguage();

// firebase.analytics();

// const googleProvider = new firebase.auth.GoogleAuthProvider();
// const facebookProvider = new firebase.auth.FacebookAuthProvider();
// const twitterProvider = new firebase.auth.TwitterAuthProvider();
// const githubProvider = new firebase.auth.GithubAuthProvider();
const db = firebase.firestore();
const userAppStorage = app.storage();
const janElaajStorage = janElaaj.storage();
export {
  firebase,
  emlVrfConfig,
  //   googleProvider,
  //   facebookProvider,
  //   twitterProvider,
  //   githubProvider,
  db,
  usrConfig,
  partnerFIRApp,
  usrRTDbConfig,
  userAppStorage,
  janElaajStorage,
};
export default app;
