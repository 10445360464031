import * as ActionTypes from "../../ActionTypes";

// docinfo reducer
export const DocsInfo = (
  state = {
    isLoading: true,
    errMess: null,
    docsinfo: {},
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_DOCSINFO:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        docsinfo: action.payload,
      };

    case ActionTypes.DOCSINFO_LOADING:
      return { ...state, isLoading: true, errMess: null, docsinfo: {} };

    case ActionTypes.DOCSINFO_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        docsinfo: {},
      };

    default:
      return state;
  }
};
