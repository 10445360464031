// import { Card } from "antd";
import { Input, Space, Form, Modal, Button, message, Col, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "../shared/apis";
import Dictaphone from "./Dictaphone";
import LoadingMask from "./LoaderMask/LoadingMask";
import PrescriptionFormPreview from "./PrescriptionFormPreview";
import {
  EyeOutlined,
  VerticalAlignTopOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const PrescriptionForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(
    props?.isPreviewVisible
  );
  const [details, setDetails] = useState({ timeslot: props?.timeslot });
  const [patHistory, setPatHistory] = useState("");
  const [sugTests, setSugTests] = useState("");
  const [medication, setMedication] = useState("");
  const [observation, setObservation] = useState("");
  const [other, setOther] = useState("");
  const [OTP, setOTP] = useState("");
  const [isOTPValid, setIsOTPValid] = useState(false);
  let previewProps = {
    details: details,
    patient_history: patHistory,
    suggested_test: sugTests,
    medication: medication,
    observation: observation,
    other: other,
  };

  const isJitsiPage = window.location.pathname?.includes("jitsi");

  const handleUploadDetails = async () => {
    setIsUploading(true);
    let obj = {
      pdla_patient_history: patHistory,
      pdla_prescription_suggested_clinical_test: sugTests,
      pdla_prescription_doctors_observation: observation,
      pdla_prescription_medication: medication,
      pdla_prescription_others: other,
      pdla_id: props?.pdla_id,
    };
    await axios({ ...apis.postUpdatePrescriptionDetails, data: obj })
      .then((res, err) => {
        // if (err) console.log("Error while Uploading... ", err);
        // else {
        setIsUploading(false);
        message.success("Prescription Uploaded Successfully !");
        // }
      })
      .catch((err) => {
        // console.log("Error in Uploading: ", err);
        setIsUploading(false);
        message.error("Error in Uploading: ", err?.message);
      });
  };
  const handleOTP = () => {
    if (OTP === props.location.state?.data?.otp) {
      message.success("Valid OTP");
      setIsOTPValid(true);
    } else {
      message.error("Invalid OTP");
      setIsOTPValid(false);
    }
  };
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let obj = {
        pdla_id: props?.pdla_id,
      };
      await axios({ ...apis.postOnlinePrescriptionEntries, data: obj })
        .then((res, err) => {
          setIsLoading(false);
          // if (err) console.log("error: ", err);
          // else {
          // console.log("Res: ", res);
          setDetails({ ...res.data?.info[0], timeslot: props?.timeslot });
          setPatHistory(res.data?.info[0]?.patient_history);
          setSugTests(res.data?.info[0]?.suggested_test);
          setMedication(res.data?.info[0]?.medication);
          setObservation(res.data?.info[0]?.observation);
          setOther(res.data?.info[0]?.other);
          // }
        })
        .catch((err) => {
          setIsLoading(false);
          // if (err) console.log("Error: ", err);
        });
    };
    setIsPreviewVisible(props.isPreviewVisible);
    if (Object.keys(details).length === 1) getData();
  }, [details, props.isPreviewVisible, props?.pdla_id, props?.timeslot]);

  return (
    <div>
      {isLoading ? (
        <LoadingMask message={"Loading..."}></LoadingMask>
      ) : (
        <div>
          <div className="row mr-0">
            <div className="doc-details-container col-sm-6 ">
              <table
                className="table table-responsive"
                style={{
                  margin: "0 0 0 auto",
                  width: "fit-content",
                }}
              >
                <tbody>
                  <tr className="atr-val-container">
                    <th className="doc-attribute">Name: </th>
                    <td className="doc-value">{details?.docName}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="doc-attribute">Registration No: </th>
                    <td className="doc-value">{details?.docRegistrationNo}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="doc-attribute">Contact No: </th>
                    <td className="doc-value">{details?.docContact}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="doc-attribute">Email: </th>
                    <td className="doc-value">{details?.docEmail}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="doc-attribute">Clinic: </th>
                    <td className="doc-value">{details?.docClinic}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="foot-atr-val-container">
                    <th className="foot-attribute">Appointment Date: </th>
                    <td className="foot-value">{details?.appointmentDate}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div
              className="pat-details-container col-sm-6"
              style={{ borderLeft: "1px solid #f1f1f1" }}
            >
              <table
                className="table table-responsive"
                style={{
                  margin: "0 auto 0 0",
                  width: "fit-content",
                }}
              >
                <tbody>
                  <tr className="atr-val-container">
                    <th className="pat-attribute">Patient Name: </th>
                    <td className="pat-value">{details?.pm_patient_name}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="pat-attribute">Patient Contact No: </th>
                    <td className="pat-value">{details?.patient_contact}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="pat-attribute">Patient Id: </th>
                    <td className="pat-value">{details?.patient_id}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="pat-attribute">Patient Age: </th>
                    <td className="pat-value">{details?.patientAge}</td>
                  </tr>
                  <tr className="atr-val-container">
                    <th className="pat-attribute">Patient Gender: </th>
                    <td className="pat-value">{details?.pm_gender}</td>
                  </tr>
                  {/* <tr className="atr-val-container">
                    <th className="pat-attribute">Appointment Date: </th>
                    <td className="pat-value">{details?.appointmentDate}</td>
                  </tr> */}
                  {/* <tr className="atr-val-container">
                      <th className="pat-attribute">Time Slot: </th>
                      <td className="pat-value">{details?.timeslot}</td>
                    </tr> */}
                </tbody>
                <tfoot>
                  <tr className="foot-atr-val-container">
                    <th className="foot-attribute">Time Slot: </th>
                    <td className="foot-value">{details?.timeslot}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <hr />
          <Space
            direction="vertical"
            style={{ width: "100%", padding: "0rem " }}
          >
            {isJitsiPage ? (
              isOTPValid ? (
                <Form layout="vertical">
                  <Form.Item
                    label="Patients's History"
                    tooltip="Write down the previous health issues(if any) of the Patient"
                  >
                    <Row>
                      <Col xs={23}>
                        <Input.TextArea
                          placeholder="Write down the previous health issues(if any) of the Patient"
                          value={patHistory}
                          name="patHistory"
                          onChange={(e) => setPatHistory(e.target.value)}
                          size="large"
                          allowClear
                          autoSize={true}
                          showCount={true}
                          maxLength={500}
                          onPressEnter={(e) => {
                            setPatHistory(e.target.value + ",");
                          }}
                        ></Input.TextArea>
                      </Col>
                      <Col
                        xs={1}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Dictaphone
                          message={patHistory}
                          setMessage={setPatHistory}
                          key="patHistory"
                        ></Dictaphone>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    label="Suggested Tests"
                    tooltip="Mention the clinical tests that are required"
                  >
                    <Row>
                      <Col xs={23}>
                        <Input.TextArea
                          placeholder="Mention the clinical tests that are required (if any)"
                          value={sugTests}
                          name="sugTests"
                          onChange={(e) => setSugTests(e.target.value)}
                          size="large"
                          allowClear
                          autoSize={true}
                          showCount={true}
                          maxLength={500}
                          onPressEnter={(e) => {
                            setSugTests(e.target.value + ",");
                          }}
                        ></Input.TextArea>
                      </Col>
                      <Col
                        xs={1}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Dictaphone
                          message={sugTests}
                          setMessage={setSugTests}
                          key="sugTests"
                        ></Dictaphone>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label="Medication" tooltip="Medicine1 1-1-1 xdays">
                    <Row>
                      <Col xs={23}>
                        <Input.TextArea
                          placeholder={`Medicine1 1-1-1 xdays  Medicine2 1-1-1 xdays`}
                          value={medication}
                          name="medication"
                          onChange={(e) => setMedication(e.target.value)}
                          size="large"
                          allowClear
                          autoSize={true}
                          showCount={true}
                          maxLength={500}
                          onPressEnter={(e) => {
                            setMedication(e.target.value + ",");
                          }}
                        ></Input.TextArea>
                      </Col>
                      <Col
                        xs={1}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Dictaphone
                          key="medication"
                          message={medication}
                          setMessage={setMedication}
                        ></Dictaphone>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    label="Observation"
                    tooltip="Write down your diagnosis"
                  >
                    <Row>
                      <Col xs={23}>
                        <Input.TextArea
                          placeholder="Write down your diagnosis"
                          value={observation}
                          name="observation"
                          onChange={(e) => setObservation(e.target.value)}
                          size="large"
                          allowClear
                          autoSize={true}
                          showCount={true}
                          maxLength={500}
                          onPressEnter={(e) => {
                            setObservation(e.target.value + ",");
                          }}
                        ></Input.TextArea>
                      </Col>
                      <Col
                        xs={1}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Dictaphone
                          key="observation"
                          message={observation}
                          setMessage={setObservation}
                        ></Dictaphone>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    label="Other"
                    tooltip="Write down additive instructions(if any)"
                  >
                    <Row>
                      <Col xs={23}>
                        <Input.TextArea
                          placeholder="Write down additive instructions(if any)"
                          value={other}
                          name="other"
                          onChange={(e) => setOther(e.target.value)}
                          size="large"
                          allowClear
                          autoSize={true}
                          showCount={true}
                          maxLength={500}
                          onPressEnter={(e) => {
                            setOther(e.target.value + ",");
                          }}
                        ></Input.TextArea>
                      </Col>
                      <Col
                        xs={1}
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Dictaphone
                          key="other"
                          message={other}
                          setMessage={setOther}
                        ></Dictaphone>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              ) : (
                <div className="m-2">
                  <Input
                    placeholder="Enter OTP"
                    value={OTP}
                    onChange={(e) => setOTP(e.target.value)}
                    onBlur={handleOTP}
                    addonBefore={
                      isOTPValid ? (
                        <CheckCircleOutlined
                          className="text-success"
                          style={{ display: "inline-flex" }}
                        />
                      ) : (
                        <ExclamationCircleOutlined
                          className="text-danger"
                          style={{ display: "inline-flex" }}
                        />
                      )
                    }
                    suffix={<Button type="link">Check</Button>}
                    size="large"
                  ></Input>
                </div>
              )
            ) : (
              <Form layout="vertical">
                <Form.Item
                  label="Patients's History"
                  tooltip="Write down the previous health issues(if any) of the Patient"
                >
                  <Row>
                    <Col xs={23}>
                      <Input.TextArea
                        placeholder="Write down the previous health issues(if any) of the Patient"
                        value={patHistory}
                        name="patHistory"
                        onChange={(e) => setPatHistory(e.target.value)}
                        size="large"
                        allowClear
                        autoSize={true}
                        showCount={true}
                        maxLength={500}
                        onPressEnter={(e) => {
                          setPatHistory(e.target.value + ",");
                        }}
                      ></Input.TextArea>
                    </Col>
                    <Col
                      xs={1}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Dictaphone
                        message={patHistory}
                        setMessage={setPatHistory}
                        key="patHistory"
                      ></Dictaphone>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  label="Suggested Tests"
                  tooltip="Mention the clinical tests that are required"
                >
                  <Row>
                    <Col xs={23}>
                      <Input.TextArea
                        placeholder="Mention the clinical tests that are required (if any)"
                        value={sugTests}
                        name="sugTests"
                        onChange={(e) => setSugTests(e.target.value)}
                        size="large"
                        allowClear
                        autoSize={true}
                        showCount={true}
                        maxLength={500}
                        onPressEnter={(e) => {
                          setSugTests(e.target.value + ",");
                        }}
                      ></Input.TextArea>
                    </Col>
                    <Col
                      xs={1}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Dictaphone
                        message={sugTests}
                        setMessage={setSugTests}
                        key="sugTests"
                      ></Dictaphone>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Medication" tooltip="Medicine1 1-1-1 xdays">
                  <Row>
                    <Col xs={23}>
                      <Input.TextArea
                        placeholder={`Medicine1 1-1-1 xdays  Medicine2 1-1-1 xdays`}
                        value={medication}
                        name="medication"
                        onChange={(e) => setMedication(e.target.value)}
                        size="large"
                        allowClear
                        autoSize={true}
                        showCount={true}
                        maxLength={500}
                        onPressEnter={(e) => {
                          setMedication(e.target.value + ",");
                        }}
                      ></Input.TextArea>
                    </Col>
                    <Col
                      xs={1}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Dictaphone
                        key="medication"
                        message={medication}
                        setMessage={setMedication}
                      ></Dictaphone>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  label="Observation"
                  tooltip="Write down your diagnosis"
                >
                  <Row>
                    <Col xs={23}>
                      <Input.TextArea
                        placeholder="Write down your diagnosis"
                        value={observation}
                        name="observation"
                        onChange={(e) => setObservation(e.target.value)}
                        size="large"
                        allowClear
                        autoSize={true}
                        showCount={true}
                        maxLength={500}
                        onPressEnter={(e) => {
                          setObservation(e.target.value + ",");
                        }}
                      ></Input.TextArea>
                    </Col>
                    <Col
                      xs={1}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Dictaphone
                        key="observation"
                        message={observation}
                        setMessage={setObservation}
                      ></Dictaphone>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  label="Other"
                  tooltip="Write down additive instructions(if any)"
                >
                  <Row>
                    <Col xs={23}>
                      <Input.TextArea
                        placeholder="Write down additive instructions(if any)"
                        value={other}
                        name="other"
                        onChange={(e) => setOther(e.target.value)}
                        size="large"
                        allowClear
                        autoSize={true}
                        showCount={true}
                        maxLength={500}
                        onPressEnter={(e) => {
                          setOther(e.target.value + ",");
                        }}
                      ></Input.TextArea>
                    </Col>
                    <Col
                      xs={1}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Dictaphone
                        key="other"
                        message={other}
                        setMessage={setOther}
                      ></Dictaphone>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            )}
          </Space>
          <Space style={{ padding: "0rem 2rem" }}>
            <Button
              onClick={() => props.setIsPreviewVisible(true)}
              icon={<EyeOutlined style={{ display: "inline-flex" }} />}
              type="primary"
              key="preview"
              disabled={isJitsiPage ? !isOTPValid : false}
            >
              Preview
            </Button>
            <Button
              onClick={() => {
                handleUploadDetails();
              }}
              icon={
                <VerticalAlignTopOutlined style={{ display: "inline-flex" }} />
              }
              type="primary"
              key="upload"
              loading={isUploading}
              disabled={isJitsiPage ? !isOTPValid : false}
            >
              {isUploading ? "Uploading..." : " Upload"}
            </Button>
            {isJitsiPage ? (
              <Button
                onClick={() => {
                  props?.history?.push("/choose-option/appointments");
                }}
                type="primary"
                key="dash"
                shape="round"
                className="mx-2"
              >
                Go back to Dashboard
              </Button>
            ) : null}
          </Space>
        </div>
      )}
      <Modal
        centered
        visible={isPreviewVisible}
        onCancel={() => {
          setIsPreviewVisible(false);
          props.setIsPreviewVisible(false);
        }}
        width={900}
        footer={[
          <Button
            onClick={() => {
              setIsPreviewVisible(false);
              props.setIsPreviewVisible(false);
            }}
            type="primary"
            key="close"
          >
            Close
          </Button>,
        ]}
      >
        <PrescriptionFormPreview {...previewProps}></PrescriptionFormPreview>
      </Modal>
    </div>
  );
};

export default PrescriptionForm;
