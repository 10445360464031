import React, { useState } from "react";
import { Input, Row, Col, Checkbox, Button, Tag, message } from "antd";
import axios from "axios";
import { apis } from "../shared/apis";

const EditLangForm = (props) => {
  const [filterList, setFilterList] = useState([]);
  const [langList, setLangList] = useState(props?.langList);
  const [selectedLangList, setSelectedLangList] = useState(
    props?.selectedLangList
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const handleSearch = (e) => {
    setSearchQuery(e);
    let temp = [];
    temp = langList?.filter((lan) =>
      lan?.lmname?.toUpperCase()?.includes(e?.toUpperCase())
    );

    setFilterList([...temp]);
  };
  const updateFilterList = (newL) => {
    let temp = [];
    temp = newL?.filter((lan) =>
      lan?.lmname?.toUpperCase()?.includes(searchQuery?.toUpperCase())
    );

    setFilterList([...temp]);
  };

  const submitHandler = async () => {
    const input = {
      dlm_id: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
      info: langList,
    };
    setLoader(true);
    axios({ ...apis.postAddLanguagesDoctor, data: input })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        else {
          message.success("Changes Saved Successfully!");
          props?.closeForm();
          props?.setSelectedLangList(selectedLangList);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  return (
    <div>
      <div className="mb-2">
        <Input.Search
          size="large"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search Languages Here"
          allowClear={true}
        ></Input.Search>
      </div>
      {searchQuery?.length ? (
        <div>
          <Row
            style={{
              maxHeight: "22vh",
              overflowX: "hidden",
              overflowY: "scroll",
              // padding: "10px ",
            }}
            className="my-3"
          >
            {filterList?.map((lang, i) => (
              <Col
                span={12}
                style={{
                  whiteSpace: "nowrap",
                }}
                className="my-1 py-1"
                key={lang?.lmid}
              >
                <Checkbox
                  onChange={(e) => {
                    let temp = [];
                    langList.forEach((l, i) => {
                      if (l?.lmid === e?.target?.value?.lmid) {
                        temp.push({
                          ...e?.target?.value,
                          lSelected: e.target?.checked ? "Y" : "N",
                        });
                      } else {
                        temp.push(l);
                      }
                    });
                    setLangList([...temp]);
                    let sLangs = temp.filter((l) => l?.lSelected === "Y");
                    setSelectedLangList([...sLangs]);
                    updateFilterList(temp);
                  }}
                  value={{ ...lang }}
                  checked={lang?.lSelected === "Y"}
                  style={{ textOverflow: "ellipsis" }}
                >
                  {lang?.lmname}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
      <div>
        {selectedLangList?.map((lan, i) => (
          <Tag.CheckableTag
            checked={true}
            key={i + Math.random()}
            shape="round"
            color="success"
            className="my-1 p-1 px-2"
            style={{ fontSize: "0.9rem" }}
          >
            {lan?.lmname}{" "}
          </Tag.CheckableTag>
        ))}
      </div>
      <div>
        <Row
          style={{
            maxHeight: "50vh",
            overflowX: "hidden",
            overflowY: "scroll",
            // padding: "10px ",
          }}
          className="my-3"
        >
          {langList?.map((lang, i) => (
            <Col
              span={12}
              style={{
                whiteSpace: "nowrap",
              }}
              className="my-1 py-1"
              key={lang?.lmid}
            >
              <Checkbox
                onChange={(e) => {
                  let temp = [];
                  langList.forEach((l, i) => {
                    if (l?.lmid === e?.target?.value?.lmid) {
                      temp.push({
                        ...e?.target?.value,
                        lSelected: e.target?.checked ? "Y" : "N",
                      });
                    } else {
                      temp.push(l);
                    }
                  });
                  setLangList([...temp]);
                  let sLangs = temp.filter((l) => l?.lSelected === "Y");
                  setSelectedLangList([...sLangs]);
                }}
                value={{ ...lang }}
                checked={lang?.lSelected === "Y"}
                style={{ textOverflow: "ellipsis" }}
              >
                {lang?.lmname}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="m-1"
      >
        <Button
          type="primary"
          size="large"
          shape="round"
          onClick={submitHandler}
          loading={loader}
        >
          {loader ? "Saving..." : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default EditLangForm;
