import React from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import {
  QuestionCircleFilled,
  StarFilled,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import QuestionCard from "./QuestionCard";
import MyPosts from "./MyPosts";
import AllPosts from "./AllPosts";

const { TabPane } = Tabs;

const HashTagsTabs = (props) => {
  return (
    <div>
      <Tabs centered>
        <TabPane
          key="one_min"
          tab={
            <span
              className="d-flex text-center"
              style={{ alignItems: "center" }}
            >
              <QuestionCircleFilled />
              One Min
            </span>
          }
        >
          {props?.oneMins?.isLoading ? (
            "Loading..."
          ) : props?.tag ? (
            <QuestionCard
              questions={props.oneMins.value[props?.tag]}
            ></QuestionCard>
          ) : (
            props?.tag
          )}
        </TabPane>
        <TabPane
          key="all_posts"
          tab={
            <span
              className="d-flex text-center"
              style={{ alignItems: "center" }}
            >
              <StarFilled /> All Posts
            </span>
          }
        >
          <div
          // style={{ height: '100vh' }}
          >
            <AllPosts tag={props?.tag}></AllPosts>
          </div>
        </TabPane>
        <TabPane
          key="my_posts"
          tab={
            <span
              className="d-flex text-center"
              style={{ alignItems: "center", justifyContent: "space-around" }}
            >
              <UserOutlined /> My Posts
            </span>
          }
        >
          <MyPosts tag={props?.tag}></MyPosts>
        </TabPane>
        <TabPane
          key="expert_advice"
          tab={
            <span
              className="d-flex text-center"
              style={{ alignItems: "center" }}
            >
              <UsergroupAddOutlined /> Expert Advice
            </span>
          }
        >
          {props?.expertTips?.isLoading ? (
            "Loading..."
          ) : props?.tag ? (
            <QuestionCard
              questions={props?.expertTips?.value[props?.tag]}
            ></QuestionCard>
          ) : (
            props?.tag
          )}
          {/* <AppInviteCard></AppInviteCard> */}
        </TabPane>
      </Tabs>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    hashTags: state.hashTags,
    oneMins: state.hashTags.OneMin,
    expertTips: state.hashTags.expertTips,
  };
};
export default connect(mapStateToProps)(HashTagsTabs);
