import React from "react";
import { homeStyles as styles } from "../../assets/styles/homeStyles";
import qrCode from "../../assets/images/qrCode.PNG";

function AppInviteCard(props) {
  return (
    <div className="md-6" style={styles.container}>
      <h3 style={{ ...styles.heading }}>
        {props?.title ? props?.title : "Jan Elaaj User App"}
      </h3>

      <p style={styles.text}>Neighbourhood treatment is one click away</p>
      <p style={styles.text}>
        Search and see the details of the healthcare services and providers
        based on location, speciality, ratings, experience, rates, discounts etc
      </p>
      <p style={styles.text}>
        Make an educated decision and then book an appointment.
      </p>
      {/*<img src='/images/ios.png' alt="App Store App Link" width="30%" />*/}
      <br />
      <a
        href="https://play.google.com/store/apps/details?id=clinic.janelaaj.janelaajuserapp&hl=en"
        target="__blank"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "30%", display: "flex", alignItems: "center" }}>
          <img
            src="/images/app/android.png"
            alt="Playstore App Link"
            // className="mb-4"
            width="100%"
          />
        </div>
        <div
          style={{
            // backgroundImage: "url(/images/app/mobile.png)",
            // backgroundSize: "contain",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "0% 100%",
            width: "80px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img style={{ width: "100%" }} src={qrCode} alt="QRCODE"></img>
        </div>
      </a>

      {/* <Form inline>
									<FormGroup>
										<Input type="text" />
									</FormGroup>
									{' '}{' '}
									<NavLink style={{ ...styles.text, ...loginButton }}>Stay in touch</NavLink>
								</Form> */}
    </div>
  );
}

export default AppInviteCard;
