export const styles = {
  cardHeader: {
    fontSize: 30,
    marginBottom: 50,
    paddingTop: 20,
    paddingBottom: 20,
    borderColor: "#7199ab",
    borderWidth: 2,
  },

  card1Input: {
    // borderColor: "#7199ab",
    border: "none",
    borderBottom: "1px solid #F1F1F1",
    // borderRadius: 10,
    marginBottom: 20,
  },

  card1Label: {
    marginTop: 8,
    // marginRight: -100,
    marginLeft: 10,
  },

  adjust: {
    marginTop: 5,
    marginRight: -150,
    marginLeft: 10,
  },

  card2Input: {
    borderColor: "#7199ab",
    borderWidth: 2,
    borderRadius: 10,
    marginLeft: 10,
  },

  card2Label: {
    marginTop: 5,
    marginLeft: 15,
  },

  card3Input: {
    borderColor: "#91736b",
    borderWidth: 2,
    borderRadius: 10,
    marginLeft: 15,
  },

  card4Input: {
    borderColor: "#7199ab",
    borderWidth: 2,
    borderRadius: 10,
    // marginLeft: -30
  },

  card4Label: {
    marginTop: 5,
    marginRight: -50,
  },

  docStyle: {
    width: "50vw",
    background: "linear-gradient(#fdfbfb, #ebedee)",
    color: "#555760",
    fontWeight: 700,
    fontSize: "1.2em",
    borderColor: "white",
    // borderRadius: 5,
    margin: 5,
  },
};
