import React, { useEffect, useState } from "react";
import {
  Divider,
  Image,
  Modal,
  Form,
  message,
  Input,
  DatePicker,
  Select,
  Button,
  Upload,
  Steps,
} from "antd";

import {
  EditOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { apis } from "../shared/apis";
import CardHolderFrame from "./containers/CardHolderFrame";
import moment from "moment";
import EditLangForm from "./EditLangForm";
import LoadingMask from "./LoaderMask/LoadingMask";
import { isBrowser } from "react-device-detect";
const { Step } = Steps;
const DocProfileForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const [initVal, setInitVal] = useState({});
  const [loaderL, setLoaderL] = useState(false);
  const [langList, setLangList] = useState([]);
  const [selectedLangList, setSelectedLangList] = useState([]);
  const [showLangForm, setShowLangForm] = useState(false);

  let i64 = "";

  const getbString = (string) => {
    if (string) {
      // let i = string.indexOf("64");
      // .slice(i + 2, string.length - 1);
      string = "data:image/png;base64," + string;
    }
    return string;
  };

  const removeBase = (string) => {
    if (string) {
      let i = string.indexOf("64");
      //
      let sliced = string.slice(i + 3, string.length - 1);
      return sliced;
    }
    return string;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setLoader(true);
      await axios({
        ...apis.allinformation,
        data: {
          docid: JSON.parse(localStorage.getItem("selectedRole"))?.roleid,
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something went Wrong");
          // console.log("Res DP: ", res);
          setInitVal({
            ...res.data,
            image: getbString(res.data.image),
            docemail: localStorage.getItem("partnerEmail"),
            docphone: JSON.parse(localStorage.getItem("partnerPhone")),
            docdob: new moment(res.data.docdob),
            docgender:
              res.data.docgender === "M"
                ? { label: "Male", value: "M", key: "1" }
                : { label: "Female", value: "F", key: "2" },
          });
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something went Wrong");
        });
    };
    const fetchLangs = async () => {
      const input = {
        dlm_id: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid || "",
      };
      setLoaderL(true);
      await axios({ ...apis?.getLanguages, data: input })
        .then((res, err) => {
          setLoaderL(false);
          if (err) {
            message.error("Something Went Wrong");
          } else {
            setLangList(res?.data?.info);
            let sLangs = res?.data?.info.filter((l) => l?.lSelected === "Y");
            setSelectedLangList([...sLangs]);
          }
        })
        .catch((err) => {
          setLoaderL(false);
          if (err) {
            message.error("Something Went Wrong");
          }
        });
    };
    fetchProfile();
    fetchLangs();
  }, []);
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/choose-option",
      pathName: "Service List",
    },
    {
      path: "/doc-profile",
      pathName: "Profile",
    },
  ];
  const holderProps = {
    routes: routes,
    title: "My Profile",
    loader: loader,
  };
  const handleFinish = async (data) => {
    // console.log('data : ', data)
    // if (!initVal.image && !i64) message.error("Please Upload Image");
    // else {
    const input = {
      docid: JSON.parse(localStorage.getItem("selectedRole"))?.roleid,
      introduction: data["introduction"],
      work_history: data["workhistory"],
      reason_to_select: data["reasontoselect"],
      specialization: data["specialization"],
      work_experience: data["workexperience"],
      experience: data["experience"],
      image: i64 || removeBase(initVal?.image), //base64 image
      account_number: data["dm_doctor_bank_account_no"],
      beneficiary_name: data["dm_doctor_bank_beneficiary_name"],
      ifsc_code: data["dm_doctor_bank_IFSC_code"],
    };

    setFinishing(true);
    await axios({
      ...apis.updateintroduction,
      data: {
        ...input,
      },
    })
      .then((res, err) => {
        setFinishing(false);
        if (err) message.error("Something Went Wrong");
        else message.success("Saved Successfully!");
        // console.log("ResDP: ", res);
      })
      .catch((err) => {
        setFinishing(false);
        message.error("Something Went Wrong");
      });
    // }
  };

  const customRequestID = (option) => {
    const formData = new FormData();
    formData.append("files[]", option.file);
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function (e) {
      i64 = e.target.result;
      i64 = i64.slice(22, i64.length);
      if (e && e.target && e.target.result) {
        option.onSuccess();
      }
    };
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Only upload JPG or PNG files!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image size must be less than 2MB!");
      return false;
    }
    return isJpgOrPng && isLt2M;
  };
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };
  const steps = [
    { title: "Add Language", id: "add-lang" },
    { title: "Add Professional Details", id: "add-pd" },
    { title: "Add Bank Details", id: "add-bd" },
  ];
  return (
    <CardHolderFrame {...holderProps} {...props}>
      {!Object.keys(initVal)?.length ? (
        <LoadingMask></LoadingMask>
      ) : (
        <Form
          shouldUpdate={true}
          {...layout}
          initialValues={initVal}
          size="large"
          onFinish={handleFinish}
        >
          <Steps direction={isBrowser ? "horizontal" : "vertical"}>
            {steps.map((step) => (
              <Step
                icon={<PlusCircleOutlined />}
                title={<div className="">{step.title}</div>}
                key={step.id}
                onClick={() => {
                  document
                    .getElementById(step.id)
                    .scrollIntoView({ behavior: "smooth" });
                }}
                style={{ cursor: "pointer" }}
                disabled={true}
                status="process"
              ></Step>
            ))}
          </Steps>
          <Divider>Personal Details</Divider>
          <Form.Item name="docname" label={<div>Name</div>} shouldUpdate={true}>
            <Input disabled={true} className="text-dark" />
          </Form.Item>
          {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="row mx-0"
        > */}
          <Form.Item
            // className="col-sm-6 p-0"
            name="docdob"
            label={<div>DOB</div>}
            style={{ width: "100%" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              disabled={true}
              className="text-dark"
            ></DatePicker>
          </Form.Item>
          <Form.Item
            // labelCol={{ span: 10 }}
            // className="col-sm-6 p-0"
            name="docgender"
            label={<div>Gender</div>}
            style={{ width: "100%" }}
          >
            <Select
              disabled={true}
              className="text-dark"
              labelInValue
              options={[
                { label: "Male", value: "M", key: "1" },
                { label: "Female", value: "F", key: "2" },
              ]}
            ></Select>
          </Form.Item>
          {/* </div> */}
          {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="row mx-0"
        > */}
          <Form.Item
            // labelCol={{ span: 10 }}
            name="docphone"
            // className="col-sm-6 p-0"
            label={<div>Phone</div>}
            style={{ width: "100%" }}
          >
            <Input disabled={true} className="text-dark" />
          </Form.Item>
          <Form.Item
            // labelCol={{ span: 10 }}
            name="docemail"
            // className="col-sm-6 p-0"
            label={<div>Email</div>}
            style={{ width: "100%" }}
          >
            <Input disabled={true} className="text-dark" />
          </Form.Item>
          {/* </div> */}
          <Form.Item
            name="docspecialityname"
            label={<div>Speciality</div>}
            //   style={{ width: "100%" }}
          >
            <Input disabled={true} className="text-dark" />
          </Form.Item>
          <div id="add-lang">
            <Form.Item
              name="image"
              valuePropName="file"
              label={<div>Upload Image</div>}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {initVal.image ? (
                  <div className="p-1 m-1">
                    <Image
                      src={initVal.image}
                      alt="pp"
                      style={{ width: "100px" }}
                    ></Image>
                  </div>
                ) : null}
                <Upload
                  maxCount={1}
                  listType="picture-card"
                  customRequest={customRequestID}
                  beforeUpload={beforeUpload}
                  onPreview={(d) => {
                    Modal.info({
                      centered: true,
                      bodyStyle: { width: "100%" },
                      content: (
                        <Image
                          src={d.thumbUrl}
                          alt="Imahe"
                          style={{ width: "100%" }}
                          preview={false}
                        />
                      ),
                    });
                  }}
                  onRemove={() => {
                    i64 = initVal.image || "";
                  }}
                >
                  <div style={{ fontSize: "1.5rem", color: "gray" }}>+</div>
                </Upload>
              </div>
            </Form.Item>
          </div>

          <Form.Item label={"Languages Spoken :"}>
            {loaderL ? (
              "Loading..."
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="row mx-0"
              >
                <div className="col-sm-10">
                  {selectedLangList.length ? (
                    selectedLangList?.map((lan, i) => (
                      <span className="lead mr-1" key={lan?.lmid}>
                        {lan?.lmname}
                        {i === selectedLangList?.length - 1 ? ". " : ", "}
                      </span>
                    ))
                  ) : (
                    <div>
                      <Button
                        icon={
                          <PlusOutlined style={{ display: "inline-flex" }} />
                        }
                        type="primary"
                        shape="round"
                        size="small"
                        onClick={() => {
                          setShowLangForm(true);
                        }}
                      >
                        Add One
                      </Button>
                    </div>
                  )}
                </div>
                {selectedLangList.length ? (
                  <div>
                    <Button
                      icon={<EditOutlined style={{ display: "inline-flex" }} />}
                      type="primary"
                      shape="round"
                      onClick={() => {
                        setShowLangForm(true);
                      }}
                    >
                      Edit{" "}
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </Form.Item>

          <Divider id="add-pd">Prefessional Details</Divider>
          <Form.Item name="introduction" label={<div>About</div>}>
            <Input.TextArea autoSize />
          </Form.Item>
          <Form.Item name="specialization" label={<div>Specialization</div>}>
            <Input />
          </Form.Item>
          <Form.Item name="experience" label={<div>Experience</div>}>
            <Input />
          </Form.Item>
          <Form.Item name="workhistory" label={<div>Work History</div>}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="reasontoselect"
            label={<div>Why you should be selected</div>}
          >
            <Input.TextArea />
          </Form.Item>
          <Divider id="add-bd">Bank Details</Divider>
          <Form.Item
            name="dm_doctor_bank_beneficiary_name"
            label={<div>Beneficiary Name</div>}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="dm_doctor_bank_account_no"
            label={<div>Account No</div>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dm_doctor_bank_IFSC_code"
            label={<div>IFSC code</div>}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={finishing}
            >
              {finishing ? "Saving..." : "Save"}
            </Button>
          </Form.Item>
        </Form>
      )}
      <Modal
        visible={showLangForm}
        title={<div>Add / Remove Languages Spoken</div>}
        centered
        onCancel={() => setShowLangForm(false)}
        footer={[]}
      >
        <EditLangForm
          langList={langList}
          selectedLangList={selectedLangList}
          closeForm={() => {
            setShowLangForm(false);
          }}
          setSelectedLangList={setSelectedLangList}
        ></EditLangForm>
      </Modal>
    </CardHolderFrame>
  );
};

export default DocProfileForm;
