import React from "react";
import { Card } from "antd";
import { EnvironmentFilled, HomeOutlined } from "@ant-design/icons";
import clinicLogo from "../../assets/images/clinic_logo.png";

const HospitalCard = (props) => {
  return (
    <Card
      type="inner"
      hoverable
      title={
        <div style={{ fontSize: "1.5rem" }}>
          <HomeOutlined
            style={{ display: "inline-flex", marginRight: "0.5rem" }}
          />
          {props?.name}
        </div>
      }
      className="my-2"
      bodyStyle={{ padding: "0.5rem " }}
      style={{ fontSize: "1.25rem" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "100px" }}>
          <img src={clinicLogo} style={{ width: "100%" }} alt="cliinc"></img>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 1rem",
          }}
        >
          {/* <div style={{ fontSize: "1.25rem" }}>
            <UserAddOutlined
              style={{ display: "inline-flex", marginRight: "0.5rem" }}
            ></UserAddOutlined>
            {props?.dm_doctor_name}
          </div> */}
          <div>
            <EnvironmentFilled
              style={{ display: "inline-flex", marginRight: "0.5rem" }}
            ></EnvironmentFilled>
            {props?.address}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HospitalCard;
