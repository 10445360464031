import React from "react";
import {
  CheckoutProvider,
  Checkout,
  // injectCheckout,
} from "paytm-blink-checkout-react";

// import "normalize.css";
import "../../assets/styles/home.scss";
import { message } from "antd";
const PaytmPayment = (props) => {
  const config = {
    root: "",
    flow: "DEFAULT",
    data: {
      orderId: props?.data?.ORDER_ID /* update order id */,
      token: props?.body?.txnToken /* update token value */,
      tokenType: "TXN_TOKEN",
      amount: props?.amt || "" /* update amount */,
    },
    merchant: { mid: props?.data.mid, redirect: false },
    payMode: { labels: {}, filter: [], order: [] },
    handler: {
      notifyMerchant: function (eventName, data) {
        if (eventName === "APP_CLOSED" || eventName === "PAYMENT_ERROR") {
          props?.toggleFlag(false);
        }
        console.log("notifyMerchant handler function called");
        console.log("eventName => ", eventName);
        console.log("data => ", data);
      },
      transactionStatus: function (data) {
        console.log("transactionStatus handler function called");
        console.log("data => ", data);
        if (data.STATUS === "TXN_SUCCESS") {
          message.success("Payment SuccessFully Recieved.");
          props.docNewPlanHandler({
            data: {
              ORDER_ID: data.ORDERID,
              TXNID: data.TXNID,
              paytmID: data.TXNID,
            },
          });
          props?.toggleFlag(false);
          // (async function () {
          //   await props.docNewPlanHandler({
          //     data: {
          //       ORDER_ID: data.ORDER_ID,
          //       TXNID: data.TXNID,
          //       paytmID: data.TXNID,
          //     },
          //   });

          //   // window.Paytm.CheckoutJS.invoke("notifyMerchant");
          //   // window.location.reload();
          // })();
        } else {
          message.error("Transaction Failed!");
          window.location.reload();
        }
        // console.log("payment status ", data);
      },
    },
  };

  // let configold = {
  //   root: "",
  //   style: {
  //     bodyBackgroundColor: "",
  //     bodyColor: "",
  //     themeBackgroundColor: "",
  //     themeColor: "",
  //     headerBackgroundColor: "",
  //     headerColor: "",
  //     errorColor: "",
  //     successColor: "",
  //   },
  //   flow: "DEFAULT",
  //   data: {
  //     orderId: props?.data.ORDER_ID /* update order id */,
  //     token: props?.body.txnToken /* update token value */,
  //     tokenType: "TXN_TOKEN",
  //     amount: props?.amt || "" /* update amount */,
  //     userDetail: { mobileNumber: "", name: "" },
  //   },

  //   merchant: {
  //     mid: props?.data.mid,
  //     name: "",
  //     logo: "",
  //     redirect: true,
  //     hidePaytmBranding: false,
  //   },
  //   mapClientMessage: {
  //     static: {
  //       label: {
  //         paymodeSelection: "Select an option to pay",
  //         paymodeOtherSelection: "Select from other options",
  //         saveCardSelection: "SELECT A SAVED CARD",
  //       },
  //       error: {},
  //     },
  //     header: {
  //       label: {
  //         otpSend: "OTP requested successfully",
  //         orderID: "Order ID",
  //         account: "Account",
  //         switch: "Switch",
  //         selectOption: "Select an option to pay",
  //       },
  //       error: {},
  //     },
  //     footer: {
  //       label: { message: "100% Secure Payments Powered by Paytm" },
  //       error: {},
  //     },
  //     offers: { label: { message: "Exclusive Offers for You" }, error: {} },
  //     processing: {
  //       label: {
  //         heading: "Processing Your Payment",
  //         info:
  //           "Please do not close this window or press back while we confirm your payment status",
  //       },
  //       error: {},
  //     },
  //     retry: {
  //       label: {
  //         paymentFailed: "Payment Failed",
  //         info:
  //           "Please do not close this window or press back while we confirm your payment status",
  //       },
  //       error: {},
  //     },
  //     card: {
  //       label: {
  //         cardNumber: "Card Number",
  //         expiry: "Expiry / Validity",
  //         cvv: "CVV",
  //         savedCard: "Save this card for future payments",
  //       },
  //       error: {
  //         cardNumber: "Please enter valid card number",
  //         expiry: "invalid Expiry Date",
  //         cvv: "Please enter valid cvv.",
  //       },
  //     },
  //     ppbl: {
  //       label: {
  //         changeNumber: "Change Balance",
  //         availableBalance: "Available Balance",
  //       },
  //       error: { passcode: "Please enter valid passcode" },
  //     },
  //     pdc: {
  //       label: {
  //         changeNumber: "Change Balance",
  //         availableBalance: "Available Balance",
  //       },
  //       error: { passcode: "Please enter valid passcode" },
  //     },
  //     upi: {
  //       label: {},
  //       error: {
  //         required: "Please enter a UPI ID",
  //         valid: "Please enter a valid UPI ID",
  //       },
  //     },
  //     cod: { label: {} },
  //     login: {
  //       label: { labelMessage: "Pay using your saved payment instruments" },
  //       error: {},
  //     },
  //   },
  //   labels: {},
  //   payMode: { labels: {}, filter: [], order: [] },
  //   handler: {
  //     notifyMerchant: function (eventName, data) {
  //       console.log("notifyMerchant handler function called");
  //       console.log("eventName => ", eventName);
  //       console.log("data => ", data);
  //     },
  //   },
  // };

  // function onScriptLoad() {
  //   if (window.Paytm && window.Paytm.CheckoutJS) {
  //     window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
  //       // initialze configuration using init method
  //       window.Paytm.CheckoutJS.init(config)
  //         .then(function onSuccess() {
  //           // after successfully updating configuration, invoke JS Checkout
  //           window.Paytm.CheckoutJS.invoke();
  //         })
  //         .catch(function onError(error) {
  //           console.log("error => ", error);
  //         });
  //     });
  //   }
  // }

  // useEffect(() => {
  //   // if (window.Paytm && window.Paytm.CheckoutJS) {
  //   //   onScriptLoad();
  //   // }
  //   props.docNewPlanHandler({
  //     data: {
  //       ORDER_ID: "data.ORDER_ID",
  //       TXNID: "data.TXNID",
  //       paytmID: "data.TXNID",
  //     },
  //   });
  // }, []);

  return (
    <div>
      <CheckoutProvider config={config}>
        <Checkout />
      </CheckoutProvider>
    </div>
  );
};

export default PaytmPayment;
