/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Card, Button, Modal, message, notification, Divider } from "antd";
import {
  HomeOutlined,
  UserAddOutlined,
  PhoneOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  EnvironmentOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import { isBrowser } from "react-device-detect";
import DownloadRecieptButton from "./DownloadRecieptButton";
import { apis } from "../../shared/apis";
import axios from "axios";
import RescheduleForm from "./RescheduleForm";
import SymptomsForm from "./SymptomsForm";
import AppointmentDetails from "./AppointmentDetails";
import GetDirectionBtn from "./GetDirectionBtn";
const UserAppointmentCard = (props) => {
  var reshcduleModal;

  const [isRefunding, setIsRefunding] = useState(false);
  const [isGoing, setIsGoing] = useState({
    flag: false,
    status: "Get Set Go",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false);
  const [shouldRetrunNull, setShouldRetrunNull] = useState(false);
  const [loader, setLoader] = useState(false);
  const [docDetails, setDocDetails] = useState({});
  const [patDetails, setPatDetails] = useState({});
  const [showModalForm, setShowModalForm] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const [dependents, setDependents] = useState([]);

  const formatTime = (time) => {
    let str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  const getDocDetails = async () => {
    setLoader(true);
    await axios({
      ...apis.getdoctordetails,
      data: { dlmid: props?.dlmid, req_origin: props?.reqOrigin || "userApp" },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        setDocDetails(res.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };
  const getPatDetails = async () => {
    setLoader(true);
    await axios({
      ...apis.getPatientDependents,
      data: {
        pid: JSON.parse(localStorage.getItem("userProfile"))?.role[0]
          ?.patientid,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        setDependents(res.data.dependents);
        setPatDetails(res.data);
        if (res.data.block) {
          setIsBlocked(true);
          message.error("Patient is Blocked ", res.data.status);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const rescheduleHandler = () => {
    reshcduleModal = Modal.info({
      title: "Want a reshedulement ?",
      content: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            margin: "1rem 0px",
            width: "100%",
          }}
        >
          <Button type="primary" onClick={rescheduleAppointmentHandler}>
            Reschedule
          </Button>
          {props?.pdla_paynow_flag === "Y" ? (
            <Button
              type="danger"
              onClick={refundAppointmentHandler}
              loading={isRefunding}
            >
              {isRefunding ? "Refunding..." : "Refund"}
            </Button>
          ) : (
            <Button
              type="danger"
              onClick={deleteAppointmentHandler}
              loading={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          )}
        </div>
      ),
      closable: true,
      okText: "Close",
      okButtonProps: { type: "ghost", style: { display: "none" } },
    });
  };

  const rescheduleAppointmentHandler = async () => {
    await getDocDetails();
    await getPatDetails();
    if (isBlocked) return;
    else {
      reshcduleModal.destroy();
      setShowModalForm(true);
    }
  };

  const refundAppointmentHandler = async () => {
    let input = {
      pdlaid: props?.pdlaid,
    };
    console.log("refunding... ", isRefunding);
    setIsRefunding(true);
    console.log("refunding... after", isRefunding);
    await axios({ ...apis.getmypaytmrefund, data: input })
      .then((res, err) => {
        setIsRefunding(false);
        if (err) message.error("Something Went Wrong !");
        else {
          message.success("Refund Request Initiated Succesfully !");
          reshcduleModal.destroy();
        }
        // console.log("res d: ", res.data);
      })
      .catch((err) => {
        setIsRefunding(false);
        if (err) message.error("Something Went Wrong !");
      });
  };
  const deleteAppointmentHandler = async () => {
    let input = {
      pdlaid: props?.pdlaid,
    };
    setIsDeleting(true);
    await axios({ ...apis.deleteactiveappointment, data: input })
      .then((res, err) => {
        setIsDeleting(false);
        if (err) message.error("Something Went Wrong !");
        else {
          message.success("Appointment Deleted Succesfully !");
          setShouldRetrunNull(true);
          props?.setActiveAppointmentsLength(
            props?.activeAppointmentsLength - 1
          );
          reshcduleModal.destroy();
        }
        // console.log("res d: ", res.data);
      })
      .catch((err) => {
        setIsDeleting(false);
        if (err) message.error("Something Went Wrong !");
      });
  };
  const joinVideoCallHandler = () => {
    let roomName = props?.pdlaid.slice(props?.pdlaid?.length - 4);
    let displayName = JSON.parse(localStorage.getItem("userProfile"))?.role[0]
      ?.patientname;

    props.history.push({
      pathname: `/jitsi/${props?.pdlaid}/${roomName}/${displayName}`,
      state: {
        pdlaid: props?.pdlaid,
        roomName,
        displayName,
        timeslot: `${formatTime(props?.timefrom)}-${formatTime(props?.timeto)}`,
        showUploadForm: false,
        isUser: true,
      },
    });
  };

  const getSetGoHandler = async () => {
    let input = { pdla_id: props?.pdlaid };
    setIsGoing({ status: "Going...", flag: true });
    await axios({ ...apis.postUserPresentAndDocAvailable, data: input })
      .then((res, err) => {
        setIsGoing({ status: "Get Set Go", flag: false });
        if (err) {
          message.error("Something Went Wrong !");
        }
        if (res.data.info.pdla_partner_present === "N") {
          message.info("Doctor is not available presently. Please stay Online");
        }
        if (res.data?.info?.pdla_calling_mode == 0) {
          notification.success({
            message: "Calling Mode - Video Call",
            description:
              "Doctor is Video Calling you Please stay online. Click To Join Video Call",
            btn: (
              <Button
                onClick={joinVideoCallHandler}
                type="primary"
                shape="round"
                size="large"
              >
                Click To Join
              </Button>
            ),
            // onClick: joinVideoCallHandler,
          });
        }
        if (res.data?.info?.pdla_calling_mode == 1) {
          notification.success({
            message: "Calling Mode - WhatsApp",
            description:
              "Doctor is Calling you Over WhatsApp, Please stay online",
          });
        }
        if (res.data?.info?.pdla_calling_mode == 2) {
          notification.success({
            message: "Calling Mode - Phone",
            description: "Doctor is Calling you over Phone, Please stay online",
          });
        }
        // console.log("Res of postUPnDA : ", res.data);
      })
      .catch((err) => {
        setIsGoing({ status: "Get Set Go", flag: false });
        if (err) {
          message.error("Something Went Wrong !");
        }
      });
  };

  // eslint-disable-next-line no-unused-vars

  if (shouldRetrunNull) return null;
  return (
    <div>
      <div className="my-2 p-1 appointment-card ">
        <Card
          hoverable
          type="inner"
          headStyle={{
            padding: 0,
            // backgroundColor: `${
            //   isEarly
            //     ? "#1890ff"
            //     : isOld
            //     ? "#f5222d"
            //     : fromTime > now
            //     ? "#1890ff"
            //     : isDisabled
            //     ? "#f5222d"
            //     : "#52c41a"
            // }`,
          }}
          bodyStyle={{ padding: "0.20rem" }}
          title={
            <div
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-between" : "center",
                flexDirection: isBrowser ? "row" : "column",
                fontSize: "1.25rem",
                fontWeight: "400",
                padding: "0rem 1rem",
              }}
            >
              <div>
                <HomeOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {props?.locname}
              </div>
              {/* <div>
                {props?.pdlappointmentstatus === 1
                  ? "Done"
                  : props?.pdlappointmentstatus === 2
                  ? "Cancelled"
                  : props?.pdlappointmentstatus === 3
                  ? "Doctor Not available"
                  : props?.pdlappointmentstatus === 4
                  ? "Dormant"
                  : null}
              </div> */}
              <div>
                <Button
                  type="link"
                  className={
                    props?.appointmentstatus === "active"
                      ? "text-success"
                      : "text-danger"
                  }
                  style={{ paddingLeft: "0" }}
                  icon={
                    props?.appointmentstatus === "active" ? (
                      <CheckCircleOutlined style={{ display: "inline-flex" }} />
                    ) : (
                      <ExclamationCircleOutlined
                        style={{ display: "inline-flex" }}
                      />
                    )
                  }
                >
                  {props?.appointmentstatus}
                </Button>
              </div>
              <div>
                <ClockCircleOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {formatTime(props?.timefrom)}-{formatTime(props?.timeto)}
              </div>
            </div>
          }
        >
          <div>
            {/* <div className="patient-pic col-sm-2 container-fluid">
              <div className="profile-dot">
                {pdla_user_present === "Y" ? (
                  <i className="fas fa-circle text-success"></i>
                ) : (
                  <i className="fas fa-circle text-danger"></i>
                )}
              </div>
              <img
                src={
                  pdla_symptom_photo1.length
                    ? "data:image/jpeg;base64," + pdla_symptom_photo1
                    : `https://joeschmoe.io/api/v1/${
                        gender === "M" ? "male" : "female"
                      }/${patientname}`
                }
                alt={"logo of patient"}
              ></img>
            </div> */}
            <div
              className="patient-card-body col"
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-between" : "center",
                fontSize: "1.05rem",
                flexDirection: isBrowser ? "row" : "column",
              }}
            >
              <div style={{ fontSize: "larger" }}>
                <div className="">
                  <UserAddOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.docname}
                </div>
                <div style={{ color: "gray", fontSize: "1.05rem" }}>
                  {/* {age} Yrs,{gender === "M" ? "Male" : "Female"} */}
                  <PhoneOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.doctorcontactnumber}
                </div>
              </div>
              <div>
                <div>
                  <MedicineBoxOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.pdlamomname}
                </div>
                <div>
                  <CalendarOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.appointmentdate}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                <div>
                  {props?.pdla_paynow_flag === "N" ? (
                    <span className="text-danger">
                      ₹{props?.pdladcsmamount} Unpaid
                    </span>
                  ) : (
                    <span className="text-success">
                      ₹{props?.pdladcsmamount} Paid
                    </span>
                  )}
                </div>
                <div className="btn btn-warning p-1 px-2">
                  OTP {props?.pdlaotp}
                </div>
              </div>
              {/* <Button
                className="next-visit-btn btn btn-primary"
                disabled={isDisabled}
                style={{ display: "none" }}
              >
                Next Visit Date
              </Button> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-between" : "center",
                flexDirection: isBrowser ? "row" : "column",
                padding: "0px 15px",
              }}
            >
              <div
                // className="lead"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  fontWeight: "300",
                  // marginLeft: "1.75rem",
                }}
              >
                <EnvironmentOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {props?.locadd}
              </div>
              <div className="m-1 mr-4 pr-4">{props?.role}</div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // padding: "0.5rem",
                  // flexDirection: isBrowser ? "row" : "column",
                }}
              >
                <Divider style={{ margin: "5px 0px" }}>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    className="my-1"
                    onClick={getSetGoHandler}
                    loading={isGoing?.flag}
                    icon={
                      <UsergroupAddOutlined
                        style={{ display: "inline-flex" }}
                      />
                    }
                  >
                    {isGoing?.status}
                  </Button>
                </Divider>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: isBrowser ? "center" : "flex-start",
                  justifyContent: isBrowser ? "space-around" : "center",
                  padding: "0.5rem",
                  flexDirection: isBrowser ? "row" : "column",
                }}
              >
                <Button
                  type="danger"
                  className="my-1"
                  // className="btn btn-light patient-card-action"
                  onClick={rescheduleHandler}
                  // disabled={isDisabled || !isOTPValid}
                  icon={
                    <ClockCircleOutlined style={{ display: "inline-flex" }} />
                  }
                >
                  Reschedule
                </Button>
                <div key={"add-sym-btn-" + Math.random()}>
                  <SymptomsForm {...props}></SymptomsForm>
                </div>
                {/* <Button
                  type="primary"
                  className="my-1"
                  loading={symptomsLoader}
                  onClick={handleSymptom}
                  // onClick={() =>
                  //   Modal.info({
                  //     title: "Jan Elaaj",
                  //     content: <ComingSoonCard></ComingSoonCard>,
                  //   })
                  // }
                  icon={
                    <MedicineBoxOutlined
                      style={{ display: "inline-flex" }}
                    ></MedicineBoxOutlined>
                  }
                >
                  Add Symptoms
                </Button> */}
                {props?.role === "ONL" ||
                props?.role === "ODIE" ||
                props?.role === "OPSY" ? null : (
                  <GetDirectionBtn {...props}></GetDirectionBtn>
                )}
                {props?.pdla_paynow_flag === "Y" ? (
                  <DownloadRecieptButton {...props}></DownloadRecieptButton>
                ) : null}
                <AppointmentDetails {...props}></AppointmentDetails>
              </div>
            </div>
          </div>
          {/* {showSymptom ? (
            <div className="symptom-container">
              {isLoadingSymptom ? (
                "Loading symptom..."
              ) : (
                <div>
                  {pdlaPhoto?.pdla_symptoms !== "NA" ? (
                    <div
                      tag="h5"
                      className="text-center m-1 p-2"
                      style={{ borderBottom: "1px solid #f1f1f1" }}
                    >
                      {pdlaPhoto?.pdla_symptoms}
                    </div>
                  ) : null}
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    {pdlaPhoto?.pdla_symptom_photo1 !== "NA" ? (
                      <SymptomCard
                        photo={pdlaPhoto?.pdla_symptom_photo1}
                        caption={pdlaPhoto?.pdla_photo_caption1}
                        key={pdlaPhoto?.pdla_photo_caption1}
                      ></SymptomCard>
                    ) : null}
                    {pdlaPhoto?.pdla_symptom_photo2 !== "NA" ? (
                      <SymptomCard
                        photo={pdlaPhoto?.pdla_symptom_photo2}
                        caption={pdlaPhoto?.pdla_photo_caption2}
                        key={pdlaPhoto?.pdla_photo_caption2}
                      ></SymptomCard>
                    ) : null}
                    {pdlaPhoto?.pdla_symptom_photo3 !== "NA" ? (
                      <SymptomCard
                        photo={pdlaPhoto?.pdla_symptom_photo3}
                        caption={pdlaPhoto?.pdla_photo_caption3}
                        key={pdlaPhoto?.pdla_photo_caption3}
                      ></SymptomCard>
                    ) : null}
                    {pdlaPhoto?.pdla_symptom_photo1 === "NA" &&
                    pdlaPhoto?.pdla_symptom_photo2 === "NA" &&
                    pdlaPhoto?.pdla_symptom_photo3 === "NA" ? (
                      <div className="sympton-img-container">
                        No Symptoms Available
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          ) : null} */}
        </Card>
      </div>
      <Modal
        visible={showModalForm}
        title={<div>Please Choose Options</div>}
        onCancel={() => setShowModalForm(false)}
        bodyStyle={{ paddingTop: "0" }}
        footer={[]}
      >
        <RescheduleForm
          {...docDetails}
          {...patDetails}
          dependents={dependents}
          loader={loader}
          setShowModalForm={setShowModalForm}
          {...props}
          closeForm={() => setShowModalForm(false)}
        ></RescheduleForm>
      </Modal>
    </div>
  );
};

export default UserAppointmentCard;
