import React, { useState } from "react";
import { Button, Card, Input, Form, message, notification } from "antd";
import { partnerFIRApp } from "../firebase";

const ForgotPassword = (props) => {
  // import { styles } from "../../assets/styles/DoctorVerification";
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const resetPartnerHandler = async () => {
    setLoader(true);

    var auth = partnerFIRApp.auth();
    auth
      .sendPasswordResetEmail(email)
      .then((res, err) => {
        setLoader(false);
        message.success("Password Reset link Sent Successfully.");
        notification.open({
          message: "Check Your Email",
          type: "success",
          description:
            "A Password reset link has been sent to your registered email address.",
          placement: "bottomRight",
        });
      })
      .catch((e) => {
        message.error("Something Went Wrong");
        setLoader(false);
      });
  };
  const resetUserHandler = async () => {
    setLoader(true);
    // var auth = firebase.auth();
    // auth
    //   .sendPasswordResetEmail(email)
    //   .then((res, err) => {
    //     setLoader(false);
    //     message.success("Password Reset link Sent Successfully.");
    //   })
    //   .catch((e) => {
    //     message.error("Something Went Wrong");
    //     setLoader(false);
    //   });
  };
  const resetEmpHandler = async () => {
    setLoader(true);
    // var auth = firebase.auth();
    // auth
    //   .sendPasswordResetEmail(email)
    //   .then((res, err) => {
    //     setLoader(false);
    //     message.success("Password Reset link Sent Successfully.");
    //   })
    //   .catch((e) => {
    //     message.error("Something Went Wrong");
    //     setLoader(false);
    //   });
  };
  // console.log('props? ', props)
  const type = props?.match?.params?.type;
  return (
    <div
      className="container "
      style={{ margin: "120px auto", marginTop: 120 }}
    >
      <Card
        style={{ margin: "auto" }}
        className="col-sm-6 p-0"
        headStyle={{ backgroundColor: "#007bff", padding: 0 }}
        title={<div className="card-title text-light">Forgot Password</div>}
        hoverable
        actions={[
          <Button
            type="link"
            onClick={() => props.history.push(`/${type}-login`)}
            key="logH"
            className="card-action-btn"
          >
            {props?.loginBtnText || "Login"}
          </Button>,

          <Button
            type="link"
            onClick={() => props.history.push(`/${type}-signup`)}
            key="regH"
            className="card-action-btn"
          >
            {props?.regBtnText || "Register"}
          </Button>,
        ]}
      >
        <Form
          onFinish={
            type === "user"
              ? resetUserHandler
              : type === "partner"
              ? resetPartnerHandler
              : resetEmpHandler
          }
        >
          <Form.Item
            rules={[{ required: true, message: "Please enter email" }]}
          >
            <Input
              type="email"
              value={email}
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
            ></Input>
          </Form.Item>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              htmlType="submit"
              loading={loader}
              type="primary"
              // onClick={resetPHandler}
              key="resetP"
              size="large"
              className="card-action-btn"
            >
              {"Reset Password"}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
