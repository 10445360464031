import React, { useState } from "react";
import { Button, Form, Input, Select, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import app from "../firebase";
import moment from "moment";
import AddMediaForm from "./AddMediaForm";

const { Option } = Select;
const AddPostForm = (props) => {
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };
  const [imageFireUrl, setImageFireUrl] = useState(null);

  const [posting, setPosting] = useState(false);

  const finishHandler = async (d) => {
    let userProfile = JSON.parse(localStorage.getItem("userProfile"))?.role[0];

    setPosting(true);
    // let email = userProfile?.patientemail.replace('.', ',')
    let phone = userProfile?.patientmobile;

    let allPRef = app.database().ref(`/allposts/${d?.category}`);
    let newKey1 = allPRef.child(`/${d?.category}`).push().key;
    allPRef
      .update({
        [newKey1]: {
          ...d,
          imageurl: imageFireUrl || "null",
          timeofpost:
            moment().format("DD-MM-YYYY") + " " + moment().format("hh:mm:ss"),
          user_id: newKey1,
        },
      })
      .then((err) => {
        if (err) {
          message.error("Something Went wrong");
        }
      });

    let mypRef = app.database().ref(`/myposts/${phone}/${d?.category}`);
    let newKey = mypRef.child(`/${d?.category}`).push().key;
    mypRef
      .update({
        [newKey]: {
          ...d,
          imageurl: imageFireUrl || "null",
          timeofpost:
            moment().format("DD-MM-YYYY") + " " + moment().format("hh:mm:ss"),
          user_id: newKey,
        },
      })
      .then((err) => {
        setPosting(false);
        if (err) {
          message.error("Something Went wrong");
        } else {
          message.success("Post Added Successfully");
          props?.setShowAddPostForm(false);
        }
      });
  };

  return (
    <div>
      <Form
        // form={form}
        size="large"
        labelAlign="left"
        {...layout}
        onFinish={finishHandler}
        initialValues={{ category: props?.tag }}
      >
        <Form.Item
          name="pseudo"
          label="Pseudo Name"
          rules={[{ required: true, message: "Please enter any pseudo name" }]}
        >
          <Input placeholder="Enter Pseudo Name" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: "Please select any hashtag" }]}
        >
          <Select placeholder="Select hashtag" allowClear>
            {props?.categories?.map((cat, i) => (
              <Option value={cat?.categoryName} key={cat?.categoryName}>
                # {cat?.categoryName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            { required: true, message: "Please enter title of your post" },
          ]}
        >
          <Input placeholder="Enter title of your post" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter description" }]}
        >
          <Input.TextArea
            // autoSize={true}
            maxLength={5000}
            showCount
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder="Enter description of your post"
          />
        </Form.Item>
        <Form.Item label="Media">
          <AddMediaForm
            {...props}
            setImageFireUrl={setImageFireUrl}
          ></AddMediaForm>
        </Form.Item>

        <Form.Item className="text-center">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            shape="round"
            loading={posting}
          >
            {posting ? "Posting..." : "Post"}
            <span className="ml-2">
              <SendOutlined style={{ display: "inline-flex" }} />
            </span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state?.hashTags?.Categories,
  };
};

export default connect(mapStateToProps)(AddPostForm);
