import React from "react";
import BoughtPlanCard from "./BoughtPlanCard";

const BoughtSubsPlanList = (props) => {
  return (
    <div className="row mx-0">
      {props?.selectedplan.map((plan, i) => (
        <div className="col" key={i + Math.random()}>
          <BoughtPlanCard {...plan} {...props}></BoughtPlanCard>
        </div>
      ))}
    </div>
  );
};

export default BoughtSubsPlanList;
