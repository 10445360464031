import { baseUrl } from "./baseUrl";

export const apis = {
  // textGuru: {
  //   method: "GET",
  //   url: "https://www.txtguru.in/imobile/api.php?username=techvictus&password=97058255&source=JNELAJ",
  //   headers: {
  //     Accept:
  //       "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
  //     "Cache-Control": "no-cache",
  //   },
  // },
  // home page
  getNewsOfTheDay: {
    method: "get",
    url: `${baseUrl}getNewsOfTheDay`,
  },

  numberverify: { method: "post", url: `${baseUrl}numberverify` },
  vitalsignupinfo: { method: "post", url: `${baseUrl}vitalsignupinfo` },
  registerUser: { method: "post", url: `${baseUrl}registerUser` },
  vitalregiteruser: {
    method: "post",
    url: `${baseUrl}vitalregiteruser`,
  },
  partnerSignin: { method: "post", url: `${baseUrl}signin` },
  partnerChooseLocation: { method: "post", url: `${baseUrl}chooselocation` },
  checkpoint: { method: "post", url: `${baseUrl}checkpoint` },
  fettimings2: { method: "post", url: `${baseUrl}fettimings2` },
  istimeslotavailable: { method: "post", url: `${baseUrl}istimeslotavailable` },
  timeinsert: { method: "post", url: `${baseUrl}timeinsert` },
  deletetime: { method: "post", url: `${baseUrl}deletetime` },

  serviceselected: {
    method: "post",
    url: `${baseUrl}serviceselected`,
  },
  serviceinfo: {
    method: "post",
    url: `${baseUrl}serviceinfo`,
  },
  deleteservice: {
    method: "post",
    url: `${baseUrl}deleteservice`,
  },
  updateservice: {
    method: "post",
    url: `${baseUrl}updateservice`,
  },
  serviceinsert: {
    method: "post",
    url: `${baseUrl}serviceinsert`,
  },
  managediscount: {
    method: "post",
    url: `${baseUrl}managediscount`,
  },
  updatemanagediscount: {
    method: "post",
    url: `${baseUrl}updatemanagediscount`,
  },

  fetchlocation: {
    method: "post",
    url: `${baseUrl}fetchlocation`,
  },
  updatelocation: {
    method: "post",
    url: `${baseUrl}updatelocation`,
  },

  getMedicalCouncils: {
    method: "get",
    url: `${baseUrl}getMedicalCouncils`,
  },
  updateproffesion: { method: "post", url: `${baseUrl}updateproffesion` },

  getDoctorPlanDetails: {
    method: "post",
    url: `${baseUrl}getDoctorPlanDetails`,
  },
  PaytmGenerateCheckSum: {
    method: "post",
    url: `${baseUrl}PaytmGenerateCheckSum`,
  },
  rpCreateOrderId: {
    method: "post",
    url: `${baseUrl}payment/createOrderId`,
  },
  rpCheckPayment: {
    method: "get",
    url: `${baseUrl}payment/checkApptCreation`,
  },

  doctorNewPlanPaymentY: {
    method: "post",
    url: `${baseUrl}doctorNewPlanPaymentY`,
  },

  getDataAndProduceInvoice: {
    method: "post",
    // headers: {
    //   "Content-type": "application/pdf",
    // },
    url: `${baseUrl}getDataAndProduceInvoice`,
  },

  getLanguages: {
    method: "post",
    url: `${baseUrl}getLanguages`,
  },
  postAddLanguagesDoctor: {
    method: "post",
    url: `${baseUrl}postAddLanguagesDoctor`,
  },
  allinformation: {
    method: "post",
    url: `${baseUrl}allinformation`,
  },
  updateintroduction: {
    method: "post",
    url: `${baseUrl}updateintroduction`,
  },

  partnerAppointmentList: {
    method: "post",
    url: `${baseUrl}getappointmentsfordoctorlocation`,
  },
  pdlaPhoto: {
    method: "post",
    url: `${baseUrl}getPdlaPhoto`,
  },
  sendVideoMeetingSms: {
    method: "post",
    url: `${baseUrl}sendVideoMeetingSms`,
  },
  getPatientDependents: {
    method: "post",
    url: `${baseUrl}patientdependent`,
  },
  getLocality: {
    method: "post",
    url: `${baseUrl}getlocality`,
  },
  getCityState: {
    method: "post",
    url: `${baseUrl}getCityState`,
  },
  getspeciality: {
    method: "post",
    url: `${baseUrl}getspeciality`,
  },
  getOutcomePackages: {
    method: "get",
    url: `${baseUrl}getOutcomePackage`,
  },
  getdoctorsbysearchlimit: {
    method: "post",
    url: `${baseUrl}getdoctorsbysearchlimit`,
  },
  getonlinedoctorsbysearchlimit: {
    method: "post",
    url: `${baseUrl}getonlinedoctorsbysearchlimit`,
  },
  getVitalsBySearchLimit: {
    method: "post",
    url: `${baseUrl}getVitalsBySearchLimit`,
  },
  getPackagesVitals: {
    method: "post",
    url: `${baseUrl}getPackagesVitals`,
  },

  getClinicsForDlm: {
    method: "post",
    url: `${baseUrl}getClinicsForDlm`,
  },
  getdoctordetails: {
    method: "post",
    url: `${baseUrl}getdoctordetails`,
  },
  getpatientappointmentinfo: {
    method: "post",
    url: `${baseUrl}getpatientappointmentinfo`,
  },
  insertAppointmentThroughAppPayLater: {
    method: "post",
    url: `${baseUrl}insertAppointmentThroughAppPayLater`,
  },
  insertAppointmentPayNow: {
    method: "post",
    url: `${baseUrl}insertAppointmentPayNow`,
  },

  iftimeexist: {
    method: "post",
    url: `${baseUrl}iftimeexist`,
  },
  getDoctorTimeSlots: {
    method: "post",
    url: `${baseUrl}getdoctortimeslots`,
  },
  quicksetdelaynotification: {
    method: "post",
    url: `${baseUrl}quicksetdelaynotification`,
  },
  doctortimeoffnotification: {
    method: "post",
    url: `${baseUrl}doctortimeoffnotification`,
  },

  // ------------USER APPOINTMENT------------------

  getpatientappointmenthistory: {
    method: "post",
    url: `${baseUrl}getpatientappointmenthistory`,
  },
  getmypaytmrefund: {
    method: "post",
    url: `${baseUrl}getmypaytmrefund`,
  },
  deleteactiveappointment: {
    method: "post",
    url: `${baseUrl}deleteactiveappointment`,
  },
  getReschedule: {
    method: "post",
    url: `${baseUrl}getReschedule`,
  },
  postUserPresentAndDocAvailable: {
    method: "post",
    url: `${baseUrl}postUserPresentAndDocAvailable`,
  },
  postOnlinePrescriptionEntries: {
    method: "post",
    url: `${baseUrl}postOnlinePrescriptionEntries`,
  },
  postCreatePrescriptionPdf: {
    method: "post",
    url: `${baseUrl}postCreatePrescriptionPdf`,
  },
  checkPaytmRefundStatus: {
    method: "post",
    url: `${baseUrl}checkPaytmRefundStatus`,
  },
  postVerifyPromoCode: {
    method: "post",
    url: `${baseUrl}postVerifyPromoCode`,
  },
  deletePdlaSymptoms: {
    method: "post",
    url: `${baseUrl}deletePdlaSymptoms`,
  },
  updatePdlaSymptoms: {
    method: "post",
    url: `${baseUrl}updatePdlaSymptoms`,
  },
  getPatientCompletedAppointment: {
    method: "post",
    url: `${baseUrl}getPatientCompletedAppointment`,
  },

  // getDataAndProduceInvoice: {
  //   method: "post",
  //   url: `${baseUrl}getDataAndProduceInvoice`,
  // },

  getorganisation: {
    method: "post",
    url: `${baseUrl}getorganisation`,
  },
  getCemVerification: {
    method: "post",
    url: `${baseUrl}getCemVerification`,
  },
  getRegisteredHospitals: {
    method: "get",
    url: `${baseUrl}getRegisteredHospitals`,
  },

  // ---------------------

  postUpdatePrescriptionDetails: {
    method: "post",
    url: `${baseUrl}postUpdatePrescriptionDetails`,
  },
  getFulfilledAppointmentsForOnline: {
    method: "post",
    url: `${baseUrl}getFulfilledAppointmentsForOnline`,
  },
  getPendingPaymentDetailsForDLM: {
    method: "post",
    url: `${baseUrl}getPendingPaymentDetailsForDLM`,
  },
  getPaymentsFromJdlat: {
    method: "post",
    url: `${baseUrl}getPaymentsFromJdlat`,
  },
  getUnFulfilledAppointmentsForOnline: {
    method: "post",
    url: `${baseUrl}getUnFulfilledAppointmentsForOnline`,
  },

  sendotpfordisbursal: {
    method: "post",
    url: `${baseUrl}sendotpfordisbursal`,
  },
  verifyotpfordisbursal: {
    method: "post",
    url: `${baseUrl}verifyotpfordisbursal`,
  },
  getMyDisbursal: {
    method: "post",
    url: `${baseUrl}getMyDisbursal`,
  },
  getDisbursalInvoice: {
    method: "post",
    url: `${baseUrl}getDisbursalInvoice`,
  },
  clinicaddlocation: {
    method: "post",
    url: `${baseUrl}clinicaddlocation`,
  },
  postSendEmail: {
    method: "post",
    url: `${baseUrl}postSendEmail`,
  },
  patientnumberverify: {
    method: "post",
    url: `${baseUrl}patientnumberverify`,
  },
  patientregistration: {
    method: "post",
    url: `${baseUrl}patientregistration`,
  },
  patientsignin: {
    method: "post",
    url: `${baseUrl}patientsignin`,
  },

  // Employeee Login -----
  employeeLogin: {
    method: "post",
    url: `${baseUrl}employeesignin`,
  },
  // Employeee Dashboard
  getDealerSubdealerList: {
    method: "post",
    url: `${baseUrl}getDealerSubdealerList`,
  },
  getDistributerDealerSubDealerDetails: {
    method: "post",
    url: `${baseUrl}getDistributerDealerSubDealerDetails`,
  },
  postCreateDealerSubDealer: {
    method: "post",
    url: `${baseUrl}postCreateDealerSubDealer`,
  },
  postCreateDistributerDealerSubDealer: {
    method: "post",
    url: `${baseUrl}postCreateDistributerDealerSubDealer`,
  },
  getPartneredClinic: {
    method: "post",
    url: `${baseUrl}getPartneredClinic`,
  },
  getAppointmentListForSales: {
    method: "post",
    url: `${baseUrl}getAppointmentListForSales`,
  },
  postVerifyApptOtp: {
    method: "post",
    url: `${baseUrl}postVerifyApptOtp`,
  },

  // ----add dependent======
  patientdependentgistration: {
    method: "post",
    url: `${baseUrl}patientdependentgistration`,
  },

  // todays special
  getDoctorsForFirstScreen: {
    method: "post",
    url: `${baseUrl}getDoctorsForFirstScreen`,
  },

  // process video
  postProcessVideo: {
    method: "post",
    url: `${baseUrl}visualParameter/postProcessVideo`,
  },

  uploadVideo: {
    method: "post",
    url: `${baseUrl}comman/v1/uploadVideo`,
  },
};
