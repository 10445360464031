import { Table } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { apis } from "../shared/apis";
import SearchPatient from "./SearchPatient";
import moment from "moment";
const CompletedAppointmentsList = ({
  selectedClinic,
  selectedRole,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment);
  const [endDate, setEndDate] = useState(moment);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const updateData = () => {
    setShouldUpdate(!shouldUpdate);
  };
  const dProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    updateData,
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "pm_patient_name",
      key: "pm_patient_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Patient Gender",
      data_Index: "pm_gender",
      key: "pm_gender",
      ellipsis: true,
      width: 150,
      align: "center",
      render: (gen) => <span>{gen === "M" ? "Male" : "Female"}</span>,
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Patient Age",
      dataIndex: "age",
      key: "age",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: "min-content",
    },
    {
      title: "Appointment ID",
      dataIndex: "pdla_id",
      key: "pdla_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    // {
    //   title: "Amount Paid",
    //   dataIndex: "patient_amount_paid",
    //   key: "patient_amount_paid",
    //   ellipsis: true,
    //   width: 150,
    //   align: "center",
    //   //        responsive: ["md"],

    //   //   width: 150,
    // },
    {
      title: "DoctorAmount Paid",
      dataIndex: "doctor_amount_paid",
      key: "doctor_amount_paid",
      ellipsis: true,
      width: 180,
      align: "center",
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Appontment Date",
      dataIndex: "pdla_appointment_date",
      key: "pdla_appointment_date",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    // {
    //   otp: "6858",
    //   payment_to_doctor_transaction_id: "NA",
    // },
  ];
  const empColumns = [
    {
      title: "Distributor Name",
      dataIndex: "distributer_name",
      key: "distributer_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Distributor Code",
      dataIndex: "distributer_id",
      key: "distributer_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    {
      title: "Dealer Name",
      dataIndex: "dealer_name",
      key: "dealer_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Dealer Code",
      dataIndex: "dealer_id",
      key: "dealer_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },

    {
      title: "Sub-Dealer Name",
      dataIndex: "sub_dealer_name",
      key: "sub_dealer_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Sub-Dealer Code",
      dataIndex: "sub_dealer_id",
      key: "sub_dealer_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    {
      title: "Patient Name",
      dataIndex: "pm_patient_name",
      key: "pm_patient_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Patient Gender",
      data_Index: "pm_gender",
      key: "pm_gender",
      ellipsis: true,
      width: 150,
      align: "center",
      render: (gen) => <span>{gen === "M" ? "Male" : "Female"}</span>,
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Patient Age",
      dataIndex: "age",
      key: "age",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: "min-content",
    },
    {
      title: "Appointment ID",
      dataIndex: "pdla_id",
      key: "pdla_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    // {
    //   title: "Amount Paid",
    //   dataIndex: "patient_amount_paid",
    //   key: "patient_amount_paid",
    //   ellipsis: true,
    //   width: 150,
    //   align: "center",
    //   //        responsive: ["md"],

    //   //   width: 150,
    // },
    {
      title: "DoctorAmount Paid",
      dataIndex: "doctor_amount_paid",
      key: "doctor_amount_paid",
      ellipsis: true,
      width: 180,
      align: "center",
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Appontment Date",
      dataIndex: "pdla_appointment_date",
      key: "pdla_appointment_date",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    // {
    //   otp: "6858",
    //   payment_to_doctor_transaction_id: "NA",
    // },
  ];

  const addKey = (arr) => {
    let temp = [];
    arr.forEach((item) => {
      let obj = { ...item, key: item?.pdla_id };
      temp.push(obj);
    });

    return temp;
  };

  useEffect(() => {
    const fetchCompletedAppointments = async () => {
      let obj = {
        end_date: endDate.length < 11 ? endDate : endDate.format("DD-M-YYYY"),
        start_date:
          startDate.length < 11 ? startDate : startDate.format("DD-M-YYYY"),
        role: selectedRole?.role,
        dlm_id: selectedClinic?.dlmid,
        subject: props?.subject,
        id: props?.id,
      };
      setLoading(true);
      await axios({ ...apis.getFulfilledAppointmentsForOnline, data: obj })
        .then((res, err) => {
          // if (err) console.log("Err in Fetching CAL:", err);
          setData(addKey(res.data.info));
          setLoading(false);
        })
        .catch((err) => {
          // if (err) console.log("Err in Fetching CAL:", err);
          setLoading(false);
        });
    };
    fetchCompletedAppointments();
    // return () => {
    //     cleanup
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate, selectedRole?.role, selectedClinic?.dlmid]);
  // }, [startDate, endDate, selectedRole?.role, selectedClinic?.dlmid])

  return (
    <div>
      <SearchPatient {...dProps}></SearchPatient>
      <Table
        columns={props.partnerData.isPartnerLoggedIn ? columns : empColumns}
        dataSource={data}
        loading={loading}
        // pagination={{ pageSize: 50 }}
        scroll={{ y: 250 }}
        // size="small"
        className="my-2 px-1 pr-3"
      />
      ,
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    partnerData: state.partnerData,
  };
};

export default connect(mapStateToProps)(CompletedAppointmentsList);
