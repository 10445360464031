import React, { useEffect, useState } from "react";
import { message, Form, Input, Select, Button } from "antd";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import axios from "axios";
import { apis } from "../../shared/apis";
import CardHolderFrame from "../containers/CardHolderFrame";
import LoadingMask from "../LoaderMask/LoadingMask";
const EditLoactionForm = (props) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [iloader, setILoader] = useState(false);
  const [fetching, setFetching] = useState(false);
  // const [stateV, setStateV] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [initialVal, setInitialVal] = useState({});

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/manage-clinic",
      pathName: "Manage Clinic",
    },
    {
      path: "/edit-clinic",
      pathName: "Edit Clinic",
    },
  ];

  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 5 },
      lg: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };

  const updateClinic = async (data) => {
    setLoader(true);
    await axios({
      ...apis.updatelocation,
      data: {
        ...data,
        locid: JSON.parse(localStorage.getItem("selectedClinic"))?.lid,
        state: data.state.value,
        city: data.city.value,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        if (res.data.status === "SUCCESS") {
          message.success("Successfully Updated Clinic");
          // props.history.push("/manage-clinic");
        } else {
          message.error("Ooops..Something Went Wrong!");
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error("Something Went Wrong!");
      });
  };

  const fetchStates = async () => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: "S" } })
      .then((res, err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
        let sts = [];
        let cts = [];
        res.data?.info?.forEach((element) => {
          sts.push({
            label: element.cstate,
            value: element.cstate,
            key: element.cstate,
          });
          cts.push({
            label: `${element.cname} (${element.cstate})`,
            value: element.cname,
            key: element.cname + Math.random(),
          });
        });
        sts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        cts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        if (sts?.length) {
          setStates([...sts]);
        }
      })
      .catch((err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const fetchCities = async (val) => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: val } })
      .then((res, err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
        let cts = [];
        res.data?.info?.forEach((element) => {
          cts.push({
            label: `${element.cname} (${element.cstate})`,
            value: element.cname,
            key: element.cname + Math.random(),
          });
        });
        cts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        if (cts.length) {
          setCities([...cts]);
        }
      })
      .catch((err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      setILoader(true);
      await axios({
        ...apis.fetchlocation,
        data: {
          locid: JSON.parse(localStorage.getItem("selectedClinic"))?.lid,
        },
      })
        .then((res, err) => {
          if (err) message.error("Something went Wrong");
          // fetchStates();
          fetchCities(res.data.state);
          setInitialVal({
            ...res.data,
            name: res.data.lname,
            adrline1: res.data.ladrline1,
            adrline2: res.data.ladrline2,
            pin: res.data.pincode,
            state: {
              label: res.data.state,
              value: res.data.state,
              key: res.data.state,
            },
            city: {
              label: `${res.data.city} (${res.data.state})`,
              value: res.data.city,
              key: res.data.city,
            },
          });
          setILoader(false);
        })
        .catch((err) => {
          setILoader(false);
          if (err) message.error("Something went Wrong");
        });
    };
    fetchStates();
    fetchLocation();
  }, []);

  return (
    <div className="container">
      <CardHolderFrame
        loading={iloader}
        headStyle={cardHeaderStyle}
        title="Edit Clinic"
        showPath={true}
        routes={routes}
        {...props}
      >
        {iloader ? (
          <LoadingMask></LoadingMask>
        ) : (
          <div>
            <Form
              onFinish={updateClinic}
              initialValues={{ ...initialVal }}
              form={form}
              layout="horizontal"
              {...layout}
            >
              <Form.Item
                name="name"
                label={<div>Clinic Name</div>}
                rules={[
                  { required: true, message: "Please enter your Clinic Name" },
                ]}
              >
                <Input size="large" placeholder="Enter Clinic Name"></Input>
              </Form.Item>
              <Form.Item
                name="adrline1"
                label={<div>Address Line 1</div>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your Clinic's Address",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Address Line 1"
                  maxLength={45}
                ></Input>
              </Form.Item>
              <Form.Item name="adrline2" label={<div>Address Line 2</div>}>
                <Input
                  size="large"
                  placeholder="Address Line 2"
                  maxLength={45}
                ></Input>
              </Form.Item>
              <Form.Item
                name="district"
                label={<div>District</div>}
                rules={[
                  { required: true, message: "Please enter your District" },
                ]}
              >
                <Input size="large" placeholder="District"></Input>
              </Form.Item>
              <Form.Item
                name="pin"
                label={<div>Pin-Code</div>}
                rules={[
                  { required: true, message: "Please enter your Pincode" },
                ]}
              >
                <Input size="large" placeholder="Pincode"></Input>
              </Form.Item>

              <Form.Item
                name="state"
                label={<div>State</div>}
                rules={[{ required: true, message: "Please enter your State" }]}
              >
                <Select
                  placeholder="Select Your State"
                  loading={fetching}
                  size="large"
                  allowClear
                  showSearch
                  labelInValue
                  filterOption={true}
                  onChange={(e) => {
                    fetchCities(e.value);
                  }}
                  options={states}
                />
              </Form.Item>
              <Form.Item
                name="city"
                label={<div>City</div>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your city",
                  },
                ]}
              >
                <Select
                  placeholder="Select Your City"
                  loading={fetching}
                  size="large"
                  allowClear
                  showSearch
                  labelInValue
                  filterOption={true}
                  // defaultActiveFirstOption={true}
                  // notFoundContent={fetching ? <Spin size="small" /> : null}
                  options={cities}
                ></Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 12 }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  // style={{ width: "100%" }}
                  loading={loader}
                >
                  {loader ? "Please Wait..." : "Update"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </CardHolderFrame>
    </div>
  );
};

export default EditLoactionForm;
