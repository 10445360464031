import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Empty, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import {sha256} from 'crypto-js';
// import { Redirect } from "react-router-dom";
import axios from "axios";
import "../../assets/styles/DoctorVerification.css";
import "../../assets/styles/PartnerClinics.scss";
import { apis } from "../../shared/apis";
import {
  setClinicsList,
  setIsPartnerLoggedIn,
} from "../../redux/ActionCreators/VerificationActionCreators";

import PartnerClinicCard from "./PartnerClinicCard";
import CardHeaderCustom from "../containers/CardHeaderCustom";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import AddClinicForm from "./AddClinicForm";

// const roleId = localStorage.getItem("role_id");
function PartnerClinicList(props) {
  const [loader, setLoader] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  // const [redirect, setRedirect] = useState(false);
  const [showClinicForm, setShowClinicForm] = useState(false);

  useEffect(() => {
    let roleId = JSON.parse(localStorage.getItem("selectedRole"))?.roleid;
    if (roleId)
      (async function () {
        setLoader(true);

        await axios({
          ...apis.partnerChooseLocation,
          data: {
            docid: roleId,
          },
        })
          .then((res, err) => {
            if (err) message.error("Err in gettin clicnics: ");
            props.setPartnerClinics(res.data);
            setLoader(false);
            props.setIsPartnerLoggedIn(true);
          })
          .catch((err) => {
            if (err) message.error("Err in getting Clinics: ");
            setLoader(false);
            props.setIsPartnerLoggedIn(true);
          });
      })();
    // else setRedirect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
  ];

  // if (redirect) return <Redirect to="/partner-login"></Redirect>;

  return (
    <div className="container">
      <Card
        key={Math.random()}
        style={{ margin: "50px 2%" }}
        headStyle={cardHeaderStyle}
        title={
          <CardHeaderCustom
            title="Clinics List"
            showPath={true}
            routes={routes}
          ></CardHeaderCustom>
        }
        loading={loader}
        extra={[
          <Button
            key={Math.random()}
            type="primary"
            shape="round"
            size="large"
            icon={
              <PlusOutlined
                style={{ display: "inline-flex", alignItems: "center" }}
              />
            }
            onClick={() => setShowClinicForm(true)}
          >
            Add Clinic
          </Button>,
        ]}
      >
        <div className="sub-heading">Select a clinic to proceed </div>
        {loader ? (
          "Loading..."
        ) : props.partnerData.partnerClinics.status === "SUCCESS" ? (
          props.partnerData.partnerClinics?.locations?.map((clinic, i) => (
            <div key={i + Math.random()}>
              <PartnerClinicCard
                clinic={clinic}
                history={props.history}
              ></PartnerClinicCard>
            </div>
          ))
        ) : (
          <Empty></Empty>
        )}
      </Card>
      <Modal
        centered
        visible={showClinicForm}
        onCancel={() => setShowClinicForm(false)}
        title={<div>Add New Clinic</div>}
        footer={[]}
      >
        {/* {JSON.parse(localStorage.getItem("selectedRole"))?.role === "ONL" ? (
          "Online Doc"
        ) : ( */}
        <AddClinicForm
          role={JSON.parse(localStorage.getItem("selectedRole"))?.role}
          closeForm={() => setShowClinicForm(false)}
          setShouldUpdate={setShouldUpdate}
          shouldUpdate={shouldUpdate}
        ></AddClinicForm>
        {/* )} */}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerClinics: (obj) => dispatch(setClinicsList(obj)),
    setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerClinicList);
