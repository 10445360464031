import React, { useState } from "react";
import { Form, Input, Button, Radio, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import RCG from "react-captcha-generator";
import axios from "axios";
import { apis } from "../shared/apis";
// const layout = {
//   labelCol: {
//     span: 6,
//   },
//   wrapperCol: {
//     span: 18,
//   },
// };

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};

function ContactForm() {
  const [form] = Form.useForm();
  const [captchaText, setCaptchaText] = useState("");
  const [loader, setLoader] = useState(false);
  const [updateCap, setUpdateCap] = useState(true);
  const plainOptions = ["EMAIL", "CALL"];

  const onChange = (v) => {
    // // console.log("V: ", v);
  };

  const resultHandler = (text) => {
    // console.log("Captch: ", text);
    setCaptchaText(text);
  };
  // const validateMessages = {
  //   // eslint-disable-next-line no-template-curly-in-string
  //   required: "${label} is required!",
  //   types: {
  //     // eslint-disable-next-line no-template-curly-in-string
  //     email: "${label} is not a valid email!",
  //     // eslint-disable-next-line no-template-curly-in-string
  //     // number: "${label} is not a valid number!",
  //   },
  // };
  const refreshCap = () => {
    setUpdateCap(false);
    setTimeout(() => setUpdateCap(true), 1);
  };

  const onFinish = async (values) => {
    if (values.captcha === captchaText) {
      setLoader(true);
      await axios({ ...apis.postSendEmail, data: values.user })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          else {
            message.success("Email Successfully Sent!");
            form.resetFields();
            refreshCap();
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
        });
    } else {
      message.error("Invalid Captcha");
    }
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      form={form}
      labelAlign="left"
      // validateMessages={validateMessages}
    >
      <Form.Item
        name={["user", "user_name"]}
        label="Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Enter Your Name" />
      </Form.Item>
      <Form.Item
        name={["user", "user_email"]}
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
          },
        ]}
      >
        <Input placeholder="Enter Your Email" />
      </Form.Item>
      <Form.Item
        name={["user", "user_contact"]}
        label="Phone"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Enter Your Phone Number" />
      </Form.Item>
      <Form.Item
        name={["user", "message"]}
        label="Message"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea rows={6} placeholder="Enter Your Message" />
      </Form.Item>
      <Form.Item
        name={["user", "prefered_response_method"]}
        label="Prefered Response Method"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group options={plainOptions} onChange={onChange} />
      </Form.Item>
      <Form.Item
        label="Captcha Text"
        wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            background: "#f1f1f1",
          }}
        >
          {updateCap ? (
            <RCG result={resultHandler}></RCG>
          ) : (
            <div style={{ height: "100px", width: "100px" }}></div>
          )}
          <Button
            type="dashed"
            shape="round"
            icon={<ReloadOutlined style={{ display: "inline-flex" }} />}
            onClick={refreshCap}
          >
            Refresh
          </Button>
        </div>
      </Form.Item>
      <Form.Item
        name="captcha"
        label="Captcha"
        wrapperCol={{ ...layout.wrapperCol }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          name="captcha"
          size="large"
          placeholder="Enter the text from Image on above"
        ></Input>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 11 }}>
        <Button type="primary" htmlType="submit" loading={loader}>
          {loader ? "Please Wait..." : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ContactForm;
