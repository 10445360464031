import React, { useEffect, useState } from "react";
import {
  IdcardOutlined,
  MedicineBoxOutlined,
  ExperimentOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  SmileOutlined,
} from "@ant-design/icons";

const DocDetailProfile = (props) => {
  // const removeNum = (a) => {
  //   let arr = [];
  //   for (let i = 1; i < a?.length - 1; i++) {
  //     let temp = a[i]?.replace(a[i][a[i]?.length - 1], "");
  //     arr.push(temp);
  //   }
  //   arr.push(a?.pop());
  //   return arr;
  // };

  // const formatIntro = (str) => {
  //   let a1, a2, a3, a4, a5, data;
  //   if (str?.length) {
  //     a1 = str?.split("Myself");
  //     a2 = typeof a1[1] !== "undefined" ? a1[1]?.split("Specialization") : "";
  //     a3 = typeof a2[1] !== "undefined" ? a2[1]?.split("Experience") : "";
  //     a4 = typeof a3[1] !== "undefined" ? a3[1]?.split("Hospitals worked") : "";
  //     a5 =
  //       typeof a4[1] !== "undefined"
  //         ? a4[1]?.split("Why should you select me?")
  //         : "";

  //     data = [
  //       {
  //         title: "Myself",
  //         text: a2[0],
  //       },
  //       {
  //         title: "Specialization",
  //         text: a3[0],
  //       },
  //       {
  //         title: "Experience",
  //         text: a4[0],
  //       },
  //       {
  //         title: "Hospitals worked",
  //         text: removeNum(a5[0]?.split(".")),
  //       },
  //       {
  //         title: "Why should you select me?",
  //         text: a5[1],
  //       },
  //     ];
  //   } else {
  //     a1 = "Unspecified";
  //     a2 = "Unspecified";
  //     a3 = "Unspecified";
  //     a4 = "Unspecified";
  //     a5 = "Unspecified";
  //     data = [
  //       {
  //         title: "About",
  //         text: "",
  //       },
  //       {
  //         title: "Specialization",
  //         text: "",
  //       },
  //       {
  //         title: "Experience",
  //         text: "",
  //       },
  //       {
  //         title: "Hospitals worked",
  //         text: [""],
  //       },
  //       {
  //         title: "Why should you select me?",
  //         text: "",
  //       },
  //     ];
  //   }

  //   //   let k = 0;
  //   //   let arr = [];
  //   //   for (let i = 0; i < str.length; i++) {
  //   //     if (str[i] === ":") {
  //   //       console.log("Found :");
  //   //       let pnt = str.substr(k, i);
  //   //       console.log("pnt: ", pnt);
  //   //       arr.push(pnt);
  //   //     }
  //   //     k = i + 1;
  //   //   }
  //   return data;
  // };
  const initCategories = [
    {
      title: "About",
      icon: <IdcardOutlined />,
      text: "",
    },
    {
      title: "Specialization",
      icon: <MedicineBoxOutlined />,
      text: "",
    },
    {
      title: "Experience",
      icon: <ExperimentOutlined />,
      text: "",
    },
    {
      title: "Hospitals worked",
      icon: <HomeOutlined />,
      text: "",
    },
    {
      title: "Why should you select me?",
      icon: <SmileOutlined />,
      text: "",
    },
    {
      title: "Primary Address",
      icon: <EnvironmentOutlined />,
      text: "",
    },
  ];

  const [Categories, setCategories] = useState(initCategories);

  useEffect(() => {
    const newCategories = [
      {
        title: "About",
        icon: <IdcardOutlined />,
        text: props?.doctorinfo[0]?.dm_introduction,
      },
      {
        title: "Specialization",
        icon: <MedicineBoxOutlined />,
        text: props?.doctorinfo[0]?.dm_specialization,
      },
      {
        title: "Experience",
        icon: <ExperimentOutlined />,
        text: props?.doctorinfo[0]?.dm_work_experience,
      },
      {
        title: "Hospitals worked",
        icon: <HomeOutlined />,
        text: props?.doctorinfo[0]?.dm_work_history,
      },
      {
        title: "Why should you select me?",
        icon: <SmileOutlined />,
        text: props?.doctorinfo[0]?.dm_reason_to_select,
      },
      {
        title: "Primary Address",
        icon: <EnvironmentOutlined />,
        text: props?.doctorinfo[0]?.lm_address,
      },
    ];
    setCategories(newCategories);
  }, [props?.doctorinfo]);

  return (
    <div style={{ width: "100%" }}>
      {Categories.map((dat, i) => (
        <div key={i + Math.random} className="my-2">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "#f1f1f1",
              borderRadius: "20px",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            <div className="ml-3" style={{ display: "inline-flex" }}>
              {dat.icon}
            </div>
            <div className="p-2 px-4">{dat.title}</div>
          </div>
          <div className="m-1 p-2 px-4"> {dat.text || "Unspecified "}</div>
        </div>
      ))}

      {/* {formatIntro(props?.intro).map((obj, i) => (
        <div key={i + Math.random()} className="px-1 m-1 mt-2">
          {obj?.title?.includes("?") ? obj.title : obj.title + " : "}
          {i !== 3 ? (
            <p>
              {obj?.text?.length ? obj?.text?.replace(":", "") : "Unspecified"}
            </p>
          ) : (
            obj?.text?.map((t, i) => (
              <div key={i + Math.random()}>
                {i + 1}.{t?.length ? t : "Unspecified"}
              </div>
            ))
          )}
        </div>
      ))} */}
    </div>
  );
};

export default DocDetailProfile;
