import React, { useState } from "react";
import { Button, Card, message } from "antd";
import moment from "moment";
import { apis } from "../shared/apis";
import { DownloadOutlined } from "@ant-design/icons";
import { isBrowser } from "react-device-detect";
const BoughtPlanCard = (props) => {
  const [loader, setLoader] = useState(false);
  const rows = [
    { title: "Amount", value: "₹ " + props.lplanamount + "" || "Amt" },
    {
      title: "End Date",
      value: moment().add(props.lplanduration, "days").format("DD-MM-YYYY"),
    },
    {
      title: "Validity Left",
      value: props.lplanduration + " days",
    },
    { title: "Features", value: props?.lfeaturename || "" },
  ];

  const input = {
    role: JSON.parse(localStorage.getItem("selectedRole"))?.role, // role id
    recipt_code: "1", // always same
    dlm_id: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid, // clinic id
    pdla_id: "PDLAID100000330", // always same
  };

  const showReciept = async () => {
    setLoader(true);
    var data = JSON.stringify(input);

    var config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    fetch(apis.getDataAndProduceInvoice.url, config)
      .then((res) => res.blob())
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `JanElaaj-inovice-${input.dlm_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoader(false);
        message.success("Successfully downloaded invoice");
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  return (
    <Card
      hoverable
      type="inner"
      headStyle={{ backgroundColor: "#1890ff", color: "white" }}
      title={
        <div className="text-center">{props?.lplanname || "No Name "}</div>
      }
      style={{ width: "100%" }}
      // actions={[
      //   <Button
      //     size="large"
      //     type="primary"
      //     shape="round"
      //     style={{ background: "#2f54eb" }}
      //     key={Math.random()}
      //     onClick={showReciept}
      //     loading={loader}
      //   >
      //     {loader ? "Please Wait..." : "Download Reciept"}
      //   </Button>,
      // ]}
    >
      {isBrowser ? (
        <div>
          <table className="table m-0 text-center" style={{ width: "100%" }}>
            <tbody>
              <tr>
                {rows.map((item, i) => (
                  <th className="text-primary" key={i + Math.random()}>
                    {item.title}
                  </th>
                ))}
              </tr>
              <tr>
                {rows.map((item, i) => (
                  <th className="text-success" key={i + Math.random()}>
                    {item.value + "" || ""}
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 0px",
              // background: "red",
            }}
          >
            <Button
              size="large"
              type="primary"
              shape="round"
              icon={
                <DownloadOutlined
                  style={{ display: "inline-flex" }}
                ></DownloadOutlined>
              }
              // style={{ background: "#2f54eb" }}
              key={Math.random()}
              onClick={showReciept}
              loading={loader}
            >
              {loader ? "Please Wait..." : "Download Reciept"}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <table className="table m-0 bg-light" style={{ width: "100%" }}>
              <tbody>
                {rows.map((row, i) => (
                  <React.Fragment key={i + Math.random()}>
                    <tr>
                      <th
                        style={{ textAlign: "right" }}
                        className="text-primary"
                      >
                        {row.title} :{" "}
                      </th>
                      <th className="text-success">{row.value + "" || ""}</th>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 0px",
              // background: "red",
            }}
          >
            <Button
              size="large"
              type="primary"
              shape="round"
              icon={
                <DownloadOutlined
                  style={{ display: "inline-flex" }}
                ></DownloadOutlined>
              }
              // style={{ background: "#2f54eb" }}
              key={Math.random()}
              onClick={showReciept}
              loading={loader}
            >
              {loader ? "Please Wait..." : "Download Reciept"}
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default BoughtPlanCard;
