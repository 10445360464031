import * as ActionTypes from "../../ActionTypes";

// locinfo reducer
export const LocInfo = (
  state = { isLoading: true, errMess: null, locinfo: {} },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_LOCINFO:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        locinfo: action.payload,
      };

    case ActionTypes.LOCINFO_LOADING:
      return { ...state, isLoading: true, errMess: null, locinfo: {} };

    case ActionTypes.LOCINFO_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        locinfo: {},
      };

    default:
      return state;
  }
};
