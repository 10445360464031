import { Button, Card, Image, Input, message, Statistic } from "antd";
import Meta from "antd/lib/card/Meta";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apis } from "../shared/apis";
import doc_card from "../assets/images/doc_card.png";
import BankAccountForm from "./BankAccountForm";
const { Countdown } = Statistic;
const PaymentReqOtpForm = (props) => {
  // console.log(props)
  const [state, setState] = useState({
    sendingOtp: false,
    status: "",
  });

  const [verify, setVerify] = useState({
    verifying: false,
    status: "Verify",
    type: "primary",
  });

  const [otp, setOtp] = useState("");

  //   const deadline = Date.now() + 60 * 60 * 24 * 7;
  const deadline = Date.now() + 10 * 60 * 1000;
  const dlm_id = JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid;
  const mobileno = JSON.parse(localStorage.getItem("selectedRole"))?.mobileno;
  const role = JSON.parse(localStorage.getItem("selectedRole"))?.role;

  const [isOtpExpired, setIsOtpExpired] = useState(false);

  const verifyOtpHandler = async () => {
    if (!otp) {
      message.error("Please enter OTP");
      return;
    }
    setVerify({ verifying: true, status: "verifying", type: "primary" });
    let obj = {
      number: props.partnerData.isPartnerLoggedIn ? mobileno : "",
      // number : 8448735275,
      otp: otp,
      dlm_id: props.partnerData.isPartnerLoggedIn ? dlm_id : "",
      id: props.partnerData.isPartnerLoggedIn
        ? ""
        : JSON.parse(localStorage.getItem("empRole")).empid,
      subject: props.partnerData.isPartnerLoggedIn
        ? ""
        : JSON.parse(localStorage.getItem("empRole")).role,
    };
    await axios({ ...apis.verifyotpfordisbursal, data: obj })
      .then((res, err) => {
        if (err) {
          setVerify({ verifying: false, status: "Retry", type: "danger" });
        } else {
          if (res.data.status === "INVALID")
            setVerify({ verifying: false, status: "Retry", type: "danger" });
          else
            setVerify({
              verifying: false,
              status: "Verified",
              type: "success",
            });
        }
      })
      .catch((err) => {
        setVerify({ verifying: false, status: "Retry", type: "danger" });
      });
  };

  useEffect(() => {
    (async function () {
      setState({ sendingOtp: true, status: "Sending OTP", verifying: false });
      setIsOtpExpired(false);
      let obj = {
        number: props.partnerData.isPartnerLoggedIn ? mobileno : "",
        // number: 8448735275,
        dlm_id: props.partnerData.isPartnerLoggedIn ? dlm_id : "",
        id: props.partnerData.isPartnerLoggedIn
          ? ""
          : JSON.parse(localStorage.getItem("empRole")).empid,
        subject: props.partnerData.isPartnerLoggedIn
          ? ""
          : JSON.parse(localStorage.getItem("empRole")).role,
      };

      await axios({ ...apis.sendotpfordisbursal, data: obj })
        .then((res, err) => {
          if (err) {
            setState({
              sendingOtp: false,
              status: "Sending OTP Failed",
              verifying: false,
            });
            setVerify({ verifying: false, status: "Verify", type: "primary" });
          } else {
            setState({
              sendingOtp: false,
              status: "OTP Sent",
              verifying: false,
            });
            setIsOtpExpired(false);
            setVerify({ verifying: false, status: "Verify", type: "primary" });
          }
        })
        .catch((err) => {
          setState({
            sendingOtp: false,
            status: "Sending OTP Failed",
            verifying: false,
          });
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dlm_id, mobileno]);

  return verify.status !== "Verified" ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        type="inner"
        hoverable
        style={{ width: "50%", minWidth: "300px" }}
        cover={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              className="rounded-circle m-auto p-3 text-center"
              src={doc_card}
              preview={false}
              alt={"Doc Logo  "}
              style={{ maxWidth: "80%" }}
            ></Image>
            <div
              className="text-success font-weight-bold"
              style={{ fontSize: "1.15rem" }}
            >
              {props.billAmnt}
            </div>
          </div>
        }
        title={
          <div
            className="head-xl font-weight-bold text-center"
            style={{ fontSize: "1.25rem" }}
          >
            Enter Verification Code
          </div>
        }
        actions={[
          <Button
            type={verify.type === "success" ? "primary" : verify.type}
            style={
              verify.type === "success" ? { backgroundColor: "#52c41a" } : null
            }
            loading={verify.verifying}
            onClick={verifyOtpHandler}
            className="my-2"
          >
            {verify.status}
          </Button>,
        ]}
      >
        <Meta
          title={
            <Input
              placeholder="Enter OTP"
              size="large"
              className="my-2"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onPressEnter={verifyOtpHandler}
              required
            ></Input>
          }
        ></Meta>
        <Meta
          description={
            state.status === "OTP Sent" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{state.status} </div>
                <div>
                  {
                    <Countdown
                      title={isOtpExpired ? "OTP Expired" : "Expires In"}
                      value={deadline}
                      format="mm:ss"
                      valueStyle={{
                        color: isOtpExpired ? "#f5222d" : "#1890ff",
                        fontSize: "1rem",
                        textJustify: "auto",
                        textAlign: "center",
                      }}
                      onFinish={() => {
                        setIsOtpExpired(true);
                        setState({
                          sendingOtp: false,
                          status: "OTP Expired",
                          verifying: false,
                        });
                      }}
                    />
                  }{" "}
                </div>
              </div>
            ) : (
              <div>{state.status}</div>
            )
          }
          className="m-2"
        ></Meta>
      </Card>
    </div>
  ) : (
    <BankAccountForm {...props} dlm_id={dlm_id} role={role}></BankAccountForm>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    partnerData: state.partnerData,
  };
};

export default connect(mapStateToProps)(PaymentReqOtpForm);
