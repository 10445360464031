import React, { useEffect, useState } from "react";
import { Divider, message, Tabs } from "antd";

import "../../assets/styles/userDashboard.scss";
import ServicesList from "./ServicesList";
import DoctorTypesList from "./DoctorTypesList";
import DoctorsList from "./DoctorsList";
import { Steps, Select, Spin } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  // LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import UserAppointmentList from "./UserAppointmentList";
import axios from "axios";
import { apis } from "../../shared/apis";
import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";
import { connect } from "react-redux";
import { topActionStyles } from "../../assets/styles/UserAppStyles";
// eslint-disable-next-line no-unused-vars
import UserProfile from "./UserProfile";
import CardHolderFrame from "../containers/CardHolderFrame";
import { isBrowser } from "react-device-detect";
// import ComingSoonCard from "../common/ComingSoonCard";
import CurrentLocation from "../common/CurrentLocation";
import AddDependentForm from "./AddDependentForm.js";

const { Option } = Select;
const { Step } = Steps;
// const { Search } = Input;

const { TabPane } = Tabs;

const DashBoard = (props) => {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))?.role[0];
  const [key, setKey] = useState("1");
  const [appointmentKey, setAppointmentKey] = useState("active");
  const [current, setCurrent] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [userState, setUserState] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userCity, setUserCity] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userLocality, setUserLocality] = useState("");
  const [fetching, setFetching] = useState(false);
  const [fetchingL, setFetchingL] = useState(false);
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [fetchingD, setFetchingD] = useState(false);
  const [dependents, setDependents] = useState([
    {
      dependentid: userProfile?.patientid,
      dob: userProfile?.patientdob,
      email: userProfile?.patientemail,
      gender: userProfile?.patientgender,
      mobile: userProfile?.patientmobile,
      name: userProfile?.patientname,
      patientid: userProfile?.patientid,
      pdmid: userProfile?.patientid,
      photo: userProfile?.patientphoto,
    },
  ]);
  const [dependent, setDependent] = useState(dependents[0]?.name);
  const [userDependent, setUserDependent] = useState(dependents[0]);

  const handleDependent = (e) => {
    // console.log(e);
    setDependent(e);
    let selDep = dependents.filter((d) => d.name === e);
    setUserDependent(selDep[0]);
  };

  const toggleHighlighter = () => {
    setShouldHighlight(true);
    setTimeout(() => setShouldHighlight(false), 5000);
  };

  const tabChangeHandler = (e) => {
    setKey(e);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const formatLabelInValue = (arr) => {
    let sts = [];
    arr?.forEach((element) => {
      sts.push({
        label: `${element.llocalityname} (${element.llocalitystate})`,
        value: element.llocalityname,
        // value: { ...element },
        key: element.llocalityname + Math.random(),
        data: { ...element },
      });
      // cts.push({
      //   label: `${element.cname} (${element.cstate})`,
      //   value: element.cname,
      //   key: element.cname + Math.random(),
      // });
    });
    sts.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    setLocalities([...sts]);
  };

  const fetchDependents = async () => {
    setFetchingD(true);
    await axios({
      ...apis.getPatientDependents,
      data: {
        pid: JSON.parse(localStorage.getItem("userProfile"))?.role[0]
          ?.patientid,
      },
    })
      .then((res, err) => {
        // if (err) console.log("Err while fetching dependents: ", err);
        // else {
        setFetchingD(false);
        setDependents([...dependents, ...res.data.dependents]);
        // }
      })
      .catch((err) => {
        // if (err) console.log("Err while fetching dependents: ", err);
        setFetchingD(false);
      });
  };

  const fetchStates = async (val) => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: val || "S" } })
      .then((res, err) => {
        if (err) message.error("Fetching states err: ");
        let sts = [];
        res.data?.info?.forEach((element) => {
          sts.push({
            label: element.cstate,
            value: element.cstate,
            key: element.cstate + Math.random(),
          });
          // cts.push({
          //   label: `${element.cname} (${element.cstate})`,
          //   value: element.cname,
          //   key: element.cname + Math.random(),
          // });
        });
        sts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        setStates([...sts]);
        setFetching(false);
        // }
      })
      .catch((err) => {
        if (err) message.error("Fetching states err: ");
        setFetching(false);
      });
  };
  const fetchCities = async (val) => {
    if (val) {
      setFetching(true);
      await axios({ ...apis.getCityState, data: { cityorstate: val } })
        .then((res, err) => {
          if (err) message.error("Fetching Cities err: ");
          let cts = [];
          res.data?.info?.forEach((element) => {
            cts.push({
              label: `${element.cname} (${element.cstate})`,
              value: element.cname,
              key: element.cname + Math.random(),
            });
          });
          cts.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });
          setCities([...cts]);
          setFetching(false);
        })
        .catch((err) => {
          if (err) message.error("Fetching cities err: ");
          setFetching(false);
        });
    }
  };

  const fetchLocality = async (val) => {
    setFetchingL(true);
    await axios({ ...apis.getLocality, data: { cityname: val } })
      .then((res, err) => {
        if (err) message.error("Fetching Locaities err: ");
        else {
          formatLabelInValue(res.data.info);
          setFetchingL(false);
        }
      })
      .catch((err) => {
        if (err) message.error("Fetching Localities err: ");
        setFetchingL(false);
      });
  };

  const handleStateChange = (e) => {
    setUserState(e);
    props.setUserState(e);
    fetchCities(e?.value);
  };

  const handleCityChange = (e) => {
    setUserCity(e);
    props?.setUserCity(e);
    fetchLocality(e?.value);
  };

  const handleLocalityChange = (e) => {
    let selectedLoc = localities.filter(
      (l) => l?.data?.llocalityname === e?.value
    );
    setUserLocality(e);
    // setUserLocality({ ...selectedLoc[0], ...e.value });
    props.setUserLocality({
      ...selectedLoc[0]?.data,
      value: e?.value,
      label: e?.label,
      key: e?.value,
    });
  };

  const steps = [
    {
      title: "Choose Doctors",
      description: props?.userDash?.selectedService?.roleName || "",
      content: (
        <ServicesList
          next={next}
          // userState={userState}
          // userCity={userCity}
          // userLocality={userLocality}
          toggleHighlighter={toggleHighlighter}
          tabChangeHandler={tabChangeHandler}
          {...props}
        ></ServicesList>
      ),
      icon: <UserOutlined style={{ display: "inline-flex" }} {...props} />,
    },

    {
      title: "Choose Speciality",
      description: props?.userDash?.selectedSpeciality?.name || "",
      content: <DoctorTypesList next={next} {...props}></DoctorTypesList>,
      icon: <SolutionOutlined style={{ display: "inline-flex" }} />,
    },

    {
      title: "Choose Doctor",
      description: "Available Doctors",
      content: <DoctorsList next={next} {...props}></DoctorsList>,
      icon: <SmileOutlined style={{ display: "inline-flex" }} />,
    },

    // {
    //   title: "Pay",
    //   content: "Payment Section",
    //   icon: <LoadingOutlined style={{ display: "inline-flex" }} />,
    // },
  ];

  useEffect(() => {
    fetchDependents();
    fetchStates("S");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = [
    { path: "/", pathName: "Home" },
    { path: "/dashboard", pathName: "Dashboard" },
  ];

  return (
    <CardHolderFrame
      title="User Dashboard"
      routes={routes}
      bodyStyle={{ padding: "0.5rem" }}
    >
      {/* {key === "1" ? ( */}
      {/* <div
          className="text-center p-1 row mx-0"
          style={{
            backgroundColor: "#f0f0f0",
            alignItems: "center",
            justifyContent: "space-around",
            animation: shouldHighlight ? "highlight 2s 2" : "",
          }}
        > */}
      {/* <div style={{ width: "20%" }}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder=" Choose Dependent"
            optionFilterProp="children"
            onChange={handleDependent}
            // onSearch={onSearch}
            notFoundContent={fetchingD ? <Spin size="small" /> : null}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={dependent}
            allowClear
          >
            {dependents.map((d, i) => (
              <Option value={d.name} key={i + Math.random()}>
                <div className="option-dependent px-1">
                  <span className="user-icon">
                    {d.photo ? (
                      <img
                        alt={d.name}
                        className="img-fluid"
                        src={"data:image/jpeg;base64," + d.photo}
                      ></img>
                    ) : (
                      <UserOutlined></UserOutlined>
                    )}
                  </span>
                  <span className="user-name">{d.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div> */}

      {/* <div>
            <CurrentLocation></CurrentLocation>
          </div>
          <Divider>OR</Divider>
          <div className="my-2 col-sm-3">
            <Select
              // mode="multiple"
              showSearch
              // allowClear
              labelInValue
              disabled={props?.userDash?.userCurrentLocation?.isOn}
              value={props?.userDash?.userState}
              placeholder={
                props?.userDash?.userCurrentLocation?.isOn
                  ? "Using Current Location"
                  : "Select State"
              }
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={true}
              // onSearch={fetchStates}
              onChange={handleStateChange}
              // onSelect={handleStateChange}
              style={{ width: "100%" }}
              options={states}
            ></Select>
          </div>
          <div className="my-2 col-sm-3">
            <Select
              showSearch
              // allowClear
              labelInValue
              disabled={props?.userDash?.userCurrentLocation?.isOn}
              placeholder={
                props?.userDash?.userCurrentLocation?.isOn
                  ? "Using Current Location"
                  : "Select City"
              }
              value={props?.userDash?.userCity}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={true}
              onChange={handleCityChange}
              style={{ width: "100%" }}
              options={cities}
            ></Select>
          </div> */}
      {/* <div className="my-2 col-sm-3">
            <Select
              showSearch
              // allowClear
              labelInValue
              placeholder={
                props?.userDash?.userCurrentLocation?.isOn
                  ? "Using Current Location"
                  : "Select Locality"
              }
              value={props?.userDash?.userLocality}
              disabled={props?.userDash?.userCurrentLocation?.isOn}
              notFoundContent={fetchingL ? <Spin size="small" /> : null}
              filterOption={true}
              onChange={handleLocalityChange}
              style={{ width: "100%" }}
              options={localities}
            > */}
      {/* {localities.map((d, i) => (
              <Option value={d.llocalityname} key={i + Math.random()}>
                {d.llocalityname} {d.llocalitystate}
              </Option>
            ))} */}
      {/* </Select>
          </div> */}
      {/* </div>
      ) : null} */}
      {
        key === "1" ? (
          <div
            className="text-center pb-1 pt-3 px-3 mb-3 border"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#f0f0f0",
            }}
          >
            {isBrowser ? (
              <div
                className=" pb-3"
                style={{
                  flexBasis: "15%",
                  textAlign: "center",
                  fontSize: "1.40rem",
                }}
              >
                Steps :
              </div>
            ) : null}
            <Steps
              type="navigation"
              // direction="vertical"
              style={{
                // ...topActionStyles,
                flexBasis: isBrowser ? "85%" : "100%",
                marginLeft: "auto",
                // display: isBrowser ? "flex" : "f",
                // display: "flex",
                // alignItems: "flex-start",
                // justifyContent: "center",
                // flexDirection: isBrowser ? "row" : "column",
              }}
              current={current}
              onChange={(c) => setCurrent(c)}
              size={isBrowser ? "large" : "small"}
              // progressDot
              className="pb-3"
            >
              {steps.map((step, i) => (
                <Step
                  title={step.title}
                  description={
                    <div style={{ whiteSpace: "nowrap" }}>
                      {step?.description?.toUpperCase()}
                    </div>
                  }
                  // icon={step.icon}
                  key={i}
                  disabled={i > current}
                  // style={
                  //   isBrowser
                  //     ? {}
                  //     : {
                  //         display: "flex",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         flexDirection: "column",
                  //         whiteSpace: "normal",
                  //       }
                  // }
                />
              ))}
            </Steps>
          </div>
        ) : key === "2" ? (
          <div className="text-center">
            <Tabs
              // size="large"
              centered
              onChange={(key) => setAppointmentKey(key)}
              tabBarStyle={{
                ...topActionStyles,
                width: isBrowser ? "85%" : "100%",
                display: "flex",
                flexDirection: isBrowser ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              // tabBarGutter={60}
              tabBarExtraContent={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0.51rem 0",
                    // justifyContent: "center",
                  }}
                >
                  <div
                    className="mr-2 text-secondary "
                    style={{ fontSize: "1rem", fontWeight: "300" }}
                  >
                    Showing Appointments for
                  </div>
                  <div style={{ width: "20%" }}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder=" Choose Dependent"
                      optionFilterProp="children"
                      onChange={handleDependent}
                      // onSearch={onSearch}
                      notFoundContent={fetchingD ? <Spin size="small" /> : null}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      value={dependent}
                      allowClear
                    >
                      {dependents.map((d, i) => (
                        <Option value={d.name} key={i + Math.random()}>
                          <div className="option-dependent px-1">
                            <span className="user-icon">
                              {d.photo ? (
                                <img
                                  alt={d.name}
                                  className="img-fluid"
                                  src={"data:image/jpeg;base64," + d.photo}
                                ></img>
                              ) : (
                                <UserOutlined></UserOutlined>
                              )}
                            </span>
                            <span className="user-name">{d.name}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              }
            >
              <TabPane key="active" tab="Active Appointments"></TabPane>
              <TabPane key="past" tab="Past Appointments"></TabPane>
            </Tabs>
          </div>
        ) : null
        // <div className="text-center">
        //   <Tabs
        //     size="large"
        //     centered
        //     onChange={(key) => setAppointmentKey(key)}
        //     tabBarStyle={{
        //       ...topActionStyles,
        //       visibility: "hidden", //Jus to create space ----dummy
        //     }}
        //     tabBarGutter={60}
        //   >
        //     <TabPane key="active" tab="Active"></TabPane>
        //     <TabPane key="past" tab="Past"></TabPane>
        //   </Tabs>
        // </div>
      }
      <Tabs
        tabPosition={isBrowser ? "left" : "top"}
        onChange={tabChangeHandler}
        size={isBrowser ? "large" : ""}
        // size="large"
        activeKey={key}
        className="user-dash-tabs"
      >
        <TabPane tab="Book Now" key="1" style={{ padding: "0 0.5rem" }}>
          {steps[current].content}
        </TabPane>
        <TabPane tab="Appointments" key="2" style={{ padding: "0.5rem" }}>
          <UserAppointmentList
            {...props}
            appointmentKey={appointmentKey}
            dependent={userDependent}
          ></UserAppointmentList>
        </TabPane>
        <TabPane tab="Add Dependent" key="3">
          {/* Add Dependent Section */}
          <AddDependentForm></AddDependentForm>
        </TabPane>
        <TabPane tab="My Profile" key="4">
          <UserProfile></UserProfile>
        </TabPane>
        <TabPane
          tab="My Location"
          key="5"
          tabBarStyle={{ animation: shouldHighlight ? "highlight 2s 2" : "" }}
          // className={shouldHighlight ? "highlight bg-dark" : ""}
        >
          <div
            className="text-center p-1 row mx-0"
            style={{
              backgroundColor: "#f0f0f0",
              alignItems: "center",
              justifyContent: "space-around",
              animation: shouldHighlight ? "highlight 2s 2" : "",
            }}
          >
            {/* <div style={{ width: "20%" }}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder=" Choose Dependent"
            optionFilterProp="children"
            onChange={handleDependent}
            // onSearch={onSearch}
            notFoundContent={fetchingD ? <Spin size="small" /> : null}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={dependent}
            allowClear
          >
            {dependents.map((d, i) => (
              <Option value={d.name} key={i + Math.random()}>
                <div className="option-dependent px-1">
                  <span className="user-icon">
                    {d.photo ? (
                      <img
                        alt={d.name}
                        className="img-fluid"
                        src={"data:image/jpeg;base64," + d.photo}
                      ></img>
                    ) : (
                      <UserOutlined></UserOutlined>
                    )}
                  </span>
                  <span className="user-name">{d.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div> */}
            <div>
              <CurrentLocation></CurrentLocation>
            </div>
            <Divider>OR</Divider>
            <div className="my-2 col-sm-3">
              <Select
                // mode="multiple"
                showSearch
                // allowClear
                labelInValue
                disabled={props?.userDash?.userCurrentLocation?.isOn}
                value={props?.userDash?.userState}
                placeholder={
                  props?.userDash?.userCurrentLocation?.isOn
                    ? "Using Current Location"
                    : "Select State"
                }
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={true}
                // onSearch={fetchStates}
                onChange={handleStateChange}
                // onSelect={handleStateChange}
                style={{ width: "100%" }}
                options={states}
              ></Select>
            </div>
            <div className="my-2 col-sm-3">
              <Select
                showSearch
                // allowClear
                labelInValue
                disabled={props?.userDash?.userCurrentLocation?.isOn}
                placeholder={
                  props?.userDash?.userCurrentLocation?.isOn
                    ? "Using Current Location"
                    : "Select City"
                }
                value={props?.userDash?.userCity}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={true}
                onChange={handleCityChange}
                style={{ width: "100%" }}
                options={cities}
              ></Select>
            </div>
            <div className="my-2 col-sm-3">
              <Select
                showSearch
                // allowClear
                labelInValue
                placeholder={
                  props?.userDash?.userCurrentLocation?.isOn
                    ? "Using Current Location"
                    : "Select Locality"
                }
                value={props?.userDash?.userLocality}
                disabled={props?.userDash?.userCurrentLocation?.isOn}
                notFoundContent={fetchingL ? <Spin size="small" /> : null}
                filterOption={true}
                onChange={handleLocalityChange}
                style={{ width: "100%" }}
                options={localities}
              >
                {/* {localities.map((d, i) => (
              <Option value={d.llocalityname} key={i + Math.random()}>
                {d.llocalityname} {d.llocalitystate}
              </Option>
            ))} */}
              </Select>
            </div>
          </div>
        </TabPane>
      </Tabs>
      {/* </Card> */}
    </CardHolderFrame>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserState: (data) => dispatch(dashboardAC.setUserState(data)),
    setUserCity: (data) => dispatch(dashboardAC.setUserCity(data)),
    setUserLocality: (data) => dispatch(dashboardAC.setUserLocality(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
