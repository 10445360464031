import React, { useState } from "react";
import { DatePicker, Button } from "antd";
import moment from "moment";
// import Dictaphone from "./Dictaphone";

// const { Search } = Input;

// const { RangePicker } = DatePicker;
const SearchPatient = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ...props
}) => {
  const [startMoment, setStartMoment] = useState(startDate);
  const [endMoment, setEndMoment] = useState(endDate);
  // const [message, setMessage] = useState("");

  // const handleDateChange = (e, ds) => {
  //   setStartDate(ds[0]);
  //   setStartMoment(e[0]);
  //   setEndDate(ds[1]);
  //   setEndMoment(e[1]);
  // };

  const handleStartDateChange = (e, ds) => {
    setStartDate(ds);
    setStartMoment(e);
  };
  const handleEndDateChange = (e, ds) => {
    setEndDate(ds);
    setEndMoment(e);
  };

  // useEffect(() => {
  //   setStartDate(startMoment.format("DD-M-YYYY"));
  //   setEndDate(endMoment.format("DD-M-YYYY"));
  // }, []);
  // eslint-disable-next-line no-unused-vars
  function disabledDate(current) {
    // Can not select days before today

    return current &&
      current.format("DD-MM-YYYY") ===
        moment().endOf("day").format("DD-MM-YYYY")
      ? false
      : current < moment().endOf("day");
  }
  return (
    <div className="row mr-0 p-1">
      {/* <div className="col-sm-6 mb-2">
        <Search
          placeholder="Search Patient by Name"
          enterButton="Search"
          size="large"
          suffix={<Dictaphone message={message} setMessage={setMessage} />}
          allowClear
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          //   onSearch={onSearch}
        />
      </div> */}
      <div
        className="col-sm-5 mb-2 row mx-0"
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        {/* <RangePicker
          size="large"
          style={{ width: "100%" }}
          value={[startMoment, endMoment]}
          format={props?.isSlash ? "DD/MM/YYYY" : "DD-MM-YYYY"}
          onChange={handleDateChange}
          allowClear={false}
          disabledDate={disabledDate}
        /> */}
        <div className=" col-4 mr-2" style={{ whiteSpace: "nowrap" }}>
          Start Date :
        </div>
        <div className="col">
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            value={startMoment}
            format={props?.isSlash ? "DD/MM/YYYY" : "DD-MM-YYYY"}
            onChange={handleStartDateChange}
            allowClear={false}
          ></DatePicker>
        </div>
      </div>
      <div
        className="col-sm-5 mb-2 row mx-0"
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        {/* <RangePicker
          size="large"
          style={{ width: "100%" }}
          value={[startMoment, endMoment]}
          format={props?.isSlash ? "DD/MM/YYYY" : "DD-MM-YYYY"}
          onChange={handleDateChange}
          allowClear={false}
          disabledDate={disabledDate}
        /> */}
        <div className=" col-4 mr-2" style={{ whiteSpace: "nowrap" }}>
          {" "}
          End Date :{" "}
        </div>
        <div className="col">
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            value={endMoment}
            format={props?.isSlash ? "DD/MM/YYYY" : "DD-MM-YYYY"}
            onChange={handleEndDateChange}
            allowClear={false}
          ></DatePicker>
        </div>
      </div>
      <div className="col-sm-2">
        <Button type="primary" onClick={props?.updateData}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default SearchPatient;
