import React, { useState } from "react";
import { Form, TimePicker, Tag, Button, message } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";
const { CheckableTag } = Tag;

const format = "HH:mm";

const AddTimingForm = (props) => {
  const [loader, setLoader] = useState(false);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [selectedDays, setSelectedDays] = useState([]);
  const insertTime = async (d) => {
    let data = {
      dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
    };
    for (let i = 0; i < 7; i++) {
      data[days[i].toLowerCase()] = [];
    }
    selectedDays.forEach((day) => {
      data[day.toLowerCase()] = [{ time: d.from + "_" + d.to }];
    });
    setLoader(true);
    await axios({ ...apis.timeinsert, data })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Err in inserting time:");
        if (res.data.status === "SUCCESS") {
          props.updateList();
          message.success("Timing Added Successfully");
          props.closeForm();
        } else {
          message.error("TimeSlot Not Available");
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Err in inserting time:");
      });
  };
  const handleFinish = async (d) => {
    d = { ...d, to: d.to.format("HHmm"), from: d.from.format("HHmm") };
    let data = {
      username: localStorage.getItem("partnerEmail"),
      currrole: JSON.parse(localStorage.getItem("selectedRole"))?.role,
      DocId: JSON.parse(localStorage.getItem("selectedRole"))?.roleid,
      DlmId: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
      TimeFrom: d.from,
      TimeTo: d.to,
    };

    let temp = {
      mon: "N",
      tue: "N",
      wed: "N",
      thu: "N",
      fri: "N",
      sat: "N",
      sun: "N",
    };
    selectedDays.forEach((day) => {
      temp[day.substr(0, 3).toLowerCase()] = day.substr(0, 3).toUpperCase();
    });
    data = { ...data, ...temp };
    setLoader(true);
    await axios({ ...apis.istimeslotavailable, data })
      .then(async (res, err) => {
        setLoader(false);
        if (err) message.error("Err in saving ts: ");
        if (res.data.insideroleoverlap === "NO") {
          await insertTime(d);
        } else {
          message.error("TimeSlot Not Available");
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Err in saving ts: ");
      });
  };
  return (
    <div>
      <Form onFinish={handleFinish}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexBasis: "100%",
          }}
        >
          <Form.Item
            name="from"
            style={{ flexGrow: "1", padding: "0 10px 0 0" }}
            rules={[{ required: true, message: "Please enter time" }]}
          >
            <TimePicker
              size="large"
              placeholder="Time From"
              format={format}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="to"
            style={{ flexGrow: "1", padding: "0 0 0 10px" }}
            rules={[{ required: true, message: "Please enter time" }]}
          >
            <TimePicker
              style={{ width: "100%" }}
              size="large"
              placeholder="Time To"
              format={format}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="days"
          // rules={[{ required: true, message: "Please select days" }]}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            {days.map((day, i) => (
              <CheckableTag
                key={i + Math.random()}
                checked={selectedDays.indexOf(day) > -1}
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onChange={(a) =>
                  a
                    ? setSelectedDays([...selectedDays, day])
                    : setSelectedDays([
                        ...selectedDays.filter((d) => d !== day),
                      ])
                }
              >
                <div>{day.substr(0, 3).toUpperCase()}</div>
              </CheckableTag>
            ))}
          </div>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              loading={loader}
            >
              {loader ? "Saving..." : "Save"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTimingForm;
