// Added by vinay at line no 18 to put a temorary fix of employee login otp fix, remove that once it is fixed

import axios from "axios";
import app from "../firebase";
import React, { useState } from "react";
import { Button, Form, Input, Tooltip } from "antd";
import { Link } from "react-router-dom";

import PaymentReqOtpForm from "./PaymentReqOtpForm";

import { apis } from "../shared/apis";
let sha256 = require("crypto-js/sha256");

const PaymentReqForm = (props) => {
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [isVerified, setIsVerified] = useState({
    status: false,
    message: "Verify Password",
    error: false,
  });
  const [errMsg, setErrMsg] = useState("");
  //const email = localStorage.getItem("partnerEmail");
  //const email = localStorage.getItem("partnerEmail")||localStorage.getItem("empEmail"); // temorary

  const verifyPassWord = async () => {
    if (!password) return;
    setLoader(true);
    setIsVerified({
      status: false,
      message: "Verifying Password",
      error: false,
    });
    try {
      const email = localStorage.getItem("partnerEmail");
      const empEmail = localStorage.getItem("empEmail");
      if (empEmail) {
        const hashDigest = sha256(password);
        const input = {
          email: empEmail,
          password: hashDigest.toString(),
        };
        await axios({
          ...apis.employeeLogin,
          data: input,
        }).then((res, err) => {
          if (err) {
            setLoader(false);
            setErrMsg(err?.message);
            setIsVerified({
              status: false,
              message: "Retry",
              error: true,
            });
          } else {
            setLoader(false);
            setIsVerified({
              status: true,
              message: "Verification Successful",
              error: false,
            });
          }
        });
      } else {
        await app
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((res, err) => {
            if (err) {
              setLoader(false);
              setErrMsg(err?.message);
              setIsVerified({
                status: false,
                message: "Retry",
                error: true,
              });
            } else {
              setLoader(false);
              setIsVerified({
                status: true,
                message: "Verification Successful",
                error: false,
              });
            }
          });
      }
    } catch (err) {
      if (err) {
        setLoader(false);
        setIsVerified({
          status: false,
          message: "Retry",
          error: true,
        });
        setErrMsg(err?.code);
      }
    }
  };

  return isVerified.status ? (
    <PaymentReqOtpForm {...props}></PaymentReqOtpForm>
  ) : (
    <div>
      <Form onFinish={verifyPassWord}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please Enter your password!",
            },
          ]}
          validateStatus={errMsg ? "error" : "success"}
          help={errMsg ? "Your Password didn't match" : null}
        >
          <Input.Password
            placeholder="Enter Your Jan Elaaj Password"
            size="large"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded"
            onPressEnter={verifyPassWord}
          />
        </Form.Item>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="container-fluid p-2"
        >
          <Button
            type={isVerified.error ? "danger" : "primary"}
            onClick={verifyPassWord}
            shape="round"
            loading={loader}
            htmlType="submit"
          >
            {isVerified.message}
          </Button>
          {/* <Tooltip title="Forgot Password">
            <Link to="/API">Forgot Password?</Link>
          </Tooltip> */}
        </div>
      </Form>
    </div>
  );
};

export default PaymentReqForm;
