import { Button, Card, Checkbox, Empty, message, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "../../shared/apis";

const LinkPreviousAppointments = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [pdlaIdList, setPdlaIdList] = useState([]);
  const [checkboxOptions, setCheckBoxOptions] = useState([]);
  const [checkedOptions, setCheckedBoxOptions] = useState([]);

  const formatOptions = (arr) => {
    let temp = [];
    let tempChecked = [];
    arr?.forEach((obj, i) => {
      let cbOpt = {
        label: (
          <div
            className="mx-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              fontSize: "1.2rem",
            }}
          >
            <div className="font-weght-bold mr-4">{obj?.dm_doctor_name}</div>
            <div className="text-secondary ml-4 my-2">
              {obj?.appointmentdate}
            </div>
          </div>
        ),
        value: obj?.pdlaid,
        key: obj?.pdlaid,
      };
      temp.push(cbOpt);
      if (obj?.pdla_pres_link_status === "Y") {
        tempChecked.push(cbOpt?.value);
      }
    });
    console.table(temp);
    console.log(tempChecked);
    return [temp, tempChecked];
  };

  //   const getCheckedOptions = (arr) => {
  //     let temp = [];
  //     arr.forEach((obj, i) => {
  //       if (obj?.pdla_pres_link_status === "Y") {
  //         let cbOpt = {
  //           label: (
  //             <div
  //               className="mx-4"
  //               style={{
  //                 display: "flex",
  //                 alignItems: "center",
  //                 justifyContent: "space-between",
  //                 width: "100%",
  //                 fontSize: "1.2rem",
  //               }}
  //             >
  //               <div className="font-weght-bold mr-4">{obj?.dm_doctor_name}</div>
  //               <div className="text-secondary ml-4 my-2">
  //                 {obj?.appointmentdate}
  //               </div>
  //             </div>
  //           ),
  //           value: obj?.pdlaid,
  //           key: obj?.pdlaid,
  //         };
  //         temp.push(cbOpt);
  //       }
  //     });
  //     console.table("checked", temp);
  //     return temp;
  //   };

  const handleOnchange = (v) => {
    console.table("v", v);
    setPdlaIdList([...v]);
    props?.setPdlaIdList([...v]);
  };

  const okHandler = () => {
    props?.uploadSingleSymtomsToDB({}, true);
    setShowForm(false);
  };

  useEffect(() => {
    const fetchList = async () => {
      setLoader(true);
      await axios({
        ...apis.getPatientCompletedAppointment,
        data: { patientid: props?.dependent?.patientid },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          else {
            //   console.log("res: ", res.data);
            //   setPdlaIdList(res.data.info);
            let all = formatOptions(res?.data?.info);
            setCheckBoxOptions([...all[0]]);
            setCheckedBoxOptions([...all[1]]);
            props?.setPdlaIdList([...all[1]]);
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          console.log("error : ", err);
        });
    };
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setShowForm(true);
          //   fetchList();
        }}
      >
        Link Previous Appointments
      </Button>
      <Modal
        // destroyOnClose={true}
        visible={showForm}
        onCancel={() => setShowForm(false)}
        title={<div>Select Appointments to Link</div>}
        okText={"Save"}
        okButtonProps={{ size: "large", loading: props?.updatingDb }}
        cancelButtonProps={{ size: "large" }}
        centered
        onOk={okHandler}
      >
        <Card
          loading={loader}
          bordered={false}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <div>
            {checkboxOptions?.length ? (
              <div>
                <Checkbox.Group
                  options={checkboxOptions}
                  defaultValue={checkedOptions}
                  onChange={handleOnchange}
                >
                  {/* {checkboxOptions?.map((opt, i) => (
                    <Checkbox
                      key={opt.key}
                      value={opt.value}
                      checked={opt?.pdla_pres_link_status === "Y"}
                    >
                      {opt?.label}
                    </Checkbox>
                  ))} */}
                </Checkbox.Group>
              </div>
            ) : (
              <Empty description={"No Previous Appointments Found"}></Empty>
            )}
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default LinkPreviousAppointments;
