import React from "react";
import { Card } from "antd";
import * as images from "../../shared/images";
const { Meta } = Card;

function LogoCard(props) {
  const { title } = props;
  const iconImage = (
    <img
      style={{ maxWidth: "30px" }}
      src={
        images[title?.toUpperCase()?.replaceAll(" ", "_")] || images["DOCTOR"]
      }
      alt={title?.toUpperCase()?.replaceAll(" ", "_")}
    />
  );
  return (
    <div className="logo-card-wrapper">
      <Card
        type="inner"
        hoverable
        cover={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "0.25rem",
              fontSize: "1.25rem",
            }}
          >
            {iconImage}
          </div>
        }
        className="logo-card bg-light border"
        bodyStyle={{ padding: 10 }}
        style={{ width: "150px", textAlign: "center", fontSize: "1.05rem" }}
        // title={title}
      >
        <Meta description={title?.toUpperCase()} />
      </Card>
    </div>
  );
}

export default LogoCard;
