import React, { useState } from "react";
import { Card, Button, message, Modal } from "antd";
import {
  HomeOutlined,
  UserAddOutlined,
  PhoneOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  EnvironmentOutlined,
  RedoOutlined,
  FieldTimeOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  //   UsergroupAddOutlined,
} from "@ant-design/icons";
import { isBrowser } from "react-device-detect";

import { apis } from "../../shared/apis";
import DownloadRecieptButton from "./DownloadRecieptButton";
import DownloadPrescriptionButton from "./DownloadPrescriptionButton";
import axios from "axios";
import BookAgainButton from "./BookAgainButton";
import { connect } from "react-redux";
import AppointmentDetails from "./AppointmentDetails";
const UserPastAppointmentCard = (props) => {
  // const [isDownloading, setIsDownloading] = useState(false);
  const [loadingRefundStatus, setLoadingRefundStatus] = useState(false);

  const formatTime = (time) => {
    let str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  // const input = {
  //   role: props?.role,
  //   pdla_id: props?.pdlaid,
  // };

  const handleRefundStatus = async () => {
    let input = {
      pdlat_paynow_vendor: "PAYTM", // set it as default
      pdlaid: props?.pdlaid,
    };
    setLoadingRefundStatus(true);
    await axios({ ...apis.checkPaytmRefundStatus, data: input })
      .then((res, err) => {
        setLoadingRefundStatus(false);
        if (err) message.error("Something Went Wrong");
        else {
          Modal.info({
            title: "Refund Status",
            content: res.data?.responsemsg || "No Information Available",
          });
        }
      })
      .catch((err) => {
        setLoadingRefundStatus(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  // useEffect(() => {
  //   // let doc = props?.userDash?.doctorsList?.info.filter((doc) => {
  //   //   if (doc?.ldlm_id === props?.ldlm_id) return true;
  //   //   else return false;
  //   // });
  //   // console.log("Doc: ", doc);
  // }, []);

  return (
    <div>
      <div className="my-2 p-1 appointment-card ">
        <Card
          hoverable
          type="inner"
          headStyle={{
            padding: 0,
            // backgroundColor: `${
            //   isEarly
            //     ? "#1890ff"
            //     : isOld
            //     ? "#f5222d"
            //     : fromTime > now
            //     ? "#1890ff"
            //     : isDisabled
            //     ? "#f5222d"
            //     : "#52c41a"
            // }`,
          }}
          bodyStyle={{ padding: "0.20rem" }}
          title={
            <div
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-between" : "center",
                flexDirection: isBrowser ? "row" : "column",
                fontSize: "1.25rem",
                fontWeight: "400",
                padding: "0rem 1rem",
              }}
            >
              <div>
                <HomeOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {props?.locname}
              </div>
              <div>
                <Button
                  type="link"
                  className={
                    props?.pdlappointmentstatus === 1
                      ? "text-success"
                      : "text-danger"
                  }
                  style={{ paddingLeft: "0" }}
                  icon={
                    props?.pdlappointmentstatus === 1 ? (
                      <CheckCircleOutlined style={{ display: "inline-flex" }} />
                    ) : (
                      <ExclamationCircleOutlined
                        style={{ display: "inline-flex" }}
                      />
                    )
                  }
                >
                  {props?.pdlappointmentstatus === 1
                    ? "Done"
                    : props?.pdlappointmentstatus === 2
                    ? "Doctor Not available"
                    : props?.pdlappointmentstatus === 3
                    ? "Cancelled"
                    : props?.pdlappointmentstatus === 4
                    ? "Dormant"
                    : null}
                </Button>
              </div>
              <div>
                <ClockCircleOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {formatTime(props?.timefrom)}-{formatTime(props?.timeto)}
              </div>
            </div>
          }
        >
          <div>
            {/* <div className="patient-pic col-sm-2 container-fluid">
              <div className="profile-dot">
                {pdla_user_present === "Y" ? (
                  <i className="fas fa-circle text-success"></i>
                ) : (
                  <i className="fas fa-circle text-danger"></i>
                )}
              </div>
              <img
                src={
                  pdla_symptom_photo1.length
                    ? "data:image/jpeg;base64," + pdla_symptom_photo1
                    : `https://joeschmoe.io/api/v1/${
                        gender === "M" ? "male" : "female"
                      }/${patientname}`
                }
                alt={"logo of patient"}
              ></img>
            </div> */}
            <div
              className="patient-card-body col"
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-between" : "center",
                fontSize: "1.05rem",
                flexDirection: isBrowser ? "row" : "column",
              }}
            >
              <div style={{ fontSize: "larger" }}>
                <div className="">
                  <UserAddOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.docname}
                </div>
                <div style={{ color: "gray", fontSize: "1.05rem" }}>
                  {/* {age} Yrs,{gender === "M" ? "Male" : "Female"} */}
                  <PhoneOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.doctorcontactnumber}
                </div>
              </div>
              <div>
                <div>
                  <MedicineBoxOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.pdlamomname}
                </div>
                <div>
                  <CalendarOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  />
                  {props?.appointmentdate}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
              >
                <div>
                  {props?.pdla_paynow_flag === "N" ? (
                    <span className="text-danger">
                      ₹{props?.pdladcsmamount} Unpaid
                    </span>
                  ) : (
                    <span className="text-success">
                      ₹{props?.pdladcsmamount} Paid
                    </span>
                  )}
                </div>
                <div>OTP {props?.pdlaotp}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-between" : "center",
                flexDirection: isBrowser ? "row" : "column",
                padding: "0px 15px",
              }}
            >
              <div
                // className="lead"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  fontWeight: "300",
                  // marginLeft: "1.75rem",
                }}
              >
                <EnvironmentOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {props?.locadd}
              </div>
              <div className="m-1 mr-4">{props?.role}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: isBrowser ? "center" : "flex-start",
                justifyContent: isBrowser ? "space-around" : "center",
                padding: "0.5rem",
                flexDirection: isBrowser ? "row" : "column",
              }}
            >
              <BookAgainButton
                {...props}
                {...props?.userDash}
                btnText="Book Again"
                icon={<RedoOutlined style={{ display: "inline-flex" }} />}
              ></BookAgainButton>
              {props?.role === "VIT" ||
              props?.pdlappointmentstatus === 2 ||
              props?.pdlappointmentstatus === 3 ||
              props?.pdlappointmentstatus === 4 ? null : (
                <DownloadPrescriptionButton
                  {...props}
                ></DownloadPrescriptionButton>
              )}
              {props?.pdla_paynow_flag === "Y" ? (
                <DownloadRecieptButton {...props}></DownloadRecieptButton>
              ) : null}
              {props?.pdla_paynow_flag === "Y" ? (
                <Button
                  className="my-1"
                  onClick={handleRefundStatus}
                  loading={loadingRefundStatus}
                  icon={
                    <FieldTimeOutlined style={{ display: "inline-flex" }} />
                  }
                >
                  Refund Status
                </Button>
              ) : null}
              <AppointmentDetails {...props}></AppointmentDetails>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDash: state.userDash,
  };
};

export default connect(mapStateToProps)(UserPastAppointmentCard);
