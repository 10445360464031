import React, { useState } from "react";
import { connect } from "react-redux";
import { setSelectedRole } from "../redux/ActionCreators/UIActionCreators";
import { Modal, Card, Button, message, Empty, Pagination } from "antd";
import "../assets/styles/home.scss";

import axios from "axios";
import { apis } from "../shared/apis";
import DoctorCard from "./userDashBoard/DoctorCard";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AppInviteCard from "./common/AppInviteCard";
import SpecialityList from "./SpecialityList";
import SortAndFilterForm from "./userDashBoard/SortAndFilterForm";
const { Meta } = Card;
let queryoffset = 0;
let querylimit = 50;

const TodaysSpecialCard = (props) => {
  const pageSize = 5;
  const [page, setPage] = useState(1);
  //  const [sortid, setSortId] = useState("1");
  //  const [searchFilterId, setSearchFilterId] = useState("1");
  // eslint-disable-next-line no-unused-vars

  const [loaders, setLoaders] = useState({
    locL: false,
    specL: false,
    searchL: false,
  });
  // const [cityname, setCityName] = useState("New Delhi");
  // const [cities, setCities] = useState(props.cities.cities.info);
  // const [localityname, setLocalityname] = useState("Sarita Vihar");
  const [userLocality, setUserLocality] = useState();
  const [localities, setLocalities] = useState([]);
  // const [role, setRole] = useState(props.ui?.selectedRole || "ONL");
  // const [specialityname, setSpecialityname] = useState("ENT");
  const [specialities, setSpecialities] = useState([]);
  const [docs, setDocs] = useState([]);
  const [docList, setDocList] = useState({ 1: [] });
  const [state, setState] = useState({ showDocCard: false });
  const [specialityFlag, setSpecialityFlag] = useState(false);
  const [specData, setSpecData] = useState("");

  // console.log("props of TDSC : ", props?.i, props);

  // const form = Form.useForm();
  const formatList = (arr) => {
    let len = arr?.length;
    let obj = {};
    let j = 1;
    for (let i = 0; i < len; i++) {
      if (i !== 0 && i % pageSize === 0) j++;
      if (typeof obj[j] !== "undefined") obj[j] = [...obj[j], arr[i]];
      else {
        obj[j] = [];
        obj[j] = [...obj[j], arr[i]];
      }
    }
    return obj;
  };

  const formatLocalities = (arr) => {
    let a = [];
    arr.forEach((element) => {
      let obj = {
        ...element,
        label: `${element.llocalityname} (${element.llocalitystate})`,
        value: element.llocalityname,
        key: element.llocalityid,
      };
      a.push(obj);
    });
    return a;
  };

  const fetchLocalities = async (data) => {
    setLoaders({ ...loaders, locL: true });
    await axios({ ...apis.getLocality, data: data })
      .then((res, err) => {
        setLoaders({ ...loaders, locL: false });
        if (err) message.error("Err in getLocality");
        setLocalities([...formatLocalities(res.data.info)]);
      })
      .catch((err) => {
        if (err) message.error("Err in getLocality");
        setLoaders({ ...loaders, locL: false });
      });
  };

  const fetchSpecialities = async (data) => {
    setLoaders({ ...loaders, specL: true });

    await axios({ ...apis.getspeciality, data: data })
      .then((res, err) => {
        setLoaders({ ...loaders, specL: false });

        if (err) message.error("Err in getSpeciality");

        setSpecialities(res.data.sparr);
        setSpecData(data);
      })
      .catch((err) => {
        setLoaders({ ...loaders, specL: false });
        if (err) message.error("Err in getSpecialities");
      });
  };

  const handleFinish = async (data) => {
    // Changed here by vinay on 1/7/2021 to make Online psychologist as default

    data.service = data?.service == undefined ? "OPSY" : data?.service;
    data.speciality =
      data?.specialityid == undefined ? "80010" : data?.specialityid;
    let api =
      data?.service === "ONL" ||
      data?.service === "ODIE" ||
      data?.service === "OPSY"
        ? apis.getonlinedoctorsbysearchlimit
        : apis.getdoctorsbysearchlimit;
    setLoaders({ ...loaders, searchL: true });

    await axios({
      ...api,
      data: {
        cityname: data?.city,
        dmrole: data?.service,
        localityname: userLocality?.llocalityname,
        localitylat: userLocality?.llocality_lat,
        localitylong: userLocality?.llocality_long,
        specialityid: data?.speciality,
        queryoffset: queryoffset,
        querylimit: querylimit,
        sortid: 0,
        searchfilter: 0,
        dayshortname: null,
        service_id:
          data?.service === "ONL" || data?.service === "DOC"
            ? ""
            : data?.speciality,
        ...data,
      },
    })
      .then((res, err) => {
        setLoaders({ ...loaders, searchL: false });
        if (err) message.error("Something Went Wrong");
        else {
          // console.log('res?darta?.info : ', res?.data?.info)
          setDocs(res.data.info);
          setDocList(formatList(res?.data?.info));
          setState({ ...state, showDocCard: true });
        }
      })
      .catch((err) => {
        setLoaders({ ...loaders, searchL: false });
        if (err) message.error("Something Went Wrong");
      });
  };

  const handleSpeciality = (data) => {
    fetchSpecialities({ ...data, Role: data?.dmrole });
    setSpecialityFlag(true);
  };

  // const handleRedirects = () => {
  //   let index = props?.tsd_api_keywords.findIndex(elem => elem === 'dmrole')
  //   props?.setSelectedRole(props?.tsd_api_keywords_values[index])
  //   document.querySelector('#home').scrollIntoView()
  // }

  const handleClick = () => {
    let data = { service: "OPSY" };
    props?.tsd_api_keywords?.forEach((key, i) => {
      data[key] = props?.tsd_api_keywords_values[i];
    });
    data = { ...data, service: data?.dmrole || "OPSY" };

    // if (props?.tsd_api_label === 'ONLINE DOCTORS') {
    if (props?.tsd_api_name === "getspeciality") {
      // handleRedirects();
      handleSpeciality(data);
    } else {
      if (props?.tsd_api_name === "getOnlineDoctorsBySearchLimit") {
        handleFinish(data);
        // handleRedirects();
      } else {
        message.error("Nothing To Display");
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Card
        onClick={handleClick}
        hoverable
        cover={
          <img
            src={`data:image/jpeg;base64,${props?.ts_image}`}
            alt={props?.ts_name}
            style={{ width: "100%", height: "170px" }}
          ></img>
        }
        actions={[
          <Button
            type="primary"
            shape="round"
            className="my-1"
            loading={loaders.searchL}
          >
            Consult Now
          </Button>,
        ]}
        style={{ borderRadius: "18px" }}
      >
        <Meta
          title={
            <div style={{ whiteSpace: "normal" }}>{props?.ts_description}</div>
          }
          description={props?.ts_name}
          style={{ height: "90px" }}
        ></Meta>
      </Card>
      <Modal
        visible={state.showDocCard}
        centered={true}
        className="container"
        onCancel={() => {
          setState({
            ...state,
            showDocCard: false,
          });
        }}
        onOk={() =>
          setState({
            ...state,
            showDocCard: false,
          })
        }
        width="100%"
        bodyStyle={{ padding: "0.5rem" }}
        title={` Showing ${
          (page - 1) * pageSize + docList[page]?.length <= docs.length
            ? (page - 1) * pageSize + docList[page]?.length
            : docs.length
        } out of ${docs.length} results`}
        destroyOnClose={true}
      >
        <SortAndFilterForm
          {...props}
          data={specData}
          setPage={setPage}
          handleFinish={handleFinish}
        ></SortAndFilterForm>
        {docs?.length ? (
          <div>
            <div>
              {/* {docs?.map((doc, i) => ( */}
              {docList[page] !== "undefined"
                ? docList[page]?.map((doc, i) => (
                    <div key={i + Math.random()}>
                      <DoctorCard {...props} doc={doc}></DoctorCard>
                    </div>
                  ))
                : null}
              <div className="paginition-container">
                <Pagination
                  pageSize={pageSize}
                  total={docs.length}
                  // total={Object.keys(docList).length}
                  hideOnSinglePage={true}
                  onChange={(page) => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    setPage(page);
                  }}
                  showSizeChanger={false}
                  // simple={true}
                  responsive={true}
                ></Pagination>
              </div>
            </div>
          </div>
        ) : (
          <Empty
            description={"No doctors available for your search query"}
          ></Empty>
        )}

        <div className="p-4">
          <AppInviteCard></AppInviteCard>
        </div>
      </Modal>
      <Modal
        visible={specialityFlag}
        onCancel={() => setSpecialityFlag(false)}
        title={<div>Select Speciality</div>}
        style={{ minWidth: "1000px", width: "100%" }}
        bodyStyle={{ padding: "0" }}
        centered={true}
        className="container"
        footer={[]}
      >
        <SpecialityList
          {...props}
          specialities={specialities}
          loadingSpec={loaders?.specL}
          data={specData}
          handleFinish={handleFinish}
          loaders={loaders}
        ></SpecialityList>
      </Modal>

      {/* SORT AND FILTER */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDash: state.userDash,
    // doctorsList: state.docs.docs.info,
    cities: state.cities,
    localities: state.localities,
    specialities: state.specialities,
    ui: state.ui,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSelectedRole: (data) => dispatch(setSelectedRole(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaysSpecialCard);
