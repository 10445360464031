import { combineReducers } from "redux";
import { Cities } from "./homeScreen/cities";
import { Localities } from "./homeScreen/localities";
import { Specialities } from "./homeScreen/specialities";
import { Docs } from "./homeScreen/docs";
import { Doc } from "./homeScreen/doc";
import { DocInfo } from "./verificationScreens/docinfo";
import { LocInfo } from "./verificationScreens/locinfo";
import { DocsInfo } from "./verificationScreens/docsinfo";
import { Roles } from "./verificationScreens/roles";
import { Auth } from "./verificationScreens/login";
import partnerReducer from "./homeScreen/partnerReducer";
import uiReducer from "./uiReducer";
import { hashTags } from "./homeScreen/hashtags";
import userDashReducer from "./userDashReducer";
const rootReducer = combineReducers({
  cities: Cities,
  localities: Localities,
  specialities: Specialities,
  docs: Docs,
  doc: Doc,
  docinfo: DocInfo,
  locinfo: LocInfo,
  docsinfo: DocsInfo,
  roles: Roles,
  auth: Auth,
  partnerData: partnerReducer,
  ui: uiReducer,
  hashTags: hashTags,
  userDash: userDashReducer,
});

export default rootReducer;
