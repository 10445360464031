import { Card, Tabs } from "antd";
import React from "react";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import CompletedAppointmentsList from "../CompletedAppointmentsList";
import CardHeaderCustom from "../containers/CardHeaderCustom";
import PreviousPaymentsList from "../PreviousPaymentsList";
import RequestPaymentsList from "../RequestPaymentsList";
import UnfulfilledAppointmentsList from "../UnfulfilledAppointmentsList";

const { TabPane } = Tabs;

const ReportsAndPayments = () => {
  let empRole = JSON.parse(localStorage.getItem("empRole"));
  empRole = {
    ...empRole,
    subject: empRole?.role,
    role: empRole?.role,
    id: empRole?.empid,
  };
  const tabs = [
    {
      tabName: "Completed Appointments",
      content: (
        <CompletedAppointmentsList {...empRole}></CompletedAppointmentsList>
      ),
    },
    {
      tabName: "Request Payment",
      content: <RequestPaymentsList {...empRole}></RequestPaymentsList>,
    },
    {
      tabName: "Previous Payments",
      content: <PreviousPaymentsList {...empRole}></PreviousPaymentsList>,
    },
    {
      tabName: "Unfulfilled Appointments",
      content: (
        <UnfulfilledAppointmentsList {...empRole}></UnfulfilledAppointmentsList>
      ),
    },
  ];

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/emp-dashboard",
      pathName: "Dashboard",
    },
    {
      path: "/emp-reports",
      pathName: "Reports and Payments",
    },
  ];

  return (
    <div className="container m-auto p-0">
      <Card
        style={{ margin: "50px 2%" }}
        headStyle={cardHeaderStyle}
        title={
          <CardHeaderCustom
            title="Reports and Payments"
            showPath={true}
            routes={routes}
          ></CardHeaderCustom>
        }
        bodyStyle={{ padding: "0px 10px" }}
      >
        <Tabs size="large" centered>
          {tabs.map((tab, i) => (
            <TabPane tab={tab.tabName} key={i.toString()}>
              {tab.content}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </div>
  );
};

export default ReportsAndPayments;
