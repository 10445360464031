import React, { useState } from "react";
import { Button, Empty, Tabs } from "antd";
import {
  UserOutlined,
  SelectOutlined,
  LineChartOutlined,
  SettingOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  EditOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { cardHeaderStyle } from "../assets/styles/PartnerAppStyles";
import { connect } from "react-redux";
import DocProfileForm from "./DocProfileForm";
import MySubscriptionsList from "./MySubscriptionsList";
import AppointmentList from "./AppointmentList";
import PartnerReportPaymentList from "./PartnerReportPaymentList";
import Partneroneview from "./PartnerOneView";
import CardHolderFrame from "./containers/CardHolderFrame";
import EditLoactionForm from "./intermediate/EditLoactionForm";
import Partnerdashmanagelocation from "./PartnerdashManageLocation";
import Partnerquicksetting from "./PartnerQuickSetting";

const { TabPane } = Tabs;

const Partnerservicesdashboard = (props) => {
  console.log("props dash: ", props);
  const [activeKey, setActiveKey] = useState(props?.match.params?.tabKey);
  const selectedClinic = JSON.parse(localStorage.getItem("selectedClinic"));
  const selectedRole = JSON.parse(localStorage.getItem("selectedRole"));
  const partnerEmail = localStorage.getItem("partnerEmail");
  const options = [
    {
      title: "My Profile",
      path: "/doc-profile",
      icon: UserOutlined,
      alt: "docProfile",
      component: DocProfileForm,
    },
    {
      title: "My Subscription",
      path: "/my-subscription",
      icon: SelectOutlined,
      alt: "Subscription",
      component:
        JSON.parse(localStorage.getItem("selectedClinic"))
          ?.dlm_ready_live_flag === "Y"
          ? MySubscriptionsList
          : Empty,
    },
    {
      title: "Appointments",
      path: "/appointment-list",
      icon: OrderedListOutlined,
      alt: "Appointments",
      component: AppointmentList,
    },
    {
      title: "Reports and Payments",
      path: "/reports-payments-list",
      icon: LineChartOutlined,
      alt: "Reports-Payments",
      component: PartnerReportPaymentList,
    },
    {
      title: "Manage Clinic",
      path: "/manage-clinic",
      icon: SettingOutlined,
      state: { hideSave: true },
      alt: "Manage-clinic",
      component: Partnerdashmanagelocation,
    },
    {
      title: "Edit Clinic",
      path: "/edit-clinic",
      icon: EditOutlined,
      alt: "edit-clinic",
      component: EditLoactionForm,
    },
    {
      title: "Quick Setting",
      path: "/quick-setting",
      icon: FieldTimeOutlined,
      alt: "quick-setting",
      component: Partnerquicksetting,
    },
    {
      title: "One View",
      icon: ProfileOutlined,
      alt: "One-view",
      component: Partneroneview,
    },
  ];

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/choose-option",
      pathName: " Service List",
    },
  ];

  return (
    <div className="container m-auto p-0">
      <CardHolderFrame
        headStyle={cardHeaderStyle}
        title="Services List"
        showPath={true}
        routes={routes}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Tabs
          tabPosition="left"
          size="large"
          activeKey={activeKey}
          onChange={(k) => setActiveKey(k)}
          // tabBarExtraContent={
          //   <div className="bg-danger container-fluid">
          //     <Button>Extra</Button>
          //   </div>
          // }
          // tabBarStyle={{ backgroundColor: "#f1f1f1" }}
        >
          {options.map((option, i) => (
            <TabPane
              tab={
                <div>
                  <span title={option.title}>
                    <option.icon></option.icon>
                  </span>
                  {option.title}
                </div>
              }
              key={option.title.toLowerCase().replaceAll(" ", "-")}
            >
              <option.component
                {...option}
                {...props}
                showPath={false}
                hideFrame={true}
                description={
                  "Your Profile is under Review, You will be notified once it is accepted."
                }
                selectedClinic={selectedClinic}
                selectedRole={selectedRole}
                partnerEmail={partnerEmail}
              ></option.component>
            </TabPane>
          ))}
        </Tabs>
      </CardHolderFrame>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
  };
};

export default connect(mapStateToProps)(Partnerservicesdashboard);
