import React from "react";

const Bvmnotes = () => {
  return (
    <div className="text-left" style={{ width: "fit-content", margin: "auto" }}>
      <ol
        style={{
          // textAlign: "justify",
          fontWeight: "400",
          letterSpacing: "2px",
          //   padding: "1rem 0",
          fontSize: "1.5rem",
        }}
      >
        <li>Relax, Be Calm</li>
        <li>Sit Comfortably</li>
        <li>Place should have reasonable light</li>
        <li> Please ensure there are no shadow on your face</li>
      </ol>
      <p className="text-center">Press OK to continue...</p>
    </div>
  );
};

export default Bvmnotes;
