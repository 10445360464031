import React from "react";
import { Card } from "antd";
import {
  // StarFilled,
  // DownOutlined,
  // UpOutlined,
  // EnvironmentOutlined,
  // PhoneOutlined,
  // ExperimentOutlined,
  EnvironmentFilled,
  HomeOutlined,
  UserAddOutlined,
  MedicineBoxOutlined,
} from "@ant-design/icons";
import clinicLogo from "../../assets/images/clinic_logo.png";
const ClinicCard = (props) => {
  return (
    // <div className="logo-card-wrapper">
    <Card
      type="inner"
      hoverable
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <HomeOutlined
              style={{ display: "inline-flex", marginRight: "0.5rem" }}
            />
            {props.dm_clinic}
          </div>

          <div>
            <MedicineBoxOutlined
              style={{ display: "inline-flex", marginRight: "0.5rem" }}
            ></MedicineBoxOutlined>
            {props?.dm_role}
          </div>
        </div>
      }
      className="my-2"
      bodyStyle={{ padding: "0.5rem " }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "100px" }}>
          <img src={clinicLogo} style={{ width: "100%" }} alt="cliinc"></img>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "0 1rem",
          }}
        >
          <div style={{ fontSize: "1.25rem" }}>
            <UserAddOutlined
              style={{ display: "inline-flex", marginRight: "0.5rem" }}
            ></UserAddOutlined>
            {props?.dm_doctor_name}
          </div>
          <div>
            <EnvironmentFilled
              style={{ display: "inline-flex", marginRight: "0.5rem" }}
            ></EnvironmentFilled>
            {props?.address}
          </div>
        </div>
      </div>
    </Card>
    // </div>
  );
};

export default ClinicCard;
