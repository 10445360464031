import React from "react";
import { Button, Card, Row, Col } from "antd";
import { isBrowser } from "react-device-detect";
import doc_logo from "../../assets/images/doc_logo.jpg";
function ScreeningCard(props) {
  return (
    <div className="m-2 p-1 container-fluid ">
      <Card
        title={
          <span className="text-primary" style={{ fontSize: "1.2rem" }}>
            {props.screen.mom_name}
          </span>
        }
        hoverable={true}
        type="inner"
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: isBrowser ? "row" : "column",
          }}
          gutter={16}
        >
          <Col
            span={3}
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "100px",
              textAlign: "center",
            }}
          >
            <img className="img-fluid" alt="doc" src={doc_logo}></img>
            {/* <div>Logo</div> */}
          </Col>
          <Col
            span={21}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: isBrowser ? "row" : "column",
            }}
          >
            <strong className="text-secondary m-1 mr-3">Coverage:</strong>
            <div className="screen-btn-container">
              {props.screen.coverage.map((cover, i) => (
                <div
                  key={i + Math.random()}
                  className="m-1"
                  // style={{ display: "inline-block" }}
                >
                  <Button type="primary" className="rounded">
                    {cover}
                  </Button>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default ScreeningCard;
