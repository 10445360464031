/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Upload, Image, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { userAppStorage } from "../firebase";

const AddMediaForm = (props) => {
  const fName = `${props?.tag}-${parseInt(new Date().getTime())}`;

  const [imageUrl, setImageUrl] = useState("");
  const [imageFireUrl, setImageFireUrl] = useState(null);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const uploadButton = (
    <div
      style={{
        width: "200px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Add Media</div>
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const onImageChange = (info) => {
    const reader = new FileReader();
    let file = info.file.originFileObj;
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImage(file);
        }
      };
      reader.readAsDataURL(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        uploadToFirebase(file);
      });
    } else {
      setImage(null);
    }
  };

  const uploadToFirebase = (image) => {
    if (image) {
      const storageRef = userAppStorage.ref().child("/Images");
      const imageRef = storageRef.child(`${fName}.jpg`);
      setLoading(true);
      imageRef
        .put(image)
        .then((res) => {
          setLoading(false);
          imageRef.getDownloadURL().then((url) => {
            setImageFireUrl(url);
            props?.setImageFireUrl(url);
          });

          message.success("Image uploaded successfully.");
        })
        .catch((err) => {
          setLoading(false);
          message.error("Something Went Wrong!");
        });
    } else {
      setLoading(false);
      message.error("Please upload an image first.");
    }
  };

  return (
    <div>
      {imageFireUrl ? (
        <div
          className="text-center bg-light"
          style={{ minwidth: "100px", minHeight: "100px" }}
        >
          <Image
            src={imageFireUrl}
            alt="img-uploaded"
            style={{ width: "40%" }}
          ></Image>
        </div>
      ) : (
        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={false}
          maxCount={1}
          customRequest={() => {}}
          beforeUpload={beforeUpload}
          onChange={onImageChange}
          disabled={loading}
        >
          <div>{uploadButton}</div>
        </Upload>
      )}
    </div>
  );
};

export default AddMediaForm;
