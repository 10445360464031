export const homeStyles = {
  card1: {
    title: {
      width: 500,
      marginTop: 40,
      marginBottom: 20,
      textAlign: "center",
    },
    text: {
      width: 500,
      textAlign: "left",
      marginLeft: 80,
      paddingRight: 50,
      fontSize: 15,
    },
    titleSmall: {
      fontSize: 20,
      margin: 10,
      marginTop: 30,
    },
    textSmall: {
      textAlign: "center",
      fontSize: 15,
      margin: 10,
    },
    button: {
      marginLeft: 280,
      borderRadius: 20,
      paddingLeft: 25,
      paddingRight: 25,
      fontSize: 13,
      fontWeight: "bold",
      backgroundColor: "#25a8e3",
      borderWidth: 0,
    },
    buttonSmall: {
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      align: "center",
      textAlign: "center",
      // marginLeft: 280,
      marginBottom: 20,
      borderRadius: 20,
      fontSize: 10,
      fontWeight: 800,
      backgroundColor: "#25a8e3",
      borderWidth: 0,
    },
  },
  card2: {
    title: {
      color: "white",
      fontSize: 20,
      marginTop: 30,
      marginLeft: 60,
    },
    input: {
      paddingTop: 15,
      paddingBottom: 15,
      borderWidth: 1,
      borderColor: "#aeaeae",
      borderRadius: 0,
      color: "grey",
      fontFamily: "Nunito",
      marginTop: "5px",
    },
    button: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 40,
      paddingRight: 40,
      backgroundColor: "#669ac9",
    },
    col: {
      marginLeft: -14,
      marginRight: -18,
    },
  },
  tableOne: {
    textAlign: "left",
  },
  textHeading: {
    color: "#428bca",
    fontWeight: 50,
  },
};
