import React, { Component } from "react";
import { Media, Table, Card, CardHeader, CardBody } from "reactstrap";
import { homeStyles as styles } from "../../assets/styles/homeStyles";
import { baseUrl } from "../../shared/baseUrl";
import doc2img from "../../assets/images/doc2.jpg";
import "../../assets/styles/docProfile.scss";

class DocProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      info: {},
      timings: [],
    };
    this.getDoc = this.getDoc.bind(this);
  }
  componentWillMount() {
    this.getDoc(this.props.id);
  }

  getDoc = (dlmid) => {
    fetch(baseUrl + "getdoctordetailsportal", {
      method: "post",
      body: JSON.stringify({ dlmid }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(
        (response) => {
          if (response.ok) return response;
          else {
            let error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error; // server is responding with an error response code, will be handled by catch
          }
        },
        (error) => {
          // if client unable to connect to the server
          throw new Error(error.message);
        }
      )
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          data: response.serviceinfo,
          timings: response.daytiminginfo,
          info: {
            loc: response.llocname,
            add1: response.ldoctoraddressline1,
            add2: response.ldoctoraddressline2,
            city: response.ldoctorcity,
            exp: response.ldoctorexperience,
            intro: response.ldoctorintro,
            name: response.ldoctorname,
            img: response.ldoctorphoto,
            spec: response.ldoctorspecialityname,
            pin: response.lpincode,
          },
        });
      });
    // .catch((error) => console.log(error.message));
  };

  render() {
    let NA = "Not Available";
    let info = this.state.info;
    let add = info.loc ? info.loc : "";
    add += info.add1 ? (info.loc ? ", " + info.add1 : info.add1) : "";
    add += info.add2
      ? info.loc || info.add1
        ? ", " + info.add2
        : info.add2
      : "";
    add += info.city
      ? info.loc || info.add1 || info.add2
        ? ", " + info.city
        : info.city
      : "";
    add += info.pin
      ? info.loc || info.add1 || info.add2 || info.city
        ? ", " + info.pin
        : info.pin
      : "";
    let img = info.img ? info.img : doc2img;
    const Timings = () => {
      let slots = {};
      this.state.timings.forEach((timing) => {
        let first_slot = {};
        let slot =
          timing.ldltmtimefrom.substring(0, 2) +
          ":" +
          timing.ldltmtimefrom.substring(2, 4) +
          " - " +
          timing.ldltmtimeto.substring(0, 2) +
          ":" +
          timing.ldltmtimeto.substring(2, 4);
        if (!(timing.ldldmdaynumber in slots)) {
          first_slot[slot] = timing.ldiscountnormal;
          slots[timing.ldldmdaynumber] = first_slot;
        } else slots[timing.ldldmdaynumber][slot] = timing.ldiscountnormal;
      });
      let show_slots = Object.keys(slots).map((day) => {
        let show_timings = Object.keys(slots[day]).map((slot) => {
          return (
            <div>
              <td className="profile-text">{slot}</td>
              <td className="profile-text">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {slots[day][slot]}
              </td>
            </div>
          );
        });
        return (
          <tr key={day}>
            <td className="profile-text" style={styles.tableOne}>
              {day}
            </td>
            {show_timings}
          </tr>
        );
      });
      if (this.state.timings.length) {
        return (
          <Table borderless className="table">
            <thead>
              <tr>
                <th className="profile-head-md" style={styles.textHeading}>
                  Day
                </th>
                <th className="profile-head-md" style={styles.textHeading}>
                  Slot
                </th>
                <th className="profile-head-md" style={styles.textHeading}>
                  Discount
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>{show_slots}</tbody>
          </Table>
        );
      } else return <div />;
    };
    return (
      <div>
        <Card>
          <CardHeader style={{ backgroundColor: "#2E69C9", marginBottom: 30 }}>
            <h2 className="profile-head"> Doctor Profile </h2>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div
                style={{ textAlign: "center" }}
                className="col-lg-6 col-md-12 col-sm-12"
              >
                <Media
                  object
                  src={img ? `data:image/jpeg;base64,${img}` : doc2img}
                  style={{
                    maxHeight: 250,
                    maxWidth: "100%",
                    borderRadius: "50%",
                  }}
                  alt="Doctor Image"
                />
                <hr
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #f8f8f8",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    width: "300px",
                  }}
                />
                <div style={{ marginTop: "20" }}>
                  <h5
                    className="profile-text"
                    style={{ letterSpacing: 2, fontWeight: 500 }}
                  >
                    {info.name}
                  </h5>
                  <h5
                    className="profile-text"
                    style={{ letterSpacing: 2, fontWeight: 400 }}
                  >
                    {info.spec}
                  </h5>
                </div>
                <div className="fa-2x" style={{ color: "#f4e542" }}>
                  <i className="fa fa-star" aria-hidden="true" />
                  <i className="fa fa-star" aria-hidden="true" />
                  <i className="fa fa-star" aria-hidden="true" />
                  <i className="fa fa-star" aria-hidden="true" />
                  <i className="fas fa-star-half-alt" aria-hidden="true" />
                </div>
                <div className="fa-2x" style={{ color: "#419df4" }}>
                  <i className="far fa-circle" />
                  <i className="far fa-circle" />
                  <i className="far fa-circle" /> <i className="fa fa-circle" />
                  <i className="far fa-circle" />
                  <i className="far fa-circle" />
                  <i className="far fa-circle" />
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h5 className="profile-head-md" style={styles.textHeading}>
                  About
                </h5>
                <p className="profile-text">
                  {info.intro
                    ? info.intro
                    : "I am a qualified doctor practising at this clinic and am eagerly waiting to treat you.\nPlease do get in touch with me."}
                </p>
                <br />
                <h5 className="profile-head-md" style={styles.textHeading}>
                  Experience
                </h5>
                <p className="profile-text">
                  {info.exp ? info.exp + " years" : NA}
                </p>
                <br />
                <h5 className="profile-head-md" style={styles.textHeading}>
                  Address
                </h5>
                <p className="profile-text"> {add ? add : NA} </p>
                <br />
                <Table borderless className="table">
                  <thead>
                    <tr>
                      <th
                        className="profile-head-md"
                        style={styles.textHeading}
                      >
                        Service
                      </th>
                      <th
                        className="profile-head-md"
                        style={styles.textHeading}
                      >
                        Rates
                      </th>
                      <th
                        className="profile-head-md"
                        style={styles.textHeading}
                      >
                        Discounted Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {this.state.data.map((serviceinfo) => (
                      <tr key={serviceinfo.lsmserviceid}>
                        <td className="profile-text" style={styles.tableOne}>
                          {serviceinfo.lsmservicename}
                        </td>
                        <td className="profile-text">
                          {serviceinfo.ldcsmnormalamount}
                        </td>
                        <td className="profile-text">
                          {serviceinfo.ldcsmdiscountedamount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <br />
                <Timings />
                <br />
                <h5 className="profile-head-md" style={styles.textHeading}>
                  Contact
                </h5>
                {"  "}
                <p className="profile-text"> +91 11-41025512 </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default DocProfile;
