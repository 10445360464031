// Edited by vinay on 1/7/2021 , to make online psychologist as default for Online section

import React, { useEffect, useState } from "react";
import {
  Modal,
  Select,
  Form,
  Button,
  message,
  Empty,
  Pagination,
  Tabs,
} from "antd";
import { homeStyles as styles } from "../../assets/styles/homeStyles";
import "../../assets/styles/home.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AppInviteCard from "./AppInviteCard";
import axios from "axios";
import { apis } from "../../shared/apis";
import DoctorCard from "../userDashBoard/DoctorCard";
import SortAndFilterForm from "../userDashBoard/SortAndFilterForm";
import { connect } from "react-redux";
import { setSelectedRole } from "../../redux/ActionCreators/UIActionCreators";
import { setSpeciality } from "../../redux/ActionCreators/UserDashActionCreator";
const { Option } = Select;
let queryoffset = 0;
let querylimit = 50;
const SearchServices = (props) => {
  const pageSize = 5;
  const [page, setPage] = useState(1);
  //  const [sortid, setSortId] = useState("1");
  //  const [searchFilterId, setSearchFilterId] = useState("1");
  // eslint-disable-next-line no-unused-vars
  const initLocal = {
    label: "Select locality",
    value: "Select locality",
    key: "Select locality",
  };
  const [loaders, setLoaders] = useState({
    locL: false,
    specL: false,
    searchL: false,
  });
  const [cityname, setCityName] = useState("New Delhi");
  const [cities, setCities] = useState(props?.cities?.cities?.info);
  const [localityname, setLocalityname] = useState(initLocal);
  const [userLocality, setUserLocality] = useState();
  const [localities, setLocalities] = useState([]);
  const [role, setRole] = useState(props.ui?.selectedRole || "OPSY");
  // const [specialityname, setSpecialityname] = useState('Consultation')
  const [specialityname, setSpecialityname] = useState("Select Speciality");
  const [specialities, setSpecialities] = useState(
    props?.specialities?.specialities?.sparr
  );
  const [docs, setDocs] = useState([]);
  const [docList, setDocList] = useState({ 1: [] });
  const [state, setState] = useState({ showDocCard: false });
  const [specData, setSpecData] = useState("");
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [formOff] = Form.useForm();
  const formatList = (arr) => {
    let len = arr?.length;
    let obj = {};
    let j = 1;
    for (let i = 0; i < len; i++) {
      if (i !== 0 && i % pageSize === 0) j++;
      if (typeof obj[j] !== "undefined") obj[j] = [...obj[j], arr[i]];
      else {
        obj[j] = [];
        obj[j] = [...obj[j], arr[i]];
      }
    }
    return obj;
  };

  const formatLocalities = (arr) => {
    let a = [];
    arr?.forEach((element) => {
      let obj = {
        ...element,
        label: `${element.llocalityname} (${element.llocalitystate})`,
        value: element.llocalityname,
        key: element.llocalityid,
      };
      a.push(obj);
    });
    return a;
  };

  const fetchLocalities = async (data) => {
    setLoaders({ ...loaders, locL: true });
    await axios({ ...apis.getLocality, data: data })
      .then((res, err) => {
        setLoaders({ ...loaders, locL: false });
        if (err) message.error("Err in getLocality");
        setLocalities([...formatLocalities(res.data.info)]);
      })
      .catch((err) => {
        if (err) message.error("Err in getLocality");
        setLoaders({ ...loaders, locL: false });
      });
  };

  const fetchSpecialities = async (data) => {
    setLoaders({ ...loaders, specL: true });

    await axios({ ...apis.getspeciality, data: data })
      .then((res, err) => {
        setLoaders({ ...loaders, specL: false });

        if (err) message.error("Err in getSpeciality");

        setSpecialities(res.data.sparr);
      })
      .catch((err) => {
        setLoaders({ ...loaders, specL: false });

        if (err) message.error("Err in getSpecialities");
      });
  };

  const sendToResultPage = (data) => {
    let input = JSON.stringify(data);
    props.history.push(`/doctors-list-result-page/${input}`);
  };

  const handleFinish = async (data) => {
    // Changed here by vinay on 1/7/2021 to make Online psychologist as default
    data.service = data.service == undefined ? "OPSY" : data.service;
    data.speciality = data.speciality == undefined ? "80010" : data.speciality;
    // let api =
    //   data?.service === "ONL" ||
    //   data?.service === "ODIE" ||
    //   data?.service === "OPSY"
    //     ? apis.getonlinedoctorsbysearchlimit
    //     : apis.getdoctorsbysearchlimit;
    let api =
      data?.service === "ONL" ||
      data?.service === "ODIE" ||
      data?.service === "OPSY"
        ? "getonlinedoctorsbysearchlimit"
        : "getdoctorsbysearchlimit";

    let input = {
      api: api,
      cityname: data?.city,
      dmrole: data?.service,
      localityname: userLocality?.llocalityname,
      localitylat: userLocality?.llocality_lat,
      localitylong: userLocality?.llocality_long,
      specialityid: data?.speciality,
      queryoffset: queryoffset,
      querylimit: querylimit,
      sortid: 0,
      searchfilter: 0,
      dayshortname: null,
      service_id:
        data?.service === "ONL" || data?.service === "DOC"
          ? ""
          : data?.speciality,
      ...data,
    };
    sendToResultPage(input);
    // setLoaders({ ...loaders, searchL: true });

    // await axios({
    //   ...api,
    //   data: {
    //     cityname: data?.city,
    //     dmrole: data?.service,
    //     localityname: userLocality?.llocalityname,
    //     localitylat: userLocality?.llocality_lat,
    //     localitylong: userLocality?.llocality_long,
    //     specialityid: data?.speciality,
    //     queryoffset: queryoffset,
    //     querylimit: querylimit,
    //     sortid: 0,
    //     searchfilter: 0,
    //     dayshortname: null,
    //     service_id:
    //       data?.service === "ONL" || data?.service === "DOC"
    //         ? ""
    //         : data?.speciality,
    //     ...data,
    //   },
    // })
    //   .then((res, err) => {
    //     setLoaders({ ...loaders, searchL: false });
    //     if (err) message.error("Something Went Wrong");

    //     setDocs(res.data.info);
    //     setDocList(formatList(res.data.info));
    //     setState({ ...state, showDocCard: true });
    //   })
    //   .catch((err) => {
    //     setLoaders({ ...loaders, searchL: false });
    //     if (err) message.error("Something Went Wrong");
    //   });
  };

  // useEffect(() => {
  //   setCities(props.cities.cities.info)
  //   // if (specialities?.length === 0)
  //   // fetchSpecialities({ Role: role })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.cities.cities.info])

  return (
    <div
      className="m-4  search-service-box"
      style={{ borderRadius: "50px", backgroundColor: "white" }}
    >
      <div className="container-fluid search-services-container">
        <div
          className="search-search-title container-fluid"
          style={{ color: "#007bff" }}
        >
          Search Services Near You Doctor(s)
        </div>
        <div className="row mx-0 container-fluid search-tabs-container">
          <Tabs
            style={{ width: "100%", backgroundColor: "" }}
            size="large"
            tabBarStyle={{
              color: "black",
              fontSize: "1.5rem",
              fontWeight: "bold",
              paddingLeft: "3rem",
            }}
            // type="card"
            // centered
            tabBarGutter={50}
          >
            <TabPane tab="Online" key="online">
              <div>
                <Form
                  form={form}
                  className="col row mx-0"
                  onFinish={(data) => {
                    handleFinish(data);
                    // setSpecData(data);
                    // sendToResultPage(data);
                  }}
                  initialValues={{
                    citi: cityname,
                    service: role,
                    speciality: specialityname,
                  }}
                  style={{
                    // display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    // padding: "0.75rem",
                  }}
                  // form={form}
                >
                  <div className="col-sm row mx-0 p-2 ">
                    <Form.Item
                      name="service"
                      className="col-sm m-1"
                      // rules={[
                      //   { required: true, message: "Please select Service" },
                      // ]}
                    >
                      <Select
                        style={{ fontWeight: "bold", color: "black" }}
                        showSearch
                        defaultValue={"OPSY"}
                        placeholder="Select Role"
                        value={role}
                        size="large"
                        type="select"
                        name="service"
                        onChange={(event) => {
                          form.setFieldsValue({
                            speciality: "Select Speciality",
                          });
                          setSpecialityname("Select Speciality");
                          setRole(event);
                          props.setSelectedRole(event);
                          fetchSpecialities({ Role: event });
                        }}
                      >
                        <Option value="ONL"> Online Doctors </Option>
                        <Option value="ODIE">Online Dietitian </Option>
                        <Option value="OPSY">Online Psycologist </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="speciality"
                      className="col-sm m-1"
                      rules={[
                        { required: true, message: "Please select Speciality" },
                      ]}
                    >
                      <Select
                        style={{ fontWeight: "bold", color: "black" }}
                        showSearch
                        loading={loaders.specL}
                        // OptionFilterProp="children"
                        filterOption={(input, Option) =>
                          Option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select Speciality"
                        // defaultValue={specialityname}
                        // defaultValue={'Consultation'}
                        size="large"
                        name="speciality"
                        value={specialityname}
                        onChange={(event) => {
                          props.setSpeciality({
                            id: event,
                            name: specialityname,
                          });
                          setSpecialityname(event);
                        }}
                      >
                        {specialities?.length && !loaders.specL ? (
                          specialities?.map((speciality, index) => (
                            <Option
                              value={speciality.id}
                              key={index + Math.random()}
                            >
                              {speciality.name}
                            </Option>
                          ))
                        ) : (
                          <Option value="Loading...">Loading...</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item className="col-sm-2 my-1 text-center">
                    <Button
                      loading={loaders.searchL}
                      style={{
                        ...styles.card2.button,
                        padding: "3px 30px",
                        backgroundColor: "yellow",
                        color: "black",
                      }}
                      size="large"
                      shape="round"
                      htmlType="submit"
                      // className=" btn btn-primary mt-4 mt-lg-auto round search-btn"
                      // onClick={handleSearch}
                    >
                      {loaders.searchL ? "Searching..." : "Search"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            <TabPane tab="Offline" key="offline">
              <div className="row mx-0 container-fluid">
                <Form
                  form={formOff}
                  className="col row mx-0"
                  onFinish={(data) => {
                    handleFinish(data);
                    setSpecData(data);
                  }}
                  initialValues={{ citi: cityname }}
                  style={{
                    // display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    // padding: "0.75rem",
                  }}
                  // form={form}
                >
                  <div className="col-sm row mx-0 p-2 ">
                    <Form.Item
                      name="city"
                      className="col-sm my-1"
                      rules={[
                        {
                          required: role === "ONL" ? false : true,
                          message: "Please select city",
                        },
                      ]}
                    >
                      <Select
                        style={{ fontWeight: "bold" }}
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Select City"
                        name="city"
                        id="city"
                        value={cityname}
                        onChange={(event) => {
                          formOff.setFieldsValue({
                            locality: initLocal,
                          });
                          setCityName(event);
                          fetchLocalities({ cityname: event });
                        }}
                      >
                        {cities?.length ? (
                          cities.map((city, index) => (
                            <Option
                              value={city.lcityname}
                              key={index + Math.random()}
                            >
                              {city.lcityname}
                            </Option>
                          ))
                        ) : (
                          <Option value="Loading...">Loading...</Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="locality"
                      className="col-sm m-1"
                      rules={[
                        {
                          required: role === "ONL" ? false : true,
                          message: "Please select Locality",
                        },
                      ]}
                    >
                      <Select
                        style={{ fontWeight: "bold" }}
                        showSearch
                        loading={loaders.locL}
                        // OptionFilterProp="children"
                        // filterOption={(input, Option) =>
                        //   Option.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // }
                        size="large"
                        placeholder="Select Locality"
                        name="locality"
                        id="locality"
                        value={localityname}
                        onChange={(event) => {
                          let selectedLoc = localities.filter(
                            (l) => l?.llocalityname === event?.value
                          );

                          setUserLocality({ ...selectedLoc[0] });
                          setLocalityname(event);
                        }}
                        options={localities}
                        labelInValue={true}
                      ></Select>
                    </Form.Item>
                    <Form.Item
                      name="service"
                      className="col-sm m-1"
                      rules={[
                        { required: true, message: "Please select Service" },
                      ]}
                    >
                      <Select
                        style={{ fontWeight: "bold" }}
                        showSearch
                        placeholder="Select Role"
                        value={role}
                        size="large"
                        type="select"
                        name="service"
                        onChange={(event) => {
                          formOff.setFieldsValue({
                            speciality: "Select Speciality",
                          });
                          setRole(event);
                          fetchSpecialities({ Role: event });
                        }}
                      >
                        <Option value="DOC"> Doctors </Option>
                        <Option value="PSY"> Psychologist </Option>
                        {/* <Option value="ONL"> Online Doctors </Option> */}
                        <Option value="VIT"> Vital </Option>
                        <Option value="RAD"> Radiology </Option>
                        <Option value="DIE"> Dietitian </Option>
                        <Option value="PHY"> Physiotherapist </Option>
                        <Option value="PAT"> Pathology </Option>
                        <Option value="CHE"> Chemist </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="speciality"
                      className="col-sm m-1"
                      rules={[
                        { required: true, message: "Please select Speciality" },
                      ]}
                    >
                      <Select
                        style={{ fontWeight: "bold", color: "black" }}
                        showSearch
                        loading={loaders.specL}
                        // OptionFilterProp="children"
                        filterOption={(input, Option) =>
                          Option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select Speciality"
                        size="large"
                        name="speciality"
                        value={specialityname}
                        onChange={(event) => {
                          setSpecialityname(event);
                        }}
                      >
                        {specialities?.length ? (
                          specialities?.map((speciality, index) => (
                            <Option
                              value={speciality.id}
                              key={index + Math.random()}
                            >
                              {speciality.name}
                            </Option>
                          ))
                        ) : (
                          <Option value="Loading...">Loading...</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item className="col-sm-2 my-1 text-center">
                    <Button
                      loading={loaders.searchL}
                      style={{
                        ...styles.card2.button,
                        padding: "3px 30px",
                        backgroundColor: "yellow",
                        color: "black",
                      }}
                      size="large"
                      shape="round"
                      htmlType="submit"
                      // className=" btn btn-primary mt-4 mt-lg-auto round search-btn"
                      // onClick={handleSearch}
                    >
                      {loaders.searchL ? "Searching..." : "Search"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>

      <Modal
        visible={state.showDocCard}
        centered={true}
        className="container"
        onCancel={() =>
          setState({
            ...state,
            showDocCard: false,
          })
        }
        onOk={() =>
          setState({
            ...state,
            showDocCard: false,
          })
        }
        width="100%"
        bodyStyle={{ padding: "0.5rem" }}
        title={`Showing ${
          (page - 1) * pageSize + docList[page]?.length <= docs.length
            ? (page - 1) * pageSize + docList[page]?.length
            : docs.length
        } out of ${docs.length} results`}
      >
        <SortAndFilterForm
          data={specData}
          setPage={setPage}
          handleFinish={handleFinish}
        ></SortAndFilterForm>

        {docs?.length ? (
          <div>
            <div>
              {/* {docs?.map((doc, i) => ( */}
              {docList[page] !== "undefined"
                ? docList[page]?.map((doc, i) => (
                    <div key={i + Math.random()}>
                      <DoctorCard {...props} doc={doc}></DoctorCard>
                    </div>
                  ))
                : null}
              <div className="paginition-container">
                <Pagination
                  pageSize={pageSize}
                  total={docs.length}
                  // total={Object.keys(docList).length}
                  hideOnSinglePage={true}
                  onChange={(page) => {
                    setPage(page);
                  }}
                  showSizeChanger={false}
                  // simple={true}
                  responsive={true}
                ></Pagination>
              </div>
            </div>
          </div>
        ) : (
          <Empty
            description={"No doctors available for your search query"}
          ></Empty>
        )}

        <div className="p-4">
          <AppInviteCard></AppInviteCard>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorsList: state.docs.docs.info,
    cities: state.cities,
    localities: state.localities,
    specialities: state.specialities,
    ui: state.ui,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSpeciality: (data) => dispatch(setSpeciality(data)),
    setSelectedRole: (data) => dispatch(setSelectedRole(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchServices);
