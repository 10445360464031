import { Empty, Table } from "antd";
import React, { useEffect, useState } from "react";
// const { Meta } = Card;
const ListofTimmings = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const formatTime = (time) => {
    let str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  const formatDisc = (norm, disc) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div className="text-info mx-2">
        {props?.isChemist ? "%" : "₹"}
        {disc}
      </div>
      <div
        className="text-secondary mx-2"
        style={{
          textDecoration: "line-through",
          fontSize: "0.85rem",
        }}
      >
        {props?.isChemist ? "%" : "₹"}
        {norm}
      </div>
      {props?.isChemist ? null : (
        <div
          className="text-success"
          style={{ fontSize: "0.95rem", whiteSpace: "nowrap" }}
        >
          {parseInt(((norm - disc) / norm) * 100)}% off
        </div>
      )}
    </div>
  );

  useEffect(() => {
    // let arr = {
    //   MON: { slot: [], discount: [] },
    //   TUE: { slot: [], discount: [] },
    //   WED: { slot: [], discount: [] },
    //   THU: { slot: [], discount: [] },
    //   FRI: { slot: [], discount: [] },
    //   SAT: { slot: [], discount: [] },
    //   SUN: { slot: [], discount: [] },
    // };
    let arr = {};
    props?.daytiminginfo?.forEach((data, i) => {
      arr[data.ldldmdaynumber] = { slot: [], discount: [] };
    });
    props?.daytiminginfo?.forEach((data, i) => {
      let obj = {
        ...data,
        key: i + Math.random(),
        slot: [
          ...arr[data.ldldmdaynumber]?.slot,
          `${formatTime(data?.ldltmtimefrom)} - ${formatTime(
            data?.ldltmtimeto
          )}`,
        ],
        discount: [
          ...arr[data.ldldmdaynumber]?.discount,
          data?.ldiscountnormal || "--",
        ],
      };
      arr[data.ldldmdaynumber] = { ...arr[data.ldldmdaynumber], ...obj };
    });
    setDataSource(Object.values(arr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: "Day",
      dataIndex: "ldldmdaynumber",
      key: "ldldmdaynumber",
      align: "center",
      className: "text-primary",
    },
    {
      title: "Slot",
      dataIndex: "slot",
      key: "slot",
      render: (text, record, index) => (
        <div>
          {record.slot?.map((slot, i) => (
            <div key={i + Math.random()}>{slot}</div>
          ))}
        </div>
      ),
      align: "center",
    },
    {
      title: props?.isChemist ? "Discount" : "Amount",
      dataIndex: "discount",
      key: "discount",
      render: (text, record, index) => (
        <div>
          {record.discount?.map((disc, i) => (
            <div key={i + Math.random()}>
              {disc === "Normal" ? (
                <div>
                  {" "}
                  {props?.isChemist ? "%" : "₹"}
                  {props?.serviceinfo[0]?.ldcsmnormalamount + " "}
                </div>
              ) : (
                formatDisc(
                  props?.serviceinfo[0]?.ldcsmnormalamount,
                  props?.serviceinfo[0]?.ldcsmdiscountedamount
                )
              )}
            </div>
          ))}
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {props?.daytiminginfo?.length ? (
        <div>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      ) : (
        <Empty description="No Timmings Found"></Empty>
      )}
    </div>
  );
};

export default ListofTimmings;
