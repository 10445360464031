import React, { useState, useEffect } from "react";
import { Button, Card, Input, Upload, message, Image } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { janElaajStorage } from "../../firebase";

const AddSymptomCard = (props) => {
  // console.log("Props: ASS: ", props);
  const [imageUrl, setImageUrl] = useState(props?.symptom_image_url);
  // eslint-disable-next-line no-unused-vars
  const [imageFireUrl, setImageFireUrl] = useState("");
  const [image, setImage] = useState(props?.symptom_image);
  const [caption, setCaption] = useState(props?.symptom_caption);
  const [loading, setLoading] = useState(false);
  const uploadButton = (
    <div
      style={{
        width: "200px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Add Symptom</div>
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const onImageChange = (info) => {
    const reader = new FileReader();
    let file = info.file.originFileObj;
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImage(file);
        }
      };
      reader.readAsDataURL(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        let data = {
          symptom_image_url: imageUrl,
          symptom_caption: caption,
          symptom_file_name: `${props?.pdlaid}_symptom_${props?.index + 1}.jpg`,
          // symptom_id: parseInt(Math.random() * 100) + parseInt(Math.random() * 100),
          symptom_id: -1,
          id: -1,
          symptom_media_type: 0,
          symptom_count: props?.index + 1,
          symptom_image: info.file.originFileObj,
          // symptom_id: "",
        };
        props?.changeSymptomHandler(props?.index, data);
      });
    } else {
      setImage(null);
    }
  };

  const uploadToFirebase = () => {
    if (image) {
      const storageRef = janElaajStorage.ref().child("/patient_symptom_images");
      const imageRef = storageRef.child(
        `${props?.pdlaid}_symptom_${props?.index + 1}.jpg`
      );
      setLoading(true);
      imageRef
        .put(image)
        .then(() => {
          setLoading(false);

          // let imgFurl = `https://firebasestorage.googleapis.com/v0/b/janelaajpublicbucket/o/patient_symptom_images%2F${
          //   props?.pdlaid
          // }_symptom_${
          //   props?.index + 1
          //   }.jpg?alt=media&token=b41ae6d5-bcc0-4964-a3df-2a341510ca9b`;
          imageRef.getDownloadURL().then((imgFurl) => {
            setImageFireUrl(imgFurl);
            sendData(imgFurl);
            message.success("Image uploaded successfully.");
          });
        })
        .catch((err) => {
          setLoading(false);
          message.error("Something Went Wrong!");
        });
    } else {
      setLoading(false);
      message.error("Please upload an image first.");
    }
  };

  const deleteHandler = () => {
    props.popSymptom(props?.index);
  };

  const sendData = async (url) => {
    let data = {
      symptom_image_url: url,
      symptom_caption: caption,
      symptom_file_name: `${props?.pdlaid}_symptom_${props?.index + 1}.jpg`,
      // symptom_id: parseInt(Math.random() * 100) + parseInt(Math.random() * 100),
      // symptom_id: null,
      symptom_id: -1,
      id: -1,
      symptom_media_type: 0,
      symptom_count: props?.index + 1,
      // symptom_id: "",
    };
    props?.changeSymptomHandler(props?.index, data);
    await props?.uploadSingleSymtomsToDB(data);
  };

  useEffect(() => {
    setCaption(props?.symptom_caption);
    //  setSymptomsTitle(props?.pdla_symptoms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card key={props?.symptom_id} hoverable bodyStyle={{ padding: "0.25rem" }}>
      <div
        className="m-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          minHeight: "200px",
          textAlign: "center",
        }}
      >
        {imageUrl ? (
          <div
            style={{
              padding: "0.5rem",
              width: "100%",
              // height: "200px",
            }}
          >
            <Image
              src={imageUrl}
              alt="avatar"
              style={{ width: "100%", maxHeight: "250px" }}
            />
          </div>
        ) : (
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            customRequest={() => {}}
            beforeUpload={beforeUpload}
            // onChange={handleChange}
            onChange={onImageChange}
          >
            <div>{uploadButton}</div>
          </Upload>
        )}
      </div>

      <div
        className="m-2 p-1"
        style={
          {
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
          }
        }
      >
        <div>
          <label>Caption:</label>
        </div>
        <div>
          <Input
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          ></Input>
        </div>
      </div>
      <div
        className="m-1 p-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          className="m-1"
          loading={loading}
          type="primary"
          onClick={uploadToFirebase}
        >
          {loading ? "Uploading..." : "Upload"}
        </Button>
        <Button className="m-1" type="danger" onClick={deleteHandler}>
          Delete
        </Button>
      </div>
    </Card>
  );
};

export default AddSymptomCard;
