// Edited by vinay on 06/07/2021,  to fix the flow of doc regestration

import React, { useState } from "react";
import { Button, Card, Image, Input, Modal, message } from "antd";
import Meta from "antd/lib/card/Meta";
import axios from "axios";
import { apis } from "../../shared/apis";
import doc_card from "../../assets/images/doc_card.png";
import { partnerFIRApp } from "../../firebase";
import { connect } from "react-redux";
const md5 = require("md5");
// import * as firebase from "firebase";

// const actionCodeSettings = {
//   // URL you want to redirect back to. The domain (www.example.com) for this
//   // URL must be in the authorized domains list in the Firebase Console.
//   url: "https://janelaaj.com",
//   // This must be true.
//   handleCodeInApp: true,
//   iOS: {
//     bundleId: "com.janelaaj.ios",
//   },
//   android: {
//     packageName: "com.janelaaj.android",
//     installApp: true,
//     minimumVersion: "12",
//   },
//   dynamicLinkDomain: "janelaaj.page.link",
// };

// const config = {
//   apiKey: "AIzaSyCes5qTs-43uN9XokROLqHHGx-KVlIuv48",
//   authDomain: "",
//   databaseURL: "https://jeemailverification.firebaseio.com",
//   projectId: "jeemailverification",
//   storageBucket: "jeemailverification.appspot.com",
//   messagingSenderId: "",
//   appId: "1:116427726523:android:cadef498b06b0ab5",
//   measurementId: "",
// };
// const fireApp = firebase.initializeApp(config);

const PartnerSignUpOtpForm = (props) => {
  // console.log(props)
  // const [otpCorrect, setOtpCorrect] = useState(props.otp);
  const [otp, setOtp] = useState("");
  // const [loader, setLoader] = useState(false);
  // const [otpInput, setOtpInput] = useState("");
  const [verify, setVerify] = useState({
    verifying: false,
    status: "OTP Sent !",
    type: "primary",
  });

  //   const deadline = Date.now() + 60 * 60 * 24 * 7;
  // const deadline = Date.now() + 10 * 60 * 1000;
  // const dlm_id = JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid;
  // const mobileno = JSON.parse(localStorage.getItem("selectedRole"))?.mobileno;
  // const role = JSON.parse(localStorage.getItem("selectedRole"))?.role;

  // const [isOtpExpired, setIsOtpExpired] = useState(false);

  const getSpecialityID = (name) => {
    let id;
    props?.specialitiesList?.forEach((spc, i) => {
      if (spc.name === name) {
        id = spc?.id;
      }
    });

    return id;
  };

  const registerUser = async (shouldVerify, apiData) => {
    // setLoader(true);

    let userObj = {
      pldrole: props?.partnerData?.role,
      name: props?.partnerData?.fullname,
      email: props?.partnerData?.email,
      password: md5(props?.partnerData?.password),
      mobile: props?.partnerData?.phone,
      image: "",
      dob: props?.partnerData?.dob?.format("DDMMYYYY"),
      gender: props?.partnerData?.gender,
      specialityid: getSpecialityID(props?.partnerData?.speciality),
    };

    let obj = apiData
      ? {
          ...apiData,
          specialityid: getSpecialityID(props?.partnerData?.speciality),
        }
      : userObj;
    let api =
      obj.pldrole === "ONL" || obj.pldrole === "DOC"
        ? apis.registerUser
        : apis.vitalregiteruser;
    let finalObj = { ...userObj, ...obj };
    finalObj.dob = finalObj.dob.split("-").join("");
    // let isVital = obj.pldrole === "ONL" || obj.pldrole === "DOC" ? false : true;
    await axios({ ...api, data: finalObj })
      .then((res, err) => {
        if (err) {
          message.error(err?.message || "Something Went Wrong");
          // console.log("Err in Reg usr with res : ", res, err);
        } else {
          if (res.data.status !== "SUCCESS") {
            message.error(res.data.status);
          }

          if (shouldVerify) {
            partnerFIRApp
              .auth()
              .createUserWithEmailAndPassword(
                props?.partnerData?.email,
                props?.partnerData?.password
              )
              .then((userCredential) => {
                var user = userCredential.user;
                user
                  .sendEmailVerification()
                  .then(function () {
                    // Email sent.
                    // console.log('Email Sent')
                    message.success("Successfully Sent Verificaion Email");
                    setVerify({
                      verifying: false,
                      status: "Verified",
                      type: "success",
                    });
                    props.history.push("/partner-login");
                  })
                  .catch(function (error) {
                    // An error happened.
                    message.error("Something went wrong");
                  });
              });
          } else {
            message.success("Successfully Resgistered");
            props.history.push("/partner-login");
          }
          /*partnerFIRApp
            .auth()
            .createUserWithEmailAndPassword(
              props?.partnerData?.email,
              props?.partnerData?.password
            )
            .then((userCredential) => {
              // Signed in
              // setLoader(false);
              var user = userCredential.user;
              // var user = firebase.auth().currentUser;

              if (shouldVerify) {
                user
                  .sendEmailVerification()
                  .then(function () {
                    // Email sent.
                    // console.log("Email Sent");
                  })
                  .catch(function (error) {
                    // An error happened.
                  });
              }
              message.success("Successfully Resgistered");
              setVerify({
                verifying: false,
                status: "Verified",
                type: "success",
              });
              // localStorage.setItem("partnerEmail", props?.partnerData?.email);
              // localStorage.setItem("token", user?.ya);
              // props.history.push("/roles-list");
              props.history.push("/partner-login");
              // ...
            })
            .catch((error, s) => {
              message.error("Something went wrong");

              // var errorCode = error.code;
              // var errorMessage = error.message;
              // console.log("Errin crtg usr: ", errorMessage);

              // setLoader(false);
              message.success("Successfully Resgistered");
              setVerify({
                verifying: false,
                status: "Verified",
                type: "success",
              });
              // localStorage.setItem("partnerEmail", props?.partnerData?.email);
              // localStorage.setItem("token", "user?.ya");
              // props.history.push("/roles-list");
              props.history.push("/partner-login");
            });*/
        }
      })

      .catch((err) => {
        if (err) {
          // console.log('err catch :', err)
          message.error(err?.message || "Something Went Wrong");
        }
      });
  };

  const postData = async () => {
    // setLoader(true);
    await axios({
      ...apis.vitalsignupinfo,
      data: { useremail: props?.partnerData?.email },
    })
      .then((res, err) => {
        if (err) {
          message.error("Something went wrong");
          // setLoader(false);
          // console.log("Err in vitalSignupInfo: ", err);
        } else {
          // setLoader(false);
          // console.log("Res vitalsignupinfo :", res);
          if (res.data.flag === "N") {
            Modal.info({
              title: "Please Verify Your Email",
              content: (
                <div style={{}}>
                  A verification link has been sent to your email
                  <b className="text-primary ml-1">
                    {" "}
                    {props?.partnerData?.email}
                  </b>
                </div>
              ),
              onOk: () => registerUser(true, false),
            });
          } else {
            // message.error("Email Already Registered !");
            registerUser(false, { ...res.data });
          }
        }
      })
      .catch((err) => {
        if (err) {
          // setLoader(false);
          // console.log("Err in vitalSignupInfo: ", err);
        }
      });
  };

  const verifyOtpHandler = async () => {
    setVerify({ verifying: true, status: "Verifying...", type: "primary" });
    if (props.otp + "" === otp) {
      postData();
    } else {
      setVerify({
        verifying: false,
        status: "Incorrect OTP. Please retry ",
        type: "danger",
      });
    }
  };

  // const getOTP = () => {
  //   let a = parseInt(Math.random() * 10);
  //   let b = parseInt(Math.random() * 10);
  //   let c = parseInt(Math.random() * 10);
  //   let d = parseInt(Math.random() * 10);

  //   // let otp = a * 1000 + b * 100 + c * 10 + d;
  //   let otp = a + "" + b + "" + c + "" + d;
  //   // console.log("OTP: ", otp);
  //   // setOtpCorrect(otp);
  //   return otp;
  // };

  // useEffect(() => {
  //   (async function () {
  //     setState({
  //       sendingOtp: true,
  //       status: "Sending OTP",
  //       verifying: false,
  //     });
  //     await axios({
  //       ...apis.textGuru,
  //       url: `${apis.textGuru.url}&dmobile=91${
  //         props.mobile
  //       }&message=${getOTP()} is Your JanElaaj Registration Code.`,
  //     })
  //       .then((res, err) => {
  //         // if (err) {
  //         //   console.log("Err in sending OTP: ", err);
  //         //   setState({
  //         //     sendingOtp: false,
  //         //     status: "Sending OTP Failed",
  //         //     verifying: false,
  //         //   });
  //         //   setVerify({
  //         //     verifying: false,
  //         //     status: "Verify",
  //         //     type: "primary",
  //         //   });
  //         // } else {
  //         // console.log("RES FROM TxtGuru: ", res);
  //         setState({
  //           sendingOtp: false,
  //           status: "OTP Sent",
  //           verifying: false,
  //         });
  //         // setIsOtpExpired(false);
  //         setVerify({
  //           verifying: false,
  //           status: "Verify",
  //           type: "primary",
  //         });
  //         // }
  //       })
  //       .catch((err) => {
  //         // if (err) {
  //         //   console.log("Err in sending OTP: ", err);
  //         //   setState({
  //         //     sendingOtp: false,
  //         //     status: "Sending OTP Failed",
  //         //     verifying: false,
  //         //   });
  //         //   setVerify({
  //         //     verifying: false,
  //         //     status: "Verify",
  //         //     type: "primary",
  //         //   });
  //         // }

  //         // if (err) {
  //         // console.log("ERR: ", err);
  //         setState({
  //           sendingOtp: false,
  //           status: "OTP Sent",
  //           verifying: false,
  //         });
  //         // setIsOtpExpired(false);
  //         setVerify({
  //           verifying: false,
  //           status: "Verify",
  //           type: "primary",
  //         });
  //         // }
  //       });
  //   })();
  // }, [props.mobile]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        type="inner"
        hoverable
        style={{ width: "50%", minWidth: "300px" }}
        cover={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              className="rounded-circle m-auto p-3 text-center"
              src={doc_card}
              preview={false}
              alt={"Doc Logo"}
              style={{ maxWidth: "80%" }}
            ></Image>
            {/* <div
              className="text-success font-weight-bold"
              style={{ fontSize: "1.15rem" }}
            >
              {"props?.billAmnt"}
            </div> */}
          </div>
        }
        title={
          <div
            className="head-xl font-weight-bold text-center"
            style={{ fontSize: "1.25rem" }}
          >
            OTP Verification
          </div>
        }
        actions={[
          <Button
            type={"primary"}
            loading={verify.verifying}
            onClick={verifyOtpHandler}
            style={{ margin: "1rem" }}
          >
            Submit
          </Button>,
        ]}
      >
        <Meta
          title={
            <Input
              placeholder="Enter OTP"
              size="large"
              className="my-2"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onPressEnter={verifyOtpHandler}
              required
            ></Input>
          }
        ></Meta>
        <Meta
          description={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <div>
                  {
                    <Countdown
                      title={isOtpExpired ? "OTP Expired" : "Expires In"}
                      value={deadline}
                      format="mm:ss"
                      valueStyle={{
                        color: isOtpExpired ? "#f5222d" : "#1890ff",
                        fontSize: "1rem",
                        textJustify: "auto",
                        textAlign: "center",
                      }}
                      onFinish={() => {
                        setIsOtpExpired(true);
                        setState({
                          sendingOtp: false,
                          status: "OTP Expired",
                          verifying: false,
                        });
                      }}
                    />
                  }
                </div> */}
              <div className={`text-${verify.type}`}>{verify.status}</div>
            </div>
          }
          className="m-2"
        ></Meta>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    specialities: state.specialities,
  };
};

export default connect(mapStateToProps)(PartnerSignUpOtpForm);
