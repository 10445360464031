import React from "react";
import BreadCrumbList from "../BreadCrumbList";
const CardHeaderCustom = (props) => {
  const routes = [{ path: "/", pathName: "Home" }];
  return (
    <div>
      <div className="card-title-with-extra">
        <span style={{ whiteSpace: "normal" }}> {props?.title}</span>
        {props?.extra}
      </div>
      {props?.showPath ? (
        <div style={{ whiteSpace: "normal" }}>
          <BreadCrumbList routes={props?.routes || routes}></BreadCrumbList>
        </div>
      ) : null}
    </div>
  );
};

export default CardHeaderCustom;
