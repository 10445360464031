import React, { useState, useEffect } from "react";
import {
  Card,
  Divider,
  Form,
  Button,
  Input,
  Select,
  Upload,
  Avatar,
  message,
  Spin,
  DatePicker,
  Image,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { apis } from "../../shared/apis";

const { Option } = Select;
const AddDependentForm = () => {
  const [form] = Form.useForm();
  const [numStatus] = useState({
    message: "Sign Up",
    isLoading: false,
    error: "",
  });
  const [status, setStatus] = useState({
    message: "Register",
    isLoading: false,
    error: "",
  });
  const [pdphoto, setPdPhoto] = useState();
  const [fetching, setFetching] = useState(false);
  const [userState, setUserState] = useState({
    label: "Select State",
    value: "Select State",
  });
  const [userCity, setUserCity] = useState({
    label: "Select City",
    value: "Select City",
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const fetchStates = async (val) => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: val || "S" } })
      .then((res, err) => {
        if (err) message.error("Fetching states err: ");
        let sts = [];
        res.data?.info?.forEach((element) => {
          sts.push({
            label: element.cstate,
            value: element.cstate,
            key: element.cstate + Math.random(),
          });
        });
        sts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        setStates([...sts]);
        setFetching(false);
        // }
      })
      .catch((err) => {
        if (err) message.error("Fetching states err: ");
        setFetching(false);
      });
  };

  const fetchCities = async (val) => {
    if (val) {
      setFetching(true);
      await axios({ ...apis.getCityState, data: { cityorstate: val } })
        .then((res, err) => {
          if (err) message.error("Fetching Cities err: ");
          let cts = [];
          res.data?.info?.forEach((element) => {
            cts.push({
              label: `${element.cname} (${element.cstate})`,
              value: element.cname,
              key: element.cname + Math.random(),
            });
          });
          cts.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });
          setCities([...cts]);
          setFetching(false);
        })
        .catch((err) => {
          if (err) message.error("Fetching cities err: ");
          setFetching(false);
        });
    }
  };

  const handleStateChange = (e) => {
    setUserState(e);
    //   props.setUserState(e);
    fetchCities(e?.value);
  };
  const handleCityChange = (e) => {
    setUserCity(e);
    //   props?.setUserCity(e);
    //   fetchLocality(e?.value);
  };

  const customRequestID = (option) => {
    const formData = new FormData();
    formData.append("files[]", option.file);
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function (e) {
      //   i64 = e.target.result;
      setPdPhoto(e.target.result);
      //   i64 = i64.slice(22, i64.length);
      if (e && e.target && e.target.result) {
        option.onSuccess();
      }
    };
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Only upload JPG or PNG files!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image size must be less than 2MB!");
      return false;
    }
    return isJpgOrPng && isLt2M;
  };

  const handleSubmit = async (e) => {
    const input = {
      ...e,
      pddob: e?.pddob?.format("YYYY-MM-DD"),
      pdcity: userCity?.value,
      pid: JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientid,
      pdphoto: pdphoto,
    };
    setStatus({
      message: "Registering...",
      isLoading: true,
      error: "",
    });
    await axios({ ...apis.patientdependentgistration, data: input })
      .then((res, err) => {
        if (err) {
          setStatus({
            message: "Register ",
            isLoading: false,
            error: "Failed",
          });
          message.error("Something Went Wrong");
        } else {
          setStatus({
            message: "Register",
            isLoading: false,
            error: "",
          });
          message.success("Dependent Added Successfully");
          form.resetFields();
        }
      })
      .catch((err) => {
        if (err) {
          setStatus({
            message: "Register ",
            isLoading: false,
            error: "Failed",
          });
          message.error("Something Went Wrong");
        }
      });
  };

  useEffect(() => {
    fetchStates("S");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Card hoverable bodyStyle={{ padding: "5px 24px" }}>
        <Form onFinish={handleSubmit} form={form}>
          <Divider></Divider>
          <Form.Item
            // name='pdphoto'
            rules={[{ required: false, message: "Please Enter Amount!" }]}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Upload
              showUploadList={false}
              maxCount={1}
              //   listType="picture-card"
              customRequest={customRequestID}
              beforeUpload={beforeUpload}
              //   onPreview={(d) => {
              //     Modal.info({
              //       centered: true,
              //       bodyStyle: { width: "100%" },
              //       content: (
              //         <Image
              //           src={d.thumbUrl}
              //           alt="Imahe"
              //           style={{ width: "100%" }}
              //           preview={false}
              //         />
              //       ),
              //     });
              //   }}
            >
              {pdphoto ? (
                <Image
                  src={pdphoto}
                  alt="Image"
                  className="rounded"
                  style={{ width: "256px" }}
                  preview={false}
                />
              ) : (
                <Avatar
                  size={128}
                  icon={<UserOutlined style={{ display: "inline-flex" }} />}
                />
              )}
            </Upload>
            <div className="lead">Profile Picture (Optional)</div>
          </Form.Item>
          <Divider>Personal Information</Divider>
          <Form.Item
            name="pdfname"
            rules={[{ required: true, message: "Please Enter Your Name!" }]}
          >
            <Input size="large" placeholder="Full Name" />
          </Form.Item>
          <div className="row mx-0 my-2">
            <div className="my-2 col-sm-6 pl-0">
              {/* <Form.Item name={["state", "value"]}> */}
              <Select
                showSearch
                labelInValue
                placeholder={"Select State"}
                filterOption={true}
                size="large"
                value={userState}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                // onSearch={fetchStates}
                onChange={handleStateChange}
                //   onSelect={handleStateChange}
                style={{ width: "100%" }}
                options={states}
                required
              ></Select>
              {/* </Form.Item> */}
            </div>
            <div className="my-2 col-sm-6 pr-0">
              {/* <Form.Item name="city"> */}
              <Select
                showSearch
                // allowClear
                labelInValue
                placeholder={"Select City"}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={true}
                onChange={handleCityChange}
                style={{ width: "100%" }}
                size="large"
                options={cities}
                value={userCity}
                required
              ></Select>
              {/* </Form.Item> */}
            </div>
          </div>
          <div className="row mx-0">
            <Form.Item name="pddob" className="col-sm-6 pl-0">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Select DOB"
                rules={[{ required: true, message: "Please Select your DOB" }]}
              ></DatePicker>
            </Form.Item>
            <Form.Item
              name="pdgender"
              className="col-sm-6 pr-0"
              rules={[{ required: true, message: "Please Select your gender" }]}
            >
              <Select placeholder="Gender" size="large">
                <Option value="M">Male</Option>
                <Option value="F">Female</Option>
              </Select>
            </Form.Item>
          </div>

          {/* <Form.Item
            name="email"
            rules={[{ required: true, message: "Please Enter Your Email!" }]}
          >
            <Input type="email" size="large" placeholder="Email" />
          </Form.Item> */}
          <Form.Item
            name="pdmobile"
            rules={[
              {
                required: true,
                message: "Please Enter Your Phone Number!",
              },
              () => ({
                validator(_, value) {
                  if (!value || value.length === 10) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Please enter valid Phone Number")
                  );
                },
              }),
            ]}
            hasFeedback
            help={
              numStatus?.error ? (
                <div className="text-danger">{numStatus?.error}</div>
              ) : null
            }
          >
            <Input size="large" placeholder="Phone Number" />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                //   justifyContent: "center",
                alignItems: "center",
                //   padding: "1rem",
              }}
            >
              <div>
                <Button
                  type="primary"
                  loading={status.isLoading}
                  htmlType="submit"
                  size="large"
                >
                  {status.message}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddDependentForm;
