import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { isBrowser } from "react-device-detect";
import { Avatar, Popover, Button, message, Modal } from "antd";
import { RightOutlined, UserOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "../../assets/images/logo.png";
import offers_icon from "../../assets/images/offers_icon.jpg";

// import offers_bg from "../../assets/images/offers_bg.jpg";

import { connect } from "react-redux";
import {
  logoutSuccess,
  setIsPartnerLoggedIn,
} from "../../redux/ActionCreators/VerificationActionCreators";
import app from "../../firebase";
import HeaderStrip from "./HeaderStrip";

// import BlogOption from "./BlogOption";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
      isModalOpen: false,
      isOpen: false,
      showOfferModal: false,
      isSticky: false,
      Ref: null,
    };

    // this.ref = null;
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.toggle = this.toggle.bind(this);
    this.profileContent = this.profileContent.bind(this);
  }

  getEmployee() {
    let empRole = JSON.parse(localStorage.getItem("empRole"));
    let empEmail = localStorage.getItem("empEmail");
    return (
      <div className="p-1">
        <div className="my-1">{empRole?.role || "Employee"}</div>
        <div className="my-1 text-secondary">{empEmail}</div>
      </div>
    );
  }
  getPartner() {
    let partnerEmail = localStorage.getItem("partnerEmail");
    return (
      <div className="p-1">
        <div className="my-1">Partner</div>
        <div className="my-1 text-secondary">{partnerEmail}</div>
      </div>
    );
  }
  getUser() {
    // let userProfile = JSON.parse(localStorage.getItem("userProfile"));
    let userEmail = JSON.parse(localStorage.getItem("userEmail"));
    return (
      <div className="p-1">
        <div className="my-1">Patient</div>
        <div className="my-1 text-secondary">{userEmail}</div>
      </div>
    );
  }

  profileContent() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div>
          {this.props?.partnerData?.isPartnerLoggedIn ? (
            <div>{this.getPartner()}</div>
          ) : localStorage.getItem("userEmail") ? (
            <div>{this.getUser()}</div>
          ) : (
            <div>{this.getEmployee()}</div>
          )}
        </div>

        <div>
          <NavItem
            onClick={
              localStorage.getItem("partnerEmail")
                ? this.handlePartnerLogout
                : localStorage.getItem("userEmail")
                ? this.handleUserLogout
                : this.handleStaffLogout
            }
          >
            <NavLink
              to={
                localStorage.getItem("partnerEmail")
                  ? "/partner-login"
                  : localStorage.getItem("userEmail")
                  ? "/user-login"
                  : "/emp-login"
              }
              className="nav-unstyled-link nav-link custom-link"
              onClick={isBrowser ? null : this.toggle}
            >
              <Button danger shape="round">
                Log Out
              </Button>
            </NavLink>
          </NavItem>
        </div>
      </div>
    );
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleLogin(event) {
    this.toggleModal();
    event.preventDefault();
  }

  // handleLogout() { this.props.logoutUser(); }
  handlePartnerLogout = async () => {
    try {
      await app
        .auth()
        .signOut()
        .then((res, err) => {
          if (err) throw err;
          else {
            this.props.setIsPartnerLoggedIn(false);
            localStorage.removeItem("token");
            localStorage.removeItem("partnerEmail");
            localStorage.removeItem("role_id");
            localStorage.removeItem("dlm_id");
            localStorage.removeItem("selectedRole");
            localStorage.removeItem("selectedClinic");
            localStorage.clear();
            window.location = "/partner-login";
            // this.props.history.push("/partner-login");
          }
        });
    } catch (err) {
      // if (err) console.log("Error While Signing Out");
    }
  };

  handleStaffLogout = () => {
    this.props.logoutSuccess();
    localStorage.clear();
  };
  handleUserLogout = async () => {
    try {
      await app
        .auth()
        .signOut()
        .then((res, err) => {
          if (err) throw err;
          else {
            localStorage.clear();
            window.location = "/user-login";
          }
        });
    } catch (err) {
      message.error("Error While Signing Out");
    }
  };

  // handleScroll = () => {
  //   if (this.state.Ref.current) {
  //     this.setState(
  //       {
  //         isSticky: this.ref.current.getBoundingClientRect().top <= 0,
  //       },
  //       () => console.log("isSticky: ", this.state.isSticky)
  //     );
  //   }
  // };

  PromotionalOfferContent = () => {
    return (
      <div>
        {/* <div className="font-weight-bold">For doctors:</div>
        <div>
          3 months free consultation using Jan elaaj.
          <br />
          Second referral discount-referral discount towards the second
          consulting of different doctor.
          <br />
          Bonus for referring others doctor to register with Jan elaaj{" "}
          <b>partner with us</b> button.
        </div> */}
        <h2 className="text-dark">
          Experience the online primary care consultancy services
        </h2>
        {/* <div className="btn btn-primary btn-lg"> */}
        <Button
          type="primary"
          size="large"
          style={{ display: "flex", alignItems: "center", margin: "auto" }}
        >
          <b>Consulting Starting at </b>
          <span
            style={{
              textDecoration: "line-through",
              // color: "red",
              margin: "0 10px",
            }}
          >
            ₹249
          </span>
          <span style={{ color: "#ffec3d", fontSize: "1.5rem" }}>₹49</span>
        </Button>
        {/* </div> */}
      </div>
    );
  };

  render() {
    return (
      <div style={{ position: "fixed", zIndex: "999", width: "100%" }}>
        <HeaderStrip {...this.props}></HeaderStrip>
        <div
          style={{
            minHeight: 70,
            zIndex: "999",
            width: "100%",
          }}
        >
          <Navbar color="light" light expand="lg">
            <NavbarBrand href="/" className="ml-5">
              <img
                src={logo}
                height="57.6px"
                width="134.4px"
                alt="Jan-Elaaj-Logo"
              />
            </NavbarBrand>
            <NavbarToggler onClick={isBrowser ? null : this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto mr-4" navbar>
                {window.location.pathname === "/" ? (
                  <NavItem>
                    <AnchorLink
                      href="#home"
                      className="nav-link custom-link"
                      style={navItem}
                      offset={150}
                      onClick={isBrowser ? null : this.toggle}
                    >
                      Home
                    </AnchorLink>
                    {/* <NavLink
                    to="/"
                    className="nav-unstyled-link nav-link custom-link"
                    style={navItem}
                    onClick={isBrowser ? null : this.toggle}
                  >
                    Home
                  </NavLink> */}
                    {/* </AnchorLink> */}
                  </NavItem>
                ) : (
                  <NavItem>
                    {/* < href="/" className="nav-link custom-link" style={navItem}> */}
                    <NavLink
                      to="/"
                      className="nav-link custom-link"
                      style={navItem}
                      offset={0}
                      onClick={isBrowser ? null : this.toggle}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                )}
                <NavItem
                  style={{
                    display:
                      localStorage.getItem("partnerEmail") ||
                      localStorage.getItem("userEmail") ||
                      localStorage.getItem("empEmail")
                        ? "block"
                        : "none",
                  }}
                  onClick={isBrowser ? null : this.toggle}
                >
                  <NavLink
                    // to={"/roles-list"}
                    to={
                      localStorage.getItem("partnerEmail")
                        ? "/roles-list"
                        : localStorage.getItem("userEmail")
                        ? "/dashboard"
                        : "/emp-dashboard"
                    }
                    className="nav-unstyled-link nav-link custom-link"
                    style={navItem}
                  >
                    DashBoard
                  </NavLink>
                </NavItem>
                {/* <NavItem> */}
                {/* <AnchorLink
                    href="/preventive-care"
                    className="nav-link custom-link"
                    style={navItem}
                  > */}
                {/* <NavLink
                    to="/preventive-care"
                    className="nav-unstyled-link nav-link custom-link"
                    style={navItem}
                  >
                    Preventive Care
                  </NavLink> */}
                {/* </AnchorLink> */}
                {/* </NavItem> */}

                {window.location.pathname === "/" ? (
                  <NavItem onClick={isBrowser ? null : this.toggle}>
                    <AnchorLink
                      href="#services"
                      className="nav-link custom-link"
                      style={navItem}
                      offset={150}
                    >
                      {/* <NavLink to="#services" className="nav-unstyled-link"> */}
                      Services
                      {/* </NavLink> */}
                    </AnchorLink>
                  </NavItem>
                ) : (
                  <NavItem>
                    <a
                      href="/#services"
                      className="nav-link custom-link"
                      style={navItem}
                      offset={0}
                      onClick={isBrowser ? null : this.toggle}
                    >
                      Services
                    </a>
                  </NavItem>
                )}

                {/* <NavItem onClick={isBrowser ? null : this.toggle}>
                  <NavLink
                    to="/blog"
                    className="nav-link custom-link"
                    style={navItem}
                    offset={20}
                  >
                    Blog
                  </NavLink>
                </NavItem> */}

                {window.location.pathname === "/" ? (
                  <NavItem onClick={isBrowser ? null : this.toggle}>
                    <AnchorLink
                      href="#about"
                      className="nav-link custom-link"
                      style={navItem}
                      offset={150}
                    >
                      About
                    </AnchorLink>
                  </NavItem>
                ) : (
                  <NavItem>
                    <a
                      href="/#about"
                      className="nav-link custom-link"
                      style={navItem}
                      offset={150}
                      onClick={isBrowser ? null : this.toggle}
                    >
                      About
                    </a>
                  </NavItem>
                )}

                <NavItem onClick={isBrowser ? null : this.toggle}>
                  <NavLink
                    to="/help-a-cause"
                    className="nav-unstyled-link nav-link custom-link"
                  >
                    Help A Cause
                  </NavLink>
                </NavItem>

                {/* <NavItem className="nav-link custom-link" style={navItem}>
                  <BlogOption></BlogOption>
                </NavItem> */}
                {localStorage.getItem("partnerEmail") ||
                localStorage.getItem("userEmail") ||
                localStorage.getItem("empEmail") ||
                localStorage.getItem("token") ||
                this.props?.auth?.user?.status === "SUCCESS" ? (
                  <NavItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // backgroundColor: "red",
                      padding: "0 1rem 0.5rem",
                    }}
                  >
                    <Popover
                      title={"Logged In as :"}
                      content={this.profileContent()}
                      placement="bottom"
                      trigger="click"
                    >
                      <Avatar
                        style={{
                          backgroundColor: "#4970D8",
                          cursor: "pointer",
                        }}
                        icon={<UserOutlined />}
                      ></Avatar>
                    </Popover>
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret style={navItem}>
                      <Button type="primary" shape="round">
                        Login
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink
                          to="/user-login"
                          className="nav-link custom-link"
                          style={{ ...navItem, ...loginButton }}
                          onClick={this.props.onUserLogin}
                        >
                          User Login
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem onClick={isBrowser ? null : this.toggle}>
                        <NavLink
                          to="/partner-login"
                          className="nav-link custom-dropdown-link"
                          style={{ ...navItem, ...loginButton }}
                          onClick={this.props.onPartnerLogin}
                        >
                          Partner Login
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem onClick={isBrowser ? null : this.toggle}>
                        <NavLink
                          to="/emp-login/1"
                          className="nav-link custom-dropdown-link"
                          style={{ ...navItem, ...loginButton }}
                          onClick={this.props.onEmpLogin}
                        >
                          Channel Network Login
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem onClick={isBrowser ? null : this.toggle}>
                        <NavLink
                          to="/emp-login"
                          className="nav-link custom-dropdown-link"
                          style={{ ...navItem, ...loginButton }}
                          onClick={this.props.onEmpLogin}
                        >
                          Employee Login
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                <div
                  className="offer-card"
                  onClick={() => {
                    this.setState({ showOfferModal: true });
                    this.toggle();
                  }}
                >
                  <div className="offers_icon">
                    <img
                      style={{ width: "100%" }}
                      src={offers_icon}
                      alt="hurry-up"
                    ></img>
                  </div>
                  Promotional Offers
                  <RightOutlined
                    style={{ display: "inline-flex" }}
                    className="px-1 mx-1"
                  />
                </div>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
        <Modal
          visible={this.state.showOfferModal}
          centered
          bodyStyle={{
            // backgroundImage: `url(${offers_bg})`,
            backgroundSize: "cover",
            color: "white ",
            backgroundColor: "#ffd6e7",
          }}
          closable={false}
          title="Promotional Offers"
          footer={[
            <AnchorLink
              href="#services"
              // className="nav-link custom-link"
              // style={navItem}
              offset={150}
            >
              <Button
                type="primary"
                onClick={() => this.setState({ showOfferModal: false })}
              >
                Book An Appointment
              </Button>
            </AnchorLink>,
            <Button
              // type="primary"
              // shape="round"
              onClick={() => this.setState({ showOfferModal: false })}
            >
              Close
            </Button>,
          ]}
          onCancel={() => this.setState({ showOfferModal: false })}
        >
          {this.PromotionalOfferContent()}
        </Modal>
      </div>
    );
  }
}

const navItem = {
  marginLeft: 15,
  marginRight: 15,
};

const loginButton = {
  backgroundColor: "#25a8e3",
  borderRadius: 20,
  paddingLeft: 22,
  paddingRight: 22,
  color: "white",
  fontSize: 15,
  fontWeight: "bold",
};

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
    logoutSuccess: () => dispatch(logoutSuccess()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
