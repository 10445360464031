import { Button, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import app from "../firebase";
import { setAllPosts } from "../redux/ActionCreators/HomeActionCreators";
import LoadingMask from "./LoaderMask/LoadingMask";
import PostCard from "./PostCard";

const AllPosts = (props) => {
  const [allPosts, setAllPosts] = useState({ value: [], isLoading: true });
  const [maxLength, setMaxLength] = useState(0);
  const [loader, setLoader] = useState(false);
  const [endOfPost, setEndOfPost] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const page = 5;
  useEffect(() => {
    setLoader(true);

    app
      .database()
      .ref(`allposts/${props.tag}`)
      .on("value", (snapshot) => setMaxLength(snapshot.numChildren()));

    app
      .database()
      .ref(`allposts/${props.tag}`)
      .limitToLast(limit)
      .on("value", (snapshot) => {
        let allplist = [];
        snapshot.forEach((snap, i) => {
          allplist = [snap.val(), ...allplist];
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
        });
        // console.log(studentlist)
        setLoader(false);
        setAllPosts({ value: allplist, isLoading: false });
        setCount(allplist.length);
        setEndOfPost(maxLength === allplist.length);
        // props?.setAllPosts({ value: allplist, isLoading: false })
        // this.props.setAllPosts({ value: allplist, isLoading: false })
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, props.tag]);
  return (
    <div>
      {allPosts?.isLoading ? (
        <LoadingMask></LoadingMask>
      ) : allPosts?.value?.length !== 0 ? (
        <div
          style={{
            overflowY: "scroll",
            maxHeight: "87vh",
          }}
        >
          {allPosts?.value?.map((post) => (
            <div key={post?.user_id} className="m-1 mb-4">
              <PostCard {...post}></PostCard>
            </div>
          ))}
          <div className="text-center p-1">
            {endOfPost ? (
              <Empty description={"No More Posts "}></Empty>
            ) : (
              <Button
                loading={loader}
                type="link"
                onClick={() => setLimit(limit + page)}
              >
                Load More
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Empty description={"No Posts Yet! "}></Empty>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hashtags: state.hashTags,
    allPosts: state?.hashTags?.allPosts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setAllPosts: (data) => dispatch(setAllPosts(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPosts);
