import React, { useEffect, useState } from "react";
import { Card, Empty, Result, Button } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";
import Healthmeter from "./HealthMeter";
import LoadingMask from "../LoaderMask/LoadingMask";

const VideoProcessCard = (props) => {
  const [loader, setIsProcessing] = useState(false);
  const [dataCollected, setDataCollected] = useState(props.visualParameter);
  useEffect(() => {
    const handleProcessVideo = async () => {
      setIsProcessing(true);
      await axios({
        ...apis.postProcessVideo,
        data: { videoName: `${props.fName}.mp4`, file_path: props?.filePath },
      })
        .then((res, err) => {
          if (err) {
            console.error(err);
          } else {
            // console.log(res);
            setDataCollected(res.data);
            // console.log(res.data.data)
          }
          setIsProcessing(false);
        })
        .catch((err) => {
          // setDataCollected({
          //   status_code: 1,
          //   data: {
          //     status: "test",
          //     video_path: "test",
          //     Haemoglobin: 14,
          //     Heart_Rate: 80,
          //     SPO2: 50,
          //   },
          // });
          if (err) {
            console.error(err);
          }
          setIsProcessing(false);
        });
    };
    // handleProcessVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loader ? (
    <LoadingMask
      message="Processing Video, Please Wait"
      showCustomLoader={true}
    ></LoadingMask>
  ) : (
    <Card
      bodyStyle={{ padding: 0 }}
      bordered={false}
      actions={[
        <Button type="primary" onClick={() => props.setShowModal(false)}>
          OK
        </Button>,
      ]}
    >
      {/* <p>Haemoglobin : {dataCollected?.data.Haemoglobin || "--"} g/dl</p>
      <p>Heart Rate : {dataCollected?.data.Heart_Rate || "--"} beats/min</p>
      <p>SPO2 : {dataCollected?.data.SPO2 || "--"} %</p> */}
      {dataCollected?.data ? (
        dataCollected.status_code === 1 ? (
          <Healthmeter data={dataCollected.data}></Healthmeter>
        ) : (
          <Result
            status="error"
            title="Face is not captured, Please try again"
            subTitle="Please Try Again"
          />
        )
      ) : (
        <Result
          status="error"
          title="Something went Wrong"
          subTitle="Please Try Again"
        />
      )}
    </Card>
  );
};

export default VideoProcessCard;
