import * as ActionTypes from "../../ActionTypes";

// doc reducer
export const Doc = (
  state = {
    isLoading: true,
    errMess: null,
    doc: {},
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_DOC:
      return { ...state, isLoading: false, errMess: null, doc: action.payload };

    case ActionTypes.DOC_LOADING:
      return { ...state, isLoading: true, errMess: null, doc: {} };

    case ActionTypes.DOC_FAILED:
      return { ...state, isLoading: false, errMess: action.payload, doc: {} };

    default:
      return state;
  }
};
