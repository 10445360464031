// import { Card } from "antd";
import { Space, Form } from "antd";
// import Paragraph from "antd/lib/skeleton/Paragraph";
// import axios from "axios";
import React, { useEffect, useState } from "react";
// import { apis } from "../shared/apis";
// import LoadingMask from "./LoaderMask/LoadingMask";
const PrescriptionFormPreview = (props) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [patHistory, setPatHistory] = useState("");
  const [sugTests, setSugTests] = useState("");
  const [medication, setMedication] = useState("");
  const [observation, setObservation] = useState("");
  const [other, setOther] = useState("");

  useEffect(() => {
    const getData = () => {
      // setIsLoading(true);
      // let obj = {
      //   pdla_id: props.pdla_id,
      // };
      // await axios({ ...apis.postOnlinePrescriptionEntries, data: obj })
      //   .then((res, err) => {
      // setIsLoading(false);
      // if (err) console.log("error: ", err);
      // else {
      //   console.log("Res: ", res);
      setDetails({ ...props.details });
      setPatHistory(props?.patient_history);
      setSugTests(props?.suggested_test);
      setMedication(props?.medication);
      setObservation(props?.observation);
      setOther(props?.other);
      // }
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //     if (err) console.log("Error: ", err);
      //   });
    };
    getData();
  }, [props]);

  return (
    <div>
      <div>
        <div className="row mr-0">
          <div className="doc-details-container col-sm-6 ">
            <table
              className="table table-responsive"
              style={{
                margin: "0 0 0 auto",
                width: "fit-content",
              }}
            >
              <tbody>
                <tr className="atr-val-container">
                  <th className="doc-attribute">Name: </th>
                  <td className="doc-value">{details?.docName}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="doc-attribute">Registration No: </th>
                  <td className="doc-value">{details?.docRegistrationNo}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="doc-attribute">Contact No: </th>
                  <td className="doc-value">{details?.docContact}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="doc-attribute">Email: </th>
                  <td className="doc-value">{details?.docEmail}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="doc-attribute">Clinic: </th>
                  <td className="doc-value">{details?.docClinic}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="foot-atr-val-container">
                  <th className="foot-attribute">Appointment Date: </th>
                  <td className="foot-value">{details?.appointmentDate}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            className="pat-details-container col-sm-6"
            style={{ borderLeft: "1px solid #f1f1f1" }}
          >
            <table
              className="table table-responsive"
              style={{
                margin: "0 auto 0 0",
                width: "fit-content",
              }}
            >
              <tbody>
                <tr className="atr-val-container">
                  <th className="pat-attribute">Patient Name: </th>
                  <td className="pat-value">{details?.pm_patient_name}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="pat-attribute">Patient Contact No: </th>
                  <td className="pat-value">{details?.patient_contact}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="pat-attribute">Patient Id: </th>
                  <td className="pat-value">{details?.patient_id}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="pat-attribute">Patient Age: </th>
                  <td className="pat-value">{details?.patientAge}</td>
                </tr>
                <tr className="atr-val-container">
                  <th className="pat-attribute">Patient Gender: </th>
                  <td className="pat-value">{details?.pm_gender}</td>
                </tr>
                {/* <tr className="atr-val-container">
                    <th className="pat-attribute">Appointment Date: </th>
                    <td className="pat-value">{details?.appointmentDate}</td>
                  </tr> */}
                {/* <tr className="atr-val-container">
                      <th className="pat-attribute">Time Slot: </th>
                      <td className="pat-value">{details?.timeslot}</td>
                    </tr> */}
              </tbody>
              <tfoot>
                <tr className="foot-atr-val-container">
                  <th className="foot-attribute">Time Slot: </th>
                  <td className="foot-value">{details?.timeslot}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <hr />
        <Space direction="vertical" style={{ width: "100%", padding: "0rem" }}>
          <Form layout="vertical">
            <Form.Item label="Patients's History">
              <p className={patHistory ? "text-dark" : "text-secondary"}>
                {patHistory ||
                  "Write down the previous health issues(if any) of the Patient"}
              </p>
            </Form.Item>
            <Form.Item label="Suggested Tests">
              <p className={sugTests ? "text-dark" : "text-secondary"}>
                {sugTests ||
                  "Mention the clinical tests that are required (if any)"}
              </p>
            </Form.Item>
            <Form.Item label="Medication">
              <p className={medication ? "text-dark" : "text-secondary"}>
                {medication || "Medicine1 1-1-1 xdays  Medicine2 1-1-1 xdays"}
              </p>
            </Form.Item>
            <Form.Item label="Observation">
              <p className={observation ? "text-dark" : "text-secondary"}>
                {observation || "Write down your diagnosis"}
              </p>
            </Form.Item>
            <Form.Item label="Other">
              <p className={other ? "text-dark" : "text-secondary"}>
                {other || "Write down additive instructions(if any)"}
              </p>
            </Form.Item>
          </Form>
        </Space>
      </div>
    </div>
  );
};

export default PrescriptionFormPreview;
