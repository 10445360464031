import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import { StarFilled } from "@ant-design/icons";
import axios from "axios";
import { apis } from "../../shared/apis";
import BookingForm from "./BookingForm";
import { Redirect } from "react-router";

const BookAgainButton = (props) => {
  const [loader, setLoader] = useState(false);

  const [docDetails, setDocDetails] = useState({});
  const [patDetails, setPatDetails] = useState({});
  const [showModalForm, setShowModalForm] = useState(false);
  const [dependents, setDependents] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const getDocDetails = async () => {
    setLoader(true);
    await axios({
      ...apis.getdoctordetails,
      data: {
        dlmid: props?.doc?.ldlm_id || props?.dlmid,
        req_origin: "userApp",
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        setDocDetails(res.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };
  const getPatDetails = async () => {
    setLoader(true);
    await axios({
      ...apis.getPatientDependents,
      data: {
        pid: JSON.parse(localStorage.getItem("userProfile"))?.role[0]
          ?.patientid,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        setDependents(res.data.dependents);
        setPatDetails(res.data);
        if (res.data.block) {
          setIsBlocked(true);
          message.error("Patient is Blocked ", res.data.status);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const handleBookNow = async () => {
    if (!localStorage.getItem("userProfile")) {
      message.error("Please Login First");
      setShouldRedirect(true);
    } else {
      await getDocDetails();
      await getPatDetails();
      if (isBlocked) return;
      else setShowModalForm(true);
      // setIsBooking(true);
      // let obj = {
      //   mom_id: props.userDash?.selectedSpeciality?.mom_id,
      //   role: props.userDash?.selectedService?.data?.Role,
      //   dlm_id: props.doc?.ldlm_id,
      // };
      // await axios({ ...apis.getPackagesVitals, data: obj })
      //   .then((res, err) => {
      //     setIsBooking(false);
      //     if (err) console.log("err: ", err);
      //     else console.log("res: ", res);
      //   })
      //   .catch((err) => {
      //     if (err) console.log("Err: ", err);
      //     setIsBooking(false);
      //   });
    }
  };

  // console.log("BAB props: ", props);
  if (shouldRedirect) return <Redirect to="/user-login"></Redirect>;
  return (
    <div>
      <Button
        shape="round"
        type="primary"
        icon={props?.icon ? props?.icon : <StarFilled />}
        style={{
          display: "flex",
          alignItems: "center",
          color: "white",
          fontSize: "1rem",
          // margin: "auto",
          padding: "1rem",
          paddingBottom: "1.2rem",
        }}
        className="mx-2"
        onClick={handleBookNow}
        loading={loader}
      >
        {props?.btnText ? props?.btnText : "Book Now"}
      </Button>
      <Modal
        visible={showModalForm}
        title={<div>Please Choose Options</div>}
        onCancel={() => setShowModalForm(false)}
        bodyStyle={{ paddingTop: "0" }}
        destroyOnClose={true}
        footer={[]}
      >
        <BookingForm
          {...docDetails}
          {...patDetails}
          dependents={dependents}
          loader={loader}
          setShowModalForm={setShowModalForm}
          {...props}
          closeBookingForm={() => setShowModalForm(false)}
        ></BookingForm>
      </Modal>
    </div>
  );
};

export default BookAgainButton;
