import React from "react";
import { NavLink } from "react-router-dom";

export const VerificationTitle = () => {
  return (
    <NavLink className="nav-link doc-verify" to="/verifyDoctor/">
      Doctor Verification
    </NavLink>
  );
};
