import * as ActionTypes from "../../ActionTypes";

// docinfo reducer
export const DocInfo = (
  state = {
    isLoading: true,
    errMess: null,
    docinfo: {},
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_DOCINFO:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        docinfo: action.payload,
      };

    case ActionTypes.DOCINFO_LOADING:
      return { ...state, isLoading: true, errMess: null, docinfo: {} };

    case ActionTypes.DOCINFO_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
        docinfo: {},
      };

    default:
      return state;
  }
};
