import React, { Component } from "react";
import Main from "./components/MainComponent";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/configureStore";
import { Provider } from "react-redux";
// import { FirebaseDatabaseProvider } from "@react-firebase/database";
// import { emlVrfConfig } from "./firebase";
// import firebase from "firebase";
import "./App.css";
import "antd/dist/antd.css";
// const store = ConfigureStore();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hashTags: [],
    };
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          {/* <FirebaseDatabaseProvider firebase={firebase} {...emlVrfConfig}> */}
          <Main></Main>
          {/* </FirebaseDatabaseProvider> */}
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
