import React from "react";

const EventsList = () => {
  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
      className="bg-light"
    >
      {/* <CalendarIcon></CalendarIcon> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
        }}
        className="border"
      >
        <div
          className="bg-success text-light px-2"
          style={{
            width: "100%",
            flexBasis: "100%",
            fontSize: "0.65rem",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          MONDAY
        </div>
        <div className="text-center p-1" style={{ fontSize: "1.25rem" }}>
          01
        </div>
        <div
          className="bg-success text-light px-2"
          style={{
            width: "100%",
            flexBasis: "100%",
            fontSize: "0.65rem",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          MARCH
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   alignItems: "center",
          justifyContent: "center",
        }}
        className="p-2"
      >
        <div className="event-name">Webinar by gynecoligist</div>
        <div className="time">07:30 PM - 8.30 PM</div>
        <div className="venue">Online</div>
      </div>
    </div>
  );
};

export default EventsList;
