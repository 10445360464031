import { Card, Empty, message, Pagination } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "../../shared/apis";
import UserAppointmentCard from "./UserAppointmentCard";
import UserPastAppointmentCard from "./UserPastAppointmentCard";
function UserAppointmentList(props) {
  const pageSize = 5;
  const [activePage, setActivePage] = useState(1);
  const [pastPage, setPastPage] = useState(1);
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [appointments, setAppointments] = useState([]);
  const [activeAppointments, setActiveAppointments] = useState([]);
  const [activeAppointmentsLength, setActiveAppointmentsLength] = useState(0);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [pastAppointmentsLength, setPastAppointmentsLength] = useState(0);
  const [activeAppointmentsObj, setActiveAppointmentsObj] = useState({ 1: [] });
  const [pastAppointmentsObj, setPastAppointmentsObj] = useState({ 1: [] });
  const formatList = (arr) => {
    let len = arr.length;
    let obj = {};
    let j = 1;
    for (let i = 0; i < len; i++) {
      if (i !== 0 && i % pageSize === 0) j++;
      if (typeof obj[j] !== "undefined") obj[j] = [...obj[j], arr[i]];
      else {
        obj[j] = [];
        obj[j] = [...obj[j], arr[i]];
      }
    }
    return obj;
  };
  useEffect(() => {
    setActivePage(1);
    setPastPage(1);
    (async function () {
      setLoader(true);
      await axios({
        ...apis.getpatientappointmenthistory,
        data: {
          patientid:
            props?.dependent?.dependentid ||
            JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientid,
        },
      })
        .then((res, err) => {
          setLoader(false);

          if (err) {
            message.error("Something Went Wrong");
          }
          // console.log("Res app: ", res);
          setAppointments(formatList(res.data.info));
          setActiveAppointments(
            res.data.info.filter((a) => a.pdlappointmentstatus === 0)
          );
          setPastAppointments(
            res.data.info.filter((a) => a.pdlappointmentstatus !== 0)
          );
          setActiveAppointmentsLength(
            res.data.info.filter((a) => a.pdlappointmentstatus === 0).length
          );
          setPastAppointmentsLength(
            res.data.info.filter((a) => a.pdlappointmentstatus !== 0).length
          );
          setActiveAppointmentsObj(
            formatList(
              res.data.info.filter((a) => a.pdlappointmentstatus === 0)
            )
          );
          setPastAppointmentsObj(
            formatList(
              res.data.info.filter((a) => a.pdlappointmentstatus !== 0)
            )
          );
        })
        .catch((err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          }
        });
    })();
  }, [props?.dependent]);
  return (
    <Card
      loading={loader}
      title={
        <div>
          {props.appointmentKey === "active"
            ? `Active Appointments - showing ${
                (pageSize * (activePage - 1) +
                  activeAppointmentsObj[activePage]?.length <=
                activeAppointments?.length
                  ? pageSize * (activePage - 1) +
                    activeAppointmentsObj[activePage]?.length
                  : activeAppointments?.length) || 0
              } out of ${activeAppointments?.length} appointments..`
            : `Past Appointments - showing ${
                pageSize * (pastPage - 1) +
                  pastAppointmentsObj[pastPage]?.length <=
                pastAppointments?.length
                  ? pageSize * (pastPage - 1) +
                    pastAppointmentsObj[pastPage]?.length
                  : pastAppointments?.length
              } out of ${pastAppointments?.length} appointments..`}
        </div>
      }
      size="small"
      bordered={false}
      bodyStyle={{ padding: "0.025rem" }}
    >
      {props.appointmentKey === "active" ? (
        // activeAppointments?.length ? (
        activeAppointmentsLength ? (
          <div>
            {activeAppointmentsObj[activePage]?.map((appointment, i) => (
              <UserAppointmentCard
                key={i + Math.random()}
                {...appointment}
                {...props}
                activeAppointmentsLength={activeAppointmentsLength}
                setActiveAppointmentsLength={setActiveAppointmentsLength}
              ></UserAppointmentCard>
            ))}
            <div className="paginition-container">
              <Pagination
                pageSize={pageSize}
                current={activePage}
                onChange={(page) => setActivePage(page)}
                // total={activeAppointments?.length}
                total={activeAppointmentsLength}
                hideOnSinglePage={true}
                showSizeChanger={false}
                // simple={true}
                responsive={true}
              ></Pagination>
            </div>
          </div>
        ) : (
          <Empty description="No Appointments"></Empty>
        )
      ) : pastAppointmentsLength ? (
        <div>
          {pastAppointmentsObj[pastPage]?.map((appointment, i) => (
            <UserPastAppointmentCard
              key={i + Math.random()}
              {...appointment}
              pastAppointmentsLength={pastAppointmentsLength}
              setPastAppointmentsLength={setPastAppointmentsLength}
              {...props}
            ></UserPastAppointmentCard>
          ))}
          <div className="paginition-container">
            <Pagination
              pageSize={pageSize}
              current={pastPage}
              onChange={(page) => setPastPage(page)}
              // total={Object.keys(pastAppointmentsObj).length}
              // total={pastAppointments?.length}
              total={pastAppointmentsLength}
              hideOnSinglePage={true}
              showSizeChanger={false}
              // simple={true}
              responsive={true}
            ></Pagination>
          </div>
        </div>
      ) : (
        <Empty description="No Appointments"></Empty>
      )}
    </Card>
  );
}

export default UserAppointmentList;
