import * as ActionTypes from "../ActionTypes";

export const showDashBoard = (flag) => {
  return {
    type: ActionTypes.SHOW_DASHBOARD,
    payload: flag,
  };
};

export const setSelectedServiceIndex = (flag) => {
  return {
    type: ActionTypes.SET_SELECTED_SERVICE_INDEX,
    payload: flag,
  };
};

export const setSelectedRole = (flag) => {
  return {
    type: ActionTypes.SELECTED_ROLE,
    payload: flag,
  };
};
