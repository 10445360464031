import * as ActionTypes from '../../ActionTypes'

const initialState = {
  Categories: [],
  expertTips: {
    isLoading: true
  },
  OneMin: { isLoading: true },
  allPosts: { isLoading: true, value: {} },
  myPosts: { isLoading: true, value: {} }
}

export const hashTags = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_HASHTAGS:
      return {
        ...state,
        Categories: action.payload
      }
    case ActionTypes.SET_EXPERT_TIPS:
      return {
        ...state,
        expertTips: action.payload
      }
    case ActionTypes.SET_ONE_MIN:
      return {
        ...state,
        OneMin: action.payload
      }
    case ActionTypes.SET_ALL_POSTS:
      return {
        ...state,
        allPosts: action.payload
      }
    case ActionTypes.SET_MY_POSTS:
      return {
        ...state,
        myPosts: action.payload
      }
    default:
      return state
  }
}
