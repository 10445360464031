import React from "react";
import { Card } from "antd";
const { Meta } = Card;
const MobileSliderCard = (props) => {
  return (
    <Card
      bordered={false}
      className="carousel-card"
      cover={<img className="carousel-imgs" src={props.img} alt={props.alt} />}
    >
      <Meta
        title={
          <div
            className="text-primary"
            style={{ fontFamily: "Gabriela", fontSize: "1.25rem" }}
          >
            {props.heading}
          </div>
        }
        description={
          <div
            className="text-dark"
            style={{ fontFamily: "Gabriela", fontSize: "1rem" }}
          >
            {props.description}
          </div>
        }
      ></Meta>
    </Card>
  );
};

export default MobileSliderCard;
