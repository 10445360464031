import React, { useState } from "react";
import { connect } from "react-redux";
import { Jutsu } from "react-jutsu";
import { Button, Card, message, Modal, Row } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import PrescriptionForm from "./PrescriptionForm";
import LoadingMask from "./LoaderMask/LoadingMask";
import { Redirect } from "react-router-dom";
import { apis } from "../shared/apis";
import axios from "axios";

const JitsiVideoCallWindow = (props) => {
  // console.log(props);
  // const [displayName, setDisplayName] = useState(
  //   props?.location?.state?.displayName
  //   );
  // const [roomName, setRoomName] = useState(props?.location?.state?.roomName);
  // const [pdlaid, setPdlaid] = useState(props?.location?.state?.pdlaid);
  const [isCall, setIsCall] = useState(true);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const displayName = props?.location?.state?.displayName;
  const roomName = props?.location?.state?.roomName;
  const pdlaid = props?.location?.state?.pdlaid;
  const patientmobile = props?.location?.state?.patientmobile;
  const handleAPI = (JitsiMeetAPI) => {
    // console.log(JitsiMeetAPI);
  };
  const sendSMS = async () => {
    if (!patientmobile) return;
    await axios({
      ...apis.sendVideoMeetingSms,
      data: {
        pdlaid: pdlaid,
        calling_mode: "0",
        number: patientmobile,
      },
    })
      .then((res, err) => {
        if (err) message.error("Err while Sending SMS");
        else message.success("SMS SENT ");
      })
      .catch((err) => {
        if (err) message.error("Err while Sending SMS");
      });
  };

  if (!props?.location?.state?.pdlaid) return <Redirect to="/"></Redirect>;

  return (
    <Row
      style={{
        height: "100%",
      }}
      className="row mr-0"
    >
      {isCall ? (
        <div className="pr-2 pl-4 py-4 col bg-light" id="jutsu-container">
          <Jutsu
            domain={"jepreventivescreening.com"}
            displayName={displayName}
            roomName={roomName}
            // roomName={pdlaid}
            onAPILoad={handleAPI}
            onMeetingEnd={() => {
              message.info("Call Ended");
              setIsCall(false);
              if (props?.location?.state?.isUser) {
                props?.history?.push("/dashboard");
              }
              // Modal.confirm({
              //   title: "Call Ended",
              //   // content: "Go to Home ? ",
              //   // okText: "Home",
              //   // onOk: () => props?.history.push("/"),
              //   // cancelText: "Stay",
              // });
            }}
            loadingComponent={
              <LoadingMask message="Loading meeting..."></LoadingMask>
            }
          ></Jutsu>
        </div>
      ) : null}
      {props?.location?.state?.showUploadForm ? (
        <div
          className={isCall ? "col-xl-5" : "col px-4"}
          style={{ overflowY: "scroll" }}
        >
          <Card
            headStyle={{
              backgroundColor: "#1890ff",
              color: "white",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
            bodyStyle={{
              padding: "5px 10px",
            }}
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              width: "100%",
              height: "100%",
              maxHeight: "90vh",
            }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>Send Prescription</div>
                {!isCall && (
                  <div className="mx-2">
                    <Button
                      onClick={() => {
                        sendSMS();
                        setIsCall(true);
                      }}
                      // type="danger"
                      style={{
                        backgroundColor: "#389e0d",
                        color: "white",
                        border: "1px solid #1890ff",
                      }}
                      icon={
                        <ReloadOutlined style={{ display: "inline-flex" }} />
                      }
                    >
                      Call Again
                    </Button>
                  </div>
                )}
              </div>
            }
          >
            <PrescriptionForm
              pdla_id={pdlaid}
              timeslot={props.location.state.timeslot}
              isPreviewVisible={isPreviewVisible}
              setIsPreviewVisible={setIsPreviewVisible}
              {...props}
            ></PrescriptionForm>
          </Card>
        </div>
      ) : null}
    </Row>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JitsiVideoCallWindow);
