import React, { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { apis } from "../shared/apis";
import CardHolderFrame from "./containers/CardHolderFrame";
import AllDocPlanList from "./AllDocPlanList";
import BoughtSubsPlanList from "./BoughtSubsPlanList";

const MySubscriptionsList = (props) => {
  const [loader, setLoader] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [docPlanD, setDocPlanD] = useState({});
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/choose-option",
      pathName: "Service List",
    },
    {
      path: "/my-subscription",
      pathName: "My Subscription",
    },
  ];
  const holderProps = {
    routes: routes,
    title: "My Subscriptions",
    loader: loader,
  };

  useEffect(() => {
    const fetchDoctorPlanDetails = async () => {
      setLoader(true);
      await axios({
        ...apis.getDoctorPlanDetails,
        data: {
          dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          // console.log("Res: ", res);
          setAllPlans(res.data.allplan);
          setDocPlanD(res.data);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
        });
    };
    fetchDoctorPlanDetails();
  }, [shouldUpdate]);

  return (
    <div>
      <CardHolderFrame {...holderProps} {...props}>
        {docPlanD?.plan_buy_flag === "Y" ? (
          <BoughtSubsPlanList {...docPlanD} {...props}></BoughtSubsPlanList>
        ) : (
          <AllDocPlanList
            {...props}
            allPlan={allPlans}
            updateList={() => setShouldUpdate(!shouldUpdate)}
          ></AllDocPlanList>
        )}
      </CardHolderFrame>
    </div>
  );
};

export default MySubscriptionsList;
