import React from "react";
import { isBrowser } from "react-device-detect";

const PaymentSuccessCard = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: isBrowser ? "50%" : "100%",
      }}
    >
      <table className="table ">
        <tbody>
          {props?.statusCode
            ? props?.statusCode === 200
              ? props.data.map((row, i) =>
                  row?.value ? (
                    <React.Fragment key={i + Math.random()}>
                      <tr>
                        <th
                          style={{ textAlign: "right" }}
                          className="text-primary"
                        >
                          {row.title} :{" "}
                        </th>
                        <td>{row.value + "" || ""}</td>
                      </tr>
                    </React.Fragment>
                  ) : null
                )
              : props.data.map((row, i) =>
                  row.value ? (
                    <React.Fragment key={i + Math.random()}>
                      <tr>
                        <th
                          style={{ textAlign: "right" }}
                          className="text-primary"
                        >
                          {row.title} :{" "}
                        </th>
                        <td>{row.value + "" || ""}</td>
                      </tr>
                    </React.Fragment>
                  ) : null
                )
            : props.data.map((row, i) =>
                row?.value ? (
                  <React.Fragment key={i + Math.random()}>
                    <tr>
                      <th
                        style={{ textAlign: "right" }}
                        className="text-primary"
                      >
                        {row.title} :{" "}
                      </th>
                      <td>{row.value + "" || ""}</td>
                    </tr>
                  </React.Fragment>
                ) : null
              )}
        </tbody>
      </table>
    </div>
  );
};
export default PaymentSuccessCard;
