import * as ActionTypes from "../ActionTypes";
const initialState = {
  serviceList: [],
  selectedService: {},
  specialityList: [],
  selectedSpeciality: {},
  doctorsList: [],
  outcomePackages: [],
  userCurrentLocation: { isOn: false },
  userState: {
    value: "Select State",
    label: "Select State",
    key: "Select State",
  },
  userCity: {
    value: "Select City",
    label: "Select City",
    key: "Select City",
  },
  userLocality: {
    value: "Select Locality",
    label: "Select Locality",
    key: "Select Locality",
  },
};

const formatPackages = (data) => {
  let tempArr = {};
  data.forEach((d, i) => {
    tempArr[d.mom_id] = { ...d, coverage: [] };
  });
  data.forEach((d, i) => {
    tempArr[d.mom_id].coverage = [...tempArr[d.mom_id].coverage, d.om_name];
  });
  return tempArr;
};
export const userDashReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };
    case ActionTypes.SET_SPECIALITY_LIST:
      return {
        ...state,
        specialityList: action.payload.sparr,
      };
    case ActionTypes.SET_SPECIALITY:
      return {
        ...state,
        selectedSpeciality: action.payload,
      };
    case ActionTypes.SET_DOCTORS_LIST:
      return {
        ...state,
        doctorsList: action.payload,
      };
    case ActionTypes.SET_OUTCOME_PACKAGES:
      return {
        ...state,
        outcomePackages: formatPackages(action.payload),
      };
    case ActionTypes.SET_USER_CURRENT_LOCATION:
      return {
        ...state,
        userCurrentLocation: {
          value: "Current Location",
          label: "Current Location",
          key: "Current Location",
          llocalityid: "Current Location",
          llocalityname: "Current Location",
          llocalitystate: "Current Location",
          llocality_lat: action.payload?.coords?.latitude,
          llocality_long: action.payload?.coords?.longitude,
          lpincode: "Current Location",
          ...action.payload,
        },
        userState: action.payload.isOn
          ? {
              value: "Current Location",
              label: "Current Location",
              key: "Current Location",
            }
          : {
              value: "Select State",
              label: "Select State",
              key: "Select State",
            },
        userCity: action.payload.isOn
          ? {
              value: "Current Location",
              label: "Current Location",
              key: "Current Location",
            }
          : {
              value: "Select City",
              label: "Select City",
              key: "Select City",
            },
        userLocality: action.payload.isOn
          ? {
              value: "Current Location",
              label: "Current Location",
              key: "Current Location",
              llocalityid: "Current Location",
              llocalityname: "Current Location",
              llocalitystate: "Current Location",
              llocality_lat: action.payload?.coords?.latitude,
              llocality_long: action.payload?.coords?.longitude,
              lpincode: "Current Location",
            }
          : {
              value: "Select Locality",
              label: "Select Locality",
              key: "Select Locality",
            },
      };
    case ActionTypes.SET_USER_STATE:
      return {
        ...state,
        userState: action.payload,
      };
    case ActionTypes.SET_USER_CITY:
      return {
        ...state,
        userCity: action.payload,
      };
    case ActionTypes.SET_USER_LOCALITY:
      return {
        ...state,
        userLocality: action.payload,
      };

    default:
      return state;
  }
};
export default userDashReducer;
