import React, { useState } from "react";
import {
  PhoneFilled,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  VideoCameraOutlined,
  // BulbFilled,
  // CalendarFilled,
} from "@ant-design/icons";
import { Button, Popover, Modal, message } from "antd";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import EventsList from "./EventsList";
import cal from "../../assets/images/calender.png";
import Videorecorderbutton from "../videoRecorder/VideoRecorderButton";
import { Link } from "react-router-dom";
const HeaderStrip = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleBVMClose = () => {
    setShowModal(false);
    window.location.reload();
  };
  return (
    <div className="header-strip container-fluid row mx-0 justify-content-between">
      <div className="strip-left-actions col-sm-4 px-2">
        <div className="strip-phone">
          Contact Us
          <PhoneFilled
            style={{
              display: "inline-flex",
              transform: "scale(-1)",
              // transform: "rotate(90deg)",
            }}
            className="mx-2"
          />
          <a
            href="tel: +91 1141025512"
            style={{ textDecoration: "none", color: "white" }}
          >
            +91-11-41025512
          </a>
        </div>
        {/* <div className="strip-make-appointment" style={{ display: "none" }}> */}
        {/* <Tooltip title="Health Tip:Eat Regularly, control the portion size">
            <BulbFilled
              className="mx-3"
              style={{
                display: "inline-flex",
                color: "#fff566",
                fontSize: "1.25rem",
                cursor: "pointer",
              }}
            ></BulbFilled>
          </Tooltip> */}

        {/* <Popover title="Upcoming Events" content={<EventsList></EventsList>}> */}
        {/* <CalendarFilled
                className="mx-2"
                style={{
                  display: "inline-flex",
                  color: "#e6f7ff",
                  fontSize: "1.25rem",
                  cursor: "pointer",
                }}
              ></CalendarFilled> */}
        {/* <img
              src={cal}
              style={{
                display: "inline-flex",
                // color: "#e6f7ff",
                width: "25px",
                // fontSize: "1.25rem",
                cursor: "pointer",
                alignItems: "flex-start",
              }}
              alt={"Cal"}
            ></img> */}
        {/* </Popover> */}
        {/* </div> */}
        {/* <div className="strip-make-appointment">
          <AnchorLink href="#book-an-appointment" offset={20}>
            <Button type="primary" shape="round">
              Make An Appointment
            </Button>
          </AnchorLink>
        </div> */}
      </div>
      <div className="col-sm text-center">
        {/* {localStorage.getItem("token") ? (
          <Button
            type="primary"
            shape="round"
            className="px-4"
            icon={<VideoCameraOutlined />}
            onClick={() =>
              Modal.info({
                title: "Note: ",
                content: (
                  <div className="text-left">
                    <ol
                      style={{
                        // textAlign: "justify",
                        fontWeight: "500",
                        padding: "1rem 0",
                        fontSize: "1rem",
                      }}
                    >
                      <li>Relax, Be Calm</li>
                      <li>Sit Comfortably</li>
                      <li>Place should have reasonable light</li>
                      <li> Please ensure there are no shadow on your face</li>
                    </ol>
                    <p style={{ width: "100%" }}>Press OK to continue...</p>
                  </div>
                ),
                closable: true,
                visible: false,
                onOk: () => setShowModal(true),
              })
            }
          >
            Body Vital Measurement
          </Button>
        ) : ( */}
        <Link to={localStorage.getItem("token") ? "/bvm" : "/user-login/bvm"}>
          <Button
            type="primary"
            shape="round"
            className="px-4"
            icon={<VideoCameraOutlined />}
            onClick={() => {
              if (!localStorage.getItem("token"))
                message.error("Please Login to use this feature");
            }}
          >
            Body Vital Measurement
          </Button>
        </Link>
        {/* )} */}
      </div>
      <div className="strip-right-actions col-sm-4">
        <div className="strip-icons">
          <Popover title="Upcoming Events" content={<EventsList></EventsList>}>
            {/* <CalendarFilled
                className="mx-2"
                style={{
                  display: "inline-flex",
                  color: "#e6f7ff",
                  fontSize: "1.25rem",
                  cursor: "pointer",
                }}
              ></CalendarFilled> */}
            <img
              src={cal}
              style={{
                display: "inline-flex",
                // color: "#e6f7ff",
                width: "25px",
                // fontSize: "1.25rem",
                cursor: "pointer",
                alignItems: "flex-start",
              }}
              alt={"Cal"}
              className="header-strip-icons"
            ></img>
          </Popover>
          <a
            target="blank"
            className="header-strip-icons"
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.facebook.com/jan.elaaj.35"
          >
            <FacebookOutlined style={{ display: "inline-flex" }} />
          </a>
          <a
            target="blank"
            className="header-strip-icons"
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.instagram.com/janelaaj/"
          >
            <InstagramOutlined style={{ display: "inline-flex" }} />
          </a>
          <a
            target="blank"
            className="header-strip-icons"
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.linkedin.com/in/jan-elaaj-015087165/"
          >
            <LinkedinOutlined style={{ display: "inline-flex" }} />
          </a>
          <a
            target="blank"
            className="header-strip-icons"
            style={{ textDecoration: "none", color: "white" }}
            href="https://twitter.com/elaaj_jan"
          >
            <TwitterOutlined style={{ display: "inline-flex" }} />
          </a>
        </div>
        {/* <div className="strip-make-appointment">
          <Link to="/partner-signup">
            <Button
              type="primary"
              // style={{ backgroundColor: "#FFD800" }}
              // className="text-dark"
              shape="round"
            >
              Partner With Us
            </Button>
          </Link>
        </div> */}
      </div>
      <Modal
        visible={showModal}
        onCancel={handleBVMClose}
        onOk={handleBVMClose}
        footer={[]}
        centered
        destroyOnClose={true}
        title={<div>Body Vital Measurement</div>}
      >
        {showModal ? (
          <Videorecorderbutton
            setShowModal={setShowModal}
          ></Videorecorderbutton>
        ) : null}
      </Modal>
    </div>
  );
};

export default HeaderStrip;
