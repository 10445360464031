import { message, Modal, Table } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { apis } from "../shared/apis";
import PaymentReqForm from "./PaymentReqForm";
import RequestPaymentListFooter from "./RequestPaymentListFooter";
import SearchPatient from "./SearchPatient";
import moment from "moment";

const RequestPaymentsList = ({ selectedClinic, selectedRole, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment);
  const [endDate, setEndDate] = useState(moment);
  const [showPaymentReqForm, setShowPaymentReqForm] = useState(false);

  const [cooloffDays, setCooloffDays] = useState(0);
  const [tdsPercent, setTdsPercent] = useState(0);
  const [commission, setCommission] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPatientAmount, setTotalPatientAmount] = useState(0);
  const [billingAmount, setBillingAmount] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const updateData = () => {
    setShouldUpdate(!shouldUpdate);
  };
  const dProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    updateData,
  };
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "pm_patient_name",
      key: "pm_patient_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Patient Gender",
      dataIndex: "pm_gender",
      key: "pm_gender",
      ellipsis: true,
      width: 150,
      align: "center",
      render: (gen) => <span>{gen === "M" ? "Male" : "Female"}</span>,
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Patient Age",
      dataIndex: "age",
      key: "age",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: "min-content",
    },
    {
      title: "Patient ID",
      dataIndex: "pdla_id",
      key: "pdla_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    // {
    //   title: "Amount Paid",
    //   dataIndex: "patient_amount_paid_by_patient",
    //   key: "patient_amount_paid_by_patient",
    //   ellipsis: true,
    //   width: 150,
    //   align: "center",
    //   //        responsive: ["md"],

    //   //   width: 150,
    // },
    {
      title: "DoctorAmount Paid",
      dataIndex: "payment_to_doctor",
      key: "payment_to_doctor",
      ellipsis: true,
      width: 180,
      align: "center",
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Appontment Date",
      dataIndex: "pdla_appointment_date",
      key: "pdla_appointment_date",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    // {
    //   otp: "6858",
    //   payment_to_doctor_transaction_id: "NA",
    // },
  ];

  const empColumns = [
    {
      title: "Distributor Name",
      dataIndex: "distributer_name",
      key: "distributer_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Distributor Code",
      dataIndex: "distributer_id",
      key: "distributer_id",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Distributor Consultation Share",
      dataIndex: "distributer_consultation_share",
      key: "distributer_consultation_share",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Distributor Symptom Update Share",
      dataIndex: "distributer_symptom_update_share",
      key: "distributer_symptom_update_share",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Dealer Name",
      dataIndex: "dealer_name",
      key: "dealer_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Dealer Code",
      dataIndex: "dealer_id",
      key: "dealer_id",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Dealer Consultation Share",
      dataIndex: "dealer_consultation_share",
      key: "dealer_consultation_share",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Dealer Symptom Update Share",
      dataIndex: "dealer_symptom_update_share",
      key: "dealer_symptom_update_share",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Sub-Dealer Name",
      dataIndex: "sub_dealer_name",
      key: "sub_dealer_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: "min-content",
    },
    {
      title: "Sub Dealer Code",
      dataIndex: "sub_dealer_id",
      key: "sub_dealer_id",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Sub Dealer Consultation Share",
      dataIndex: "sub_dealer_consultation_share",
      key: "sub_dealer_consultation_share",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Sub Dealer Symptom Update Share",
      dataIndex: "sub_dealer_symptom_update_share",
      key: "sub_dealer_symptom_update_share",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
    {
      title: "Patient Name",
      dataIndex: "pm_patient_name",
      key: "pm_patient_name",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: 150,
    },
    {
      title: "Patient Gender",
      dataIndex: "pm_gender",
      key: "pm_gender",
      ellipsis: true,
      width: 150,
      align: "center",
      render: (gen) => <span>{gen === "M" ? "Male" : "Female"}</span>,
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Patient Age",
      dataIndex: "age",
      key: "age",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
      //   width: "min-content",
    },
    {
      title: "Patient ID",
      dataIndex: "pdla_id",
      key: "pdla_id",
      ellipsis: true,
      width: 150,
      align: "center",
    },
    // {
    //   title: "Amount Paid",
    //   dataIndex: "patient_amount_paid_by_patient",
    //   key: "patient_amount_paid_by_patient",
    //   ellipsis: true,
    //   width: 150,
    //   align: "center",
    //   //        responsive: ["md"],

    //   //   width: 150,
    // },
    {
      title: "DoctorAmount Paid",
      dataIndex: "payment_to_doctor",
      key: "payment_to_doctor",
      ellipsis: true,
      width: 180,
      align: "center",
      //        responsive: ["md"],

      //   width: 150,
    },
    {
      title: "Appontment Date",
      dataIndex: "pdla_appointment_date",
      key: "pdla_appointment_date",
      ellipsis: true,
      width: 150,
      align: "center",
      //        responsive: ["md"],
    },
  ];

  const reqPaymentHandler = () => {
    setShowPaymentReqForm(true);
  };

  const closeBankForm = () => {
    setShowPaymentReqForm(false);
  };

  const calculateBillingAmount = (totalAmount) => {
    let billAmnt = 0;
    billAmnt += totalAmount;
    let tdsValue = 0;
    tdsValue = tdsPercent * totalAmount;
    billAmnt += tdsValue;
    billAmnt += commission;
    return billAmnt;
  };

  const addKey = (arr) => {
    let temp = [];
    let totalAmt = 0;
    let totalPAmt = 0;
    arr.forEach((item) => {
      let obj = { ...item, key: item?.pdla_id };
      temp.push(obj);
      totalAmt += props.partnerData.isPartnerLoggedIn
        ? item.payment_to_doctor
        : JSON.parse(localStorage.getItem("empRole")).role === "DISTRIBUTER"
        ? item.distributer_consultation_share +
          item.distributer_symptom_update_share
        : JSON.parse(localStorage.getItem("empRole")).role === "DEALER"
        ? item.dealer_consultation_share + item.dealer_symptom_update_share
        : item.sub_dealer_consultation_share +
          item.sub_dealer_symptom_update_share;
      totalPAmt += item?.patient_amount_paid_by_patient;
      // console.log(totalAmt)
    });
    setTotalAmount(totalAmt);
    setTotalPatientAmount(totalPAmt);
    setBillingAmount(calculateBillingAmount(totalAmt));
    // console.log(temp);
    // console.log(totalAmt)
    return temp;
  };

  useEffect(() => {
    const fetchPendingPaymentsList = async () => {
      let obj = {
        end_date: endDate.length < 11 ? endDate : endDate.format("DD-MM-YYYY"),
        start_date:
          startDate.length < 11 ? startDate : startDate.format("DD-MM-YYYY"),
        role: selectedRole?.role,
        dlm_id: selectedClinic?.dlmid,
        subject: props?.subject,
        id: props?.id,
      };
      setLoading(true);
      await axios({ ...apis.getPendingPaymentDetailsForDLM, data: obj })
        .then((res, err) => {
          if (err) message.error("Err in Fetching ReqPayLis:");
          // console.log("Res", res.data);
          // console.log("Res", addKey(res.data.info));

          setCooloffDays(res.data?.jc_je_dlm_cooloff_days);
          setTdsPercent(res.data?.jc_je_tds_percentage);
          setCommission(res.data?.jc_paytm_commission);
          setData(addKey(res.data.info));
          setLoading(false);
        })
        .catch((err) => {
          // if (err) console.log("Err in Fetching RPL:", err);
          setLoading(false);
        });
    };

    fetchPendingPaymentsList();

    // return () => {
    //     cleanup
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate, selectedRole?.role, selectedClinic?.dlmid]);
  // }, [startDate, endDate, selectedRole?.role, selectedClinic?.dlmid]);
  return (
    <div>
      <SearchPatient {...dProps}></SearchPatient>
      <Table
        columns={props.partnerData.isPartnerLoggedIn ? columns : empColumns}
        dataSource={data}
        loading={loading}
        // pagination={{ pageSize: 50 }}
        scroll={{ y: 250 }}
        // size="small"
        className="my-2 px-1 pr-3"
        footer={() => (
          <RequestPaymentListFooter
            totalAmount={totalAmount}
            totalPatientAmount={totalPatientAmount}
            billingAmount={billingAmount}
            cooloffDays={cooloffDays}
            tdsPercent={tdsPercent}
            commission={commission}
            reqPaymentHandler={reqPaymentHandler}
            shouldReqPayment={data.length > 0}
          ></RequestPaymentListFooter>
        )}
      />
      <Modal
        centered
        visible={showPaymentReqForm}
        onCancel={() => setShowPaymentReqForm(false)}
        title={"Payment Request"}
        width={920}
        footer={[]}
        destroyOnClose={true}
      >
        <PaymentReqForm
          totalAmount={totalAmount}
          totalPatientAmount={totalPatientAmount}
          billingAmount={billingAmount}
          cooloffDays={cooloffDays}
          tdsPercent={tdsPercent}
          commission={commission}
          startDate={startDate}
          endDate={endDate}
          closeBankForm={closeBankForm}
          {...props}
        ></PaymentReqForm>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    partnerData: state.partnerData,
  };
};

export default connect(mapStateToProps)(RequestPaymentsList);
