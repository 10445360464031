import React from "react";
import { Card } from "antd";

import CardHeaderCustom from "../containers/CardHeaderCustom";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import { isBrowser } from "react-device-detect";
const CardHolderFrame = (props) => {
  return props?.hideFrame ? (
    <div className="text-center">
      {props?.extra ? <div className="mb-4">{props?.extra}</div> : null}
      <div>{props?.children}</div>
    </div>
  ) : (
    <div
      className={isBrowser ? "container p-0 " : ""}
      style={isBrowser ? { minHeight: "80vh" } : {}}
    >
      <Card
        // key={Math.random()}
        style={
          isBrowser
            ? { minHeight: "80vh", margin: "50px 0%" }
            : { margin: "50px 2%" }
        }
        headStyle={cardHeaderStyle}
        title={
          <CardHeaderCustom
            title={props?.title ? props?.title : "No Tilte"}
            showPath={props?.showPath ? props?.showPath : true}
            routes={props?.routes}
          ></CardHeaderCustom>
        }
        loading={props?.loader}
        extra={props?.extra}
        bodyStyle={props?.bodyStyle}
      >
        {props?.children}
      </Card>
    </div>
  );
};

export default CardHolderFrame;
