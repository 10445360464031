import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAppointmentListFailed,
  getAppointmentListLoading,
  getAppointmentListSuccess,
  setAppointmentList,
  setIsPartnerLoggedIn,
} from "../redux/ActionCreators/VerificationActionCreators";
import "../assets/styles/DoctorVerification.css";
import AppointmentCard from "./AppointmentCard";
import axios from "axios";
import { apis } from "../shared/apis";
import { Redirect } from "react-router-dom";
import { Empty, DatePicker, message } from "antd";
import moment from "moment";
import { cardHeaderStyle } from "../assets/styles/PartnerAppStyles";
import CardHolderFrame from "./containers/CardHolderFrame";
import LoadingMask from "./LoaderMask/LoadingMask";

class AppointmentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date().toISOString(),
      dlmid: localStorage.getItem("dlm_id"),
      redirect: false,
    };
  }

  handleDate = (v, f) => {
    if (v) {
      this.setState({ date: new Date(f).toISOString() });
      this.getAppointmentList({
        dlmid: this.state.dlmid,
        appointmentdate: `${f}`,
      });
    }
  };
  getAppointmentList = async (obj) => {
    this.props.setAppointmentListLoading(true);
    await axios({ ...apis.partnerAppointmentList, data: obj })
      .then((res, err) => {
        if (err) {
          let error = new Error("Error " + res.status + ": " + res.statusText);
          error.response = res;
          this.props.setIsPartnerLoggedIn(true);
          this.props.setAppointmentListFailed(false);
          this.props.setAppointmentListLoading(false);

          throw error; // server is responding with an error response code, will be handled by catch
        } else {
          this.props.setIsPartnerLoggedIn(true);
          this.props.setAppointmentListSuccess(true);
          this.props.setAppointmentList(res.data);
          this.props.setAppointmentListLoading(false);
          // console.log(res);
        }
      })

      .catch((error) => {
        message.error("Error while Fetching ApList:");
        this.props.setAppointmentListLoading(false);
      });
  };

  async componentDidMount() {
    if (this.state.dlmid)
      this.getAppointmentList({
        dlmid: this.state.dlmid,
        appointmentdate: this.yyyymmdd(new Date()),
      });
    else {
      this.setState({ redirect: true });
      localStorage.clear();
    }
  }
  yyyymmdd = (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      date.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  };

  render() {
    const routes = [
      {
        path: "/",
        pathName: "Home",
      },
      {
        path: "/roles-list",
        pathName: "Roles List",
      },
      {
        path: "/choose-location-list",
        pathName: "Clinics List",
      },
      {
        path: "/choose-option",
        pathName: "Service List",
      },
      {
        path: "/appointment-list",
        pathName: "Appointment List",
      },
    ];
    if (this.state.redirect) return <Redirect to="/partner-login"></Redirect>;
    return (
      <div className="container">
        <CardHolderFrame
          {...this.props}
          loading={this.props.partnerData.AppointmentListLoading}
          headStyle={cardHeaderStyle}
          title="Appointment List"
          showPath={true}
          routes={routes}
          extra={
            <span className="" style={{ lineHeight: 2 }}>
              <DatePicker
                size="large"
                onChange={(v, f) => this.handleDate(v, f)}
                format={"YYYY-MM-DD"}
                defaultValue={moment()}
              />
            </span>
          }
        >
          {this.props.partnerData.AppointmentListLoading ? (
            <LoadingMask message="Loading..."></LoadingMask>
          ) : this.props.partnerData.AppointmentList.hasOwnProperty("info") &&
            this.props.partnerData.AppointmentList.info.length ? (
            this.props.partnerData.AppointmentList.info.map(
              (appointment, i) => (
                <AppointmentCard
                  key={i}
                  index={i + 1}
                  data={appointment}
                  isMissed={this.state.date}
                  {...this.props}
                ></AppointmentCard>
              )
            )
          ) : (
            <Empty description="No Appointments Yet"></Empty>
          )}
        </CardHolderFrame>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    partnerData: state.partnerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
    setAppointmentListLoading: (flag) =>
      dispatch(getAppointmentListLoading(flag)),
    setAppointmentListFailed: (flag) =>
      dispatch(getAppointmentListFailed(flag)),
    setAppointmentListSuccess: (flag) =>
      dispatch(getAppointmentListSuccess(flag)),
    setAppointmentList: (list) => dispatch(setAppointmentList(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentList);
