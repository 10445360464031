import React, { useState } from "react";
import { Button, Card } from "antd";
import {
  HomeOutlined,
  UserAddOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import SubDealerDeatails from "./SubDealerDeatails";
import SubDealerList from "./SubDealerList";
const { Meta } = Card;

const SubDealerCard = (props) => {
  const [showDocDetials, setShowDocDetials] = useState(false);
  const [showDearlersList, setShowDearlersList] = useState(false);

  return (
    <div>
      <Card
        type="inner"
        hoverable
        className="m-1"
        extra={
          <div>
            <Button
              // type="primary"
              shape="round"
              size="large"
              icon={<FileTextOutlined style={{ display: "inline-flex" }} />}
              onClick={() => {
                setShowDocDetials(!showDocDetials);
              }}
              className="mr-4"
            >
              {showDocDetials ? "Hide Details" : "View Details"}
            </Button>
            {props?.subject === "sub-dealer" ? null : (
              <Button
                type="primary"
                shape="round"
                size="large"
                icon={<UserAddOutlined style={{ display: "inline-flex" }} />}
                onClick={() => {
                  setShowDearlersList(!showDearlersList);
                }}
              >
                {showDearlersList ? `Hide Sub-Dealers` : `View Sub-Dealers`}
              </Button>
            )}
          </div>
        }
        title={
          <div style={{ fontSize: "1.25rem" }}>
            <UserAddOutlined
              style={{
                display: "inline-flex",
                marginRight: "0.5rem",
              }}
            ></UserAddOutlined>
            {props.sub.name}
          </div>
        }
        // title={
        //   <div style={{ fontSize: "1.25rem" }}>
        //     <HomeOutlined
        //       style={{
        //         display: "inline-flex",
        //         marginRight: "0.5rem",
        //       }}
        //     ></HomeOutlined>
        //     {props?.name}
        //   </div>
        // }
      >
        {showDocDetials && (
          <SubDealerDeatails {...props} {...props?.sub}></SubDealerDeatails>
        )}
        {showDearlersList && (
          <SubDealerList
            {...props}
            subject="sub-dealer"
            empid={props?.sub?.id}
          ></SubDealerList>
        )}
      </Card>
    </div>
  );
};

export default SubDealerCard;
