import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  // Modal,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
} from "reactstrap";
// import { sha256 } from "crypto-js";
// import { createHash } from "crypto";

// import sha256 from "crypto-js/sha256";
import "../../assets/styles/DoctorVerification.css";
import { styles } from "../../assets/styles/DoctorVerification";
import {
  setIsPartnerLoggedIn,
  setRolesList,
} from "../../redux/ActionCreators/VerificationActionCreators";
import { connect } from "react-redux";
// import { apis } from "../../shared/apis";
// import axios from "axios";
import Spinner from "reactstrap/lib/Spinner";
// import { Redirect } from "react-router-dom";
import app, { firebase } from "../../firebase";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { apis } from "../../shared/apis";
import { message } from "antd";
function UserLogin(props) {
  // let confirmationResult;
  const [state, setState] = useState({
    email: localStorage.getItem("partnerEmail"),
    password: "",
    oldPassword: "",
    newPassword: "",
    rePassword: "",
    phone: localStorage.getItem("loginPhone") || "",
  });
  // const [showChangePass, setShowChangePass] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showLoginError, setShowLoginError] = useState(false);
  // const [showChangePassError, setshowChangePassError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");

  // let changePassMess = "Your password is changed. Thank You.";

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // const isEmailValid = (email) => {
  //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     return true;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   console.log("App: ", app);
  // }, []);

  const fetchProfile = async (res) => {
    // const hashDigest = sha256(state.password);
    // console.log(Base64.stringify(hashDigest));
    await axios({
      ...apis.patientsignin,
      data: {
        // email: state.email,
        // password: Base64.stringify(hashDigest),
        contact: state.phone,
      },
    })
      .then((resp, err) => {
        // setLoader(false);

        if (err) {
          setShowLoginError(true);
          message.error("Something Went Wrong");
        } else if (resp?.data?.status !== "SUCCESS") {
          message.error("Please Register yourself");
          props?.history.push(`/user-signup/${state.phone}/${res?.user?.ya}`);
        } else {
          message.success("Logged in Successfully!");
          setVerifying(false);
          setShowLoginError(false);
          localStorage.setItem("token", res?.user?.ya);
          localStorage.setItem(
            "userEmail",
            JSON.stringify(res?.user?.email || resp.data?.role[0]?.patientemail)
          );
          localStorage.setItem("userProfile", JSON.stringify(resp.data));
          if (props?.match?.params?.redirectUri)
            props.history.push("/" + props?.match?.params?.redirectUri);
          else props.history.push("/dashboard");

          // localStorage.setItem(
          //   "userProfile",
          //   JSON.stringify({
          //     status: "SUCCESS",
          //     role: [
          //       {
          //         patientid: "PID2000154",
          //         patientname: "Vinay",
          //         patientdob: "1997-02-24",
          //         patientage: 24,
          //         patientgender: "M",
          //         patientmobile: "8448735275",
          //         patientemail: "AAAAAAAA@gmail.com",
          //         patientphoto: "",
          //         patientadd1: "Kali Bari Marg",
          //         patientadd2: null,
          //         patientcity: "New Delhi",
          //         patientpincode: null,
          //         patientlandmark: null,
          //         patientstate: null,
          //       },
          //     ],
          //   })
          // );

          // setShowLoginError(false);
          // localStorage.setItem("userEmail", state.email);
          // localStorage.setItem("token", res?.user?.ya);
          // localStorage.setItem("userProfile", JSON.stringify(resp.data));
          // localStorage.setItem(
          //   "userProfile",
          //   JSON.stringify({
          //     status: "SUCCESS",
          //     role: [
          //       {
          //         patientid: "PID2000154",
          //         patientname: "Vinay",
          //         patientdob: "1997-02-24",
          //         patientage: 24,
          //         patientgender: "M",
          //         patientmobile: "8448735275",
          //         patientemail: "AAAAAAAA@gmail.com",
          //         patientphoto: "",
          //         patientadd1: "Kali Bari Marg",
          //         patientadd2: null,
          //         patientcity: "New Delhi",
          //         patientpincode: null,
          //         patientlandmark: null,
          //         patientstate: null,
          //       },
          //     ],
          //   })
          // );
          // props.history.push("/dashboard");
        }
      })
      .catch((err) => {
        setVerifying(false);
        // console.log(error);
        setShowLoginError(true);
        message.error("Something Went Wrong");
      });
  };

  const handleLogin = async () => {
    localStorage.setItem("loginPhone", state.phone);
    // if (isEmailValid(state.email) && state.password) {
    //   setLoader(true);
    //   try {
    //     await app
    //       .auth()
    //       .signInWithEmailAndPassword(state.email, state.password)
    //       .then((res, err) => {
    //         if (err) {
    //           setLoader(false);
    //           setShowLoginError(true);
    //         } else {
    //           fetchProfile(res);
    //         }
    //       });
    //   } catch (err) {
    //     if (err) {
    //       setShowLoginError(true);
    //       setLoader(false);
    //       setShowLoginError(true);
    //     }
    //   }
    // }
    // if (!isEmailValid(state.email)) {
    //   setShowLoginError(true);
    //   props.setIsPartnerLoggedIn(false);
    // }
    if (codeSent) {
      // console.log(code);
      setVerifying(true);
      window.confirmationResult
        .confirm(code)
        .then((res) => {
          // User signed in successfully.
          // console.log(res);
          fetchProfile(res);
          localStorage.removeItem("loginPhone");
          // const user = result.user;

          // ...
        })
        .catch((error) => {
          setVerifying(false);
          // console.log(error);
          setShowLoginError(true);
          message.error("OTP Didn't Match");
          window.location.reload();
          // User couldn't sign in (bad verification code?)
          // ...
        });
    } else {
      setLoader(true);
      app.auth().useDeviceLanguage();
      window.appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-verifier",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
            // console.log("captcha resolved");
            // console.log("respose:", response);
          },
        }
      );
      // firebase.auth().settings.appVerificationDisabledForTesting = true;

      firebase
        .auth()
        .signInWithPhoneNumber("+91" + state.phone, window.appVerifier)
        .then((confirmationR) => {
          message.success("OTP Sent !");
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setLoader(false);
          setCodeSent(true);
          window.confirmationResult = confirmationR;

          // handleCode(confirmationR);
          // let codeWP = window.prompt("Enter OTp");
          // confirmationR
          //   .confirm(codeWP);
          //   .then((result) => {
          //     // User signed in successfully.
          //     // const user = result.user;
          //     console.log("Result: ", result);
          //     message.success("Valid OTP");
          //     // ...
          //   })
          //   .catch((error) => {
          //     // User couldn't sign in (bad verification code?)
          //     // ...
          //     message.error("Invalid OTP");
          //   });

          // console.log("confirmationResult : ", confirmationResult);
          // ...
        })
        .catch((error) => {
          setLoader(false);
          message.error("Error: SMS not sent | " + error.message);
          setCodeSent(false);
          window.location.reload();

          // ...
          window.appVerifier.render().then(function (widgetId) {
            window.appVerifier.reset(widgetId);
          });
          // console.log("Error : ", error);
        });
    }
  };
  // const handleCode = (confirmationResult) => {
  //   let cwp = window.prompt("OTP :");
  //   confirmationResult
  //     .confirm(cwp)
  //     .then((result) => {
  //       // User signed in successfully.
  //       const user = result.user;
  //       message.success("Valid OTP");
  //       // ...
  //     })
  //     .catch((error) => {
  //       // User couldn't sign in (bad verification code?)
  //       // ...
  //       message.error("Invalid OTP");
  //     });
  // };

  // const changeUserPass = () => {
  //   if (state.newPassWord === state.rePassWord) {
  //     // this.props.changePass({
  //     //   empmailid: this.email.value,
  //     //   emppassword: sha256(this.new_pass.value).toString(),
  //     //   empoldpassword: sha256(this.old_pass.value).toString(),
  //     // });
  //   } else changePassMess = "Password Mismatch";
  //   toggleChangePass();
  // };
  // const showChangePassToggler = () => {
  //   setShowChangePass(!showChangePass);
  // };
  // const togglefieldLogin = () => {
  //   setShowLoginError(!showLoginError);
  // };
  // const toggleChangePass = () => {
  //   setshowChangePassError(!showChangePassError);
  // };
  if (localStorage.getItem("token")) return <Redirect to="/"></Redirect>;
  return (
    <div>
      <div className="container">
        {/* <Modal isOpen={showLoginError} toggle={togglefieldLogin}>
          <ModalHeader toggle={togglefieldLogin}> Error </ModalHeader>
          <ModalBody> Email or Password is Incorrect </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={togglefieldLogin}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showChangePassError} toggle={toggleChangePass}>
          <ModalHeader toggle={toggleChangePass}>Information</ModalHeader>
          <ModalBody> {changePassMess} </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleChangePass}>
              OK
            </Button>
          </ModalFooter>
        </Modal> */}
        <Form
          onSubmit={(e) => e.preventDefault()}
          className="col-sm-6"
          style={{ margin: "auto" }}
        >
          <Card style={{ marginTop: 120 }}>
            <CardHeader
              className=" bg-primary text-light text-center"
              style={styles.cardHeader}
            >
              User SignIn / Signup
            </CardHeader>
            <CardBody className="card1body">
              <FormGroup row>
                <div id="recaptcha-verifier"></div>
                <div className="col-md-2" style={styles.card1Label}>
                  <Label for="phone"> Phone </Label>
                </div>
                {/* <Col>
                  <Input
                    xs="2"
                    sm="2"
                    style={styles.card1Input}
                    // type="number"
                    name="countryCode"
                    id="countryCode"
                    placeholder="Code"
                    // onChange={onChangeHandler}
                    value={"+91"}
                    // required
                    disabled={codeSent}
                  />
                </Col> */}{" "}
                <Col>
                  <Input
                    style={styles.card1Input}
                    // type="number"
                    name="phone"
                    id="phone"
                    placeholder="Please enter phone number"
                    onChange={onChangeHandler}
                    value={state.phone || ""}
                    required
                    disabled={codeSent}
                  />
                </Col>
              </FormGroup>
              {codeSent && (
                <FormGroup row>
                  <div className="col-md-2" style={styles.card1Label}>
                    <Label for="code"> OTP </Label>
                  </div>
                  <Col>
                    <Input
                      style={styles.card1Input}
                      // type="number"

                      name="code"
                      id="code"
                      placeholder="Enter OTP"
                      onChange={(e) => setCode(e.target.value)}
                      value={code || ""}
                      required
                    />
                  </Col>
                </FormGroup>
              )}
              {/* <FormGroup row>
                <div className="col-md-2" style={styles.card1Label}>
                  <Label for="email"> Email </Label>
                </div>
                <Col>
                  <Input
                    style={styles.card1Input}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@domain.com"
                    onChange={onChangeHandler}
                    value={state.email || ""}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-2" style={styles.card1Label}>
                  <Label for="pass"> Password </Label>
                </div>
                <Col>
                  <Input
                    style={styles.card1Input}
                    type="password"
                    name="password"
                    id="pass"
                    placeholder="Password"
                    onChange={onChangeHandler}
                    value={state.password || ""}
                    required
                  />
                </Col>
              </FormGroup> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {/* <Button
                  style={{ marginLeft: 1, marginTop: 20 }}
                  className="ml-5 btn btn-primary"
                  color="primary"
                  value="submit"
                  onClick={showChangePassToggler}
                >
                Change Password
              </Button> */}
                {/* <Link to="/forgot-password/user">Forgot Password</Link> */}
                {codeSent ? (
                  <Button
                    style={{ marginTop: 20, margin: "auto" }}
                    color="primary"
                    type="submit"
                    value="submit"
                    onClick={handleLogin}
                    id="sign-in-button"
                  >
                    {verifying ? (
                      <span>
                        <Spinner size="sm" className="mx-2"></Spinner>Loggin
                        in...
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                ) : (
                  <Button
                    style={{ marginTop: 20, margin: "auto" }}
                    color="primary"
                    type="submit"
                    value="submit"
                    onClick={handleLogin}
                    id="sign-in-button"
                  >
                    {loader ? (
                      <span>
                        <Spinner size="sm" className="mx-2"></Spinner>
                        Sending...
                      </span>
                    ) : (
                      "Send OTP"
                    )}
                  </Button>
                )}
                {/* <Link to="/user-signup">Register</Link> */}
              </div>
            </CardBody>
          </Card>
        </Form>
        {/* <Modal isOpen={showChangePass} toggle={showChangePassToggler}>
          <ModalHeader toggle={showChangePassToggler}>
            Change Password
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <div className="col-md-5 mt-4" style={styles.card2Label}>
                  <Label for="old-pass"> Old Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    className="mt-4"
                    type="text"
                    name="oldPassword"
                    id="old-pass"
                    placeholder="Old Password"
                    onChange={onChangeHandler}
                    value={state.oldPassword}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-5" style={styles.card2Label}>
                  <Label for="re-pass"> New Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    type="text"
                    name="newPassword"
                    id="new-pass"
                    onChange={onChangeHandler}
                    value={state.newPassword}
                    placeholder="New Password"
                    minLength="8"
                    required
                    pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/"
                    title="password must be 8 to 15 characters long including at least one lowercase letter, one uppercase letter, one numeric digit and one special character"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-5" style={styles.card2Label}>
                  <Label for="re-pass"> Retype New Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    type="text"
                    name="rePassword"
                    id="re-pass"
                    onChange={onChangeHandler}
                    value={state.rePassword}
                    placeholder="Retype New Password"
                  />
                </Col>
              </FormGroup>
              <Button
                color="primary"
                className="ml-4 mb-4"
                onClick={changeUserPass}
              >
                Save
              </Button>
            </Form>
          </ModalBody>
        </Modal> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
    setPartnerRoles: (obj) => dispatch(setRolesList(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);

// OLD ONE

// import React, { useState } from "react";
// import {
//   Button,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Col,
//   Card,
//   CardBody,
//   CardHeader,
//   Modal,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
// } from "reactstrap";
// // import { sha256 } from "crypto-js";
// // import { createHash } from "crypto";

// // import sha256 from "crypto-js/sha256";
// import "../../assets/styles/DoctorVerification.css";
// import { styles } from "../../assets/styles/DoctorVerification";
// import {
//   setIsPartnerLoggedIn,
//   setRolesList,
// } from "../../redux/ActionCreators/VerificationActionCreators";
// import { connect } from "react-redux";
// // import { apis } from "../../shared/apis";
// // import axios from "axios";
// import Spinner from "reactstrap/lib/Spinner";
// // import { Redirect } from "react-router-dom";
// import app, { firebase } from "../../firebase";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { apis } from "../../shared/apis";
// import { message } from "antd";
// import sha256 from "crypto-js/sha256";
// import Base64 from "crypto-js/enc-base64";
// function UserLogin(props) {
//   let confirmationResult;
//   const [state, setState] = useState({
//     email: localStorage.getItem("partnerEmail"),
//     password: "",
//     oldPassword: "",
//     newPassword: "",
//     rePassword: "",
//     phone: "",
//   });
//   const [showChangePass, setShowChangePass] = useState(false);
//   const [showLoginError, setShowLoginError] = useState(false);
//   const [showChangePassError, setshowChangePassError] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [codeSent, setCodeSent] = useState(false);
//   const [code, setCode] = useState("");

//   let changePassMess = "Your password is changed. Thank You.";

//   const onChangeHandler = (e) => {
//     setState({ ...state, [e.target.name]: e.target.value });
//   };
//   const isEmailValid = (email) => {
//     if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
//       return true;
//     }
//     return false;
//   };

//   // useEffect(() => {
//   //   console.log("App: ", app);
//   // }, []);

//   const fetchProfile = async (res) => {
//     const hashDigest = sha256(state.password);
//     console.log(Base64.stringify(hashDigest));
//     await axios({
//       ...apis.patientsignin,
//       data: {
//         email: state.email,
//         password: Base64.stringify(hashDigest),
//       },
//     })
//       .then((resp, err) => {
//         setLoader(false);
//         if (err) {
//           setShowLoginError(true);
//           message.error("Something Went Wrong");
//         } else {
//           setShowLoginError(false);
//           localStorage.setItem("userEmail", state.email);
//           localStorage.setItem("token", res?.user?.ya);
//           localStorage.setItem("userProfile", JSON.stringify(resp.data));
//           // localStorage.setItem(
//           //   "userProfile",
//           //   JSON.stringify({
//           //     status: "SUCCESS",
//           //     role: [
//           //       {
//           //         patientid: "PID2000154",
//           //         patientname: "Vinay",
//           //         patientdob: "1997-02-24",
//           //         patientage: 24,
//           //         patientgender: "M",
//           //         patientmobile: "8448735275",
//           //         patientemail: "AAAAAAAA@gmail.com",
//           //         patientphoto: "",
//           //         patientadd1: "Kali Bari Marg",
//           //         patientadd2: null,
//           //         patientcity: "New Delhi",
//           //         patientpincode: null,
//           //         patientlandmark: null,
//           //         patientstate: null,
//           //       },
//           //     ],
//           //   })
//           // );
//           props.history.push("/dashboard");
//         }
//       })
//       .catch((err) => {
//         setShowLoginError(true);
//         message.error("Something Went Wrong");
//       });
//   };

//   const handleLogin = async () => {
//     if (isEmailValid(state.email) && state.password) {
//       setLoader(true);
//       try {
//         await app
//           .auth()
//           .signInWithEmailAndPassword(state.email, state.password)
//           .then((res, err) => {
//             if (err) {
//               setLoader(false);
//               setShowLoginError(true);
//             } else {
//               fetchProfile(res);
//             }
//           });
//       } catch (err) {
//         if (err) {
//           setShowLoginError(true);
//           setLoader(false);
//           setShowLoginError(true);
//         }
//       }
//     }
//     if (!isEmailValid(state.email)) {
//       setShowLoginError(true);
//       props.setIsPartnerLoggedIn(false);
//     }

//     // Code for login with phone

//     // app.auth().useDeviceLanguage();
//     // const appVerifier = new firebase.auth.RecaptchaVerifier(
//     //   "recaptcha-verifier",
//     //   {
//     //     size: "invisible",
//     //     callback: (response) => {
//     //       // reCAPTCHA solved, allow signInWithPhoneNumber.
//     //       // onSignInSubmit();
//     //       console.log("captcha resolved");
//     //       console.log("respose:", response);
//     //     },
//     //   }
//     // );
//     // // firebase.auth().settings.appVerificationDisabledForTesting = true;

//     // firebase
//     //   .auth()
//     //   .signInWithPhoneNumber(state.phone, appVerifier)
//     //   .then((confirmationR) => {
//     //     // SMS sent. Prompt user to type the code from the message, then sign the
//     //     // user in with confirmationResult.confirm(code).
//     //     setCodeSent(true);
//     //     confirmationResult = confirmationR;
//     //     handleCode(confirmationR);
//     //     // let codeWP = window.prompt("Enter OTp");
//     //     // confirmationR
//     //     //   .confirm(codeWP);
//     //     //   .then((result) => {
//     //     //     // User signed in successfully.
//     //     //     // const user = result.user;
//     //     //     console.log("Result: ", result);
//     //     //     message.success("Valid OTP");
//     //     //     // ...
//     //     //   })
//     //     //   .catch((error) => {
//     //     //     // User couldn't sign in (bad verification code?)
//     //     //     // ...
//     //     //     message.error("Invalid OTP");
//     //     //   });

//     //     console.log("confirmationResult : ", confirmationResult);
//     //     // ...
//     //   })
//     //   .catch((error) => {
//     //     console.log("Error: SMS not sent");
//     //     setCodeSent(false);
//     //     // ...
//     //     appVerifier.render().then(function (widgetId) {
//     //       appVerifier.reset(widgetId);
//     //     });
//     //     console.log("Error : ", error);
//     //   });
//   };

//   const handleCode = (confirmationResult) => {
//     let cwp = window.prompt("OTP :");
//     confirmationResult
//       .confirm(cwp)
//       .then((result) => {
//         // User signed in successfully.
//         const user = result.user;
//         message.success("Valid OTP");
//         // ...
//       })
//       .catch((error) => {
//         // User couldn't sign in (bad verification code?)
//         // ...
//         message.error("Invalid OTP");
//       });
//   };
//   const changeUserPass = () => {
//     if (state.newPassWord === state.rePassWord) {
//       // this.props.changePass({
//       //   empmailid: this.email.value,
//       //   emppassword: sha256(this.new_pass.value).toString(),
//       //   empoldpassword: sha256(this.old_pass.value).toString(),
//       // });
//     } else changePassMess = "Password Mismatch";
//     toggleChangePass();
//   };
//   const showChangePassToggler = () => {
//     setShowChangePass(!showChangePass);
//   };
//   const togglefieldLogin = () => {
//     setShowLoginError(!showLoginError);
//   };
//   const toggleChangePass = () => {
//     setshowChangePassError(!showChangePassError);
//   };
//   return (
//     <div>
//       <div className="container">
//         <Modal isOpen={showLoginError} toggle={togglefieldLogin}>
//           <ModalHeader toggle={togglefieldLogin}> Error </ModalHeader>
//           <ModalBody> Email or Password is Incorrect </ModalBody>
//           <ModalFooter>
//             <Button color="secondary" onClick={togglefieldLogin}>
//               OK
//             </Button>
//           </ModalFooter>
//         </Modal>
//         <Modal isOpen={showChangePassError} toggle={toggleChangePass}>
//           <ModalHeader toggle={toggleChangePass}>Information</ModalHeader>
//           <ModalBody> {changePassMess} </ModalBody>
//           <ModalFooter>
//             <Button color="secondary" onClick={toggleChangePass}>
//               OK
//             </Button>
//           </ModalFooter>
//         </Modal>
//         <Form
//           onSubmit={(e) => e.preventDefault()}
//           className="col-sm-6"
//           style={{ margin: "auto" }}
//         >
//           <Card style={{ marginTop: 120 }}>
//             <CardHeader
//               className=" bg-primary text-light text-center"
//               style={styles.cardHeader}
//             >
//               User Login
//             </CardHeader>
//             <CardBody className="card1body">
//               {/* <FormGroup row>
//                 <div id="recaptcha-verifier"></div>
//                 <div className="col-md-2" style={styles.card1Label}>
//                   <Label for="email"> Phone </Label>
//                 </div>
//                 <Col>
//                   <Input
//                     style={styles.card1Input}
//                     // type="number"
//                     name="phone"
//                     id="phone"
//                     placeholder="Please enter phone Number"
//                     onChange={onChangeHandler}
//                     value={state.phone || ""}
//                     required
//                   />
//                 </Col>
//               </FormGroup>
//               {codeSent && (
//                 <FormGroup row>
//                   <div className="col-md-2" style={styles.card1Label}>
//                     <Label for="code"> Code </Label>
//                   </div>
//                   <Col>
//                     <Input
//                       style={styles.card1Input}
//                       // type="number"
//                       name="code"
//                       id="code"
//                       placeholder="Enter Code"
//                       onChange={(e) => setCode(e.target.value)}
//                       value={code || ""}
//                       required
//                     />
//                   </Col>
//                 </FormGroup>
//               )} */}
//               <FormGroup row>
//                 <div className="col-md-2" style={styles.card1Label}>
//                   <Label for="email"> Email </Label>
//                 </div>
//                 <Col>
//                   <Input
//                     style={styles.card1Input}
//                     type="email"
//                     name="email"
//                     id="email"
//                     placeholder="example@domain.com"
//                     onChange={onChangeHandler}
//                     value={state.email || ""}
//                     required
//                   />
//                 </Col>
//               </FormGroup>
//               <FormGroup row>
//                 <div className="col-md-2" style={styles.card1Label}>
//                   <Label for="pass"> Password </Label>
//                 </div>
//                 <Col>
//                   <Input
//                     style={styles.card1Input}
//                     type="password"
//                     name="password"
//                     id="pass"
//                     placeholder="Password"
//                     onChange={onChangeHandler}
//                     value={state.password || ""}
//                     required
//                   />
//                 </Col>
//               </FormGroup>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-around",
//                 }}
//               >
//                 {/* <Button
//                   style={{ marginLeft: 1, marginTop: 20 }}
//                   className="ml-5 btn btn-primary"
//                   color="primary"
//                   value="submit"
//                   onClick={showChangePassToggler}
//                 >
//                 Change Password
//               </Button> */}
//                 <Link to="/forgot-password/user">Forgot Password</Link>
//                 {codeSent ? (
//                   <Button
//                     style={{ marginTop: 20, margin: "auto" }}
//                     color="primary"
//                     type="submit"
//                     value="submit"
//                     onClick={handleCode}
//                     id="sign-in-button"
//                   >
//                     {loader ? (
//                       <span>
//                         <Spinner size="sm" className="mx-2"></Spinner>Loggin
//                         in...
//                       </span>
//                     ) : (
//                       "Submit"
//                     )}
//                   </Button>
//                 ) : (
//                   <Button
//                     style={{ marginTop: 20, margin: "auto" }}
//                     color="primary"
//                     type="submit"
//                     value="submit"
//                     onClick={handleLogin}
//                     id="sign-in-button"
//                   >
//                     {loader ? (
//                       <span>
//                         <Spinner size="sm" className="mx-2"></Spinner>Loggin
//                         in...
//                       </span>
//                     ) : (
//                       "Login"
//                     )}
//                   </Button>
//                 )}
//                 <Link to="/user-signup">Register</Link>
//               </div>
//             </CardBody>
//           </Card>
//         </Form>
//         <Modal isOpen={showChangePass} toggle={showChangePassToggler}>
//           <ModalHeader toggle={showChangePassToggler}>
//             Change Password
//           </ModalHeader>
//           <ModalBody>
//             <Form>
//               <FormGroup row>
//                 <div className="col-md-5 mt-4" style={styles.card2Label}>
//                   <Label for="old-pass"> Old Password </Label>
//                 </div>
//                 <Col xs={7} md={6}>
//                   <Input
//                     style={styles.card2Input}
//                     className="mt-4"
//                     type="text"
//                     name="oldPassword"
//                     id="old-pass"
//                     placeholder="Old Password"
//                     onChange={onChangeHandler}
//                     value={state.oldPassword}
//                   />
//                 </Col>
//               </FormGroup>
//               <FormGroup row>
//                 <div className="col-md-5" style={styles.card2Label}>
//                   <Label for="re-pass"> New Password </Label>
//                 </div>
//                 <Col xs={7} md={6}>
//                   <Input
//                     style={styles.card2Input}
//                     type="text"
//                     name="newPassword"
//                     id="new-pass"
//                     onChange={onChangeHandler}
//                     value={state.newPassword}
//                     placeholder="New Password"
//                     minLength="8"
//                     required
//                     pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/"
//                     title="password must be 8 to 15 characters long including at least one lowercase letter, one uppercase letter, one numeric digit and one special character"
//                   />
//                 </Col>
//               </FormGroup>
//               <FormGroup row>
//                 <div className="col-md-5" style={styles.card2Label}>
//                   <Label for="re-pass"> Retype New Password </Label>
//                 </div>
//                 <Col xs={7} md={6}>
//                   <Input
//                     style={styles.card2Input}
//                     type="text"
//                     name="rePassword"
//                     id="re-pass"
//                     onChange={onChangeHandler}
//                     value={state.rePassword}
//                     placeholder="Retype New Password"
//                   />
//                 </Col>
//               </FormGroup>
//               <Button
//                 color="primary"
//                 className="ml-4 mb-4"
//                 onClick={changeUserPass}
//               >
//                 Save
//               </Button>
//             </Form>
//           </ModalBody>
//         </Modal>
//       </div>
//     </div>
//   );
// }

// const mapStateToProps = (state) => {
//   return {
//     partnerData: state.partnerData,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
//     setPartnerRoles: (obj) => dispatch(setRolesList(obj)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
