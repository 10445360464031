import React, { Component } from "react";
import { Carousel } from "antd";
import "../assets/styles/aboutStyles.scss";
import img1 from "../assets/images/aboutCar/carousel2.jpeg";
import img2 from "../assets/images/aboutCar/carousel3.jpeg";
import img3 from "../assets/images/aboutCar/carousel4.jpeg";
import img4 from "../assets/images/aboutCar/carousel5.jpeg";
import img5 from "../assets/images/aboutCar/carousel6.jpeg";
import img6 from "../assets/images/aboutCar/carousel7.jpeg";
import img7 from "../assets/images/aboutCar/carousel8.jpeg";
import img8 from "../assets/images/aboutCar/carousel9.jpeg";
import img9 from "../assets/images/aboutCar/carousel1.jpeg";
import img10 from "../assets/images/aboutCar/carousel10.png";

const images = [
  { src: img1, alt: "alt-img-1" },
  { src: img2, alt: "alt-img-2" },
  { src: img3, alt: "alt-img-3" },
  { src: img4, alt: "alt-img-4" },
  { src: img5, alt: "alt-img-5" },
  { src: img6, alt: "alt-img-6" },
  { src: img7, alt: "alt-img-7" },
  { src: img8, alt: "alt-img-8" },
  { src: img9, alt: "alt-img-9" },
  { src: img10, alt: "alt-img-10" },
];

class About extends Component {
  render() {
    return (
      <div className="row mr-0" style={{ alignItems: "center" }}>
        <div className="col-sm-6 p-4">
          <div
            style={{
              width: "100%",
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <Carousel autoplay={true}>
              {images.map((img, i) => (
                <div
                  key={i + Math.random()}
                  style={{
                    width: "100%",
                  }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={img.src}
                    alt={img.alt}
                  ></img>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="col-sm-6 p-3 " style={{ textAlign: "justify" }}>
          <h3 className="top-heading text-center">About Us</h3>
          <p className="normal p-2">
            Our vision is to deliver accessible, quality and affordable
            comprehensive primary healthcare, through a chain of Jan Elaaj
            powered health clinics and services, promoting early detection and
            preventive screening.
          </p>
          <p className="normal p-2">
            To implement this vision, Jan Elaaj has created ‘An organised,
            encrypted AI based, predictive and affordable framework that
            empowers users with sufficient and reliable information on trending
            and relevant healthcare topics . It also diagnoses mental health and
            general health risks using cutting edge technology’.
          </p>
          <p className="normal p-2">
            The framework also provides an end-to-end platform that connects the
            patients with Doctors, Psychologists, Radiology centres etc and
            follows the entire life cycle of the patient consultation process.
          </p>
          {/* <h4
                className="about-heading"
                style={{ ...styles.heading2, ...styles.text }}
              >
                Inspiration
              </h4>
              <CardText style={{ ...styles.text, ...styles.headText }}>
                Late Dr. M K Bhan: Padma Bhusan, Former Secretary DBT
              </CardText>
              <h4
                className="about-heading"
                style={{ ...styles.heading2, ...styles.text }}
              >
                Advisory Group:
              </h4>
              <CardText style={{ ...styles.text, ...styles.headText }}>
                Meenakshi Datta Ghosh IAS, Former Secy GoI, Spl Secy Health
                <br></br>
                Prof S K Kacker (Former Director AIIMS, Delhi), ENT
                Hospital,Delhi
                <br></br>
                Dr Hamrah Siddiqui, MBBS, MS (OBG) , Viola Hospital,Delhi
                <br></br>
                Dr Raja Dutta, MBBS, MHA, MDP, Hospital Operations
              </CardText>
              <h4
                className="about-heading"
                style={{ ...styles.heading2, ...styles.text }}
              >
                Co-Founders:
              </h4>
              <CardText style={{ ...styles.text, ...styles.headText }}>
                Asif Khair: Ericsson, Hewlett Packard,Indian Oil Corporation
                <br></br>
                Sanjay Rajak: Ericsson, BSNL
              </CardText>
              <h4
                className="about-heading"
                style={{ ...styles.heading2, ...styles.text }}
              >
                Incubation, Investment & Support:
              </h4>
              <CardText style={{ ...styles.text, ...styles.headText }}>
                KIIT-TBI Bhubaneswar,<br></br> L-Incubator, IIM Lucknow,
                <br></br> Noida Campus Ministry of Electronics & IT (MeitY, Govt
                of India) - TIDE<br></br> Govt of Uttar Pradesh (UPITPOLICY)
                <br></br> Google (Digital Infrastructure)
                <br></br>Indian Institute of Technology Guwahati (IIT Guwahati)
              </CardText> */}
          {/* <h4
                className="about-heading"
                style={{ ...styles.heading2, ...styles.text }}
              >
                Investors:
              </h4> */}
          {/* <div className="incubators_container">
                <img src={kiit_tbi} alt="KIIT TBI" style={{ width: "7%" }} />
                <img
                  src="/images/about/iim.png"
                  alt="IIM Lucknow"
                  style={{ width: "7%" }}
                />
                <img src={tides} alt="TIDES" style={{ width: "11%" }} />
                <img src={govt_up} alt="UP Govt" style={{ width: "7%" }} />
                <img
                  src={iit_guwahati}
                  alt="IIT GUWAHATI"
                  style={{ width: "7%" }}
                /> */}
          {/* <img
                    src="/images/about/hpcl.png"
                    alt="HPCL"
                    style={{ width: 58 }}
                  />
                  &nbsp; &nbsp; &nbsp; &nbsp; */}
          {/* <img
                  src={cloud}
                  alt="Google_cloud"
                  style={{ width: "6.8%" }}
                  className="bg-light p-2"
                />
                <img
                  src={cfs_white}
                  alt="Google for startups"
                  style={{ width: "20%" }}
                  className="bg-dark p-3"
                />
              </div> */}
        </div>
      </div>
    );
  }
}

export default About;
