import React from "react";
import GaugeChart from "react-gauge-chart";
const Healthmeter = (props) => {
  const parameters = [
    {
      key: "Haemoglobin",
      title: "Haemoglobin",
      min: 0,
      max: 40,
      unit: "g/dl",
    },
    {
      key: "Heart_Rate",
      title: "Heart Rate",
      min: 40,
      max: 200,
      unit: "beats/min",
    },
    { key: "SPO2", title: "SPO2", min: 0, max: 100, unit: "%" },
  ];
  const getPercent = (i, min, max) => {
    let p = (i - min) / (max - min);
    return p.toFixed(2);
  };
  return (
    <div>
      {parameters?.map((hKey, i) => (
        <div
          key={i + Math.random()}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <GaugeChart
            id="gauge-chart2"
            nrOfLevels={30}
            percent={getPercent(props.data[hKey.key], hKey.min, hKey.max)}
            formatTextValue={() => ""}
            textColor="#1f1f1f"
            colors={
              hKey.key == "SPO2"
                ? ["#FFC371", "#336600"]
                : ["#336600", "#FFC371"]
            }
          />
          <p style={{ fontSize: "1.5rem" }}>
            {hKey.title + " - " + props.data[hKey.key] + " " + hKey.unit}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Healthmeter;
