import React from "react";
import govt_up from "../assets/images/govt_up.png";
// import iit_guwahati from "../assets/images/iit_guwahati.png";
import kiit_tbi from "../assets/images/kiit_tbi.png";
import tides from "../assets/images/tides.png";
// import cloud from "../assets/images/cloud-logo.png";
import cfs_white from "../assets/images/cloudforstartups_white.png";
import iim_luck from "../assets/images/iim_lucknow.png";
import { Card } from "antd";
import { isBrowser } from "react-device-detect";
const IncubatersList = () => {
  const list = [
    { src: govt_up, alt: "Up govt" },
    // { src: iit_guwahati, alt: "iit guwahati" },
    { src: kiit_tbi, alt: "kiit tbi" },
    { src: iim_luck, alt: "IIM Lucknow" },
    { src: tides, alt: "tides" },
    // { src: cloud, alt: "cloud" },
    { src: cfs_white, alt: "cfs" },
  ];
  return (
    <Card
      title={
        <div className="section-heading">
          Incubation, Investment and Support
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
        className="row mx-0 p-0 "
      >
        {list.map((item, i) => (
          <div
            key={i + Math.random()}
            className="col-sm-1 my-4"
            style={isBrowser ? { maxWidth: "120px" } : { maxWidth: "190px" }}
          >
            <img
              src={item.src}
              alt={item.alt}
              style={{
                width: "100%",
                transform: `scale(${
                  i === 0 || i === 3 || i === 4 ? 1.75 : i === 5 ? 2 : 1
                })`,
              }}
              className={`${i === 4 ? "bg-dark p-2" : ""}`}
            ></img>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default IncubatersList;
