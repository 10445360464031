import React, { useEffect, useState } from "react";
import { Card, message, Empty } from "antd";
import axios from "axios";
import { apis } from "../shared/apis";
import TodaysSpecialCard from "./TodaysSpecialCard";

const TodaysSpecial = (props) => {
  // const [loader, setLoader] = useState(false)
  // const [tdsCardList, settdsCardList] = useState([])

  // const formatList = (info, info2) => {
  //   let list = {}
  //   info?.forEach(inf => {
  //     //   list[inf?.ts_option_id] = {};
  //     list[inf?.ts_option_id] = { ...inf }
  //   })
  //   info2?.forEach(inf => {
  //     //   let obj = { [inf?.ts_option_id]: { ...inf } };
  //     //   list.push(obj);
  //     list[inf?.ts_option_id] = { ...list[inf?.ts_option_id], ...inf }
  //   })
  //   return Object.values(list)
  // }
  // const fetchFS = async () => {
  //   setLoader(true)
  //   await axios({ ...apis.getDoctorsForFirstScreen })
  //     .then((res, err) => {
  //       setLoader(false)
  //       if (err) {
  //         message.error('Something Went Wrong')
  //       } else {
  //         // console.log("res  ts: ", res.data);
  //         settdsCardList([...formatList(res.data?.info, res.data?.info2)])
  //       }
  //     })
  //     .catch(err => {
  //       setLoader(false)
  //       if (err) {
  //         message.error('Something Went Wrong')
  //       }
  //     })
  // }

  // useEffect(() => {
  //   if (tdsCardList?.length === 0) {
  //     // console.log("tdsCardList?.length : ", tdsCardList?.length);
  //     fetchFS()
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div key="TDS section Card">
      <Card
        hoverable
        title={<div className="text-left section-heading">Today Special :</div>}
        loading={props?.loader}
      >
        <div
          className="row mx-0 ts-cards-container"
          style={{
            display: "flex",
            alignItems: "stretch",
            flexWrap: "nowrap",

            paddingBottom: "2rem",
          }}
        >
          {props?.tdsCardList?.length ? (
            props?.tdsCardList.map((tsCard, i) => (
              <div
                key={i * Math.random() + i}
                style={{
                  flexBasis: "100%",
                  minWidth: "250px",
                  maxWidth: "300px",
                  margin: "0px 0.5rem ",
                }}
                className="col-3"
              >
                <TodaysSpecialCard {...tsCard} i={i}></TodaysSpecialCard>
              </div>
            ))
          ) : (
            <Empty
              description="Todays Special is Empty Today"
              style={{ width: "100%" }}
            ></Empty>
          )}
        </div>
      </Card>
    </div>
  );
};

export default React.memo(TodaysSpecial);
