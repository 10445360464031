import React from "react";
import EmpProfile from "./EmpProfile";

const SubDealerDeatails = (props) => {
  const str = props.sub.id[0] + props.sub.id[1];
  const role =
    str === "DB"
      ? "DISTRIBUTER"
      : str === "DL"
      ? "dealer"
      : str === "SD"
      ? "sub-dealer"
      : "unknown";
  return (
    <div>
      <EmpProfile
        empid={props.sub.id}
        // role={props.role === "DISTRIBUTER" ? "dealer" : "sub-dealer"}
        role={role}
        // role={props.sub.id?.includes("DB")? "DISTRIBUTER" ? "dealer" : "sub-dealer"}
      ></EmpProfile>
    </div>
  );
};

export default SubDealerDeatails;
