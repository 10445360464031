import React from "react";
import { Card, Image } from "antd";
const SymptomCard = ({ symptom_image_url, symptom_caption }) => {
  return (
    <Card hoverable bodyStyle={{ padding: "0.25rem" }}>
      <div
        className="m-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5rem",
          width: "100%",
        }}
      >
        <Image
          style={{
            width: "100%",
            minHeight: "100px",
            maxHeight: "250px",
            backgroundColor: "#cccccc",
          }}
          // src={"data:image/jpeg;base64," + photo}
          src={symptom_image_url}
          // fallback={docCard}
          alt={"Symptoms Image"}
        ></Image>
      </div>
      <div className="m-2 p-1">
        <div>
          <label>Caption:</label>
        </div>
        <div className="text-primary">
          {symptom_caption || "No Caption Provided"}
        </div>
      </div>
    </Card>
  );
};

export default SymptomCard;
