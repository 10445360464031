import React, { useState } from "react";
import { Button, Card, Image, Input, message } from "antd";
import docCard from "../../assets/images/doc_card.png";
import axios from "axios";
import { apis } from "../../shared/apis";
const UserSymptomCard = (props) => {
  const [caption, setCaption] = useState(props?.symptom_caption);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSaving, setIsSaving] = useState({
    text: "Save",
    color: "primary",
    loading: false,
  });
  const deleteHandler = async () => {
    let input = {
      pdla_id: props?.pdlaid, // appointmetn id
      data: [
        // data of image to be deleted
        {
          symptom_image_url: props?.symptom_image_url || "url",
          symptom_caption: props?.symptom_caption || "caption",
          symptom_file_name: props?.symptom_file_name || "filenawe",
          symptom_id: props?.symptom_id || "id",
          // symptom_id: -1,
          id: props?.symptom_id,
          symptom_media_type: 0,
        },
      ],
    };
    setIsDeleting(true);
    await axios({ ...apis.deletePdlaSymptoms, data: input })
      .then((res, err) => {
        setIsDeleting(false);
        if (err) message.error("Something Went Wrong!");
        else if (res.data.status === "SUCCESS") {
          message.success("Successfully Deleted");
          props.popSymptom(props?.index);
          setIsDeleted(true);
        } else if (res.data.status === "FAILED") {
          message.error("Deletion Failed");
        }
        // console.log("Res of dele: ", res.data);
      })
      .catch((err) => {
        setIsDeleting(false);
        if (err) message.error("Something Went Wrong!");
      });
  };

  const updateDB = async () => {
    setIsSaving({ text: "Saving...", color: "primary", loading: true });
    let data = {
      symptom_image_url: props?.symptom_image_url,
      symptom_caption: caption,
      symptom_file_name: props?.symptom_file_name,
      symptom_id: props?.symptom_id,
      id: props?.symptom_id,
      symptom_media_type: 0,
    };
    props?.changeSymptomHandler(props?.index, data);

    await props?.uploadSingleSymtomsToDB(data);
    //   .then(() => {
    // setIsSaving({ text: "Saved", color: "success", loading: false });
    // });
  };

  if (isDeleted) return null;
  return (
    <Card
      // key={props?.symptom_id + Math.random()}
      hoverable
      bodyStyle={{ padding: "0.25rem" }}
    >
      <div
        className="m-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0.5rem",
          width: "100%",
        }}
      >
        <Image
          // style={{ width: "200px", height: "200px" }}
          style={{
            width: "100%",
            minHeight: "100px",
            maxHeight: "250px",

            backgroundColor: "#cccccc",
          }}
          src={props?.symptom_image_url}
          fallback={docCard}
          alt={"Symptoms Image"}
        ></Image>
      </div>
      <div
        className="m-2 p-1"
        style={
          {
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
          }
        }
      >
        <div>
          <label>Caption:</label>
        </div>
        <div>
          <Input
            value={caption}
            placeholder="Update Caption Here"
            onChange={(e) => {
              setCaption(e.target.value);
              // if (isSaving.text !== "Save")
              //   setIsSaving({ text: "Save", color: "primary", loading: false });
            }}
            onBlur={updateDB}
            // addonAfter={props?.loading ? "Saving..." : null}
            // loading={true}
            suffix={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  type="link"
                  className={`text-${isSaving.color}`}
                  // loading={isSaving.loading}
                >
                  {isSaving.text}
                </Button>
              </div>
            }
          ></Input>
        </div>
      </div>
      <div
        className="m-1 p-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          className="m-1"
          type="danger"
          onClick={deleteHandler}
          loading={isDeleting}
        >
          Delete
        </Button>
        {/* <Button className="m-1" type="primary">
          Save
        </Button> */}
      </div>
    </Card>
  );
};

export default UserSymptomCard;
