import React from "react";
import GoogleMapReact from "google-map-react";
import { EnvironmentOutlined } from "@ant-design/icons";
import "../../assets/styles/map.css";

const Map = ({ location, zoomLevel }) => {
  return (
    <div className="map">
      {/* <h2 className="map-h2">Come Visit Us At Our Campus</h2> */}

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCd9j-eHGHYMvjNakncA3kyQrjbQfnGoUY" }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

const LocationPin = ({ text }) => (
  <div className="pin">
    {/* <Icon icon={locationIcon} className="pin-icon" /> */}
    <EnvironmentOutlined className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);
export default Map;
