import React from "react";
import { Result, Button } from "antd";
import PaymentSuccessCard from "./PaymentSuccessCard";
const PaymentSuccessPage = (props) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Result
        status="success"
        title="Transaction Successfull."
        subTitle="Thank You for using Jan Elaaj Services."
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => props.history.push("/dashboard")}
          >
            Go To Dashboard
          </Button>,
          //   <Button key="buy">Buy Again</Button>,
        ]}
      />
      <PaymentSuccessCard
        data={JSON.parse(localStorage.getItem("invoice"))}
      ></PaymentSuccessCard>
    </div>
  );
};

export default PaymentSuccessPage;
