import React, { useEffect, useState } from "react";
import { Card, Empty, message } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";
import SubDealerCard from "./SubDealerCard";

const SubDealerList = (props) => {
  const [loader, setLoader] = useState(true);
  const [subDealers, setSubDealers] = useState([]);
  useEffect(() => {
    (async function () {
      const input = {
        id: props?.empid,
        subject: props?.subject,
      };
      setLoader(true);
      await axios({
        ...apis.getDealerSubdealerList,
        data: input,
      })
        .then((res, err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          } else {
            setSubDealers(res.data.data);
            // console.log('Res.data: ', res.data)
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.shouldUpdate]);
  return (
    <div>
      <Card loading={loader} bodyStyle={{ padding: "0" }} bordered={false}>
        {subDealers.length ? (
          subDealers.map((sub, i) => {
            return (
              <div key={i + Math.random()} className="mb-3">
                <SubDealerCard {...props} sub={sub}></SubDealerCard>
              </div>
            );
          })
        ) : (
          <Empty description={` No ${props?.subject}s Found`}></Empty>
        )}
      </Card>
    </div>
  );
};

export default SubDealerList;
