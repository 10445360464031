import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import ImageZoom from "react-medium-image-zoom";
import "../../assets/styles/DoctorVerification.css";
import { styles } from "../../assets/styles/DoctorVerification";
import AnchorLink from "react-anchor-link-smooth-scroll";

class DoctorVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyFields: false,
      verifyDoc: false,
      loc: null,
      name: this.props.docinfo.docnameverifyflag,
      dob: this.props.docinfo.docdobverifyflag,
      add: this.props.docinfo.docaddressverifyflag,
      id: this.props.docinfo.docidentityproofverifyflag,
      ed: this.props.docinfo.doceducationverifyflag,
      exp: this.props.docinfo.docexperienceverifyflag,
      mr: this.props.docinfo.docregistrationnumberverifyflag,
      mc: this.props.docinfo.docregistrationcouncilverifyflag,
      verify: this.props.docinfo.docverificationflag,
      comment: this.props.docinfo.docverificationcomments,
      docrole: "Select a role",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.onRadioInputChange = this.onRadioInputChange.bind(this);
    this.saveDocInfo = this.saveDocInfo.bind(this);
    this.toggleDocModal = this.toggleDocModal.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
  }
  toggleDocModal() {
    this.setState({ verifyDoc: !this.state.verifyDoc });
  }
  handleSearch(event) {
    let searchTerm = this.email.value || this.number.value;
    this.props.fetchRoles({ email: searchTerm });
    this.props.onSearch(searchTerm);
    event.preventDefault();
  }
  saveDocInfo() {
    this.props.postDocInfo({
      docnameverifyflag: this.state.name,
      docdobverifyflag: this.state.dob,
      docaddressverifyflag: this.state.add,
      doceducationverifyflag: this.state.ed,
      docexperienceverifyflag: this.state.exp,
      docregistrationnumberverifyflag: this.state.mr,
      docregistrationcouncilverifyflag: this.state.mc,
      docidentityproofverifyflag: this.state.id,
      docverificationflag: this.state.verify,
      docverificationcomments: this.state.comments,
      docid: this.props.docinfo.docid,
    });
    this.toggleDocModal();
    this.setState({ verifyFields: !this.state.verifyFields });
  }
  fetchLocations() {
    this.props.fetchLocInfo({ docid: this.props.docinfo.docid });
    this.props.onClick();
  }
  onRadioInputChange(event) {
    this.setState({ verifyFields: true });
    if (event.target.name === "doc-name") {
      this.setState({ name: event.target.value });
      this.props.docinfo.docnameverifyflag = null;
    } else if (event.target.name === "doc-dob") {
      this.setState({ dob: event.target.value });
      this.props.docinfo.docdobverifyflag = null;
    } else if (event.target.name === "doc-add") {
      this.setState({ add: event.target.value });
      this.props.docinfo.docaddressverifyflag = null;
    } else if (event.target.name === "doc-id") {
      this.setState({ id: event.target.value });
      this.props.docinfo.docidentityproofverifyflag = null;
    } else if (event.target.name === "doc-ed") {
      this.setState({ ed: event.target.value });
      this.props.docinfo.doceducationverifyflag = null;
    } else if (event.target.name === "doc-exp") {
      this.setState({ exp: event.target.value });
      this.props.docinfo.docexperienceverifyflag = null;
    } else if (event.target.name === "doc-mr") {
      this.setState({ mr: event.target.value });
      this.props.docinfo.docregistrationnumberverifyflag = null;
    } else if (event.target.name === "doc-mc") {
      this.setState({ mc: event.target.value });
      this.props.docinfo.docregistrationcouncilverifyflag = null;
    } else if (event.target.name === "doc-verify") {
      this.setState({ verify: event.target.value });
      this.props.docinfo.docverificationflag = null;
    }
  }
  submitComment = (event) => {
    this.props.docinfo.docverificationcomments = null;
    // clearTimeout(this.timer);
    if (!this.state.verifyFields)
      this.setState({
        verifyFields: !this.state.verifyFields,
      });

    /*
                    this.timer = setTimeout(() => {
                        this.setState({
                            verifyFields: !this.state.verifyFields
                        });
                    }, 300);
        */

    this.setState({
      comments: event.target.value,
    });
  };

  render() {
    let docinfo = this.props.docinfo;
    let locinfo = this.props.locinfo;
    let idnumber = "";
    let id = "ID Proof";
    let cid = "Certificate Proof";
    let cidnumber = "";
    let IDImage = () => <div />;
    let CertiImage = () => <div />;
    let { verifyFields } = this.state.verifyFields;
    if (!Object.keys(docinfo).length) {
      docinfo.docname = "Name";
      docinfo.docdob = "Date of Birth";
      docinfo.docaddressline1 = "Address";
      docinfo.docaadhar = "ID Proof";
      docinfo.experience = "Experience";
      docinfo.docregistrationnumber = "Medical Registration";
      docinfo.docmedicalcouncil = "Medical Council";
    } else {
      if (docinfo.docaadhar_number) {
        idnumber = docinfo.docaadhar_number;
        id = "Aadhar";
      } else if (docinfo.docvoteridnumber) {
        idnumber = docinfo.docvoteridnumber;
        id = "Voter ID";
      } else if (docinfo.docpassportnumber) {
        idnumber = docinfo.docpassportnumber;
        id = "Passport";
      }

      if (docinfo.identityproofimage)
        IDImage = () => (
          <ImageZoom
            image={{
              src: `data:image/jpeg;base64,${docinfo.identityproofimage}`,
              alt: "ID Proof",
              style: { width: "60px", marginLeft: "30px" },
            }}
            zoomImage={{
              src: `data:image/jpeg;base64,${docinfo.identityproofimage}`,
              alt: "ID Proof",
            }}
          />
        );
      if (docinfo.regcertificateimage)
        CertiImage = () => (
          <ImageZoom
            image={{
              src: `data:image/jpeg;base64,${docinfo.regcertificateimage}`,
              alt: "Certi Proof",
              style: { width: "60px", marginLeft: "30px" },
            }}
            zoomImage={{
              src: `data:image/jpeg;base64,${docinfo.regcertificateimage}`,
              alt: "Certi Proof",
            }}
          />
        );
    }

    let info = Object.assign({}, locinfo.locations);
    let locs = Object.values(info);
    const RenderLocations = locs.map((loc) => (
      <FormGroup row>
        <Col>
          <CustomInput
            id={loc.lid}
            type="radio"
            name="loc"
            label={loc.lname}
            style={{ marginBottom: 30 }}
            onChange={() => this.setState({ loc: loc })}
            value={loc}
          />
        </Col>
        <div className="col-3">
          <Label> Is Live </Label>
          {loc.llocliveflag === "Y" ? (
            <i
              className="fas fa-check-double ml-3"
              style={{ color: "green" }}
            />
          ) : (
            <i className="fas fa-times ml-3" style={{ color: "#ab3228" }} />
          )}
        </div>
      </FormGroup>
    ));
    const SelectRoles = () => {
      if (this.props.roles.role) {
        let roles = Object.values(Object.assign({}, this.props.roles.role));
        return (
          <FormGroup row>
            <div className="col-md-4" style={styles.card1Label}>
              <Label for="select"> Select a Role </Label>
            </div>
            <Col xs={7} md={3}>
              <Input
                style={styles.card1Input}
                type="select"
                name="select"
                id="select"
                defaultValue={this.props.role}
                onChange={(event) =>
                  this.props.onChangeRole(event.target.value)
                }
              >
                <option> Select a role </option>
                {roles.map((role) => (
                  <option value={role.role}> {role.role} </option>
                ))}
              </Input>
            </Col>
            <Col md={3}>
              <Button
                color="info"
                type="submit"
                value="submit"
                className="mt-sm-4 mt-md-0"
                onClick={() => {
                  this.props.fetchDocInfo({
                    docemailormobile: this.props.email,
                    role: this.props.role,
                  });
                }}
              >
                Get Information
              </Button>
            </Col>
          </FormGroup>
        );
      } else return <div />;
    };

    return (
      <div className="container">
        <Modal isOpen={this.state.verifyDoc} toggle={this.toggleDocModal}>
          <ModalHeader toggle={this.toggleDocModal}> Information </ModalHeader>
          <ModalBody> Your response has been recorded. Thank You. </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleDocModal}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.props.isLocModalOpen} toggle={this.props.onClick}>
          <ModalHeader toggle={this.props.onClick}>
            Choose a Location
          </ModalHeader>
          <ModalBody>
            <Form> {RenderLocations} </Form>
          </ModalBody>
          <ModalFooter>
            <Link
              to={{
                pathname: "/verifyLocation/",
                state: { loc: this.state.loc },
              }}
              style={{ textDecoration: "none" }}
              onClick={this.props.onClick}
            >
              <Button style={{ color: "white" }} color="warning">
                Verify
              </Button>
            </Link>
            <Button color="secondary" onClick={this.props.onClick}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Card style={{ marginTop: 120 }}>
          <section id="doc-verify">
            <CardHeader className="head-xl" style={styles.cardHeader}>
              Doctor Verification
            </CardHeader>
          </section>
          <CardBody className="card1body">
            <Form>
              <FormGroup row>
                <div className="col-xs-12 col-lg-3" style={styles.adjust}>
                  <Label for="email"> Email </Label>
                </div>
                <Col xs={10} md={4} lg={3}>
                  <Input
                    style={styles.card1Input}
                    type="text"
                    name="email"
                    id="email"
                    placeholder="example@domain.com"
                    innerRef={(input) => (this.email = input)}
                    defaultValue={this.props.email}
                  />
                </Col>
                <div className="col-xs-12 col-lg-3" style={styles.adjust}>
                  <Label className="card1label" for="number">
                    Mobile
                  </Label>
                </div>
                <Col xs={10} md={4} lg={3}>
                  <Input
                    style={styles.card1Input}
                    type="text"
                    name="number"
                    id="number"
                    placeholder="Mobile Number"
                    innerRef={(input) => (this.number = input)}
                  />
                </Col>
                <Col md={12} lg={2}>
                  <Button
                    color="info"
                    type="submit"
                    value="submit"
                    className="mt-sm-5 mt-lg-0 ml-0 ml-lg-2"
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>
                </Col>
              </FormGroup>
              <SelectRoles />
            </Form>
          </CardBody>
        </Card>
        <Form>
          <Card>
            <CardBody className="card2body">
              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Name </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={docinfo.docname}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-name"
                      checked={
                        docinfo.docnameverifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-name"
                      checked={
                        docinfo.docnameverifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-name"
                      checked={
                        docinfo.docnameverifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="dob"> DOB </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="dob"
                    id="dob"
                    placeholder="Date of Birth"
                    value={docinfo.docdob}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-dob"
                      id="dob-1"
                      checked={docinfo.docdobverifyflag === "1" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value="1"
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-dob"
                      checked={docinfo.docdobverifyflag === "2" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value="2"
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-dob"
                      id="dob-3"
                      checked={docinfo.docdobverifyflag === "3" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value="3"
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="address"> Address </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={docinfo.docaddressline1}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-add"
                      checked={
                        docinfo.docaddressverifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-add"
                      checked={
                        docinfo.docaddressverifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-add"
                      checked={
                        docinfo.docaddressverifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="id"> ID Proof </Label>
                </div>
                <Col md={12} lg={3}>
                  <Label xs={12} lg={12} style={{ paddingLeft: 30 }}>
                    {id} {idnumber}
                  </Label>
                  <IDImage />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-id"
                      checked={
                        docinfo.docidentityproofverifyflag === "1" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-id"
                      checked={
                        docinfo.docidentityproofverifyflag === "2" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-id"
                      checked={
                        docinfo.docidentityproofverifyflag === "3" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="id"> Certificate Proof </Label>
                </div>
                <Col md={12} lg={3}>
                  <Label xs={12} lg={12} style={{ paddingLeft: 30 }}>
                    {cid} {cidnumber}
                  </Label>
                  <CertiImage />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-id"
                      checked={
                        docinfo.docidentityproofverifyflag === "1" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-id"
                      checked={
                        docinfo.docidentityproofverifyflag === "2" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-id"
                      checked={
                        docinfo.docidentityproofverifyflag === "3" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Education </Label>
                </div>
                <Col xs={7} md={7} lg={3} style={{ paddingLeft: 30 }}>
                  <Label md={5} check>
                    <Input
                      type="checkbox"
                      name="info-verify"
                      checked={docinfo.mbbs === "Y"}
                    />
                    MBBS
                  </Label>
                  <Label md={4} check>
                    <Input
                      type="checkbox"
                      name="info-verify"
                      checked={docinfo.md === "Y"}
                    />
                    MD
                  </Label>
                  <Label md={4}>
                    <Input
                      type="checkbox"
                      name="info-verify"
                      checked={docinfo.ms === "Y"}
                    />
                    MS
                  </Label>
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-ed"
                      checked={
                        docinfo.doceducationverifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-ed"
                      checked={
                        docinfo.doceducationverifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-ed"
                      checked={
                        docinfo.doceducationverifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Experience </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="experience"
                    id="experience"
                    placeholder="Experience"
                    value={docinfo.experience}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-exp"
                      checked={
                        docinfo.docexperienceverifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-exp"
                      checked={
                        docinfo.docexperienceverifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-exp"
                      checked={
                        docinfo.docexperienceverifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="registration"> Medical Registration </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="registration"
                    id="registration"
                    placeholder="Medical Registration"
                    value={docinfo.docregistrationnumber}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-mr"
                      checked={
                        docinfo.docregistrationnumberverifyflag === "1" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-mr"
                      checked={
                        docinfo.docregistrationnumberverifyflag === "2" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-mr"
                      checked={
                        docinfo.docregistrationnumberverifyflag === "3" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="council"> Medical Council </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="council"
                    id="council"
                    placeholder="Medical Council"
                    value={docinfo.docmedicalcouncil}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="doc-mc"
                      checked={
                        docinfo.docregistrationcouncilverifyflag === "1" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="doc-mc"
                      checked={
                        docinfo.docregistrationcouncilverifyflag === "2" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="doc-mc"
                      checked={
                        docinfo.docregistrationcouncilverifyflag === "3" ||
                        verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="card3body">
              <FormGroup row>
                <Col md={12}>
                  <Label md={4}> Doctor Verified </Label>
                  <Label md={2}>
                    <Input
                      type="radio"
                      name="doc-verify"
                      checked={
                        docinfo.docverificationflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={2}>
                    <Input
                      type="radio"
                      name="doc-verify"
                      checked={
                        docinfo.docverificationflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4}>
                    <Input
                      type="radio"
                      name="doc-verify"
                      checked={
                        docinfo.docverificationflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Waiting for more info
                  </Label>
                </Col>
                <Col xs={8} md={5}>
                  <Input
                    style={styles.card3Input}
                    type="text"
                    name="comments"
                    id="comments"
                    placeholder="Comments"
                    value={docinfo.docverificationcomments}
                    onChange={this.submitComment.bind(this)}
                  />
                </Col>
              </FormGroup>
              {/*
                        <FormGroup row>
                            <Label md={5} check style={{ marginLeft: 20 }} > Is Doctor Live
                                {docinfo.readyliveflag === "Y" ? <i className="fas fa-check ml-2" style={{ color: 'green' }} /> : <i className="fas fa-times ml-2" style={{ color: '#ab3228' }} /> }
                                <Input style={{ marginLeft: 20 }} type="checkbox" name="info-verify" checked={docinfo.readyliveflag === "Y"} />
                            </Label>
                            <div className="col-md-2" style={styles.card2Label}> <Label for="live-date"> Live Date </Label> </div>
                            <Col xs={8} md={2} >
                                <Input disabled style={styles.card2Input} type="text" name="live-date" id="live-date"
                                       placeholder="Live Date" value={
                                           docinfo.readyliveflagdate ? moment(docinfo.readyliveflagdate).format("DD-MMM-YYYY") : null
                                } />
                            </Col>
                        </FormGroup>
*/}
            </CardBody>
          </Card>
          <AnchorLink href="#doc-verify" className="unstyled-link">
            <Button
              color="danger"
              style={{ marginTop: 20 }}
              onClick={() => this.props.onSearch(null)}
            >
              Cancel
            </Button>
          </AnchorLink>
          <Button
            disabled={!this.state.verifyFields}
            color="primary"
            style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            onClick={this.saveDocInfo}
          >
            Save
          </Button>
          <Button
            color="success"
            className="ml-1 mt-4"
            style={{ marginLeft: 20, marginTop: 20 }}
            onClick={this.fetchLocations}
          >
            Verify Location
          </Button>
          {/* <Link to={"/goLiveVerification/"}> */}
          <Button
            color="warning"
            className="ml-md-4"
            style={{ marginTop: 20, textDecoration: "none", color: "white" }}
            onClick={() => (window.location = "/goLiveVerification")}
          >
            Go Live
          </Button>
          {/* </Link> */}
        </Form>
      </div>
    );
  }
}

export default DoctorVerification;
