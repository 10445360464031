import React, { useState } from "react";
import { Form, Button, Input, Select, Divider, Card } from "antd";
import sideImg from "../assets/images/Surakshit.png";
const { Option } = Select;
const PMSMSForm = () => {
  //   const [loader, setLoader] = useState(false);
  //   const [showOtpForm, setShowOtpForm] = useState(false);
  const [numStatus] = useState({
    message: "Sign Up",
    isLoading: false,
    error: "",
  });
  const handleSubmit = () => {};

  return (
    <div
      className="row mx-0 pt-4 px-2"
      style={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <div
        className="col-sm-6 p-2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={sideImg} alt={"gift"} style={{ width: "100%" }}></img>
      </div>
      <div className="col-sm-4 ">
        <Card hoverable bodyStyle={{ padding: "5px 24px" }}>
          <Form onFinish={handleSubmit}>
            <Divider>Volunteer Registration</Divider>
            <div className="text-center text-secondary pb-1">
              This form is for registration of doctors from private sector/
              voluntary sector/ for retired doctors.
            </div>
            <Form.Item
              name="fullname"
              rules={[{ required: true, message: "Please Enter Your Name!" }]}
            >
              <Input size="large" placeholder="Full Name" />
            </Form.Item>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="workingIn"
                style={{ flexGrow: "3", paddingRight: "10px" }}
              >
                <Select placeholder="Working In" size="large">
                  <Option value="Private Sector"> Private Sector </Option>
                  <Option value="Voluntary Organization">
                    Voluntary Organization
                  </Option>
                  <Option value="Retired">Retired </Option>
                </Select>
              </Form.Item>
              <Form.Item name="gender" style={{ flexGrow: "4" }}>
                <Select placeholder="Gender" size="large">
                  <Option value="M">Male</Option>
                  <Option value="F">Female</Option>
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="qualification"
                style={{ flexGrow: "1", paddingRight: "10px" }}
              >
                <Select placeholder="Qualification" size="large">
                  <Option value="M">MBBS</Option>
                  <Option value="F">MD</Option>
                </Select>
              </Form.Item>
              <Form.Item name="specialization" style={{ flexGrow: "1" }}>
                <Select placeholder="Specialization" size="large">
                  <Option value="1">MD(Obstetrician/Gynecologist)</Option>
                  <Option value="2">DGO</Option>
                  <Option value="3">Radiologist</Option>
                  <Option value="4">Others</Option>
                  <Option value="5">None</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Phone Number!",
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length === 10) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Please enter valid Phone Number")
                    );
                  },
                }),
              ]}
              hasFeedback
              help={
                numStatus?.error ? (
                  <div className="text-danger">{numStatus?.error}</div>
                ) : null
              }
            >
              <Input size="large" placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please Enter Your Email!" }]}
            >
              <Input type="email" size="large" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="mic"
              rules={[{ required: true, message: "Please Enter Your MIC!" }]}
            >
              <Input
                type="text"
                size="large"
                placeholder="MCI/State Medical Council Registration Number"
              />
            </Form.Item>
            <Form.Item name="organisation" style={{ flexGrow: "1" }}>
              <Select
                placeholder="Member of any Professional Body"
                size="large"
              >
                <Option value="1">FOGSI</Option>
                <Option value="2">IMA</Option>
                <Option value="3">Rotary</Option>
                <Option value="4">Lions Club</Option>
                <Option value="5">NGO</Option>
                <Option value="6">None</Option>
                <Option value="7">Others</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                { required: true, message: "Please Enter Your Address!" },
              ]}
              //   style={{ flexGrow: "2", paddingRight: "10px" }}
            >
              <Input.TextArea size="large" placeholder="Address" />
            </Form.Item>
            <Divider>Volunteering For</Divider>

            <div
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              <Form.Item
                name="state"
                style={{ flexGrow: "1", paddingRight: "10px" }}
              >
                <Select placeholder="State" size="large">
                  <Option value="M">Rajasthan</Option>
                  <Option value="F">Delhi</Option>
                </Select>
              </Form.Item>
              <Form.Item name="district" style={{ flexGrow: "1" }}>
                <Select placeholder="District" size="large">
                  <Option value="1">Jaipur</Option>
                  <Option value="2">Udaipur</Option>
                  <Option value="3">Alwar</Option>
                  <Option value="4">Others</Option>
                  <Option value="5">None</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  //   justifyContent: "center",
                  alignItems: "center",
                  //   padding: "1rem",
                }}
              >
                <div>
                  <Button type="primary" htmlType="submit" size="large">
                    Join Now
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="col-sm-2"></div>
    </div>
  );
};

export default PMSMSForm;
