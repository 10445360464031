import React, { useState } from "react";
import {
  Button,
  Empty,
  Radio,
  Modal,
  Divider,
  Card,
  Form,
  message,
  Pagination,
  DatePicker,
} from "antd";
import { SortAscendingOutlined, FilterOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";
import DoctorCard from "./DoctorCard";
import { apis } from "../../shared/apis";
import axios from "axios";
import { isBrowser } from "react-device-detect";
import LangFilterForm from "../LangFilterForm";

const DoctorsList = (props) => {
  const pageSize = 5;

  const formatList = (arr) => {
    let len = arr.length;
    let obj = {};
    let j = 1;
    for (let i = 0; i < len; i++) {
      if (i !== 0 && i % pageSize === 0) j++;
      if (typeof obj[j] !== "undefined") obj[j] = [...obj[j], arr[i]];
      else {
        obj[j] = [];
        obj[j] = [...obj[j], arr[i]];
      }
    }
    return obj;
  };

  const [loader, setLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showLangForm, setShowLangForm] = useState(false);
  const [selectedLangList, setSelectedLangList] = useState([]);
  const [showDateForm, setShowDateForm] = useState(false);
  const [selecetdDate, setSelectedDate] = useState("");
  const [page, setPage] = useState("1");
  const [sortid, setSortId] = useState("-1");
  const [searchFilterId, setSearchFilterId] = useState("-1");
  // eslint-disable-next-line no-unused-vars
  const [docList, setDocList] = useState(
    formatList(props.userDash.doctorsList.info)
  );
  const showFormHandler = () => {
    setShowForm(true);
  };

  const getDoctorsList = async (data) => {
    setLoader(true);
    let api =
      props.selectedService.role === "VIT"
        ? apis.getVitalsBySearchLimit
        : props.userDash?.selectedService?.role === "ONL" ||
          props.userDash?.selectedService?.role === "ODIE" ||
          props.userDash?.selectedService?.role === "OPSY"
        ? apis.getonlinedoctorsbysearchlimit
        : apis.getdoctorsbysearchlimit;
    // let api = apis.getVitalsBySearchLimit;
    // let api = apis.getdoctorsbysearchlimit;
    await axios({ ...api, data: data })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        else {
          props?.setDoctorsList(res.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const selectSpeciality = () => {
    let data = {
      // specialityid: service?.id || "000",
      // service_id: service?.id || "000",
      dmrole: props.userDash?.selectedService?.role || "role",
      cityname: props.userDash?.userState?.value || "cn",
      localitylat: props.userDash?.userLocality?.llocality_lat || "000000",
      localitylong: props.userDash?.userLocality?.llocality_long || "000000",
      localityname: props.userDash?.userLocality?.llocalityname || "llocality",
      queryoffset: 0, // query off set limit
      querylimit: 50, // query upper limit
      sortid: sortid, // default sorting id
      searchfilter: searchFilterId, // default search filter
      dayshortname: selecetdDate
        ? selecetdDate?.format("ddd")?.toLowerCase() || ""
        : "", // day name
      languageList: selectedLangList,
      // ...props.userDash?.userLocality,
    };
    if (
      props.userDash?.selectedService?.role === "ONL" ||
      props.userDash?.selectedService?.role === "DOC"
    ) {
      data = { ...data, specialityid: props?.selectedSpeciality.id || "000" };
    } else {
      data = { ...data, service_id: props?.selectedSpeciality.id || "000" };
    }
    getDoctorsList({ ...data });
  };

  // useEffect(() => {
  //   setSortId(-1);
  //   setSearchFilterId(-1);
  // }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "0.25rem",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<SortAscendingOutlined style={{ display: "inline-flex" }} />}
          onClick={showFormHandler}
          className="mx-4"
          // size="large"
        >
          Sort
        </Button>
        <Button
          type="primary"
          shape="round"
          icon={<FilterOutlined style={{ display: "inline-flex" }} />}
          className="mx-4"
          // size="large"
          onClick={showFormHandler}
        >
          Filter
        </Button>
      </div>
      {props?.userDash?.doctorsList?.info?.length ? (
        <div>
          <div>
            <Card
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: isBrowser ? "row" : "column",
                    // padding: "0.25rem",
                  }}
                >
                  <div>{`Showing ${
                    (page - 1) * pageSize + docList[page]?.length <=
                    props?.userDash?.doctorsList?.info?.length
                      ? (page - 1) * pageSize + docList[page]?.length
                      : props?.userDash?.doctorsList?.info?.length
                  } out of ${
                    props?.userDash?.doctorsList?.info?.length
                  } doctors`}</div>
                </div>
              }
              loading={loader}
              size="small"
              bodyStyle={{ padding: "0.025rem" }}
              bordered={false}
            >
              <div>
                {docList[page]?.map((doc, i) => (
                  <div key={i + Math.random()}>
                    <DoctorCard {...props} doc={doc}></DoctorCard>
                  </div>
                ))}
              </div>
              <div className="paginition-container">
                <Pagination
                  pageSize={pageSize}
                  total={props?.userDash?.doctorsList?.info?.length}
                  // total={Object.keys(docList).length}
                  hideOnSinglePage={true}
                  onChange={(page) => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    // console.log("page: ", page);
                    setPage(page);
                  }}
                  showSizeChanger={false}
                  // simple={true}
                  responsive={true}
                ></Pagination>
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <Empty description={"No Doctors Available"}></Empty>
      )}
      <Modal
        visible={showForm}
        title={<div>Select Any Option</div>}
        bodyStyle={{ paddingTop: "0" }}
        onCancel={() => setShowForm(false)}
        onOk={() => {
          setPage(1);
          setShowForm(false);
          selectSpeciality();
        }}
        // destroyOnClose={true}
        centered
      >
        <div>
          <Form>
            <Divider>Sort By</Divider>
            <Form.Item name="sortid">
              <Radio.Group
                value={sortid}
                onChange={(e) => setSortId(e.target.value)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Radio value="1">High to Low</Radio>
                <Radio value="2">Low to High</Radio>
              </Radio.Group>
            </Form.Item>
            <Divider>Filter By</Divider>
            <Form.Item name="searchFilterId">
              <Radio.Group
                value={searchFilterId}
                onChange={(e) => {
                  setSearchFilterId(e.target.value);
                  if (e.target.value === "3") {
                    setShowDateForm(true);
                    setSelectedLangList([]);
                    setShowLangForm(false);
                  } else if (e.target.value === "5") {
                    setShowLangForm(true);
                    setSelectedDate("");
                    setShowDateForm(false);
                  } else {
                    setShowDateForm(false);
                    setShowLangForm(false);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Radio value="1">All</Radio>
                <Radio value="2">Discount</Radio>
                <Radio value="3" onClick={() => setShowDateForm(true)}>
                  Date
                </Radio>
                <Radio value="5" onClick={() => setShowLangForm(true)}>
                  Language
                </Radio>
              </Radio.Group>
            </Form.Item>
            {showDateForm ? (
              <Form.Item style={{ textAlign: "center" }}>
                <DatePicker
                  onChange={(e) => {
                    setSelectedDate(e);
                  }}
                  size="large"
                ></DatePicker>
              </Form.Item>
            ) : null}
          </Form>
        </div>
      </Modal>
      <Modal
        visible={showLangForm}
        title={<div>Select Languages Spoken </div>}
        centered
        onCancel={() => setShowLangForm(false)}
        footer={[]}
      >
        <LangFilterForm
          closeForm={() => {
            setShowLangForm(false);
          }}
          setSelectedLangList={setSelectedLangList}
        ></LangFilterForm>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
    selectedService: state.userDash.selectedService,
    selectedSpeciality: state.userDash.selectedSpeciality,
    specialityList: state.userDash.specialityList,
    outcomePackages: state.userDash.outcomePackages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpeciality: (data) => dispatch(dashboardAC.setSpeciality(data)),
    setDoctorsList: (list) => dispatch(dashboardAC.setDoctorsList(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList);
