import React, { useState } from 'react'
import { Button, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { apis } from '../../shared/apis'
const DownloadRecieptButton = props => {
  const [isDownloading, setIsDownloading] = useState(false)
  const input = {
    role: props?.role,
    pdla_id: props?.pdlaid
  }

  const downloadRecieptHandler = async () => {
    setIsDownloading(true)
    var data = JSON.stringify(input)

    const timeout = 200000
    var config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data
    }
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)
    await fetch(apis.getDataAndProduceInvoice.url, {
      ...config,
      signal: controller.signal
    })
      .then(res => res.blob())
      .then(data => {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `JanElaaj-inovice-${props?.pdlaid}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        setIsDownloading(false)
        message.success('Successfully downloaded invoice')
      })
      .catch(err => {
        setIsDownloading(false)
        if (JSON.stringify(err).includes('aborted')) {
          message.error('Server is taking too much time')
        } else if (err) message.error('Something Went Wrong')
        // console.log(err, JSON.stringify(err).includes("aborted"));
      })
    clearTimeout(id)
  }

  return (
    <Button
      type='primary'
      className='my-1'
      onClick={downloadRecieptHandler}
      loading={isDownloading}
      // disabled={isDisabled || !isOTPValid}
      icon={<DownloadOutlined style={{ display: 'inline-flex' }} />}
    >
      {isDownloading ? 'Downloading...' : 'Download Reciept'}
    </Button>
  )
}

export default DownloadRecieptButton
