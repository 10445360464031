import { Tabs } from "antd";
import React from "react";
import { cardHeaderStyle } from "../assets/styles/PartnerAppStyles";
import CompletedAppointmentsList from "./CompletedAppointmentsList";
import CardHolderFrame from "./containers/CardHolderFrame";
import PreviousPaymentsList from "./PreviousPaymentsList";
import RequestPaymentsList from "./RequestPaymentsList";
import UnfulfilledAppointmentsList from "./UnfulfilledAppointmentsList";

const { TabPane } = Tabs;
const PartnerReportPaymentList = (props) => {
  const selectedRole = JSON.parse(localStorage.getItem("selectedRole"));
  const selectedClinic = JSON.parse(localStorage.getItem("selectedClinic"));

  const idProps = { selectedRole, selectedClinic };
  const tabs = [
    {
      tabName: "Completed Appointments",
      content: (
        <CompletedAppointmentsList {...idProps}></CompletedAppointmentsList>
      ),
    },
    {
      tabName: "Request Payment",
      content: <RequestPaymentsList {...idProps}></RequestPaymentsList>,
    },
    {
      tabName: "Previous Payments",
      content: <PreviousPaymentsList {...idProps}></PreviousPaymentsList>,
    },
    {
      tabName: "Unfulfilled Appointments",
      content: (
        <UnfulfilledAppointmentsList {...idProps}></UnfulfilledAppointmentsList>
      ),
    },
  ];

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/choose-option",
      pathName: " Service List",
    },
    {
      path: "/reports-payments-list",
      pathName: "Reports and Payments",
    },
  ];

  return (
    <div className="container m-auto p-0">
      <CardHolderFrame
        {...props}
        headStyle={cardHeaderStyle}
        title="Reports and Payments"
        showPath={true}
        routes={routes}
      >
        <Tabs size="large" centered>
          {tabs.map((tab, i) => (
            <TabPane tab={tab.tabName} key={i.toString()}>
              {tab.content}
            </TabPane>
          ))}
        </Tabs>
      </CardHolderFrame>
    </div>
  );
};

export default PartnerReportPaymentList;
