// FOR VERIFICATION SCREENS
export const DOCINFO_LOADING = 'DOCINFO_LOADING'
export const DOCINFO_FAILED = 'DOCINFO_FAILED'
export const ADD_DOCINFO = 'ADD_DOCINFO'

export const ROLES_LOADING = 'ROLES_LOADING'
export const ROLES_FAILED = 'ROLES_FAILED'
export const ADD_ROLES = 'ADD_ROLES'

export const LOCINFO_LOADING = 'LOCINFO_LOADING'
export const LOCINFO_FAILED = 'LOCINFO_FAILED'
export const ADD_LOCINFO = 'ADD_LOCINFO'

export const DOCSINFO_LOADING = 'DOCSINFO_LOADING'
export const DOCSINFO_FAILED = 'DOCSINFO_FAILED'
export const ADD_DOCSINFO = 'ADD_DOCSINFO'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

// FOR HOME SCREEN
export const CITIES_LOADING = 'CITIES_LOADING'
export const CITIES_FAILED = 'CITIES_FAILED'
export const ADD_CITIES = 'ADD_CITIES'

export const LOCALITIES_LOADING = 'LOCALITIES_LOADING'
export const LOCALITIES_FAILED = 'LOCALITIES_FAILED'
export const ADD_LOCALITIES = 'ADD_LOCALITIES'

export const SPECIALITIES_LOADING = 'SPECIALITIES_LOADING'
export const SPECIALITIES_FAILED = 'SPECIALITIES_FAILED'
export const ADD_SPECIALITIES = 'ADD_SPECIALITIES'

export const DOCS_LOADING = 'DOCS_LOADING'
export const DOCS_FAILED = 'DOCS_FAILED'
export const ADD_DOCS = 'ADD_DOCS'

export const DOC_LOADING = 'DOC_LOADING'
export const DOC_FAILED = 'DOC_FAILED'
export const ADD_DOC = 'ADD_DOC'

export const SET_HASHTAGS = 'SET_HASHTAGS'
export const SET_EXPERT_TIPS = 'SET_EXPERT_TIPS'
export const SET_ONE_MIN = 'SET_ONE_MIN'
export const SET_ALL_POSTS = 'SET_ALL_POSTS'
export const SET_MY_POSTS = 'SET_MY_POSTS'

// UI
export const SET_SELECTED_SERVICE_INDEX = 'SET_SELECTED_SERVICE_INDEX'
export const SHOW_DASHBOARD = 'SHOW_DASHBOARD'
export const SELECTED_ROLE = 'SELECTED_ROLE'

//Partner Login
export const SET_ISPARTNER_LOGGED_IN = 'SET_ISPARTNER_LOGGED_IN'
export const APPOINTMENT_LOADING = 'APPOINTMENT_LOADING'
export const APPOINTMENT_FAILED = 'APPOINTMENT_FAILED'
export const APPOINTMENT_SUCCESS = 'APPOINTMENT_SUCCESS'
export const SET_APPOINTMENT_LIST = 'SET_APPOINTMENT_LIST'
export const SET_ROLES_LIST = 'SET_ROLES_LIST'
export const SET_CLINIC_LIST = 'SET_CLINIC_LIST'

export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE'
export const SET_SELECTED_CLINIC = 'SET_SELECTED_CLINIC'
export const SET_SELECTED_APPOINTMENT = 'SET_SELECTED_APPOINTMENT'

//User dashboard

export const SET_SERVICE = 'SET_SERVICE'
export const SET_SPECIALITY_LIST = 'SET_SPECIALITY_LIST'

export const SET_OUTCOME_PACKAGES = 'SET_OUTCOME_PACKAGES'

export const SET_SPECIALITY = 'SET_SPECIALITY'
export const SET_DOCTORS_LIST = 'SET_DOCTORS_LIST'

export const SET_USER_CURRENT_LOCATION = 'SET_USER_CURRENT_LOCATION'
export const SET_USER_STATE = 'SET_USER_STATE'
export const SET_USER_CITY = 'SET_USER_CITY'
export const SET_USER_LOCALITY = 'SET_USER_LOCALITY'
