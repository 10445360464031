import React, { useEffect, useState } from "react";
import {
  MedicineBoxOutlined,
  UserAddOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  SmileOutlined,
  DollarCircleOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Modal, Button } from "antd";
const AppointmentDetails = (props) => {
  // console.log("PAD: ", props);

  const initCategories = [
    {
      title: "Date",
      icon: <CalendarOutlined />,
      text: "",
    },
    {
      title: "Time",
      icon: <ClockCircleOutlined />,
      text: "",
    },
    {
      title: "Doctor",
      icon: <UserAddOutlined />,
      text: "",
    },
    {
      title: "Role",
      icon: <MedicineBoxOutlined />,
      text: "",
    },
    {
      title: "Address",
      icon: <EnvironmentOutlined />,
      text: "",
    },
    {
      title: "Status",
      icon: <SmileOutlined />,
      text: "",
    },
    {
      title: "Outcome Combo",
      icon: <FileTextOutlined />,
      text: "",
    },
    {
      title: "Package Name",
      icon: <FileProtectOutlined />,
      text: "",
    },
    {
      title: "Amount",
      icon: <DollarCircleOutlined />,
      text: "",
    },
  ];

  const [Categories, setCategories] = useState(initCategories);
  const [showAppointmentsDetails, setShowAppointmentsDetails] = useState(false);

  const formatTime = (time) => {
    let str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  useEffect(() => {
    const newCategories = [
      {
        title: "Date",
        icon: <CalendarOutlined />,
        text: props?.appointmentdate,
      },
      {
        title: "Time",
        icon: <ClockCircleOutlined />,
        text: `${formatTime(props?.timefrom)} - ${formatTime(props?.timeto)}`,
      },
      {
        title: "Doctor",
        icon: <UserAddOutlined />,
        text: props?.docname,
      },
      {
        title: "Role",
        icon: <MedicineBoxOutlined />,
        text: props?.role,
      },
      {
        title: "Clinic Name",
        icon: <HomeOutlined />,
        text: props?.locname,
      },
      {
        title: "Address",
        icon: <EnvironmentOutlined />,
        text: props?.locadd,
      },
      {
        title: "Status",
        icon: <SmileOutlined />,
        text: props?.appointmentstatus,
      },
      {
        title: "Outcome Combo",
        icon: <FileTextOutlined />,
        text: "",
      },
      {
        title: "Package Name",
        icon: <FileProtectOutlined />,
        text: props?.pdlamomname,
      },
      {
        title: "Amount",
        icon: <DollarCircleOutlined />,
        text:
          props?.pdla_paynow_flag === "N" ? (
            <span className="text-danger">₹{props?.pdladcsmamount} Unpaid</span>
          ) : (
            <span className="text-success">₹{props?.pdladcsmamount} Paid</span>
          ),
      },
    ];
    setCategories(newCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Button
        className="my-1"
        type="link"
        onClick={() => {
          setShowAppointmentsDetails(true);
        }}
        icon={<EyeOutlined style={{ display: "inline-flex" }} />}
      >
        View Details
      </Button>
      <Modal
        title={"Appointment Details"}
        centered
        closable
        visible={showAppointmentsDetails}
        onCancel={() => {
          setShowAppointmentsDetails(false);
        }}
        onOk={() => {
          setShowAppointmentsDetails(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        bodyStyle={{ padding: "0px" }}
      >
        <div style={{ width: "100%" }}>
          {Categories.map((dat, i) => (
            <div
              key={i + Math.random}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="border px-4"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  width: "195px",
                  //   color: "#007bff",
                }}
              >
                <div style={{ display: "inline-flex" }}>{dat.icon}</div>
                <div className="p-2 px-4">{dat.title}</div>
              </div>

              <div style={{ textAlign: "end" }}> {dat.text || "NA "}</div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default AppointmentDetails;
