import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Radio,
  Divider,
  Form,
  Input,
  message,
  Select,
  DatePicker,
  Upload,
  Modal,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";

import axios from "axios";
import { apis } from "../../shared/apis";
import CardHolderFrame from "../containers/CardHolderFrame";
const UploadDocumentsForm = (props) => {
  let c64 = false,
    i64 = false;
  const [loader, setLoader] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const [medCouncils, setMedCouncils] = useState([]);

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/manage-clinic",
      pathName: "Manage Clinic",
    },
    {
      path: "/upload-documents",
      pathName: "Upload Documents",
    },
  ];

  useEffect(() => {
    const getMedicalCouncils = async ({ onlyPsy }) => {
      setLoader(true);
      await axios({ ...apis.getMedicalCouncils })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          let mcs = [];
          onlyPsy
            ? res.data.info.forEach((mc, i) => {
                if (mc.mctype === "PSY" || mc.mctype === "OPSY") {
                  let obj = {
                    ...mc,
                    label: mc.mcname,
                    value: mc.mcid,
                    key: mc.mcid,
                  };
                  mcs.push(obj);
                }
              })
            : res.data.info.forEach((mc, i) => {
                let obj = {
                  ...mc,
                  label: mc.mcname,
                  value: mc.mcid,
                  key: mc.mcid,
                };
                mcs.push(obj);
              });
          setMedCouncils(mcs);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
        });
    };

    switch (JSON.parse(localStorage.getItem("selectedRole")).role) {
      case "ONL":
        getMedicalCouncils({ onlyPsy: false });
        break;
      case "DOC":
        getMedicalCouncils({ onlyPsy: false });
        break;
      case "PSY":
        getMedicalCouncils({ onlyPsy: true });
        break;
      case "OPSY":
        getMedicalCouncils({ onlyPsy: true });
        break;
      case "RAD":
        break;
      case "CHE":
        // console.log("Ch");
        break;
      case "DIE":
        break;
      case "ODIE":
        break;
      case "PHY":
        break;
      case "PAT":
        break;
      default:
        // console.log("D");
        // props.history.push("/choose-option");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isPsy =
    JSON.parse(localStorage.getItem("selectedRole")).role === "PSY" ||
    JSON.parse(localStorage.getItem("selectedRole")).role === "OPSY";
  let isRad = JSON.parse(localStorage.getItem("selectedRole")).role === "RAD";
  let hideQual =
    JSON.parse(localStorage.getItem("selectedRole")).role === "CHE" ||
    JSON.parse(localStorage.getItem("selectedRole")).role === "DIE" ||
    JSON.parse(localStorage.getItem("selectedRole")).role === "ODIE" ||
    JSON.parse(localStorage.getItem("selectedRole")).role === "PHY";
  let hideCouncil = isRad || hideQual;
  const degreesConst = isPsy
    ? [
        { name: "BA", value: "mbbs" },
        { name: "MA", value: "ms" },
        { name: "Diploma", value: "diploma" },
        { name: "Others", value: "other" },
      ]
    : isRad
    ? [
        { name: "MBBS", value: "mbbs" },
        { name: "MD", value: "md" },
        { name: "MS", value: "ms" },
        { name: "Diploma", value: "diploma" },
      ]
    : [
        { name: "MBBS", value: "mbbs" },
        { name: "MD", value: "md" },
        { name: "MS", value: "ms" },
        { name: "Diploma", value: "diploma" },
        { name: "Others", value: "other" },
      ];
  const idsConst = [
    { name: "Adhar Card", value: "1" },
    { name: "Voter ID", value: "2" },
    { name: "Passport", value: "3" },
    // { name: "None", value: "4" },
  ];

  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };

  const [form] = Form.useForm();

  const handleFinish = async (v) => {
    // e.preventDefault();
    if (!i64 || !c64) {
      message.error("Please Upload Images");
    } else {
      setFinishing(true);
      await axios({
        ...apis.updateproffesion,
        data: {
          ...v,
          regcertificateimage: c64,
          identityproofimage: i64,
          regyear: v?.regyear?.format("YYYY"),
          docid: JSON.parse(localStorage.getItem("selectedRole"))?.roleid,
          MBBS: v?.degree?.includes("mbbs") ? "Y" : "N",
          MS: v?.degree?.includes("ms") ? "Y" : "N",
          MD: v?.degree?.includes("md") ? "Y" : "N",
          Diploma: v?.degree?.includes("diploma") ? "Y" : "N",
        },
      })
        .then((res, err) => {
          setFinishing(false);
          if (err) message.error("Something Went Wrong");
          message.success("Successfully Saved !");
          props?.setIsDocSubmitted(true);
          props?.goto(0);
          props.history.push("/choose-option");
        })
        .catch((err) => {
          setFinishing(false);
          if (err) message.error("Something Went Wrong");
        });
    }
  };

  const customRequestID = (option) => {
    const formData = new FormData();
    formData.append("files[]", option.file);
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function (e) {
      i64 = e.target.result;
      i64 = i64.slice(22, i64.length);

      if (e && e.target && e.target.result) {
        option.onSuccess();
      }
    };
  };
  const customRequest = (option) => {
    const formData = new FormData();
    formData.append("files[]", option.file);
    const reader = new FileReader();
    reader.readAsDataURL(option.file);
    reader.onloadend = function (e) {
      c64 = e.target.result;
      c64 = c64.slice(22, c64.length);

      if (e && e.target && e.target.result) {
        option.onSuccess();
      }
    };
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Only upload JPG or PNG files!");
      return false;
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    const isLt2M = true;
    if (!isLt2M) {
      message.error("Image size must be less than 2MB!");
      return false;
    }
    return isJpgOrPng && isLt2M;
  };
  return (
    <div className="container">
      <CardHolderFrame
        loading={loader}
        headStyle={cardHeaderStyle}
        title="Upload Documents"
        showPath={true}
        routes={routes}
        {...props}
      >
        <Form
          {...layout}
          // labelCol={{ span: 7 }}
          size="large"
          form={form}
          onFinish={handleFinish}
          labelAlign="left"
        >
          {hideQual ? null : (
            <>
              <Divider>Qualifications</Divider>
              <Form.Item
                name="degree"
                // valuePropName="checked"
                label={<div>Degree</div>}
                rules={[
                  {
                    required: true,
                    message:
                      "Please select atleast one degree or add custom degree",
                  },
                ]}
              >
                <Checkbox.Group
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {degreesConst.map((degree, i) => (
                    <div key={degree.name + Math.random()}>
                      <Checkbox value={degree.value}>{degree.name}</Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.degree !== currentValues.degree
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("degree")?.includes("other") ? (
                    <Form.Item
                      name="dm_doctor_other_qualifications"
                      label={<div>Other Degree</div>}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Degree name!",
                        },
                      ]}
                    >
                      <Input placeholder="Other Qualification"></Input>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </>
          )}
          <Divider>Medical Registration</Divider>
          {hideCouncil ? null : (
            <>
              <Form.Item
                name="regcouncil"
                label={<div>Medical Council</div>}
                rules={[
                  {
                    required: true,
                    message: "Please Select Medical Council",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Medical Council"
                  options={medCouncils}
                  onChange={(e) => {
                    if (e === 30) {
                      form.setFieldsValue({
                        regno: JSON.parse(localStorage.getItem("selectedRole"))
                          ?.roleid,
                      });
                    }
                  }}
                ></Select>
              </Form.Item>

              <Form.Item
                name="regyear"
                label={<div>Registration Year</div>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your registration year!",
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  placeholder="Select your Registration Year"
                  style={{ width: "100%" }}
                  format="YYYY"
                  picker="year"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                name="experience"
                label={<div>Experience(in years)</div>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your experience in years!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        value &&
                        parseInt(value) >
                          new Date().getFullYear() -
                            parseInt(getFieldValue("regyear").format("YYYY"))
                      ) {
                        return Promise.reject(
                          "Career Experience cannot be greater than  " +
                            (new Date().getFullYear() -
                              parseInt(
                                getFieldValue("regyear").format("YYYY")
                              )) +
                            " !"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Career Experience in Years"
                  type="number"
                ></Input>
              </Form.Item>
            </>
          )}
          <Form.Item
            name="regno"
            label={<div>{isPsy ? "CRR " : "Medical "} Registration No</div>}
            rules={[
              {
                required: true,
                message: "Please enter your medical/crr registration no!",
              },
            ]}
          >
            <Input
              placeholder={
                isPsy ? "CRR Registration No" : "Medical Registration No"
              }
            ></Input>
          </Form.Item>
          <Form.Item
            name="regcertificateimage"
            rules={[
              {
                required: true,
                message: isPsy
                  ? "Please Upload RCI Certificate"
                  : "Please Upload Medical Registration Certificate",
              },
              // ({ getFieldValue }) => ({
              //   validator(_, value) {
              //     if (c64.length < 10) {
              //       return Promise.reject(
              //         isPsy
              //           ? "Please Upload RCI Certificate"
              //           : "Please Upload Medical Registration Certificate"
              //       );
              //     }
              //     return Promise.resolve();
              //   },
              // }),
            ]}
            label={
              <div>
                {isPsy
                  ? "Upload RCI Certificate"
                  : "Upload Medical Registration Certificate"}
              </div>
            }
            valuePropName="file"
          >
            <Upload
              disabled={
                form.getFieldValue("regcertificateimage")?.fileList?.length
                  ? true
                  : false
              }
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              listType="picture-card"
              fileList={
                form.getFieldValue("regcertificateimage")?.fileList
                // ? form.getFieldValue("regcertificateimage")?.fileList
                // : []
              }
              maxCount={1}
              onPreview={(d) => {
                Modal.info({
                  centered: true,
                  bodyStyle: { width: "100%" },
                  content: (
                    <Image
                      src={d.thumbUrl}
                      alt="Imahe"
                      style={{ width: "100%" }}
                      preview={false}
                    />
                  ),
                });
              }}
              onRemove={() => {
                c64 = "";
              }}
            >
              <div>
                <div style={{ fontSize: "1.5rem" }}>
                  <UploadOutlined />
                </div>
                <div> Choose file from device</div>
              </div>
            </Upload>
          </Form.Item>
          <Divider>Identity Proof</Divider>
          <Form.Item
            name="verification_no"
            // valuePropName="checked"
            label={<div>ID</div>}
            rules={[
              {
                required: true,
                message: "Please select anyone id proof",
              },
            ]}
          >
            <Radio.Group
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {idsConst.map((id, i) => (
                <div key={id.name + Math.random()}>
                  <Radio value={id.value}>{id.name}</Radio>
                </div>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="identityproofimage"
            rules={[
              {
                required:
                  form.getFieldValue("verification_no") !== "4" ? true : false,
                message: "Please Upload ID",
              },
            ]}
            label={<div>Upload ID Proof</div>}
            valuePropName="file"
          >
            <Upload
              disabled={
                form.getFieldValue("identityproofimage")?.fileList?.length
                  ? true
                  : false
              }
              customRequest={customRequestID}
              beforeUpload={beforeUpload}
              listType="picture-card"
              // name="file"
              fileList={form.getFieldValue("identityproofimage")?.fileList}
              maxCount={1}
              onPreview={(d) => {
                Modal.info({
                  centered: true,
                  bodyStyle: { width: "100%" },
                  content: (
                    <Image
                      src={d.thumbUrl}
                      alt="Imahe"
                      preview={false}
                      style={{ width: "100%" }}
                    />
                  ),
                });
              }}
              onRemove={() => {
                i64 = "";
              }}
            >
              <div>
                <div style={{ fontSize: "1.5rem" }}>
                  <UploadOutlined />
                </div>
                <div> Choose file from device</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={finishing}>
              {finishing ? "Saving..." : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </CardHolderFrame>
    </div>
  );
};

export default UploadDocumentsForm;
