import { Card, Form, Input, Button, Modal, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "../shared/apis";
const BankAccountForm = (props) => {
  const [accnum, setAccNum] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accName, setAccName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState({
    inProgess: false,
    message: "Submit",
    error: "",
  });

  // const [bankRes, setBankRes] = useState("");

  const ifscRegEx = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$");
  const accNumRegEx = new RegExp("[0-9]{9,18}");
  const accNameRegEx = new RegExp("^[A-Za-z\\s]+$");

  const isValidCode = (val) => {
    if (ifscRegEx.test(val)) {
      return true;
    } else return false;
  };

  const isValidAccNum = (num) => {
    if (accNumRegEx.test(num)) {
      return true;
    } else return false;
  };

  const isValidName = (name) => {
    if (accNameRegEx.test(name)) {
      return true;
    } else return false;
  };

  const showDetails = (bankRes) => {
    Modal.info({
      title: "Details",
      closable: true,
      okText: "Close",
      onOk: () => {
        props.closeBankForm();
      },
      bodyStyle: { padding: "0px" },
      content: (
        <div>
          {bankRes?.status_code === 1 ? (
            <div
              className="bg-primary text-light p-2 my-2 border rounded"
              style={{ fontSize: "1.15rem" }}
            >
              Request accepted. An amount of {bankRes?.calculatedamount} will be
              transferred to your bank account shortly.
            </div>
          ) : (
            <div
              className="bg-light text-danger p-2 my-2 border rounded"
              style={{ fontSize: "1.15rem" }}
            >
              {bankRes?.status}.<br />
              Something went wrong
            </div>
          )}
          {/* <Card type="inner" title={<div>Payment Details</div>}>
            <table className="table">
              <tbody>
                <tr key="1">
                  <th className="text-secondary">Gateway : </th>
                  <th className="">Paytm</th>
                </tr>
                <tr key="2">
                  <th className="text-secondary">Transaction ID </th>
                  <th className="">{bankRes?.lorderid}</th>
                </tr>
                <tr key="3">
                  <th className="text-secondary">Client : </th>
                  <th className="">{bankRes?.beneficiaryName}</th>
                </tr>
                <tr key="4">
                  <th className="text-secondary">Amount : </th>
                  <th className="">{bankRes?.calculatedamount}</th>
                </tr>
              </tbody>
            </table>
          </Card> */}
        </div>
      ),
    });
  };

  const handleSubmit = async () => {
    setStatus({ inProgess: true, message: "Submitting...", error: "" });
    let obj = {
      passedamount: props.totalPatientAmount,
      enddate:
        props?.endDate?.length < 11
          ? props?.endDate
          : props?.endDate.format("DD-M-YYYY"),
      dlmid: props?.dlm_id || "",
      id: props?.id || "",
      role: props?.role || "",
      subject: props?.subject || "",
      beneficiaryName: accName,
      comment: remarks,
      startdate:
        props?.startDate?.length < 11
          ? props?.startDate
          : props?.startDate.format("DD-M-YYYY"),
      calculatedamount: props.billingAmount,
      amouttodoctor: props.billingAmount,
      ifscCode: ifscCode,
      accoutnumber: accnum,
      cooloffdays: props?.cooloffDays,
    };
    await axios({ ...apis.getMyDisbursal, data: obj })
      .then((res, err) => {
        if (err) {
          setStatus({
            inProgess: false,
            message: "Retry",
            error: err?.message,
          });
        } else {
          setStatus({
            inProgess: false,
            message: "Success",
            error: "Successfully Submitted !",
          });
          // console.log("res.data:", res.data);
          // setBankRes(res.data);
          showDetails(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          setStatus({
            inProgess: false,
            message: "Retry",
            error: err?.message,
          });
        }
      });
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setLoader(true);
      await axios({
        ...apis.allinformation,
        data: {
          docid: JSON.parse(localStorage.getItem("selectedRole"))?.roleid,
        },
      })
        .then((res, err) => {
          if (err) message.error("Something went Wrong");
          // console.log("Res DP: ", res);
          setAccNum(
            res?.data?.dm_doctor_bank_account_no !== "null"
              ? res?.data?.dm_doctor_bank_account_no
              : ""
          );
          setIfscCode(
            res.data?.dm_doctor_bank_IFSC_code !== "null"
              ? res.data?.dm_doctor_bank_IFSC_code
              : ""
          );
          setAccName(
            res.data?.dm_doctor_bank_beneficiary_name !== "null"
              ? res.data?.dm_doctor_bank_beneficiary_name
              : ""
          );
          setRemarks("");
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something went Wrong");
        });
    };
    fetchProfile();
  }, []);

  return (
    <div>
      <Card
        type="inner"
        title={<div>Enter Account Details</div>}
        loading={loader}
      >
        {loader ? (
          "Loadng"
        ) : (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              password: accnum,
              confirm: accnum,
              acc_holder_name: accName,
              ifsc: ifscCode,
            }}
          >
            <Form.Item
              name="password"
              label="Account Number"
              rules={[
                {
                  required: true,
                  message: "Please input your Account Number!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || isValidAccNum(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please Enter Valid Account Number");
                  },
                }),
              ]}
              hasFeedback
            >
              <Input
                placeholder="Enter Account Number"
                allowClear
                value={accnum}
                onChange={(e) => setAccNum(e.target.value)}
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Account Number"
              dependencies={["password"]}
              hasFeedback
              // validateStatus={"warning"}
              // help="Should be combination of numbers & alphabets"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two account numbers that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder="Confirm Account Number "
                allowClear
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="ifsc"
              label="IFSC CODE"
              rules={[
                {
                  required: true,
                  message: "Please confirm your bank's IFSC Code!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || isValidCode(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please Enter Valid IFSC Code");
                  },
                }),
              ]}
            >
              <Input
                placeholder="IFSC Code of Your Bank "
                allowClear
                size="large"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="acc_holder_name"
              label="Account Holder Name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Account Holder's Name!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || isValidName(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please Enter Valid Name !");
                  },
                }),
              ]}
            >
              <Input
                placeholder="Account Holder's Name"
                allowClear
                size="large"
                value={accName}
                onChange={(e) => setAccName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="comment"
              label="Remarks"
              rules={[
                {
                  required: false,
                  message: "Any Comment!",
                },
              ]}
              help={
                <div className="text-secondary font-weight-bold">
                  Reciever will be notified once the amount reaches their bank
                  account
                </div>
              }
            >
              <Input.TextArea
                placeholder="Any Comments"
                allowClear
                size="large"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                // onClick={handleSubmit}
                loading={status.inProgess}
                style={{
                  backgroundColor:
                    status?.message === "Success"
                      ? "#52c41a"
                      : status?.message === "Retry"
                      ? "#f5222d"
                      : "#1890ff",
                }}
              >
                {status?.message}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
      {/* 
      <Modal
        visible={bankRes?.status === "SUCCESS"}
        closable={true}
        onCancel={() => console.log("closed")}
        title={<div>Details</div>}
      ></Modal> */}
    </div>
  );
};

export default BankAccountForm;
