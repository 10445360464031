import React, { useState } from "react";
import { Card, message, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "../../assets/styles/AppointmentCard.css";
import axios from "axios";
import { apis } from "../../shared/apis";
import clinicLogo from "../../assets/images/clinic_logo.png";
import DoctorDetailsCard from "../userDashBoard/DoctorDetailsCard";

function PartnerClinicCard(props) {
  const [loader, setLoader] = useState(false);
  const [showDocDetials, setShowDocDetials] = useState(false);
  const handleClickHandler = async () => {
    if (props?.clinic?.checkpoint !== 3) {
      localStorage.setItem("dlm_id", props?.clinic?.dlmid);
      localStorage.setItem("selectedClinic", JSON.stringify(props?.clinic));
      props.history.push("/choose-option/manage-clinic");
      // props.history.push("/manage-clinic");
    } else {
      let proG = 0;
      setLoader(true);
      await axios({
        ...apis.checkpoint,
        data: { dlmid: props?.clinic?.dlmid },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong!");
          localStorage.setItem("dlm_id", props?.clinic?.dlmid);
          localStorage.setItem("selectedClinic", JSON.stringify(props?.clinic));
          localStorage.setItem("checkpoint", props?.clinic?.checkpoint);
          proG = res.data.progress;
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong!");
        });
      // props?.clinic.checkpoint !== 3
      proG !== 2
        ? props.history.push({
            pathname: "/choose-option/manage-clinic",
            state: { checkpoint: props?.clinic?.checkpoint },
          })
        : props.history.push(`/choose-option`);
        // : props.history.push(`/choose-option?lid=${props?.clinic.lid}`);
    }
  };
  return (
    <div
      className="mb-4"
      onClick={
        props?.shouldSkip
          ? () => {
              setShowDocDetials(!showDocDetials);
            }
          : handleClickHandler
      }
    >
      <Card
        loading={loader}
        hoverable
        type="inner"
        headStyle={{
          padding: 0,
          backgroundColor: `${
            props?.clinic?.dlm_active_today === "Y" ? "#1890ff" : "#f5222d"
          }`,
        }}
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              fontSize: "1.75rem",
              fontWeight: "400",
              padding: "0rem 1rem",
              color: "white",
            }}
          >
            <div className="mr-1" style={{ textTransform: "capitalize" }}>
              {props?.clinic?.lname}
            </div>
            <div style={{ fontSize: "15px", color: "#f0f0f0" }}>
              {props?.clinic?.lid}
            </div>
          </div>
        }
      >
        <div className="row mx-0">
          <div className="col-sm" style={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "100px" }}
              src={
                clinicLogo ||
                `https://cdn.icon-icons.com/icons2/1465/PNG/512/588hospital_100778.png`
              }
              alt={"logo of clinic"}
            ></img>
          </div>
          <div
            className="col-sm-8"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className=" p-1">
              <div className="patient-name">{props?.clinic?.lcity}</div>
              <div className="patient-age">{props?.clinic?.ladrline1}</div>
            </div>
            <div style={{ whiteSpace: "nowrap" }}>
              {props?.clinic?.dlm_active_today === "Y" ? (
                <>
                  <i className="fas fa-circle text-success"></i> Active
                </>
              ) : (
                <>
                  <i className="fas fa-circle text-danger"></i>Not Active
                </>
              )}
            </div>
            {props?.shouldSkip ? (
              <div>
                <Button
                  shape="round"
                  type="text"
                  icon={!showDocDetials ? <DownOutlined /> : <UpOutlined />}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    fontSize: "1rem",
                    margin: "0.5rem",
                    backgroundColor: "#52c41a",
                    padding: "1rem",
                    paddingBottom: "1.2rem",
                    transition: "all linear 0.5s",
                  }}
                  onClick={() => {
                    // setShowClinicDetials(false);
                    setShowDocDetials(!showDocDetials);
                  }}
                  className="mx-2 bg-success"
                >
                  View Details
                </Button>
              </div>
            ) : null}
          </div>
          {props?.shouldSkip ? null : (
            <div className="col-sm-12 text-center">
              <Button size="large" shape="round" type="primary">
                Click to Select
              </Button>
            </div>
          )}
        </div>
        {showDocDetials && (
          <DoctorDetailsCard
            doc={{
              ldlm_id: props?.clinic?.dlmid,
              ldoctorid: JSON.parse(
                localStorage.getItem("selectedRole")
              )?.role?.toUpperCase(),
            }}
            reqOrigin={"partnerApp"}
          ></DoctorDetailsCard>
        )}
      </Card>
    </div>
  );
}

export default PartnerClinicCard;
