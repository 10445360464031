import React, { useEffect, useState } from "react";

import { Empty, Button, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import axios from "axios";
import { apis } from "../../shared/apis";
import TimingCard from "./TimingCard";
import AddTimingForm from "./AddTimingForm";
import CardHolderFrame from "../containers/CardHolderFrame";

const AllTimingsList = (props) => {
  const [loader, setLoader] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [showTimingForm, setShowTimingForm] = useState(false);

  const [allTimings, setAllTimings] = useState([]);
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },

    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/manage-clinic",
      pathName: "Manage Clinic",
    },
    {
      path: "/manage-timings",
      pathName: "Manage Timings",
    },
  ];

  useEffect(() => {
    const fetchTimings = async () => {
      setLoader(true);
      await axios({
        ...apis.fettimings2,
        data: {
          docid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Err in Fetching Timings: ");
          setAllTimings(res.data.alltimings);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Err in Fetching Timings: ");
        });
    };
    fetchTimings();
  }, [shouldUpdate]);

  return (
    <div className="container">
      <CardHolderFrame
        headStyle={cardHeaderStyle}
        title="Manage Timings"
        showPath={true}
        routes={routes}
        loading={loader}
        extra={[
          <Button
            key={Math.random()}
            type="primary"
            shape="round"
            size="large"
            icon={
              <PlusOutlined
                style={{ display: "inline-flex", alignItems: "center" }}
              />
            }
            onClick={() => setShowTimingForm(true)}
          >
            Add Timing
          </Button>,
        ]}
        {...props}
      >
        {allTimings.length ? (
          allTimings.map((time, i) => (
            <div key={i + Math.random()} className="mb-4">
              <TimingCard
                {...time}
                updateList={() => setShouldUpdate(!shouldUpdate)}
              ></TimingCard>
            </div>
          ))
        ) : (
          <Empty description="No Timings Added"></Empty>
        )}
      </CardHolderFrame>
      <Modal
        centered
        visible={showTimingForm}
        onCancel={() => setShowTimingForm(false)}
        title={<div className="text-center">Add New Timing</div>}
        footer={[]}
        destroyOnClose={true}
      >
        <AddTimingForm
          closeForm={() => setShowTimingForm(false)}
          updateList={() => setShouldUpdate(!shouldUpdate)}
        />
      </Modal>
    </div>
  );
};

export default AllTimingsList;
