import React, { useEffect, useState } from "react";
import {
  Divider,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Spin,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { apis } from "../../shared/apis";
const { Option } = Select;
const AddClinicForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: "S" } })
      .then((res, err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
        let sts = [];
        let cts = [];
        res.data?.info?.forEach((element) => {
          sts.push({
            label: element.cstate,
            value: element.cstate,
            key: element.cstate + Math.random(),
          });
          cts.push({
            label: `${element.cname} (${element.cstate})`,
            value: element.cname,
            key: element.cname + Math.random(),
          });
        });
        sts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        cts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        setStates(sts);
        setCities(cts);
      })
      .catch((err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const fetchCities = async (val) => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: val } })
      .then((res, err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");

        let cts = [];
        res.data?.info?.forEach((element) => {
          cts.push({
            label: `${element.cname} (${element.cstate})`,
            value: element.cname,
            key: element.cname + Math.random(),
          });
        });
        cts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        setCities([...cts]);
      })
      .catch((err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const addClinic = async (data) => {
    setLoader(true);

    await axios({
      ...apis.clinicaddlocation,
      data: {
        ...data,
        isoimage: data?.accreditation?.iso || "",
        nablimage: data?.accreditation?.nabl || "",
        capimage: data?.accreditation?.cap || "",
        docid: JSON.parse(localStorage.getItem("selectedRole"))?.roleid,
        state: data.state.value,
        city: data.city.value,
        dealer_id: data.dealer_id,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        if (res.data.status === "SUCCESS") {
          message.success("Successfully Added Clinic");
          props.closeForm();
          props.setShouldUpdate(!props.shouldUpdate);
        } else {
          message.error("Ooops..Something Went Wrong!");
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      lg: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };
  return (
    <div>
      <Form
        {...layout}
        onFinish={addClinic}
        onValuesChange={(a) => {
          if (a?.state) {
            fetchCities(a?.state?.value);
          }
        }}
      >
        <Form.Item
          name="option"
          rules={[{ required: true, message: "Please select a option" }]}
        >
          <Select placeholder="Select Any Option" allowClear>
            <Option value="1">I own/rent/visit a Clinic/Hospital/Vitals</Option>
            <Option value="2">I am available for Home Visits</Option>
          </Select>
        </Form.Item>
        <Divider>Clinic Details</Divider>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please enter your Clinic Name" }]}
        >
          <Input size="large" placeholder="Enter Clinic Name"></Input>
        </Form.Item>
        <Form.Item
          name="adrline1"
          rules={[
            { required: true, message: "Please enter your Clinic's Address" },
          ]}
        >
          <Input
            size="large"
            placeholder="Address Line 1"
            maxLength={45}
          ></Input>
        </Form.Item>
        <Form.Item name="adrline2">
          <Input
            size="large"
            placeholder="Address Line 2"
            maxLength={45}
          ></Input>
        </Form.Item>
        <Form.Item
          name="district"
          rules={[{ required: true, message: "Please enter your District" }]}
        >
          <Input size="large" placeholder="District"></Input>
        </Form.Item>
        <Form.Item
          name="pin"
          rules={[{ required: true, message: "Please enter your Pincode" }]}
        >
          <Input size="large" placeholder="Pincode"></Input>
        </Form.Item>
        <Form.Item
          name="state"
          rules={[{ required: true, message: "Please enter your State" }]}
        >
          <Select
            placeholder="Select Your State"
            loading={fetching}
            size="large"
            allowClear
            showSearch
            labelInValue
            autoComplete="off"
            filterOption={true}
            // onSearch={(val) => fetchCities(val, { isStateOnly: true })}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={states}
          ></Select>
        </Form.Item>
        <Form.Item
          name="city"
          rules={[{ required: true, message: "Please enter your City" }]}
        >
          <Select
            placeholder="Select Your City"
            loading={fetching}
            size="large"
            allowClear
            showSearch
            labelInValue
            filterOption={true}
            // onSearch={(val) => fetchCities(val, { isStateOnly: false })}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={cities}
          ></Select>
        </Form.Item>
        <Form.Item name="dealer_id">
          <Input
            size="large"
            placeholder="Reference Id(Optional)"
            maxLength={45}
          ></Input>
        </Form.Item>
        {JSON.parse(localStorage.getItem("selectedRole"))?.role === "PAT" ||
        JSON.parse(localStorage.getItem("selectedRole"))?.role === "RAD" ? (
          <div>
            <Divider>
              <div>
                <div className="text-primary">Accreditaion(Optional)</div>
                <div className="text-secondary" style={{ fontSize: "0.75rem" }}>
                  Upload Any One
                </div>
              </div>
            </Divider>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Form.Item name={["accreditation", "iso"]}>
                <Upload>
                  <Button
                    type="primary"
                    icon={<UploadOutlined style={{ display: "inline-flex" }} />}
                  >
                    ISO
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name={["accreditation", "nabl"]}>
                <Upload>
                  <Button
                    type="primary"
                    icon={<UploadOutlined style={{ display: "inline-flex" }} />}
                  >
                    NABL
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name={["accreditation", "cap"]}>
                <Upload>
                  <Button
                    type="primary"
                    icon={<UploadOutlined style={{ display: "inline-flex" }} />}
                  >
                    CAP
                  </Button>
                </Upload>
              </Form.Item>
            </div>
          </div>
        ) : null}
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loader}
          >
            {loader ? "Please Wait..." : "Register"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddClinicForm;
