import * as ActionTypes from "../ActionTypes";

export const setService = (data) => {
  return {
    type: ActionTypes.SET_SERVICE,
    payload: data,
  };
};

export const setSpecialityList = (data) => {
  return {
    type: ActionTypes.SET_SPECIALITY_LIST,
    payload: data,
  };
};

export const setSpeciality = (data) => {
  return {
    type: ActionTypes.SET_SPECIALITY,
    payload: data,
  };
};

export const setDoctorsList = (data) => {
  return {
    type: ActionTypes.SET_DOCTORS_LIST,
    payload: data,
  };
};

export const setPackagesList = (data) => {
  return {
    type: ActionTypes.SET_OUTCOME_PACKAGES,
    payload: data,
  };
};

export const setUserCurrentLocation = (data) => {
  return {
    type: ActionTypes.SET_USER_CURRENT_LOCATION,
    payload: data,
  };
};
export const setUserState = (data) => {
  return {
    type: ActionTypes.SET_USER_STATE,
    payload: data,
  };
};

export const setUserCity = (data) => {
  return {
    type: ActionTypes.SET_USER_CITY,
    payload: data,
  };
};
export const setUserLocality = (data) => {
  return {
    type: ActionTypes.SET_USER_LOCALITY,
    payload: data,
  };
};
