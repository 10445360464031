import React, { useState } from "react";
import { Button, message } from "antd";
import { AimOutlined } from "@ant-design/icons";
import Geolocation from "react-geolocation";
import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";
import { connect } from "react-redux";
const CurrentLocation = (props) => {
  const [isOn, setIsOn] = useState(false);
  return (
    <div>
      <Geolocation
        lazy
        onError={() => {
          message.error("Cannot Get Current Location");
          props?.setUserCurrentLocation({
            isOn: false,
          });
        }}
        onSuccess={(pos) => {
          // console.log("Pos:", pos);
          const { latitude, longitude } = pos.coords;
          props?.setUserCurrentLocation({
            coords: { latitude, longitude },
            isOn: !isOn,
          });
          localStorage.setItem(
            "currentLocation",
            JSON.stringify({
              coords: { latitude, longitude },
              isOn: isOn,
            })
          );
          message.success("Got Current Location");
        }}
        render={({
          position: { coords: { latitude, longitude } = {} } = {},
          getCurrentPosition,
          fetchingPosition,
        }) => (
          <div>
            <Button
              loading={fetchingPosition}
              icon={<AimOutlined style={{ display: "inline-flex" }} />}
              type={isOn ? "primary" : "ghost"}
              onClick={async () => {
                setIsOn(!isOn);
                if (!isOn) {
                  await getCurrentPosition();
                } else {
                  props?.setUserCurrentLocation({
                    coords: { latitude, longitude },
                    isOn: !isOn,
                  });
                  localStorage.setItem(
                    "currentLocation",
                    JSON.stringify({
                      coords: { latitude, longitude },
                      isOn: !isOn,
                    })
                  );
                }
              }}
            >
              Use Current Location
            </Button>
          </div>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserCurrentLocation: (data) =>
      dispatch(dashboardAC.setUserCurrentLocation(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrentLocation);
