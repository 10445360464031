export const SharedFeatures = [
  {
    id: 0,
    feature: "Affordability",
    description:
      "Discounted healthcare services for all in their neighbourhood at Jan Elaaj Clinics as well as for other services.",
    image: "/images/services/1.png",
  },
  {
    id: 1,
    feature: "Performance",
    description:
      "We are committed in delivering excellence in everything we do. We will be accountable and responsible for consistently delivering high-quality results.",
    image: "/images/services/2.png",
  },
  {
    id: 2,
    feature: "Convenience",
    description:
      "You can find a Jan Elaaj Clinic and other services near you through our website as well as mobile application",
    image: "/images/services/3.png",
  },
  {
    id: 3,
    feature: "Digitization",
    description:
      "Patients can access their medical records any time and from anywhere with the help of application and digital prescriptions are directly sent to the pharmacies.",
    image: "/images/services/4.png",
  },
];
