import { Progress } from "antd";
import React, { useState, useEffect } from "react";

const Videotimer = (props) => {
  const [time, setTime] = useState(45);

  useEffect(() => {
    const updateTime = () => {
      if (time < 1) {
        clearInterval(interval);
        // props?.stopRecording();
      } else {
        setTime((time) => time - 1);
      }
    };

    const interval = setInterval(() => {
      updateTime();
    }, 1000);

    return () => {
      clearInterval(interval);
      // props?.stopRecording();
    };
  }, []);
  if (time <= 1) {
    console.log("Rec stopped");
    props?.stopRecording();
  }
  return time > 0 ? (
    <div>
      <Progress
        percent={time * 2.25}
        type="circle"
        format={() => time}
      ></Progress>
    </div>
  ) : (
    "Time Over"
  );
};

export default Videotimer;
