import React, { useState } from "react";
import LogoCard from "./LogoCard";
import {
  UserOutlined,
  //   SolutionOutlined,
  //   LoadingOutlined,
  //   SmileOutlined,
} from "@ant-design/icons";

import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";
import { connect } from "react-redux";
import axios from "axios";
import { apis } from "../../shared/apis";
import LoadingMask from "../LoaderMask/LoadingMask";
import ScreeningCard from "./ScreeningCard";
import { message } from "antd";
function DoctorTypesList(props) {
  const [loader, setLoader] = useState(false);
  // const doctorTypes = [
  //   {
  //     title: "Allergist",
  //     logo: <UserOutlined />,
  //     data: { id: 1 },
  //   },
  // ];
  const getDoctorsList = async (data) => {
    setLoader(true);
    let api =
      props.selectedService.role === "VIT"
        ? apis.getVitalsBySearchLimit
        : props.userDash?.selectedService?.role === "ONL" ||
          props.userDash?.selectedService?.role === "ODIE" ||
          props.userDash?.selectedService?.role === "OPSY"
        ? apis.getonlinedoctorsbysearchlimit
        : apis.getdoctorsbysearchlimit;
    // let api = apis.getVitalsBySearchLimit;
    // let api = apis.getdoctorsbysearchlimit;
    await axios({ ...api, data: data })
      .then((res, err) => {
        if (err) message.error("Err wile fetching specialities");
        else {
          props.setDoctorsList(res.data);
          setLoader(false);
          props.next();
        }
      })
      .catch((err) => {
        if (err) message.error("Err wile fetching specialities");
        setLoader(false);
      });
  };

  const selectSpeciality = (service) => {
    props.setSpeciality(service);
    let data = {
      localityname: props.userDash?.userLocality?.llocalityname || "llocality",
      // specialityid: service?.id || "000",
      // service_id: service?.id || "000",
      hospital_id: props.userDash?.selectedService?.hospital_id,
      dmrole: props.userDash?.selectedService?.role || "role",
      cityname: props.userDash?.userCity?.value || "cn",
      localitylat: props.userDash?.userLocality?.llocality_lat || "000000",
      localitylong: props.userDash?.userLocality?.llocality_long || "000000",

      // cityname: "new delhi", // city name
      // dmrole: "DOC", // role
      // localityname: "Current Location",
      // localitylat: 28.5415026, // latitude
      // localitylong: 77.2934899, // longitude
      // specialityid: service?.id, // speciality id comming from getspeciality id
      queryoffset: 0, // query off set limit
      querylimit: 50, // query upper limit
      sortid: "-1", // default sorting id
      searchfilter: "-1", // default search filter
      dayshortname: "", // day name
      // service_id: "10053A",
      // ...props.userDash?.userLocality,
    };
    if (
      props.userDash?.selectedService?.role === "ONL" ||
      props.userDash?.selectedService?.role === "DOC"
    ) {
      data = { ...data, specialityid: service.id || "000" };
    } else {
      data = { ...data, service_id: service.id || "000" };
    }
    getDoctorsList(data);
  };
  const handleScreenSelect = (key) => {
    props.setSpeciality(key);
    setLoader(true);
    let data = {
      // localityname: props.userDash?.userLocality?.localityname,
      // specialityid: key.mom_id,
      // dmrole: props.userDash?.selectedService?.data?.Role,
      // cityname: props.userDash?.userState?.value,
      // localitylat: props.userDash?.userLocality?.locality_lat,
      // localitylong: props.userDash?.userLocality?.locality_long,

      localityname: props.userDash?.userLocality?.llocalityname || "llocality",
      // specialityid: service?.id || "000",
      // service_id: service?.id || "000",
      dmrole: props.userDash?.selectedService?.role || "VIT",
      cityname: props.userDash?.userState?.value || "cn",
      localitylat: props.userDash?.userLocality?.llocality_lat || "000000",
      localitylong: props.userDash?.userLocality?.llocality_long || "000000",

      specialityid: key?.mom_id,
      querylimit: "50",
      queryoffset: "0",
      sortid: "0",
      searchfilter: "0",
      mom_mental_health_flag: key?.mom_mental_health_flag,
      // ...props.userDash?.userLocality,
    };
    getDoctorsList(data);
  };
  return (
    <div>
      {loader ? <LoadingMask message="Loading Doctor..."></LoadingMask> : null}
      {props.selectedService.role === "VIT" ? (
        Object.keys(props.outcomePackages)?.map((key, i) => (
          <div
            key={i}
            onClick={() => handleScreenSelect(props.outcomePackages[key])}
          >
            <ScreeningCard screen={props.outcomePackages[key]}></ScreeningCard>
          </div>
        ))
      ) : (
        <div className="logo-card-container">
          {props.specialityList?.map((service, i) => (
            <span key={i} onClick={() => selectSpeciality(service)}>
              <LogoCard title={service.name} logo={<UserOutlined />}></LogoCard>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
    selectedService: state.userDash.selectedService,
    specialityList: state.userDash.specialityList,
    outcomePackages: state.userDash.outcomePackages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpeciality: (data) => dispatch(dashboardAC.setSpeciality(data)),
    setDoctorsList: (list) => dispatch(dashboardAC.setDoctorsList(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorTypesList);
