import React, { useEffect, useState } from "react";
import { Empty, Button, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import axios from "axios";
import { apis } from "../../shared/apis";
import ServiceAndRateCard from "./ServiceAndRateCard";
import AddServiceandRateForm from "./AddServiceandRateForm";
import CardHolderFrame from "../containers/CardHolderFrame";

const ServicesAndRatesList = (props) => {
  const [loader, setLoader] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [showServicesandRatesForm, setShowServicesandRatesForm] =
    useState(false);

  const [allServicesandRates, setAllServicesAndRates] = useState([]);
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/manage-clinic",
      pathName: "Manage Clinic",
    },
    {
      path: "/manage-services-and-rates",
      pathName: "Manage Services and Rates",
    },
  ];

  useEffect(() => {
    const fetchServicesandRates = async () => {
      setLoader(true);
      await axios({
        ...apis.serviceselected,
        data: {
          dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong!");
          setAllServicesAndRates(res.data.serviceinfo);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong!");
        });
    };
    fetchServicesandRates();
  }, [shouldUpdate]);

  return (
    <div className="container">
      <CardHolderFrame
        headStyle={cardHeaderStyle}
        title="Manage Services and Rates"
        showPath={true}
        routes={routes}
        loading={loader}
        extra={[
          <Button
            key={Math.random()}
            type="primary"
            shape="round"
            size="large"
            icon={
              <PlusOutlined
                style={{ display: "inline-flex", alignItems: "center" }}
              />
            }
            onClick={() => setShowServicesandRatesForm(true)}
          >
            Add Service
          </Button>,
        ]}
        {...props}
      >
        {allServicesandRates.length ? (
          allServicesandRates.map((service, i) => (
            <div key={i + Math.random()} className="mb-3">
              <ServiceAndRateCard
                {...service}
                updateList={() => setShouldUpdate(!shouldUpdate)}
              ></ServiceAndRateCard>
            </div>
          ))
        ) : (
          <Empty></Empty>
        )}
      </CardHolderFrame>
      <Modal
        centered
        visible={showServicesandRatesForm}
        onCancel={() => setShowServicesandRatesForm(false)}
        title={<div className="text-center">Add New Service</div>}
        footer={[]}
        destroyOnClose={true}
      >
        <AddServiceandRateForm
          closeForm={() => setShowServicesandRatesForm(false)}
          updateList={() => setShouldUpdate(!shouldUpdate)}
          allServicesandRates={allServicesandRates}
        />
      </Modal>
    </div>
  );
};

export default ServicesAndRatesList;
