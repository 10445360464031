export const styles = {
  normalText: {
    color: "grey",
  },
  cardSubTitle: {
    // alignSelf: 'left'
    // fontSize: 13
  },
  cardText: {
    color: "green",
    // fontSize: 13,
  },
  heading: {
    letterSpacing: 2,
    fontSize: 40,
    fontWeight: 500,
    marginTop: 80,
  },
  headText: {
    width: 800,
    textAlign: "center",
  },
  title: {
    fontSize: 20,
    color: "#646464",
    fontWeight: 700,
  },
  description: {
    fontSize: "1.15rem",
    color: "#4d4d4d",
    width: 300,
  },
};
