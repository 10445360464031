import React, { useState } from 'react'
import {
  Button,
  Form,
  FormGroup,
  Label,
  // Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from 'reactstrap'
import { Input, message } from 'antd'
// import {sha256} from 'crypto-js';
import '../../assets/styles/DoctorVerification.css'
import { styles } from '../../assets/styles/DoctorVerification'
import {
  setIsPartnerLoggedIn,
  setRolesList
} from '../../redux/ActionCreators/VerificationActionCreators'
import { connect } from 'react-redux'
// import { apis } from "../../shared/apis";
// import axios from "axios";
import Spinner from 'reactstrap/lib/Spinner'
import { Link, Redirect } from 'react-router-dom'
import { partnerFIRApp } from '../../firebase'
function PartnerLogin (props) {
  const [state, setState] = useState({
    email: localStorage.getItem('partnerEmail'),
    password: '',
    oldPassword: '',
    newPassword: '',
    rePassword: ''
  })
  const [showChangePass, setShowChangePass] = useState(false)
  const [showLoginError, setShowLoginError] = useState(false)
  const [showChangePassError, setshowChangePassError] = useState(false)
  const [loader, setLoader] = useState(false)
  let changePassMess = 'Your password is changed. Thank You.'
  const onChangeHandler = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const isEmailValid = email => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  // useEffect(() => {
  //   console.log("App: ", app);
  // }, []);

  const handleLogin = async () => {
    if (isEmailValid(state.email) && state.password) {
      setLoader(true)
      try {
        await partnerFIRApp
          .auth()
          .signInWithEmailAndPassword(state.email.trim(), state.password.trim())
          .then((res, err) => {
            // console.log("Res F login: ", res);
            // console.log("ERR: ", err);
            // console.log("user.ya:", res.user.ya);
            if (err) {
              // console.log("Err in Signing: ", err);
              setLoader(false)
              setShowLoginError(true)
              props.setIsPartnerLoggedIn(false)
            } else if (res?.user?.emailVerified === false) {
              message.error('Please Verify Your Email First')
              setLoader(false)
            } else {
              setLoader(false)
              setShowLoginError(false)
              props.setIsPartnerLoggedIn(true)
              localStorage.setItem('partnerEmail', state.email)
              localStorage.setItem('token', res?.user?.ya)
              props.history.push('/roles-list')
            }
          })
      } catch (err) {
        if (err) {
          setShowLoginError(true)
          setLoader(false)
          setShowLoginError(true)
          props.setIsPartnerLoggedIn(false)
        }
        // console.log("Err in FIREBASE LOGIN: ", err);
      }
      // console.log("Calling api");
      // await axios({
      //   ...apis.partnerSignin,
      //   data: { email: state.email, version_code: "10" },
      // })
      //   .then((res, err) => {
      //     if (err) {
      //       console.log("Err in Signing: ", err);
      //       setLoader(false);
      //       setShowLoginError(true);
      //       props.setIsPartnerLoggedIn(false);
      //     } else {
      //       console.log("res: ", res);
      //       if (res.data.status.includes("INVALID")) {
      //         setLoader(false);
      //         setShowLoginError(true);
      //         props.setIsPartnerLoggedIn(false);
      //       } else {
      //         setLoader(false);
      //         setShowLoginError(false);
      //         props.setIsPartnerLoggedIn(true);
      //         localStorage.setItem("partnerEmail", state.email);
      //         props.history.push("/roles-list");
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     if (err) console.log("Err in siginn: ", err);
      //     setLoader(false);
      //     setShowLoginError(true);
      //     props.setIsPartnerLoggedIn(false);
      //   });
    }
    if (!isEmailValid(state.email)) {
      setShowLoginError(true)
      props.setIsPartnerLoggedIn(false)
    }
  }
  const changeUserPass = () => {
    if (state.newPassWord === state.rePassWord) {
      // this.props.changePass({
      //   empmailid: this.email.value,
      //   emppassword: sha256(this.new_pass.value).toString(),
      //   empoldpassword: sha256(this.old_pass.value).toString(),
      // });
    } else changePassMess = 'Password Mismatch'
    toggleChangePass()
  }
  const showChangePassToggler = () => {
    setShowChangePass(!showChangePass)
  }
  const togglefieldLogin = () => {
    setShowLoginError(!showLoginError)
  }
  const toggleChangePass = () => {
    setshowChangePassError(!showChangePassError)
  }
  if (
    localStorage.getItem('partnerEmail') &&
    props.partnerData.isPartnerLoggedIn
  )
    return <Redirect to='/roles-list'></Redirect>
  return (
    //     redirect ? (
    //     <Redirect to="/roles-list"></Redirect>
    //   ) : (
    <div>
      <div className='container'>
        <Modal isOpen={showLoginError} toggle={togglefieldLogin}>
          <ModalHeader toggle={togglefieldLogin}> Error </ModalHeader>
          <ModalBody> Email or Password is Incorrect </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={togglefieldLogin}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showChangePassError} toggle={toggleChangePass}>
          <ModalHeader toggle={toggleChangePass}>Information</ModalHeader>
          <ModalBody> {changePassMess} </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={toggleChangePass}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Form
          onSubmit={e => e.preventDefault()}
          className='col-sm-6'
          style={{ margin: 'auto' }}
        >
          <Card style={{ marginTop: 120 }}>
            <CardHeader
              className='bg-primary text-light text-center'
              style={styles.cardHeader}
            >
              Partner Login
            </CardHeader>
            <CardBody className='card1body'>
              <FormGroup row>
                <div className='col-md-2' style={styles.card1Label}>
                  <Label for='email'> Email </Label>
                </div>
                <Col>
                  <Input
                    style={styles.card1Input}
                    type='email'
                    name='email'
                    id='email'
                    placeholder='example@domain.com'
                    onChange={onChangeHandler}
                    value={state.email || ''}
                    size='large'
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className='col-md-2' style={styles.card1Label}>
                  <Label for='pass'> Password </Label>
                </div>
                <Col>
                  <Input.Password
                    style={styles.card1Input}
                    type='password'
                    name='password'
                    id='pass'
                    autoComplete='current-password'
                    placeholder='Password'
                    onChange={onChangeHandler}
                    value={state.password || ''}
                    size='large'
                    required
                  />
                </Col>
              </FormGroup>
              <div
                className='container d-flex'
                style={{ alignItems: 'center' }}
              >
                <Link
                  style={{ color: '#007bff' }}
                  to='/parner-login'
                  onClick={e => {
                    e.preventDefault()
                    props?.history?.push('/forgot-password/partner')
                    // console.log("Forgot Password");
                  }}
                >
                  Forgot Password ?
                </Link>
                <Button
                  style={{ marginTop: 20, margin: 'auto' }}
                  className='d-flex'
                  color='primary'
                  type='submit'
                  value='submit'
                  onClick={handleLogin}
                >
                  {loader ? (
                    <span>
                      <Spinner size='sm' className='mx-2'></Spinner>Loggin in...
                    </span>
                  ) : (
                    'Login'
                  )}
                </Button>
                <Link to='/partner-signup'>Register</Link>
                {/* <a
                  href="https://play.google.com/store/apps/details?id=com.janelaaj&hl=en"
                  target="__blank"
                >
                  Register
                </a> */}
              </div>
              <Button
                style={{ marginLeft: 1, marginTop: 20, display: 'none' }}
                className='ml-5'
                color='primary'
                value='submit'
                onClick={showChangePassToggler}
              >
                Change Password
              </Button>
            </CardBody>
          </Card>
        </Form>
        <Modal isOpen={showChangePass} toggle={showChangePassToggler}>
          <ModalHeader toggle={showChangePassToggler}>
            Change Password
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <div className='col-md-5 mt-4' style={styles.card2Label}>
                  <Label for='old-pass'> Old Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    className='mt-4'
                    type='text'
                    name='oldPassword'
                    id='old-pass'
                    placeholder='Old Password'
                    onChange={onChangeHandler}
                    value={state.oldPassword}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className='col-md-5' style={styles.card2Label}>
                  <Label for='re-pass'> New Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    type='text'
                    name='newPassword'
                    id='new-pass'
                    onChange={onChangeHandler}
                    value={state.newPassword}
                    placeholder='New Password'
                    minLength='8'
                    required
                    pattern='/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/'
                    title='password must be 8 to 15 characters long including at least one lowercase letter, one uppercase letter, one numeric digit
                                           and one special character'
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className='col-md-5' style={styles.card2Label}>
                  <Label for='re-pass'> Retype New Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    type='text'
                    name='rePassword'
                    id='re-pass'
                    onChange={onChangeHandler}
                    value={state.rePassword}
                    placeholder='Retype New Password'
                  />
                </Col>
              </FormGroup>
              <Button
                color='primary'
                className='ml-4 mb-4'
                onClick={changeUserPass}
              >
                Save
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    partnerData: state.partnerData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setIsPartnerLoggedIn: flag => dispatch(setIsPartnerLoggedIn(flag)),
    setPartnerRoles: obj => dispatch(setRolesList(obj))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerLogin)
