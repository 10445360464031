import React from "react";
import { Route, Switch } from "react-router-dom";
import PreventiveCare from "./PreventiveCare";
import AppointmentList from "./AppointmentList";
import PartnerLogin from "./verification/PartnerLogin";
import PartnerRoles from "./intermediate/PartnerRoles";
import PartnerClinicList from "./intermediate/PartnerClinicList";
import UserLogin from "./verification/UserLogin";
import PageNotFound from "./PageNotFound";
import Contact from "./ContactComponent";
import DashBoard from "./userDashBoard/DashBoard";
import JitsiVideoCallWindow from "./JitsiVideoCallWindow";
// import Dictaphone from "./Dictaphone";
// import PartnerOptionsList from "./PartnerOptionsList";
import PartnerReportPaymentList from "./PartnerReportPaymentList";
import PartnerSignup from "./verification/PartnerSignup";
import HelpACause from "./HelpACause";
import PMSMSForm from "./PMSMSForm";
import GiftFormHAC from "./GiftFormHAC";
import VolunteerrForm from "./VolunteerrForm";
import ManageLocation from "./intermediate/ManageLocation";
import AllTimingsList from "./intermediate/AllTimingsList";
import ServicesAndRatesList from "./intermediate/ServicesAndRatesList";
import ManageDiscountsList from "./intermediate/ManageDiscountsList";
import EditLoactionForm from "./intermediate/EditLoactionForm";
import UploadDocumentsForm from "./intermediate/UploadDocumentsForm";
import MySubscriptionsList from "./MySubscriptionsList";
import DocProfile from "./DocProfileForm";
import PaymentSuccessPage from "./PaymentSuccessPage";
import UserSignup from "./verification/UserSignup";
import EmployLogin from "./verification/EmployLogin";
import EmpDashboard from "./empDashboard/EmpDashboard";
import ReportsAndPayments from "./empDashboard/ReportsAndPayments";
import LandlngPage from "./common/LandlngPage";
import ForgotPassword from "./ForgotPassword";
import PartnerServicesDashboard from "./PartnerServicesDashboard";
import Razorpaypaymentcomplete from "./payment/RazorpayPaymentComplete";
import Razorpaypaymentfailed from "./payment/RazorpayPaymentFailed";
import Ourpolicies from "./policies/OurPolicies";
import Policycontainer from "./policies/PolicyContainer";
import Doctorslistpage from "./common/DoctorsListPage";
import Bodyvitalmeasurement from "./videoRecorder/BodyVitalMeasurement";
function Routes() {
  return (
    <Switch>
      <Route exact path="/help-a-cause" component={HelpACause}></Route>
      <Route exact path="/gift" component={GiftFormHAC}></Route>
      <Route exact path="/pmsma" component={PMSMSForm}></Route>
      <Route exact path="/volunteer" component={VolunteerrForm}></Route>
      <Route exact path="/preventive-care" component={PreventiveCare} />
      <Route exact path="/partner-login" component={PartnerLogin} />
      <Route
        exact
        path="/partner-login/:redirectUri"
        component={PartnerLogin}
      />
      <Route exact path="/partner-signup" component={PartnerSignup} />
      <Route exact path="/user-login" component={UserLogin} />
      <Route exact path="/user-login/:redirectUri" component={UserLogin} />
      <Route exact path="/user-signup/:phone/:token" component={UserSignup} />
      <Route exact path="/emp-login" component={EmployLogin} />
      <Route exact path="/emp-login/:id" component={EmployLogin} />
      <Route exact path="/emp-dashboard" component={EmpDashboard} />
      <Route exact path="/emp-reports" component={ReportsAndPayments}></Route>
      <Route exact path="/roles-list" component={PartnerRoles}></Route>
      <Route
        exact
        path="/choose-location-list"
        component={PartnerClinicList}
      ></Route>
      <Route exact path="/manage-clinic" component={ManageLocation}></Route>
      <Route exact path="/manage-timings" component={AllTimingsList}></Route>
      <Route
        exact
        path="/manage-services-and-rates"
        component={ServicesAndRatesList}
      ></Route>
      <Route
        exact
        path="/manage-discounts"
        component={ManageDiscountsList}
      ></Route>
      <Route exact path="/edit-clinic" component={EditLoactionForm}></Route>
      <Route
        exact
        path="/upload-documents"
        component={UploadDocumentsForm}
      ></Route>
      <Route
        exact
        path="/choose-option"
        component={PartnerServicesDashboard}
      ></Route>
      <Route
        exact
        path="/choose-option/:tabKey"
        component={PartnerServicesDashboard}
      ></Route>
      {/* <Route exact path="/choose-option" component={PartnerOptionsList}></Route> */}
      <Route exact path="/doc-profile" component={DocProfile}></Route>
      <Route
        exact
        path="/my-subscription"
        component={MySubscriptionsList}
      ></Route>
      <Route exact path="/appointment-list" component={AppointmentList}></Route>
      <Route
        exact
        path="/reports-payments-list"
        component={PartnerReportPaymentList}
      ></Route>
      <Route
        exact
        path="/paytm-payment-result"
        component={PaymentSuccessPage}
      ></Route>

      <Route
        exact
        path="/razorpay-payment-complete"
        component={Razorpaypaymentcomplete}
      ></Route>
      <Route
        exact
        path="/razorpay-payment-failed"
        component={Razorpaypaymentfailed}
      ></Route>

      <Route exact path="/contact-us" component={Contact} />
      <Route
        exact
        path="/dashboard"
        component={
          localStorage.getItem("partnerEmail")
            ? PartnerRoles
            : localStorage.getItem("userEmail")
            ? DashBoard
            : EmpDashboard
        }
      ></Route>
      <Route
        exact
        path="/jitsi/:pdlaid/:roomName/:displayName"
        component={JitsiVideoCallWindow}
      ></Route>
      <Route
        exact
        path="/lp/:dmrole/:cityname/:localityname/:speciality"
        component={LandlngPage}
      ></Route>
      {/* <Route exact path="/lp1" component={LandlngPage}></Route> */}
      {/* <Route exact path="/dictaphone" component={Dictaphone}></Route> */}

      <Route
        exact
        path="/forgot-password/:type"
        component={ForgotPassword}
      ></Route>
      <Route exact path="/our-policies" component={Ourpolicies}></Route>
      <Route
        exact
        path="/policy/:for/:cKey"
        component={Policycontainer}
      ></Route>
      <Route
        exact
        path={"/doctors-list-result-page/:data"}
        component={Doctorslistpage}
      />
      <Route exact path="/bvm" component={Bodyvitalmeasurement} />
      <Route to="/pagenotfound" component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
