import React, { Component } from "react";
import { VerificationTitle } from "../HelperComponent";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ImageZoom from "react-medium-image-zoom";
import moment from "moment";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { styles } from "../../assets/styles/DoctorVerification";
import "../../assets/styles/DoctorVerification.css";
import { NavLink } from "react-router-dom";
import DoctorDetailsCard from "../userDashBoard/DoctorDetailsCard";

let loc;

class LocationVerification extends Component {
  constructor(props) {
    super(props);
    let location = Object.values(Object.assign({}, props.locinfo.locations));
    let loc1 = location.filter((loc) => loc.lid === props.location.state.lid);
    loc =
      props.location.state.loc ||
      Object.assign({}, Object.values(Object.assign({}, loc1))[0]);
    this.state = {
      verifyFields: false,
      verifyLoc: false,
      name: loc.llocname_verifyflag,
      add: loc.llocaddress_verifyflag,
      timings: loc.lloctimings_verifyflag,
      services: loc.llocservices_verifyflag,
      accr: loc.llocaccreditation_verifyflag,
      verify: loc.llocverification_flag,
      cord: loc.lloclatlongverificationflag,
      comments: loc.llocverifycomments,
      coordinates: {
        lat: loc.lgprslat,
        long: loc.lgprslong,
      },
    };
    this.saveLocInfo = this.saveLocInfo.bind(this);
    this.onRadioInputChange = this.onRadioInputChange.bind(this);
    this.toggleLocModal = this.toggleLocModal.bind(this);
  }
  saveLocInfo() {
    this.props.postLocInfo({
      lm_locname_verifyflag: this.state.name,
      lm_locaddress_verifyflag: this.state.add,
      lm_loctimings_verifyflag: this.state.timings,
      lm_locservices_verifyflag: this.state.services,
      lm_locaccreditation_verifyflag: this.state.accr,
      lm_loclatlong_verifyflag: this.state.cord,
      lm_locverification_flag: this.state.verify,
      lm_locverification_comments: this.state.comments,
      lm_gps_tracker_lat: this.state.coordinates.lat,
      lm_gps_tracker_long: this.state.coordinates.long,
      locid: loc.lid,
    });
    this.toggleLocModal();
    this.setState({ verifyFields: !this.state.verifyFields });
  }
  onRadioInputChange(event) {
    this.setState({ verifyFields: true });
    if (event.target.name === "loc-name") {
      this.setState({ name: event.target.value });
      loc.llocname_verifyflag = null;
    } else if (event.target.name === "loc-add") {
      this.setState({ add: event.target.value });
      loc.llocaddress_verifyflag = null;
    } else if (event.target.name === "loc-timings") {
      this.setState({ timings: event.target.value });
      loc.lloctimings_verifyflag = null;
    } else if (event.target.name === "loc-services") {
      this.setState({ services: event.target.value });
      loc.llocservices_verifyflag = null;
    } else if (event.target.name === "loc-accr") {
      this.setState({ accr: event.target.value });
      loc.llocaccreditation_verifyflag = null;
    } else if (event.target.name === "loc-cord") {
      this.setState({ cord: event.target.value });
      loc.lloclatlongverificationflag = null;
    } else if (event.target.name === "loc-verify") {
      this.setState({ verify: event.target.value });
      loc.llocverification_flag = null;
    }
  }
  toggleLocModal() {
    this.setState({ verifyLoc: !this.state.verifyLoc });
  }
  submitComment = (event) => {
    loc.llocverifycomments = null;
    if (!this.state.verifyFields)
      this.setState({
        verifyFields: !this.state.verifyFields,
      });
    this.setState({
      comments: event.target.value,
    });
  };
  render() {
    let { verifyFields } = this.state.verifyFields;
    let handleSuccess = (position) => {
      this.setState({
        coordinates: {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        },
      });
    };
    let handleError = (error) => {
      switch (error.code) {
        case 1:
          alert("Denied to fetch current location");
          break;
        case 2:
          alert("Unable to fetch current location");
          break;
        case 3:
          alert("Timeout");
          break;
        default:
          alert("Unknown Error");
      }
    };
    let getLoc = () => {
      if (!loc.lgprslat || !loc.lgprslong) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
            enableHighAccuracy: true,
          });
          if (!this.state.verifyFields)
            this.setState({
              verifyFields: !this.state.verifyFields,
            });
        } else alert("Geolocation is not supported by your browser");
        /*
                                Geocode.setApiKey(geoCodeApiKey);
                                Geocode.enableDebug();
                                Geocode.fromAddress(loc.ladrline1 + ', ' + loc.lcity + ', ' + loc.lstate + ', (' + loc.lpincode + ')')
                                    .then(response => {
                                            this.setState({
                                                coordinates: {
                                                    lat: response.results[0].geometry.location.lat,
                                                    long: response.results[0].geometry.location.lng
                                                },
                                                verifyFields: !this.state.verifyFields
                                            });
                                            loc.lgprslat = this.state.coordinates.lat;
                                            loc.lgprslong = this.state.coordinates.long;
                                        },
                                        error => {
                                            alert("Unable to find the coordinates ");
                                            console.log("Error occured while getting coordinates, ", error);
                                            this.setState({ verifyFields: false });

                                        })
                */
      }
    };
    const RenderImage = (props) => {
      // if (props.img)
      if (props.img)
        return (
          <ImageZoom
            image={{
              src: `data:image/jpeg;base64,${props.img}`,
              alt: props.alt,
              style: { width: "60px" },
            }}
            zoomImage={{
              src: `data:image/jpeg;base64,${props.img}`,
              alt: props.alt,
            }}
          />
        );
      else return null;
    };
    if (!Object.keys(loc).length) {
      loc.lname = "Name";
      loc.ladrline1 = "Address";
      loc.lcity = "City";
      loc.lstate = "State";
      loc.lpincode = "PIN Code";
      loc.lgprslat = "Latitude";
      loc.lgprslong = "Longitude";
    }
    return (
      <div className="container">
        <Modal isOpen={this.state.verifyLoc} toggle={this.toggleLocModal}>
          <ModalHeader toggle={this.toggleLocModal}> Information </ModalHeader>
          <ModalBody> Your response has been recorded. Thank You. </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleLocModal}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Card style={{ marginTop: 120 }}>
          <CardHeader className="head-xl fc" style={styles.cardHeader}>
            Location Verification
            <VerificationTitle />
          </CardHeader>
          <CardBody className="card1body">
            <Form onSubmit={this.handleSearch}>
              <FormGroup row>
                <div className="col-md-3" style={styles.card1Label}>
                  <Label for="loc"> Location Name </Label>
                </div>
                <Col xs={7} md={4} lg={3}>
                  <Input
                    style={styles.card1Input}
                    type="text"
                    name="loc"
                    id="loc"
                    placeholder="Location Name"
                    innerRef={(input) => (this.loc = input)}
                  />
                </Col>
                <Col md={2}>
                  <Button
                    color="info"
                    type="submit"
                    value="submit"
                    className="mt-sm-4 mt-lg-0"
                  >
                    Search
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="card2body">
            <Form>
              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Name </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={loc.lname}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="loc-name"
                      checked={loc.llocname_verifyflag === "1" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="loc-name"
                      checked={loc.llocname_verifyflag === "2" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="loc-name"
                      checked={loc.llocname_verifyflag === "3" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="address"> Address </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={loc.ladrline1}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="city"> City </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="city"
                    id="city"
                    placeholder="City"
                    value={loc.lcity}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="loc-add"
                      checked={
                        loc.llocaddress_verifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="loc-add"
                      checked={
                        loc.llocaddress_verifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="loc-add"
                      checked={
                        loc.llocaddress_verifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> State </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={loc.lstate}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="pin"> PIN </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="pin"
                    id="pin"
                    placeholder="PIN"
                    value={loc.lpincode}
                  />
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="card4body">
            <Form>
              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Timings </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Timings"
                    value={loc.lname}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="loc-timings"
                      checked={
                        loc.lloctimings_verifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="loc-timings"
                      checked={
                        loc.lloctimings_verifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="loc-timings"
                      checked={
                        loc.lloctimings_verifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>

              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Services </Label>
                </div>
                <Col xs={7} md={5} lg={3}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Services"
                    value={loc.lname}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <Label md={3} lg={3}>
                    Verified
                  </Label>
                  <Label md={2} lg={2}>
                    <Input
                      type="radio"
                      name="loc-services"
                      checked={
                        loc.llocservices_verifyflag === "1" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3}>
                    <Input
                      type="radio"
                      name="loc-services"
                      checked={
                        loc.llocservices_verifyflag === "2" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4}>
                    <Input
                      type="radio"
                      name="loc-services"
                      checked={
                        loc.llocservices_verifyflag === "3" || verifyFields
                      }
                      onChange={this.onRadioInputChange}
                      value={"3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        {loc?.dlmid ? (
          <Card className="bg-light">
            <div>
              <DoctorDetailsCard
                doc={{
                  ldlm_id: loc?.dlmid,
                  ldoctorid: loc?.did,
                }}
                reqOrigin={"partnerApp"}
              ></DoctorDetailsCard>
            </div>
          </Card>
        ) : (
          "null"
        )}
        <Card>
          <CardBody className="card4body">
            <FormGroup row>
              <Col md={8} className="ml-3">
                <Label md={2} check>
                  <Input
                    type="checkbox"
                    name="info-verify"
                    checked={loc.lisoflag === "Y"}
                  />
                  ISO
                  <RenderImage img={loc.lisoimage} alt="ISO Image" />
                </Label>
                <Label md={3} check>
                  <Input
                    type="checkbox"
                    name="info-verify"
                    checked={loc.lnablflag === "Y"}
                  />
                  NABL
                  <RenderImage img={loc.lnablimage} alt="NABL Image" />
                </Label>
                <Label md={4}>
                  <Input
                    type="checkbox"
                    name="info-verify"
                    checked={loc.lcapflag === "Y"}
                  />
                  CAP
                  <RenderImage img={loc.lcapimage} alt="CAP Image" />
                </Label>
              </Col>
              <Col md={12} lg={6} className="ml-1">
                <Label md={3} lg={3}>
                  Verified
                </Label>
                <Label md={2} lg={2}>
                  <Input
                    type="radio"
                    name="loc-accr"
                    checked={
                      loc.llocaccreditation_verifyflag === "1" || verifyFields
                    }
                    onChange={this.onRadioInputChange}
                    value={"1"}
                  />
                  ok
                </Label>
                <Label md={3} lg={3}>
                  <Input
                    type="radio"
                    name="loc-accr"
                    checked={
                      loc.llocaccreditation_verifyflag === "2" || verifyFields
                    }
                    onChange={this.onRadioInputChange}
                    value={"2"}
                  />
                  Reject
                </Label>
                <Label md={4} lg={4}>
                  <Input
                    type="radio"
                    name="loc-accr"
                    checked={
                      loc.llocaccreditation_verifyflag === "3" || verifyFields
                    }
                    onChange={this.onRadioInputChange}
                    value={"3"}
                  />
                  Need more info
                </Label>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>

        <Card>
          <CardBody className="card3body">
            <FormGroup row>
              <Col md={5}>
                <Row>
                  <Col md={5} lg={5}>
                    <Button className="mt-4 mt-lg-5 ml-3" onClick={getLoc}>
                      Get Location Coordinates
                    </Button>
                  </Col>
                  <Col xs={7}>
                    <Input
                      className="ml-md-5 mt-4 col-lg-6"
                      // disabled
                      style={styles.card2Input}
                      type="text"
                      name="lat"
                      placeholder="Latitude"
                      value={this.state.coordinates.lat}
                      onChange={(e) => {
                        this.setState({
                          coordinates: {
                            ...this.state.coordinates,
                            lat: e.target.value,
                          },
                        });
                      }}
                    />
                    <Input
                      className="ml-md-5 mt-4 col-lg-6"
                      // disabled
                      style={styles.card2Input}
                      type="text"
                      name="lon"
                      placeholder="Longitude"
                      value={this.state.coordinates.long}
                      onChange={(e) => {
                        this.setState({
                          coordinates: {
                            ...this.state.coordinates,
                            long: e.target.value,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={6} className="mt-md-5">
                <Label md={3} lg={3}>
                  Verified
                </Label>
                <Label md={2} lg={2}>
                  <Input
                    type="radio"
                    name="loc-cord"
                    checked={
                      loc.lloclatlongverificationflag === "1" || verifyFields
                    }
                    onChange={this.onRadioInputChange}
                    value={"1"}
                  />
                  ok
                </Label>
                <Label md={3} lg={3}>
                  <Input
                    type="radio"
                    name="loc-cord"
                    checked={
                      loc.lloclatlongverificationflag === "2" || verifyFields
                    }
                    onChange={this.onRadioInputChange}
                    value={"2"}
                  />
                  Reject
                </Label>
                <Label md={4} lg={4}>
                  <Input
                    type="radio"
                    name="loc-cord"
                    checked={
                      loc.lloclatlongverificationflag === "3" || verifyFields
                    }
                    onChange={this.onRadioInputChange}
                    value={"3"}
                  />
                  Need more info
                </Label>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="card3body">
            <FormGroup row>
              <Col md={12}>
                <Label md={4}> Location Verified </Label>
                <Label md={2}>
                  <Input
                    type="radio"
                    name="loc-verify"
                    checked={loc.llocverification_flag === "1" || verifyFields}
                    onChange={this.onRadioInputChange}
                    value={"1"}
                  />
                  ok
                </Label>
                <Label md={2}>
                  <Input
                    type="radio"
                    name="loc-verify"
                    checked={loc.llocverification_flag === "2" || verifyFields}
                    onChange={this.onRadioInputChange}
                    value={"2"}
                  />
                  Reject
                </Label>
                <Label md={4}>
                  <Input
                    type="radio"
                    name="loc-verify"
                    checked={loc.llocverification_flag === "3" || verifyFields}
                    onChange={this.onRadioInputChange}
                    value={"3"}
                  />
                  Waiting for more info
                </Label>
              </Col>
              <Col xs={7} md={6}>
                <Input
                  style={styles.card3Input}
                  type="text"
                  name="comments"
                  id="comments"
                  placeholder="Comments"
                  value={loc.llocverifycomments}
                  onChange={this.submitComment.bind(this)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={5} check style={{ marginLeft: 20 }}>
                Is Location Live
                {loc.llocliveflag === "Y" ? (
                  <i className="fas fa-check ml-2" style={{ color: "green" }} />
                ) : (
                  <i
                    className="fas fa-times ml-2"
                    style={{ color: "#ab3228" }}
                  />
                )}
              </Label>
              <div className="col-md-2" style={styles.card2Label}>
                <Label for="live-date"> Live Date </Label>
              </div>
              <Col xs={8} md={2}>
                <Input
                  disabled
                  style={styles.card2Input}
                  type="text"
                  name="date"
                  id="date"
                  placeholder="Live Date"
                  value={
                    loc.llocliveflagdate
                      ? moment(loc.llocliveflagdate).format("DD-MMM-YYYY")
                      : null
                  }
                />
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <NavLink className="unstyled-link" to="/verifyDoctor/">
          <Button color="danger" style={{ marginTop: 20 }}>
            Cancel
          </Button>
        </NavLink>
        <Button
          color="primary"
          disabled={!this.state.verifyFields}
          style={{ marginLeft: 20, marginTop: 20 }}
          onClick={this.saveLocInfo}
        >
          Save
        </Button>
        {/* <Link to={"/goLiveVerification/"}> */}
        <Button
          color="warning"
          className="ml-md-4"
          style={{ marginTop: 20, textDecoration: "none", color: "white" }}
          onClick={() => (window.location = "/goLiveVerification")}
        >
          Go Live
        </Button>
        {/* </Link> */}
      </div>
    );
  }
}

export default LocationVerification;
