import React, { useState } from "react";
import { Button, Card, Input, Modal, Form, message, Result } from "antd";
import "../assets/styles/helpacause.css";
import axios from "axios";
import { apis } from "../shared/apis";
// import GiftFormHAC from "./GiftFormHAC";
// import PMSMSForm from "./PMSMSForm";
// import { Link } from "react-router-dom";
const HelpACause = () => {
  // const [showGiftForm, setShowGiftForm] = useState(false);
  // const [showJoinForm, setShowJoinForm] = useState(false);
  // const [showVolunteerForm, setShowVolunteerForm] = useState(false);
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const giftSub = "Gift for the under privileged with basic primary healthcare";
  const pmsmaSub =
    "An Obstetrician / Radiologist / Physician ready to volunteer";
  const volSub = "Support for the under privileged";
  const subMap = {
    gift: giftSub,
    pmsma: pmsmaSub,
    volunteer: volSub,
  };
  const handleForm = (type) => {
    // setSub(subMap[type]);
    form.setFieldsValue({ sub: subMap[type] });
    setShowForm(true);
  };
  const onFinish = async (values) => {
    setLoader(true);
    await axios({
      ...apis.postSendEmail,
      data: {
        ...values,
        message: `Subject : ${values.sub} /n Company Name: ${values.cname}`,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
        else {
          message.success("Email Successfully Sent!");
          setIsSent(true);
          // form.resetFields();
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  return (
    <div
      className="container-fluid"
      style={{
        display: "flex",
        justifyContent: "space-around",
        // alignItems: "space-around",
        // alignContent: "space-around",
        // flexDirection: "column",
      }}
    >
      <div className="row mx-0 bg-light my-4 text-center py-4 hac-container">
        <div className="container-fluid hac-main-heading">Help A Cause</div>
        <div className="col-sm-4 my-1">
          <Card
            id="gift"
            hoverable
            className="bg-danger"
            title={
              <div className="hac-heading">
                <b>Gift</b> Now
              </div>
            }
            bordered={false}
            headStyle={{ border: "none" }}
            actions={[
              <div className="hac-button">
                {/* <Link to="/gift"> */}
                <Button
                  type="danger"
                  shape="round"
                  size="large"
                  onClick={() => handleForm("gift")}
                >
                  Gift Now
                </Button>
                {/* </Link> */}
              </div>,
            ]}
          >
            <div className="hac-description">
              {/* Your gift will help make a tremendous difference.<br></br>
              Minimum donation starts at ₹100 */}
              Gift the under privileged with basic primary healthcare . Start a
              CSR initiative.
            </div>
            {/* <div style={{ height: "38px" }}></div> */}
          </Card>
        </div>
        <div className="col-sm-4 my-1">
          <Card
            id="pmsms"
            hoverable
            className="bg-success"
            title={
              <div className="hac-heading">
                Join{" "}
                <span className="font-weight-bold text-warning">PMSMA</span>
              </div>
            }
            bordered={false}
            headStyle={{ border: "none" }}
            style={{}}
            actions={[
              <div className="hac-button">
                {/* <Link to="/pmsma"> */}
                <Button
                  shape="round"
                  size="large"
                  type="primary"
                  style={{ border: "none", backgroundColor: "#39B856" }}
                  // onClick={() => setShowJoinForm(true)}
                  onClick={() => handleForm("pmsma")}
                >
                  Join Now
                </Button>
                {/* </Link> */}
              </div>,
            ]}
          >
            <div className="hac-description">
              Are you an Obstetrician / Radiologist / Physician ready to
              volunteer?
              {/* <br></br> */}
              {/* Register yourself for PRADHAN MANTRI SURAKSHIT MATRITVA ABHIYAN */}
            </div>
            <div style={{ height: "38px" }}></div>
          </Card>
        </div>
        <div className="col-sm-4 my-1">
          <Card
            id="volunteer"
            hoverable
            className="bg-primary"
            title={
              <div className="hac-heading">
                <b>Volunteer</b> Consultation<br></br>
                Win-A-Smile
              </div>
            }
            bordered={false}
            headStyle={{ border: "none" }}
            actions={[
              <div className="hac-button">
                {/* <Link to="/volunteer"> */}
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  // onClick={() => setShowVolunteerForm(true)}
                  onClick={() => handleForm("volunteer")}
                >
                  Volunteer
                </Button>
                {/* </Link> */}
              </div>,
            ]}
          >
            <div className="hac-description">
              Your Support will help the under privileged.
            </div>
            <div style={{ height: "25px" }}></div>
          </Card>
        </div>
      </div>
      {/* <Modal
        visible={showGiftForm}
        centered
        title={<div>Thank You for your contribution</div>}
        onCancel={() => setShowGiftForm(false)}
        footer={[]}
        bodyStyle={{ paddingTop: "0", paddingBottom: "0" }}
      >
        <GiftFormHAC></GiftFormHAC>
      </Modal>
      <Modal
        visible={showJoinForm}
        centered
        title={<div>Volunteer Registration</div>}
        onCancel={() => setShowJoinForm(false)}
        footer={[]}
        bodyStyle={{ paddingTop: "0", paddingBottom: "0" }}
      >
        <PMSMSForm></PMSMSForm>
      </Modal>
      <Modal
        visible={showVolunteerForm}
        centered
        title={<div>Volunteer Registration</div>}
        onCancel={() => setShowVolunteerForm(false)}
        footer={[]}
        bodyStyle={{ paddingTop: "0", paddingBottom: "0" }}
      >
        <PMSMSForm></PMSMSForm>
      </Modal> */}
      <Modal
        visible={showForm}
        onCancel={() => {
          setIsSent(false);
          setShowForm(false);
        }}
        title={<div>Enter Your Details</div>}
        footer={[]}
        centered
        // destroyOnClose
      >
        {isSent ? (
          <Result
            status="success"
            title="Thank You. Your details have been submitted Succesfully"
            subTitle={
              <div style={{ fontSize: "1.5rem" }}>
                An executive from JanElaaj will contact you soon.
              </div>
            }
          ></Result>
        ) : (
          <Form form={form} size="large" onFinish={onFinish}>
            <Form.Item
              name="sub"
              // rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input
                allowClear
                placeholder="Enter Subject "
                size="large"
              ></Input>
            </Form.Item>
            <Form.Item
              name="Name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input
                allowClear
                placeholder="Enter Your Name "
                size="large"
              ></Input>
            </Form.Item>
            <Form.Item
              name="cname"
              rules={[
                { required: true, message: "Please enter your company name" },
              ]}
            >
              <Input
                allowClear
                placeholder="Enter Your Company's Name "
                size="large"
              ></Input>
            </Form.Item>
            <Form.Item
              name="Email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input
                allowClear
                placeholder="Enter Your Email "
                size="large"
              ></Input>
            </Form.Item>
            <Form.Item
              name="Contact"
              rules={[{ required: true, message: "Please enter your phone" }]}
            >
              <Input
                allowClear
                placeholder="Enter Your Phone Number"
                size="large"
              ></Input>
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                loading={loader}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default HelpACause;
