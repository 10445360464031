import React, { Component } from "react";
import Header from "./common/HeaderComponent";
import About from "./AboutComponent";
import Services from "./ServicesComponent";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { SharedServices } from "../shared/sharedServices";
import { SharedFeatures } from "../shared/sharedFeatures";
import DoctorVerification from "./verification/DoctorVerificationComponent";
import LocationVerification from "./verification/LocationVerificationComponent";
import GoLiveVerification from "./verification/GoLiveVerificationComponent";
import Contact from "./ContactComponent";
import SearchServices from "./common/SearchServices";
// import AppScreen from "./AppScreen";
import HashTags from "./HashTags";

// import { FirebaseDatabaseNode } from '@react-firebase/database'
import {
  fetchDocInfo,
  fetchLocInfo,
  fetchDocsInfo,
  fetchRoles,
  postDocInfo,
  postLocInfo,
  postDocsInfo,
  loginUser,
  changePass,
} from "../redux/ActionCreators/VerificationActionCreators";
import {
  fetchCities,
  fetchLocalities,
  fetchSpecialities,
  getDocs,
  getDoc,
  setHashTags,
  setExpertTip,
  setOneMin,
  setAllPosts,
  setMyPosts,
} from "../redux/ActionCreators/HomeActionCreators";
import DocProfile from "./intermediate/DocProfile";
import Routes from "./Routes";
// import BookAnAppointmentForm from "./BookAnAppointmentForm";
import IncubatersList from "./IncubatersList";
import ImageSlider from "./ImageSlider";
import { isBrowser } from "react-device-detect";
import MobileImageSlider from "./MobileImageSlider";
import TodaysSpecial from "./TodaysSpecial";
import app from "../firebase";
import axios from "axios";
import { apis } from "../shared/apis";
import { message } from "antd";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocModalOpen: false,
      docemail: null,
      docrole: null,
      empLogin: false,
      userLogin: false,
      partnerLogin: false,
      searchService: "DOC",
      goLiveEmail: null,
      goLiveRole: null,
      isDocModalOpen: false,
      showLinks: true,
      search: true,
      tip: "Loading...",
      oneMin: "",
      categories: "",
      expertTip: "",
      allPosts: {},
      myPosts: {},
      tdsCardList: [],
      tdsLoading: true,
    };
    this.toggleLocModal = this.toggleLocModal.bind(this);
  }
  toggleLocModal() {
    this.setState({ isLocModalOpen: !this.state.isLocModalOpen });
  }

  formatList = (info, info2) => {
    let list = {};
    info?.forEach((inf) => {
      //   list[inf?.ts_option_id] = {};
      list[inf?.ts_option_id] = { ...inf };
    });
    info2?.forEach((inf) => {
      //   let obj = { [inf?.ts_option_id]: { ...inf } };
      //   list.push(obj);
      list[inf?.ts_option_id] = { ...list[inf?.ts_option_id], ...inf };
    });
    return Object.values(list);
  };

  fetchTip = async () => {
    await axios({ ...apis.getNewsOfTheDay })
      .then((res, err) => {
        if (err) {
          message.error("Something Went Wrong");
        } else {
          let t = "";
          res.data?.news.forEach((str, i) => {
            t += str + " | ";
          });
          this.setState({ tip: t });
        }
      })
      .catch((err) => {
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  fetchFS = async () => {
    this.setState({ tdsLoading: true });
    await axios({ ...apis.getDoctorsForFirstScreen })
      .then((res, err) => {
        this.setState({ tdsLoading: false });
        // setLoader(false)
        if (err) {
          message.error("Something Went Wrong");
        } else {
          // console.log("res  ts: ", res.data);
          this.setState({
            tdsCardList: [...this.formatList(res.data?.info, res.data?.info2)],
          });
        }
      })
      .catch((err) => {
        this.setState({ tdsLoading: false });
        // setLoader(false)
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  componentDidMount() {
    this.fetchTip();
    this.fetchFS();
    let catlist = [];
    app
      .database()
      .ref("Categories")
      .on("value", (snapshot) => {
        snapshot.forEach((snap) => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
          catlist.push(snap.val());
        });
        // console.log(studentlist)
        this.setState({ categories: catlist });
        this.props.setCategories(catlist);
      });

    app
      .database()
      .ref("onemin")
      .on("value", (snapshot) => {
        let onelist = {};
        snapshot.forEach((snap, i) => {
          onelist = { ...onelist, [snap.ref_.path?.pieces_[1]]: snap.val() };
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
        });
        this.setState({ oneMin: onelist });
        this.props.setOneMin({ value: onelist, isLoading: false });
      });

    app
      .database()
      .ref("expertTip")
      .on("value", (snapshot) => {
        let explist = [];
        snapshot.forEach((snap, i) => {
          explist = { ...explist, [snap.ref_.path?.pieces_[1]]: snap.val() };
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
        });
        // console.log(studentlist)
        this.setState({ expertTip: explist });
        this.props.setExpertTip({ value: explist, isLoading: false });
      });

    // fetching all posts----

    // app
    //   .database()
    //   .ref('allposts')
    //   .on('value', snapshot => {
    //     let allplist = []
    //     snapshot.forEach((snap, i) => {
    //       allplist = { ...allplist, [snap.ref_.path?.pieces_[1]]: snap.val() }
    //       // snap.val() is the dictionary with all your keys/values from the 'students-list' path
    //     })
    //     // console.log(studentlist)
    //     this.setState({ allPosts: allplist })
    //     this.props.setAllPosts({ value: allplist, isLoading: false })
    //   })

    // -------------

    // MY post bfetching-----------------
    // if (localStorage.getItem('token')) {
    //   let userProfile = JSON.parse(localStorage.getItem('userProfile'))?.role[0]
    //   let email = userProfile?.patientemail.replace('.', ',')
    //   let phone = userProfile?.patientmobile

    //   // app
    //   //   .database()
    //   //   .ref(`myposts/${email}`)
    //   //   // .ref(`myposts/AAAAAAAA@gmail,com`)
    //   //   .on('value', snapshot => {
    //   //     let myplist = {}
    //   //     snapshot.forEach((snap, i) => {
    //   //       console.log('snap: ', snap)
    //   //       myplist = { ...myplist, [snap.ref_.path?.pieces_[2]]: snap.val() }
    //   //       // snap.val() is the dictionary with all your keys/values from the 'students-list' path
    //   //     })

    //   //     this.setState({ myPosts: myplist })
    //   //     this.props.setMyPosts({ value: myplist, isLoading: false })
    //   //   })
    //   app
    //     .database()
    //     .ref(`myposts/${phone}`)
    //     // .ref(`myposts/AAAAAAAA@gmail,com`)
    //     .on('value', snapshot => {
    //       let myplist = {}
    //       snapshot.forEach((snap, i) => {
    //         myplist = { ...myplist, [snap.ref_.path?.pieces_[2]]: snap.val() }
    //         // snap.val() is the dictionary with all your keys/values from the 'students-list' path
    //       })

    //       this.setState({ myPosts: myplist })
    //       this.props.setMyPosts({ value: myplist, isLoading: false })
    //     })
    // }

    // ----------------------

    this.props.fetchCities();
    this.props.fetchSpecialities({ Role: "OPSY" });
    //   this.props.fetchLocalities({ cityname: 'New Delhi' })
    // this.props.setCategories(this.props.hashTags);
  }

  // componentDidUpdate (prevProps, prevState) {
  // if (prevState.oneMin !== this.state.oneMin) {
  //   this.props.setOneMin(this.state.oneMin)
  // }
  // if (prevState.categories !== this.state.categories) {
  //   this.props.setCategories(this.state.categories)
  // }
  // if (prevState.expertTip !== this.state.expertTip) {
  //   this.props.setExpertTip(this.state.expertTip)
  // }
  // }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.hashTags !== this.props.hashTags) {
  //     this.props.setCategories(this.props.hashTags);
  //   }
  // }
  render() {
    // console.log('mAIN c')
    const DocInfo = (props) => {
      return (
        <DoctorVerification
          docinfo={this.props.docinfo.docinfo}
          docLoading={this.props.docinfo.isLoading}
          docErrMess={this.props.docinfo.errMess}
          locinfo={this.props.locinfo.locinfo}
          locLoading={this.props.locinfo.isLoading}
          locErrMess={this.props.locinfo.errMess}
          roles={this.props.roles.roles}
          rolesLoading={this.props.roles.isLoading}
          rolesErrMess={this.props.roles.errMess}
          fetchRoles={this.props.fetchRoles}
          fetchDocInfo={this.props.fetchDocInfo}
          fetchLocInfo={this.props.fetchLocInfo}
          postDocInfo={this.props.postDocInfo}
          onClick={this.toggleLocModal}
          isLocModalOpen={this.state.isLocModalOpen}
          email={this.state.docemail}
          onSearch={(email) => this.setState({ docemail: email })}
          role={this.state.docrole}
          onChangeRole={(role) => this.setState({ docrole: role })}
          {...props}
        />
      );
    };
    const LocInfo = (props) => {
      return (
        <LocationVerification
          postLocInfo={this.props.postLocInfo}
          locinfo={this.props.locinfo.locinfo}
          {...props}
        />
      );
    };
    const DocsInfo = (props) => {
      return (
        <GoLiveVerification
          docsinfo={this.props.docsinfo.docsinfo}
          locinfo={this.props.locinfo.locinfo}
          docsLoading={this.props.docsinfo.isLoading}
          docsErrMess={this.props.docsinfo.errMess}
          fetchDocsInfo={this.props.fetchDocsInfo}
          fetchDocInfo={this.props.fetchDocInfo}
          docinfo={this.props.docinfo.docinfo}
          fetchLocInfo={this.props.fetchLocInfo}
          postDocsInfo={this.props.postDocsInfo}
          roles={this.props.roles.roles}
          rolesLoading={this.props.roles.isLoading}
          rolesErrMess={this.props.roles.errMess}
          fetchRoles={this.props.fetchRoles}
          auth={this.props.auth}
          email={this.state.goLiveEmail}
          onSearch={(email) => this.setState({ goLiveEmail: email })}
          role={this.state.goLiveRole}
          onChangeRole={(role) => this.setState({ goLiveRole: role })}
          {...props}
        />
      );
    };

    const onClick = (role) => {
      this.setState({ searchService: role });
      this.props.fetchSpecialities({ Role: role });
    };

    const Home = (props, { match }) => {
      return (
        <div>
          <section id="home">
            {isBrowser ? (
              <div className="box">
                <ImageSlider tip={this.state.tip}></ImageSlider>
              </div>
            ) : (
              <MobileImageSlider></MobileImageSlider>
            )}
            {/* <SearchServices
              role={this.state.searchService}
              onClick={onClick}
              {...this.props}
              match2={match}
            ></SearchServices> */}
          </section>

          <section id="search_services">
            {/* <div className="box2 m-2">
              <SearchServices {...props} />
            </div> */}
            <SearchServices
              role={this.state.searchService}
              onClick={onClick}
              {...this.props}
              match2={match}
            ></SearchServices>
          </section>
          {/* <section id="book-an-appointment"> */}
          {/* <BookAnAppointmentForm></BookAnAppointmentForm> */}
          {/* </section> */}
          <section id="hashtags">
            <div className="container-fluid  text-center bg-light tags-container">
              <div className="pt-4">
                <HashTags></HashTags>
              </div>
            </div>
          </section>
          <section id="todays special">
            <div className="container-fluid text-center bg-light tags-container">
              <div className="pt-4">
                <TodaysSpecial
                  tdsCardList={this.state.tdsCardList}
                  loader={this.state.tdsLoading}
                ></TodaysSpecial>
              </div>
            </div>
          </section>
          <section id="services">
            <Services
              services={SharedServices}
              features={SharedFeatures}
              onClick={onClick}
              {...props}
            />
          </section>
          <section id="about">
            <About {...props} />
          </section>
          {/* <section id="app_screen">
            <AppScreen />
          </section> */}
          <section id="partners">
            <IncubatersList></IncubatersList>
          </section>
          <section id="contact">
            <Contact {...props} />
          </section>
        </div>
      );
    };
    const Search_Services = ({ match }) => {
      return (
        <SearchServices
        // match2={match}
        // role={this.state.searchService}
        // onClick={onClick}
        // search={this.state.search}
        // onSearch={() => {
        //   this.setState({ search: false });
        // }}
        // {...this.props}
        />
      );
    };
    const DocDetails = ({ match }) => {
      return (
        <DocProfile {...this.props} id={match.params.docid} match={match} />
      );
    };
    return (
      <div>
        <Header
          onEmpLogin={() => this.setState({ empLogin: true })}
          onUserLogin={() => this.setState({ userLogin: true })}
          onPartnerLogin={() => this.setState({ partnerLogin: true })}
        />
        <div style={{ height: "144px" }} className="bg-light"></div>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/home" component={Home} />
          {/* <Route exact path="/emp-login" component={EmpLogin} /> */}
          <Route exact path="/verifyDoctor/" component={DocInfo} />
          <Route exact path="/verifyLocation/" component={LocInfo} />
          <Route exact path="/goLiveVerification/" component={DocsInfo} />
          <Route
            exact
            path="/doctors/:city/:locality/:role/:spec"
            component={Search_Services}
          />
          <Route
            exact
            path="/doctors/:city/:locality/:role/:spec/:docid"
            component={DocDetails}
          />
          <Routes></Routes>
        </Switch>
        {/* <FirebaseDatabaseNode
          path='Categories'
          // limitToFirst={this.state.limit}
          // orderByKey
          orderByValue={'created_on'}
        >
          {d => {
            if (d.value && d.isLoading === false) {
              this.setState(
                { categories: d.value },
                console.log('d.val: ', d.value)
              )
            }
            return null
          }}
        </FirebaseDatabaseNode> */}
        {/* <FirebaseDatabaseNode path='onemin' orderByValue={'created_on'}>
          {d => {
            if (d.value && d.isLoading === false) {
              this.setState({ oneMin: d })
            }
            return null
          }}
        </FirebaseDatabaseNode> */}
        {/* <FirebaseDatabaseNode path='expertTip' orderByValue={'created_on'}>
          {d => {
            if (d.value && d.isLoading === false) {
              this.setState({ expertTip: d })
            }
            return null
          }}
        </FirebaseDatabaseNode> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cities: state.cities,
    localities: state.localities,
    specialities: state.specialities,
    docs: state.docs,
    doc: state.doc,
    docinfo: state.docinfo,
    locinfo: state.locinfo,
    docsinfo: state.docsinfo,
    roles: state.roles,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCities: () => dispatch(fetchCities()),
  fetchLocalities: (cityname) => dispatch(fetchLocalities(cityname)),
  fetchSpecialities: (role) => dispatch(fetchSpecialities(role)),
  getDocs: (info) => dispatch(getDocs(info)),
  getDoc: (dlmid) => dispatch(getDoc(dlmid)),
  fetchDocInfo: (docinfo) => dispatch(fetchDocInfo(docinfo)),
  fetchLocInfo: (locinfo) => dispatch(fetchLocInfo(locinfo)),
  fetchDocsInfo: (doc) => dispatch(fetchDocsInfo(doc)),
  fetchRoles: (email) => dispatch(fetchRoles(email)),
  postDocInfo: (docinfo) => dispatch(postDocInfo(docinfo)),
  postLocInfo: (locinfo) => dispatch(postLocInfo(locinfo)),
  postDocsInfo: (docsinfo) => dispatch(postDocsInfo(docsinfo)),
  loginUser: (creds) => dispatch(loginUser(creds)),
  changePass: (creds) => dispatch(changePass(creds)),
  setCategories: (cat) => dispatch(setHashTags(cat)),
  setExpertTip: (eT) => dispatch(setExpertTip(eT)),
  setOneMin: (data) => dispatch(setOneMin(data)),
  setAllPosts: (data) => dispatch(setAllPosts(data)),
  setMyPosts: (data) => dispatch(setMyPosts(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(Main))
);
