import React, { useEffect, useState } from "react";
import { message, Empty, Pagination } from "antd";
import "../../assets/styles/home.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AppInviteCard from "./AppInviteCard";
import axios from "axios";
import { apis } from "../../shared/apis";
import DoctorCard from "../userDashBoard/DoctorCard";
import SortAndFilterForm from "../userDashBoard/SortAndFilterForm";
import { connect } from "react-redux";
let queryoffset = 0;
let querylimit = 50;
const Doctorslistpage = (props) => {
  const { userLocality } = props?.userDash;
  const pageSize = 5;
  const [page, setPage] = useState(1);
  const initLocal = {
    label: "Select locality",
    value: "Select locality",
    key: "Select locality",
  };
  const [loaders, setLoaders] = useState({
    locL: false,
    specL: false,
    searchL: false,
  });
  //   const [userLocality, setUserLocality] = useState();
  const [docs, setDocs] = useState([]);
  const [docList, setDocList] = useState({ 1: [] });
  //   const [state, setState] = useState({ showDocCard: false });
  const [specData, setSpecData] = useState("");
  const formatList = (arr) => {
    let len = arr?.length;
    let obj = {};
    let j = 1;
    for (let i = 0; i < len; i++) {
      if (i !== 0 && i % pageSize === 0) j++;
      if (typeof obj[j] !== "undefined") obj[j] = [...obj[j], arr[i]];
      else {
        obj[j] = [];
        obj[j] = [...obj[j], arr[i]];
      }
    }
    return obj;
  };

  const handleFinish = async (data) => {
    // Changed here by vinay on 1/7/2021 to make Online psychologist as default
    data.service = data.service == undefined ? "OPSY" : data.service;
    data.speciality = data.speciality == undefined ? "80010" : data.speciality;
    let api =
      data?.service === "ONL" ||
      data?.service === "ODIE" ||
      data?.service === "OPSY"
        ? apis.getonlinedoctorsbysearchlimit
        : apis.getdoctorsbysearchlimit;
    setLoaders({ ...loaders, searchL: true });

    await axios({
      ...api,
      data: {
        cityname: data?.city,
        dmrole: data?.service,
        localityname: userLocality?.llocalityname,
        localitylat: userLocality?.llocality_lat,
        localitylong: userLocality?.llocality_long,
        specialityid: data?.speciality,
        queryoffset: queryoffset,
        querylimit: querylimit,
        sortid: 0,
        searchfilter: 0,
        dayshortname: null,
        service_id:
          data?.service === "ONL" || data?.service === "DOC"
            ? ""
            : data?.speciality,
        ...data,
      },
    })
      .then((res, err) => {
        setLoaders({ ...loaders, searchL: false });
        if (err) message.error("Something Went Wrong");

        setDocs(res.data.info);
        setDocList(formatList(res.data.info));
        // setState({ ...state, showDocCard: true });
      })
      .catch((err) => {
        setLoaders({ ...loaders, searchL: false });
        if (err) message.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    const handleFinish = async (data) => {
      let api = apis[data.api];
      setLoaders({ ...loaders, searchL: true });

      await axios({
        ...api,
        data: {
          ...data,
        },
      })
        .then((res, err) => {
          setLoaders({ ...loaders, searchL: false });
          if (err) message.error("Something Went Wrong");

          setDocs(res.data.info);
          setDocList(formatList(res.data.info));
          // setState({ ...state, showDocCard: true });
        })
        .catch((err) => {
          setLoaders({ ...loaders, searchL: false });
          if (err) message.error("Something Went Wrong");
        });
    };
    let input = JSON.parse(props.match.params.data);
    console.log(input);
    handleFinish(input);
  }, []);

  return (
    <div className="container">
      <SortAndFilterForm
        data={specData}
        setPage={setPage}
        handleFinish={handleFinish}
      ></SortAndFilterForm>

      {docs?.length ? (
        <div>
          <div>{`Showing ${
            (page - 1) * pageSize + docList[page]?.length <= docs.length
              ? (page - 1) * pageSize + docList[page]?.length
              : docs.length
          } out of ${docs.length} results`}</div>
          <div>
            {/* {docs?.map((doc, i) => ( */}
            {docList[page] !== "undefined"
              ? docList[page]?.map((doc, i) => (
                  <div key={i + Math.random()}>
                    <DoctorCard {...props} doc={doc}></DoctorCard>
                  </div>
                ))
              : null}
            <div className="paginition-container">
              <Pagination
                pageSize={pageSize}
                total={docs.length}
                // total={Object.keys(docList).length}
                hideOnSinglePage={true}
                onChange={(page) => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  setPage(page);
                }}
                showSizeChanger={false}
                // simple={true}
                responsive={true}
              ></Pagination>
            </div>
          </div>
        </div>
      ) : (
        <Empty
          description={"No doctors available for your search query"}
        ></Empty>
      )}

      <div className="p-4">
        <AppInviteCard></AppInviteCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorsList: state.docs.docs.info,
    cities: state.cities,
    localities: state.localities,
    specialities: state.specialities,
    ui: state.ui,
    userDash: state.userDash,
  };
};

export default connect(mapStateToProps)(Doctorslistpage);
