import React, { useState } from "react";
import {
  DatePicker,
  Divider,
  message,
  Select,
  Spin,
  Form,
  Button,
  Radio,
  Empty,
  Modal,
} from "antd";
import {
  UserOutlined,
  // SolutionOutlined,
  // LoadingOutlined,
  // SmileOutlined,
} from "@ant-design/icons";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { connect } from "react-redux";
import axios from "axios";
import { apis } from "../../shared/apis";
import moment from "moment";
// import PaytmPayment from "../payment/PaytmPayment";
const { Option } = Select;

const RescheduleForm = (props) => {
  console.log("props of RF: ", props);

  const userProfile = JSON.parse(localStorage.getItem("userProfile"))?.role[0];
  // eslint-disable-next-line no-unused-vars
  const [patList, setPatList] = useState([
    {
      dependentid: userProfile.patientid,
      dob: userProfile.patientdob,
      email: userProfile.patientemail,
      gender: userProfile.patientgender,
      mobile: userProfile.patientmobile,
      name: userProfile.patientname,
      patientid: userProfile.patientid,
      pdmid: userProfile.patientid,
      photo: userProfile.patientphoto,
    },
    ...props?.dependents,
  ]);
  const [dependent, setDependent] = useState(
    patList?.length ? patList[0].name : "Choose Dependent"
  );
  const [appointmentDate, setAppointmentDate] = useState();
  const [appointmentTime, setAppointmentTime] = useState();
  const [timeArr, setTimeArr] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [service, setService] = useState();
  // const [otp, setOtp] = useState("");
  const [isDayAvailable, setIsDayAvailable] = useState(false);
  //   const [payingL, setPayingL] = useState(false);

  const [loader, setLoader] = useState(false);

  //   const [showPaytmPayment, setShowPaytmPayment] = useState(false);
  //   const [checkSumRes, setCheckSumRes] = useState({});

  // const generateOTP = () => {
  //   var digits = "0123456789";
  //   let OTP = "";
  //   for (let i = 0; i < 4; i++) {
  //     OTP += digits[Math.floor(Math.random() * 10)];
  //   }
  //   // setOtp(OTP);
  //   return OTP;
  // };

  const formatTime = (time) => {
    let str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  const isTimeExpired = (from, to) => {
    const curr = moment().format("hhmm");
    if (appointmentDate?.format("YYYY-MM-DD"))
      if (parseInt(curr) <= parseInt(to)) return false;
    return true;
  };

  const disabledDate = (current) => {
    // Can not select days before today
    // return false
    return current &&
      current.format("DD-MM-YYYY") ===
        moment().endOf("day").format("DD-MM-YYYY")
      ? false
      : current < moment().endOf("day");
  };

  const getPid = (dependent) => {
    let res = {};
    console.log("d pd: ", dependent, props?.dependents);
    res = props?.dependents?.filter((dep) => dep.name === dependent);
    return typeof res[0] !== "undefined"
      ? res[0]?.dependentid || res[0].patientid
      : JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientid;
  };

  const handleDependent = (e) => {
    setDependent(e);
  };

  // const handleTime = (time, checked) => {
  //   if (checked) setAppointmentTime(time);
  //   else {
  //     setAppointmentTime("");
  //   }
  // };

  const handleTime = (time, checked) => {
    if (checked) {
      let day = appointmentDate.format("ddd");
      // console.log("day: ", day);

      let timeArrDocDetails = props?.daytiminginfo?.filter(
        (dti) => dti?.ldldmdaynumber === day.toUpperCase()
      );
      // console.log("timeArrDocDetails: ", timeArrDocDetails);

      let timeDocDetiails = timeArrDocDetails?.filter(
        (tdd) =>
          tdd?.ldltmtimefrom === time?.timefrom &&
          tdd?.ldltmtimeto === time?.timeto
      );

      // console.log("timeDocDetiails: ", timeDocDetiails);
      setAppointmentTime({
        ...time,
        ldiscountnormal: timeDocDetiails[0]?.ldiscountnormal,
        isChecked: checked,
      });

      // setAmount(
      //   timeDocDetiails[0]?.ldiscountnormal === "Normal"
      //     ? service?.ldcsmnormalamount
      //     : service?.ldcsmdiscountedamount
      // );
    } else {
      setAppointmentTime({ isChecked: checked });
      // setAmount(0);
      // console.log("amt : ", 0);
    }
  };

  const isDocAvailable = async (date) => {
    let inputTemp = {
      appointmentdate: date?.format("YYYY-MM-DD"), // selected date
      dlmid: props?.dlmid, // clinic id
      rescheduleflag: "Y", // whether user wants a reshedule or not(means is user is resudeling an appt or not)
      patientid: getPid(dependent), // patient id
    };
    await axios({ ...apis.getpatientappointmentinfo, data: inputTemp })
      .then((res, err) => {
        if (err) message.error("Something Went Wrong");
        else {
          // console.log("res for info: ", res.data);
          if (res?.data?.status === "SUCCESS") {
            setTimeArr(res?.data?.info);

            // let flag = false;
            // if (date) {
            //   let day = date?.format("ddd");
            //   props?.daytiminginfo?.forEach((avaDay) => {
            //     if (avaDay?.ldldmdaynumber === day.toUpperCase()) {
            //       flag = true;
            //     }
            //   });
            // }

            // if (flag === false) {
            //   message.error("The Doctor Is Not In Attendence On The Choosen Day");
            // } else {
            message.success("The Doctor Is In Attendence On The Choosen Day");
            // }
            setIsDayAvailable(true);
          } else {
            setIsDayAvailable(false);
            Modal.info({
              title: `${res.data.status}`,
              onOk: props?.closeBookingForm,
            });
          }
        }
      })
      .catch((err) => {
        if (err) message.error("Something went Wrong");
      });

    // let flag = false;
    // if (date) {
    //   let day = date?.format("ddd");
    //   props?.daytiminginfo?.forEach((avaDay) => {
    //     if (avaDay?.ldldmdaynumber === day.toUpperCase()) {
    //       flag = true;
    //     }
    //   });
    // }
    // if (flag === false) {
    //   message.error("The Doctor Is Not In Attendence On The Choosen Day");
    // } else {
    //   message.success("The Doctor Is In Attendence On The Choosen Day");
    // }
    // setIsDayAvailable(flag);

    // return flag;
  };

  const reshcheduleAppointment = async () => {
    let input = {
      pdla_appointment_datetime: appointmentDate?.format("YYYY-MM-DD"),
      pdla_id: props?.pdlaid,
      pdla_dltm_dltm_id: appointmentTime?.timeid || "timeid",
    };
    setLoader(true);

    await axios({ ...apis.getReschedule, data: input })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong!");
        // console.log("Res Resche:", res.data);
        message.info(res?.data?.status);
        props?.closeForm();
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong!");
      });
  };

  const timeInputForm = () => {
    // let day = appointmentDate.format("ddd");
    // console.log("day: ", day);

    // let timeArr = props?.daytiminginfo?.filter(
    //   (dti) => dti?.ldldmdaynumber === day.toUpperCase()
    // );
    console.table("timeArr : ", timeArr);
    // let time = timeArr[0];
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {timeArr.map((time, i) => (
          <div className="m-1" key={time?.timeid || i + Math.random()}>
            <CheckableTag
              checked={
                time?.timeto === appointmentTime?.timeto &&
                time?.timefrom === appointmentTime?.timefrom
              }
              // checked={appointmentTime?.isChecked}
              size="large"
              onChange={(checked) => handleTime(time, checked)}
              style={{
                padding: "0",
                fontSize: "1rem",
                border: "1px solid #f1f1f1",
                disabled: true,
              }}
              disabled={true}
            >
              <Button
                style={{
                  padding: "0 0.50rem",
                  backgroundColor: "unset",
                  color: "unset",
                  border: "none",
                }}
                // disabled={isTimeExpired(time?.ldltmtimefrom, time?.ldltmtimeto)}
                disabled={isTimeExpired(time?.timefrom, time?.timeto)}
              >
                {" "}
                {`${formatTime(time?.timefrom)}-${formatTime(time?.timeto)}`}
              </Button>
            </CheckableTag>
          </div>
        ))}
      </div>
    );
  };

  // const timeInputForm = () => {
  //   let day = appointmentDate.format("ddd");
  //   // console.log("day: ", day);
  //   let timeArr = props?.daytiminginfo?.filter(
  //     (dti) => dti?.ldldmdaynumber === day.toUpperCase()
  //   );
  //   let time = timeArr[0];
  //   return (
  //     <div className="m-1">
  //       <CheckableTag
  //         checked={time === appointmentTime}
  //         size="large"
  //         onChange={(checked) => handleTime(time, checked)}
  //         style={{
  //           padding: "0.50rem",
  //           fontSize: "1rem",
  //           border: "1px solid #f1f1f1",
  //         }}
  //       >
  //         {`${formatTime(time?.ldltmtimefrom)}-${formatTime(
  //           time?.ldltmtimeto
  //         )}`}
  //       </CheckableTag>
  //     </div>
  //   );
  // };

  return (
    <div>
      <Form initialValues={{ dependent }} onFinish={reshcheduleAppointment}>
        <Divider>Select Pateint/Dependent</Divider>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Item
            name="dependent"
            rules={[{ required: true, message: "Please Select Dependent" }]}
          >
            <Select
              showSearch
              size="large"
              style={{ width: 300 }}
              placeholder=" Choose Dependent"
              optionFilterProp="children"
              onChange={handleDependent}
              // onSearch={onSearch}
              notFoundContent={props?.loader ? <Spin size="small" /> : null}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={dependent}
              allowClear
            >
              {patList.map((d, i) => (
                <Option value={d.name} key={i + Math.random()}>
                  <div className="option-dependent px-1">
                    <span className="user-icon">
                      {d.photo ? (
                        <img
                          alt={d.name}
                          className="img-fluid"
                          src={"data:image/jpeg;base64," + d.photo}
                        ></img>
                      ) : (
                        <UserOutlined></UserOutlined>
                      )}
                    </span>
                    <span className="user-name">{d.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Divider>Select Appointment Date</Divider>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Item
            name="appointmentDate"
            rules={[{ required: true, message: "Please Select Date" }]}
          >
            <DatePicker
              onChange={(e) => {
                setAppointmentDate(e);
                isDocAvailable(e);
              }}
              style={{ width: 300 }}
              size="large"
              allowClear={false}
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item>
        </div>
        {isDayAvailable ? (
          <div>
            {" "}
            <Divider>Select Appointment Time Slot</Divider>
            <div>
              <Form.Item
                name="appointmentTime"
                rules={[
                  //   { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (appointmentTime !== "") {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Please select any time slot")
                      );
                    },
                  }),
                ]}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  {timeInputForm()}
                  {/* {props?.daytiminginfo?.map((time, i) => (
                    <div key={i + Math.random()} className="m-1">
                      <CheckableTag
                        checked={time === appointmentTime}
                        size="large"
                        onChange={(checked) => handleTime(time, checked)}
                        style={{
                          padding: "0.50rem",
                          fontSize: "1rem",
                          border: "1px solid #f1f1f1",
                        }}
                      >
                        {`${formatTime(time?.ldltmtimefrom)}-${formatTime(
                          time?.ldltmtimeto
                        )}`}
                      </CheckableTag>
                    </div>
                  ))}
                 */}
                </div>
              </Form.Item>
            </div>
            <Divider>Select Service</Divider>
            <div style={{ width: "100%" }}>
              <Form.Item name="service">
                <Radio.Group
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setService(e.target.value);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    {props?.serviceinfo?.length ? (
                      props?.serviceinfo.map((serv, i) => (
                        <div key={serv.ldcsmid}>
                          <Radio value={serv}>{serv?.lsmservicename}</Radio>
                        </div>
                      ))
                    ) : (
                      <Empty description="No Services Available"></Empty>
                    )}
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loader}
                >
                  Reschedule
                </Button>
              </Form.Item>
            </div>
          </div>
        ) : null}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
    selectedService: state.userDash.selectedService,
    selectedSpeciality: state.userDash.selectedSpeciality,
    specialityList: state.userDash.specialityList,
    outcomePackages: state.userDash.outcomePackages,
  };
};

export default connect(mapStateToProps)(RescheduleForm);
