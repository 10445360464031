import React, { useState } from "react";
import { Button, Card, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import axios from "axios";
import { apis } from "../../shared/apis";
import { isBrowser } from "react-device-detect";
const TimingCard = (props) => {
  const [loader, setLoader] = useState(false);
  const days = [
    {
      title: "Monday",
      isOn: props?.mon === "Y" ? true : false,
      id: props?.monid,
    },
    {
      title: "Tuesday",
      isOn: props?.tue === "Y" ? true : false,
      id: props?.tueid,
    },
    {
      title: "Wednesday",
      isOn: props?.wed === "Y" ? true : false,
      id: props?.wedid,
    },
    {
      title: "Thursday",
      isOn: props?.thu === "Y" ? true : false,
      id: props?.thuid,
    },
    {
      title: "Friday",
      isOn: props?.fri === "Y" ? true : false,
      id: props?.friid,
    },
    {
      title: "Saturday",
      isOn: props?.sat === "Y" ? true : false,
      id: props?.satid,
    },
    {
      title: "Sunday",
      isOn: props?.sun === "Y" ? true : false,
      id: props?.sunid,
    },
  ];
  const deleteTime = async () => {
    let data = { idss: [] };
    days.forEach((day) => {
      let obj = {};
      if (day.isOn) {
        obj = { id: day.id };
        data.idss.push(obj);
      }
    });
    setLoader(true);
    await axios({ ...apis.deletetime, data })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Err in deleting time:");
        // console.log("Red in dt: ", res);
        props.updateList();
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Err in deleting time:");
      });
  };
  return (
    <div>
      <Card
        hoverable
        type="inner"
        headStyle={{ backgroundColor: "#004fcf", color: "white" }}
        title={
          <div
            className="text-center p-2"
            style={{
              display: "flex",
              fontSize: "1rem",
            }}
          >
            <div style={{ flexGrow: "10" }}>
              {props.from[0]}
              {props.from[1]}:{props.from[2]}
              {props.from[3]}-{props.to[0]}
              {props.to[1]}:{props.to[2]}
              {props.to[3]}
            </div>
            <div onClick={deleteTime}>
              <DeleteFilled
                spin={loader}
                style={{ display: "inline-flex", fontSize: "1.25rem" }}
              />
            </div>
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {days.map((day) => (
            <div
              key={day.id + Math.random()}
              style={{ textTransform: "uppercase", padding: "7px 5px" }}
            >
              <Button
                shape="circle"
                disabled={!day.isOn}
                type="primary"
                size={isBrowser ? "large" : "middle"}
              >
                {day.title.substr(0, 3)}
              </Button>
            </div>
          ))}
        </div>
      </Card>
      {}
    </div>
  );
};

export default TimingCard;
