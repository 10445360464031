import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Switch,
} from "antd";
import {
  PercentageOutlined,
  HourglassOutlined,
  CoffeeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { apis } from "../shared/apis";
import moment from "moment";
const { Meta } = Card;
const Partnerquicksetting = (props) => {
  const [loaderD, setLoaderD] = useState(false);
  const [loaderL, setLoaderL] = useState(false);
  const [loaderT, setLoaderT] = useState(false);
  const [loaderLS, setLoaderLS] = useState(false);
  const [onDiscount, setOnDiscount] = useState(false);
  const [onDiscountAll, setOnDiscountAll] = useState(false);
  const [lateBy, setLateBy] = useState("");
  const [showTimeForm, setShowTimeForm] = useState(false);
  const [timeList, setTimeList] = useState([]);
  const [selectedTimeslots, setSelectedTimeslots] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isTimeOffDisabled, setIsTimeOffDisabled] = useState(false);
  const [funcProps, setFuncProps] = useState({});
  const [form] = Form.useForm();

  const formatTime = (time) => {
    let str = "";
    if (typeof time !== "undefined")
      str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };
  const cardContainer = (data) => (
    <Meta
      avatar={<Avatar size={55} src={data.src} style={data?.icoStyle}></Avatar>}
      title={<div>{data.title}</div>}
      description={<div>{data.desc}</div>}
    >
      {data.children}
    </Meta>
  );

  const handleLateInput = (e) => {
    let v = e.target.value;
    if (v < 0) v = 0;
    if (v > 60) v = 60;
    setLateBy(v);
  };

  const handleSelectTimeslot = (time, checked) => {
    // let temp = { ...selectedTimeslots };
    let temp = {};
    if (checked) temp[time?.timeid] = { ...time };
    else delete temp[time?.timeid];
    console.log("tiemOBj : ", temp, checked);
    setSelectedTimeslots({ ...temp });
  };

  const discountChild = () => (
    <div>
      <div className="disc-action-item">
        <div className="disc-label">
          Turn on Discounts for my current location
        </div>
        <div>
          <Switch
            checked={onDiscount}
            onChange={(c) => {
              setOnDiscount(c);
              setOnDiscountAll(false);
            }}
          ></Switch>
        </div>
      </div>
      <div className="disc-action-item">
        <div className="disc-label">Turn off All my Discounts</div>
        <div>
          <Switch
            checked={onDiscountAll}
            onChange={(c) => {
              setOnDiscount(false);
              setOnDiscountAll(c);
            }}
          ></Switch>
        </div>
      </div>
    </div>
  );

  const selectTimeForm = () => (
    <div>
      {timeList?.length ? (
        timeList.map((time, i) => (
          <div className="disc-action-item" key={time?.timeid}>
            <div className="disc-label">
              Timeslot :{" "}
              <b className="ml-2">
                {" "}
                {formatTime(time.timefrom) + " - " + formatTime(time.timeto)}
              </b>
            </div>
            <div>
              <Switch
                checked={selectedTimeslots[time?.timeid]}
                // checked={Object.keys(selectedTimeslots).includes(time?.timeid)}
                onChange={(checked) => handleSelectTimeslot(time, checked)}
              ></Switch>
            </div>
          </div>
        ))
      ) : (
        <Empty description="No timeslots available"></Empty>
      )}
    </div>
  );

  const handleDiscount = async () => {
    const input = {
      locid: props?.selectedClinic?.lid,
      onDiscount: onDiscount,
    };

    setLoaderD(true);
    await axios({ ...apis.iftimeexist, data: input })
      .then((res, err) => {
        setLoaderD(false);
        if (err) {
          message.error("Something went wrong");
          console.log("Eror: ", err);
        } else {
          console.log("res : ", res.data);
          if (res.data.status === "SUCCESS" && res.data.time_exist === "Y") {
            message.success("Successfully Saved");
          }
        }
      })
      .catch((err) => {
        setLoaderD(false);
        if (err) {
          message.error("Something went wrong");
          console.log("Eror: ", err);
        }
      });
  };
  const handleLate = async (setLoader) => {
    const input = {
      dlmid: props?.selectedClinic?.dlmid,
      day: moment().format("ddd").toUpperCase(),
    };

    setLoader(true);
    await axios({ ...apis.getDoctorTimeSlots, data: input })
      .then((res, err) => {
        setLoader(false);
        if (err) {
          message.error("Something went wrong");
          console.log("Eror: ", err);
        } else {
          console.log("res : ", res.data);
          setShowTimeForm(true);
          setTimeList(res.data?.info);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) {
          message.error("Something went wrong");
          console.log("Eror: ", err);
        }
      });
  };
  const sendLateTimeSlots = async () => {
    const input = {
      appointmentdate: startDate
        ? moment(startDate)?.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      end_date: endDate
        ? moment(endDate)?.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      dlmid: props?.selectedClinic?.dlmid,
      timeid: Object.keys(selectedTimeslots)[0],
      dayofweek: moment().format("ddd").toUpperCase(),
      delayinmins: lateBy,
      docmobile: props?.selectedRole?.mobileno,
      docname: props?.partnerEmail,
    };
    funcProps?.setLoader(true);
    await axios({ ...funcProps?.api, data: input })
      .then((res, err) => {
        funcProps?.setLoader(false);
        if (err) {
          message.error("Something went wrong");
          console.log("error: ", err);
        } else {
          setSelectedTimeslots({});
          setShowTimeForm(false);
          message.success("Request Successfull");
          console.log("res.dara", res.data);
        }
      })
      .catch((err) => {
        funcProps?.setLoader(false);
        if (err) {
          message.error("Something went wrong");
          console.log("error: ", err);
        }
      });
  };

  const handleTimeOff = async () => {
    // setLoaderT(true);

    setFuncProps({
      loader: loaderT,
      setLoader: setLoaderT,
      api: apis.doctortimeoffnotification,
      title: "Are you sure want to take time off for selected slot ?",
    });

    // handleLate(setLoaderT);
    sendLateTimeSlots();
  };

  const cards = [
    {
      title: "Discount",
      desc: "Turn On/Off Discount on One Click",
      src: (
        <PercentageOutlined
          style={{
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        ></PercentageOutlined>
      ),
      icoStyle: { backgroundColor: "#fa8c16" },
      children: discountChild(),
      btnText: "Submit",
      btnProps: { loading: loaderD, disabled: !onDiscount && !onDiscountAll },
      onClick: handleDiscount,
    },
    {
      title: "Late",
      desc: "Mark Delay time for your next schedule Location for convenience of your",
      src: (
        <HourglassOutlined
          style={{
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        />
      ),
      icoStyle: { backgroundColor: "#722ed1" },
      children: (
        <div className="my-4">
          <div className="disc-label">
            Late by{" "}
            <Input
              className="lateby-input"
              bordered={false}
              type="number"
              min="0"
              max="60"
              value={lateBy}
              onChange={handleLateInput}
            ></Input>{" "}
            Mins
          </div>
        </div>
      ),
      btnText: "OK",
      btnProps: { loading: loaderL, disabled: !lateBy },
      onClick: () => {
        setFuncProps({
          loader: loaderL,
          setLoader: setLoaderL,
          api: apis.quicksetdelaynotification,
          title: "Are you sure want to delay your appointments ?",
        });
        handleLate(setLoaderL);
      },
    },
    {
      title: "Take Time-Off",
      desc: "Make Time-off and all your appointments/meeting will be on hold",
      src: (
        <CoffeeOutlined
          style={{
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        />
      ),
      icoStyle: { backgroundColor: "#52c41a" },
      children: (
        <div className="pt-4">
          <Form
            form={form}
            labelAlign="left"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 7 }}
          >
            <Form.Item
              name="startDate"
              // className="mb-2"
              label={<div className="date-input-label">Start Date </div>}
              rules={[{ required: true, message: "Please enter date " }]}
            >
              <DatePicker
                value={startDate}
                onChange={(e) => setStartDate(e)}
                style={{ width: "100%" }}
              ></DatePicker>
            </Form.Item>
            <Form.Item
              name="endDate"
              dependencies={["startDate"]}
              // className="mb-2"
              label={<div className="date-input-label">End Date </div>}
              rules={[
                { required: true, message: "Please enter date " },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("startDate") < value) {
                      setIsTimeOffDisabled(false);
                      return Promise.resolve();
                    }

                    setIsTimeOffDisabled(true);
                    return Promise.reject(
                      new Error("endDate must be greater than start date !")
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                value={endDate}
                onChange={(e) => setEndDate(e)}
                style={{ width: "100%" }}
              ></DatePicker>
            </Form.Item>
          </Form>
        </div>
      ),
      btnText: "Send",
      btnProps: {
        loading: loaderT,
        disabled: isTimeOffDisabled || !startDate || !endDate,
        // disabled:
        //   !form.getFieldValue("startDate") || !form.getFieldValue("endDate"),
      },
      onClick: handleTimeOff,
    },
  ];

  return (
    <div className="row mx-0">
      {cards.map((card, i) => (
        <div
          key={i}
          className="col-sm p-0 m-2 mb-4"
          style={{ minWidth: "420px", maxWidth: "420px" }}
        >
          <Card
            hoverable
            // style={{ position: "relative" }}
            bodyStyle={{
              paddingBottom: 0,
              height: i === 2 ? "300px" : "260px",
            }}
          >
            <div>{cardContainer(card)}</div>
            <div>{card.children}</div>
            <div
              className="qs-btn mb-3"
              style={{
                position: "absolute",
                bottom: 0,
                width: "90%",
                margin: "auto",
              }}
            >
              <Button
                size="large"
                style={{ width: "100%" }}
                type="primary"
                onClick={card.onClick}
                {...card.btnProps}
              >
                {card.btnText}
              </Button>
            </div>
          </Card>
        </div>
      ))}
      <Modal
        visible={showTimeForm}
        onCancel={() => setShowTimeForm(false)}
        title={<div>Select Time Slot</div>}
        destroyOnClose={true}
        footer={[
          <div className="container-fluid text-center">
            <Popconfirm
              title={funcProps?.title || "Are you sure ?"}
              onConfirm={sendLateTimeSlots}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                shape="round"
                size="large"
                disabled={!Object.keys(selectedTimeslots)?.length}
                loading={funcProps?.loader}
              >
                Send
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        {selectTimeForm()}
      </Modal>
    </div>
  );
  //   return <div>{cards.map((card) => cardContainer(card))}</div>;
};

export default Partnerquicksetting;
