import React, { useState, useEffect } from "react";
import { Button, message, Result } from "antd";
// import { Link } from "react-router-dom";
// import { PlayCircleOutlined } from "@ant-design/icons";
import { ReactMediaRecorder } from "react-media-recorder";
import { janElaajStorage } from "../../firebase";
import LoadingMask from "../LoaderMask/LoadingMask";
// import VideoPreview from "./VideoPreview";
import Videorecorder from "./VideoRecorder";
import VideoProcessCard from "./VideoProcessCard";
import axios from "axios";
import { apis } from "../../shared/apis";
import { isBrowser } from "react-device-detect";
import MobVideorecorderbutton from "./MobVideoRecorderButton";
const Videorecorderbutton = (props) => {
  // const [showRecorder, setShowRecorder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmmited, setIsSubmitted] = useState(null);
  const [isError, setIsError] = useState(false);
  const [showRecorder, setShowRecorder] = useState(true);
  const [videoBlob, setVideoBlob] = useState("");
  const [filePath, setFilePath] = useState(false);
  let fEmail =
    localStorage.getItem("partnerEmail") ||
    localStorage.getItem("empEmail") ||
    JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientemail ||
    "test-user";
  fEmail = fEmail + "";
  const fName = fEmail?.replaceAll?.(".", ",") || fEmail?.split("@")[0];
  // let timer = "";

  // const handleTime = () => {
  //   setTime((time) => time - 1);
  //   if (time < 1) {
  //     clearInterval(timer);
  //     console.log("time clear: ", time);
  //   }
  //   console.log("time: ", time);
  // };

  // const startTime = () => {
  //   timer = setInterval(handleTime, 1000);
  //   // timer();
  // };
  // const stopTime = () => {
  //   clearInterval(timer);
  // };

  const uploadToDB = async (video) => {
    if (video) {
      var file = new File([video], fName);
      const formData = new FormData();
      formData.append("video", file);
      // formData.append("originalname", fName);
      setLoading(true);
      await axios({
        ...apis.uploadVideo,
        data: formData,
        headers: {
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary='another cool boundary'",
        },
      })
        .then((res, err) => {
          if (err) {
            setLoading(false);
            message.error("Something Went Wrong!");
            setIsError(true);
          }
          setFilePath(res.data?.file_path);
          setLoading(false);
          setIsSubmitted(res.data);
          message.success("Video Successfully Processed.......");
          setShowRecorder(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Something Went Wrong!");
          setIsError(true);
          setShowRecorder(false);
        });
    } else {
      setLoading(false);
      message.error("Please upload an Video first.");
    }
  };
  const handleOnStop = async (blobUrl, blob) => {
    setVideoBlob(blob);
    await uploadToDB(blob || videoBlob);
    // await uploadToFirebase(blob || videoBlob);
  };
  // const uploadToFirebase = (video) => {
  //   if (video) {
  //     const storageRef = janElaajStorage.ref().child("/visualParameter");
  //     const imageRef = storageRef.child(`${fName}.mp4`);
  //     setLoading(true);
  //     imageRef
  //       .put(video)
  //       .then((res) => {
  //         setLoading(false);
  //         // imageRef.getDownloadURL().then((url) => {
  //         //   // setImageFireUrl(url);
  //         //   // props?.setImageFireUrl(url);
  //         // });
  //         // setShowRecorder(false);
  //         setIsSubmitted(true);
  //         message.success("Video uploaded successfully.");
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         message.error("Something Went Wrong!");
  //       });
  //   } else {
  //     setLoading(false);
  //     message.error("Please upload an image first.");
  //   }
  // };
  // const handleOnStop = async (blobUrl, blob) => {
  //   setVideoBlob(blob);
  //   await uploadToFirebase(blob || videoBlob);
  // };

  // useEffect(() => {
  //   setShowRecorder(true);
  // }, []);

  return (
    <div style={{ minHeight: "400px" }}>
      {loading ? (
        <LoadingMask
          message="Processing Video, Please Wait"
          showCustomLoader={true}
        ></LoadingMask>
      ) : null}
      {isError ? null : (
        <div>
          {isBrowser ? (
            <ReactMediaRecorder
              askPermission={true}
              video
              onStop={handleOnStop}
              render={(vprops) =>
                showRecorder ? <Videorecorder {...props} {...vprops} /> : null
              }
            />
          ) : (
            <MobVideorecorderbutton handleOnStop={handleOnStop} />
          )}
        </div>
      )}
      {/* <Card
        title={<div>Video Screening</div>}
        extra={
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => setShowRecorder(true)}
            icon={<PlayCircleOutlined />}
          >
            {isSubmmited ? "Record Again" : "Record Video"}
          </Button>
        }
        hoverable
        bodyStyle={isSubmmited ? {} : { padding: 0 }}
      > */}
      {isSubmmited ? (
        <VideoProcessCard
          {...props}
          fName={fName}
          filePath={filePath}
          visualParameter={isSubmmited}
        ></VideoProcessCard>
      ) : isError ? (
        <Result
          status={"error"}
          title="Something Went Wrong"
          subTitle="Please close this Modal and Retry"
          extra={[
            <Button
              type="primary"
              shape="round"
              onClick={
                () => (window.location = "/")
                // props?.history?.push("/")
              }
            >
              Go back to Home
            </Button>,
          ]}
        />
      ) : null}
      {/* </Card> */}
      {/* <Modal
        visible={showRecorder}
        onCancel={() => setShowRecorder(false)}
        okText={loading ? "Submiting..." : "Submit"}
        okButtonProps={{ loading: loading }}
        onOk={handleSubmit}
        title={<div>Video recorder</div>}
        destroyOnClose={true}
      ></Modal> */}
    </div>
  );
};

export default Videorecorderbutton;
