import React, { useState } from 'react'
import { Tabs } from 'antd'
import EmpProfile from './EmpProfile'
import SubDealerList from './SubDealerList'
import CreateSubDealer from './CreateSubDealer'
import PartneredClinics from './PartneredClinics'
import PatientAppointments from './PatientAppointments'
const { TabPane } = Tabs
const DealerPage = props => {
  const [shouldUpdateSubDealer, setShouldUpdateSubDealer] = useState(true)
  const [shouldUpdatePartnerClinic, setShouldUpdatePartnerClinic] = useState(
    true
  )
  return (
    <div>
      <Tabs
        size='large'
        centered
        onTabClick={key => {
          if (key === 'empSubDealersList') {
            setShouldUpdateSubDealer(!shouldUpdateSubDealer)
          }
          if (key === 'empPartneredClinics') {
            setShouldUpdatePartnerClinic(!shouldUpdatePartnerClinic)
          }
        }}
      >
        <TabPane tab={<div>Profile</div>} key='empProfile'>
          <EmpProfile {...props}></EmpProfile>
        </TabPane>
        <TabPane tab={<div>All Sub Dealers</div>} key='empSubDealersList'>
          <SubDealerList
            shouldUpdate={shouldUpdateSubDealer}
            {...props}
            subject={'sub-dealer'}
          ></SubDealerList>
        </TabPane>
        <TabPane tab={<div>Create Sub Dealer</div>} key='empCreateSubDealer'>
          <CreateSubDealer {...props} subject={'sub-dealer'}></CreateSubDealer>
        </TabPane>
        <TabPane tab={<div>Partnered Clinics</div>} key='empPartneredClinics'>
          <PartneredClinics
            shouldUpdate={shouldUpdatePartnerClinic}
            {...props}
          ></PartneredClinics>
        </TabPane>
        <TabPane
          tab={<div>Patient Appointments</div>}
          key='empPatientAppointments'
        >
          <PatientAppointments {...props}></PatientAppointments>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DealerPage
