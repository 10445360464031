import React, { useEffect, useState } from "react";
import { Button, Input, Form, Select, Checkbox, message } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";
const { Option } = Select;
const AddServiceandRateForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [sflag, setSflag] = useState("N");
  const [damt, setDamt] = useState("0");
  const [servicesList, setServicesList] = useState([]);
  const isChemist =
    JSON.parse(localStorage.getItem("selectedRole"))?.role === "CHE";
  const updateServiceHandler = async (data) => {
    setUpdating(true);
    await axios({
      ...apis.serviceinsert,
      data: {
        values: [{ ...data, sflag: sflag, damt: damt || data.damt || "0" }],
        dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        role: JSON.parse(localStorage.getItem("selectedRole"))?.role,
      },
    })
      .then((res, err) => {
        setUpdating(false);
        if (err) {
          message.error("Something Went Wrong");
        } else {
          message.success("Service Added Successfully!");
          props?.updateList();
          props.closeForm();
        }
      })
      .catch((err) => {
        setUpdating(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  useEffect(() => {
    const fetchServiceList = async () => {
      setLoader(true);
      await axios({
        ...apis.serviceinfo,
        data: { Role: JSON.parse(localStorage.getItem("selectedRole"))?.role },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          let newS = [];
          newS = res.data.info.filter((ser) => {
            let flag = true;
            for (let x = 0; x < props.allServicesandRates.length; x++) {
              if (props.allServicesandRates[x].sname === ser.sname) {
                flag = false;
                break;
              }
            }
            return flag;
          });
          setServicesList(newS);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
        });
    };
    fetchServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      lg: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };
  return (
    <div>
      <div>
        <Form
          {...layout}
          layout="vertical"
          initialValues={{ namount: props.namount, damount: props.damount }}
          onFinish={updateServiceHandler}
        >
          <Form.Item
            name="sid"
            label={
              <div className="text-primary" style={{ fontSize: "1rem" }}>
                Service
              </div>
            }
            rules={[{ required: true, message: "Please Select Service !" }]}
          >
            <Select
              placeholder="Select Service"
              allowClear
              showSearch
              loading={loader}
            >
              {servicesList.map((ser, i) => (
                <Option value={ser.sid} key={i + Math.random()}>
                  {ser.sname}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="namt"
            label={
              <div className="text-primary" style={{ fontSize: "1rem" }}>
                {isChemist ? "Normal Discount(%)" : "Price (₹)"}
              </div>
            }
            rules={[{ required: true, message: "Please Enter Price !" }]}
          >
            <Input
              placeholder="Enter Price"
              size="large"
              bordered={false}
              style={{ borderBottom: "1px solid gray" }}
            ></Input>
          </Form.Item>
          <Form.Item name="sflag">
            <Checkbox
              checked={sflag === "N" ? false : true}
              onChange={(e) => setSflag(e.target.checked ? "Y" : "N")}
            >
              Discount Available
            </Checkbox>
          </Form.Item>
          {sflag === "Y" ? (
            <Form.Item
              name="damt"
              label={
                <div className="text-primary" style={{ fontSize: "1rem" }}>
                  {isChemist ? "Special Discount(%)" : "Discounted Price (₹)"}
                </div>
              }
              rules={[
                {
                  required: false,
                  message: "No problem",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      value &&
                      parseInt(value) > parseInt(getFieldValue("namt"))
                    ) {
                      return Promise.reject(
                        "Discount Amount cannot be greater than Price !"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder="Enter Discount Price"
                size="large"
                bordered={false}
                value={damt}
                onChange={(e) => {
                  setDamt(e.target.value);
                }}
                style={{ borderBottom: "1px solid gray" }}
                disabled={sflag === "Y" ? false : true}
              ></Input>
              <div className="font-weight-bold text-secondary m-1 mb-2">
                Leave blank for no discount!
              </div>
            </Form.Item>
          ) : null}

          <Form.Item className="mt-2">
            <Button type="primary" htmlType="submit" loading={updating}>
              {updating ? "Please Wait ..." : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddServiceandRateForm;
