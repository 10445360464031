import * as ActionTypes from "../../ActionTypes";

// docs reducer
export const Docs = (
  state = {
    isLoading: true,
    errMess: null,
    docs: {},
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_DOCS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        docs: action.payload,
      };

    case ActionTypes.DOCS_LOADING:
      return { ...state, isLoading: true, errMess: null, docs: {} };

    case ActionTypes.DOCS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload, docs: {} };

    default:
      return state;
  }
};
