import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import AppInviteCard from "./common/AppInviteCard";
const { Paragraph } = Typography;
function QuestionCard(props) {
  console.log("qC: ", props);
  // const [points, setPoints] = useState([])
  useEffect(() => {
    // getPoints(props.questions['question1'].answer)
    setShowAnswer(false);
    setShowAppInvite(false);
  }, [props.questions]);

  const getPoints = (str) => {
    console.log("str : ", str);
    let k = 0;
    let tempPoints = [];
    let pnt = "";
    for (let j = 0; j < str?.length; j++) {
      if (str[j] === "*") {
        pnt = str?.slice(k, j);
        tempPoints?.push(pnt);
        k = j + 1;
      }
    }
    pnt = str?.slice(k, str?.length);
    tempPoints.push(pnt || "Not provided");
    // setPoints(tempPoints)
    return tempPoints;
    // tempPoints = []
  };
  // const keys = ["question1", "question1"];
  const [showAppInvite, setShowAppInvite] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showAnswer, setShowAnswer] = useState(false);
  let divRef = useRef(null);
  return (
    <div className="questions-container">
      {Object.keys(props.questions)?.map((key, i) => {
        return (
          <div
            className="question-answer-container border pl-3 pt-2 my-2 mr-4 bg-light"
            key={key}
          >
            <Paragraph>
              <b className="text-danger">Que : </b>
              <b>{props.questions[key]?.question}</b>
            </Paragraph>

            <Paragraph
            // ellipsis={{ expandable: true, rows: 3, symbol: "more" }}
            >
              <b className="text-success">Ans :</b>
              {/* {i === 0 && showAnswer ? ( */}
              {true ? (
                // points.map((pnt, i) => <div key={i}>{pnt}</div>)
                getPoints(props.questions[key]?.answer).map((pnt, i) => (
                  <div key={i}>{pnt}</div>
                ))
              ) : (
                <Button
                  type="link"
                  onClick={
                    i === 0
                      ? () => setShowAnswer(true)
                      : () => {
                          setShowAppInvite(true);
                          divRef.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                  }
                >
                  View Answer
                </Button>
              )}
            </Paragraph>
          </div>
        );
      })}
      <div>
        <Button
          type="link"
          block={true}
          size="small"
          onClick={() => setShowAppInvite(!showAppInvite)}
          // icon={showAppInvite ? <DownOutlined /> : <UpOutlined />}
        >
          <span>
            {showAppInvite ? (
              <span>
                Hide more
                <UpOutlined
                  style={{
                    fontSize: "10px",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                />
              </span>
            ) : (
              <span>
                View More
                <DownOutlined
                  style={{
                    fontSize: "10px",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                />
              </span>
            )}
          </span>
        </Button>
        <div className="mt-3" ref={(ref) => (divRef = ref)}>
          {showAppInvite && (
            <AppInviteCard title="Download Jan Elaaj App To View More"></AppInviteCard>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionCard;
