import React from "react";
import { Redirect } from "react-router-dom";
import CardHolderFrame from "../containers/CardHolderFrame";
import DealerPage from "./DealerPage";
import DistributerPage from "./DistributerPage";
import SubDealerPage from "./SubDealerPage";

const EmpDashboard = (props) => {
  const empRole = JSON.parse(localStorage.getItem("empRole"));
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/emp-reports",
      pathName: "Reports and Payments",
    },
    {
      path: "/emp-dashboard",
      pathName: "Dashboard",
    },
  ];
  const holderProps = {
    routes: routes,
    title: empRole?.role === "DISTRIBUTER" ? "DISTRIBUTOR" : empRole?.role,
    bodyStyle: { padding: "0px 10px" },
    // loader: loader,
  };
  if (localStorage.getItem("empEmail")) {
    if (empRole?.role === "DEALER") {
      return (
        <CardHolderFrame {...holderProps}>
          <DealerPage {...empRole} {...props}></DealerPage>;
        </CardHolderFrame>
      );
    } else if (empRole?.role === "DISTRIBUTER") {
      return (
        <CardHolderFrame {...holderProps}>
          <DistributerPage {...empRole} {...props}></DistributerPage>
        </CardHolderFrame>
      );
    } else if (empRole?.role === "SUB_DEALER") {
      return (
        <CardHolderFrame {...holderProps}>
          <SubDealerPage {...empRole} {...props}></SubDealerPage>
        </CardHolderFrame>
      );
    } else return <Redirect to="/goLiveVerification"></Redirect>;
  } else return <Redirect to="/"></Redirect>;
};

export default EmpDashboard;
