import { Card, Empty } from "antd";
import React from "react";
const { Meta } = Card;
const ListofLanguages = (props) => {
  return (
    <div className=" pt-2 pb-4 px-4">
      <div className="text-center" style={{ fontSize: "1.5rem" }}>
        Languages Spoken
      </div>
      {props?.doctorLanguagInfo?.length > 0 ? (
        props?.doctorLanguagInfo?.map((lan, i) => (
          <Meta
            style={{ fontSize: "1.25rem" }}
            hoverable
            title={`${i + 1}. ${lan?.language_name}`}
            key={lan?.language_id}
          ></Meta>
        ))
      ) : (
        <Empty description="No Languages added "></Empty>
      )}
    </div>
  );
};

export default ListofLanguages;
