import React from "react";

import { Button, Card, Image } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { styles } from "../assets/styles/servicesStyles";
import "../assets/styles/serviceStyles.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Meta from "antd/lib/card/Meta";
import { connect } from "react-redux";

const Services = (props) => {
  const RenderServiceItem = ({ service, animation }) => {
    return (
      <AnchorLink
        href="#search_services"
        className="link"
        offset={150}
        // onClick={() => props.onClick(service.role)}
      >
        <Card
          cover={
            <Image
              placeholder={true}
              preview={false}
              src={service.image}
              alt={service.title}
            ></Image>
          }
          hoverable
          bordered
          style={{
            maxWidth: "450px",
            textAlign: "justify",
            animation: animation,
          }}
          // actions={[
          //  ,
          //   // >{`Search ${service.title} near by`}</Button>,
          // ]}
        >
          <Meta
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{service.title}</div>
                <div>
                  <Button
                    type="link"
                    // icon={}
                    // shape="round"
                    size="small"
                  >
                    {`Search `}
                    <RightOutlined
                      style={{ display: "inline-flex", fontSize: "0.75rem" }}
                    />
                  </Button>
                </div>
              </div>
            }
            description={service.description}
          ></Meta>
        </Card>
      </AnchorLink>
    );
  };

  const services = props.services.map((item, index) => {
    return (
      <div
        key={item.id}
        // style={{
        //   display: "flex",
        //   // justifyContent: "space-around",
        //   alignItems: "center",
        // }}
        // className="row mr-0"
        className="mb-4 col-md-3"
      >
        <RenderServiceItem
          service={item}
          {...props}
          animation={
            index === props.ui.selectedServiceIndex ? "highlight 2s 2" : ""
          }
        />
      </div>
    );
  });

  const features = props.features.map((feature) => {
    return (
      <div
        key={feature.id}
        // className="flex-fill flex-grow-1 flex-shrink-1 col-md-5 ml-md-4 mr-md-4 m-lg-4 m-xl-5"
        style={{
          maxWidth: 350,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          textAlign: "justify",
        }}
        className="col-sm-3"
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <img
            src={feature.image}
            alt={feature.feature}
            width="30%"
            style={{
              marginRight: -20,
            }}
          />
          <div style={styles.title} className="">
            {feature.feature}
          </div>
        </div>
        <div style={styles.description} className="normal mb-5">
          {feature.description}
        </div>
      </div>
    );
  });

  return (
    <div className="container-fluid">
      <h3 className="text-center m-3 p-3" style={{ fontSize: "2.5rem" }}>
        Services
      </h3>
      <div className="row mx-0" style={{ justifyContent: "space-evenly" }}>
        {services}
      </div>
      <div>
        <div className="d-flex flex-row flex-wrap justify-content-center why-choose-us">
          <h3 className="col-12 mt-3 text-center" style={{ ...styles.heading }}>
            Why Choose Us ?
          </h3>
          <p
            style={{
              ...styles.headText,
              ...styles.normalText,
              fontSize: "1.25rem",
              color: "#002329",
            }}
            className="normal"
          >
            Our mission is to serve our patients and community with exceptional
            quality, affordable healthcare in a comfortable, caring and positive
            environment from trusted and verified doctors, medical
            practitioners.
          </p>
        </div>
        <div
          className="my-4 row mr-0"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          {features}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

export default connect(mapStateToProps)(Services);
