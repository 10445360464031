import { Card, message, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "../../shared/apis";
import DocDetailProfile from "./DocDetailProfile";
import ListofLanguages from "./ListofLanguages";
import ListofServices from "./ListofServices";
import ListofTimmings from "./ListofTimmings";
const { TabPane } = Tabs;
function DoctorDetailsCard(props) {
  const [loader, setLoader] = useState(false);
  const [docDetails, setDocDetails] = useState({ doctorinfo: [{}] });
  const isChemist = props?.doc?.ldoctorid?.includes("CHE");
  useEffect(() => {
    (async function () {
      setLoader(true);
      await axios({
        ...apis.getdoctordetails,
        data: {
          dlmid: props?.doc?.ldlm_id,
          req_origin: props?.reqOrigin || "userApp",
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
          setDocDetails(res.data);
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong");
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex", margin: "auto" }}>
      <Card
        className="container-fluid"
        style={{
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          marginTop: "0.8rem",
        }}
        bodyStyle={{ padding: "0px" }}
        loading={loader}
      >
        <Tabs centered tabPosition="top" className="w-100">
          <TabPane key="profile" tab="PROFILE">
            <DocDetailProfile
              intro={props?.doc?.ldoctorintro}
              {...docDetails}
            ></DocDetailProfile>
          </TabPane>
          <TabPane key="timings" tab="TIMINGS">
            <ListofTimmings
              daytiminginfo={docDetails?.daytiminginfo}
              serviceinfo={docDetails?.serviceinfo}
              isChemist={isChemist}
            ></ListofTimmings>
          </TabPane>
          <TabPane key="services" tab="SERVICES">
            <ListofServices
              serviceinfo={docDetails?.serviceinfo}
              isChemist={isChemist}
            ></ListofServices>
          </TabPane>
          <TabPane key="languages" tab="LANGUAGES">
            <ListofLanguages {...docDetails}></ListofLanguages>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default DoctorDetailsCard;
