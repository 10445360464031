import * as ActionTypes from '../ActionTypes'
import { baseUrl } from '../../shared/baseUrl'

// GETTING CITIES - THUNK that returns a function instead of a object
export const fetchCities = () => dispatch => {
  dispatch(citiesLoading())
  return fetch(baseUrl + 'getcitylist', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(
      response => {
        if (response.ok) return response
        else {
          let error = new Error(
            'Error ' + response.status + ': ' + response.statusText
          )
          error.response = response
          throw error // server is responding with an error response code, will be handled by catch
        }
      },
      error => {
        // if client unable to connect to the server
        throw new Error(error.message)
      }
    )
    .then(response => response.json())
    .then(response => {
      dispatch(addCities(response))
    })
    .catch(error => dispatch(citiesFailed(error.message)))
}

export const citiesLoading = () => ({
  type: ActionTypes.CITIES_LOADING
})

// CITIES STATE CHANGE
export const addCities = cities => ({
  type: ActionTypes.ADD_CITIES,
  payload: cities
})

export const citiesFailed = errmess => ({
  type: ActionTypes.CITIES_FAILED,
  payload: errmess
})

// GETTING LOCALITY - THUNK
export const fetchLocalities = cityname => dispatch => {
  dispatch(localitiesLoading())
  return fetch(baseUrl + 'getlocality', {
    method: 'post',
    body: JSON.stringify(cityname),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(
      response => {
        if (response.ok) return response
        else {
          let error = new Error(
            'Error ' + response.status + ': ' + response.statusText
          )
          error.response = response
          throw error // server is responding with an error response code, will be handled by catch
        }
      },
      error => {
        // if client unable to connect to the server
        throw new Error(error.message)
      }
    )
    .then(response => response.json())
    .then(response => {
      dispatch(addLocalities(response))
    })
    .catch(error => dispatch(localitiesFailed(error.message)))
}

export const localitiesLoading = () => ({
  type: ActionTypes.LOCALITIES_LOADING
})

// LOCALITIES STATE CHANGE
export const addLocalities = localities => ({
  type: ActionTypes.ADD_LOCALITIES,
  payload: localities
})

export const localitiesFailed = errmess => ({
  type: ActionTypes.LOCALITIES_FAILED,
  payload: errmess
})

// GETTING LOCALITY - THUNK
export const fetchSpecialities = role => dispatch => {
  dispatch(specialitiesLoading())
  return fetch(baseUrl + 'getspeciality', {
    method: 'post',
    body: JSON.stringify(role),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(
      response => {
        if (response.ok) return response
        else {
          let error = new Error(
            'Error ' + response.status + ': ' + response.statusText
          )
          error.response = response
          throw error // server is responding with an error response code, will be handled by catch
        }
      },
      error => {
        // if client unable to connect to the server
        throw new Error(error.message)
      }
    )
    .then(response => response.json())
    .then(response => {
      dispatch(addSpecialities(response))
    })
    .catch(error => dispatch(specialitiesFailed(error.message)))
}

export const specialitiesLoading = () => ({
  type: ActionTypes.SPECIALITIES_LOADING
})

// SPECIALITIES STATE CHANGE
export const addSpecialities = specialities => ({
  type: ActionTypes.ADD_SPECIALITIES,
  payload: specialities
})

export const specialitiesFailed = errmess => ({
  type: ActionTypes.SPECIALITIES_FAILED,
  payload: errmess
})

// GETTING LOCALITY - THUNK
export const getDocs = info => dispatch => {
  dispatch(docsLoading())
  return fetch(baseUrl + 'getdoctorsbysearchlimit', {
    method: 'post',
    body: JSON.stringify(info),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(
      response => {
        if (response.ok) return response
        else {
          let error = new Error(
            'Error ' + response.status + ': ' + response.statusText
          )
          error.response = response
          throw error // server is responding with an error response code, will be handled by catch
        }
      },
      error => {
        // if client unable to connect to the server
        throw new Error(error.message)
      }
    )
    .then(response => response.json())
    .then(response => {
      dispatch(addDocs(response))
    })
    .catch(error => dispatch(docsFailed(error.message)))
}

export const docsLoading = () => ({
  type: ActionTypes.DOCS_LOADING
})

// DOCS STATE CHANGE
export const addDocs = docs => ({
  type: ActionTypes.ADD_DOCS,
  payload: docs
})

export const docsFailed = errmess => ({
  type: ActionTypes.DOCS_FAILED,
  payload: errmess
})

// GETTING DETAILS FOR A SPECIFIC DOCTOR - THUNK
export const getDoc = dlmid => dispatch => {
  dispatch(docLoading())
  return fetch(baseUrl + 'getdoctordetails', {
    method: 'post',
    body: JSON.stringify(dlmid),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(
      response => {
        if (response.ok) return response
        else {
          let error = new Error(
            'Error ' + response.status + ': ' + response.statusText
          )
          error.response = response
          throw error // server is responding with an error response code, will be handled by catch
        }
      },
      error => {
        // if client unable to connect to the server
        throw new Error(error.message)
      }
    )
    .then(response => response.json())
    .then(response => {
      dispatch(addDoc(response))
    })
    .catch(error => dispatch(docFailed(error.message)))
}

export const docLoading = () => ({
  type: ActionTypes.DOC_LOADING
})

// DOC STATE CHANGE
export const addDoc = doc => ({
  type: ActionTypes.ADD_DOC,
  payload: doc
})

export const docFailed = errmess => ({
  type: ActionTypes.DOC_FAILED,
  payload: errmess
})

// HashTags

export const setHashTags = tags => ({
  type: ActionTypes.SET_HASHTAGS,
  payload: tags
})
export const setExpertTip = tags => ({
  type: ActionTypes.SET_EXPERT_TIPS,
  payload: tags
})
export const setOneMin = data => ({
  type: ActionTypes.SET_ONE_MIN,
  payload: data
})
export const setAllPosts = data => ({
  type: ActionTypes.SET_ALL_POSTS,
  payload: data
})
export const setMyPosts = data => ({
  type: ActionTypes.SET_MY_POSTS,
  payload: data
})
