import React, { useEffect, useState } from "react";
import { Empty, Button, Modal, Tabs, Typography, message } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import axios from "axios";
import { apis } from "../../shared/apis";
import AddServiceandRateForm from "./AddServiceandRateForm";
import DiscountCard from "./DiscountCard";
import CardHolderFrame from "../containers/CardHolderFrame";
const { TabPane } = Tabs;
const { Paragraph } = Typography;
const ManageDiscountsList = (props) => {
  const [loader, setLoader] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [shouldApply, setShouldApply] = useState(false);
  const [showServicesandRatesForm, setShowServicesandRatesForm] =
    useState(false);

  const [allDiscountsList, setDiscountsList] = useState([]);
  const [activeKey, setActiveKey] = useState("0");

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
    {
      path: "/choose-location-list",
      pathName: "Clinics List",
    },
    {
      path: "/manage-clinic",
      pathName: "Manage Clinic",
    },
    {
      path: "/manage-discounts",
      pathName: "Manage Discounts",
    },
  ];

  useEffect(() => {
    const fetchDiscounts = async () => {
      setLoader(true);
      await axios({
        ...apis.managediscount,
        data: {
          dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          }
          setDiscountsList(res.data);
        })
        .catch((err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          }
        });
    };
    fetchDiscounts();
  }, [shouldUpdate]);

  const updatedFlagArray = (oldArr, ind, flag) => {
    setShouldApply(true);
    let temp = [...oldArr];
    temp[ind] = {
      ...oldArr[ind],
      discountflag: flag ? "Y" : "N",
    };
    return temp;
  };

  const getValues = () => {
    let v = [];
    days.forEach((day, i) => {
      if (allDiscountsList[day.toLowerCase()]?.length)
        allDiscountsList[day.toLowerCase()]?.forEach((obj, i) => {
          v.push({ flag: obj.discountflag, timeid: obj.timeid });
        });
    });
    return v;
  };
  const applyChanges = async () => {
    setIsApplying(true);
    await axios({
      ...apis.updatemanagediscount,
      data: {
        locid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        values: getValues(),
      },
    })
      .then((res, err) => {
        setIsApplying(false);
        if (err) {
          message.error("Something Went Wrong");
        } else {
          message.success("Successfully Updated!");
          setShouldApply(false);
        }
      })
      .catch((err) => {
        setIsApplying(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  return (
    <div className="container">
      <CardHolderFrame
        headStyle={cardHeaderStyle}
        title="Manage Discounts"
        showPath={true}
        routes={routes}
        loading={loader}
        {...props}
        // extra={[
        //   <Button
        //     key={Math.random()}
        //     type="primary"
        //     shape="round"
        //     size="large"
        //     icon={
        //       <PlusOutlined
        //         style={{ display: "inline-flex", alignItems: "center" }}
        //       />
        //     }
        //     onClick={() => setShowServicesandRatesForm(true)}
        //   >
        //     Add Discount
        //   </Button>,
        // ]}
      >
        <Paragraph
          strong={true}
          className=" p-1 py-3 mb-2"
          style={{
            fontSize: "1rem",
            backgroundColor: "#fbfbfb",
            width: "100%",
            margin: "auto",
            textAlign: "center ",
          }}
        >
          You can Enable Discounts for any of your time slots for the current
          locations.
          <br /> Discounts will be applicable only on the options that you
          select.
        </Paragraph>

        <Tabs
          centered
          tabBarExtraContent={
            <Button
              type="primary"
              shape="round"
              disabled={!shouldApply}
              onClick={applyChanges}
              loading={isApplying}
            >
              {isApplying ? "Applying... " : "Apply"}
            </Button>
          }
          onChange={(a, b) => {
            setActiveKey(a);
          }}
          activeKey={activeKey}
        >
          {days.map((day, ind) => (
            <TabPane
              tab={
                // <div style={{ textTransform: "uppercase", padding: "7px 5px" }}>
                //   <Button shape="circle" type="primary" size="large">
                //     {day.substr(0, 3)}
                //   </Button>
                // </div>
                day
              }
              key={ind}
              centered
            >
              {allDiscountsList[day.toLowerCase()]?.length ? (
                allDiscountsList[day.toLowerCase()]?.map((disc, i) => (
                  <div key={i + Math.random()} className="mb-3">
                    <DiscountCard
                      {...disc}
                      index={i}
                      updateIsChecked={(i, flag) => {
                        setDiscountsList({
                          ...allDiscountsList,
                          [day.toLowerCase()]: [
                            ...updatedFlagArray(
                              allDiscountsList[day.toLowerCase()],
                              i,
                              flag
                            ),
                          ],
                        });
                      }}
                      updateList={() => setShouldUpdate(!shouldUpdate)}
                    ></DiscountCard>
                  </div>
                ))
              ) : (
                <Empty></Empty>
              )}
            </TabPane>
          ))}
        </Tabs>
      </CardHolderFrame>
      <Modal
        centered
        visible={showServicesandRatesForm}
        onCancel={() => setShowServicesandRatesForm(false)}
        title={<div className="text-center">Add New Service</div>}
        footer={[]}
      >
        <AddServiceandRateForm
          closeForm={() => setShowServicesandRatesForm(false)}
          updateList={() => setShouldUpdate(!shouldUpdate)}
        />
      </Modal>
    </div>
  );
};

export default React.memo(ManageDiscountsList);
