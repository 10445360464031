import React from "react";
import { Result, Button } from "antd";
import PaymentSuccessCard from "../PaymentSuccessCard";
const Razorpaypaymentcomplete = (props) => {
  const invoice = JSON.parse(localStorage.getItem("rpInvoice"));
  console.log("invoice : ", invoice, invoice[5]?.data?.txn_status);
  const stats = {
    PENDING: {
      title: "",
      status: "warning",
      className: "text-warning",
    },
    SUCCESS: {
      status: "success",
      className: "text-success",
    },
    ERROR: {
      status: "error",
      className: "text-danger",
    },
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Result
        // status={"info"}
        status={stats[invoice[5]?.data?.txn_status]?.status || "info"}
        title={
          <div
            className={`text-status ${
              stats[invoice[5]?.data?.txn_status]?.className || ""
            }`}
          >
            {invoice[5]?.data?.txn_info || "Transaction Initiated"}
          </div>
        }
        subTitle="Thank You for using Jan Elaaj Services."
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => props.history.push("/dashboard")}
            size="large"
          >
            Go To Dashboard
          </Button>,
          //   <Button key="buy">Buy Again</Button>,
        ]}
      />
      <PaymentSuccessCard data={invoice} statusCode={200}></PaymentSuccessCard>
    </div>
  );
};

export default Razorpaypaymentcomplete;
