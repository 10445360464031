import React, { useState } from "react";
import { Button, message, notification, Input } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";

// import DownloadRecieptButton from "../userDashBoard/DownloadRecieptButton";
import { apis } from "../../shared/apis";
import axios from "axios";
import SymptomsForm from "../userDashBoard/SymptomsForm";
// import AppointmentDetails from "../userDashBoard/AppointmentDetails";
// import GetDirectionBtn from "../userDashBoard/GetDirectionBtn";
const PatientAppointmentCard = (props) => {
  // console.log("GSG Props: ", props);
  const [isGoing, setIsGoing] = useState({
    flag: false,
    status: "Get Set Go",
  });
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  // const [docDetails, setDocDetails] = useState({});
  // const [patDetails, setPatDetails] = useState({});
  // const [showModalForm, setShowModalForm] = useState(false);
  // const [isBlocked, setIsBlocked] = useState(false);

  const [isOTPVerified, setIsOTPverified] = useState(false);

  // const [dependents, setDependents] = useState([]);

  const formatTime = (time) => {
    let str = "";
    if (typeof time !== "undefined")
      str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  const verifyOtpHandler = async () => {
    setLoader(true);
    await axios({
      ...apis.postVerifyApptOtp,
      data: { otp: otp, pdla_id: props.pdla_id },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) {
          message.error("Something Went Wrong");
        } else {
          if (res.data.status_code === 0) {
            message.error(res.data.status);
          } else {
            message.success(res.data.status);
            setIsOTPverified(true);
          }
          // console.log('Res.dara: ', res.data)
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  const joinVideoCallHandler = () => {
    let roomName = props?.pdla_id;
    let displayName = props?.role;

    props.history.push({
      pathname: `/jitsi/${props?.pdla_id}/${roomName}/${displayName}`,
      state: {
        pdlaid: props?.pdla_id,
        roomName,
        displayName,
        timeslot: `${formatTime(
          props?.appointment_time?.split(" ")[0]
        )}-${formatTime(props?.appointment_time?.split(" ")[2])}`,
        showUploadForm: false,
        isUser: false,
      },
    });
  };
  const getSetGoHandler = async () => {
    let input = {
      pdla_id: props?.pdla_id,
      id: props?.empid,
      subject: props?.role,
    };
    setIsGoing({ status: "Going...", flag: true });
    await axios({ ...apis.postUserPresentAndDocAvailable, data: input })
      .then((res, err) => {
        setIsGoing({ status: "Get Set Go", flag: false });
        if (err) {
          message.error("Something Went Wrong !");
        }
        if (res.data.info.pdla_partner_present === "N") {
          message.info("Doctor is not available presently. Please stay Online");
        }
        if (res.data?.info?.pdla_calling_mode === "0") {
          notification.success({
            message: "Calling Mode - Video Call",
            description:
              "Doctor is Video calling you Please stay online. Click To Join Video Call",
            btn: (
              <Button
                onClick={joinVideoCallHandler}
                type="primary"
                shape="round"
                size="large"
              >
                Click To Join
              </Button>
            ),
            // onClick: joinVideoCallHandler,
          });
        }
        if (res.data?.info?.pdla_calling_mode === "1") {
          notification.success({
            message: "Calling Mode - WhatsApp",
            description:
              "Doctor is calling you Over WhatsApp, Please stay online",
          });
        }
        if (res.data?.info?.pdla_calling_mode === "2") {
          notification.success({
            message: "Calling Mode - Phone",
            description: "Doctor is calling you over Phone, Please stay online",
          });
        }
        // console.log("Res of postUPnDA : ", res.data);
      })
      .catch((err) => {
        setIsGoing({ status: "Get Set Go", flag: false });
        if (err) {
          message.error("Something Went Wrong !");
        }
      });
  };
  return (
    // <div>
    //   <div className="my-2 p-1 appointment-card ">
    //     <Card
    //       hoverable
    //       type="inner"
    //       headStyle={{
    //         padding: 0,
    //       }}
    //       bodyStyle={{ padding: "0.20rem" }}
    //       title={
    //         <div
    //           style={{
    //             display: "flex",
    //             alignItems: isBrowser ? "center" : "flex-start",
    //             justifyContent: isBrowser ? "space-between" : "center",
    //             flexDirection: isBrowser ? "row" : "column",
    //             fontSize: "1.25rem",
    //             fontWeight: "400",
    //             padding: "0rem 1rem",
    //           }}
    //         >
    //           <div>
    //             {props?.pdla_status === 1
    //               ? "Done"
    //               : props?.pdla_status === 2
    //               ? "Cancelled"
    //               : props?.pdla_status === 3
    //               ? "Doctor Not available"
    //               : props?.pdla_status === 4
    //               ? "Dormant"
    //               : null}
    //           </div>
    //           <div
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <div className="mx-4">
    //               <CalendarOutlined
    //                 style={{ display: "inline-flex", marginRight: "0.5rem" }}
    //               />
    //               {props?.appointment_datetime.split(" ")[0]}
    //             </div>
    //             <div className="mx-4">
    //               <ClockCircleOutlined
    //                 style={{ display: "inline-flex", marginRight: "0.5rem" }}
    //               />
    //               {formatTime(props?.appointment_time.split(" ")[0])} -{" "}
    //               {formatTime(props?.appointment_time.split(" ")[2])}
    //             </div>
    //           </div>
    //         </div>
    //       }
    //     >
    //       <div>
    //         <div
    //           className="patient-card-body"
    //           style={{
    //             display: "flex",
    //             alignItems: isBrowser ? "center" : "flex-start",
    //             justifyContent: isBrowser ? "space-between" : "center",
    //             fontSize: "1.05rem",
    //             flexDirection: isBrowser ? "row" : "column",
    //             padding: "0.5rem",
    //           }}
    //         >
    //           <div
    //             style={{
    //               display: "flex",
    //               // justifyContent: "center",
    //               alignItems: "center",
    //             }}
    //             className="col-sm-6"
    //           >
    //             <div className="m-1 mr-4">{props?.role}</div>
    //             <div>
    //               {props?.pay_now_flag === "N" ? (
    //                 <span className="text-danger">₹{props?.amount} Unpaid</span>
    //               ) : (
    //                 <span className="text-success">₹{props?.amount} Paid</span>
    //               )}
    //             </div>
    //           </div>
    //           <div className="col-sm-6">
    //             <Input
    //               // size="large"
    //               onChange={(e) => {
    //                 setOtp(e.target.value);
    //               }}
    //               onPressEnter={verifyOtpHandler}
    //               placeholder="Enter OTP"
    //               suffix={
    //                 <Button
    //                   type="link"
    //                   loading={loader}
    //                   onClick={verifyOtpHandler}
    //                 >
    //                   {loader ? "Verifying..." : "Verify"}
    //                 </Button>
    //               }
    //             ></Input>
    //           </div>
    //         </div>

    //         <div>
    //           {/* <Divider style={{ margin: "5px 0px" }}> */}
    //           <div
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "space-around",
    //               width: "100%",

    //               padding: "0.5rem",
    //               // flexDirection: isBrowser ? "row" : "column",
    //             }}
    //           >
    //             <Button
    //               type="danger"
    //               // size="large"
    //               // shape="round"
    //               className="my-1"
    //               onClick={getSetGoHandler}
    //               loading={isGoing?.flag}
    //               icon={
    //                 <UsergroupAddOutlined style={{ display: "inline-flex" }} />
    //               }
    //               disabled={!isOTPVerified}
    //             >
    //               {isGoing?.status}
    //             </Button>
    //             <SymptomsForm
    //               {...props}
    //               disabled={!isOTPVerified}
    //             ></SymptomsForm>
    //           </div>
    //           {/* </Divider> */}

    //           {/* {props?.pay_now_flag === "Y" ? (
    //             <DownloadRecieptButton {...props}></DownloadRecieptButton>
    //           ) : null} */}
    //           {/* <AppointmentDetails {...props}></AppointmentDetails> */}
    //         </div>
    //       </div>
    //     </Card>
    //   </div>
    //             </div>
    <>
      <td>{props?.pdla_id || "-"}</td>
      <td style={{ whiteSpace: "nowrap" }}>
        {/* <div className="mx-4">
          <CalendarOutlined
            style={{ display: "inline-flex", marginRight: "0.5rem" }}
          /> */}
        {props?.appointment_datetime.split(" ")[0] || "-"}
        {/* </div> */}
      </td>
      <td>
        {/* <div className="m-1 mr-4"> */}
        {props?.role || "-"}
        {/* </div> */}
      </td>
      <td>
        {/* <div> */}
        {props?.pdla_status === 1
          ? "Done"
          : props?.pdla_status === 2
          ? "Cancelled"
          : props?.pdla_status === 3
          ? "Doctor Not available"
          : props?.pdla_status === 4
          ? "Dormant"
          : "Active"}
        {/* </div> */}
      </td>
      {/* <td>
        {" "}
        <div>
          {props?.pay_now_flag === "N" ? (
            <span className="text-danger">₹{props?.amount} Unpaid</span>
          ) : (
            <span className="text-success">₹{props?.amount} Paid</span>
          )}
        </div>
      </td> */}
      <td>
        {/* <div> */}
        {props?.pay_now_flag === "N" ? (
          <span className="text-danger">₹{props?.amount} Unpaid</span>
        ) : (
          <span className="text-success">₹{props?.amount} Paid</span>
        )}
        {/* </div> */}
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        {/* <div className="mx-4"> */}
        {/* <ClockCircleOutlined
            style={{ display: "inline-flex", marginRight: "0.5rem" }}
          /> */}
        {formatTime(props?.appointment_time.split(" ")[0])} -{" "}
        {formatTime(props?.appointment_time.split(" ")[2])}
        {/* </div> */}
      </td>
      <td>{props?.Distributer_id || "-"}</td>
      <td>{props?.Dealer_id || "-"}</td>
      <td>{props?.Sub_Dealer_id || "-"}</td>
      <td>{props?.Distributer_name || "-"}</td>
      <td>{props?.Dealer_name || "-"}</td>
      <td>{props?.Sub_dealer_name || "-"}</td>
      <td style={{ minWidth: "200px" }}>
        <Input
          // size="large"
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          onPressEnter={verifyOtpHandler}
          placeholder="Enter OTP"
          suffix={
            <Button
              type="link"
              loading={loader}
              onClick={verifyOtpHandler}
              disabled={props?.pdla_status !== 0}
            >
              {loader ? "Verifying..." : "Verify"}
            </Button>
          }
          disabled={props?.pdla_status !== 0}
        ></Input>
      </td>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",

            // padding: "0.5rem",
            // flexDirection: isBrowser ? "row" : "column",
          }}
        >
          <Button
            type="danger"
            // size="large"
            // shape="round"
            className="my-1"
            onClick={getSetGoHandler}
            loading={isGoing?.flag}
            icon={<UsergroupAddOutlined style={{ display: "inline-flex" }} />}
            disabled={!isOTPVerified}
          >
            {isGoing?.status}
          </Button>
          <SymptomsForm
            {...props}
            pdlaid={props?.pdla_id}
            disabled={!isOTPVerified}
          ></SymptomsForm>
        </div>
      </td>
    </>
  );
};

export default PatientAppointmentCard;
