import React, { useState } from "react";
import axios from "axios";
import { Button, Input, message, Modal, Card, Empty, Checkbox } from "antd";
import "../assets/styles/AppointmentCard.css";
import { baseUrl } from "../shared/baseUrl";
import { apis } from "../shared/apis";
import SymptomCard from "./intermediate/SymptomCard";
import {
  FileTextOutlined,
  // ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import PrescriptionForm from "./PrescriptionForm";
import { connect } from "react-redux";

function AppointmentCard(props) {
  const {
    patientmobile,
    // dcsmamount,
    // patientemail,
    pdlaprocessed,
    pdlaid,
    // patientid,
    patientname,
    age,
    gender,
    otp,
    timeslot,
    // pdlasymptoms,
    // nextvisitdate,
    pdla_user_present,
    pdla_paynow_flag,
    // pdla_mental_health_flag,
    pdla_symptom_photo1,
    home_collection_flag,
  } = props.data;
  const TimeArr = timeslot.split("-");
  const fromTime = TimeArr[0];
  // const toTime = TimeArr[1];
  const [OTP, setOTP] = useState("");
  const [isOTPValid, setIsOTPValid] = useState(false);
  const [onVideoCall, setonVideoCall] = useState(false);
  const [showSymptom, setShowSymptom] = useState(false);
  const [isLoadingSymptom, setIsLoadingSymptom] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [pdlaPhoto, setPdlaPhoto] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const nowHours = new Date().getHours();
  const nowMinutes = new Date().getMinutes();
  const now = nowHours + ":" + (nowMinutes < 10 ? "0" : "") + nowMinutes;
  const year = new Date().getFullYear() + "";
  let month = new Date().getMonth() + 1;
  month = (month < 10 ? "0" : "") + month;
  let day = new Date().getDate();
  day = (day < 10 ? "0" : "") + day;
  let recDArr = props?.isMissed.split("-");
  let rYear = recDArr[0];
  let rMonth = recDArr[1];
  let rDay = recDArr[2][0] + recDArr[2][1];
  const isOld = rYear <= year && rMonth <= month && rDay < day;
  const isEarly = rYear >= year && rMonth >= month && rDay > day;
  // const isToday = rYear === year && rMonth === month && rDay === day;
  const isDisabled =
    pdlaprocessed === "Y" ||
    // !(fromTime <= now && now <= toTime) ||  //Uncomment this for make timely disabled
    isOld ||
    isEarly;
  const roomName = `${pdlaid?.slice(-4)}`;
  const displayName = localStorage?.getItem("partnerEmail");

  const homeDeliverMessage =
    props?.selectedRole?.role === "CHE"
      ? "Medicine Home Delivery"
      : props?.selectedRole?.role === "PAT"
      ? "Sample Home Delivery"
      : false;

  const sendSMS = async () => {
    await axios({
      ...apis.sendVideoMeetingSms,
      data: {
        pdlaid: pdlaid,
        calling_mode: "0",
        number: patientmobile,
      },
    })
      .then((res, err) => {
        if (err) message.error("Err while Sending SMS");
        else message.success("SMS SENT ");
      })
      .catch((err) => {
        if (err) message.error("Err while Sending SMS");
      });
  };
  const vedioCallHandler = () => {
    sendSMS();
    setonVideoCall(true);
    // window.location = `/jitsi/${pdlaid}/${roomName}/${displayName}`;
    // <Redirect to={`/jitsi/${pdlaid}/${roomName}/${displayName}`}></Redirect>;
    props.history.push({
      pathname: `/jitsi/${pdlaid}/${roomName}/${displayName}`,
      state: {
        pdlaid,
        roomName,
        displayName,
        timeslot,
        showUploadForm: true,
        data: props.data,
        patientmobile,
      },
    });
  };

  const whatsappHandler = () => {
    sendSMS();
    window.open(
      `https://api.whatsapp.com/send?phone=+91${patientmobile}`,
      "",
      "_blank"
    );
  };
  const handleOTP = () => {
    if (OTP === otp) {
      message.success("Valid OTP");
      setIsOTPValid(true);
    } else {
      message.error("Invalid OTP");
      setIsOTPValid(false);
    }
  };

  // const handleAPI = (JitsiMeetAPI) => {
  //   console.log(JitsiMeetAPI);
  //   window.open(JitsiMeetAPI._url, "", "_blank");
  //   setonVideoCall(false);
  // };

  const handleSymptom = async () => {
    setShowSymptom(!showSymptom);
    if (!pdlaPhoto) {
      setIsLoadingSymptom(true);
      await axios({
        method: "post",
        url: `${baseUrl}getPdlaPhoto`,
        // data: { pdla_id: "PDLAI" },
        data: { pdla_id: pdlaid },
      })
        .then((res, err) => {
          setIsLoadingSymptom(false);
          if (err) message.error("Something Went Wrong");
          else {
            setPdlaPhoto(res.data);
          }
        })
        .catch((err) => {
          if (err) message.error("Something Went Wrong");
          setIsLoadingSymptom(false);
        });
    }
  };

  const handlePrescription = () => {
    setShowPrescription(true);
  };

  const closePrescription = () => {
    setShowPrescription(false);
  };

  const handleRadComplete = async () => {
    setIsUploading(true);
    let obj = {
      pdla_patient_history: "",
      pdla_prescription_suggested_clinical_test: "",
      pdla_prescription_doctors_observation: "",
      pdla_prescription_medication: "",
      pdla_prescription_others: "",
      pdla_id: pdlaid || props?.pdla_id,
    };
    await axios({ ...apis.postUpdatePrescriptionDetails, data: obj })
      .then((res, err) => {
        if (err) message.error("Error in Uploading: ", err?.message);
        else {
          setIsUploading(false);
          message.success("Prescription Uploaded Successfully !");
        }
      })
      .catch((err) => {
        // console.log("Error in Uploading: ", err);
        setIsUploading(false);
        message.error("Error in Uploading: ", err?.message);
      });
  };
  const handleRad = () => {
    Modal.confirm({
      title: "Are you sure you want to mark this appointment complete ?",
      okText: "Yes",
      onOk: handleRadComplete,
    });
  };

  console.log("Appt Card  props ", props);

  return (
    <div className="mb-4 appointment-card ">
      <Card
        hoverable
        type="inner"
        headStyle={{
          padding: 0,
          backgroundColor: `${
            isEarly
              ? "#1890ff"
              : isOld
              ? "#f5222d"
              : fromTime > now
              ? "#1890ff"
              : isDisabled
              ? "#f5222d"
              : "#52c41a"
          }`,
        }}
        title={
          <div
            style={{
              fontSize: "1.75rem",
              fontWeight: "400",
              padding: "0rem 1rem",
              color: "white",
            }}
          >
            {timeslot}
            <span
              className="float-right"
              style={{ fontSize: "20px", color: "#f0f0f0", lineHeight: 2 }}
            >
              {/* {patientid} */}
              <Input
                placeholder="Enter OTP"
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
                onBlur={handleOTP}
                onPressEnter={handleOTP}
                suffix={
                  isOTPValid ? (
                    <CheckCircleOutlined className="text-success" />
                  ) : (
                    <span className="text-primary">Verify</span>
                  )
                }
                disabled={isDisabled}
              ></Input>
            </span>
          </div>
        }
      >
        <div className="patient-details-container row mx-0">
          <div className="patient-pic col-sm-2 container-fluid">
            <div className="profile-dot">
              {pdla_user_present === "Y" ? (
                <i className="fas fa-circle text-success"></i>
              ) : (
                <i className="fas fa-circle text-danger"></i>
              )}
            </div>
            <img
              src={
                pdla_symptom_photo1.length
                  ? "data:image/jpeg;base64," + pdla_symptom_photo1
                  : `https://joeschmoe.io/api/v1/${
                      gender === "M" ? "male" : "female"
                    }/${patientname}`
              }
              alt={"logo of patient"}
            ></img>
          </div>
          <div className="patient-card-body col">
            <div className="patient-details">
              <div className="patient-name">{patientname}</div>
              <div className="patient-age">
                {age} Yrs,{gender === "M" ? "Male" : "Female"}
              </div>
            </div>
            <div className="top-right-div">
              <div>
                <div>
                  {pdla_paynow_flag === "N" ? (
                    <span className="text-danger">Unpaid</span>
                  ) : (
                    <span className="text-success">Paid</span>
                  )}
                </div>
                {homeDeliverMessage ? (
                  <div>
                    <Checkbox
                      checked={home_collection_flag === "Y" ? true : false}
                    >
                      {homeDeliverMessage}
                    </Checkbox>
                  </div>
                ) : null}
              </div>
            </div>

            {/* <Button
              className="next-visit-btn btn btn-primary"
              disabled={isDisabled}
            >
              Next Visit Date
            </Button> */}
            <div className="patient-card-actions">
              <Button
                className="btn btn-light patient-card-action"
                onClick={vedioCallHandler}
                // disabled={isDisabled || !isOTPValid}
                disabled={isDisabled}
                icon={<i className="fas fa-video" aria-hidden="true"></i>}
              >
                {onVideoCall ? "Calling... " : "Call Now"}
              </Button>
              <Button
                className="btn btn-light patient-card-action"
                onClick={whatsappHandler}
                // disabled={isDisabled || !isOTPValid}
                disabled={isDisabled}
                icon={<i className="fab fa-whatsapp" aria-hidden="true"></i>}
              >
                Whatsapp
              </Button>
              <Button
                className={`btn btn-light patient-card-action ${
                  showSymptom ? "bg-success text-light" : ""
                }`}
                onClick={handleSymptom}
                disabled={isDisabled}
                icon={<i className="fas fa-user-injured"></i>}
              >
                Symptoms
              </Button>
              <Button
                className={`btn btn-light patient-card-action`}
                onClick={
                  props?.selectedRole?.role === "RAD"
                    ? handleRad
                    : handlePrescription
                }
                disabled={isDisabled || !isOTPValid}
                icon={<FileTextOutlined style={{ display: "inline-flex" }} />}
                loading={isUploading}
              >
                {props?.selectedRole?.role === "RAD"
                  ? "Complete"
                  : "Send Prescription"}
              </Button>
            </div>
          </div>
        </div>
        {showSymptom ? (
          <Card
            loading={isLoadingSymptom}
            title={
              <div className="text-center" style={{ fontSize: "1.25rem" }}>
                {pdlaPhoto?.pdla_symptoms !== "NA" ? (
                  <div>{pdlaPhoto?.pdla_symptoms}</div>
                ) : (
                  "No Symptom"
                )}
              </div>
            }
            bodyStyle={{ padding: "0.25rem" }}
            style={{ margin: "0.5rem 0" }}
            // type="inner"
          >
            <div>
              <div className="row mx-0">
                {pdlaPhoto?.data?.length ? (
                  pdlaPhoto?.data?.map((pdla, i) => (
                    <div key={i + Math.random()} className="col-sm-4 p-2">
                      <SymptomCard {...pdla}></SymptomCard>
                    </div>
                  ))
                ) : (
                  <Empty description="No Symptoms Available"></Empty>
                )}
              </div>
            </div>
          </Card>
        ) : null}
      </Card>
      <Modal
        title={<span>Send Prescription</span>}
        centered
        visible={showPrescription}
        onCancel={closePrescription}
        okText="Upload"
        cancelText="Cancel"
        width={1000}
        wrapClassName="upload-prescription-form"
        footer={[<Button onClick={closePrescription}>Close</Button>]}
      >
        <PrescriptionForm
          pdla_id={pdlaid}
          timeslot={timeslot}
          isPreviewVisible={isPreviewVisible}
          setIsPreviewVisible={setIsPreviewVisible}
        ></PrescriptionForm>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
    selectedRole: state.partnerData?.selectedRole,
  };
};

export default connect(mapStateToProps)(AppointmentCard);
