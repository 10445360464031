import React from "react";
import { connect } from "react-redux";
import { Card, Tooltip } from "antd";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import "../../assets/styles/AppointmentCard.css";
import docRoleLogo from "../../assets/images/role-logo.jpg";
import * as PAC from "../../redux/ActionCreators/PartnerDashActionCreators";
function PartnerRoleCard(props) {
  const handleClickHandler = () => {
    localStorage.setItem("role_id", props?.role?.roleid);
    localStorage.setItem("selectedRole", JSON.stringify(props?.role));
    props.setSelectedRole(props.role);
    if (props?.role?.isReg) props.history.push("/choose-location-list");
    else {
      props.history.push({
        pathname: "/partner-signup",
        state: { role: props?.role },
      });
    }
  };
  return (
    <div className="mb-4  role-card" onClick={handleClickHandler}>
      <Card type="inner" hoverable>
        <div className="role-details-container">
          <div className="patient-pic">
            <img
              src={
                docRoleLogo ||
                "https://previews.123rf.com/images/natis76/natis761205/natis76120500906/13833666-doctor-icon.jpg"
              }
              alt={"logo of Doc"}
            ></img>
          </div>
          <div className="container-fluid px-0">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="patient-name">{props?.role?.role}</div>
              <div style={{ fontSize: "1.25rem" }}>
                {props?.role?.isReg ? (
                  <Tooltip title="Registred" color="#52c41a">
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      title="Registered"
                      style={{ display: "inline-flex" }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Click to Register" color="#f5222d">
                    <ExclamationCircleTwoTone
                      twoToneColor="#f5222d"
                      title="Click to Register"
                      style={{ display: "inline-flex" }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="patient-age">
              {props?.role?.roleName?.toUpperCase()}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedRole: (obj) => dispatch(PAC.setSelectedRole(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerRoleCard);
