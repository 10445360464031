import React, { useState } from "react";
import { Button, message } from "antd";
import { EditTwoTone, ClockCircleTwoTone } from "@ant-design/icons";

import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
import ManageLocCard from "./ManageLocCard";
import axios from "axios";
import { apis } from "../../shared/apis";
import CardHolderFrame from "../containers/CardHolderFrame";
const ManageLocation = (props) => {
  const [loader, setLoader] = useState(false);

  const routes = props?.location?.state?.hideSave
    ? [
        {
          path: "/",
          pathName: "Home",
        },
        {
          path: "/roles-list",
          pathName: "Roles List",
        },
        {
          path: "/choose-location-list",
          pathName: "Clinics List",
        },
        {
          path: "/choose-option",
          pathName: " Service List",
        },
        {
          path: "/manage-clinic",
          pathName: "Manage Clinic",
        },
      ]
    : [
        {
          path: "/",
          pathName: "Home",
        },
        {
          path: "/roles-list",
          pathName: "Roles List",
        },
        {
          path: "/choose-location-list",
          pathName: "Clinics List",
        },
        {
          path: "/manage-clinic",
          pathName: "Manage Clinic",
        },
      ];

  const manageOptions = [
    {
      title: "Timings",
      icon: <ClockCircleTwoTone style={{ display: "inline-flex" }} />,
      path: "/manage-timings",
    },
    {
      title: "Services & Rates",
      icon: <span style={{ fontWeight: "bold", color: "#007bff" }}>₹</span>,
      path: "/manage-services-and-rates",
    },
    {
      title: "Discounts",
      icon: (
        <span
          style={{
            fontWeight: "bold",
            color: "white",
            backgroundColor: "orange",
            padding: "3px 10.65px",
            textAlign: "center",
            borderRadius: "50%",
          }}
        >
          %
        </span>
      ),
      path: "/manage-discounts",
    },
    {
      title: "Edit",
      icon: <EditTwoTone style={{ display: "inline-flex" }} />,
      path: "/edit-clinic",
    },
  ];

  const handleClickHandler = async () => {
    setLoader(true);
    await axios({
      ...apis.checkpoint,
      data: {
        dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong!");
        switch (res.data.progress) {
          case 0:
            message.error("Timmings Not Set");
            // break;
            return;
          case 1:
            message.error("Service Not Set");
            // break;
            return;
          // case 2:
          //   console.log("Case 2");
          //   props.history.push("/upload-documents");
          //   break;
          default:
            // console.log("Case d");
            // props.history.push("/choose-option");
            break;
        }
        if (
          JSON.parse(localStorage.getItem("selectedClinic")).checkpoint !== 3
        ) {
          props.history.push("/upload-documents");
        } else {
          props.history.push("/choose-option");
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong!");
      });
  };
  return (
    <div className="container">
      <CardHolderFrame
        headStyle={cardHeaderStyle}
        title="Manage Clinic"
        showPath={true}
        routes={routes}
        extra={
          props?.location?.state?.hideSave
            ? []
            : [
                <Button
                  key="manage-location-key"
                  type="primary"
                  shape="round"
                  onClick={handleClickHandler}
                  loading={loader}
                >
                  {loader ? "Saving..." : "Save"}
                </Button>,
              ]
        }
      >
        <div className="row mx-0">
          {manageOptions.map((opt, i) => (
            <div key={i + Math.random()} className="col-sm-3">
              <ManageLocCard {...opt} {...props}></ManageLocCard>
            </div>
          ))}
        </div>
      </CardHolderFrame>
    </div>
  );
};

export default ManageLocation;
