/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect } from "react";
import Slider from "react-animated-slider";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  BulbFilled,
  // CalendarFilled,
} from "@ant-design/icons";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "../assets/styles/slider-animations.css";
import "../assets/styles/imgsliderStyles.css";
import carousel01 from "../assets/images/01.png";
import carousel02 from "../assets/images/02.png";
import carousel03 from "../assets/images/03.png";
import carousel04 from "../assets/images/04.png";
import carousel05 from "../assets/images/05.png";
import carousel06 from "../assets/images/06.png";
import carousel07 from "../assets/images/07.png";
import carousel08 from "../assets/images/08.png";
// import carousel0 from "../assets/images/B1.png";
// import carousel1 from "../assets/images/B0.png";
// import carousel2 from "../assets/images/B2.png";
// import carousel3 from "../assets/images/B3.png";
// import carousel4 from "../assets/images/B4_a.png";
// import carousel5 from "../assets/images/B5.png";
// import carousel51 from "../assets/images/B51.jpeg";
// import carousel6 from "../assets/images/B6.png";
// import carousel7 from "../assets/images/B7.png";
// import carousel8 from "../assets/images/B8.png";
import qrCode from "../assets/images/qrCode.PNG";

// import carousel5 from "../assets/images/B4_b.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import HastagsHeadline from "./HastagsHeadline";
import { Affix, Card, Image } from "antd";
import { setSelectedServiceIndex } from "../redux/ActionCreators/UIActionCreators";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
const { Meta } = Card;

const ImageSlider = (props) => {
  // const [tip, setTip] = useState('Loading...')
  // const sliderClassNames = [
  //   { subHeading: "slider-sub-heading-1", button: "slider-button-1" },
  //   { subHeading: "slider-sub-heading-2", button: "slider-button-2" },
  //   { subHeading: "slider-sub-heading-3", button: "slider-button-3" },
  //   { subHeading: "slider-sub-heading-4", button: "slider-button-4" },
  //   { subHeading: "slider-sub-heading-5", button: "slider-button-5" },
  //   { subHeading: "slider-sub-heading-6", button: "slider-button-6" },
  //   { subHeading: "slider-sub-heading-7", button: "slider-button-7" },
  //   { subHeading: "slider-sub-heading-8", button: "slider-button-8" },
  //   { subHeading: "slider-sub-heading-9", button: "slider-button-9" },
  //   { subHeading: "slider-sub-heading-10", button: "slider-button-10" },
  //   { subHeading: "slider-sub-heading-11", button: "slider-button-11" },
  //   { subHeading: "slider-sub-heading-12", button: "slider-button-12" },
  //   { subHeading: "slider-sub-heading-13", button: "slider-button-13" },
  // ];

  // const fetchTip = async () => {
  //   await axios({ ...apis.getNewsOfTheDay })
  //     .then((res, err) => {
  //       if (err) {
  //         message.error('Something Went Wrong')
  //       } else {
  //         let t = ''
  //         res.data?.news.forEach((str, i) => {
  //           t += str + ' | '
  //         })
  //         setTip(t)
  //       }
  //     })
  //     .catch(err => {
  //       if (err) {
  //         message.error('Something Went Wrong')
  //       }
  //     })
  // }

  useEffect(() => {
    // (async function () {
    // })()
    // fetchTip()
  }, []);

  const content = [
    {
      heading: "Collaborative Learning",
      description: "Learn, Share, Spread, Reliable  Health Related Information",
      button: (
        <AnchorLink href="#hashtags" offset={20} className="text-dark">
          <button className="slider-button slider-button-1">Read More</button>
        </AnchorLink>
      ),
      image: carousel01,
      position: 1,
      headClassName: "dark-heading",
      desClassName: "dark-description",
    },
    {
      heading: "Online Consultation",
      description:
        "Connect with trusted and experienced healthcare experts anytime",
      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-1">Read More</button>
        </AnchorLink>
      ),
      image: carousel02,
      position: 2,
      headClassName: "dark-heading",
      desClassName: "dark-description",
    },
    {
      heading: "Trusted Neighborhood Clinics",
      description:
        "Affordable Primary care in your neighborhood clinics and hospitals.",
      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-4">Read More</button>
        </AnchorLink>
      ),
      image: carousel03,
      position: 3,
      headClassName: "light-heading",
      desClassName: "light-description",
    },
    {
      heading: "Trusted Neighborhood Pharmacies",
      description: "Discounted Accredited Pharmacies in your neighbourhood.",
      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-5">Read More</button>
        </AnchorLink>
      ),
      image: carousel04,
      position: 4,
      headClassName: "dark-heading",
      desClassName: "dark-description",
    },
    {
      heading: "Nutritionist & Dietician Online",
      description:
        "Know your nutritional needs and get yourself in appropriate body weight range.",
      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-6">Read More</button>
        </AnchorLink>
      ),
      image: carousel05,
      position: 5,
      headClassName: "light-heading",
      desClassName: "light-description",
    },

    {
      heading: "Mental Health Screening",
      description:
        "This is a disease too !!!  Screen yourself for risks conveniently and in privacy",
      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-9">Read More</button>
        </AnchorLink>
      ),
      image: carousel06,
      position: 6,
      headClassName: "dark-heading",
      desClassName: "dark-description",
    },

    {
      heading: "Preventive Healthcare Screening",
      description:
        "Lifestyle Diseases, Breast Cancer etc may not have early visible symptoms. Get screened conveniently",

      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-1">Read More</button>
        </AnchorLink>
      ),
      image: carousel07,
      position: 7,
      headClassName: "dark-heading",
      desClassName: "dark-description",
    },
    {
      heading: "Mental Health Counselling",
      description:
        "Beat the Mental Health Risk. Get Certified Professional Help on a Click  !!!",

      button: (
        <AnchorLink href="#services" offset={20} className="text-dark">
          <button className="slider-button slider-button-1">Read More</button>
        </AnchorLink>
      ),
      image: carousel08,
      position: 8,
      headClassName: "light-heading",
      desClassName: "light-description",
    },
  ];

  // const [container, setContainer] = useState(null);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Slider className="slider-wrapper" infinite={true} autoplay={3000}>
          {content.map((item, index) => (
            <div
              key={index}
              className={"slider-content"}
              style={{
                background: `url('${item.image}') no-repeat`,
                backgroundSize: "contain",
                // animation:
                //   index === 2
                //     ? "moblieImg 3s infinite"
                //     : index === 8
                //     ? "tabImg 3s infinite"
                //     : null,
              }}
            >
              <div className="inner">
                <div
                  style={{
                    textAlign: "left",
                    margin: "auto",
                    width: "fit-content",
                    paddingTop: "0.5rem",
                  }}
                >
                  <h1 className={"ml-4 " + item.headClassName}>
                    {item?.heading}
                  </h1>
                  {item.description && (
                    <p className={"text-left ml-4 pt-1 " + item.desClassName}>
                      {item.description}
                    </p>
                  )}
                </div>
                <div
                  className="m-4"
                  onClick={() =>
                    item.position === 1 || item.position === 8
                      ? null
                      : props.setServInd(index)
                  }
                >
                  {item.button}
                </div>
              </div>
              {/* <section style={{ marginBottom: "25px" }} className="p-2">
                <img src={qrCode} alt={"qr code"} />
                <span className={item.desClassName}>Download the App</span>
              </section> */}
            </div>
          ))}
        </Slider>
        <div className="slider-tickler-container">
          <marquee scrollamount="10" className="px-3 slider-tickler">
            <BulbFilled
              className="mx-3"
              style={{
                display: "flex",
                color: "#fff566",
                fontSize: "1.25rem",
                cursor: "pointer",
              }}
            ></BulbFilled>
            <span className="font-weight-bold mr-1" style={{ color: "yellow" }}>
              Tip of the Day :
            </span>
            {/* {JSON.stringify(tip)} */}
            {props?.tip}
          </marquee>
        </div>
        <Affix offsetTop={140}>
          {/* <a
            href="https://play.google.com/store/apps/details?id=clinic.janelaaj.janelaajuserapp&hl=en"
            target="__blank"
            // replace
          > */}
          <Card
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "85px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: 0,
              // border: "1px solid #28A745",
            }}
            // className="bg-dar"
            hoverable
            cover={<Image src={qrCode} alt="QRCODE"></Image>}
            bodyStyle={{ padding: 0 }}
          >
            <Meta
              // className="bg-success font-weight-bold text-light p-2 text-center"
              // title={
              //   <div style={{ fontSize: "0.5rem", padding: "0", margin: "0" }}>
              //     Click To Enlarge
              //   </div>
              // }
              description={
                <div className="text-center" style={{ padding: "0.5rem 0" }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=clinic.janelaaj.janelaajuserapp&hl=en"
                    target="__blank"
                  >
                    <img
                      src="/images/app/android.png"
                      alt="Playstore App Link"
                      // className="mb-4"
                      width="100%"
                    />
                  </a>
                </div>
              }
              style={{ color: "white" }}
            ></Meta>
          </Card>
          {/* </a> */}
        </Affix>
        {/* <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "85px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "100%" }} src={qrCode} alt="QRCODE"></img>
          <div className="bg-success font-weight-bold text-light py-2 text-center">
            Download the App
          </div>
        </div> */}
      </div>
      <HastagsHeadline></HastagsHeadline>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setServInd: (index) => dispatch(setSelectedServiceIndex(index)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ImageSlider));
