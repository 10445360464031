import { Button, Card, message, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { apis } from "../shared/apis";
import PaytmPayment from "./payment/PaytmPayment";
import logo from "../assets/images/logo.png";

// import PaymentSuccessCard from "./PaymentSuccessCard";

const DocPlanCard = (props) => {
  const [loader, setLoader] = useState(false);
  //   const [buying, setBuying] = useState(false);
  const [showPaytmPayment, setShowPaytmPayment] = useState(false);
  const [checkSumRes, setCheckSumRes] = useState({});
  const [rzpOrderId, setCreateOrderID] = useState("");

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  // old paytm one
  // const input = {
  //   CUST_ID: "888", // can be empty
  //   CHANNEL_ID: "WAP", // channel ID(same as WAP)
  //   ORDER_ID: "", // can be empty
  //   TXN_AMOUNT: props.lplanamount + "" || "", // transaction amount
  //   CALLBACK_URL:
  //     "https://securegw.paytm.in/theia/paytmCallback?ORDER_ID=PDLAT300427",
  //   INDUSTRY_TYPE_ID: "Retail", // Same
  //   WEBSITE: "WEBSTAGING", // Same
  //   payment_code: 1, // Same
  // };

  const docNewPlanInput = {
    DptmId: "DPTMDt01000077", // order id
    DpmId: props?.lplanid, // plan id
    DlmId: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid, // clinic id
    DptmStartDate: moment().format("YYYY-MM-DD"), // plan start date
    DptmEndDate: moment().add(props.lplanduration, "days").format("YYYY-MM-DD"), // plan end date
    ModeOfPayment: "None", // mode of payment
    BulkBuyFlag: "N", // Always N
    PaymentStatus: "Y", // Always Y
    ActiveFlag: "Y", // Always Y
    DlmPendingId: "", // Empty
    OldDptmId: "", // empty
    PaytmId: "", // paytm id can be empty
    BankId: "", // bank id can be empty
    payment_amount: props.lplanamount, // payment amount
  };

  const input = {
    amount: props?.lplanamount || "0",
    appt_details: {},
    orderIdCreatedFor: "PARTNER",
    docSubscriptionDetails: {
      // same input which is passing in doctoNewPlayPayment API
      ...docNewPlanInput,
    },
  };

  const addDocNewPlanHandler = async (inpt) => {
    setLoader(true);
    await axios({
      ...apis.doctorNewPlanPaymentY,
      data: {
        ...docNewPlanInput,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something went Wrong");
        else {
          const inp = {
            id: "Will be Generated Later",
            amount: 0,
            txnId: "XXXXXXXX",
            service: props?.lfeaturename,
            data: {
              txn_status: "SUCCESS",
              txn_info: "Transaction SUCCESS",
            },
          };
          paymentCompleteHandler(inpt || inp);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something went Wrong");
      });
  };

  const docNewPlanHandler = async (ptmChk) => {
    // console.log("ptmChk", ptmChk);
    setLoader(true);
    await axios({
      ...apis.doctorNewPlanPaymentY,
      data: {
        ...docNewPlanInput,
        DptmId: ptmChk.data.ORDER_ID || docNewPlanInput.DptmId, // order id
        PaytmId: ptmChk.data?.TXNID,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something went Wrong");
        localStorage.setItem(
          "invoice",
          JSON.stringify([
            {
              title: "Order Id",
              value: ptmChk.data.ORDER_ID,
            },
            { title: "Amount", value: docNewPlanInput.payment_amount },
            { title: "Date", value: docNewPlanInput?.DptmStartDate },
            {
              title: "Transaction Id",
              value: ptmChk.data?.TXNID || "XXXXXX",
            },
            { title: "Validity", value: docNewPlanInput?.DptmEndDate },
          ])
        );
        setShowPaytmPayment(false);
        setTimeout(() => (window.location = "/paytm-payment-result"), 1000);
        // props.history.push({
        //   pathname: "/paytm-payment-result",
        //   state: [
        //     {
        //       title: "Order Id",
        //       value: ptmChk.data.ORDER_ID,
        //     },
        //     { title: "Amount", value: docNewPlanInput.payment_amount },
        //     { title: "Date", value: docNewPlanInput?.DptmStartDate },
        //     {
        //       title: "Transaction Id",
        //       value: ptmChk.data?.TXNID || "XXXXXX",
        //     },
        //     { title: "Validity", value: docNewPlanInput?.DptmEndDate },
        //   ],
        // });
        // Modal.success({
        //   centered: true,
        //   title: (
        //     <div
        //       className="text-success text-center font-weight-bold"
        //       style={{ fontSize: "larger" }}
        //     >
        //       Payment Successful
        //     </div>
        //   ),
        //   content: (
        //     <PaymentSuccessCard
        //       data={[
        //         {
        //           title: "Order Id",
        //           value: ptmChk?.data?.ORDER_ID || docNewPlanInput.DptmId,
        //         },
        //         { title: "Amount", value: docNewPlanInput.payment_amount },
        //         { title: "Date", value: docNewPlanInput.DptmStartDate },
        //         {
        //           title: "Transaction Id",
        //           value: ptmChk?.TXNID || docNewPlanInput?.PaytmId || "XXXXXX",
        //         },
        //         { title: "Validity", value: docNewPlanInput.DptmEndDate },
        //       ]}
        //     />
        //   ),
        //   onOk: () => {
        //     props?.updateList();
        //   },
        // });
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something went Wrong");
      });
  };

  const buyPlanHandler = async () => {
    setLoader(true);
    await axios({ ...apis.rpCreateOrderId, data: input })
      .then((res, err) => {
        if (err) message.error("Something Went Wrong");
        else {
          setCreateOrderID(res?.data?.order_id);
        }
        setLoader(false);
        // console.log("Res: of docPC: ", res);
        // if (
        //   res.data.head?.signature?.length > 0 ||
        //   res.data.data?.ORDER_ID?.length > 0
        // ) {
        //   if (props.lplanamount === 0) {
        //     setLoader(false);

        //     docNewPlanHandler(res.data);
        //   } else {
        //     setShowPaytmPayment(true);
        //     setLoader(false);

        //     // Modal.info({
        //     //   title: "Paytm Payment",
        //     //   content: (
        //     //     <PaytmPayment
        //     //       {...res.data}
        //     //       amt={props.lplanamount + ""}
        //     //     ></PaytmPayment>
        //     //   ),
        //     // });
        //   }
        // } else {
        //   message.error("Something went wrong");
        // }
      })
      .catch((err) => {
        setLoader(false);
        if (err) message.error("Something Went Wrong");
      });
  };
  // OLD - PAYTM
  // const buyPlanHandler = async () => {
  //   setLoader(true);
  //   await axios({ ...apis.PaytmGenerateCheckSum, data: input })
  //     .then((res, err) => {
  //       if (err) message.error("Something Went Wrong");
  //       // console.log("Res: of docPC: ", res);
  //       setCheckSumRes(res.data);
  //       if (
  //         res.data.head?.signature?.length > 0 ||
  //         res.data.data?.ORDER_ID?.length > 0
  //       ) {
  //         if (props.lplanamount === 0) {
  //           setLoader(false);

  //           docNewPlanHandler(res.data);
  //         } else {
  //           setShowPaytmPayment(true);
  //           setLoader(false);

  //           // Modal.info({
  //           //   title: "Paytm Payment",
  //           //   content: (
  //           //     <PaytmPayment
  //           //       {...res.data}
  //           //       amt={props.lplanamount + ""}
  //           //     ></PaytmPayment>
  //           //   ),
  //           // });
  //         }
  //       } else {
  //         message.error("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       if (err) message.error("Something Went Wrong");
  //     });
  // };

  const paymentCompleteHandler = ({ id, amount, txnId, service, data }) => {
    message.success("Transaction Successfull");
    localStorage.setItem(
      "rpInvoice",
      JSON.stringify([
        {
          title: "Order Id",
          value: id || "Will be Generated Later",
        },
        {
          title: "Amount",
          value: amount,
        },
        {
          title: "Date",
          value: moment().format("DD-MM-YYYY"),
        },
        {
          title: "Transaction Id",
          value: txnId || "XXXXXX",
        },
        { title: "Service", value: service },
        {
          data: data || {
            txn_status: "PENDING",
            txn_info: "Transsaction PENDING",
          },
        },
      ])
    );
    props.history.push("/razorpay-payment-complete");
  };

  const checkPaymentStatus = async (inp) => {
    await axios({
      ...apis.getDoctorPlanDetails,
      data: {
        dlmid: docNewPlanInput?.DlmId,
      },
    })
      .then((res, err) => {
        if (err) message.error("Something went wrong");
        else {
          if (res?.data?.selectedplan?.length === 0) {
            paymentCompleteHandler({
              ...inp,
              data: {
                txn_status: "PENDING",
                txn_info: "Transsaction Pending",
              },
            });
          } else {
            message.success("Transaction Successfull");
            addDocNewPlanHandler({
              ...inp,
              data: {
                txn_status: "SUCCESS",
                txn_info: "Transsaction Successfull",
              },
            });
          }
        }
      })
      .catch((err) => {
        if (err) message.error("Something went wrong");
        console.log("eoror: ", err);
      });
  };

  const displayRazorPay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      Modal.error({ title: "Razorpay SDK failed to load. Are you online?" });
      return;
    }

    // creating a new order
    // eslint-disable-next-line no-unused-vars
    const partnerEmail = localStorage.getItem("partnerEmail");
    const partnerPhone = localStorage.getItem("partnerPhone");
    setCreateOrderID(true);
    await axios({ ...apis.rpCreateOrderId, data: input })
      .then((res, err) => {
        setCreateOrderID(false);
        if (err) message.error("Server error. Are you online?");
        else {
          // Getting the order details back
          console.log("res.data doc plan: ", res.data);
          // const { order_id } = res.data;
          const options = {
            key:
              document.domain === "localhost"
                ? "rzp_test_B6bNY8QqNi15WB"
                : "rzp_live_odWZvXoQBCx0Xt", // Enter the Key ID generated from the Dashboard
            // key: " rzp_live_odWZvXoQBCx0Xt", // Enter the Key ID generated from the Dashboard
            amount: (props.lplanamount * 100).toString(),
            // method: "card",
            // account_id: "IA0WFmSWWlEK86",
            currency: "INR",
            name: "Jan Elaaj",
            description: "A Healthcare Platform",
            image: logo,
            order_id: res.data.order_id,
            handler: async function (response) {
              const data = {
                orderCreationId: res.data.order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
              console.log("rp data : ", data);
              const inp = {
                id: res.data.order_id,
                amount: props.lplanamount,
                txnId: response.razorpay_payment_id,
                service: props?.lfeaturename,
              };
              await checkPaymentStatus(inp);
            },
            prefill: {
              name: partnerEmail?.split("@")[0] || "",
              email: partnerEmail || "",
              contact: partnerPhone || "",
            },
            notes: {
              address:
                "Indian Institute of Management Lucknow, Noida Campus, B1, Institutional Area, Sector 62, Noida – 201307 Uttar Pradesh, India",
            },
            theme: {
              color: "#007bff",
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          paymentObject.on("payment.failed", function (response) {
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            localStorage.setItem(
              "rpError",
              JSON.stringify([
                {
                  title: "Order Id",
                  value: response.error.metadata.order_id || "XXXXXX",
                },
                { title: "Description", value: response.error.description },
                { title: "Reason", value: response.error.reason },
                {
                  title: "Transaction Id",
                  value: response.error.metadata.payment_id || "XXXXXX",
                },
                { title: "Service", value: props?.lfeaturename },
                ...response,
              ])
            );
            props.history.push("/razorpay-payment-failed");
            //  paymentFailureHandler()
          });
        }
      })
      .catch((err) => {
        setCreateOrderID(false);
        if (err) message.error("Server error. Are you online?");
        localStorage.setItem(
          "rpError",
          JSON.stringify([
            {
              title: "Order Id",
              value: "XXXXXX",
            },
            {
              title: "Description",
              value: "Payment Failed.Please Retry after some time",
            },
            {
              title: "Reason",
              value: "Payment Failed.Please Retry after some time",
            },
            {
              title: "Transaction Id",
              value: "XXXXXX",
            },
            { title: "Service", value: "" },
          ])
        );
        props.history.push("/razorpay-payment-failed");
      });
  };

  const buyNowHandler = () => {
    // RAZORPAY-----
    if (props.lplanamount > 0) displayRazorPay();
    else {
      addDocNewPlanHandler(false);
    }
  };
  return (
    <Card
      hoverable
      type="inner"
      headStyle={{ backgroundColor: "#1890ff", color: "white" }}
      title={<div>{props?.lplanname || "No Name "}</div>}
      style={{ minWidth: "150px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Button
            type="dashed"
            size="large"
            style={{ color: "#a0d911", borderColor: "#a0d911" }}
          >
            {props.lplanduration || "Duration"} Days
          </Button>
          <Button
            type="primary"
            size="large"
            loading={loader}
            onClick={buyNowHandler}
          >
            {loader
              ? "Buying..."
              : `Pay Here ₹ ${props.lplanamount + "" || "Amt"} `}
          </Button>
        </div>
        <div style={{ width: "100%" }} className="m-1 mt-3 p-1 text-secondary">
          {props?.lfeaturename}
        </div>
      </div>
      {/* {showPaytmPayment ? (
        <PaytmPayment
          {...checkSumRes}
          {...docNewPlanInput}
          amt={props.lplanamount + ""}
          docNewPlanHandler={docNewPlanHandler}
          {...props}
          toggleFlag={setShowPaytmPayment}
        ></PaytmPayment>
      ) : null} */}
    </Card>
  );
};

export default DocPlanCard;
