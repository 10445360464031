const policiesContentPartner = {
  "about-us": {
    content: (
      <div>
        <p>
          <strong>About Us</strong>
        </p>
        Jan Elaaj has a unique, organised, multi tiered, encrypted, AI based,
        structured digital framework of Learn-Screen-Consult-Measure that
        enables a user to conclude their health risk. This framework also
        promotes early detection of primary health risks, both, general and
        mental health risks. The platform also connects verified healthcare
        partners such as Doctors, Hospitals, Psychologists, Pathology labs,
        Radiology labs, Pharmacies, Ambulance Services, Medical Attendants, to
        end users seamlessly and at affordable costs. Jan Elaaj also enables a
        health benefit plan which covers non-hospitalization cover for vector
        borne diseases, critical illness. There is a hospitalization cover as
        well. Jan Elaaj is incubated at IIM Lucknow, Noida Campus , KIIT
        Bhubaneswar and have been awarded with MeitY EiR award / recognition for
        mental health DIY tool.
      </div>
    ),
  },
  "contact-us": {
    content: (
      <div>
        <p>
          <strong>CONTACT US</strong>
        </p>
        For any support, please visit the Support Section on the App. There are
        various pre-defined templates. You can choose any template and pen down
        your query and attach relevant photos etc . Alternatively, you can write
        to enquiry@janelaaj.com. We understand you need our support and we are
        eager to respond to your queries.
      </div>
    ),
  },
  "our-services": {
    content: (
      <div>
        <p>OUR SERVICES:</p>
        Jan Elaaj connects you with verified healthcare partners and offers
        affordable services. The partners include :
        <ul>
          <li>Online doctors,</li>
          <li>Offline doctors (doctors with clinics),</li>
          <li>Online psychologists,</li>
          <li>Psychologists with clinics,</li>
          <li>Radiology labs,</li>
          <li>Pathology labs</li>
          <li>Ambulance Services</li>
          <li>Medical Attendents</li>
          <li>Nurses etc</li>
        </ul>
        <div>
          There is a health benefit plan focussed around non-hospitalization and
          hospitalization.
        </div>
        <div>
          The services are available for retail as well as corporate customers.{" "}
        </div>
        For any appointment, the User or their dependents can upload symptoms,
        reports etc for the practitioner to review before the consultation.{" "}
        <br />
        The consultation can be through a video call or a normal call, depending
        on the connectivity. The consultations can be booked via a very flexible
        appointment system.
        <br />
        Doctors can view appointments, patient symptoms, contact them, give them
        online consultation and even upload prescriptions respective of their
        consultation with ease.
        <br />
        There is no need for the user to carry prescriptions for subsequent
        consultations.
      </div>
    ),
  },
  "payment-process": {
    content: (
      <div>
        <p>PAYMENT PROCESS</p>The payment process involves payments against the
        services provided for Online/Offline consultation by Doctors,
        Psychologists, Radiology Services, Pathology Services, Preventive
        Screening etc. <br />
        The healthcare service provider mentions the nature of service, rate of
        the service, discounts (if any) on the Jan Elaaj Partner App. This can
        be verified by the Partner using the ‘One View’ feature on the App.
        <br />
        Each service is provided against an appointment, which the user has to
        make via the App using the ‘Book Now’ feature. While navigating through
        the appointment process, the user is asked to make a payment The payment
        amount on the payment gateway will be the same as indicated on the App.
        <br />
        After servicing the appointment successfully, the healthcare service
        provider needs to go to the ‘Reports & Payments’ tab and select ‘Request
        Payment ‘option. Once selected , the Start date and the End date for
        which the payment is requested is entered, and immediately all the
        appointments, with corresponding patient details, will appear. The
        Doctor Payment field will show the amount the partner will be paid.{" "}
        <br />
        The’ View Details ‘ tab of ‘Reports & Payments’ will give the details of
        the transaction. On verifying everything, the partner needs to proceed
        by clicking the Request Payment button. Then enter the valid Jan Elaaj
        App password and verify your account. Once verified, enter your Account
        Number, IFSC , Account Holder Name, related Comments and proceed. <br />
        The partner is notified of a successful appointment / payment via an
        SMS. The same is also visible on the ‘Reports & Payments’ Tab’ of the
        App.
      </div>
    ),
  },
  "privacy-policy": {
    content: (
      <div>
        Privacy Policy of Jan Elaaj Partner App
        <p>Privacy Policy</p>
        Effective date: April 02, 2019 Jan ElaajHealthcare Pvt Ltd ("us", "we",
        or "our") operates the Jan Elaaj Partner App mobile application (the
        "Service"). This page informs you of our policies regarding the
        collection, use, and disclosure of personal data when you use our
        Service and the choices you have associated with that data. We use your
        data, voluntarily provided by you, to provide and improve the Service.
        By using the Service, you agree to the collection and use of information
        in accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions.
        <p>Information Collection And Use</p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
        <p>Types of Data Collected</p>
        <p>Personal Data</p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address</li>
          <li>Gender</li>
          <li>Date of Birth</li>
          <li>Dependents (if they have to be shown to the practitioner)</li>
          <li>Symptoms (if requested / consented by User)</li>
          <li>Medical Reports (If requested / consented by User)</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p>Usage Data</p>
        When you access the Service by or through a mobile device, we may
        collect certain information automatically, including, but not limited
        to, the type of mobile device you use, your mobile device unique ID, the
        IP address of your mobile device, your mobile operating system, the type
        of mobile Internet browser you use, unique device identifiers and other
        diagnostic data ("Usage Data").
        <p>Tracking & Cookies Data</p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information. Cookies are files with
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Tracking technologies also used are beacons, tags, and scripts
        to collect and track information and to improve and analyze our Service.
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service. Examples of Cookies we
        use:
        <ul>
          <li>
            Session Cookies. We use Session Cookies to operate our Service.
          </li>
          <li>
            Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li>
            Security Cookies. We use Security Cookies for security purposes.
          </li>
        </ul>
        <p>Use of Data</p>
        Jan ElaajHealthcare Pvt Ltd uses the collected data for various
        purposes:
        <ul>
          <li>To provide and maintain Healthcare Service to end users</li>
          <li>
            To make your medical records available to healthcare service
            provider , if consented by user , for better diagnosis
          </li>
          <li>
            To enable healthcare providers get a better idea of your medical
            record, if consented by User
          </li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <p>Transfer Of Data</p>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
        <br />
        If you are located outside India and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        India and process it there. Your consent to this Privacy Policy followed
        by your submission of such information represents your agreement to that
        transfer.
        <br />
        Jan Elaaj Healthcare Pvt Ltd will take all steps reasonably necessary to
        ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer of your Personal Data will take place to
        an organization or a country unless there are adequate controls in place
        including the security of your data and other personal information.
        <br />
        <p>Disclosure Of Data</p>
        <p>Legal Requirements</p>
        Jan ElaajHealthcare Pvt Ltd may disclose your Personal Data in the good
        faith belief that such action is necessary to:
        <ul>
          <li>To comply with a legal obligation</li>
          <li>
            To protect and defend the rights or property of Healthcare Services
          </li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <p>Security Of Data</p>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security. <p>Service Providers.</p>
        <br />
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. These third parties have access to your Personal Data
        only to perform these tasks on our behalf and are obligated not to
        disclose or use it for any other purpose.
        <p>Links To Other Sites</p>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
        <br />
        <p>Children's Privacy</p>
        Our Service does not address anyone under the age of 18 ("Children").
        <br />
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Children has provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
        <br />
        <p>Changes To This Privacy Policy</p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
        <br />
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
        <br />
        <p>Contact Us</p>
        If you have any questions about this Privacy Policy, please contact us:
        By email: enquiry@janelaaj.com
      </div>
    ),
  },
  "doctor-guidelines": {
    content: (
      <div>
        <p>Jan Elaaj Partner</p> The Preventive Comprehensive Primary Healthcare
        Partner Healthcare partners can unlock their potential and become
        accessible to offer trusted primary healthcare services in their
        neighbourhoods by registering with Jan Elaaj. A dedicated team will
        onboard you after registration and equip you with technology,
        collaterals, devices, labs to empower you offer trusted services. You
        will be enabled to bulk sell your services / inventory, add your own
        external bookings, provide discounts flexibly, become co-location
        partners in polyclinics and digitally market to extend reach
      </div>
    ),
  },
  "terms-and-contions": {
    content: (
      <div>
        <p>Terms & Conditions</p>
        The terms We / Us Our/Company individually and collectively refer to Jan
        Elaaj Healthcare Pvt Ltd and the terms Visitor/ User refer to the users.
        This page states the Terms and Conditions under which you (Visitor) may
        visit this website ("Website"). Please read this page carefully. If you
        do not accept the Terms and Conditions stated here, we would request you
        to exit this site or uninstall the app. The business, any of its
        business divisions and / or its subsidiaries, associate companies or
        subsidiaries to subsidiaries or such other investment companies (in
        India or abroad) reserve their respective rights to revise these Terms
        and Conditions at any time by updating this posting. You should visit
        this page periodically to re-appraise yourself of the Terms and
        Conditions, because they are binding on all users of this Website.
        <p>USE OF CONTENT</p>
        All logos, brands, marks headings, labels, names, signatures, numerals,
        shapes or any combinations thereof, appearing in this site, except as
        otherwise noted, are properties either owned, or used under licence, by
        the business and /or its associate entities who feature on this Website.
        The use of these properties or any other content on this site, except as
        provided in these terms and conditions or in the site content, is
        strictly prohibited. You may not sell or modify the content of this
        Website or reproduce, display, publicly perform, distribute, or
        otherwise use the materials in any way for any public or commercial
        purpose without the respective organisation's or entity's written
        permission.
        <p>ACCEPTABLE WEBSITE USE (A) Security Rules</p>
        ACCEPTABLE WEBSITE USE (A) Security Rules Visitors are prohibited from
        violating or attempting to violate the security of the Web site,
        including, without limitation, (1) accessing data not intended for such
        user or logging into a server or account which the user is not
        authorised to access, (2) attempting to probe, scan or test the
        vulnerability of a system or network or to breach security or
        authentication measures without proper authorisation, (3) attempting to
        interfere with service to any user, host or network, including, without
        limitation, via means of submitting a virus or to the Website,
        overloading, flooding, mail bombing or crashing, or (4) sending
        unsolicited electronic mail, including promotions and/or advertising of
        products or services. Violations of system or network security may
        result in civil or criminal liability. The business and /or its
        associate entities will have the right to investigate occurrences that
        they suspect as involving such violations and will have the right to
        involve, and cooperate with, law enforcement authorities in prosecuting
        users who are involved in such violations. (B) General Rules Visitors
        may not use the Web Site in order to transmit, distribute, store or
        destroy material (a) that could constitute or encourage conduct that
        would be considered a criminal offence or violate any applicable law or
        regulation, (b) in a manner that will infringe the copyright, trademark,
        trade secret or other intellectual property rights of others or violate
        the privacy or publicity of other personal rights of others, or (c) that
        is libellous, defamatory, pornographic, profane, obscene, threatening,
        abusive or hateful.
        <p>INDEMNITY </p>
        The User unilaterally agree to indemnify and hold harmless, without
        objection, the Company, its officers, directors, employees and agents
        from and against any claims, actions and/or demands and/or liabilities
        and/or losses and/or damages whatsoever arising from or resulting from
        their use of www.janelaaj.com or their breach of the terms .
        <p>LIABILITY</p>
        User agrees that neither Company nor its group companies, directors,
        officers or employee shall be liable for any direct or/and indirect
        or/and incidental or/and special or/and consequential or/ and exemplary
        damages, resulting from the use or/and the inability to use the service
        or/and for cost of procurement of substitute goods or/and services or
        resulting from any goods or/and data or/and information or/and services
        purchased or/ and obtained or/and messages received or/and transactions
        entered into through or/and from the service or/and resulting from
        unauthorized access to or/and alteration of user's transmissions or/and
        data or/and arising from any other matter relating to the service,
        including but not limited to, damages for loss of profits or/and use
        or/and data or other intangible, even if Company has been advised of the
        possibility of such damages. User further agrees that Company shall not
        be liable for any damages arising from interruption, suspension or
        termination of service, including but not limited to direct or/and
        indirect or/ and incidental or/and special consequential or/and
        exemplary damages, whether such interruption on and suspension or/and
        termination was justified or not, negligent or intentional, inadvertent
        or advertent. User agrees that Company shall not be responsible or
        liable to user, or anyone, for the statements or conduct of any third
        party of the service. In sum, in no event shall Company's total
        liability to the User for all damages or/and losses or/and causes of
        action exceed the amount paid by the User to Company, if any, that is
        related to the cause of action. DISCLAIMER OF CONSEQUENTIAL DAMAGES In
        no event shall Company or any parties, organizations or entities
        associated with the corporate brand name us or otherwise, mentioned at
        this Website be liable for any damages whatsoever (including, without
        limitations, incidental and consequential damages, lost profits, or
        damage to computer hardware or loss of data information or business
        interruption) resulting from the use or inability to use the Website and
        the Website material, whether based on warranty, contract, tort, or any
        other legal theory, and whether or not, such organization or entities
        were advised of the possibility of such damages.
      </div>
    ),
  },
  "return-and-refund": {
    content: (
      <div>
        <p>RETURN, REFUND & CANCELLATION POLICY</p> A doctor or any healthcare
        partner / service provider can cancel an appointment(s). In case he/ she
        cancels an appointment or does a no show, they will not be paid against
        the cancelled appointment or missed appointment. An appointment can be
        cancelled by a patient as well. In that case as well, the doctor will
        not be paid. In the use cases where the healthcare partners / service
        provider are required to pay the subscription fees, there will be ‘no’
        refund of the subscription fees.
      </div>
    ),
  },
};
const policiesContentUser = {
  "about-us": {
    content: (
      <div>
        <p>
          <strong>About Us</strong>
        </p>
        Jan Elaaj has a unique, organised, multi tiered, encrypted, AI based,
        structured digital framework of Learn-Screen-Consult-Measure that
        enables a user to conclude their health risk. This framework also
        promotes early detection of primary health risks, both, general and
        mental health risks. The platform also connects verified healthcare
        partners such as Doctors, Hospitals, Psychologists, Pathology labs,
        Radiology labs, Pharmacies, Ambulance Services, Medical Attendants, to
        end users seamlessly and at affordable costs. Jan Elaaj also enables a
        health benefit plan which covers non-hospitalization cover for vector
        borne diseases, critical illness. There is a hospitalization cover as
        well. Jan Elaaj is incubated at IIM Lucknow, Noida Campus , KIIT
        Bhubaneswar and have been awarded with MeitY EiR award / recognition for
        mental health DIY tool.
      </div>
    ),
  },
  "contact-us": {
    content: (
      <div>
        <p>
          <strong>CONTACT US</strong>
        </p>
        For any support, please visit the Support Section on the App. There are
        various pre-defined templates. You can choose any template and pen down
        your query and attach relevant photos etc . Alternatively, you can write
        to enquiry@janelaaj.com. We understand you need our support and we are
        eager to respond to your queries.
      </div>
    ),
  },
  "our-services": {
    content: (
      <div>
        <p>OUR SERVICES:</p>
        Jan Elaaj connects you with verified healthcare partners and offers
        affordable services. The partners include :
        <ul>
          <li>Online doctors,</li>
          <li>Offline doctors (doctors with clinics),</li>
          <li>Online psychologists,</li>
          <li>Psychologists with clinics,</li>
          <li>Radiology labs,</li>
          <li>Pathology labs</li>
          <li>Ambulance Services</li>
          <li>Medical Attendents</li>
          <li>Nurses etc</li>
        </ul>
        <div>
          There is a health benefit plan focussed around non-hospitalization and
          hospitalization.
        </div>
        <div>
          The services are available for retail as well as corporate customers.{" "}
        </div>
        For any appointment, the User or their dependents can upload symptoms,
        reports etc for the practitioner to review before the consultation.{" "}
        <br />
        The consultation can be through a video call or a normal call, depending
        on the connectivity. The consultations can be booked via a very flexible
        appointment system.
        <br />
        Doctors can view appointments, patient symptoms, contact them, give them
        online consultation and even upload prescriptions respective of their
        consultation with ease.
        <br />
        There is no need for the user to carry prescriptions for subsequent
        consultations.
      </div>
    ),
  },
  "payment-process": {
    content: (
      <div>
        <p>PAYMENT PROCESS</p> The payment process involves payments against the
        appointments made for Online Doctors, Offline Doctors, Online
        Psychologists, Offline Psychologists, Radiology Services, Pathology
        Services, Preventive Screening etc. Every service has a published rate
        which is visible clearly on the App. The user can also click on ‘View
        Details’ on the App and view the ‘Rates and Services’. Each service is
        provided against an appointment, which the user has to make via the App
        using the ‘Book Now’ feature. While navigating through the appointment
        process, the user is asked to make a payment. The payment amount on the
        payment gateway will be the same as indicated on the App. The user is
        notified of a successful appointment creation / payment via an SMS. The
        same is also visible on the ‘Appointments Tab’ of the App. The
        Appointment will be created successfully only on receipt of payment for
        any online services.
      </div>
    ),
  },
  "privacy-policy": {
    content: (
      <div>
        Privacy Policy of Jan Elaaj User App
        <p>Privacy Policy</p>
        Effective date: April 02, 2019 Jan ElaajHealthcare Pvt Ltd ("us", "we",
        or "our") operates the Jan Elaaj Partner App mobile application (the
        "Service"). This page informs you of our policies regarding the
        collection, use, and disclosure of personal data when you use our
        Service and the choices you have associated with that data. We use your
        data, voluntarily provided by you, to provide and improve the Service.
        By using the Service, you agree to the collection and use of information
        in accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions.
        <p>Information Collection And Use</p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
        <p>Types of Data Collected</p>
        <p>Personal Data</p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address</li>
          <li>Gender</li>
          <li>Date of Birth</li>
          <li>Dependents (if they have to be shown to the practitioner)</li>
          <li>Symptoms (if requested / consented by User)</li>
          <li>Medical Reports (If requested / consented by User)</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p>Usage Data</p>
        When you access the Service by or through a mobile device, we may
        collect certain information automatically, including, but not limited
        to, the type of mobile device you use, your mobile device unique ID, the
        IP address of your mobile device, your mobile operating system, the type
        of mobile Internet browser you use, unique device identifiers and other
        diagnostic data ("Usage Data").
        <p>Tracking & Cookies Data</p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information. Cookies are files with
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Tracking technologies also used are beacons, tags, and scripts
        to collect and track information and to improve and analyze our Service.
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service. Examples of Cookies we
        use:
        <ul>
          <li>
            Session Cookies. We use Session Cookies to operate our Service.
          </li>
          <li>
            Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li>
            Security Cookies. We use Security Cookies for security purposes.
          </li>
        </ul>
        <p>Use of Data</p>
        Jan ElaajHealthcare Pvt Ltd uses the collected data for various
        purposes:
        <ul>
          <li>To provide and maintain Healthcare Service to end users</li>
          <li>
            To make your medical records available to healthcare service
            provider , if consented by user , for better diagnosis
          </li>
          <li>
            To enable healthcare providers get a better idea of your medical
            record, if consented by User
          </li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <p>Transfer Of Data</p>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
        <br />
        If you are located outside India and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        India and process it there. Your consent to this Privacy Policy followed
        by your submission of such information represents your agreement to that
        transfer.
        <br />
        Jan Elaaj Healthcare Pvt Ltd will take all steps reasonably necessary to
        ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer of your Personal Data will take place to
        an organization or a country unless there are adequate controls in place
        including the security of your data and other personal information.
        <br />
        <p>Disclosure Of Data</p>
        <p>Legal Requirements</p>
        Jan ElaajHealthcare Pvt Ltd may disclose your Personal Data in the good
        faith belief that such action is necessary to:
        <ul>
          <li>To comply with a legal obligation</li>
          <li>
            To protect and defend the rights or property of Healthcare Services
          </li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <p>Security Of Data</p>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security. <p>Service Providers.</p>
        <br />
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. These third parties have access to your Personal Data
        only to perform these tasks on our behalf and are obligated not to
        disclose or use it for any other purpose.
        <p>Links To Other Sites</p>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
        <br />
        <p>Children's Privacy</p>
        Our Service does not address anyone under the age of 18 ("Children").
        <br />
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Children has provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
        <br />
        <p>Changes To This Privacy Policy</p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
        <br />
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
        <br />
        <p>Contact Us</p>
        If you have any questions about this Privacy Policy, please contact us:
        By email: enquiry@janelaaj.com
      </div>
    ),
  },
  "doctor-guidelines": {
    content: (
      <div>
        <p>Jan Elaaj Partner</p> The Preventive Comprehensive Primary Healthcare
        Partner Healthcare partners can unlock their potential and become
        accessible to offer trusted primary healthcare services in their
        neighbourhoods by registering with Jan Elaaj. A dedicated team will
        onboard you after registration and equip you with technology,
        collaterals, devices, labs to empower you offer trusted services. You
        will be enabled to bulk sell your services / inventory, add your own
        external bookings, provide discounts flexibly, become co-location
        partners in polyclinics and digitally market to extend reach
      </div>
    ),
  },
  "terms-and-contions": {
    content: (
      <div>
        <p>Terms & Conditions</p>
        The terms We / Us Our/Company individually and collectively refer to Jan
        Elaaj Healthcare Pvt Ltd and the terms Visitor/ User refer to the users.
        This page states the Terms and Conditions under which you (Visitor) may
        visit this website ("Website"). Please read this page carefully. If you
        do not accept the Terms and Conditions stated here, we would request you
        to exit this site or uninstall the app. The business, any of its
        business divisions and / or its subsidiaries, associate companies or
        subsidiaries to subsidiaries or such other investment companies (in
        India or abroad) reserve their respective rights to revise these Terms
        and Conditions at any time by updating this posting. You should visit
        this page periodically to re-appraise yourself of the Terms and
        Conditions, because they are binding on all users of this Website.
        <p>USE OF CONTENT</p>
        All logos, brands, marks headings, labels, names, signatures, numerals,
        shapes or any combinations thereof, appearing in this site, except as
        otherwise noted, are properties either owned, or used under licence, by
        the business and /or its associate entities who feature on this Website.
        The use of these properties or any other content on this site, except as
        provided in these terms and conditions or in the site content, is
        strictly prohibited. You may not sell or modify the content of this
        Website or reproduce, display, publicly perform, distribute, or
        otherwise use the materials in any way for any public or commercial
        purpose without the respective organisation's or entity's written
        permission.
        <p>ACCEPTABLE WEBSITE USE (A) Security Rules</p>
        ACCEPTABLE WEBSITE USE (A) Security Rules Visitors are prohibited from
        violating or attempting to violate the security of the Web site,
        including, without limitation, (1) accessing data not intended for such
        user or logging into a server or account which the user is not
        authorised to access, (2) attempting to probe, scan or test the
        vulnerability of a system or network or to breach security or
        authentication measures without proper authorisation, (3) attempting to
        interfere with service to any user, host or network, including, without
        limitation, via means of submitting a virus or to the Website,
        overloading, flooding, mail bombing or crashing, or (4) sending
        unsolicited electronic mail, including promotions and/or advertising of
        products or services. Violations of system or network security may
        result in civil or criminal liability. The business and /or its
        associate entities will have the right to investigate occurrences that
        they suspect as involving such violations and will have the right to
        involve, and cooperate with, law enforcement authorities in prosecuting
        users who are involved in such violations. (B) General Rules Visitors
        may not use the Web Site in order to transmit, distribute, store or
        destroy material (a) that could constitute or encourage conduct that
        would be considered a criminal offence or violate any applicable law or
        regulation, (b) in a manner that will infringe the copyright, trademark,
        trade secret or other intellectual property rights of others or violate
        the privacy or publicity of other personal rights of others, or (c) that
        is libellous, defamatory, pornographic, profane, obscene, threatening,
        abusive or hateful.
        <p>INDEMNITY </p>
        The User unilaterally agree to indemnify and hold harmless, without
        objection, the Company, its officers, directors, employees and agents
        from and against any claims, actions and/or demands and/or liabilities
        and/or losses and/or damages whatsoever arising from or resulting from
        their use of www.janelaaj.com or their breach of the terms .
        <p>LIABILITY</p>
        User agrees that neither Company nor its group companies, directors,
        officers or employee shall be liable for any direct or/and indirect
        or/and incidental or/and special or/and consequential or/ and exemplary
        damages, resulting from the use or/and the inability to use the service
        or/and for cost of procurement of substitute goods or/and services or
        resulting from any goods or/and data or/and information or/and services
        purchased or/ and obtained or/and messages received or/and transactions
        entered into through or/and from the service or/and resulting from
        unauthorized access to or/and alteration of user's transmissions or/and
        data or/and arising from any other matter relating to the service,
        including but not limited to, damages for loss of profits or/and use
        or/and data or other intangible, even if Company has been advised of the
        possibility of such damages. User further agrees that Company shall not
        be liable for any damages arising from interruption, suspension or
        termination of service, including but not limited to direct or/and
        indirect or/ and incidental or/and special consequential or/and
        exemplary damages, whether such interruption on and suspension or/and
        termination was justified or not, negligent or intentional, inadvertent
        or advertent. User agrees that Company shall not be responsible or
        liable to user, or anyone, for the statements or conduct of any third
        party of the service. In sum, in no event shall Company's total
        liability to the User for all damages or/and losses or/and causes of
        action exceed the amount paid by the User to Company, if any, that is
        related to the cause of action. DISCLAIMER OF CONSEQUENTIAL DAMAGES In
        no event shall Company or any parties, organizations or entities
        associated with the corporate brand name us or otherwise, mentioned at
        this Website be liable for any damages whatsoever (including, without
        limitations, incidental and consequential damages, lost profits, or
        damage to computer hardware or loss of data information or business
        interruption) resulting from the use or inability to use the Website and
        the Website material, whether based on warranty, contract, tort, or any
        other legal theory, and whether or not, such organization or entities
        were advised of the possibility of such damages.
      </div>
    ),
  },
  "return-and-refund": {
    content: (
      <div>
        <p>RETURN, REFUND & CANCELLATION POLICY</p> A user or their dependent
        can cancel their confirmed active/current appointment. In case they want
        to cancel an active/current appointment, they will be given an option to
        re-schedule the appointment, with no extra charges. Incase, they still
        want to cancel their active/current appointment, a service charge of 10%
        will be deducted and they can request for refund of the fee through the
        ‘Appointments’ tab on the App. This cancellation has to be done atleast
        4 hours before the scheduled time of the appointment.
      </div>
    ),
  },
};

export { policiesContentUser, policiesContentPartner };
