import { Button } from "antd";
import React, { useState } from "react";
import CardHolderFrame from "../containers/CardHolderFrame";
import Bvmnotes from "./BVMNotes";
import Videorecorderbutton from "./VideoRecorderButton";

const Bodyvitalmeasurement = (props) => {
  const [step, setStep] = useState(0);
  const goto = (i) => {
    setStep(i);
  };
  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/bvm",
      pathName: "Body Vital Measurement",
    },
  ];
  const holderProps = {
    routes: routes,
    title: "Body Vital Measurement",
    // bodyStyle: { paddingTop: "0.25rem" },
    // loader: loader,
  };
  const components = [
    { component: <Bvmnotes goto={goto} {...props} /> },
    { component: <Videorecorderbutton goto={goto} {...props} /> },
  ];

  const handleOk = () => {
    setStep(1);
  };

  return (
    <div>
      <CardHolderFrame {...holderProps}>
        <div className="text-center">
          <div style={{ maxWidth: "700px", margin: "auto" }}>
            {components[step].component}
          </div>
          {step === 0 ? (
            <Button onClick={handleOk} type="primary">
              OK
            </Button>
          ) : null}
        </div>
      </CardHolderFrame>
    </div>
  );
};

export default Bodyvitalmeasurement;
