import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Image,
  message,
  DatePicker,
} from "antd";
import AppScreen from "../AppScreen";
import bgImage from "../../assets/images/partner-signup.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import { apis } from "../../shared/apis";
import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";
import { connect } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import PartnerSignUpOtpForm from "./PartnerSignUpOtpForm";

const { Option } = Select;
const PartnerSignup = (props) => {
  const [loader, setLoader] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [numStatus, setNumStatus] = useState({
    message: "Sign Up",
    isLoading: false,
    error: "",
  });
  const [specialityList, setSpecialityList] = useState([]);
  const [partnerData, setPartnerData] = useState({});
  const [otp, setOTP] = useState("");
  const [form] = Form.useForm();
  const roles = [
    { value: "CHE", label: "Chemist" },
    { value: "DOC", label: "Offline Doctors" },
    { value: "ONL", label: "Online Doctor" },
    { value: "DIE", label: "Offline Dietitian" },
    { value: "ODIE", label: "Online Dietitian" },
    { value: "PSY", label: "Offline Psychologist" },
    { value: "OPSY", label: "Online Psychologist" },
    { value: "PHY", label: "Physiotherapist" },
    { value: "PAT", label: "Pathology" },
    { value: "RAD", label: "Radiology" },
    { value: "VIT", label: "Vital" },
  ];
  const renderOptions = () =>
    roles.map((role, i) => (
      <Option value={role.value} key={i + Math.random()}>
        {role.label}
      </Option>
    ));

  const renderSpecialities = () =>
    specialityList.map((role, i) => (
      <Option value={role.name} key={i + Math.random()}>
        {role.name}
      </Option>
    ));

  // const prefixSelector = (
  //   <Form.Item name="countryCode" noStyle>
  //     <Select
  //       style={{
  //         width: 80,
  //       }}
  //       defaultValue="+91"
  //       value="+91"
  //     >
  //       <Option value="+86">+86</Option>
  //       <Option value="+87">+87</Option>
  //       <Option value="+91">+91</Option>
  //     </Select>
  //   </Form.Item>
  // );

  const numberverify = async (data) => {
    setNumStatus({ message: "Verifying...", isLoading: true, error: "" });
    await axios({ ...apis.numberverify, data: data })
      .then((res, err) => {
        if (err) {
          // console.log('ERR in Verifying: ', err)
          setNumStatus({
            message: "Retry",
            isLoading: false,
            error: err?.message,
          });
        }
        // console.log('Res from numVerify: ', res)
        if (res.data.status === "SUCCESS") {
          setOTP(res.data.otp);
          setShowOtpForm(true);
          setNumStatus({
            message: "Success",
            isLoading: false,
            error: "",
          });
        } else {
          setNumStatus({
            message: "Retry",
            isLoading: false,
            error: "Number Already Registered!",
          });
          message.error("Number Already Registered!");
          setShowOtpForm(false);

          // if (numStatus.error.length > 1) {
          // }
        }
      })
      .catch((err) => {
        // if (err) console.log('ERR in Verifying: ', err)
        message.error(err?.message || "ERR in Verifying:");
      });
  };

  const getSpecialities = async (data) => {
    setLoader(true);
    await axios({ ...apis.getspeciality, data: data })
      .then((res, err) => {
        if (err)
          message.error(err?.message || "Err wile fetching specialities");
        else {
          props.setSpecialityList(res.data);
          setSpecialityList(res.data.sparr);
          setLoader(false);
          //   props.next();
        }
      })
      .catch((err) => {
        if (err)
          message.error(err?.message || "Err wile fetching specialities");

        setLoader(false);
      });
  };

  const handleSubmit = (e) => {
    setPartnerData(e);
    numberverify({ mobile: e.phone, pldrole: e.role });
  };

  useEffect(() => {
    const setSpec = () => {
      let e = props?.location?.state?.role?.role;
      // setSpecialityList([]);
      if (e === "DOC" || e === "ONL") {
        getSpecialities({ Role: e });
      } else if (
        form?.getFieldValue()?.role === "DOC" ||
        form?.getFieldValue()?.role === "ONL"
      ) {
        getSpecialities({ Role: form?.getFieldValue()?.role });
      }
    };
    setSpec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.getFieldValue()?.role]);

  return (
    <div>
      <div
        style={{
          minHeight: "84vh",
          backgroundColor: "#F3FBFE",
          display: "flex",
          // alignItems: "center",
          justifyContent: "space-around",
          // backgroundImage: `url(${bgImage})`,
          // backgroundSize: "contain",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "90% 90%",
          padding: "2rem",
        }}
        className="row mx-0"
      >
        <div
          className="col-sm-6"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Card
            hoverable
            title={<div style={{ fontSize: "1.25rem" }}>Partner With Us</div>}
            style={{ width: "50%", minWidth: "400px", alignSelf: "flex-start" }}
          >
            <Form
              onFinish={handleSubmit}
              initialValues={{ role: props?.location?.state?.role?.role }}
              form={form}
            >
              <Form.Item
                name="fullname"
                rules={[{ required: true, message: "Please Enter Your Name!" }]}
              >
                <Input size="large" placeholder="Full Name" />
              </Form.Item>
              <Form.Item
                name="role"
                rules={[
                  { required: true, message: "Please Select Your Role!" },
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  placeholder="Select Role"
                  onChange={(e, q) => {
                    setSpecialityList([]);
                    if (e === "DOC" || e === "ONL")
                      getSpecialities({ Role: e });
                  }}
                >
                  {renderOptions()}
                </Select>
              </Form.Item>
              {specialityList?.length > 0 && (
                <Form.Item
                  name="speciality"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Your Speciality!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select Speciality"
                    loading={loader}
                  >
                    {renderSpecialities()}
                  </Select>
                </Form.Item>
              )}

              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                }}
                // className="bg-dark"
              >
                <Form.Item
                  name="dob"
                  rules={[
                    { required: true, message: "Please enter your DOB!" },
                  ]}
                  style={{ flexGrow: "2", paddingRight: "10px" }}
                >
                  <DatePicker
                    size="large"
                    placeholder="Select your DOB"
                    style={{ width: "100%" }}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  rules={[
                    { required: true, message: "Please select your gender" },
                  ]}
                >
                  <Select placeholder="Gender" size="large">
                    <Option value="M">Male</Option>
                    <Option value="F">Female</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Phone Number!",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length === 10) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Please enter valid Phone Number")
                      );
                    },
                  }),
                ]}
                hasFeedback
                help={
                  numStatus?.error ? (
                    <div className="text-danger">{numStatus?.error}</div>
                  ) : null
                }
              >
                <Input
                  //   addonBefore={prefixSelector}
                  //   suffix={
                  //     <Button size="small" type="link" onClick={numberverify}>
                  //       Send OTP
                  //     </Button>
                  //   }
                  size="large"
                  placeholder="Phone Number"
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please Enter Your Email!" },
                ]}
              >
                <Input type="email" size="large" placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  autoComplete="partner-new-password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  size="large"
                  autoComplete="partner-new-password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  <div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      loading={numStatus?.isLoading || loader}
                    >
                      {/* {numStatus?.message} */}
                      Sign Up
                    </Button>
                  </div>
                  <div>
                    Already have an account ?
                    <Link to="/partner-login">
                      <Button type="link">Login</Button>
                    </Link>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div
          className="col-sm-6"
          //   style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              margin: "auto",
              color: "#030852",
              fontSize: "2.5rem",
              fontFamily: "monospace",
              textAlign: "center",
              padding: "2rem",
            }}
            className=" container-fluid "
          >
            Patients are looking for Medical Practitioners like you
          </div>
          <Image
            // style={{ opacity: "0.4" }}
            preview={false}
            src={bgImage}
            alt="doc patient"
          ></Image>
        </div>
      </div>
      <div>
        <AppScreen></AppScreen>
      </div>
      <Modal
        title={<div>Please Verify your Number</div>}
        visible={showOtpForm}
        centered
        onCancel={() => setShowOtpForm(false)}
        footer={[
          <Button type="default" onClick={() => setShowOtpForm(false)}>
            Close
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <PartnerSignUpOtpForm
          mobile={partnerData?.phone}
          partnerData={partnerData}
          otp={otp}
          specialitiesList={specialityList}
          {...props}
        ></PartnerSignUpOtpForm>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpecialityList: (data) => dispatch(dashboardAC.setSpecialityList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignup);
