import React from "react";
import { policiesContentUser, policiesContentPartner } from "./PoliciesContent";
// import ReactHtmlParser from "react-html-parser";
// import parse from "html-react-parser";
const Policycontainer = (props) => {
  const cKey = props.match.params.cKey;
  const policiesContent =
    props.match.params.for === "user"
      ? policiesContentUser
      : policiesContentPartner;
  return (
    <div className="policy-content-wrapper p-4">
      {policiesContent[cKey]?.content}
    </div>
  );
  //   return <div>{parse(policiesContent[cKey]?.content)}</div>;
};

export default Policycontainer;
