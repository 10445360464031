import * as ActionTypes from "../ActionTypes";
const initialState = {
  showDashBoard: false,
  selectedServiceIndex: "",
  selectedRole: "OPSY",
};
export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_DASHBOARD:
      return {
        ...state,
        showDashBoard: action.payload,
      };
    case ActionTypes.SET_SELECTED_SERVICE_INDEX:
      return {
        ...state,
        selectedServiceIndex: action.payload,
      };
    case ActionTypes.SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };
    default:
      return state;
  }
};
export default uiReducer;
