import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
const BreadCrumbList = (props) => {
  return (
    <div>
      <Breadcrumb separator=">">
        {props?.routes.map((route, i) => (
          <Breadcrumb.Item key={i}>
            <Link to={route.path}>{route.pathName}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbList;
