import React, { useState } from "react";
import LogoCard from "./LogoCard";
import {
  UserOutlined,
  MedicineBoxOutlined,
  //   SolutionOutlined,
  //   LoadingOutlined,
  //   SmileOutlined,
} from "@ant-design/icons";
import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";

import { connect } from "react-redux";
import axios from "axios";
import { apis } from "../../shared/apis";
import LoadingMask from "../LoaderMask/LoadingMask";
import { Card, Empty, message, Modal, Radio } from "antd";
import onlineDoc from "../../assets/images/doc_card_laptop.png";
import offlineDoc from "../../assets/images/doc2.jpg";
import HospitalCard from "./HospitalCard";
const { Meta } = Card;

function ServicesList(props) {
  // console.log(this);
  const [loader, setLoader] = useState(false);
  const [hospitals, setHospitalsList] = useState([]);
  const [showHospitalList, setShowHospitalList] = useState(false);
  // const [isOnline, setIsOnline] = useState("Role");

  // const services = [
  //   {
  //     title: "Doctor",
  //     logo: <UserOutlined />,
  //     data: { id: 1, Role: "DOC" },
  //   },
  //   {
  //     title: "Screening",
  //     logo: <UserOutlined />,
  //     data: { id: 1, Role: "VIT" },
  //   },
  // ];

  const rolesList = [
    {
      role: "DOC",
      roleName: "DOCTOR",
      roleid: "DOC2000015",
      mobileno: "8448735275",
      isReg: false,
      roleList: [
        {
          role: "ONL",
          roleName: "ONLINE DOCTOR",
          roleid: "ONL3900508",
          mobileno: "8448735275",
          isReg: false,
        },
        {
          role: "DOC",
          roleName: "DOCTOR",
          roleid: "ONL3900508",
          mobileno: "8448735275",
          isReg: false,
        },
      ],
    },
    {
      role: "DIE",
      roleName: "DIETITIAN",
      roleid: "DIE3900006",
      mobileno: "84487352750",
      isReg: false,
      roleList: [
        {
          role: "ODIE",
          roleName: "ONLINE DIETITIAN",
          roleid: "ODIE1000002",
          mobileno: "8448735275",
          isReg: false,
        },
        {
          role: "DIE",
          roleName: "DIETITIAN",
          roleid: "DIE3900006",
          mobileno: "84487352750",
          isReg: false,
        },
      ],
    },
    {
      role: "CHE",
      roleName: " CHEMIST",
      roleid: "CHE2000015",
      mobileno: "8448735275",
      isReg: false,
    },
    {
      role: "PSY",
      roleName: "PSYCHOLOGIST",
      roleid: "PSY1000001",
      mobileno: "84487352750",
      isReg: false,
      roleList: [
        {
          role: "OPSY",
          roleName: "ONLINE PSYCHOLOGIST",
          roleid: "OPSY10005",
          mobileno: "84487352750",
          isReg: false,
        },
        {
          role: "PSY",
          roleName: "PSYCHOLOGIST",
          roleid: "PSY1000001",
          mobileno: "8448735275",
          isReg: false,
        },
      ],
    },
    {
      role: "PAT",
      roleName: "PATHOLOGY",
      roleid: "PAT2000016",
      mobileno: "84487352750",
      isReg: false,
    },
    // {
    //   role: "PHY",
    //   roleName: "PSYCHOTHERAPIST",
    //   roleid: "PHY3900006",
    //   mobileno: "84487352750",
    //   isReg: false,
    // },
    {
      role: "RAD",
      roleName: "RADIOLOGY",
      roleid: "RAD3000018",
      mobileno: "84487352750",
      isReg: false,
    },
    {
      role: "VIT",
      roleName: "SCREENING",
      roleid: "VIT3900023",
      mobileno: "84487352750",
      isReg: false,
    },
  ];

  const isOnlineForm = (service) => {
    return (
      <div>
        <Radio.Group
          onChange={(e) => {
            if (e.target.value === "online") {
              okHandler(service.roleList[0], true);
              global.modall.destroy();
              delete global.modall;
              return;
            } else {
              okHandler(service.roleList[1], false);
              global.modall.destroy();
              delete global.modall;
              return;
            }
            // setIsOnline(e.target.value);
            // props.setService(JSON.parse(e.target.value))
            // console.log('THIS IS FROM RADION : ',isOnline);
          }}
          // value={isOnline}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
          className="horizontal-radio"
        >
          <Radio name="isOnline" value="online">
            <Card
              cover={<img alt="online-doc" src={onlineDoc} />}
              style={{ width: "200px", margin: "10px 0px" }}
              hoverable
              className="highlight-hover"
            >
              <Meta title={"Online"}></Meta>
            </Card>
          </Radio>
          <Radio name="isOnline" value="offline">
            <Card
              cover={<img alt="online-doc" src={offlineDoc} />}
              style={{ width: "200px", margin: "10px 0px" }}
              hoverable
              className="highlight-hover"
            >
              <Meta title={"Offline"}></Meta>
            </Card>
          </Radio>
        </Radio.Group>
      </div>
    );
  };
  // useEffect(()=>{
  //   console.log('USEEFFECT')
  //   if(isOnline !== ''){
  //     props.setService()
  //   }
  // },[isOnline])

  // useEffect(()=>{
  //   console.log('USEEFFECT')
  //   if(isOnline !== ''){
  //     props.setService()
  //   }
  // },[isOnline])

  const getSpecialities = async (data) => {
    setLoader(true);
    await axios({ ...apis.getspeciality, data: data })
      .then((res, err) => {
        if (err) message.error("Err while fetching specialities");
        else {
          props.setSpecialityList(res.data);
          setLoader(false);
          props.next();
        }
      })
      .catch((err) => {
        if (err) message.error("Err while fetching specialities");
        setLoader(false);
      });
  };
  const getOutcomePackages = async () => {
    setLoader(true);
    await axios(apis.getOutcomePackages)
      .then((res, err) => {
        if (err) message.error("Err wile fetching outcome packages");
        else {
          props.setOutcomePackages(res.data.data);
          setLoader(false);
          props.next();
        }
      })
      .catch((err) => {
        if (err) message.error("Err wile fetching outcome packages", err);
        setLoader(false);
      });
  };

  const selectService = (service) => {
    // props.setService(service);
    // console.log("serv: ", service);
    if (service.role === "VIT") {
      getOutcomePackages();
    } else {
      getSpecialities({ Role: service.role });
    }
  };

  const isStateandCity = (service) => {
    if (!props?.userDash?.userCurrentLocation?.isOn) {
      if (
        !(
          props?.userDash?.userState?.value &&
          props?.userDash?.userCity?.value &&
          props?.userDash?.userLocality?.llocalityname
        )
      ) {
        message.error("Please Select State and City and Locality");
        props?.toggleHighlighter();
        props?.tabChangeHandler("5");
      } else {
        selectService(service);
        props.setService(service);
      }
    } else {
      selectService(service);
      props.setService(service);
    }
  };

  const okHandler = (service, isOnline) => {
    // selectService(service);
    // props.setService(service);
    if (isOnline) {
      selectService(service);
      props.setService(service);
    } else {
      isStateandCity(service);
    }
  };

  const serviceClickHandler = (service) => {
    if (
      service.role === "VIT" ||
      service.role === "PAT" ||
      service.role === "CHE" ||
      service.role === "RAD" ||
      service.role === "PHY"
    ) {
      isStateandCity(service);
    } else {
      let modall = Modal.info({
        title: "Select Any one",
        content: isOnlineForm(service),
        okText: "Close",
        width: "500px",
        centered: true,
        closable: true,
      });
      global.modall = modall;
      return modall;
    }
  };

  const hospitalClickHandler = async () => {
    setLoader(true);
    await axios(apis.getRegisteredHospitals)
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Hospital List can't loaded");
        else {
          console.log("HL : ", res.data);
          setHospitalsList(res.data.info);
          setShowHospitalList(true);
        }
      })
      .catch((err) => {
        if (err) message.error("Err wile fetching outcome packages", err);
        setLoader(false);
      });
  };
  const hospitalSubmitHandler = (hos) => {
    let service = {
      role: "ONL",
      roleName: "ONLINE",
      roleid: "ONL3900508",
      mobileno: "8448735275",
      hospital_id: hos?.id,
      isReg: false,
    };
    setShowHospitalList(false);
    selectService(service);
    props.setService(service);
    // getSpecialities({ Role: "ONL" });
  };
  return (
    <div className="logo-card-container">
      {loader ? <LoadingMask message="Loading..."></LoadingMask> : null}

      {rolesList.map((service, i) => (
        <span
          key={i + Math.random()}
          onClick={() => {
            serviceClickHandler(service);
          }}
        >
          <LogoCard
            title={service.roleName}
            logo={<UserOutlined></UserOutlined>}
          ></LogoCard>
        </span>
      ))}
      <span
        key={"Hospital"}
        onClick={() => {
          hospitalClickHandler();
        }}
      >
        <LogoCard title={"Hospital"} logo={<MedicineBoxOutlined />}></LogoCard>
      </span>
      <Modal
        title={
          <div style={{ fontSize: "1.25rem" }} className="p-2">
            Hospitals
          </div>
        }
        visible={showHospitalList}
        onCancel={() => setShowHospitalList(false)}
        closable
        width={1000}
        footer={[]}
      >
        {hospitals?.length ? (
          <div className="row mx-0">
            {hospitals?.map((hos, i) => (
              <div
                key={i + Math.random()}
                className="col-sm-6"
                onClick={() => {
                  hospitalSubmitHandler(hos);
                }}
              >
                <HospitalCard {...hos}></HospitalCard>
              </div>
            ))}
          </div>
        ) : (
          <Empty></Empty>
        )}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setService: (data) => dispatch(dashboardAC.setService(data)),
    setSpecialityList: (data) => dispatch(dashboardAC.setSpecialityList(data)),
    setOutcomePackages: (list) => dispatch(dashboardAC.setPackagesList(list)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicesList);
