//export const baseUrl = 'http://35.200.243.43:3000/';
//export const baseUrl = 'http://ubuntu@ec2-3-89-152-60.compute-1.amazonaws.com:3000/';
//export const baseUrl = 'http://3.89.152.60:3000/';
//export const baseUrl = 'http://ec2-3-89-152-60.compute-1.amazonaws.com:3000/';
// export const baseUrl = 'http://35.244.43.227:3000/';
// const prod_ip = "35.244.43.227";

const isProduction = true;
const prod_node = "prod-node.janelaaj.com";
// const dev_ip = "34.131.119.174"; // ip changed here on 06/07/2021
// const dev_ip = "165.22.220.31"; // ip changed here on 13/07/20213-
const dev_ip = "34.131.119.174"; // ip changed here on 28/07/20213-
let port;
let nodeServer;
let protocol;
if (isProduction) {
  port = "3443";
  nodeServer = prod_node;
  protocol = "https";
} else {
  port = "3000";
  nodeServer = dev_ip;
  protocol = "http";
}

// const dev_node = "dev-node.janelaaj.com";
// const dev_app_engine = "jan-elaaj.appspot.com";
// const local_node = "localhost";
// const localhost = "hotshot.janelaaj.com";
// const nodeServer = prod_ip;
// const nodeServer = prod_node;
// const nodeServer = dev_app_engine;
// const nodeServer = local_node;
// const port = "3001";
// const port = "8443";
// export const baseUrl = `http://${prod_ip}:${port}/`;
// export const baseUrl = `http://${nodeServer}:${port}/`;
/*fetch('https://localhost:3443/set-dev-env')
    .then(res => console.log(res));*/
export const baseUrl = `${protocol}://${nodeServer}:${port}/`;
// export const baseVideoUrl = "http://0.0.0.0:3001/";
// export const baseUrl = `https://${nodeServer}/`;
