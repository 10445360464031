import React, { useState } from "react";
import { Button, Modal } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import Map from "../common/Map";
const GetDirectionBtn = (props) => {
  // console.log("Props of GD: ", props);
  const [showMap, setShowMap] = useState(false);
  const location = {
    address: props?.locadd,
    lat: 37.42216,
    lng: -122.08427,
  };
  return (
    <div>
      <Button
        className="my-1"
        icon={<EnvironmentOutlined style={{ display: "inline-flex" }} />}
        onClick={() => {
          setShowMap(true);
        }}
      >
        Get Direction
      </Button>
      <Modal
        visible={showMap}
        title={
          <div>
            Directions for <i>{props?.locname}</i>
          </div>
        }
        closable
        centered
        onCancel={() => {
          setShowMap(false);
        }}
        onOk={() => {
          setShowMap(false);
        }}
        style={{ width: "100%" }}
        width={1000}
        bodyStyle={{ padding: "10px" }}
      >
        <Map location={location} zoomLevel={15}></Map>
      </Modal>
    </div>
  );
};

export default GetDirectionBtn;
