import React from "react";
import { Image, Spin } from "antd";
import scan from "../../assets/images/scan.gif";
import "./LoadingMask.css";
function LoadingMask(props) {
  const getCustomLoader = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Image
        preview={false}
        src={props?.animation || scan}
        style={{ width: "100%", maxWidth: "500px" }}
      />
    </div>
  );

  return (
    <div>
      <div className="loader-container-dummy"></div>
      <div className="loader-container">
        <div className="spinner-container">
          <div className="spinner">
            {/* <CircleLoader size={60} color="#000f64"></CircleLoader> */}
            {props?.showCustomLoader ? (
              getCustomLoader()
            ) : (
              <Spin size="large"></Spin>
            )}
          </div>
          {props?.showCustomLoader ? null : (
            <div className="spinnner-message">
              {props.message || "Please Wait..."}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(LoadingMask);
