import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  Media,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import "../../assets/styles/DoctorVerification.css";
import { styles } from "../../assets/styles/DoctorVerification";
import moment from "moment";
import { VerificationTitle } from "../HelperComponent";

class GoLiveVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docid: null,
      showDoc: false,
      postDoc: false, // flag to show the response when supervisor save the info
      verifyFields: false,
      locVerify: null,
      approved: null,
      comments: null,
      live: null,
      liveDate: null,
      doc: "",
    };
    this.approve = this.state.approved;
    this.comments = this.state.comments;
    this.postDocMess = "Your response has been recorded. Thank You."; // Msg to show when supervisor save the info
    this.toggleShowDoc = this.toggleShowDoc.bind(this);
    this.togglePostDoc = this.togglePostDoc.bind(this);
    this.setDocInfo = this.setDocInfo.bind(this);
    this.onRadioInputChange = this.onRadioInputChange.bind(this);
  }
  submitComment = (event) => {
    this.setState({
      comments: null,
    });
    // this.state.doc.DlmComments = null;
    this.comments = event.target.value;
    if (!this.state.verifyFields)
      this.setState({
        verifyFields: !this.state.verifyFields,
      });
  };
  toggleShowDoc = () => {
    this.setState({
      showDoc: !this.state.showDoc,
    });
  };
  setDocInfo = (doc) => {
    this.setState({
      docVerify: doc.DocVerificationFlag,
      locVerify: doc.LocVerificationFlag,
      approved: doc.DlmVerifyFlag,
      comments: doc.DlmComments,
      live: doc.DlmReadyLiveFlag,
      liveDate: doc.DlmReadyLiveDate,
      doc: doc,
    });
    this.toggleShowDoc();
  };
  onRadioInputChange(event) {
    this.setState({ verifyFields: true });
    if (event.target.name === "doc-approve") {
      this.approve = event.target.value;
      this.setState({
        approved: event.target.value,
      });
    }
  }
  togglePostDoc() {
    this.setState({ postDoc: !this.state.postDoc });
  }
  postDocInfo = () => {
    if (localStorage.getItem("token") || this.props.auth.user) {
      // if (this.props.auth.user.role[0].role === "SUPERVISOR") {
      if (JSON.parse(localStorage.getItem("empRole"))?.role === "SUPERVISOR") {
        // supervisor user is authorized to perform this operation
        this.props.postDocsInfo({
          dlmid: this.state.doc.DlmId,
          docid: this.state.doc.DocId,
          dlmcomments: this.comments,
          dlmverifyflag: this.approve,
          docname: this.state.doc.DocName,
          docmobile: this.state.doc.DocMobile,
        });
        // console.log("if :", this.props.auth.user);
      } else {
        this.postDocMess = "You are not authorize to perform this operation";
        // console.log("Else :", this.props.auth.user);
      }
    }

    this.togglePostDoc();
    this.setState({ verifyFields: !this.state.verifyFields });
  };

  render() {
    // let roleid = null;
    let { verifyFields } = this.state.verifyFields;
    let docs = this.props.docsinfo.locations;
    let RenderDoc = () => <div />;
    if (docs)
      RenderDoc = () => {
        return docs.map((doc, i) => (
          <Col key={i}>
            <Button
              color="secondary"
              id={doc.DocId}
              name="doc"
              style={styles.docStyle}
              onClick={() => this.setDocInfo(doc)}
            >
              {doc.DocName}, {doc.DocRole}, {doc.LocName}, {doc.LocAddress}
            </Button>
          </Col>
        ));
      };
    const SelectRoles = () => {
      if (this.props.roles.role) {
        let roles = Object.values(Object.assign({}, this.props.roles.role));
        return (
          <FormGroup row>
            <div className="col-md-3 col-lg-2" style={styles.card4Label}>
              <Label for="select"> Select a Role </Label>
            </div>
            <Col xs={8} md={4} lg={3}>
              <Input
                style={styles.card1Input}
                type="select"
                name="select"
                id="select"
                defaultValue={this.props.role}
                innerRef={(input) => (this.id = input)}
                onChange={(event) =>
                  this.props.onChangeRole(event.target.value)
                }
              >
                <option> Select a role </option>
                {roles.map((role) => (
                  <option value={role.roleid}> {role.role} </option>
                ))}
              </Input>
            </Col>
            <Col md={3}>
              <Button
                color="info"
                type="submit"
                value="submit"
                className="mt-sm-4 mt-md-0"
                onClick={() =>
                  this.props.fetchDocsInfo({ docid: this.id.value })
                }
              >
                Get Information
              </Button>
            </Col>
          </FormGroup>
        );
      } else return <div />;
    };

    return (
      <div>
        <Card style={{ marginTop: 120 }}>
          <CardHeader className="head-xl" style={styles.cardHeader}>
            Go Live Verification
            <VerificationTitle />
          </CardHeader>
          <CardBody className="card1body  ">
            <Form>
              <FormGroup row>
                <div className="col-md-3 col-lg-2" style={styles.card4Label}>
                  <Label for="email"> Email </Label>
                </div>
                <Col xs={8} md={4} lg={3}>
                  <Input
                    className="mr-md-5"
                    style={styles.card1Input}
                    type="text"
                    name="email"
                    id="email"
                    placeholder="example@domain.com"
                    innerRef={(input) => (this.email = input)}
                    defaultValue={this.props.email}
                  />
                </Col>
                <Col md={2}>
                  <Button
                    color="info"
                    type="submit"
                    value="submit"
                    className="mt-sm-4 mt-md-0"
                    onClick={() => {
                      this.props.onSearch(this.email.value);
                      this.props.fetchRoles({ email: this.email.value });
                    }}
                  >
                    Search
                  </Button>
                </Col>
              </FormGroup>
            </Form>
            <SelectRoles />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <Button
              color="secondary"
              value="submit"
              className="m-3"
              onClick={() =>
                this.props.fetchDocsInfo({ docid: this.state.docid })
              }
            >
              Get All Unverified Doctors
            </Button>
          </CardHeader>
          <CardBody className="card1body">
            <Media>
              <Media body>
                <RenderDoc />
              </Media>
            </Media>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.postDoc} toggle={this.togglePostDoc}>
          <ModalHeader toggle={this.togglePostDoc}> Information </ModalHeader>
          <ModalBody> {this.postDocMess} </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                this.togglePostDoc();
                this.toggleShowDoc();
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showDoc} toggle={this.toggleShowDoc}>
          <ModalHeader toggle={this.toggleShowDoc}>
            Verify Information
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="name"> Partner </Label>
                </div>
                <Col xs={7} md={5} lg={4}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="docname"
                    id="name"
                    placeholder="Name"
                    value={this.state.doc.DocName}
                  />
                </Col>
                <Button
                  color="primary"
                  className="ml-4"
                  onClick={() => {
                    this.props.fetchDocInfo({
                      docemailormobile: this.state.doc.DocEmail,
                      role: this.state.doc.DocRole,
                    });
                    this.props.history.push({ pathname: "/verifyDoctor/" });
                  }}
                >
                  Get Details
                  {/*<Link to={{
                                        pathname: '/verifyDoctor/'
                                    }}

                                          target="_blank" style={{ color: 'white', textDecoration: 'none'}}> Get Details </Link>*/}
                </Button>
              </FormGroup>
              <FormGroup row>
                <Col md={12}>
                  <Label md={3}> Verified </Label>
                  <Label md={2} lg={2} className="ml-4 ml-md-0">
                    <Input
                      disabled
                      type="radio"
                      name="doc-verify5"
                      checked={this.state.docVerify === "1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3} className="ml-4 ml-md-0">
                    <Input
                      disabled
                      type="radio"
                      name="doc-verify5"
                      checked={this.state.docVerify === "2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4} className="ml-4 ml-md-0">
                    <Input
                      disabled
                      type="radio"
                      name="doc-verify5"
                      checked={this.state.docVerify === "3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-2" style={styles.card2Label}>
                  <Label for="address"> Location </Label>
                </div>
                <Col xs={7} md={5} lg={4}>
                  <Input
                    disabled
                    style={styles.card2Input}
                    type="text"
                    name="locname"
                    id="address"
                    placeholder="Address"
                    value={this.state.doc.LocName}
                  />
                </Col>
                <Button
                  color="primary"
                  className="ml-4"
                  onClick={() => {
                    this.props.fetchLocInfo({ docid: this.state.doc.DocId });
                    this.props.history.push({
                      pathname: "/verifyLocation/",
                      state: { lid: this.state.doc.LocId },
                    });
                  }}
                >
                  Get Details
                </Button>
              </FormGroup>
              <FormGroup row>
                <Col md={12}>
                  <Label md={3}> Verified </Label>
                  <Label md={2} lg={2} className="ml-4 ml-md-0">
                    <Input
                      disabled
                      type="radio"
                      name="loc-verify"
                      checked={this.state.locVerify === "1"}
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3} className="ml-4 ml-md-0">
                    <Input
                      disabled
                      type="radio"
                      name="loc-verify"
                      checked={this.state.locVerify === "2"}
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4} className="ml-4 ml-md-0">
                    <Input
                      disabled
                      type="radio"
                      name="loc-verify"
                      checked={this.state.locVerify === "3"}
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={12} lg={12}>
                  <Label md={3} lg={3}>
                    Approval
                  </Label>
                  <Label md={2} lg={2} className="ml-4 ml-md-0">
                    <Input
                      type="radio"
                      name="doc-approve"
                      checked={this.state.approved === "1" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value="1"
                    />
                    ok
                  </Label>
                  <Label md={3} lg={3} className="ml-4 ml-md-0">
                    <Input
                      type="radio"
                      name="doc-approve"
                      checked={this.state.approved === "2" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value="2"
                    />
                    Reject
                  </Label>
                  <Label md={4} lg={4} className="ml-4 ml-md-0">
                    <Input
                      type="radio"
                      name="doc-approve"
                      checked={this.state.approved === "3" || verifyFields}
                      onChange={this.onRadioInputChange}
                      value="3"
                    />
                    Need more info
                  </Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} check style={{ marginLeft: 15 }}>
                  Is Doctor Live
                  {this.state.live === "Y" ? (
                    <i
                      className="fas fa-check ml-4"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <i
                      className="fas fa-times ml-4"
                      style={{ color: "#ab3228" }}
                    />
                  )}
                </Label>
                <div className="col-md-3" style={styles.card2Label}>
                  <Label for="live-date"> Live Date </Label>
                </div>
                <Col xs={8} md={4}>
                  <Input
                    className="ml-4 ml-md-0"
                    disabled
                    style={styles.card4Input}
                    type="text"
                    name="date"
                    id="date"
                    placeholder="Live Date"
                    value={
                      this.state.liveDate
                        ? moment(this.state.liveDate).format("DD-MMM-YYYY")
                        : null
                    }
                  />
                </Col>
              </FormGroup>
              <Col xs={10}>
                <Input
                  style={styles.card2Input}
                  type="text"
                  name="comments"
                  id="comments"
                  value={this.state.comments}
                  onChange={this.submitComment.bind(this)}
                  placeholder="Comments"
                />
              </Col>
              <Button
                color="danger"
                className="ml-md-3"
                style={{ marginTop: 20 }}
                onClick={this.toggleShowDoc}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                style={{ marginLeft: 20, marginTop: 20 }}
                disabled={!this.state.verifyFields}
                onClick={this.postDocInfo.bind(this)}
              >
                Save
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default GoLiveVerification;
