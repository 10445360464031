import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import "../../assets/styles/DoctorVerification.css";
import { styles } from "../../assets/styles/DoctorVerification";
import {
  receiveLogin,
  setIsPartnerLoggedIn,
  setRolesList,
} from "../../redux/ActionCreators/VerificationActionCreators";
import { connect } from "react-redux";
import Spinner from "reactstrap/lib/Spinner";
// import app from "../../firebase";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { apis } from "../../shared/apis";
import { message, Input as InputA } from "antd";
const { Password } = InputA;

// import sha256 from "crypto-js/sha256";
// import Base64 from "crypto-js/enc-base64";
let sha256 = require("crypto-js/sha256");
const EmployLogin = (props) => {
  const [state, setState] = useState({
    email: localStorage.getItem("empEmail"),
    password: "",
    oldPassword: "",
    newPassword: "",
    rePassword: "",
  });
  const [showChangePass, setShowChangePass] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showChangePassError, setshowChangePassError] = useState(false);
  const [loader, setLoader] = useState(false);
  let changePassMess = "Your password is changed. Thank You.";
  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const isEmailValid = (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const handleLogin = async () => {
    if (isEmailValid(state.email) && state.password) {
      setLoader(true);
      const hashDigest = sha256(state.password);
      await axios({
        ...apis.employeeLogin,
        data: {
          email: state.email,
          password: hashDigest.toString(),
        },
      })
        .then((resp, err) => {
          setLoader(false);
          if (resp.data.status === "SUCCESS") {
            setShowLoginError(false);
            props?.setEmpLogin(resp.data);
            localStorage.setItem("token", "employee logged in");
            localStorage.setItem("empEmail", state.email);
            localStorage.setItem(
              "empRole",
              JSON.stringify(resp?.data?.role[0])
            );
            localStorage.setItem(
              "selectedClinic",
              JSON.stringify(resp?.data?.role[0])
            );
            props.history.push("/emp-dashboard");
          } else {
            setShowLoginError(true);
            // message.error("Something Went Wrong");
          }
        })
        .catch((err) => {
          setShowLoginError(true);
          message.error("Something Went Wrong");
        });
    }
    if (!isEmailValid(state.email)) {
      setShowLoginError(true);
    }
  };
  const changeUserPass = () => {
    if (state.newPassWord === state.rePassWord) {
    } else changePassMess = "Password Mismatch";
    toggleChangePass();
  };
  const showChangePassToggler = () => {
    setShowChangePass(!showChangePass);
  };
  const togglefieldLogin = () => {
    setShowLoginError(!showLoginError);
  };
  const toggleChangePass = () => {
    setshowChangePassError(!showChangePassError);
  };
  if (localStorage.getItem("token")) return <Redirect to="/" />;

  return (
    <div>
      <div className="container">
        <Modal isOpen={showLoginError} toggle={togglefieldLogin}>
          <ModalHeader toggle={togglefieldLogin}> Error </ModalHeader>
          <ModalBody> Email or Password is Incorrect </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={togglefieldLogin}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showChangePassError} toggle={toggleChangePass}>
          <ModalHeader toggle={toggleChangePass}>Information</ModalHeader>
          <ModalBody> {changePassMess} </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleChangePass}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Form
          onSubmit={(e) => e.preventDefault()}
          className="col-sm-6"
          style={{ margin: "auto" }}
        >
          <Card style={{ marginTop: 120 }}>
            <CardHeader
              className=" bg-primary text-light text-center"
              style={styles.cardHeader}
            >
              {props?.match?.params?.id === "1"
                ? "Channel Network Login"
                : "Login"}
            </CardHeader>
            <CardBody className="card1body">
              <FormGroup row>
                <div className="col-md-2" style={styles.card1Label}>
                  <Label for="email"> Email </Label>
                </div>
                <Col>
                  <Input
                    style={styles.card1Input}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@domain.com"
                    onChange={onChangeHandler}
                    value={state.email || ""}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-2" style={styles.card1Label}>
                  <Label for="pass"> Password </Label>
                </div>
                <Col>
                  <Password
                    style={styles.card1Input}
                    type="password"
                    name="password"
                    id="pass"
                    placeholder="Password"
                    onChange={onChangeHandler}
                    value={state.password || ""}
                    required
                  />
                </Col>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {/* <Button
                  style={{ marginLeft: 1, marginTop: 20 }}
                  className="ml-5 btn btn-primary"
                  color="primary"
                  value="submit"
                  onClick={showChangePassToggler}
                >
                  Change Password
                </Button> */}
                <Button
                  style={{ marginTop: 20, margin: "auto" }}
                  color="primary"
                  type="submit"
                  value="submit"
                  onClick={handleLogin}
                >
                  {loader ? (
                    <span>
                      <Spinner size="sm" className="mx-2"></Spinner>Loggin in...
                    </span>
                  ) : (
                    "Login"
                  )}
                </Button>
                {/* <Link to="/user-signup">Register</Link> */}
              </div>
            </CardBody>
          </Card>
        </Form>
        <Modal isOpen={showChangePass} toggle={showChangePassToggler}>
          <ModalHeader toggle={showChangePassToggler}>
            Change Password
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <div className="col-md-5 mt-4" style={styles.card2Label}>
                  <Label for="old-pass"> Old Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    className="mt-4"
                    type="text"
                    name="oldPassword"
                    id="old-pass"
                    placeholder="Old Password"
                    onChange={onChangeHandler}
                    value={state.oldPassword}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-5" style={styles.card2Label}>
                  <Label for="re-pass"> New Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    type="text"
                    name="newPassword"
                    id="new-pass"
                    onChange={onChangeHandler}
                    value={state.newPassword}
                    placeholder="New Password"
                    minLength="8"
                    required
                    pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/"
                    title="password must be 8 to 15 characters long including at least one lowercase letter, one uppercase letter, one numeric digit and one special character"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <div className="col-md-5" style={styles.card2Label}>
                  <Label for="re-pass"> Retype New Password </Label>
                </div>
                <Col xs={7} md={6}>
                  <Input
                    style={styles.card2Input}
                    type="text"
                    name="rePassword"
                    id="re-pass"
                    onChange={onChangeHandler}
                    value={state.rePassword}
                    placeholder="Retype New Password"
                  />
                </Col>
              </FormGroup>
              <Button
                color="primary"
                className="ml-4 mb-4"
                onClick={changeUserPass}
              >
                Save
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEmpLogin: (user) => dispatch(receiveLogin(user)),
    setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
    setPartnerRoles: (obj) => dispatch(setRolesList(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployLogin);
