import React, { useState } from "react";
import { Modal, List, Avatar, Card } from "antd";
import { connect } from "react-redux";
import AppInviteCardSmall from "./common/AppInviteCardSmall";
import HashTagsTabs from "./HashTagsTabs";

function HashTags(props) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  //   const tagsData = [
  //     "Abortion",
  //     "abnormal periods",
  //     "IVF",
  //     "Contraceptive pills",
  //     "Medics",
  //     "Pills",
  //     "Movies",
  //     "Books",
  //     "Music",
  //     "Sports",
  //     "Abortion2",
  //     "Movies2",
  //     "Books2",
  //     "Music2",
  //     "Sports2",
  //   ];
  const closeModal = () => {
    setShowModal(false);
    setSelectedTags([null]);
  };
  // const handleChange = (tag, checked) => {
  //   // const { selectedTags } = this.state;
  //   const nextSelectedTags = checked
  //     ? //   ? [...selectedTags, tag]
  //       [tag]
  //     : selectedTags.filter((t) => t !== tag);
  //   console.log("You are interested in: ", nextSelectedTags);
  //   setSelectedTags(nextSelectedTags);
  //   setShowModal(true);
  // };

  const showTagsDetails = (tag) => {
    // const { selectedTags } = this.state;
    // console.log("You are interested in: ", tag);
    setSelectedTags([tag]);
    setShowModal(true);
  };

  return (
    <Card
      hoverable
      title={
        <div className="text-left section-heading">
          Trending Health Topics of the day :
        </div>
      }
      bodyStyle={{ padding: "10px 24px" }}
    >
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="row mr-0"
      >
        <div className="col-sm-8">
          {props.hashTags.Categories !== null &&
          props.hashTags?.Categories?.length !== 0 ? (
            <List
              itemLayout="vertical"
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
                column: 3,
              }}
              dataSource={props.hashTags?.Categories}
              renderItem={(item, index) => (
                <List.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => showTagsDetails(item.categoryName)}
                  >
                    <Avatar className="bg-secondary mr-3">{index + 1}</Avatar>
                    <span
                      style={{
                        color: "#007bff",
                        fontSize: "1.1rem",
                        // letterSpacing: "5px",
                      }}
                    >
                      {item.categoryName}
                    </span>
                  </div>
                </List.Item>
              )}
            ></List>
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div className="col-sm-4">
          <AppInviteCardSmall></AppInviteCardSmall>
        </div>
      </div>
      <Modal
        centered
        title={
          <div
            style={{
              textAlign: "center",
              fontStyle: "italic",
              fontSize: "large",
            }}
          >{`#${selectedTags[0]}`}</div>
        }
        visible={showModal}
        onCancel={closeModal}
        onOk={closeModal}
        // className='container'
        style={{
          width: "100%",
          maxWidth: "1000px",
          maxHeight: "100vh",
          overflowY: "hidden",
        }}
        bodyStyle={{ padding: "0.5rem " }}
        footer={[]}
        destroyOnClose={true}
      >
        <HashTagsTabs tag={selectedTags[0]}></HashTagsTabs>
      </Modal>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    hashTags: state.hashTags,
    oneMins: state.hashTags.OneMin,
    expertTips: state.hashTags.expertTips,
  };
};
export default connect(mapStateToProps)(HashTags);
