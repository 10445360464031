import React from "react";
import { Empty, Table } from "antd";
// const { Meta } = Card;
const ListofServices = (props) => {
  const addKey = (arr) => {
    let temp = [];
    arr.forEach((li, i) => {
      let obj = {
        ...li,
        key: Math.random(),
        ldcsmdiscountedamount:
          li.ldiscount_flag === "N" ? " NA" : li.ldcsmdiscountedamount,
      };
      temp.push(obj);
    });
    return temp;
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "lsmservicename",
      key: "lsmservicename",
      align: "center",
    },
    {
      title: "Normal Amount",
      dataIndex: "ldcsmnormalamount",
      key: "ldcsmnormalamount",
      align: "center",
      render: (text) => (
        <div key={+Math.random()} className="text-primary">
          ₹{text}
        </div>
      ),
    },
    {
      title: props?.isChemist ? "Discount" : "After Discount",
      dataIndex: "ldcsmdiscountedamount",
      key: "ldcsmdiscountedamount",
      align: "center",
      render: (text) => (
        <div key={+Math.random()} className="text-success">
          {props?.isChemist ? "%" : "₹"}
          {text}
        </div>
      ),
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      {props.serviceinfo?.length ? (
        <div className="mb-1">
          <Table
            dataSource={addKey(props?.serviceinfo)}
            columns={columns}
            pagination={false}
          ></Table>
          {/* {props.serviceinfo?.map((serv, i) => (
            <div key={i + Math.random()} className="col-sm-6">
              <Card
                type="inner"
                hoverable
                className="m-1 mb-2"
                style={{ width: "100%" }}
                title={
                  <div style={{ fontSize: "1.05rem" }}>
                    {serv?.lsmservicename}
                  </div>
                }
              >
                <Meta
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0.5rem",
                      }}
                    >
                      <div className="text-primary">{`Price ₹${serv?.ldcsmnormalamount}`}</div>
                      <div className="text-success">{`Dsicount ₹${serv?.ldcsmdiscountedamount}`}</div>
                    </div>
                  }
                ></Meta>
              </Card>
            </div>
          ))} */}
        </div>
      ) : (
        <Empty description="No Services Found"></Empty>
      )}
    </div>
  );
};

export default ListofServices;
