import React, { useState } from "react";
import { Modal, Tag } from "antd";
import { connect } from "react-redux";
import HashTagsTabs from "./HashTagsTabs";

const { CheckableTag } = Tag;

function HastagsHeadline(props) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setSelectedTags([null]);
  };
  const handleChange = (tag, checked) => {
    // const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? //   ? [...selectedTags, tag]
        [tag]
      : selectedTags.filter((t) => t !== tag);
    // console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
    setShowModal(true);
  };

  return (
    <div className="hashtag-strip-container">
      <div className="hashtag-strip">
        {props.hashTags.Categories !== null &&
        props.hashTags?.Categories?.length !== 0 ? (
          props.hashTags?.Categories.map((tagObj) => {
            let tag = tagObj?.categoryName;
            return (
              <CheckableTag
                key={tag}
                checked={selectedTags.indexOf(tag) > -1}
                onChange={(checked) => handleChange(tag, checked)}
                style={{ fontSize: "15px", backgroundColor: "blue" }}
                className="border p-2 px-3 m-1 mt-0 rounded-pill bg-light"
              >
                <b>#{tag}</b>
              </CheckableTag>
            );
          })
        ) : (
          <div>Loading...</div>
        )}
        <Modal
          centered
          title={
            <div
              style={{
                textAlign: "center",
                fontStyle: "italic",
                fontSize: "large",
              }}
            >{`#${selectedTags[0]}`}</div>
          }
          visible={showModal}
          onCancel={closeModal}
          onOk={closeModal}
          style={{
            width: "100%",
            maxWidth: "1000px",
            maxHeight: "100vh",
            overflowY: "hidden",
          }}
          bodyStyle={{ padding: "0.5rem " }}
          footer={[]}
          destroyOnClose={true}
        >
          <HashTagsTabs tag={selectedTags[0]}></HashTagsTabs>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    hashTags: state.hashTags,
    oneMins: state.hashTags.OneMin,
    expertTips: state.hashTags.expertTips,
  };
};
export default connect(mapStateToProps)(HastagsHeadline);
