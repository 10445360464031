/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Image,
  message,
  DatePicker,
} from "antd";
import AppScreen from "../AppScreen";
import bgImage from "../../assets/images/partner-signup.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import { apis } from "../../shared/apis";
// import UserSignUpOtpForm from "./UserSignUpOtpForm";
import moment from "moment";
const { Option } = Select;
const UserSignup = (props) => {
  //   const [loader, setLoader] = useState(false);
  // const [showOtpForm, setShowOtpForm] = useState(false);
  const [numStatus, setNumStatus] = useState({
    message: "Sign Up",
    isLoading: false,
    error: "",
  });
  const [userData, setUserData] = useState({});
  // const [otp, setOTP] = useState("");
  const [fetching, setFetching] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const prefixSelector = (
  //   <Form.Item name="countryCode" noStyle>
  //     <Select
  //       style={{
  //         width: 80,
  //       }}
  //       defaultValue="+91"
  //       value="+91"
  //     >
  //       <Option value="+86">+86</Option>
  //       <Option value="+87">+87</Option>
  //       <Option value="+91">+91</Option>
  //     </Select>
  //   </Form.Item>
  // );

  // const numberverify = async (data) => {
  //   setNumStatus({ message: "Verifying...", isLoading: true, error: "" });
  //   await axios({
  //     ...apis.patientnumberverify,
  //     data: { mobile: data.pmobile },
  //   })
  //     .then((res, err) => {
  //       if (err) {
  //         message.error("ERR in Verifying: ");
  //         setNumStatus({
  //           message: "Retry",
  //           isLoading: false,
  //           error: err?.message,
  //         });
  //       }
  //       // console.log("Res from patientnumberverify: ", res);
  //       if (res.data.status === "SUCCESS") {
  //         setOTP(res.data.otp);
  //         setShowOtpForm(true);
  //         setNumStatus({
  //           message: "Success",
  //           isLoading: false,
  //           error: "",
  //         });
  //       } else {
  //         setNumStatus({
  //           message: "Retry",
  //           isLoading: false,
  //           error: "Number Already Registered!",
  //         });
  //         message.error("Number Already Registered!");
  //         setShowOtpForm(false);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err) message.error("ERR in Verifying: ");
  //     });
  // };

  const fetchProfile = async (userData) => {
    // const hashDigest = sha256(state.password);
    // console.log(Base64.stringify(hashDigest));
    await axios({
      ...apis.patientsignin,
      data: {
        // email: state.email,
        // password: Base64.stringify(hashDigest),
        contact: props?.match.params?.phone,
      },
    })
      .then((resp, err) => {
        // setLoader(false);
        console.log("resp: ", resp);
        if (err) {
          message.error("Something Went Wrong");
        }
        //  else if (resp?.data?.status !== "SUCCESS") {
        //   message.error("Please Register yourself");
        //   props?.history.push(`/user-signup/${state.phone}`);
        // }
        else {
          message.success("Logged in Successfully!");

          localStorage.setItem("token", props?.match.params?.token);
          localStorage.setItem(
            "userEmail",
            JSON.stringify(
              resp.data?.role[0]?.patientemail ||
                userData?.email ||
                "No email provided"
            )
          );
          localStorage.setItem("userProfile", JSON.stringify(resp.data));
          props.history.push("/dashboard");
        }
      })
      .catch((err) => {
        message.error("Something Went Wrong");
      });
  };

  const registerUser = async (userData) => {
    await axios({
      ...apis.patientregistration,
      data: {
        ...userData,
        pcity: userData.pcity.value,
        pstate: userData.pstate.value,
        pdob: moment(userData.pdob).format("YYYY-MM-DD"),
        pphoto: "",
      },
    })
      .then((res, err) => {
        if (err) {
          message.error("Something Went Wrong");
        } else {
          message.success("Successfully Resgistered");
          // setVerify({
          //   verifying: false,
          //   status: "Verified",
          //   type: "success",
          // });
          localStorage.setItem(
            "userEmail",
            userData?.pemail || "No email Provided"
          );
          localStorage.setItem("pid", res?.data?.pid);
          fetchProfile(userData);
          // props.history.push("/dashboard");
        }
      })

      .catch((err) => {
        if (err) {
          message.error("Something went wrong");
          // console.log("Err in Reg usr catch: ", err);
        }
      });
  };

  const handleSubmit = (e) => {
    // console.log("C:", e);
    setUserData(e);
    registerUser(e);
    // numberverify({ ...e });
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: "S" } })
      .then((res, err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
        let sts = [];
        let cts = [];
        res.data?.info?.forEach((element) => {
          sts.push({
            label: element.cstate,
            value: element.cstate,
            key: element.cstate + Math.random(),
          });
          cts.push({
            label: `${element.cname} (${element.cstate})`,
            value: element.cname,
            key: element.cname + Math.random(),
          });
        });
        sts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        cts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        setStates(sts);
        setCities(cts);
      })
      .catch((err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const fetchCities = async (val) => {
    setFetching(true);
    await axios({ ...apis.getCityState, data: { cityorstate: val } })
      .then((res, err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");

        let cts = [];
        res.data?.info?.forEach((element) => {
          cts.push({
            label: `${element.cname} (${element.cstate})`,
            value: element.cname,
            key: element.cname + Math.random(),
          });
        });
        cts.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
        setCities([...cts]);
      })
      .catch((err) => {
        setFetching(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  return (
    <div>
      <div
        style={{
          minHeight: "84vh",
          backgroundColor: "#F3FBFE",
          display: "flex",
          justifyContent: "space-around",
          padding: "2rem",
        }}
        className="row mx-0"
      >
        <div
          className="col-sm-6"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Card
            hoverable
            title={<div style={{ fontSize: "1.25rem" }}>Join Us</div>}
            style={{ width: "50%", minWidth: "400px", alignSelf: "flex-start" }}
          >
            <Form
              onFinish={handleSubmit}
              initialValues={{
                role: props?.location?.state?.role?.role,
                pmobile: props?.match.params?.phone,
              }}
            >
              <Form.Item
                name="pname"
                rules={[{ required: true, message: "Please Enter Your Name!" }]}
              >
                <Input size="large" placeholder="Full Name" />
              </Form.Item>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name="pdob"
                  rules={[
                    { required: true, message: "Please enter your DOB!" },
                  ]}
                  style={{ flexGrow: "2", paddingRight: "10px" }}
                >
                  <DatePicker
                    size="large"
                    placeholder="Select your DOB"
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                <Form.Item name="pgender">
                  <Select placeholder="Gender" size="large">
                    <Option value="M">Male</Option>
                    <Option value="F">Female</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                name="pmobile"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Phone Number!",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length === 10) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Please enter valid Phone Number")
                      );
                    },
                  }),
                ]}
                hasFeedback
                help={
                  numStatus?.error ? (
                    <div className="text-danger">{numStatus?.error}</div>
                  ) : null
                }
              >
                <Input
                  disabled={true}
                  size="large"
                  placeholder="Phone Number"
                />
              </Form.Item>
              <Form.Item
                name="pemail"
                rules={[
                  { required: false, message: "Please Enter Your Email!" },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  placeholder="Email (Optional)"
                />
              </Form.Item>
              <Form.Item
                name="pstate"
                rules={[{ required: true, message: "Please enter your State" }]}
              >
                <Select
                  placeholder="Select Your State"
                  loading={fetching}
                  size="large"
                  allowClear
                  showSearch
                  labelInValue
                  filterOption={true}
                  onChange={(e) => {
                    fetchCities(e.value);
                  }}
                  options={states}
                ></Select>
              </Form.Item>
              <Form.Item
                name="pcity"
                rules={[{ required: true, message: "Please enter your City" }]}
              >
                <Select
                  placeholder="Select Your City"
                  loading={fetching}
                  size="large"
                  allowClear
                  showSearch
                  labelInValue
                  filterOption={true}
                  options={cities}
                ></Select>
              </Form.Item>
              {/* <Form.Item
                name="pemailpasswd"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  autoComplete="user-new-password"
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("pemailpasswd") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Confirm Password"
                  autoComplete="user-new-password"
                />
              </Form.Item> */}
              <Form.Item>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  <div key={"signup"}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      key="signup-btn-usr"
                      loading={numStatus?.isLoading}
                    >
                      Sign Up
                    </Button>
                  </div>
                  <div key={"login"}>
                    Already have an account ?
                    <Link to="/user-login">
                      <Button type="link" key="login-btn">
                        Login
                      </Button>
                    </Link>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div className="col-sm-6">
          <div
            style={{
              margin: "auto",
              color: "#030852",
              fontSize: "2.5rem",
              fontFamily: "monospace",
              textAlign: "center",
              padding: "2rem",
            }}
            className=" container-fluid "
          >
            Thank you for joining Jan Elaaj. Now get medical help at your
            fingertips.
          </div>
          <Image
            // style={{ opacity: "0.4" }}
            preview={false}
            src={bgImage}
            alt="doc patient"
          ></Image>
        </div>
      </div>
      <div>
        <AppScreen></AppScreen>
      </div>
      {/* <Modal
        title={<div>Please Verify your Number</div>}
        visible={showOtpForm}
        centered
        onCancel={() => setShowOtpForm(false)}
        footer={[
          <Button type="default" onClick={() => setShowOtpForm(false)}>
            Close
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <UserSignUpOtpForm
          userData={userData}
          otp={otp}
          {...props}
        ></UserSignUpOtpForm>
      </Modal> */}
    </div>
  );
};
// const mapStateToProps = (state) => {
//   return {
//     state: state,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setSpecialityList: (data) => dispatch(dashboardAC.setSpecialityList(data)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(PartnerSignup);

export default UserSignup;
