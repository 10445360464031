import { Table, message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { apis } from "../shared/apis";
import SearchPatient from "./SearchPatient";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
const PreviousPaymentsList = ({ selectedClinic, selectedRole, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [invoice, setInvoice] = useState("");
  const [startDate, setStartDate] = useState(moment);
  const [endDate, setEndDate] = useState(moment);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const updateData = () => {
    setShouldUpdate(!shouldUpdate);
  };
  const dProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    updateData,
  };

  const downloadInvoiceHandler = async (id) => {
    await axios({
      ...apis.getDisbursalInvoice,
      responseType: "blob",
      data: { jdlat_id: id, subject: props?.subject, id: props?.id },
    })
      .then((res, err) => {
        if (err) message.error("Err in Downloading Invoice: ");
        else {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          // setInvoice(fileURL);
        }
      })
      .catch((err) => {
        if (err) message.error("Err in Downloading Invoice: ");
      });
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <DownloadOutlined
          style={{ fontSize: "1.5rem", cursor: "pointer" }}
          className="text-primary"
          onClick={() => downloadInvoiceHandler(record?.jdlat_id)}
        />
      ),
      //        responsive: ["md"],
      width: 100,
    },
    {
      title: "JDLAT ID",
      dataIndex: "jdlat_id",
      key: "jdlat_id",
      ellipsis: false,
      align: "center",
      //        responsive: ["md"],
      width: 150,
    },
    {
      title: "Payment Mode",
      dataIndex: "jdlat_payment_mode",
      key: "jdlat_payment_mode",
      ellipsis: false,
      align: "center",
      //        responsive: ["md"],
      width: 150,
    },
    {
      title: "Last Update",
      dataIndex: "jdlat_create_date",
      key: "jdlat_create_date",
      ellipsis: false,
      align: "center",
      //        responsive: ["md"],
      width: 150,
    },
    {
      title: "Time",
      dataIndex: "jdlat_create_time",
      key: "jdlat_create_time",
      ellipsis: false,
      align: "center",
      //        responsive: ["md"],
      width: 100,
    },
    {
      title: "Amount",
      dataIndex: "jdlat_final_paid_amount",
      key: "jdlat_final_paid_amount",
      align: "center",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "jdlat_payment_status",
      key: "jdlat_payment_status",
      align: "center",
      width: 100,
    },
    {
      title: "Comment",
      dataIndex: "jdlat_comment",
      key: "jdlat_comment",
      align: "center",
      ellipsis: true,
    },
  ];

  const addKey = (arr) => {
    let temp = [];
    arr.forEach((item) => {
      let obj = { ...item, key: item?.pdla_id };
      temp.push(obj);
    });
    return temp;
  };

  useEffect(() => {
    const fetchPreviousPaymentsList = async () => {
      let obj = {
        end_date: endDate.length < 11 ? endDate : endDate.format("DD-M-YYYY"),
        start_date:
          startDate.length < 11 ? startDate : startDate.format("DD-M-YYYY"),
        role: selectedRole?.role,
        dlm_id: selectedClinic?.dlmid,
        subject: props?.subject,
        id: props?.id,
      };
      setLoading(true);
      await axios({ ...apis.getPaymentsFromJdlat, data: obj })
        .then((res, err) => {
          // if (err) console.log("Err in Fetching PayList:", err);
          // console.log("Res", res.data);
          // console.log("Res", addKey(res.data.info));
          setData(addKey(res.data.info));
          setLoading(false);
        })
        .catch((err) => {
          // if (err) console.log("Err in Fetching CAL:", err);
          setLoading(false);
        });
    };
    fetchPreviousPaymentsList();
    // return () => {
    //     cleanup
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate, selectedRole?.role, selectedClinic?.dlmid]);
  // }, [startDate, endDate, selectedRole?.role, selectedClinic?.dlmid]);

  return (
    <div>
      <SearchPatient {...dProps}></SearchPatient>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        // pagination={{ pageSize: 50 }}
        scroll={{ y: 250 }}
        // size="small"
        className="my-2 px-1 pr-3"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    partnerData: state.partnerData,
  };
};

export default connect(mapStateToProps)(PreviousPaymentsList);
