/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Empty, Modal, Result, Affix } from "antd";
import { PlusOutlined, LoginOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PostCard from "./PostCard";
import AddPostForm from "./AddPostForm";
import app from "../firebase";
import LoadingMask from "./LoaderMask/LoadingMask";

const MyPosts = (props) => {
  const [showAddPostForm, setShowAddPostForm] = useState(false);
  const [container, setContainer] = useState(null);
  const [myPosts, setMyPosts] = useState({ value: [], isLoading: true });
  const [maxLength, setMaxLength] = useState(0);
  const [loader, setLoader] = useState(false);
  const [endOfPost, setEndOfPost] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const page = 5;

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let userProfile = JSON.parse(localStorage.getItem("userProfile"))
        ?.role[0];
      let email = userProfile?.patientemail.replace(".", ",");
      let phone = userProfile?.patientmobile;

      // app
      //   .database()
      //   .ref(`myposts/${email}`)
      //   // .ref(`myposts/AAAAAAAA@gmail,com`)
      //   .on('value', snapshot => {
      //     let myplist = {}
      //     snapshot.forEach((snap, i) => {
      //       console.log('snap: ', snap)
      //       myplist = { ...myplist, [snap.ref_.path?.pieces_[2]]: snap.val() }
      //       // snap.val() is the dictionary with all your keys/values from the 'students-list' path
      //     })

      //     this.setState({ myPosts: myplist })
      //     this.props.setMyPosts({ value: myplist, isLoading: false })
      //   })
      app
        .database()
        .ref(`myposts/${phone}/${props.tag}`)
        .on("value", (snapshot) => {
          setMaxLength(snapshot.numChildren());
        });
      app
        .database()
        .ref(`myposts/${phone}/${props.tag}`)
        .limitToLast(limit)
        .on("value", (snapshot) => {
          let myplist = [];
          snapshot.forEach((snap, i) => {
            myplist.unshift(snap.val());
          });
          setMyPosts({ value: myplist, isLoading: false });
          setLoader(false);
          setCount(myplist.length);
          setEndOfPost(maxLength === myplist.length);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, props.tag]);
  return (
    <div style={{ minHeight: "300px" }}>
      {localStorage.getItem("token") ? (
        myPosts?.isLoading ? (
          <LoadingMask></LoadingMask>
        ) : myPosts?.value?.length !== 0 ? (
          <div
            className="scrollable-container"
            ref={setContainer}
            style={{
              overflowY: "scroll",
              maxHeight: "87vh",
            }}
          >
            {myPosts?.value?.map((post) => (
              <div key={post?.user_id} className="m-1 mb-4">
                <PostCard {...post}></PostCard>
              </div>
            ))}
            <div className="text-center p-1">
              {endOfPost ? (
                <Empty description={"No More Posts "}></Empty>
              ) : (
                <Button
                  loading={loader}
                  type="link"
                  onClick={() => setLimit(limit + page)}
                >
                  Load More
                </Button>
              )}
            </div>

            <Affix offsetBottom={150}>
              <Button
                className="m-4 float-right"
                type="primary"
                shape="circle"
                size="large"
                // icon={}
                onClick={() => setShowAddPostForm(true)}
              >
                <div>
                  <PlusOutlined
                    style={{ display: "inline-flex", fontSize: "1rem" }}
                  />
                </div>

                {/* Add New Post */}
              </Button>
            </Affix>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Empty className="m-4" description={"No Posts yet !"}></Empty>
            <Button
              className="m-4"
              type="primary"
              shape="round"
              icon={<PlusOutlined style={{ display: "inline-flex" }} />}
              onClick={() => setShowAddPostForm(true)}
            >
              Add Post
            </Button>
          </div>
        )
      ) : (
        <div>
          {" "}
          <Result
            status="403"
            title="Please Login First"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Link to="/user-login">
                <Button
                  type="primary"
                  icon={<LoginOutlined style={{ display: "inline-flex" }} />}
                >
                  Login
                </Button>
              </Link>
            }
          />
        </div>
      )}
      <Modal
        visible={showAddPostForm}
        title={<div>New Post</div>}
        closable
        onCancel={() => setShowAddPostForm(false)}
        footer={[]}
        centered
        destroyOnClose={true}
      >
        <AddPostForm
          // key={props?.tag + Math.random()}
          setShowAddPostForm={setShowAddPostForm}
          tag={props?.tag}
        ></AddPostForm>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hashtags: state.hashTags,
    myPosts: state?.hashTags?.myPosts,
  };
};

export default connect(mapStateToProps)(MyPosts);
