/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PartnerRoleCard from "./PartnerRoleCard";
// import {sha256} from 'crypto-js';
import "../../assets/styles/DoctorVerification.css";
import "../../assets/styles/PartnerRoles.scss";
import {
  setIsPartnerLoggedIn,
  setRolesList,
} from "../../redux/ActionCreators/VerificationActionCreators";
import { apis } from "../../shared/apis";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Card, message } from "antd";
import CardHeaderCustom from "../containers/CardHeaderCustom";
import { cardHeaderStyle } from "../../assets/styles/PartnerAppStyles";
function PartnerRoles(props) {
  const email = localStorage.getItem("partnerEmail");
  const rolesList = [
    {
      role: "CHE",
      roleName: "CHEMIST",
      roleid: "CHE2000015",
      mobileno: "8448735275",
      isReg: false,
    },
    {
      role: "DOC",
      roleName: "OFFLINE DOCTOR",
      roleid: "DOC2000015",
      mobileno: "8448735275",
      isReg: false,
    },
    {
      role: "DIE",
      roleName: "OFFLINE DIETITIAN",
      roleid: "DIE3900006",
      mobileno: "84487352750",
      isReg: false,
    },
    {
      role: "ODIE",
      roleName: "ONLINE DIETITIAN",
      roleid: "ODIE1000002",
      mobileno: "8448735275",
      isReg: false,
    },
    {
      role: "ONL",
      roleName: "ONLINE DOCTOR",
      roleid: "ONL3900508",
      mobileno: "8448735275",
      isReg: false,
    },
    {
      role: "OPSY",
      roleName: "ONLINE PSYCHOLOGIST",
      roleid: "OPSY10005",
      mobileno: "84487352750",
      isReg: false,
    },
    {
      role: "PAT",
      roleName: "PATHOLOGY",
      roleid: "PAT2000016",
      mobileno: "84487352750",
      isReg: false,
    },
    {
      role: "PHY",
      roleName: "PSYCHOTHERAPIST",
      roleid: "PHY3900006",
      mobileno: "84487352750",
      isReg: false,
    },
    {
      role: "PSY",
      roleName: "PSYCHOLOGIST",
      roleid: "PSY1000001",
      mobileno: "8448735275",
      isReg: false,
    },
    {
      role: "RAD",
      roleName: "RADIOLOGY",
      roleid: "RAD3000018",
      mobileno: "84487352750",
      isReg: false,
    },
    {
      role: "VIT",
      roleName: "VITALS",
      roleid: "VIT3900023",
      mobileno: "84487352750",
      isReg: false,
    },
  ];
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [roles, setRoles] = useState(rolesList);

  const updateRoles = (regRoles) => {
    let newRoles = [...roles];
    for (let i = 0; i < roles.length; i++) {
      for (let j = 0; j < regRoles.length; j++) {
        if (roles[i].role === regRoles[j].role) {
          newRoles[i] = {
            ...roles[i],
            ...regRoles[j],
            isReg: true,
          };
        }
      }
    }

    let green = newRoles.filter((a) => a.isReg === true);
    let red = newRoles.filter((a) => a?.isReg === false);
    green.sort((a, b) => (a.roleName > b.roleName ? 1 : -1));
    red.sort((a, b) => (a.roleName > b.roleName ? 1 : -1));
    setRoles([...green, ...red]);
  };
  useEffect(() => {
    if (email)
      (async function () {
        setLoader(true);
        // console.log("Calling api");
        await axios({
          ...apis.partnerSignin,
          data: { email: email, version_code: "10" },
        })
          .then((res, err) => {
            if (err) {
              message.error("Err in Login");
              setLoader(false);
              setRedirect(true);
              props.setIsPartnerLoggedIn(false);
              localStorage.clear();
            } else {
              if (res.data.status.includes("INVALID")) {
                setLoader(false);
                setRedirect(true);
                props.setIsPartnerLoggedIn(false);
                localStorage.clear();
              } else {
                setRedirect(false);
                props.setPartnerRoles(res.data);
                updateRoles(res.data.role);
                localStorage.setItem(
                  "partnerPhone",
                  JSON.stringify(res?.data?.role[0]?.mobileno)
                );
                setLoader(false);
                props.setIsPartnerLoggedIn(true);
              }
            }
          })
          .catch((err) => {
            if (err) message.error("Err in siginn: ");
            setLoader(false);
            props.setIsPartnerLoggedIn(false);
            // antdMessage.error("ERROR: " + err.message);
            setRedirect(true);
          });
      })();
    else setRedirect(true);
  }, []);

  const routes = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/roles-list",
      pathName: "Roles List",
    },
  ];

  if (redirect) return <Redirect to="/partner-login"></Redirect>;
  return (
    <div className="container">
      <Card
        style={{ margin: "50px 2%" }}
        headStyle={cardHeaderStyle}
        title={
          <CardHeaderCustom
            title="Roles List"
            showPath={true}
            routes={routes}
          ></CardHeaderCustom>
        }
        loading={loader}
      >
        {loader ? (
          "Loading..."
        ) : (
          <div className="row mr-0">
            {/* {props.partnerData.partnerRoles.role.map((role, i) => ( */}
            {roles.map((role, i) => (
              <div className="col-sm-6" key={i + Math.random()}>
                <PartnerRoleCard role={role} {...props}></PartnerRoleCard>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    partnerData: state.partnerData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPartnerRoles: (obj) => dispatch(setRolesList(obj)),
    setIsPartnerLoggedIn: (flag) => dispatch(setIsPartnerLoggedIn(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerRoles);
