import * as ActionTypes from "../ActionTypes";
import { baseUrl } from "../../shared/baseUrl";

// GETTING ROLES
export const fetchRoles = (email) => (dispatch) => {
  dispatch(rolesLoading());
  return fetch(baseUrl + "signin", {
    method: "post",
    body: JSON.stringify(email),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addRoles(response));
    })
    .catch((error) => dispatch(rolesFailed(error.message)));
};

export const rolesLoading = () => ({
  type: ActionTypes.ROLES_LOADING,
});

// ROLES STATE CHANGE
export const addRoles = (roles) => ({
  type: ActionTypes.ADD_ROLES,
  payload: roles,
});

export const rolesFailed = (errmess) => ({
  type: ActionTypes.ROLES_FAILED,
  payload: errmess,
});

// GETTING DOCTOR INFO - THUNK
export const fetchDocInfo = (docinfo) => (dispatch) => {
  dispatch(docInfoLoading());
  return fetch(baseUrl + "verifyalldoctorinformation", {
    method: "post",
    body: JSON.stringify(docinfo),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addDocInfo(response));
    })
    .catch((error) => dispatch(docInfoFailed(error.message)));
};

export const docInfoLoading = () => ({
  type: ActionTypes.DOCINFO_LOADING,
});

// DOCINFO STATE CHANGE
export const addDocInfo = (docinfo) => ({
  type: ActionTypes.ADD_DOCINFO,
  payload: docinfo,
});

export const docInfoFailed = (errmess) => ({
  type: ActionTypes.DOCINFO_FAILED,
  payload: errmess,
});

export const postDocInfo = (docinfo) => (dispatch) => {
  return fetch(baseUrl + "updateverifypartners", {
    method: "post",
    body: JSON.stringify(docinfo),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      // console.log("response: ", response);
    })
    .catch((error) => console.log(error.message));
};

// GETTING LOCATION INFO - THUNK
export const fetchLocInfo = (locinfo) => (dispatch) => {
  dispatch(locInfoLoading());
  return fetch(baseUrl + "managelocation", {
    method: "post",
    body: JSON.stringify(locinfo),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addLocInfo(response));
    })
    .catch((error) => dispatch(locInfoFailed(error.message)));
};

export const locInfoLoading = () => ({
  type: ActionTypes.LOCINFO_LOADING,
});

// LOCINFO STATE CHANGE
export const addLocInfo = (locinfo) => ({
  type: ActionTypes.ADD_LOCINFO,
  payload: locinfo,
});

export const locInfoFailed = (errmess) => ({
  type: ActionTypes.LOCINFO_FAILED,
  payload: errmess,
});

export const postLocInfo = (locinfo) => (dispatch) => {
  return fetch(baseUrl + "updateverifylocation", {
    method: "post",
    body: JSON.stringify(locinfo),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => console.log(error.message));
};

// GETTING GOLIVE DOCTORS INFO
export const fetchDocsInfo = (doc) => (dispatch) => {
  dispatch(docsInfoLoading());
  return fetch(baseUrl + "golivependingquery", {
    method: "post",
    body: JSON.stringify(doc),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addDocsInfo(response));
    })
    .catch((error) => dispatch(docsInfoFailed(error.message)));
};

export const docsInfoLoading = () => ({
  type: ActionTypes.DOCSINFO_LOADING,
});

// LOCINFO STATE CHANGE
export const addDocsInfo = (docsinfo) => ({
  type: ActionTypes.ADD_DOCSINFO,
  payload: docsinfo,
});

export const docsInfoFailed = (errmess) => ({
  type: ActionTypes.DOCSINFO_FAILED,
  payload: errmess,
});

export const postDocsInfo = (docsinfo) => (dispatch) => {
  return fetch(baseUrl + "goliveupdate", {
    method: "post",
    body: JSON.stringify(docsinfo),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => console.log(error.message));
};

// LOGIN API

export const loginUser = (creds) => (dispatch) => {
  dispatch(requestLogin());
  return fetch(baseUrl + "employeesignin", {
    method: "post",
    body: JSON.stringify(creds),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((user) => {
      dispatch(receiveLogin(user));
    })
    .catch((error) => dispatch(loginError(error.message)));
};

// Handling Login and Logout
export const requestLogin = () => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
  };
};

export const receiveLogin = (user) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    user,
  };
};

export const loginError = (message) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    message,
  };
};

export const logoutSuccess = () => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
  };
};

// CHANGE THE PASSWORD
export const changePass = (creds) => (dispatch) => {
  return fetch(baseUrl + "changeemployeepassword", {
    method: "post",
    body: JSON.stringify(creds),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (response) => {
        if (response.ok) return response;
        else {
          let error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error; // server is responding with an error response code, will be handled by catch
        }
      },
      (error) => {
        // if client unable to connect to the server
        throw new Error(error.message);
      }
    )
    .then((response) => response.json())
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => dispatch(loginError(error.message)));
};
// -------------------------------------------Partner Data------------------------------------------------------------

//gettting Appointment list for Partner
export const setIsPartnerLoggedIn = (flag) => {
  return {
    type: ActionTypes.SET_ISPARTNER_LOGGED_IN,
    payload: flag,
  };
};
export const getAppointmentListLoading = (flag) => {
  return {
    type: ActionTypes.APPOINTMENT_LOADING,
    payload: flag,
  };
};
export const getAppointmentListFailed = (flag) => {
  return {
    type: ActionTypes.APPOINTMENT_FAILED,
    payload: flag,
  };
};
export const getAppointmentListSuccess = (flag) => {
  return {
    type: ActionTypes.APPOINTMENT_SUCCESS,
    payload: flag,
  };
};
export const setAppointmentList = (list) => {
  return {
    type: ActionTypes.SET_APPOINTMENT_LIST,
    payload: list,
  };
};

export const setRolesList = (obj) => {
  return {
    type: ActionTypes.SET_ROLES_LIST,
    payload: obj,
  };
};

export const setClinicsList = (obj) => {
  return {
    type: ActionTypes.SET_CLINIC_LIST,
    payload: obj,
  };
};
