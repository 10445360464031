import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Divider,
  message,
  Select,
  Spin,
  Form,
  Button,
  Radio,
  Modal,
  Empty,
  Input,
  Checkbox,
} from "antd";
import {
  UserOutlined,
  // SolutionOutlined,
  // LoadingOutlined,
  // SmileOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/logo.png";

import CheckableTag from "antd/lib/tag/CheckableTag";
import { connect } from "react-redux";
import axios from "axios";
import { apis } from "../../shared/apis";
import PaytmPayment from "../payment/PaytmPayment";
import SymptomsForm from "./SymptomsForm";
import LoadingMask from "../LoaderMask/LoadingMask";
import moment from "moment";
const { Option } = Select;

const BookingForm = (props) => {
  console.log("props BF: ", props);
  //   const [dependent, setDependent] = useState(
  //     JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientname ||
  //       "Choose Dependent"
  //   );
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))?.role[0];
  // // console.log("uP: ", userProfile);

  const homeDeliverMessage =
    props?.selectedService?.role === "CHE"
      ? "Medicine Home Delivery"
      : props?.selectedService?.role === "PAT"
      ? "Sample Home Delivery"
      : false;

  // eslint-disable-next-line no-unused-vars
  const [patList, setPatList] = useState([
    {
      dependentid: userProfile.patientid,
      dob: userProfile.patientdob,
      email: userProfile.patientemail,
      gender: userProfile.patientgender,
      mobile: userProfile.patientmobile,
      name: userProfile.patientname,
      patientid: userProfile.patientid,
      pdmid: userProfile.patientid,
      photo: userProfile.patientphoto,
    },
    ...props?.dependents,
  ]);
  const [dependent, setDependent] = useState(
    patList?.length ? patList[0].name : "Choose Dependent"
  );
  const [appointmentDate, setAppointmentDate] = useState();
  const [appointmentTime, setAppointmentTime] = useState({
    isChecked: false,
  });
  const [service, setService] = useState();

  const [timeArr, setTimeArr] = useState([]);

  // const [otp, setOtp] = useState("");
  const [isDayAvailable, setIsDayAvailable] = useState(false);
  const [payingL, setPayingL] = useState(false);

  const [loader, setLoader] = useState(false);

  const [showPaytmPayment, setShowPaytmPayment] = useState(false);
  const [showAddSymtomForm, setShowAddSymtomForm] = useState(false);
  const [checkSumRes, setCheckSumRes] = useState({});

  const [promoCode, setPromoCode] = useState("");
  const [promoCodeRes, setPromoCodeRes] = useState("");
  const [isApplying, setIsApplying] = useState({
    loading: false,
    title: "Apply",
    color: "primary",
  });

  const [amount, setAmount] = useState(0);
  const [payFunc, setPayFunc] = useState(1);

  const [loadingOrganisation, setLoadingOrganisation] = useState(false);
  const [organList, setOrganList] = useState([]);
  const [selectedOrgan, setSelectedOrgan] = useState({});
  const [unqFormVisible, setUnqFormVisible] = useState(
    props?.doc?.validation_flag === "Y" ? true : false
  );
  const [unqId, setUnqId] = useState("");
  const [unqVerifying, setUnqVerifying] = useState(false);

  const [pdlaid, setPdlaId] = useState("");

  const [createOrderId, setCreateOrderID] = useState(false);

  const [homeDeliveryFlag, setHomeDeliveryFlag] = useState(false);

  const generateOTP = () => {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    // setOtp(OTP);
    return OTP;
  };

  const formatTime = (time) => {
    let str = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
    return str;
  };

  const isTimeExpired = (from, to) => {
    const curr = moment().format("HHmm");
    if (appointmentDate > new Date()) return false;
    if (appointmentDate?.format("YYYY-MM-DD")) {
      if (parseInt(curr) <= parseInt(to)) return false;
    }
    return true;
  };

  const disabledDate = (current) => {
    // Can not select days before today
    // return false;
    return current &&
      current.format("DD-MM-YYYY") ===
        moment().endOf("day").format("DD-MM-YYYY")
      ? false
      : current < moment().endOf("day");
  };

  const getPid = (dependent) => {
    let res = {};
    res = props?.dependents?.filter((dep) => dep.name === dependent);
    return typeof res[0] !== "undefined"
      ? res[0].dependentid || res[0].patientid
      : JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientid;
  };

  const addSymtomProps = {
    pdlaid: pdlaid || "pdlaid from booking form",
    empid: "",
    role: props?.selectedService?.role || "role in Booking Form",
    dependent: {
      patientid: getPid(dependent) || "patient id in Booking Form",
    },
  };

  const askSymptoms = () => {
    setShowAddSymtomForm(true);
  };
  const input = {
    CUST_ID: "888", // can be empty
    CHANNEL_ID: "WAP", // channel ID(same as WAP)
    ORDER_ID: "", // can be empty
    TXN_AMOUNT: amount || "",
    // (appointmentTime?.ldiscountnormal === "Normal"
    //   ? service?.ldcsmnormalamount + ""
    //   : service?.ldcsmdiscountedamount + "") || "", // transaction amount
    CALLBACK_URL:
      "https://securegw.paytm.in/theia/paytmCallback?ORDER_ID=PDLAT300427",
    INDUSTRY_TYPE_ID: "Retail", // Same
    WEBSITE: "WEBSTAGING", // Same
    // payment_code: 1, // Same
  };

  const patNewPlanInput = {
    // dflag: appointmentTime?.ldiscountnormal === "Normal" ? "N" : "Y",
    // dflag: dependent === patList[0].name ? "N" : "Y",
    dflag: getPid(dependent) === patList[0].dependentid ? "N" : "Y",
    // banktransactionid: "156031269824",
    role: props?.selectedService?.role,
    // organisation_code: "",
    paynowflag: "Y",
    // pid: JSON.parse(localStorage.getItem("userProfile"))?.role[0]?.patientid,
    pid: getPid(dependent),
    otp: generateOTP(),
    pmid: service?.ldcsmid,
    modeofpayment: "PPI",
    tid: appointmentTime?.timeid || "1514",
    dcsmpaymentapplicableflag: "0",
    mom_mental_health_flag: "N",
    homeCollectionFlag: homeDeliveryFlag ? "Y" : "N",
    appointmentdate: appointmentDate?.format("YYYY-MM-DD"),
    dlmid: props?.doc?.ldlm_id,
    // pdlatid: "PDLATD1000d579",
    dcsmamount: amount,
    // appointmentTime?.ldiscountnormal === "Normal"
    //   ? service?.ldcsmnormalamount + ""
    //   : service?.ldcsmdiscountedamount + "",
  };

  const patNewPlanHandler = async (ptmChk) => {
    setLoader(true);

    await axios({
      ...apis.insertAppointmentPayNow,
      data: {
        ...patNewPlanInput,
        pdlatid: ptmChk?.data?.ORDER_ID || patNewPlanInput?.pdlatid, // order id
        paytmtransactionid: ptmChk?.data?.TXNID,
      },
    })
      .then((res, err) => {
        setLoader(false);
        if (err) message.error("Something went Wrong");
        localStorage.setItem(
          "invoice",
          JSON.stringify([
            {
              title: "Order Id",
              value: ptmChk?.data?.ORDER_ID,
            },
            { title: "Amount", value: patNewPlanInput?.dcsmamount },
            { title: "Date", value: patNewPlanInput?.appointmentdate },
            {
              title: "Transaction Id",
              value: ptmChk.data?.TXNID || "XXXXXX",
            },
            { title: "Service", value: service?.lsmservicename },
          ])
        );
        localStorage.setItem(
          "rpInvoice",
          JSON.stringify([
            {
              title: "Order Id",
              value: ptmChk?.data?.ORDER_ID || "Will be Generated Later",
            },
            {
              title: "Amount",
              value: patNewPlanInput?.dcsmamount || amount,
            },
            {
              title: "Date",
              value: patNewPlanInput?.appointmentdate || appointmentDate,
            },
            {
              title: "Transaction Id",
              value: ptmChk.data?.TXNID || "XXXXXX",
            },
            { title: "Service", value: service?.lsmservicename },
            {
              title: "extra",
              data: {
                ...res.data,
                txn_status: "SUCCESS",
                txn_info: "Transaction Successful",
              },
            },
          ])
        );
        localStorage.setItem("pdla", JSON.stringify(res.data?.pdla_id));
        setPdlaId(res.data?.pdla_id);
        setShowPaytmPayment(false);
        askSymptoms();
        // setTimeout(() => (window.location = "/paytm-payment-result"), 1000);
      })
      .catch((err) => {
        setShowPaytmPayment(false);
        setLoader(false);
        if (err) message.error("Something went Wrong");
      });
  };

  const buyPlanHandler = async () => {
    setLoader(true);
    await axios({ ...apis.PaytmGenerateCheckSum, data: input })
      .then((res, err) => {
        if (err) message.error("Something Went Wrong");
        setCheckSumRes(res.data);
        if (
          res.data.head?.signature?.length > 0 ||
          res.data.data?.ORDER_ID?.length > 0
        ) {
          if (
            amount === 0
            // (appointmentTime?.ldiscountnormal === 'Normal'
            //   ? service?.ldcsmnormalamount
            //   : service?.ldcsmdiscountedamount) === 0
          ) {
            setLoader(false);
            patNewPlanHandler(res.data);
          } else {
            setLoader(false);
            setShowPaytmPayment(true);

            // Modal.info({
            //   title: "Paytm Payment",
            //   content: (
            //     <PaytmPayment
            //       {...res.data}
            //       amt={props.lplanamount + ""}
            //     ></PaytmPayment>
            //   ),
            // });
          }
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        if (err) message.error("Something Went Wrong");
        setLoader(false);
        setShowPaytmPayment(false);
      });
  };

  const payDetails = () => (
    <div>
      <table className="table table-responsive ">
        <tbody>
          <tr>
            <th>Service</th>
            <td>{service?.lsmservicename}</td>
          </tr>
          <tr>
            <th>Appointment Date</th>
            <td>{appointmentDate?.format("YYYY-MM-DD")}</td>
          </tr>
          <tr>
            <th>Time Slot</th>
            <td>
              {`${formatTime(appointmentTime?.timefrom)}-${formatTime(
                appointmentTime?.timeto
              )}`}
            </td>
          </tr>
          <tr>
            <th>Service Amount</th>
            <td>₹{service?.ldcsmnormalamount + ""}</td>
          </tr>
          <tr>
            <th>Total Amount</th>
            <td>
              ₹{amount + ""}
              {/* {appointmentTime?.ldiscountnormal === "Normal"
                ? service?.ldcsmnormalamount + ""
                : service?.ldcsmdiscountedamount + ""} */}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Info:</th>
            <th>
              If you want to pay by Credit/Debit Please Select Paytm and enter
              your mobile number(You dont have to have a Paytm account) UPI -
              For UPI payments
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );

  const handleDependent = (e) => {
    setDependent(e);
  };

  const handleTime = (time, checked) => {
    if (checked) {
      let day = appointmentDate.format("ddd");
      // console.log("day: ", day);

      let timeArrDocDetails = props?.daytiminginfo?.filter(
        (dti) => dti?.ldldmdaynumber === day.toUpperCase()
      );
      // console.log("timeArrDocDetails: ", timeArrDocDetails);

      let timeDocDetiails = timeArrDocDetails?.filter(
        (tdd) =>
          tdd?.ldltmtimefrom === time?.timefrom &&
          tdd?.ldltmtimeto === time?.timeto
      );

      // console.log("timeDocDetiails: ", timeDocDetiails);
      setAppointmentTime({
        ...time,
        ldiscountnormal: timeDocDetiails[0]?.ldiscountnormal,
        isChecked: checked,
      });

      setAmount(
        timeDocDetiails[0]?.ldiscountnormal === "Normal"
          ? service?.ldcsmnormalamount
          : service?.ldcsmdiscountedamount
      );
    } else {
      setAppointmentTime({ isChecked: checked });
      setAmount(0);
      // console.log("amt : ", 0);
    }
  };

  const isDocAvailable = async (date) => {
    let inputTemp = {
      appointmentdate: date?.format("YYYY-MM-DD"), // selected date
      dlmid: props?.doc?.ldlm_id, // clinic id
      rescheduleflag: "N", // whether user wants a reshedule or not(means is user is resudeling an appt or not)
      patientid: getPid(dependent), // patient id
    };
    await axios({ ...apis.getpatientappointmentinfo, data: inputTemp })
      .then((res, err) => {
        if (err) message.error("Something Went Wrong");
        else {
          // console.log("res for info: ", res.data);
          if (res?.data?.status === "SUCCESS") {
            setTimeArr(res?.data?.info);

            // let flag = false;
            // if (date) {
            //   let day = date?.format("ddd");
            //   props?.daytiminginfo?.forEach((avaDay) => {
            //     if (avaDay?.ldldmdaynumber === day.toUpperCase()) {
            //       flag = true;
            //     }
            //   });
            // }

            // if (flag === false) {
            //   message.error("The Doctor Is Not In Attendence On The Choosen Day");
            // } else {
            message.success("The Doctor Is In Attendence On The Choosen Day");
            // }
            setIsDayAvailable(true);
          } else {
            setIsDayAvailable(false);
            Modal.info({
              title: `${res.data.status}`,
              onOk: props?.closeBookingForm,
            });
          }
        }
      })
      .catch((err) => {
        if (err) message.error("Something went Wrong");
      });

    // return flag;
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const checkPaymentStatus = async (id) => {
    await axios({
      ...apis.rpCheckPayment,
      url: `${apis.rpCheckPayment.url}?appt_id=${id}`,
    })
      .then((res, err) => {
        if (err) message.error("Something went wrong");
        else {
          if (res.data.status_code !== 1) {
            Modal.error({
              title: res.data.status,
              okText: "Home",
              onOk: () => props.history.push("/"),
            });
          } else {
            message.success("Appointment Created Successfully");
            localStorage.setItem(
              "rpInvoice",
              JSON.stringify([
                {
                  title: "Order Id",
                  value: id || "Will be Generated Later",
                },
                {
                  title: "Amount",
                  value: res.data?.transaction_details.txn_amount || amount,
                },
                {
                  title: "Date",
                  value:
                    res.data?.transaction_details.txn_date || appointmentDate,
                },
                {
                  title: "Transaction Id",
                  value: res?.data?.transaction_details.txn_id || "XXXXXX",
                },
                { title: "Service", value: service?.lsmservicename },
                { title: "extra", data: { ...res.data } },
              ])
            );
            askSymptoms();
            // props.history.push("/razorpay-payment-complete");
          }
        }
      })
      .catch((err) => {
        if (err) message.error("Something went wrong");
        console.log("eoror: ", err);
      });
  };

  const displayRazorPay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      Modal.error({ title: "Razorpay SDK failed to load. Are you online?" });
      return;
    }

    // creating a new order
    // eslint-disable-next-line no-unused-vars
    let userProfile = JSON.parse(localStorage.getItem("userProfile"))?.role[0];
    const input = {
      appt_details: {
        ...patNewPlanInput,
      },
      amount: amount,
    };
    setCreateOrderID(true);
    await axios({ ...apis.rpCreateOrderId, data: input })
      .then((res, err) => {
        setCreateOrderID(false);
        if (err) message.error("Server error. Are you online?");
        else {
          // Getting the order details back
          console.log("res.data : ", res.data);
          // const { order_id } = res.data;
          const options = {
            key:
              document.domain === "localhost"
                ? "rzp_test_B6bNY8QqNi15WB"
                : "rzp_live_odWZvXoQBCx0Xt", // Enter the Key ID generated from the Dashboard
            // key: " rzp_live_odWZvXoQBCx0Xt", // Enter the Key ID generated from the Dashboard
            amount: (amount * 100).toString(),
            // method: "card",
            // account_id: "IA0WFmSWWlEK86",
            currency: "INR",
            name: "Jan Elaaj",
            description: "A Healthcare Platform",
            image: logo,
            order_id: res.data.order_id,
            handler: async function (response) {
              const data = {
                orderCreationId: res.data.order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
              console.log("rp data : ", data);
              await checkPaymentStatus(res.data.appt_id);
              // const result = await axios.post(
              //   "http://localhost:5000/payment/success",
              //   data
              // );

              // alert(result.data.msg);
            },
            prefill: {
              name: userProfile?.patientname || "",
              email: userProfile?.patientemail || "",
              contact: userProfile?.patientmobile || "",
            },
            notes: {
              address:
                "Indian Institute of Management Lucknow, Noida Campus, B1, Institutional Area, Sector 62, Noida – 201307 Uttar Pradesh, India",
            },
            theme: {
              color: "#007bff",
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          paymentObject.on("payment.failed", function (response) {
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            localStorage.setItem(
              "rpError",
              JSON.stringify([
                {
                  title: "Order Id",
                  value: response.error.metadata.order_id || "XXXXXX",
                },
                { title: "Description", value: response.error.description },
                { title: "Reason", value: response.error.reason },
                {
                  title: "Transaction Id",
                  value: response.error.metadata.payment_id || "XXXXXX",
                },
                { title: "Service", value: service?.lsmservicename },
                ...response,
              ])
            );
            props.history.push("/razorpay-payment-failed");
          });
        }
      })
      .catch((err) => {
        setCreateOrderID(false);
        if (err) message.error("Server error. Are you online?");
        localStorage.setItem(
          "rpError",
          JSON.stringify([
            {
              title: "Order Id",
              value: "XXXXXX",
            },
            {
              title: "Description",
              value: "Payment Failed.Please Retry after some time",
            },
            {
              title: "Reason",
              value: "Payment Failed.Please Retry after some time",
            },
            {
              title: "Transaction Id",
              value: "XXXXXX",
            },
            { title: "Service", value: "" },
          ])
        );
        props.history.push("/razorpay-payment-failed");
      });
  };

  const okHandler = () => {
    // setShowPaytmPayment(true);
    // buyPlanHandler();
    // setTimeout(() => props?.setShowModalForm(false), 1000)

    // RAZORPAY-----
    if (amount > 0) displayRazorPay();
    else buyPlanHandler();
  };

  const payNowHandler = () => {
    Modal.confirm({
      title: "Confirm Details",
      content: payDetails(),
      onOk: okHandler,
      width: 520,
      closable: true,
      // bodyStyle: { padding: "0px" },
    });
  };
  const payLaterHandler = async () => {
    setPayingL(true);
    let payLaterInput = {
      // dltmid: `${formatTime(appointmentTime?.ldltmtimefrom)}-${formatTime(
      //   appointmentTime?.ldltmtimeto
      // )}`, // time slot id
      dltmid: appointmentTime?.timeid, // time slot id
      dflag: getPid(dependent) === patList[0].dependentid ? "N" : "Y", // discount flag: Y> discount is there,
      appointmentdate: appointmentDate?.format("YYYY-MM-DD"), // appointment date
      dlmid: props?.doc?.ldlm_id, // clinic id
      paynowflag: "N", // Always N
      homeCollectionFlag: homeDeliveryFlag ? "Y" : "N",
      dcsmid: service?.ldcsmid, // doctor service id
      pid: getPid(dependent), // patient or dependent id
      appointmentforrole: props?.selectedService?.role, // role
      otp: generateOTP(), // otp(create random otp)
      dcsmamount: amount,
      // appointmentTime?.ldiscountnormal === "Normal"
      //   ? service?.ldcsmnormalamount + ""
      //   : service?.ldcsmdiscountedamount + "", // amount
    };
    await axios({
      ...apis.insertAppointmentThroughAppPayLater,
      data: payLaterInput,
    })
      .then((res, err) => {
        setPayingL(false);
        if (err) message.error("Something Went Wrong");
        else {
          localStorage.setItem(
            "invoice",
            JSON.stringify([
              {
                title: "Order Id",
                value: "Will be Generated Later",
              },
              { title: "Amount", value: payLaterInput?.dcsmamount },
              { title: "Date", value: payLaterInput?.appointmentdate },
              {
                title: "Transaction Id",
                value: "XXXXXX",
              },
              { title: "Service", value: service?.lsmservicename },
            ])
          );

          localStorage.setItem(
            "rpInvoice",
            JSON.stringify([
              {
                title: "Order Id",
                value: "Will be Generated Later",
              },
              {
                title: "Amount",
                value: payLaterInput?.dcsmamount || amount,
              },
              {
                title: "Date",
                value: payLaterInput?.appointmentdate || appointmentDate,
              },
              {
                title: "Transaction Id",
                value: "XXXXXX",
              },
              { title: "Service", value: service?.lsmservicename },
              {
                title: "extra",
                data: {
                  ...res.data,
                  txn_status: "SUCCESS",
                  txn_info: "Transaction Successful",
                },
              },
            ])
          );
          localStorage.setItem("pdla", JSON.stringify(res.data?.pdla_id));
          askSymptoms();
        }
      })
      .catch((err) => {
        setPayingL(false);
        if (err) message.error("Something Went Wrong");
      });
  };

  const timeInputForm = () => {
    // let day = appointmentDate.format("ddd");
    // console.log("day: ", day);

    // let timeArr = props?.daytiminginfo?.filter(
    //   (dti) => dti?.ldldmdaynumber === day.toUpperCase()
    // );
    console.table("timeArr : ", timeArr);
    // let time = timeArr[0];
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {timeArr.map((time, i) => (
          <div className="m-1" key={time?.timeid || i + Math.random()}>
            <CheckableTag
              checked={
                time?.timeto === appointmentTime?.timeto &&
                time?.timefrom === appointmentTime?.timefrom
              }
              // checked={appointmentTime?.isChecked}
              size="large"
              onChange={(checked) => handleTime(time, checked)}
              style={{
                padding: "0",
                fontSize: "1rem",
                border: "1px solid #f1f1f1",
                disabled: true,
              }}
              disabled={true}
            >
              <Button
                style={{
                  padding: "0 0.50rem",
                  backgroundColor: "unset",
                  color: isTimeExpired(time?.timefrom, time?.timeto)
                    ? "#f5222d"
                    : "unset",
                  border: "none",
                }}
                // disabled={isTimeExpired(time?.ldltmtimefrom, time?.ldltmtimeto)}
                disabled={isTimeExpired(time?.timefrom, time?.timeto)}
              >
                {" "}
                {`${formatTime(time?.timefrom)}-${formatTime(time?.timeto)}`}
              </Button>
            </CheckableTag>
          </div>
        ))}
      </div>
    );
  };

  const applyPromoCodeHandler = async () => {
    if (promoCodeRes) {
      setPromoCodeRes("");
    }
    setIsApplying({ title: "Applying", loading: true, color: "primary" });
    const input = {
      Promo_code: promoCode,
      dlm_id: props?.doc?.ldlm_id,
      // dlm_id: "DLM01",
    };
    await axios({ ...apis.postVerifyPromoCode, data: input }, { timeout: 5000 })
      .then((res, err) => {
        if (err) {
          message.error("Something Went Wrong!");
          setIsApplying({
            title: "Retry",
            color: "danger",
            loading: false,
          });
        } else {
          setPromoCodeRes(res.data);
          // console.log("promoCodeRes: ", res.data);
          if (res?.data?.applyPromoCode === "N") {
            setIsApplying({
              title: "Retry",
              color: "danger",
              loading: false,
            });
            message.error(res?.data?.msg);
          } else {
            setIsApplying({
              title: "Applied",
              color: "success",
              loading: false,
            });
            message.success(res?.data?.msg);
            if (res.data?.data?.fixed_amount === -1) {
              setAmount(amount - res.data?.data?.pcm_code_discount);
            } else {
              setAmount(res.data?.data?.fixed_amount);
            }
          }
        }
      })
      .catch((err) => {
        if (err) {
          message.error("Something Went Wrong!");
          setIsApplying({
            title: "Retry",
            color: "danger",
            loading: false,
          });
        }
      });
  };
  const formatOrg = (arr) => {
    let data = [];
    arr.forEach((a, i) => {
      let obj = {
        label: a?.org_name,
        key: a?.org_code,
        value: a?.org_code,
        // value: a?.org_name,
        ...a,
      };
      data.push(obj);
    });
    return data;
  };

  const unqSubmitHandler = async () => {
    const input = {
      Unique_id: unqId,
      // [props?.doc?.lclinictype === "Y" ? "hospital_id" : "org_code"]:
      org_code: selectedOrgan?.value || "hospital id ",
      type: props?.doc?.lclinictype === "Y" ? "HOSPITAL" : "organisation",
    };
    setUnqVerifying(true);
    await axios({ ...apis.getCemVerification, data: input })
      .then((res, err) => {
        setUnqVerifying(false);
        if (err) {
          message.error("Something Went Wrong");
        }

        if (res.data.status_code === 1) {
          message.success(res.data?.info);
        } else {
          message.error(res.data?.info);
        }
      })
      .catch((err) => {
        setUnqVerifying(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  useEffect(() => {
    (async function () {
      if (props?.doc?.validation_flag === "Y") {
        if (props?.doc?.lclinictype !== "Y") {
          // Not Hospital

          setLoadingOrganisation(true);
          await axios({ ...apis.getorganisation })
            .then((res, err) => {
              setLoadingOrganisation(false);
              if (err) {
                message.error("Something Went Wrong");
              } else {
                setOrganList([...formatOrg(res.data?.data)]);
              }
            })
            .catch((err) => {
              setLoadingOrganisation(false);
              if (err) {
                message.error("Something Went Wrong");
              }
            });
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Form
        initialValues={{ dependent }}
        onFinish={(e) => {
          payFunc === 1 ? payNowHandler() : payLaterHandler();
        }}
      >
        <Divider>Select Pateint/Dependent</Divider>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Item
            name="dependent"
            rules={[{ required: true, message: "Please Select Dependent" }]}
          >
            <Select
              showSearch
              size="large"
              style={{ width: 300 }}
              placeholder=" Choose Dependent"
              optionFilterProp="children"
              onChange={handleDependent}
              // onSearch={onSearch}
              notFoundContent={props?.loader ? <Spin size="small" /> : null}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              value={dependent}
              allowClear
            >
              {patList.map((d, i) => (
                <Option value={d.name} key={i + Math.random()}>
                  <div className="option-dependent px-1">
                    <span className="user-icon">
                      {d.photo ? (
                        <img
                          alt={d.name}
                          className="img-fluid"
                          src={"data:image/jpeg;base64," + d.photo}
                        ></img>
                      ) : (
                        <UserOutlined></UserOutlined>
                      )}
                    </span>
                    <span className="user-name">{d.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Divider>Select Appointment Date</Divider>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Item
            name="appointmentDate"
            rules={[{ required: true, message: "Please Select Date" }]}
          >
            <DatePicker
              onChange={(e) => {
                // console.log("E: ", e);
                setAppointmentDate(e);
                isDocAvailable(e);
              }}
              allowClear={false}
              style={{ width: 300 }}
              size="large"
              disabledDate={disabledDate}
            ></DatePicker>
          </Form.Item>
        </div>
        {isDayAvailable ? (
          <div>
            <Divider>Select Appointment Time Slot</Divider>
            <div>
              <Form.Item
                name="appointmentTime"
                rules={[
                  //   { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (appointmentTime !== "") {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Please select any time slot")
                      );
                    },
                  }),
                ]}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  {/* {props?.daytiminginfo?.map((time, i) => ( */}
                  {timeInputForm()}
                  {/* ))} */}
                </div>
              </Form.Item>
            </div>
            <Divider>Select Service</Divider>
            <div style={{ width: "100%" }}>
              <Form.Item
                name="service"
                rules={[{ required: true, message: "Please Select Service" }]}
              >
                <Radio.Group
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setService(e.target.value);
                    setAmount(
                      appointmentTime?.ldiscountnormal === "Normal"
                        ? e.target.value?.ldcsmnormalamount
                        : e.target.value?.ldcsmdiscountedamount
                    );
                    // setAmount(0); //For debugging----insert Appntmnt without payment
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                    }}
                  >
                    {props?.serviceinfo?.length ? (
                      props?.serviceinfo.map((serv, i) => (
                        <div key={serv.ldcsmid}>
                          <Radio value={serv}>{serv?.lsmservicename}</Radio>
                        </div>
                      ))
                    ) : (
                      <Empty description="No Services Available"></Empty>
                    )}
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
            {homeDeliverMessage ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <Form.Item
                  name="homeCollectionFlag"
                  // rules={[{ required: true, message: "Please Select" }]}
                >
                  <Checkbox
                    checked={homeDeliveryFlag}
                    onChange={(e) => setHomeDeliveryFlag(e.target.checked)}
                  >
                    {homeDeliverMessage + ""}
                  </Checkbox>
                </Form.Item>
              </div>
            ) : null}

            <Divider>
              Have A Promo Code?{" "}
              {/* <span className="tex-secondary">(Optional)</span> */}
            </Divider>
            <div style={{ width: "100%" }}>
              <Form.Item
                name="promo_code"
                rules={[
                  {
                    required:
                      props?.doc?.promocode_mandatory_flag === "Y"
                        ? true
                        : false,
                    message: "Please Enter promo code",
                  },
                ]}
                help={
                  promoCodeRes?.applyPromoCode === "N" ? (
                    <div className="text-danger p-1">{promoCodeRes.msg}</div>
                  ) : (
                    <div className="text-success p-1">{promoCodeRes.msg}</div>
                  )
                }
              >
                <Input
                  placeholder={
                    props?.doc?.promocode_mandatory_flag === "Y"
                      ? "Enter Promo Code Here (required) "
                      : "Enter Promo Code Here (Optional) "
                  }
                  suffix={
                    <Button
                      type="link"
                      onClick={applyPromoCodeHandler}
                      loading={isApplying.loading}
                      className={`text-${isApplying.color}`}
                    >
                      {isApplying.title}
                    </Button>
                  }
                  onPressEnter={(e) => {
                    e.preventDefault();
                    applyPromoCodeHandler();
                  }}
                  value={promoCode}
                  onChange={(e) => {
                    setPromoCode(e.target.value);
                    if (isApplying.title !== "Apply")
                      setIsApplying({
                        loading: false,
                        title: "Apply",
                        color: "primary",
                      });
                    // if (promoCodeRes) {
                    //   setPromoCodeRes("");
                    // }
                  }}
                ></Input>
              </Form.Item>
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props?.doc?.pay_now_flag === "N" ? null : (
            <Form.Item className="mx-2">
              <Button
                htmlType="submit"
                name="pay now"
                type="primary"
                size="large"
                onClick={() => setPayFunc(1)}
                // onClick={payNowHandler}
                loading={loader || createOrderId}
              >
                {amount ? <span className="mr-1">₹{amount}</span> : null} Pay
                Now
              </Button>
            </Form.Item>
          )}
          {/* {props?.selectedService?.role === 'ONL' ||
          props?.selectedService?.role === 'OPSY' ||
          props?.selectedService?.role === 'ODIE' ? null : ( */}
          {props?.doc?.pay_later_flag === "N" ? null : (
            <Form.Item className="mx-2">
              <Button
                htmlType="submit"
                name="pay later"
                onClick={() => setPayFunc(2)}
                // onClick={payLaterHandler}
                type="primary"
                size="large"
                loading={payingL}
              >
                {amount ? <span className="mr-1">₹{amount}</span> : null} Pay
                Later
              </Button>
            </Form.Item>
          )}
        </div>
      </Form>
      <Modal
        title={<div>Please Complete Verification</div>}
        closable={false}
        visible={unqFormVisible}
        onCancel={() => {
          setUnqFormVisible(false);
        }}
        footer={[]}
      >
        {loadingOrganisation && <LoadingMask></LoadingMask>}
        <div>
          {props?.doc?.validation_flag === "Y" &&
            props?.doc?.lclinictype !== "Y" && (
              <div style={{ width: "100%" }} className="mb-4">
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Select Your Organisation "}
                  options={organList}
                  labelInValue={true}
                  loading={loadingOrganisation}
                  size="large"
                  value={selectedOrgan}
                  onChange={(e) => {
                    setSelectedOrgan(e);
                  }}
                ></Select>
              </div>
            )}
          <div>
            <Input
              value={unqId}
              onChange={(e) => {
                setUnqId(e.target.value);
              }}
              placeholder={"Enter Unique Id"}
              size="large"
            ></Input>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <Button
              size="large"
              type="primary"
              shape="round"
              onClick={unqSubmitHandler}
              loading={unqVerifying}
            >
              {unqVerifying ? "Please Wait..." : "Submit"}
            </Button>
            {props?.doc?.validation_mandatory_flag === "N" && (
              <Button
                onClick={() => {
                  setUnqFormVisible(false);
                }}
                size="large"
              >
                Skip
              </Button>
            )}
          </div>
        </div>
      </Modal>
      {showPaytmPayment ? (
        <PaytmPayment
          {...checkSumRes}
          {...patNewPlanInput}
          amt={
            amount
            // appointmentTime?.ldiscountnormal === "Normal"
            //   ? service?.ldcsmnormalamount + ""
            //   : service?.ldcsmdiscountedamount + ""
          }
          docNewPlanHandler={patNewPlanHandler}
          {...props}
          toggleFlag={setShowPaytmPayment}
        ></PaytmPayment>
      ) : null}
      <Modal
        visible={showAddSymtomForm}
        onCancel={() => {
          setShowAddSymtomForm(false);
          // setTimeout(() => (window.location = "/paytm-payment-result"), 1000);
          setTimeout(
            () => props.history.push("/razorpay-payment-complete"),
            1000
          );
        }}
        title={<div>Appointment Booked Successfully</div>}
        footer={[
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              key={"later btn"}
              onClick={() => {
                setShowAddSymtomForm(false);
                setTimeout(
                  () => props.history.push("/razorpay-payment-complete"),
                  1000
                );
                // setTimeout(
                //   () => (window.location = "/paytm-payment-result"),
                //   1000
                // );
              }}
              className="mr-4"
            >
              Later
            </Button>
            <SymptomsForm
              key={"symtoms btn"}
              {...props}
              {...addSymtomProps}
              onClose={
                () =>
                  setTimeout(
                    () => props.history.push("/razorpay-payment-complete"),
                    1000
                  )
                // setTimeout(
                //   () => (window.location = "/paytm-payment-result"),
                //   1000
                // )
              }
            ></SymptomsForm>
          </div>,
        ]}
      >
        <div>Would You like to add Symptoms ?</div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
    selectedService: state.userDash.selectedService,
    selectedSpeciality: state.userDash.selectedSpeciality,
    specialityList: state.userDash.specialityList,
    outcomePackages: state.userDash.outcomePackages,
  };
};

export default connect(mapStateToProps)(BookingForm);
