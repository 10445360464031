import React, { useState } from "react";
import { Button, Modal, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;
const RequestPaymentListFooter = ({
  totalAmount,
  reqPaymentHandler,
  shouldReqPayment,
  cooloffDays,
  tdsPercent,
  commission,
  billingAmount,
}) => {
  const [showViewDetails, setShowViewDetails] = useState(false);

  const handleReqPayment = () => {
    Modal.confirm({
      title: "Make Payment Request",
      onOk: reqPaymentHandler,
      // onCancel: () => console.log("Canceled"),
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      className="px-3"
    >
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <div className="mr-2 font-weight-bold">
          Total Amount : {totalAmount}
        </div>
        <div className="mx-2">
          <Button
            icon={<InfoCircleOutlined style={{ display: "inline-flex" }} />}
            type="text"
            disabled={!shouldReqPayment}
            onClick={() => setShowViewDetails(true)}
          >
            View Details
          </Button>
        </div>
      </div>
      <div>
        <Button
          onClick={handleReqPayment}
          type="primary"
          shape="round"
          disabled={!shouldReqPayment}
        >
          Request Payment
        </Button>
      </div>

      <Modal
        visible={showViewDetails}
        onCancel={() => setShowViewDetails(false)}
        title="Details"
        footer={[
          <Button
            type="primary"
            key="Close"
            onClick={() => setShowViewDetails(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          <Paragraph>
            This report contains appointments which have been processed atleast{" "}
            {cooloffDays + ""} days from the processed date.
          </Paragraph>
          <table className="table">
            <tbody>
              <tr key="1">
                <th>Total Amount : </th>
                <th>{totalAmount}</th>
              </tr>
              <tr key="2">
                <th>TDS ({tdsPercent}%) : </th>
                <th>{0.0}</th>
              </tr>
              <tr key="3">
                <th>Commission : </th>
                <th>{commission}</th>
              </tr>
              <tr key="4">
                <th>Billing Amount : </th>
                <th>{billingAmount}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default RequestPaymentListFooter;
