import React, { useEffect, useState } from "react";
import { Button, message, Steps } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import AllTimingsList from "./intermediate/AllTimingsList";
import ServicesAndRatesList from "./intermediate/ServicesAndRatesList";
import ManageDiscountsList from "./intermediate/ManageDiscountsList";
import { apis } from "../shared/apis";
import axios from "axios";
import LoadingMask from "./LoaderMask/LoadingMask";
import UploadDocumentsForm from "./intermediate/UploadDocumentsForm";

const { Step } = Steps;
const Partnerdashmanagelocation = (props) => {
  const [current, setCurrent] = useState(0);
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [checkpoint, setCheckpoint] = useState(0);
  const [isDocSubmitted, setIsDocSubmitted] = useState(
    JSON.parse(localStorage.getItem("checkpoint")) === 3
  );
  const goto = (idx) => {
    setCurrent(idx);
  };
  const manageOptions = [
    {
      title: "Timings",
      icon: <ClockCircleOutlined style={{ display: "inline-flex" }} />,
      path: "/manage-timings",
      component: <AllTimingsList {...props} goto={goto} />,
    },
    {
      title: "Services & Rates",
      icon: <span style={{ fontWeight: "bold", color: "#007bff" }}>₹</span>,
      path: "/manage-services-and-rates",
      component: (
        <ServicesAndRatesList {...props} goto={goto}></ServicesAndRatesList>
      ),
    },
    {
      title: "Discounts",
      icon: (
        <span
          style={{
            fontWeight: "bold",
            color: "white",
            backgroundColor: "orange",
            padding: "3px 10.65px",
            textAlign: "center",
            borderRadius: "50%",
          }}
        >
          %
        </span>
      ),
      path: "/manage-discounts",
      component: (
        <ManageDiscountsList {...props} goto={goto}></ManageDiscountsList>
      ),
    },
    {
      title: "Upload Documents",
      icon: <ClockCircleOutlined style={{ display: "inline-flex" }} />,
      path: "/upload-documents",
      component: (
        <UploadDocumentsForm
          {...props}
          setIsDocSubmitted={setIsDocSubmitted}
          goto={goto}
        ></UploadDocumentsForm>
      ),
    },
  ];

  useEffect(() => {
    const fetchCheckpoint = async () => {
      setLoader(true);
      await axios({
        ...apis.checkpoint,
        data: {
          dlmid: JSON.parse(localStorage.getItem("selectedClinic"))?.dlmid,
        },
      })
        .then((res, err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong!");
          // setCheckpoint(res.data.progress);
          setCurrent(res.data.progress);
          if (
            JSON.parse(localStorage.getItem("selectedClinic")).checkpoint !== 3
          )
            setCurrent(3);
          // switch (res.data.progress) {
          //   case 0:
          //     message.error("Timmings Not Set");
          //     // break;
          //     return;
          //   case 1:
          //     message.error("Service Not Set");
          //     // break;
          //     return;
          //   // case 2:
          //   //   console.log("Case 2");
          //   //   props.history.push("/upload-documents");
          //   //   break;
          //   default:
          //     // console.log("Case d");
          //     // props.history.push("/choose-option");
          //     break;
          // }
          // if (
          //   JSON.parse(localStorage.getItem("selectedClinic")).checkpoint !== 3
          // ) {
          //   props.history.push("/upload-documents");
          // } else {
          //   props.history.push("/choose-option");
          // }
        })
        .catch((err) => {
          setLoader(false);
          if (err) message.error("Something Went Wrong!");
        });
    };

    fetchCheckpoint();
  }, []);

  return loader ? (
    <LoadingMask></LoadingMask>
  ) : (
    <div>
      <Steps
        type="navigation"
        current={current}
        onChange={(c) => setCurrent(c)}
      >
        {manageOptions?.map((opt, i) => (
          <Step
            title={<div>{opt?.title}</div>}
            key={opt?.title}
            disabled={i === manageOptions.length - 1 && isDocSubmitted}
            status={
              i === manageOptions.length - 1 && isDocSubmitted ? "finish" : ""
            }
            className={
              i === manageOptions.length - 1 && isDocSubmitted
                ? "step-done"
                : ""
            }
          ></Step>
        ))}
      </Steps>
      <div>{manageOptions[current]?.component}</div>
      {current < 2 ? (
        <div className="text-center">
          <Button type="primary" onClick={() => setCurrent(current + 1)}>
            Next
          </Button>
        </div>
      ) : current === 2 && !isDocSubmitted ? (
        <div className="text-center">
          <Button type="primary" onClick={() => setCurrent(current + 1)}>
            Next
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Partnerdashmanagelocation;
