import React from "react";
import Videomirror from "./VideoMirror";
import { ReactMediaRecorder } from "react-media-recorder";
import Webcam from "react-webcam";
const Videomirrorcontainer = () => {
  const videoConstraints = {
    // width: 1280,
    // height: 720,
    facingMode: "user",
  };

  return (
    <div>
      <Webcam
        audio={false}
        // height={350}
        // ref={this.setRef}
        screenshotFormat="image/jpeg"
        // width={350}
        videoConstraints={videoConstraints}
      />
    </div>
  );
  // return (
  //   <div>
  //     <ReactMediaRecorder
  //       // askPermission={true}
  //       video
  //       render={(vprops) => <Videomirror {...vprops} />}
  //     />
  //   </div>
  // );
};

export default Videomirrorcontainer;
