import React, { useEffect, useState } from "react";
import landing from "../../assets/images/landing.png";
import lpf from "../../assets/images/lp_footer.jpg";
import landing1 from "../../assets/images/lp_1.jpg";
import landing2 from "../../assets/images/lp_2.jpg";
import landing3 from "../../assets/images/bn_btn.jpg";
import landing4 from "../../assets/images/gp_btn.jpg";
import { Modal, message, Empty, Pagination } from "antd";
import { homeStyles as styles } from "../../assets/styles/homeStyles";
import "../../assets/styles/home.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AppInviteCard from "./AppInviteCard";
import { connect } from "react-redux";
import axios from "axios";
import { apis } from "../../shared/apis";
import DoctorCard from "../userDashBoard/DoctorCard";
import { isBrowser } from "react-device-detect";
let queryoffset = 0;
let querylimit = 50;
const LandlngPage = (props) => {
  // console.log("Props LP: ", props);
  const pageSize = 5;
  const [page, setPage] = useState(1);
  let input = {
    ...props.match?.params,
  };
  //  const [sortid, setSortId] = useState("1");
  //  const [searchFilterId, setSearchFilterId] = useState("1");
  // eslint-disable-next-line no-unused-vars

  const [loaders, setLoaders] = useState({
    locL: false,
    specL: false,
    searchL: false,
  });
  // const [cityname, setCityName] = useState("New Delhi");
  const [cities, setCities] = useState([]);
  // const [cities, setCities] = useState(props.cities.cities.info);
  const [localityname, setLocalityname] = useState("Sarita Vihar");
  const [userLocality, setUserLocality] = useState();
  const [localities, setLocalities] = useState([]);
  const [role, setRole] = useState("Doctors");
  const [specialityname, setSpecialityname] = useState("ENT");
  const [specialities, setSpecialities] = useState([]);
  const [docs, setDocs] = useState([]);
  const [docList, setDocList] = useState({ 1: [] });
  const [state, setState] = useState({ showDocCard: false });
  const formatList = (arr) => {
    let len = arr.length;
    let obj = {};
    let j = 1;
    for (let i = 0; i < len; i++) {
      if (i !== 0 && i % pageSize === 0) j++;
      if (typeof obj[j] !== "undefined") obj[j] = [...obj[j], arr[i]];
      else {
        obj[j] = [];
        obj[j] = [...obj[j], arr[i]];
      }
    }
    return obj;
  };

  const formatLocalities = (arr) => {
    let a = [];
    arr.forEach((element) => {
      let obj = {
        ...element,
        label: `${element.llocalityname} (${element.llocalitystate})`,
        value: element.llocalityname,
        key: element.llocalityid,
      };
      a.push(obj);
    });
    return a;
  };

  const fetchLocalities = async (data) => {
    setLoaders({ ...loaders, locL: true });
    await axios({ ...apis.getLocality, data: data })
      .then((res, err) => {
        setLoaders({ ...loaders, locL: false });
        if (err) message.error("Err in getLocality");
        setLocalities([...formatLocalities(res.data.info)]);
        let loc = res.data.info.filter(
          (l) => l.llocalityname === input?.llocalityname
        );
        setUserLocality({ ...loc[0] });
      })
      .catch((err) => {
        if (err) message.error("Err in getLocality");
        setLoaders({ ...loaders, locL: false });
      });
  };

  const fetchSpecialities = async (data) => {
    setLoaders({ ...loaders, specL: true });

    await axios({ ...apis.getspeciality, data: data })
      .then((res, err) => {
        setLoaders({ ...loaders, specL: false });

        if (err) message.error("Err in getSpeciality");

        setSpecialities(res.data.sparr);
      })
      .catch((err) => {
        setLoaders({ ...loaders, specL: false });

        if (err) message.error("Err in getSpecialities");
      });
  };

  const handleFinish = async (data) => {
    // Changed here by vinay on 1/7/2021 to make Online psychologist as default
    data.service = data.service == undefined ? "OPSY" : data.service;
    data.speciality = data.speciality == undefined ? "80010" : data.speciality;
    let api =
      data?.service === "ONL" ||
      data?.service === "ODIE" ||
      data?.service === "OPSY"
        ? apis.getonlinedoctorsbysearchlimit
        : apis.getdoctorsbysearchlimit;
    await axios({
      ...api,
      data: {
        cityname: data?.city,
        dmrole: data?.service,
        localityname: userLocality?.llocalityname,
        localitylat: userLocality?.llocality_lat,
        localitylong: userLocality?.llocality_long,
        specialityid: data?.speciality,
        queryoffset: queryoffset,
        querylimit: querylimit,
        sortid: 0,
        searchfilter: 0,
        dayshortname: null,
        service_id:
          data?.service === "ONL" || data?.service === "DOC"
            ? ""
            : data?.speciality,
      },
    })
      .then((res, err) => {
        setLoaders({ ...loaders, searchL: false });
        if (err) message.error("Something Went Wrong");

        setDocs(res.data.info);
        setDocList(formatList(res.data.info));
        setState({ ...state, showDocCard: true });
      })
      .catch((err) => {
        setLoaders({ ...loaders, searchL: false });
        if (err) message.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    setCities(props.cities.cities.info);
    setRole(input?.dmrole);
    fetchLocalities({ cityname: input?.cityname });

    fetchSpecialities({ Role: input?.dmrole });
  }, []);

  const handleBookNow = () => {
    let data = {
      service: role,
      city: input?.cityname,
      speciality: input?.speciality,
    };
    handleFinish(data);
  };
  return (
    <div
      style={{
        // backgroundImage: `url(${landing})`,
        // backgroundSize: "cover",
        height: "100%",
        width: "100%",
        // minHeight: "80vh",
      }}
    >
      <div
        className="row mx-0 p-0"
        style={{ display: "flex", flexDirection: isBrowser ? "row" : "column" }}
      >
        <div
          className="p-0 m-0"
          style={{
            width: isBrowser ? "45.9%" : "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "space-around",
            backgroundColor: "#EBF9FC",
          }}
        >
          <img
            src={landing1}
            style={{ width: "100%" }}
            alt="lp1"
            // useMap="#imgmap"
          ></img>
          <div
            style={{
              width: isBrowser ? "50%" : "80%",
              marginTop: "2rem",
            }}
          >
            <div
              className="mx-0 p-0"
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                className="mr-4 p-0"
                src={landing3}
                style={{ width: "100%", cursor: "pointer" }}
                alt="lp1"
                onClick={handleBookNow}

                // useMap="#imgmap"
              ></img>
              <a
                href="https://play.google.com/store/apps/details?id=clinic.janelaaj.janelaajuserapp&hl=en"
                target="__blank"
                style={{ width: "100%" }}
              >
                <img
                  className=" ml-4 p-0"
                  src={landing4}
                  style={{ width: "100%", cursor: "pointer" }}
                  alt="lp"
                  // useMap="#imgmap"
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="col p-0 m-0">
          <img
            src={landing2}
            style={{ width: "100%" }}
            alt="lp"
            // useMap="#imgmap"
          ></img>
        </div>
      </div>
      <img src={lpf} alt="footer" style={{ width: "100%" }}></img>

      <Modal
        visible={state.showDocCard}
        centered={true}
        className="container"
        onCancel={() =>
          setState({
            ...state,
            showDocCard: false,
          })
        }
        onOk={() =>
          setState({
            ...state,
            showDocCard: false,
          })
        }
        width="100%"
        bodyStyle={{ padding: "0.5rem" }}
        title={`Showing ${
          (page - 1) * pageSize + docList[page]?.length <= docs.length
            ? (page - 1) * pageSize + docList[page]?.length
            : docs.length
        } out of ${docs.length} results`}
      >
        {docs?.length ? (
          <div>
            <div>
              {/* {docs?.map((doc, i) => ( */}
              {docList[page] !== "undefined"
                ? docList[page]?.map((doc, i) => (
                    <div key={i + Math.random()}>
                      <DoctorCard {...props} doc={doc}></DoctorCard>
                    </div>
                  ))
                : null}
              <div className="paginition-container">
                <Pagination
                  pageSize={pageSize}
                  total={docs.length}
                  // total={Object.keys(docList).length}
                  hideOnSinglePage={true}
                  onChange={(page) => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    setPage(page);
                  }}
                  showSizeChanger={false}
                  // simple={true}
                  responsive={true}
                ></Pagination>
              </div>
            </div>
          </div>
        ) : (
          <Empty
            description={"No doctors available for your search query"}
          ></Empty>
        )}

        <div className="p-4">
          <AppInviteCard></AppInviteCard>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorsList: state.docs.docs.info,
    cities: state.cities,
    localities: state.localities,
    specialities: state.specialities,
  };
};

export default connect(mapStateToProps)(LandlngPage);
