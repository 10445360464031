import React, { useEffect } from "react";
import { Button, Image } from "antd";
import VideoPreview from "./VideoPreview";
import Videotimer from "./VideoTimer";
import Videomirrorcontainer from "./VideoMirrorContainer";

const Videorecorder = ({
  status,
  startRecording,
  pauseRecording,
  resumeRecording,
  stopRecording,
  clearBlobUrl,
  mediaBlobUrl,
  previewStream,
}) => {
  const stopRecordingHandler = () => {
    console.log("Recording Stopped.Camera must be off now");
    stopRecording();
  };

  // useEffect(() => {
  //   startRecording();
  // }, []);
  return (
    <div>
      {/* <p className="text-center text-primary font-weight-bold text-capitalize">
        Please Sit in Bright Room
      </p> */}
      {status === "idle" ? (
        <div
          className="text-center"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <div className="mt-4">
            <div>
              <Videomirrorcontainer />
            </div>
            <p>
              Click <b>Lets Go</b> when you are ready
            </p>
          </div>
          <div>
            <Button
              size="large"
              shape="round"
              type="primary"
              onClick={startRecording}
            >
              Lets Go
            </Button>
          </div>
        </div>
      ) : null}
      {mediaBlobUrl ? null : (
        <div>
          <VideoPreview stream={previewStream}></VideoPreview>
        </div>
      )}
      {status === "recording" ? (
        <p className="text-center text-primary font-weight-bold text-capitalize">
          <Videotimer stopRecording={stopRecordingHandler}></Videotimer>
        </p>
      ) : null}
    </div>
  );
};

export default Videorecorder;
