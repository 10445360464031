import React from "react";
import { Tabs } from "antd";
import EmpProfile from "./EmpProfile";
// import SubDealerList from "./SubDealerList";
// import CreateSubDealer from "./CreateSubDealer";
import PartneredClinics from "./PartneredClinics";
import PatientAppointments from "./PatientAppointments";
const { TabPane } = Tabs;
const SubDealerPage = (props) => {
  return (
    <div>
      <Tabs size="large" centered>
        <TabPane tab={<div>Profile</div>} key="empProfile">
          <EmpProfile {...props}></EmpProfile>
        </TabPane>
        {/* <TabPane tab={<div>All Sub Dealers</div>} key="empSubDealersList">
          <SubDealerList {...props} subject={"sub-dealer"}></SubDealerList>
        </TabPane>
        <TabPane tab={<div>Create Sub Dealer</div>} key="empCreateSubDealer">
          <CreateSubDealer {...props}></CreateSubDealer>
        </TabPane> */}
        <TabPane tab={<div>Partnered Clinics</div>} key="empPartneredClinics">
          <PartneredClinics {...props}></PartneredClinics>
        </TabPane>
        <TabPane
          tab={<div>Patient Appointments</div>}
          key="empPatientAppointments"
        >
          <PatientAppointments {...props}></PatientAppointments>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SubDealerPage;
