import * as ActionTypes from "../../ActionTypes";
const initialState = {
  isPartnerLoggedIn: localStorage.getItem("partnerEmail") ? true : false,
  AppointmentListLoading: null,
  AppointmentList: {},
  partnerRoles: {
    status: "",
    role: [],
  },
  partnerClinics: {
    status: "",
    locations: [],
  },
  selectedRole: {},
  selectedClinic: {},
  selectedAppointment: {},
};
export const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ISPARTNER_LOGGED_IN:
      return {
        ...state,
        isPartnerLoggedIn: action.payload,
      };
    case ActionTypes.APPOINTMENT_LOADING:
      return {
        ...state,
        AppointmentListLoading: action.payload,
      };
    case ActionTypes.APPOINTMENT_SUCCESS:
      return {
        ...state,
        AppointmentListLoading: action.payload,
      };
    case ActionTypes.APPOINTMENT_FAILED:
      return {
        ...state,
        AppointmentListLoading: action.payload,
      };
    case ActionTypes.SET_APPOINTMENT_LIST:
      return {
        ...state,
        AppointmentList: action.payload,
      };
    case ActionTypes.SET_ROLES_LIST:
      return {
        ...state,
        partnerRoles: action.payload,
      };
    case ActionTypes.SET_CLINIC_LIST:
      return {
        ...state,
        partnerClinics: action.payload,
      };
    case ActionTypes.SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };
    case ActionTypes.SET_SELECTED_CLINIC:
      return {
        ...state,
        selectedClinic: action.payload,
      };
    case ActionTypes.SET_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.payload,
      };
    default:
      return state;
  }
};
export default partnerReducer;
