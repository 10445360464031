import React, { useEffect, useRef } from "react";

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(HTMLVideoElement || null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} width={"100%"} autoPlay />;
};

export default VideoPreview;
