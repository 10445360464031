import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Divider,
} from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";

const CreateSubDealer = (props) => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 4 },
      lg: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
    },
  };
  const ifscRegEx = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$");
  const accNumRegEx = new RegExp("[0-9]{9,18}");
  const accNameRegEx = new RegExp("^[A-Za-z\\s]+$");

  const isValidCode = (val) => {
    if (ifscRegEx.test(val)) {
      return true;
    } else return false;
  };

  const isValidAccNum = (num) => {
    if (accNumRegEx.test(num)) {
      return true;
    } else return false;
  };

  const isValidName = (name) => {
    if (accNameRegEx.test(name)) {
      return true;
    } else return false;
  };

  const handleFinish = async (e) => {
    // e.preventDefault();
    // console.log("E: ", e);
    setLoader(true);
    const input = {
      ...e,
      DOB: e?.dob?.format("DD-MM-YYYY"),
      start_date: e?.reg_start_date?.format("DD-MM-YYYY"),
      // end_date: e.reg_end_date.format("DD-MM-YYYY"),
      id: props?.empid,
      subject: props?.subject,
    };
    await axios({ ...apis.postCreateDistributerDealerSubDealer, data: input })
      .then((res, err) => {
        setLoader(false);
        if (err) {
          message.error("Something Went Wrong");
        } else {
          if (res?.data?.status_code === 1) {
            Modal.success({
              title: "Dealer Created Successfully ",
              content: res.data.status,
              onOk() {
                window.location.reload();
              },
            });
            form.resetFields();
          } else {
            message.error("Something went Wrong");
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };
  return (
    <div>
      <Form onFinish={handleFinish} form={form} {...layout}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input size="large" placeholder="Enter Name"></Input>
        </Form.Item>
        <Form.Item
          name="company_name"
          rules={[{ required: true, message: "Please enter company name" }]}
        >
          <Input size="large" placeholder="Enter Company Name"></Input>
        </Form.Item>

        <Form.Item
          name="contact"
          rules={[{ required: true, message: "Please enter contact number" }]}
        >
          <Input size="large" placeholder="Enter Contact Number"></Input>
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please enter email" }]}
        >
          <Input size="large" placeholder="Enter Email" type="email"></Input>
        </Form.Item>
        <div className="row mx-0">
          <div className="col-sm-6 pl-0">
            <Form.Item
              name="reg_start_date"
              rules={[{ required: true, message: "Please enter start date" }]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter Start Date"
              ></DatePicker>
            </Form.Item>
          </div>

          <div className="col-sm-6 pr-0">
            {/* <Form.Item
              name="reg_end_date"
              rules={[{ required: true, message: "Please enter end date" }]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter End Date"
              ></DatePicker>
            </Form.Item> */}
            <Form.Item
              name="dob"
              rules={[{ required: true, message: "Please enter dob" }]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter DOB"
              ></DatePicker>
            </Form.Item>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-sm-6 pl-0">
            <Form.Item
              name="duration"
              rules={[{ required: true, message: "Please enter duration" }]}
            >
              <Input
                size="large"
                type="number"
                placeholder="Enter Duration"
              ></Input>
            </Form.Item>
          </div>
          <div className="col-sm-6 pr-0">
            <Form.Item
              name="gender"
              rules={[{ required: false, message: "Please enter your gender" }]}
            >
              <Select placeholder="Enter Your Gender" size="large">
                <Select.Option value="M">Male</Select.Option>
                <Select.Option value="F">Female</Select.Option>
              </Select>
            </Form.Item>
          </div>

          {/* <div className="col-sm-6 pr-0">
            <Form.Item
              name="dob"
              rules={[{ required: true, message: "Please enter dob" }]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter DOB"
              ></DatePicker>
            </Form.Item>
          </div> */}
        </div>
        <Divider>Banking Form</Divider>
        <Form.Item
          name="acc_number"
          label="Account Number"
          rules={[
            {
              required: true,
              message: "Please input your Account Number!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || isValidAccNum(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Please Enter Valid Account Number");
              },
            }),
          ]}
          hasFeedback
        >
          <Input placeholder="Enter Account Number" allowClear size="large" />
        </Form.Item>

        <Form.Item
          name="confirm_acc_number"
          label="Confirm Account Number"
          dependencies={["password"]}
          hasFeedback
          // validateStatus={"warning"}
          // help="Should be combination of numbers & alphabets"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("acc_number") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two account numbers that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input
            placeholder="Confirm Account Number "
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="ifsc_code"
          label="IFSC CODE"
          rules={[
            {
              required: true,
              message: "Please confirm your bank's IFSC Code!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || isValidCode(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Please Enter Valid IFSC Code");
              },
            }),
          ]}
        >
          <Input
            placeholder="IFSC Code of Your Bank "
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="acc_holder_name"
          label="Account Holder Name"
          rules={[
            {
              required: true,
              message: "Please Enter Account Holder's Name!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || isValidName(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Please Enter Valid Name !");
              },
            }),
          ]}
        >
          <Input placeholder="Account Holder's Name" allowClear size="large" />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Form.Item>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              shape="round"
              loading={loader}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default CreateSubDealer;
