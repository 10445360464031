import React, { useEffect, useState } from "react";
import { Card, Empty, message } from "antd";
import axios from "axios";

import { apis } from "../../shared/apis";
import PartnerClinicCard from "./PartnerClinicCard";
const PartneredClinics = (props) => {
  const [loader, setLoader] = useState(false);

  const [clinicList, setClinicList] = useState([
    // { doctor_name: "Rishab", clinic_name: "test", dlm_id: "12" },
  ]);
  useEffect(() => {
    const input = {
      id: props?.empid,
      subject: props?.role.toLowerCase().replaceAll("_", "-"),
    };
    (async function () {
      setLoader(true);
      await axios({ ...apis.getPartneredClinic, data: input })
        .then((res, err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          } else {
            setClinicList(res.data.data);
            // console.log('Res.data: ', res.data)
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.shouldUpdate]);
  return (
    <div>
      <Card loading={loader} bodyStyle={{ padding: "0" }} bordered={false}>
        <div className="">
          {clinicList.length ? (
            clinicList.map((cli, i) => (
              <div key={cli?.dlm_id} className="mb-4">
                <PartnerClinicCard {...cli}></PartnerClinicCard>
              </div>
            ))
          ) : (
            <Empty style={{ width: "100%" }}></Empty>
          )}
        </div>
      </Card>
    </div>
  );
};

export default PartneredClinics;
