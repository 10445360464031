import React, { useEffect, useState } from "react";
import { Card, Row, Col, Image, Button, Rate } from "antd";
import {
  StarFilled,
  EnvironmentFilled,
  FileOutlined,
  DownOutlined,
  UpOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  UserAddOutlined,
  // PhoneOutlined,
  MedicineBoxOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
// import {
//   CalendarOutlined,
//   ClockCircleOutlined,
//   MedicineBoxOutlined,
//   UsergroupAddOutlined,
// } from "@ant-design/icons";
import docLogo from "../../assets/images/doc_card.png";

import DoctorDetailsCard from "./DoctorDetailsCard";
import { connect } from "react-redux";
import ClinicDetailsCard from "./ClinicDetailsCard";
import BookAgainButton from "./BookAgainButton";
import { isBrowser } from "react-device-detect";
function DoctorCard(props) {
  console.log("props of DC: ", props);
  const [showDocDetials, setShowDocDetials] = useState(false);
  const [showClinicDetials, setShowClinicDetials] = useState(false);
  const [degress, setDegrees] = useState(false);
  const isChemist = props?.doc?.ldoctorid?.includes("CHE");
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  useEffect(() => {
    let d = [];
    const { lmbbsflag, lmdflag, lmsflag } = props?.doc;
    if (lmbbsflag === "Y") d.push("MBBS");
    if (lmdflag === "Y") d.push("MD");
    if (lmsflag === "Y") d.push("MS");
    if (d.length) setDegrees(d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-2 p-1 ">
      <Card
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "1.2rem",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <div className="text-dark">
              <HomeOutlined
                style={{ display: "inline-flex", marginRight: "0.5rem" }}
              ></HomeOutlined>
              {props.doc?.lcliniclocationname}
            </div>
            <div className="" style={{ minWidth: "70px" }}>
              {props?.doc?.ldiscountFlag === "N" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="text-info mr-1">
                    {isChemist ? "%" : "₹"}
                    {props?.doc?.lnormalamount}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      fontSize: "1.05rem",
                      width: "100%",
                    }}
                  >
                    <div className="text-info mr-1">
                      {isChemist ? "%" : "₹"}
                      {props?.doc?.ldiscountedamount}
                    </div>
                    <div
                      className="text-secondary ml-1"
                      style={{
                        textDecoration: "line-through",
                        fontSize: "0.85rem",
                      }}
                    >
                      {isChemist ? "%" : "₹"}
                      {props?.doc?.lnormalamount}
                    </div>
                  </div>
                  {isChemist ? null : (
                    <div
                      className="text-success"
                      style={{ fontSize: "0.95rem" }}
                    >
                      {parseInt(
                        ((props?.doc?.lnormalamount -
                          props?.doc?.ldiscountedamount) /
                          props?.doc?.lnormalamount) *
                          100
                      )}
                      % off
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        }
        hoverable={true}
        type="inner"
        headStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        bodyStyle={{ padding: "0.025rem 0.5rem" }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "1rem",
          }}
          // gutter={16}
          gutter={{ xs: 0, sm: 16, md: 16, lg: 0 }}
        >
          <Col
            span={isBrowser ? 4 : 24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "150px",
              // backgroundColor: "red",
              margin: "auto",
              padding: "0.25rem",
            }}
          >
            <Image
              style={{ width: "100%" }}
              src={
                props.doc?.ldoctorphoto
                  ? "data:image/jpeg;base64," + props.doc?.ldoctorphoto
                  : docLogo
              }
            />
            <div
              style={{ position: "absolute", bottom: "-5px", right: "-5px" }}
            >
              {props?.doc?.dlm_active_today === "Y" ? (
                <>
                  <i className="fas fa-circle text-success "></i>
                </>
              ) : (
                <>
                  <i className="fas fa-circle text-danger"></i>
                </>
              )}
            </div>
          </Col>
          <Col
            span={20}
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
            className="p-2"
          >
            <div className="">
              <div
                className="py-1"
                style={{
                  display: "flex",
                  alignItems: isBrowser ? "center" : "flex-start",
                  flexDirection: isBrowser ? "row" : "column",
                  width: "100%",
                  // backgroundColor: "red",
                }}
              >
                <div
                  style={{ flex: "3", fontSize: "1.3rem", fontWeight: "500" }}
                  className="doctor-name"
                >
                  <UserAddOutlined
                    style={{ display: "inline-flex", marginRight: "0.5rem" }}
                  ></UserAddOutlined>
                  {props.doc?.ldoctorname}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    // flexDirection: "column",
                    minWidth: "40%",
                    // width: isBrowser ? "25%" : "100%",
                  }}
                >
                  {/* <div className="text-secondary mr-2">
                    <PhoneOutlined
                      style={{ display: "inline-flex", marginRight: "0.5rem" }}
                    ></PhoneOutlined>
                    {props.doc?.ldoctorphone}
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                      // flexDirection: "column",
                      // width: "50%",
                      // width: isBrowser ? "25%" : "100%",
                    }}
                  >
                    {degress
                      ? degress?.map((deg, i) => (
                          <div style={{ flex: "0.5" }} className="ml-2">
                            <Button
                              // type="primary"
                              // danger
                              shape="round"
                              icon={<FileOutlined />}
                              size={"small"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // color: "white",
                                fontSize: "0.8rem",
                                margin: "auto",
                                // cursor: "pointer",
                              }}
                              className="p-2"
                            >
                              {deg}
                            </Button>
                          </div>
                        ))
                      : null}
                  </div>

                  <div className="ml-2">
                    <Rate
                      value={props.doc?.lrating - 0}
                      disabled
                      tooltips={desc}
                      allowHalf={true}
                    />
                    <span
                      className={`ant-rate-text ${
                        props.doc?.lrating - 0 < 3
                          ? " text-danger"
                          : " text-success"
                      }`}
                    >
                      {props.doc?.lrating + ".0"}
                    </span>
                  </div>
                  {/* <div style={{ flex: "0.5" }} className="ml-2">
                    <Button
                      disabled={true}
                      shape="round"
                      icon={<StarFilled />}
                      size={"small"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontSize: "0.8rem",
                        margin: "auto",
                        backgroundColor:
                          props.doc?.lrating < 3 ? "#fadb14" : "#52c41a",
                        cursor: "pointer",
                      }}
                      className="p-2"
                    >
                      {props.doc?.lrating + ".0"}
                    </Button>
                  </div> */}
                </div>
              </div>
              <div className="text-secondary">
                <MedicineBoxOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                />
                {props.doc?.lgender === "M" ? "Male" : "Female"},{" "}
                {props?.doc?.lspecialityname}
              </div>

              <div className="text-secondary">
                <ExperimentOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                ></ExperimentOutlined>
                {props.doc?.lexperience} yrs Experience
              </div>
              <div
                className="my-1 text-secondary"
                style={{ display: "flex", alignItems: "center" }}
              >
                <EnvironmentOutlined
                  style={{ display: "inline-flex", marginRight: "0.5rem" }}
                ></EnvironmentOutlined>
                {`${props.doc?.lcliniclocationname}, ${
                  props.doc?.laddressline1 + props.doc?.laddressline2
                }, ${props.doc?.lcity}, ${props.doc?.lpincode}`}
              </div>
              {props?.doc?.ldistance ? (
                <div
                  className="p-1 my-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.8rem",
                  }}
                >
                  <EnvironmentFilled className="mr-1 text-primary" />
                  {props?.doc?.ldistance + ""} KM away
                </div>
              ) : null}
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              Consult the same doctor offline?
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: isBrowser ? "row" : "column",
                // width: "fit-content",
                padding: "0.5rem",
              }}
            >
              <Button
                shape="round"
                type="text"
                icon={!showDocDetials ? <DownOutlined /> : <UpOutlined />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontSize: "1rem",
                  margin: "0.5rem",
                  backgroundColor: "#52c41a",
                  padding: "1rem",
                  paddingBottom: "1.2rem",
                  transition: "all linear 0.5s",
                }}
                onClick={() => {
                  setShowClinicDetials(false);
                  setShowDocDetials(!showDocDetials);
                }}
                className="mx-2 bg-success"
              >
                View Details
              </Button>

              <Button
                shape="round"
                type="text"
                icon={!showClinicDetials ? <DownOutlined /> : <UpOutlined />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontSize: "1rem",
                  margin: "0.5rem",
                  backgroundColor: "#faad14",
                  padding: "1rem",
                  paddingBottom: "1.2rem",
                  transition: "all linear 0.5s",
                }}
                onClick={() => {
                  setShowDocDetials(false);
                  setShowClinicDetials(!showClinicDetials);
                }}
                className="mx-2"
              >
                View Clinics
              </Button>

              <div style={{ margin: "0.5rem" }}>
                <BookAgainButton {...props}></BookAgainButton>
              </div>
            </div>
          </Col>
        </Row>
        {showDocDetials && (
          <DoctorDetailsCard doc={props.doc}></DoctorDetailsCard>
        )}
        {showClinicDetials && (
          <ClinicDetailsCard {...props}></ClinicDetailsCard>
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userDash: state.userDash,
  };
};

export default connect(mapStateToProps)(DoctorCard);
