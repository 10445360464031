import React, { useEffect, useState } from "react";
import { Card, Checkbox } from "antd";
const DiscountCard = (props) => {
  const [isChecked, setIsChecked] = useState(
    props.discountflag === "N" ? false : true
  );

  useEffect(() => {
    setIsChecked(props.discountflag === "N" ? false : true);
  }, [props.discountflag]);

  return (
    <div>
      <Card
        hoverable
        type="inner"
        headStyle={{ backgroundColor: "#004fcf", color: "white" }}
        bodyStyle={{ padding: "0" }}
        title={
          <div
            className="text-center p-2"
            style={{
              display: "flex",
              fontSize: "1.15rem",
            }}
          >
            <div style={{ flexGrow: "10" }}>
              {props.from[0]}
              {props.from[1]}:{props.from[2]}
              {props.from[3]} - {props.to[0]}
              {props.to[1]}:{props.to[2]}
              {props.to[3]}
            </div>
            <div>
              <Checkbox
                checked={isChecked}
                onChange={(e) =>
                  props.updateIsChecked(props.index, e.target.checked)
                }
              ></Checkbox>
            </div>
          </div>
        }
      ></Card>
    </div>
  );
};

export default DiscountCard;
