import React from "react";
import { Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./policy.css";
const Ourpolicies = () => {
  const policies = [
    { title: "About Us", cKey: "about-us" },
    { title: "Contact Us", cKey: "contact-us" },
    { title: "Our Services", cKey: "our-services" },
    { title: "Payment Process", cKey: "payment-process" },
    { title: "Privacy Policy", cKey: "privacy-policy" },
    { title: "Doctor's Guidelines", cKey: "doctor-guidelines" },
    { title: "Our Term's & conditions", cKey: "terms-and-contions" },
    {
      title: "Return & Refund Policy",
      cKey: "return-and-refund",
    },
  ];
  return (
    <div className="bg-light p-4 container">
      {policies.map((policy, i) => (
        <Link
          key={i}
          to={`/policy/user/${policy.cKey}`}
          className="policy-link"
        >
          <Card hoverable className="mb-4" style={{ borderRadius: "50px" }}>
            <div className="policy-card-item">
              <div className="policy-link">{policy.title}</div>
              <div className="policy-link-icon">
                <RightOutlined />
              </div>
            </div>
          </Card>
        </Link>
      ))}
    </div>
  );
};

export default Ourpolicies;
