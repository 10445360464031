import React, { useState } from "react";
import { Button, Radio, Modal, Divider, Form, DatePicker, Input } from "antd";
import {
  SortAscendingOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import * as dashboardAC from "../../redux/ActionCreators/UserDashActionCreator";

import LangFilterForm from "../LangFilterForm";

const SortAndFilterForm = (props) => {
  // const [loader, setLoader] = useState(false)
  const [showForm, setShowForm] = useState(false);
  const [showLangForm, setShowLangForm] = useState(false);
  const [selectedLangList, setSelectedLangList] = useState([]);
  const [showDateForm, setShowDateForm] = useState(false);
  const [selecetdDate, setSelectedDate] = useState("");
  const [docSearchName, setDocSearchName] = useState("");
  const [sortid, setSortId] = useState("-1");
  const [searchFilterId, setSearchFilterId] = useState("-1");
  // eslint-disable-next-line no-unused-vars
  const showFormHandler = () => {
    setShowForm(true);
  };
  const selectSpeciality = () => {
    let data = {
      localityname: props?.data?.llocalityname,
      dmrole: props?.data?.service,
      service: props?.data?.dmrole,
      cityname: props?.data?.cityname,
      localitylat: props?.data?.llocalitylat,
      localitylong: props?.data?.llocalitylong,
      queryoffset: 0, // query off set limit
      querylimit: 50, // query upper limit
      ...props?.data,
      sortid: sortid, // default sorting id
      searchfilter: searchFilterId, // default search filter
      dayshortname: selecetdDate
        ? selecetdDate?.format("ddd")?.toLowerCase() || ""
        : props?.data?.dayshortname, // day name
      languageList: selectedLangList,
    };
    if (props?.data?.dmrole === "ONL" || props?.data?.dmrole === "DOC") {
      data = {
        ...data,
        specialityid:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
        speciality:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
      };
    } else {
      data = {
        ...data,
        service_id:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
        speciality:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
      };
    }
    props?.handleFinish(data);
  };
  const handleSearchByName = () => {
    let data = {
      localityname: props?.data?.llocalityname,
      dmrole: props?.data?.service,
      service: props?.data?.dmrole,
      cityname: props?.data?.cityname,
      localitylat: props?.data?.llocalitylat,
      localitylong: props?.data?.llocalitylong,
      queryoffset: 0, // query off set limit
      querylimit: 50, // query upper limit
      ...props?.data,
      sortid: sortid, // default sorting id
      // searchfilter: searchFilterId, // default search filter
      dayshortname: selecetdDate
        ? selecetdDate?.format("ddd")?.toLowerCase() || ""
        : props?.data?.dayshortname, // day name
      languageList: selectedLangList,
      docSearchName: docSearchName,
      searchfilter: "6",
    };
    if (props?.data?.dmrole === "ONL" || props?.data?.dmrole === "DOC") {
      data = {
        ...data,
        specialityid:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
        speciality:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
      };
    } else {
      data = {
        ...data,
        service_id:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
        speciality:
          props?.selectedSpeciality?.id || props?.data?.specialityid || "000",
      };
    }
    props?.handleFinish(data);
  };

  return (
    <div className="px-2 py-3 mb-4 border-bottom">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "0.25rem",
        }}
      >
        <div>
          <Button
            type="primary"
            shape="round"
            icon={<SortAscendingOutlined style={{ display: "inline-flex" }} />}
            onClick={showFormHandler}
            className="mx-4"
            size="large"
          >
            Sort
          </Button>
        </div>
        {/* <Button
          type="primary"
          shape="round"
          icon={<SearchOutlined style={{ display: "inline-flex" }} />}
          className="mx-4"
          // size="large"
          onClick={showFormHandler}
        >
          Search by Name
        </Button> */}
        <div>
          <Input
            placeholder="Search By Doctor Name"
            onChange={(e) => setDocSearchName(e.target.value)}
            allowClear
            allowSearch
            size="large"
            onPressEnter={() => {
              props?.setPage(1);
              setShowForm(false);
              handleSearchByName();
            }}
            addonBefore={<SearchOutlined style={{ fontSize: "1rem" }} />}
            suffix={
              <Button
                onClick={() => {
                  props?.setPage(1);
                  // setShowForm(false);
                  handleSearchByName();
                }}
                type="link"
              >
                Search
              </Button>
            }
          />
        </div>
        <div>
          <Button
            type="primary"
            shape="round"
            icon={<FilterOutlined style={{ display: "inline-flex" }} />}
            className="mx-4"
            size="large"
            onClick={showFormHandler}
          >
            Filter
          </Button>
        </div>
      </div>
      <Modal
        visible={showForm}
        title={<div>Select Any Option</div>}
        bodyStyle={{ paddingTop: "0" }}
        onCancel={() => setShowForm(false)}
        onOk={() => {
          props?.setPage(1);
          setShowForm(false);
          selectSpeciality();
        }}
        // destroyOnClose={true}
        centered
      >
        <div>
          <Form>
            <Divider>
              <span className="mr-2">
                <SortAscendingOutlined />
              </span>
              Sort By
            </Divider>
            <Form.Item name="sortid">
              <Radio.Group
                value={sortid}
                onChange={(e) => setSortId(e.target.value)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Radio value="1">High to Low</Radio>
                <Radio value="2">Low to High</Radio>
              </Radio.Group>
            </Form.Item>
            <Divider>
              {" "}
              <span className="mr-2">
                <FilterOutlined />
              </span>
              Filter By
            </Divider>
            <Form.Item name="searchFilterId">
              <Radio.Group
                value={searchFilterId}
                onChange={(e) => {
                  setSearchFilterId(e.target.value);
                  if (e.target.value === "3") {
                    setShowDateForm(true);
                    setSelectedLangList([]);
                    setShowLangForm(false);
                  } else if (e.target.value === "5") {
                    setShowLangForm(true);
                    setSelectedDate("");
                    setShowDateForm(false);
                  } else {
                    setShowDateForm(false);
                    setShowLangForm(false);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Radio value="1">All</Radio>
                <Radio value="2">Discount</Radio>
                <Radio value="3" onClick={() => setShowDateForm(true)}>
                  Date
                </Radio>
                <Radio value="5" onClick={() => setShowLangForm(true)}>
                  Language
                </Radio>
              </Radio.Group>
            </Form.Item>
            {showDateForm ? (
              <Form.Item style={{ textAlign: "center" }}>
                <DatePicker
                  onChange={(e) => {
                    setSelectedDate(e);
                  }}
                  size="large"
                ></DatePicker>
              </Form.Item>
            ) : null}
            {/* <Divider>
              <span className="mr-2">
                <SearchOutlined />
              </span>
              Search By Name
            </Divider>
            <Form.Item name="docSearchName">
              <Input
                placeholder="Search By Doctor Name"
                onChange={(e) => setDocSearchName(e.target.value)}
                allowClear
                allowSearch
                size="large"
                onPressEnter={() => {
                  props?.setPage(1);
                  setShowForm(false);
                  handleSearchByName();
                }}
                addonBefore={<SearchOutlined style={{ fontSize: "1rem" }} />}
                suffix={
                  <Button
                    onClick={() => {
                      props?.setPage(1);
                      setShowForm(false);
                      handleSearchByName();
                    }}
                    type="link"
                  >
                    Search
                  </Button>
                }
              />
            </Form.Item> */}
          </Form>
        </div>
      </Modal>
      <Modal
        visible={showLangForm}
        title={<div>Select Languages Spoken </div>}
        centered
        onCancel={() => setShowLangForm(false)}
        footer={[]}
      >
        <LangFilterForm
          closeForm={() => {
            setShowLangForm(false);
          }}
          setSelectedLangList={setSelectedLangList}
        ></LangFilterForm>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    state: state,
    userDash: state.userDash,
    selectedService: state.userDash.selectedService,
    selectedSpeciality: state.userDash.selectedSpeciality,
    specialityList: state.userDash.specialityList,
    outcomePackages: state.userDash.outcomePackages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpeciality: (data) => dispatch(dashboardAC.setSpeciality(data)),
    setDoctorsList: (list) => dispatch(dashboardAC.setDoctorsList(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortAndFilterForm);
