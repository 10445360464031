export const SharedServices = [
  {
    id: 3,
    title: "Pathology and Radiology",
    role: "PAT",
    image: "/images/services/radio.jpg",
    description:
      "Get access to affordable, high-quality radiology and pathology lab services closer to your home.",
  },
  {
    id: 1,
    title: "Online Doctor",
    role: "ONL",
    image: "/images/services/onl.png",
    description:
      "Connect with trusted and experienced healthcare experts , upload previous prescriptions & symptoms and know your turn",
  },
  {
    id: 0,
    title: "Offline Doctors",
    role: "DOC",
    image: "/images/services/doc2.jpg",
    description:
      "Affordable & Trusted Primary care in your neighborhood clinics and hospitals. Get appointments online and beat the queues.",
  },

  {
    id: 2,
    title: "Pharmacies",
    role: "CHE",
    image: "/images/services/pharmacies.jpg",
    description:
      "Discounted Accredited Pharmacies in your neighbourhood. Send digital prescriptions directly to the pharmacies.",
  },
  {
    id: 4,
    title: "Dietitians",
    role: "DIE",
    image: "/images/services/diet.jpeg",
    description:
      "Know & monitor your nutritional needs and get customized dietetic diets & meal plans appropriate for daily health care needs.",
  },
  {
    id: 7,
    title: "Preventive Mental Health Care",
    role: "VIT",
    image: "/images/services/mental_h.png",
    description:
      "This smart tool  will screen you for mental health risks based on  games, audio-visual techniques and mobile phone sensors. Book an Appointment and evaluate yourself today !!!",
  },
  {
    id: 6,
    title: "Preventive General Health Care",
    role: "VIT",
    image: "/images/services/vit.png",
    description:
      "AI-Based Smart techniques using Blood Samples, Thermal Images, ECG, Correlated parameters to detect Lifestyle and other predictive diseases conveniently",
  },

  {
    id: 5,
    title: "Physiotherapists",
    role: "PHY",
    image: "/images/services/phy.jpeg",
    description:
      "Enhancing your  healthcare experience and improving your quality of life through Physiotherapy services",
  },
];
