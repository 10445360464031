import React from "react";
import { Carousel } from "antd";
import { BulbFilled } from "@ant-design/icons";
import "../assets/styles/home.scss";
import collaboration from "../assets/images/collaboration.png";
// import carousel1 from "../assets/images/pre1.png";
import carousel2 from "../assets/images/pre2.png";
import carousel3 from "../assets/images/pre3.png";
import dietician from "../assets/images/dietician.png";
import affordable_services from "../assets/images/affordable_services.png";
import lets_fight_covid_19 from "../assets/images/lets_fight_covid_19.png";
// import mental_health from "../assets/images/mental_health.png";
import online_doctors from "../assets/images/online_doctors.png";
import preventive_healthcare from "../assets/images/preventive_healthcare.png";
import trusted_practitioners from "../assets/images/trusted_practitioners.png";
import MobileSliderCard from "./MobileSliderCard";
import HashTagsHeadline from "./HastagsHeadline";
const MobileImageSlider = (props) => {
  let carRef;
  const slides = [
    {
      cardTitle: "Preventive Screening",
      cardText:
        "Hypertension, diabetes, depression, stroke or thyroid etc. do not have visible symptoms.Our simple and easy preventive screening steps alerts and informs you.",
      // img: carousel1,
      // alt: "Doctors",
      position: 1,
    },
    {
      cardTitle: "Find Healthcare Practitioners in your neighbourhood",
      cardText:
        "Search for trusted and verified doctors and other healthcare partners ( radiology / pathology labs, pharmacies, dietitians etc) for your regular primary healthcare needs.",
      img: online_doctors,
      alt: "practitioners",
      position: 2,
    },
    {
      cardTitle: "Affordable Healthcare Services",
      cardText:
        "The Jan Elaaj discount is available in most neighbourhoods for a variety of healthcare services.",
      img: carousel3,
      alt: "affordable",
      position: 3,
    },
    {
      cardTitle: "Let’s FIGHT COVID-19 together !",
      cardText:
        " Read latest updates about Covid-19.Know about precautions, symptoms, treatments and more",
      img: lets_fight_covid_19,
      alt: "cov-19",
      position: 4,
    },
    {
      cardTitle: "Mental Health is Preserved Wealth",
      cardText:
        "Know how the pandemic has affected mental health.Read about symptoms, treatments and more",
      // img: mental_health,
      // alt: "mental Health",
      position: 5,
    },
    {
      cardTitle: "Online Doctors",
      cardText:
        " Consult doctors online / anytime through video calls.Share photos of symptoms etc.",
      img: carousel2,
      alt: "Online Doctors",
      position: 6,
    },
    {
      cardTitle: "Affordable Services",
      cardText:
        "Get affordable healthcare services across medical specialities.",
      img: affordable_services,
      alt: "affordable_services",
      position: 7,
    },
    {
      cardTitle: "Preventive Healthcare Services",
      cardText:
        "Preventive and predictive screening leads to early detection of health risks.",
      img: preventive_healthcare,
      alt: "preventive_healthcare",
      position: 8,
    },
    {
      cardTitle: "Trusted Practitioners",
      cardText:
        "Get trusted and verified healthcare practitioners in your neighbourhood.",
      img: trusted_practitioners,
      alt: "trusted_practitioners",
      position: 9,
    },
  ];

  const content = [
    {
      heading: "Collaborative Learning",
      description: "Learn, Share, Spread, Reliable  Health Related Information",
      img: collaboration,
      alt: "collab",
      position: 1,
    },
    {
      heading: "Online Consultation",
      description:
        "Connect with trusted and experienced healthcare experts anytime",

      position: 2,
    },
    {
      heading: "Trusted Neighborhood Clinics",
      description:
        "Affordable Primary care in your neighborhood clinics and hospitals.",

      position: 3,
    },
    {
      heading: "Trusted Neighborhood Pharmacies",
      description: "Discounted Accredited Pharmacies in your neighbourhood.",

      position: 4,
    },
    {
      heading: "Nutritionist & Dietician Online",
      description:
        "Know your nutritional needs and get yourself in appropriate body weight range.",
      img: dietician,
      alt: "dietician",
      position: 5,
    },

    {
      heading: "Mental Health Screening",
      description:
        "This is a disease too !!!  Screen yourself for risks conveniently and in privacy",

      position: 6,
    },

    {
      heading: "Preventive Healthcare Screening",
      description:
        "Lifestyle Diseases, Breast Cancer etc may not have early visible symptoms. Get screened conveniently",

      position: 7,
    },
    {
      heading: "Mental Health Counselling",
      description:
        "Beat the Mental Health Risk. Get Certified Professional Help on a Click  !!!",

      position: 8,
    },
  ];

  return (
    <div>
      <div className="car-container-mob">
        <>
          <div
            className="previousButton left_arrow"
            onClick={() => {
              carRef?.prev();
            }}
          >
            <div className="dummy-arrow"></div>
          </div>
          <div
            className="nextButton right_arrow"
            onClick={() => {
              carRef?.next();
            }}
          >
            <div className="dummy-arrow"></div>
          </div>
        </>
        <Carousel
          ref={(ref) => {
            carRef = ref;
          }}
          autoplay={true}
          //   dotPosition="bottom"
          dots={false}
          // dots={{ className: "primary-dots" }}
        >
          {content.map((slide, i) => (
            <MobileSliderCard
              {...slide}
              {...slides[i]}
              key={i + Math.random()}
            ></MobileSliderCard>
          ))}
        </Carousel>
      </div>
      <div className="slider-tickler-container-mobile">
        <div
          className="px-3 slider-tickler-mobile"
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <BulbFilled
            className="mx-3"
            style={{
              display: "flex",
              color: "#fff566",
              fontSize: "1.25rem",
              cursor: "pointer",
            }}
          ></BulbFilled>
          <span className="font-weight-bold mr-1" style={{ color: "yellow" }}>
            Tip of the Day :
          </span>
          There is no exercise that will do what eating healthy does to your
          life
        </div>
      </div>
      <div>
        <HashTagsHeadline></HashTagsHeadline>
      </div>
    </div>
  );
};

export default MobileImageSlider;
