import React, { useState } from "react";
import { Button, Empty, Input, message, Modal } from "antd";
import { MedicineBoxOutlined } from "@ant-design/icons";
import AddSymptomCard from "./AddSymptomCard";
import UserSymptomCard from "./UserSymptomCard";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apis } from "../../shared/apis";
import LinkPreviousAppointments from "./LinkPreviousAppointments";
const SymptomsForm = (props) => {
  const [symptomsLoader, setSymptomsLoader] = useState(false);
  const [showSymptomsForm, setShowSymptomsForm] = useState(false);
  const [symptomsTitle, setSymptomsTitle] = useState("");
  const [symptomsList, setSymptomsList] = useState([]);
  const [updatingDb, setUpdatingDb] = useState(false);
  const [pdlaIdList, setPdlaIdList] = useState([]);
  // const formatSymptoms = (data) => {
  //   // let arr = [];
  //   // let obj1 = {
  //   //   symptom_image_url: "",
  //   //   symptom_caption: "",
  //   //   symptom_file_name: "",
  //   //   symptom_id: "",
  //   // };
  //   // let o1 = {
  //   //   pdla_photo_caption1,
  //   // };
  //   return data;
  // };

  const handleSymptom = async () => {
    let input = {
      pdla_id: props?.pdlaid,
      id: props?.empid,
      subject: props?.role,
      // pdla_id: "PDLAID300021750",
    };
    setSymptomsLoader(true);
    await axios({ ...apis.pdlaPhoto, data: input })
      .then((res, err) => {
        setSymptomsLoader(false);
        if (err) {
          message.error("Something Went Wrong");
          // console.log("Err: ", err);
        } else {
          setShowSymptomsForm(true);
          setSymptomsTitle(res.data.pdla_symptoms);
          setSymptomsList([...res?.data?.data]);
        }
      })
      .catch((err) => {
        setSymptomsLoader(false);
        if (err) {
          message.error("Something Went Wrong");
          // console.log("Err cach: ", err);
        }
      });
  };
  // const uploadSymtomsToDB = async () => {
  //   setUpdatingDb(true);
  //   console.log("symtoms: ", symptomsList);
  //   let input = {
  //     pdla_id: props?.pdlaid, // appointment id
  //     pdla_symptoms: symptomsTitle, // symptom
  //     symptom_image_data: [...symptomsList],
  //     pdla_id_list: [], // linked appointment list
  //     pdla_patient_id: props?.dependent?.patientid || "patient Id", // patient id
  //   };
  //   await axios({ ...apis.updatePdlaSymptoms, data: input })
  //     .then((res, err) => {
  //       setUpdatingDb(false);
  //       if (err) {
  //         message.error("Something Went Wrong");
  //         // console.log("Err : ", err);
  //       } else {
  //         message.success("Successfully Saved Changes!");
  //         setShowSymptomsForm(false);
  //         console.log("res from upDb: ", res);
  //       }
  //     })
  //     .catch((err) => {
  //       setUpdatingDb(false);
  //       if (err) {
  //         console.log("Err : ", err);

  //         // message.error("Something Went Wrong");
  //       }
  //     });
  // };
  const uploadSingleSymtomsToDB = async (symptom, isNull = false) => {
    setUpdatingDb(true);
    let input = {
      pdla_id: props?.pdlaid, // appointment id
      pdla_symptoms: symptomsTitle, // symptom
      symptom_image_data: isNull ? [] : [symptom],
      pdla_id_list: [...pdlaIdList], // linked appointment list
      pdla_patient_id: props?.dependent?.patientid || "patient Id", // patient id
      id: props?.empid,
      subject: props?.role,
    };
    await axios({ ...apis.updatePdlaSymptoms, data: input })
      .then((res, err) => {
        setUpdatingDb(false);
        if (err) {
          message.error("Something Went Wrong");
          // console.log("Err : ", err);
        } else {
          message.success("Successfully Saved Changes!");
          // setShowSymptomsForm(false);
          // console.log("res from upDb: ", res);
        }
      })
      .catch((err) => {
        setUpdatingDb(false);
        if (err) {
          // console.log("Err : ", err);

          message.error("Something Went Wrong");
        }
      });
  };

  const insertSymptomHandler = () => {
    let symptoms = [...symptomsList];
    let obj = {
      symptom_image_url: "",
      symptom_caption: "",
      symptom_file_name: "",
      symptom_id: "",
      symptom_image: "",
    };
    // symptoms.push(obj);
    setSymptomsList([...symptoms, obj]);
  };

  const changeSymptomHandler = (i, data) => {
    let symptoms = [...symptomsList];
    symptoms.splice(i, 1, data);
    setSymptomsList([...symptoms]);
  };
  const popSymptomHandler = (i) => {
    let symptoms = [...symptomsList];
    symptoms.splice(i, 1);
    setSymptomsList([...symptoms]);
  };

  const updateDB = async () => {
    let data = {
      symptom_image_url: "props?.symptom_image_url",
      symptom_caption: "caption",
      symptom_file_name: "props?.symptom_file_name",
      symptom_id: "props?.symptom_id",
    };
    uploadSingleSymtomsToDB(data, true);
  };

  return (
    <div>
      <Button
        type="primary"
        className="my-1"
        loading={symptomsLoader}
        onClick={handleSymptom}
        icon={
          <MedicineBoxOutlined
            style={{ display: "inline-flex" }}
          ></MedicineBoxOutlined>
        }
        disabled={props?.disabled}
      >
        Add Symptoms
      </Button>
      <Modal
        visible={showSymptomsForm}
        title={<div>Please Add Symtoms</div>}
        okText={updatingDb ? "Saving..." : "Save"}
        // onOk={uploadSymtomsToDB}
        okButtonProps={{ loading: updatingDb }}
        onOk={() => {
          setShowSymptomsForm(false);
          if (props?.onClose) props?.onClose();
        }}
        onCancel={() => {
          setShowSymptomsForm(false);
          if (props?.onClose) props?.onClose();
        }}
        width={1000}
        destroyOnClose={true}
        footer={[
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="mr-4"
              onClick={() => {
                setShowSymptomsForm(false);
                if (props?.onClose) props?.onClose();
              }}
            >
              Close
            </Button>
            <LinkPreviousAppointments
              {...props}
              setPdlaIdList={setPdlaIdList}
              pdlaIdList={pdlaIdList}
              uploadSingleSymtomsToDB={uploadSingleSymtomsToDB}
              updatingDb={updatingDb}
            ></LinkPreviousAppointments>
          </div>,
        ]}
      >
        <div>
          <div
            style={{ width: "100%", fontSize: "1.5rem", textAlign: "center" }}
            className="text-primary row mx-0"
          >
            <div className="col-sm-9 my-2">
              <Input
                value={symptomsTitle}
                placeholder="Add Symptoms for"
                size="large"
                onChange={(e) => setSymptomsTitle(e.target.value)}
                onBlur={updateDB}
                loading={updatingDb}
                // style={{ width: "80%" }}
              ></Input>
            </div>
            <div
              className="col my-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined style={{ display: "inline-flex" }} />}
                onClick={insertSymptomHandler}
              >
                Add Symptom
              </Button>
            </div>
          </div>
          {symptomsList?.length ? (
            <div className="row mx-0">
              {symptomsList?.map((symptom, i) =>
                symptom.symptom_image_url &&
                !symptom.symptom_image_url.includes("base64") ? (
                  <div key={+Math.random()} className="col-sm-4 my-2">
                    <UserSymptomCard
                      {...symptom}
                      {...props}
                      index={i}
                      popSymptom={popSymptomHandler}
                      uploadSingleSymtomsToDB={uploadSingleSymtomsToDB}
                      changeSymptomHandler={changeSymptomHandler}
                      loading={updatingDb}
                    ></UserSymptomCard>
                  </div>
                ) : (
                  <div className="col-sm-4 my-2" key={i + Math.random()}>
                    <AddSymptomCard
                      {...symptom}
                      popSymptom={popSymptomHandler}
                      changeSymptomHandler={changeSymptomHandler}
                      uploadSingleSymtomsToDB={uploadSingleSymtomsToDB}
                      index={i}
                      {...props}
                      loading={updatingDb}
                    ></AddSymptomCard>
                  </div>
                )
              )}
            </div>
          ) : (
            <Empty description="No Symptoms Added"></Empty>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SymptomsForm;
