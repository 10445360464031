import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Empty,
  Input,
  message,
  Select,
  Spin,
  Card,
} from "antd";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  // UsergroupAddOutlined,
} from "@ant-design/icons";

import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { apis } from "../../shared/apis";
import PatientAppointmentCard from "./PatientAppointmentCard";
import moment from "moment";
// import "../../assets/styles/home.scss";
const { Option } = Select;
const PatientAppointments = (props) => {
  const [loader, setLoader] = useState(false);
  const [searching, setSearching] = useState(false);
  const [contact, setContact] = useState("");
  const [pid, setPid] = useState("");

  const [fetchingD, setFetchingD] = useState(false);
  const [dependents, setDependents] = useState([]);
  const [dependent, setDependent] = useState();
  const [userDependent, setUserDependent] = useState();

  const [appointmentList, setAppointmentList] = useState([]);

  const [startMoment, setStartMoment] = useState(moment);
  const [endMoment, setEndMoment] = useState(moment);
  // const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(moment);
  const [endDate, setEndDate] = useState(moment);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const updateData = () => {
    setShouldUpdate(!shouldUpdate);
  };
  const handleEndDateChange = (e, ds) => {
    setEndDate(ds);
    setEndMoment(e);
  };
  const handleStartDateChange = (e, ds) => {
    setStartDate(ds);
    setStartMoment(e);
  };

  const fetchDependents = async (tpid) => {
    setFetchingD(true);
    await axios({
      ...apis.getPatientDependents,
      data: {
        pid: tpid,
      },
    })
      .then((res, err) => {
        setFetchingD(false);
        if (err) {
          message.error("Something Went Wrong");
        } else setDependents([...dependents, ...res.data.dependents]);
      })
      .catch((err) => {
        if (err) {
          message.error("Something Went Wrong");
        }
        setFetchingD(false);
      });
  };

  const handleDependent = (e) => {
    setDependent(e);
    let selDep = dependents.filter((d) => d.name === e);
    setUserDependent(selDep[0]);
  };

  const handleSearch = async () => {
    setSearching(true);
    const input = {
      contact: contact, // phone no, iff searching for patient id then put patient id else put empty
      id: "", // if searching for patient then empty else depedent id
      subject: "patient", // if searching for patient than subject -> payient else depedent
      end_date: endDate.length < 11 ? endDate : endDate?.format("DD-MM-YYYY"),
      start_date:
        startDate.length < 11 ? startDate : startDate?.format("DD-MM-YYYY"),
      empSubject: JSON.parse(localStorage.getItem("empRole")).role?.replaceAll(
        "_",
        "-"
      ),
      empId: JSON.parse(localStorage.getItem("empRole")).empid,
    };
    await axios({ ...apis.getAppointmentListForSales, data: input })
      .then((res, err) => {
        setSearching(false);
        if (err) {
          message.error("Something Went Wrong");
        } else {
          // console.log('res: ', res.data)
          setPid(res.data.patient_id);
          if (dependents.length === 0) {
            fetchDependents(res.data.patient_id);
          }
          setAppointmentList(res.data.data);
        }
      })
      .catch((err) => {
        setSearching(false);
        if (err) {
          message.error("Something Went Wrong");
        }
      });
  };

  useEffect(() => {
    if (userDependent?.dependentid) {
      (async function () {
        setLoader(true);
        const input = {
          contact: "", // phone no, iff searching for patient id then put patient id else put empty
          id: userDependent?.dependentid, // if searching for patient then empty else depedent id
          subject: "dependent", // if searching for patient than subject -> payient else depedent
          empSubject: JSON.parse(
            localStorage.getItem("empRole")
          ).role?.replaceAll("_", "-"),
          empId: JSON.parse(localStorage.getItem("empRole")).empid,
          end_date:
            endDate.length < 11 ? endDate : endDate?.format("DD-MM-YYYY"),
          start_date:
            startDate.length < 11 ? startDate : startDate?.format("DD-MM-YYYY"),
        };
        await axios({ ...apis.getAppointmentListForSales, data: input })
          .then((res, err) => {
            setLoader(false);
            if (err) {
              message.error("Something Went Wrong");
            } else {
              // console.log('res: ', res.data)
              // setPid(res.data.patient_id);
              // fetchDependents(res.data.patient_id);
              setAppointmentList(res.data.data);
            }
          })
          .catch((err) => {
            setLoader(false);
            if (err) {
              message.error("Something Went Wrong");
            }
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDependent, shouldUpdate]);
  function disabledDate(current) {
    // Can not select days before today
    // return false
    return current &&
      current.format("DD-MM-YYYY") ===
        moment().endOf("day").format("DD-MM-YYYY")
      ? false
      : current < moment().endOf("day");
  }
  return (
    <div>
      <div className="row mx-0">
        <div className="col-sm-6 mb-2">
          <Input
            placeholder="Enter Mobile Number"
            // size="large"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            onPressEnter={handleSearch}
            suffix={
              <div>
                <Button
                  shape="round"
                  type="link"
                  loading={searching}
                  onClick={handleSearch}
                >
                  {searching ? "Searching..." : "Search"}
                </Button>
              </div>
            }
          ></Input>
        </div>
        {pid && (
          <div className="col-sm-6 mb-2">
            <Select
              size="large"
              showSearch
              style={{ width: "100%" }}
              placeholder=" Choose Dependent"
              optionFilterProp="children"
              onChange={handleDependent}
              // onSearch={onSearch}
              notFoundContent={fetchingD ? <Spin size="small" /> : null}
              // filterOption={(input, option) =>
              //   option.children
              //     .toLowerCase()
              //     .indexOf(input.toLowerCase()) >= 0
              // }
              value={dependent}
              allowClear
            >
              {dependents.map((d, i) => (
                <Option value={d.name} key={i + Math.random()}>
                  <div className="option-dependent px-1">
                    <span
                      className="user-icon"
                      style={{ width: "5%", textAlign: "center" }}
                    >
                      {d.photo ? (
                        <img
                          alt={d.name}
                          className="img-fluid"
                          src={"data:image/jpeg;base64," + d.photo}
                          style={{ width: "100%" }}
                        ></img>
                      ) : (
                        <UserOutlined></UserOutlined>
                      )}
                    </span>
                    <span className="user-name">{d.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div
          className="col-sm-12 row mx-0 py-2"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <div className="col-sm-6 mb-2">
        <Search
          placeholder="Search Patient by Name"
          enterButton="Search"
          size="large"
          suffix={<Dictaphone message={message} setMessage={setMessage} />}
          allowClear
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          //   onSearch={onSearch}
        />
      </div> */}
          <div
            className="col-sm-5 mb-2 row mx-0 px-0"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            <div className=" col-4 mr-2 pl-0"> Start Date : </div>
            <div className="col">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                value={startMoment}
                format={props?.isSlash ? "DD/MM/YYYY" : "DD-MM-YYYY"}
                onChange={handleStartDateChange}
                allowClear={false}
              ></DatePicker>
            </div>
          </div>
          <div
            className="col-sm-5 mb-2 row mx-0 px-0"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            <div className=" col-4 mr-2"> End Date : </div>
            <div className="col">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                value={endMoment}
                format={props?.isSlash ? "DD/MM/YYYY" : "DD-MM-YYYY"}
                onChange={handleEndDateChange}
                allowClear={false}
              ></DatePicker>
            </div>
          </div>
          <div
            className="col-sm-2 pr-0"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={updateData}>
              Search
            </Button>
          </div>
        </div>
      </div>
      <Card loading={loader} bordered={false} bodyStyle={{ padding: "0" }}>
        <div className="my-2">
          {appointmentList?.length ? (
            <table
              className="table table-pa table-responsive"
              style={{ textTransform: "capitalize", textAlign: "center" }}
            >
              <thead>
                <tr className="">
                  {Object.keys({ ...appointmentList[0] }).map((pkey, i) =>
                    pkey !== "pay_now_flag" ? (
                      <th className="" key={i + Math.random()}>
                        {pkey === "appointment_datetime" ? (
                          <div>
                            <CalendarOutlined
                              style={{
                                display: "inline-flex",
                                marginRight: "0.5rem",
                              }}
                            />
                            Date
                          </div>
                        ) : pkey === "appointment_time" ? (
                          <div>
                            <ClockCircleOutlined
                              style={{
                                display: "inline-flex",
                                marginRight: "0.5rem",
                              }}
                            />
                            Time
                          </div>
                        ) : pkey === "pdla_status" ? (
                          "Status"
                        ) : (
                          pkey
                        )}
                      </th>
                    ) : null
                  )}
                  <th>Enter OTP</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {appointmentList?.map((app, i) => (
                  <tr key={i + Math.random()}>
                    <PatientAppointmentCard
                      {...app}
                      {...props}
                    ></PatientAppointmentCard>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            // <div className="row mx-0">
            //   {appointmentList.map((app, i) => (
            //     <div key={i + Math.random()} className="col-12">
            //       <PatientAppointmentCard
            //         {...app}
            //         {...props}
            //       ></PatientAppointmentCard>
            //     </div>
            //   ))}
            // </div>
            <Empty description={false}>{`NO Appointments Found for ${
              JSON.parse(localStorage.getItem("empRole")).empid
            } Code`}</Empty>
          )}
        </div>
      </Card>
    </div>
  );
};

export default PatientAppointments;
