import React, { Component } from "react";
import "../assets/styles/globalStyles.scss";
import "../assets/styles/appScreen.scss";
import AppInviteCard from "./common/AppInviteCard";

class AppScreen extends Component {
  render() {
    return (
      <div
        className="row mr-0"
        style={{ backgroundColor: "#FAFAFA", padding: "80px 0px" }}
      >
        <div className="col-sm-6 p-1">
          <div>
            <img
              src="/images/app/mobile.png"
              className="img-fluid"
              alt="Jan Elaaj User App"
            />
          </div>
        </div>
        <div
          className="col-sm-6 d-flex p-4"
          style={{ alignItems: "center", justifyContent: "space-around" }}
        >
          <AppInviteCard></AppInviteCard>
        </div>
      </div>
    );
  }
}
// const loginButton = {
// 	backgroundColor: '#25a8e3',
// 	borderRadius: 20,
// 	paddingLeft: 22,
// 	paddingRight: 22,
// 	color: 'white',
// 	fontSize: 15,
// 	fontWeight: 'bold',
// 	marginLeft: 20,
// 	cursor: 'pointer'
// };

export default AppScreen;
