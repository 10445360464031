import React, { useState } from "react";
import { Button, Card } from "antd";
import {
  HomeOutlined,
  UserAddOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import DoctorDetailsCard from "../userDashBoard/DoctorDetailsCard";
const { Meta } = Card;
const PartnerClinicCard = (props) => {
  const [showDocDetials, setShowDocDetials] = useState(false);
  return (
    <div>
      <Card
        type="inner"
        hoverable
        className="m-1"
        extra={
          <Button
            type="primary"
            shape="round"
            icon={<FileTextOutlined style={{ display: "inline-flex" }} />}
            onClick={() => {
              setShowDocDetials(!showDocDetials);
            }}
          >
            {showDocDetials ? "Hide Details" : "View Details"}
          </Button>
        }
        title={
          <div style={{ fontSize: "1.25rem" }}>
            <HomeOutlined
              style={{
                display: "inline-flex",
                marginRight: "0.5rem",
              }}
            ></HomeOutlined>
            {props?.clinic_name}
          </div>
        }
      >
        <Meta
          title={
            <div>
              <UserAddOutlined
                style={{
                  display: "inline-flex",
                  marginRight: "0.5rem",
                }}
              ></UserAddOutlined>
              Dr. {props?.doctor_name}
            </div>
          }
        ></Meta>
        {showDocDetials && (
          <DoctorDetailsCard
            doc={{ ldlm_id: props.dlm_id }}
            reqOrigin={"userApp"}
          ></DoctorDetailsCard>
        )}
      </Card>
    </div>
  );
};

export default PartnerClinicCard;
