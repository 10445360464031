import { Button, message } from "antd";
import React, { useState } from "react";
const MobVideorecorderbutton = (props) => {
  const [video, setVideo] = useState(null);
  return (
    <div style={{ minHeight: "400px" }}>
      <p>
        <label className="" for="videoFile">
          Upload a video:
        </label>
        <input
          type="file"
          id="videoFile"
          capture="environment"
          accept="video/*"
          onChange={(e) => {
            console.log("e: ", e.target.files);
            setVideo(e.target.files[0]);
          }}
        />
      </p>
      <div className="text-center">
        <Button
          onClick={() => {
            if (video) props?.handleOnStop("dummy url", video);
            else message.error("Please Upload Video");
          }}
          type="primary"
          shape="round"
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default MobVideorecorderbutton;
