import * as ActionTypes from "../ActionTypes";

//gettting Appointment list for Partner
export const setIsPartnerLoggedIn = (flag) => {
  return {
    type: ActionTypes.SET_ISPARTNER_LOGGED_IN,
    payload: flag,
  };
};
export const getAppointmentListLoading = (flag) => {
  return {
    type: ActionTypes.APPOINTMENT_LOADING,
    payload: flag,
  };
};
export const getAppointmentListFailed = (flag) => {
  return {
    type: ActionTypes.APPOINTMENT_FAILED,
    payload: flag,
  };
};
export const getAppointmentListSuccess = (flag) => {
  return {
    type: ActionTypes.APPOINTMENT_SUCCESS,
    payload: flag,
  };
};
export const setAppointmentList = (list) => {
  return {
    type: ActionTypes.SET_APPOINTMENT_LIST,
    payload: list,
  };
};

export const setRolesList = (obj) => {
  return {
    type: ActionTypes.SET_ROLES_LIST,
    payload: obj,
  };
};

export const setClinicsList = (obj) => {
  return {
    type: ActionTypes.SET_CLINIC_LIST,
    payload: obj,
  };
};

export const setSelectedRole = (obj) => {
  return {
    type: ActionTypes.SET_SELECTED_ROLE,
    payload: obj,
  };
};
export const setSelectedClinic = (obj) => {
  return {
    type: ActionTypes.SET_SELECTED_CLINIC,
    payload: obj,
  };
};
export const setSelectedAppointment = (obj) => {
  return {
    type: ActionTypes.SET_SELECTED_APPOINTMENT,
    payload: obj,
  };
};
