import React, { useEffect, useState } from "react";
import { Card, Empty, message } from "antd";
import axios from "axios";
import { apis } from "../../shared/apis";

const EmpProfile = (props) => {
  const [loader, setLoader] = useState(true);
  const [profile, setProfile] = useState({});
  useEffect(() => {
    (async function () {
      const input = {
        id: props?.empid,
        subject: props?.role.toLowerCase().replaceAll("_", "-"),
      };
      setLoader(true);
      await axios({
        ...apis.getDistributerDealerSubDealerDetails,
        data: input,
      })
        .then((res, err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          } else {
            // if (typeof res.data?.info[0] !== "undefined")
            if (res.data?.info?.length) setProfile(res.data?.info[0]);
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err) {
            message.error("Something Went Wrong");
          }
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const layout = {
  //   labelCol: { span: 8 },
  //   wrapperCol: { span: 16 },
  // };

  return (
    <div>
      <Card
        bordered={false}
        loading={loader}
        bodyStyle={{ paddingTop: "0", paddingBottom: "0" }}
      >
        {Object.keys(profile)?.length ? (
          <table
            className="table table-striped"
            style={{ textTransform: "capitalize" }}
          >
            <tbody>
              {Object.keys(profile).map((pkey, i) => (
                <tr key={i + Math.random()}>
                  <th>{pkey}:</th>
                  <td>
                    {pkey === "duration"
                      ? profile[pkey] + " years"
                      : pkey === "gender"
                      ? profile[pkey]
                        ? profile[pkey] === "M"
                          ? "Male"
                          : "Female"
                        : "Not Applicable"
                      : profile[pkey]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Empty description={"No Profile Found"}></Empty>
        )}
        {/* <Form
          initialValues={{ ...profile }}
          labelAlign="right"
          size="large"
          {...layout}
        >
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="name"
            label={<div>Name</div>}
          >
            <Input disabled={false} placeholder="Name"></Input>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="company_name"
            label={<div>Company Name</div>}
          >
            <Input disabled={false} placeholder="Company Name"></Input>
          </Form.Item>
          <div className="row mx-0">
            <div className="col-sm-6 pl-0">
              <Form.Item name="contact" label={<div>Contact Number</div>}>
                <Input disabled={false} placeholder="Contact Number"></Input>
              </Form.Item>
            </div>
            <div className="col-sm-6 pr-0">
              <Form.Item name="email" label={<div>Email</div>}>
                <Input disabled={false} placeholder="Email"></Input>
              </Form.Item>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-sm-6 pl-0">
              <Form.Item name="reg_start_date" label={<div>Start Date</div>}>
                <Input disabled={false} placeholder="start Date"></Input>
              </Form.Item>
            </div>
            <div className="col-sm-6 pr-0">
              <Form.Item name="reg_end_date" label={<div>End Date</div>}>
                <Input disabled={false} placeholder="End Date"></Input>
              </Form.Item>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-sm-6 pl-0">
              <Form.Item name="duration" label={<div>Duration</div>}>
                <Input
                  disabled={false}
                  size="large"
                  type="number"
                  placeholder="Enter Duration"
                ></Input>
              </Form.Item>
            </div>

            <div className="col-sm-6 pr-0">
              <Form.Item name="DOB" label={<div>DOB</div>}>
                <Input
                  disabled={false}
                  value={profile?.DOB}
                  placeholder="DOB"
                ></Input>
              </Form.Item>
            </div>
          </div>
        </Form> */}
      </Card>
    </div>
  );
};

export default EmpProfile;
