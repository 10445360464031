import React from "react";
import { Tabs } from "antd";
import EmpProfile from "./EmpProfile";
import SubDealerList from "./SubDealerList";
import CreateSubDealer from "./CreateSubDealer";
// import PartneredClinics from "./PartneredClinics";
// import PatientAppointments from "./PatientAppointments";
const { TabPane } = Tabs;
const DistributerPage = (props) => {
  return (
    <div>
      <Tabs size="large" centered>
        <TabPane tab={<div>Profile</div>} key="empProfile">
          <EmpProfile {...props}></EmpProfile>
        </TabPane>
        <TabPane tab={<div>All Dealers</div>} key="empDealersList">
          <SubDealerList {...props} subject={"dealer"}></SubDealerList>
        </TabPane>
        <TabPane tab={<div>Create Dealer</div>} key="empCreateDealer">
          <CreateSubDealer {...props} subject={"dealer"}></CreateSubDealer>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DistributerPage;
